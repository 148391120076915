import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function ControlledRadioButtonsGroup({ type, setType }) {
  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={type}
        onChange={handleChange}
      >
        <FormControlLabel
          value="correction"
          control={<Radio />}
          label="Correction"
        />
        <FormControlLabel
          value="amendment"
          control={<Radio />}
          label="Amendment"
        />
      </RadioGroup>
    </FormControl>
  );
}
