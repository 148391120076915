import React from 'react'
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TabContentCard = ({children}) => {
  return (
    <div className='col-md-9 bg-white tab-card  '>

        {children}
      
    </div>
  )
}

export default TabContentCard
