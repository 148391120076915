import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getclientcategorylist } from "../../redux/api";
import { RailwayZone } from "../../schemas/settingSchema";
import { useFormik } from "formik";

function EditRailwayZone() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const location = useLocation();
  const navigate = useNavigate();
  const editData = location?.state?.editData ?? "";
  const [clientCategoryData, setClientCategoryData] = useState([]);
  const railwayZoneId = editData.id;
  const [validationError, setValidationError] = useState(null);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const [spinner, setSpinner] = useState(false);

  const initialValues = {
    name: "",
    catId: "",
  };

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: RailwayZone,
    onSubmit: (values) => {
      updateZone(values);
    },
  });

  const updateZone = (value) => {
    setSpinner(true);
    const url = baseUrl + "editrailwayzone";
    const data = {
      user_id,
      railwayZoneId,
      ...value,
      accessType: "edit",
    };
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          navigate("/railway-zone");
          toast.success("Updated Successfully");
        }
        if (response.data.status === "Token is Expired") {
          navigate("/");
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
      });
  };

  useEffect(() => {
    getclientcategorylist({ user_id: user_id })
      .then((res) => {
        setClientCategoryData(res?.data?.response?.ClientCategoryList);
      })
      .catch((err) => {

      });
    setValues(editData);
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                          <Link to="#!">Projects /</Link>
                        </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/railway-zone">Railway Zone/</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Railway Zone
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Railway Zone</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Railway Zone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Railway Zone Name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p style={{ color: "red" }}>
                              {touched.name && errors.name
                                ? errors.name
                                : validationError?.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="parent" className="form-label">
                              Client Category
                            </label>
                            <select
                              className="form-control"
                              id="catId"
                              name="catId"
                              value={values.catId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              //
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {clientCategoryData.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <p style={{ color: "red" }}>
                            {touched.catId && errors.catId
                              ? errors.catId
                              : validationError?.catId}
                          </p>
                        </div>
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Wait...</p>
                        </div>
                      ) : (
                        <div className="col-md-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-3"
                          >
                            Update
                          </button>
                          <Link to="/railway-zone">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm"
                            >
                              Cancel
                            </button>
                          </Link>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditRailwayZone;
