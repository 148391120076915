import * as Yup from "yup";

export const tenderTicketSchema = Yup.object({
  tender_id: Yup.string().required("This field is required"),
  board_group_id: Yup.string().required("This field is required"),
  title: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  priority: Yup.string().required("This field is required"),
  assignee: Yup.string().required("This field is required"),
  estimated_time: Yup.number()
    .typeError("Please enter valid number")
    .required("This field is required").min(1,  "Enter valid number"),
  reporting_to: Yup.string().required("This field is required"),
  start_date: Yup.string().required("This field is required"),
  board_group_status_id: Yup.string().required("This field is required"),
  due_time: Yup.string().required("This field is required"),
  due_date: Yup.string()
    .required("This field is required.")
    .when("start_date", (start_date, schema) => {
      return schema.test({
        test: function (due_date) {
          return (
            new Date(due_date) >= new Date(start_date) ||
            this.createError({
              message: "End date must be after start date",
            })
          );
        },
      });
    }),   
});
