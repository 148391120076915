import * as Yup from "yup";

export const GoodReceiptSchema = Yup.object({
  // doc_no: Yup.string().required("This field is required."),

  // rev_no: Yup.string().required("This field is required."),

  // supplier_id: Yup.number().required("This field is required."),
  // date: Yup.date().required("This field is required."),

  // grn_no: Yup.string().required("This field is required."),
  // order_no: Yup.string().required("This field is required."),
  // delivery_location: Yup.string().required("This field is required."),
  // project_id: Yup.number().required("This field is required."),

  invoice: Yup.mixed()
  .required("This field is required."),
});
