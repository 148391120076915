import React, { useEffect, useState } from "react";
import "../Material/Material.css";
import RequistionForm from "./RequistionForm";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { errorsMessage } from "../../constant";
import { useNavigate } from "react-router-dom";
import { requistionSchema } from "../../schemas/StoreSchema/RequistionFormScehma";

function AddRequisitionForm() {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const initialValue = {
    type: "forOther",
    requested_date: "",
    store_id: "",
    requested_by: "",
    required_date: "",
    project_id: "",
    items: [
      {
        item_id: "",
        required_qty: "",
        issued_qty: "",
        issued_to: "",
        batch_no: "",
      },
    ],
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: requistionSchema,
    onSubmit: (values) => {
      storeRequistionForm(values);
    },
  });
  const storeRequistionForm = (values) => {
    const url = `${baseUrl}storerequisitionform`;
    const data = { user_id, ...values };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status == 200) {
          toast.success("Requisition Form Details Added Successfully", {
            autoClose: 2000,
          });
          navigate(-1);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setValidationError(err?.response?.data?.response);
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card form_goodsnote">
                <div className="card-body">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h3> Requisition form</h3>
                  </div>
                  <RequistionForm
                    handleChange={handleChange}
                    values={values}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    mode="add"
                    setFieldValue={setFieldValue}
                    validationError={validationError}
                    detail=""
                    spinner={spinner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRequisitionForm;
