import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

export default function AddUserRoleModal({
  show,
  handleClose,
  getRoleList,
  editAbleRole,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [name, setName] = useState("");
  const [spinner, setSpinner] = useState(false);

  function addRole(e) {
    e.preventDefault();
    setSpinner(true);
    let url = baseUrl + "createnewrole";
    let data = { user_id, name };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getRoleList();
          setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
      });
  }

  function editRole(e) {
    e.preventDefault();
    setSpinner(true);
    let url = baseUrl + "editrole";
    let role_id = editAbleRole.id;
    let data = { user_id, name, role_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getRoleList();
          setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
      });
  }


  useEffect(() => {
    if (editAbleRole) {
      setName(editAbleRole.name);
    } else {
      setName("");
    }
  }, [editAbleRole]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-12">
                <label className="form-label">Role Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Role Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {editAbleRole ? (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => editRole(e)}
                    >
                      Update Role
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => addRole(e)}
                    >
                      Add Role
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
