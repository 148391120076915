import React from "react";

function TdcDetailPreview({ tdcData }) {
  return (
    <>
      <div className="">
        <label
          htmlFor=""
          className="form-label"
          style={{ textAlign: "center" }}
        >
          Type
        </label>
        <h5
          className="text-danger fw-bold m-0 form-control border-0 ps-0"
          style={{ text: "center" }}
        >
          TDC Details
        </h5>
      </div>
      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Paid/Exempted
          </label>
          <input
            defaultValue={tdcData?.tdc_paidExempted}
            readOnly="true"
            className="form-control"
            placeholder="Paid/Exempted"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Amount
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={tdcData?.tdc_toPay}
            placeholder="Tdc to pay"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Transaction No.
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={tdcData?.tdc_paidTransactionNo}
            placeholder="Transaction No."
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group mb-3">
          <label htmlFor="" className="form-label">
            Transaction Date
          </label>
          <input
            className="form-control"
            readOnly="true"
            defaultValue={tdcData?.tdc_paidTransactionDate}
            placeholder="Transaction Date"
          />
        </div>
      </div>
      <div></div>
      
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </>
  );
}

export default TdcDetailPreview;
