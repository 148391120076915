// import React from 'react'
import { Link, NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import BillingDetailPdf from "./BillingDetailPdf";

function ProjectsProjectDetail() {
  const heightScroll = {
    maxHeight: "600px",
    overflowY: "scroll",
  };
  const componentPdf = useRef();

  function formatDate(value) {
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }
  const [projectDetails, setprojectDetails] = useState([]);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [render, setRender] = useState(true);

  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;

  const params = useParams();

  const fetchData = async () => {
    const url = apiurl + "untiprojectdetailpdf";
    var body = {
      user_id: user_id,
      project_id: params.id,
    };
    await axios
      .post(url, body, config)
      .then((res) => {
        setprojectDetails(res?.data?.response?.projectDetails);

        if (res?.data?.response?.isSuccess) {
          if (
            res?.data?.response?.projectDetails?.TdcEmdData &&
            res?.data?.response?.projectDetails?.pgMultipleData.length !== 0 &&
            res?.data?.response?.projectDetails?.sdMultipleData.length !== 0
          ) {
            setRender(true);
          } else {
            setRender(false);
          }
        }
      })
      .catch((err) => {
        toast.error("Something went Wrong!Please try again in sometime");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // setOther();
  const generatePdf = useReactToPrint({
    content: () => componentPdf.current,
    documentTitle: "Project Preview",
    onAfterPrint: () => alert("data saved in pdf"),
  });

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#!"></Link>
                      </li>

                      <li className="breadcrumb-item">
                        <Link to="/ProjectsProject">Projects list /</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link
                          to={`/ProjectsProjectDetail/${projectDetails?.id}`}
                        >
                          Projects detail /
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Project Preview {projectDetails?.pd_projectTitle}
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">
                    Project Preview ({projectDetails?.pd_projectTitle})
                  </h4>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div ref={componentPdf} style={{ width: "100%" }}>
                  <div className="table__styled">
                    <a
                      href="https://www.acmeindia.co/"
                      className="d-block mb-4"
                      style={{ marginBottom: "15px" }}
                    >
                      <img
                        src="/assets/images/logos/acme-india.svg"
                        className="img-1"
                      />
                    </a>

                    <div className="float-start w-100">
                      <table className="pb-5">
                        <thead>
                          <tr>
                            <th colSpan="5">Project details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4>File No.</h4>
                              <p>{projectDetails?.pd_fileNo}</p>
                            </td>
                            <td>
                              <h4>Railway Zone</h4>
                              <p>{projectDetails?.pd_railwayZone}</p>
                            </td>

                            <td>
                              <h4>Sub Division</h4>
                              <p>{projectDetails?.pd_subDivision}</p>
                            </td>

                            <td>
                              <h4>Work Status</h4>
                              <p>{projectDetails?.pd_workStatus}</p>
                            </td>

                            <td>
                              <h4>Work Status Remark</h4>
                              <p>
                                {projectDetails?.pd_workStatusRemark
                                  ? projectDetails?.pd_workStatusRemark
                                  : " -  - - -"}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <h4>Tender No</h4>
                              <p>{projectDetails?.pd_tenderNo}</p>
                            </td>

                            <td>
                              <h4>Tender Date</h4>
                              <p>{formatDate(projectDetails?.pd_tenderDate)}</p>
                            </td>

                            <td>
                              <h4>Tender Quantity</h4>
                              <p>
                                {projectDetails?.pd_tenderQuantity
                                  ? projectDetails?.pd_tenderQuantity
                                  : " -  - - -"}
                              </p>
                            </td>

                            {/* <td>
                              <h4>Tender Docs</h4>
                              <a
                                href={
                                  imageBaseUrl + projectDetails?.pd_tenderDocs
                                }
                                target="_blank"
                              >
                                <p
                                  className="text-primary"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Tender Attachment
                                </p>
                              </a>
                            </td> */}

                            <td>
                              <h4>PO/LOA No.</h4>
                              <p>{projectDetails?.pd_poloaNo}</p>
                            </td>
                            <td>
                              <h4>PO/LOA Date.</h4>
                              <p>{formatDate(projectDetails?.pd_poloaDate)}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <h4>Total Order Value</h4>
                              <p>{projectDetails?.pd_totalOrderValue} </p>
                            </td>

                            <td>
                              <h4>Total Order Quantity</h4>
                              <p>{projectDetails?.pd_totalOrderQty} </p>
                            </td>

                            {/* <td>
                              <h4>PO/LOA Docs</h4>
                              <a
                                href={
                                  imageBaseUrl + projectDetails?.pd_poloaDocs
                                }
                                target="_blank"
                              >
                                <p
                                  className="text-primary"
                                  style={{ textDecoration: "underline" }}
                                >
                                  PO/LOA Attachment
                                </p>
                              </a>
                            </td> */}

                            <td>
                              <h4>Project Title</h4>
                              <p>{projectDetails?.pd_projectTitle}</p>
                            </td>
                            <td>
                              <h4>Project Category</h4>
                              <p>{projectDetails?.pd_projectCategory}</p>
                            </td>
                            <td>
                              <h4>Project Manager</h4>
                              <p>{projectDetails?.pd_projectManager}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <h4>Purchase Manager</h4>
                              <p>{projectDetails?.pd_purchaseManager}</p>
                            </td>
                            <td>
                              <h4>Inspection Agency</h4>
                              <p>{projectDetails?.pd_InspectionAgency}</p>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <h4>PO/LOA Quantity</h4>
                            </td>

                            <td>
                              <h4>UOM</h4>
                            </td>

                            <td>
                              <h4>Start Date</h4>
                            </td>

                            <td>
                              <h4>Completion Date</h4>
                            </td>

                            <td>
                              <h4>DP Extension</h4>
                            </td>
                          </tr>

                          {projectDetails.pd_poloaQuantityMutipleData?.map(
                            (item, i) => (
                              <tr>
                                <td className="py-0">
                                  <p className="m-0">{item.poLoaQty} </p>
                                </td>

                                <td className="py-0">
                                  <p className="m-0">{item.unit}</p>
                                </td>

                                <td className="py-0">
                                  <p className="m-0">
                                    {formatDate(item.startDate)}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p className="m-0">
                                    {formatDate(item.completionDate)}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p className="m-0">
                                    {item.dpExtension
                                      ? item.dpExtension
                                      : " -  - - -"}
                                  </p>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th colSpan="4">Railway rate as per LOA/PO Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <h4>Per Unit </h4>
                          </td>
                          <td>
                            <h4>Quantity</h4>
                          </td>
                          <td>
                            <h4>Total </h4>
                          </td>
                        </tr>
                        {projectDetails?.lcb_basicMultipleData !== null ? (
                          projectDetails?.lcb_basicMultipleData?.map(
                            (item, index) => (
                              <tr>
                                <td className="py-0">
                                  <h4
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                    hidden={index !== 0}
                                  >
                                    Basic
                                  </h4>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item.basicPerUnit}{" "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item.basicTotalQuantity}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item.basicTotal}{" "}
                                  </p>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="py-0">
                              <h4>Basic</h4>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="py-0">
                            <h4 style={{ marginTop: "1rem" }}>Installation </h4>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_installationPerUnit
                                ? projectDetails?.lcb_installationPerUnit
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_installationQty
                                ? projectDetails?.lcb_installationQty
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_installationTotal
                                ? projectDetails?.lcb_installationTotal
                                : " -  - - -"}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td className="py-0">
                            <h4 style={{ marginTop: "1rem" }}>Packaging </h4>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_packagingPerUnit
                                ? projectDetails?.lcb_packagingPerUnit
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_packagingQty
                                ? projectDetails?.lcb_packagingQty
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_packagingTotal
                                ? projectDetails?.lcb_packagingTotal
                                : " -  - - -"}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td className="py-0">
                            <h4 style={{ marginTop: "1rem" }}>Freight</h4>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_freightPerUnit
                                ? projectDetails?.lcb_freightPerUnit
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_freightQty
                                ? projectDetails?.lcb_freightQty
                                : " -  - - -"}
                            </p>
                          </td>
                          <td className="py-0">
                            <p>
                              {projectDetails?.lcb_freightTotal
                                ? projectDetails?.lcb_freightTotal
                                : " -  - - -"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0">
                            <h4 style={{ marginTop: "1rem" }}>
                              GST@{projectDetails?.lcb_gstValue}%{" "}
                            </h4>
                          </td>
                          <td className="py-0">
                            <p style={{ marginTop: "1rem" }}>
                              {projectDetails?.lcb_gstUnit}
                            </p>
                          </td>
                          <td className="py-0">
                            <p style={{ marginTop: "1rem" }}>
                              {projectDetails?.lcb_gstQty}{" "}
                            </p>
                          </td>
                          <td className="py-0">
                            <p style={{ marginTop: "1rem" }}>
                              {projectDetails?.lcb_gstTotal}
                            </p>
                          </td>
                        </tr>

                        {projectDetails.lcb_otherMultipleData !== null ? (
                          projectDetails.lcb_otherMultipleData?.map(
                            (item, index) => (
                              <tr>
                                <td className="py-0">
                                  {" "}
                                  <h4
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                    hidden={index !== 0}
                                  >
                                    {" "}
                                    Other
                                  </h4>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item?.otherPerUnit
                                      ? item?.otherPerUnit
                                      : " -  - - -"}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item?.otherQuantity
                                      ? item?.otherQuantity
                                      : " -  - - -"}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "1rem" : "0",
                                    }}
                                  >
                                    {item?.otherTotal
                                      ? item?.otherTotal
                                      : " -  - - -"}
                                  </p>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="py-0">
                              {" "}
                              <h4> Other</h4>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                            <td className="py-0">
                              <p>{" -  - - -"}</p>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className="py-0">
                            <h4 style={{ marginTop: "1rem" }}>PO/LOA Value </h4>
                          </td>
                          <td className="py-0">
                            <p style={{ marginTop: "1rem" }}>
                              {projectDetails?.lcb_poloaValue
                                ? projectDetails?.lcb_poloaValue
                                : " -  - - -"}
                            </p>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>
                            <h4>PAYMENT TERMS </h4>
                          </td>
                          <td>
                            <p>{projectDetails?.lcb_paymentTerms}</p>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    {render && (
                      <table style={{ tableLayout: "inherit" }}>
                        <thead>
                          <tr>
                            <th colSpan="12">EMD/SD/PG PAID DETAILS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <h4>Type</h4>
                            </td>
                            <td>
                              <h4>To Pay</h4>
                            </td>
                            <td>
                              <h4>Paid/Exempted</h4>
                            </td>
                            <td>
                              <h4>Transaction No.</h4>
                            </td>
                            <td>
                              <h4>Transaction Date</h4>
                            </td>
                            <td>
                              <h4>Expiry Date</h4>
                            </td>
                            <td>
                              <h4>Return Date</h4>
                            </td>
                            <td>
                              <h4>Return Amount</h4>
                            </td>
                            <td>
                              <h4>Due</h4>
                            </td>
                            <td>
                              <h4>Remark</h4>
                            </td>
                            <td>
                              <h4>Attachment</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>TDC</h4>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.tdc_toPay
                                  ? projectDetails?.TdcEmdData?.tdc_toPay
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.tdc_paidExempted
                                  ? projectDetails?.TdcEmdData?.tdc_paidExempted
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData
                                  ?.tdc_paidTransactionNo
                                  ? projectDetails?.TdcEmdData
                                      ?.tdc_paidTransactionNo
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData
                                  ?.tdc_paidTransactionDate
                                  ? formatDate(
                                      projectDetails?.TdcEmdData
                                        ?.tdc_paidTransactionDate
                                    )
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              {" "}
                              <p>{"  -  - - -  "}</p>
                            </td>

                            <td>
                              <p>{"  -  - - -  "}</p>
                            </td>

                            <td>
                              <p>{"  -  - - -  "}</p>
                            </td>

                            <td>
                              <p>{"  -  - - -  "}</p>
                            </td>
                            <td>
                              <p>{"  -  - - -  "}</p>
                            </td>
                            <td>
                              <a
                                href={
                                  imageBaseUrl +
                                  projectDetails?.TdcEmdData?.tdc_attachement
                                }
                                target="_blank"
                              >
                                <p
                                  className="text-primary"
                                  style={{ textDecoration: "underline" }}
                                >
                                  TDC Attachment
                                </p>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h4>EMD</h4>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_toPay
                                  ? projectDetails?.TdcEmdData?.emd_toPay
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_paidExempted
                                  ? projectDetails?.TdcEmdData?.emd_paidExempted
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData
                                  ?.emd_paidTransactionNo
                                  ? projectDetails?.TdcEmdData
                                      ?.emd_paidTransactionNo
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData
                                  ?.emd_paidTransactionNoDate
                                  ? formatDate(
                                      projectDetails?.TdcEmdData
                                        ?.emd_paidTransactionNoDate
                                    )
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_expiryDate
                                  ? formatDate(
                                      projectDetails?.TdcEmdData?.emd_expiryDate
                                    )
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_returnDate
                                  ? formatDate(
                                      projectDetails?.TdcEmdData?.emd_returnDate
                                    )
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_returnAmount
                                  ? projectDetails?.TdcEmdData?.emd_returnAmount
                                  : "  -  - - -  "}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_due
                                  ? projectDetails?.TdcEmdData?.emd_due
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                {projectDetails?.TdcEmdData?.emd_remark
                                  ? projectDetails?.TdcEmdData?.emd_remark
                                  : "  -  - - -  "}
                              </p>
                            </td>
                            <td>
                              <a
                                href={
                                  imageBaseUrl +
                                  projectDetails?.TdcEmdData?.emd_attachement
                                }
                                target="_blank"
                              >
                                <p
                                  className="text-primary"
                                  style={{ textDecoration: "underline" }}
                                >
                                  EMD Attachment
                                </p>
                              </a>
                            </td>
                          </tr>
                          {projectDetails?.sdMultipleData?.map(
                            (item, index) => (
                              <tr>
                                <td className="py-0">
                                  <h4
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                    hidden={index !== 0}
                                  >
                                    SD
                                  </h4>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {" "}
                                    {item?.sd_toPay
                                      ? item?.sd_toPay
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_paidExempted
                                      ? item?.sd_paidExempted
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_paidTransactionNo
                                      ? item?.sd_paidTransactionNo
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_paidTransactionNoDate
                                      ? formatDate(
                                          item?.sd_paidTransactionNoDate
                                        )
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_expiryDate
                                      ? formatDate(item?.sd_expiryDate)
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_returnDate
                                      ? formatDate(item?.sd_returnDate)
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_returnAmount
                                      ? item?.sd_returnAmount
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_due
                                      ? item?.sd_due
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.sd_remark
                                      ? item?.sd_remark
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td>
                                  <a
                                    href={imageBaseUrl + item?.sd_attachement}
                                    target="_blank"
                                  >
                                    <p
                                      className="text-primary"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      SD Attachment
                                    </p>
                                  </a>
                                </td>
                              </tr>
                            )
                          )}

                          {projectDetails?.pgMultipleData?.map(
                            (item, index) => (
                              <tr>
                                <td className="py-0">
                                  <h4
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                    hidden={index !== 0}
                                  >
                                    PG
                                  </h4>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_toPay
                                      ? item?.pg_toPay
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_paidExempted
                                      ? item?.pg_paidExempted
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_paidTransactionNo
                                      ? item?.pg_paidTransactionNo
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_paidTransactionNoDate
                                      ? formatDate(
                                          item?.pg_paidTransactionNoDate
                                        )
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_expiryDate
                                      ? formatDate(item?.pg_expiryDate)
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_returnDate
                                      ? formatDate(item?.pg_returnDate)
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_returnAmount
                                      ? item?.pg_returnAmount
                                      : "  -  - - -  "}
                                  </p>
                                </td>

                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_due
                                      ? item?.pg_due
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td className="py-0">
                                  <p
                                    style={{
                                      marginTop: index === 0 ? "2rem" : "0",
                                    }}
                                  >
                                    {item?.pg_remark
                                      ? item?.pg_remark
                                      : "  -  - - -  "}
                                  </p>
                                </td>
                                <td>
                                  <a
                                    href={imageBaseUrl + item?.pg_attachement}
                                    target="_blank"
                                  >
                                    <p
                                      className="text-primary"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      PG Attachment
                                    </p>
                                  </a>
                                </td>
                              </tr>
                            )
                          )}

                          {/* <tr colSpan="9">
                        <td>
                          <h4>Remark</h4>
                          <p>{projectDetails?.remark}</p>
                        </td>
                      </tr> */}
                        </tbody>
                      </table>
                    )}
                    {projectDetails?.billingData?.length !== 0 ? (
                      <BillingDetailPdf
                        billingDetail={projectDetails?.billingData}
                      />
                    ) : (
                      ""
                    )}

                    {projectDetails?.billingData?.length !== 0
                      ? ""
                      : //  <table>
                        //       <thead>
                        //         <tr>
                        //           <th colSpan="5">BILLING & PAYMENT DETAILS</th>
                        //         </tr>
                        //       </thead>
                        //       <tbody>
                        //         {projectDetails?.billingData?.map((item, index) => (
                        //           <tr>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice No.
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_invoiceNo
                        //                   ? item?.bpd_invoiceNo
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice Date
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {" "}
                        //                 {formatDate(item?.bpd_invoiceDate)
                        //                   ? formatDate(item?.bpd_invoiceDate)
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice Quantity
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_invoiceQuantity
                        //                   ? item?.bpd_invoiceQuantity
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice Amount
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_invoiceAmount
                        //                   ? item?.bpd_invoiceAmount
                        //                   : " -  - - -"}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice Description
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_descriptionOfInvoice
                        //                   ? item?.bpd_descriptionOfInvoice
                        //                   : " -  - - -"}
                        //               </p>
                        //             </td>
                        //           </tr>
                        //         ))}
                        //         {projectDetails?.billingData?.map((item, index) => (
                        //           <tr>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Invoice Attachment
                        //               </h4>
                        //              { item?.bpd_attachmentOfInvoice?
                        //               <a
                        //                 style={{ margin: 0 }}
                        //                 href={
                        //                   imageBaseUrl + item?.bpd_attachmentOfInvoice
                        //                 }
                        //                 target="_blank"
                        //               >
                        //                 <p
                        //                   className="text-primary m-0"
                        //                   style={{ textDecoration: "underline" }}
                        //                 >
                        //                   Invoice Attachment
                        //                 </p>
                        //               </a>:<p> -  - - -</p>}
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Material Accepted/Rejected
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {" "}
                        //                 {item?.bpd_materialAcceptedRejected
                        //                   ? item?.bpd_materialAcceptedRejected
                        //                   : "-  - - -"}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Attached R Note
                        //               </h4>
                        //              {item?.bpd_attachedRNote?  <a
                        //                 style={{ margin: 0 }}
                        //                 href={imageBaseUrl + item?.bpd_attachedRNote}
                        //                 target="_blank"
                        //               >
                        //                 <p
                        //                   className="text-primary"
                        //                   style={{
                        //                     textDecoration: "underline",
                        //                     margin: 0,
                        //                   }}
                        //                 >
                        //                   R Note Attachment
                        //                 </p>
                        //               </a>:<p>-  - - -</p>}

                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 R Note No.
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_rNoteNo
                        //                   ? item?.bpd_rNoteNo
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 R Note Date
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {formatDate(item?.bpd_rNoteNoDate)
                        //                   ? formatDate(item?.bpd_rNoteNoDate)
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //           </tr>
                        //         ))}
                        //         {projectDetails?.billingData?.map((item, index) => (
                        //           <tr>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Payment Status
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_paymentStatus
                        //                   ? item?.bpd_paymentStatus
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Date
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {formatDate(item?.bpd_date)
                        //                   ? formatDate(item?.bpd_date)
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Amount Received
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_amountRecieved
                        //                   ? item?.bpd_amountRecieved
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 SD (Security Deposit)
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_sd_securityDeposite
                        //                   ? item?.bpd_sd_securityDeposite
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Penalty (Delay)
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_penalty
                        //                   ? item?.bpd_penalty
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //           </tr>
                        //         ))}
                        //         {projectDetails?.billingData?.map((item, index) => (
                        //           <tr>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Store Deposit
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_storeDeposite
                        //                   ? item?.bpd_storeDeposite
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Material Deducted
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_materialDeductied
                        //                   ? item?.bpd_materialDeductied
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Primery/ CESS Surcharge
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_primaryCessSurcharge
                        //                   ? item?.bpd_primaryCessSurcharge
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 TDS(Income Tax)
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_tds ? item?.bpd_tds : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 GST
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_gst ? item?.bpd_gst : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //           </tr>
                        //         ))}
                        //         {projectDetails?.billingData?.map((item, index) => (
                        //           <tr>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Other Deduction
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_otherDeduction
                        //                   ? item?.bpd_otherDeduction
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Total Deduction
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_totalDeduction
                        //                   ? item?.bpd_totalDeduction
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 Payment Due
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_paymentDue
                        //                   ? item?.bpd_paymentDue
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>

                        //             <td className="py-0">
                        //               <h4
                        //                 style={{ marginTop: "2rem" }}
                        //                 hidden={index !== 0}
                        //               >
                        //                 PO/LOA Completion Status
                        //               </h4>
                        //               <p style={{ margin: 0 }}>
                        //                 {item?.bpd_poloaCompletionStatus
                        //                   ? item?.bpd_poloaCompletionStatus
                        //                   : "  -  - - -  "}
                        //               </p>
                        //             </td>
                        //           </tr>
                        //         ))}
                        //       </tbody>
                        //     </table>
                        null}
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <button className="btn-danger btn" onClick={generatePdf}>
                  {" "}
                  Generate PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProjectsProjectDetail;
