import React, { useEffect, useState } from "react";
import CommonTable from "../Common/CommonTable";
import VehicleMasterModal from "../Modal/VehicleMasterModal";
import { listPurchaseVechileMasterApi } from "../purchaseApi";
import CommonTd from "../../Tender/Common/CommonTd";
import { RiDeleteBinLine } from "react-icons/ri";
import CommonPurchaseDelete from "../Modal/CommonPurchaseDelete";
import { FaRegEdit } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";

function VechileMasterList() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [vehileMasterData, setvehileMasterData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [url, setUrl] = useState("");

  function getVechileMasterList() {
    listPurchaseVechileMasterApi({ user_id })
      .then((response) => {
        // console.log("response" , response.data.response.VehcileMasterList)
        setvehileMasterData(response.data.response.VehcileMasterList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const tableHeading = [
    {
      name: "S. No",
      width: "3rem",
    },
    {
      name: "TRANSPORT NAME",
      width: "9.25rem",
    },
    {
      name: "VEHICLENO",
      width: "5.7rem",
    },
    {
      name: "VEHICLENAME",
      width: "10rem",
    },
    {
      name: "VEHICLEMODEL",
      width: "9rem",
    },
    {
      name: "TAX RENEWAL DATE",
      width: "9.95rem",
    },
    {
      name: "INSURANCE RENEWAL DATE",
      width: "13.79rem",
    },
    {
      name: "POLLUTION RENEWAL DATE",
      width: "15rem",
    },
    {
      name: "FITNESS RENEWAL DATE",
      width: "14rem",
    },
    {
      name: "DRIVERNAME",
      width: "10rem",
    },
    {
      name: "Edit",
      width: "1.95rem",
    },
    {
      name: "Delete",
      width: "3.33rem",
    },
  ];

  useEffect(() => {
    getVechileMasterList();
  }, []);

  const handleEditClick = (element) => {
    setUrl("updatepurchasevehiclemaster");

    setEditData(element);
    setShowModal(true);
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mt-3 mb-3">
              <div className="col-sm-6">
                <h4 className="page-title text-uppercase">Vehicle Master</h4>
              </div>
              <div className="col-sm-4 text-end">
                <div className="postion-relative">
                  <input
                    className="form-control w-75 ms-auto"
                    placeholder="Search"
                  />
                  <IoIosSearch
                    size={30}
                    className="position-absolute top-0 end-0 mt-1 me-2"
                    style={{ color: "ef4d56" }}
                  />
                </div>
              </div>
              <div className="col-sm-auto text-end">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowModal(true);
                    setUrl("addpurchasevehiclemaster");
                  }}
                >
                  Add New
                </button>
              </div>
            </div>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive table-container-scroll"
                    style={{ overflowX: "auto" }}
                  >
                    <table
                      className="table common-table"
                      style={{ tableLayout: "unset" }}
                    >
                      <CommonTable tableHeading={tableHeading}></CommonTable>
                      <tbody>
                        {vehileMasterData?.map((element, index) => (
                          <tr key={index}>
                            <CommonTd
                              width="3rem"
                              value={element.id}
                            ></CommonTd>
                            <CommonTd
                              width="9.25rem"
                              value={element.transporter_name}
                            ></CommonTd>
                            <CommonTd
                              width="5.7rem"
                              value={element.vehicle_no}
                            ></CommonTd>
                            <CommonTd
                              width="10rem"
                              value={element.vehicle_name}
                            ></CommonTd>
                            <CommonTd
                              width="9rem"
                              value={element.vehicle_model}
                            ></CommonTd>
                            <CommonTd
                              width="9.95rem"
                              value={element.tax_renewal_date}
                            ></CommonTd>
                            <CommonTd
                              width="13.79rem"
                              value={element.insurance_renewal_date}
                            ></CommonTd>
                            <CommonTd
                              width="15rem"
                              value={element.pollution_renewal_date}
                            ></CommonTd>
                            <CommonTd
                              width="14rem"
                              value={element.fitness_renewal_date}
                            ></CommonTd>
                            <CommonTd
                              width="10rem"
                              value={element.driver_name}
                            ></CommonTd>
                            <td>
                              <p
                                style={{
                                  minWidth: "1.95rem",
                                  maxWidth: "1.95rem",
                                  margin: "auto",
                                  padding: "auto",
                                  cursor: "pointer",
                                }}
                                className="text-center"
                              >
                                <FaRegEdit
                                  className="text-dark font-20"
                                  style={{ margin: "auto" }}
                                  onClick={() => handleEditClick(element)}
                                />
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  minWidth: "3.33rem",
                                  maxWidth: "3.33rem",
                                  margin: "auto",
                                  padding: "auto",
                                  cursor: "pointer",
                                }}
                                className="text-center"
                              >
                                <RiDeleteBinLine
                                  data-bs-toggle="modal"
                                  data-bs-target="#vehicleMasterDelete"
                                  className="text-danger font-20"
                                  style={{ margin: "auto" }}
                                  onClick={() => setDeleteId(element.id)}
                                />
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <VehicleMasterModal
        show={showModal}
        setShow={setShowModal}
        updatedList={getVechileMasterList}
        editData={editData}
        url={url}
      />
      <CommonPurchaseDelete
        group_id={deleteId}
        updatedList={getVechileMasterList}
        url={"deletepurchasevehiclemaster"}
        groupDelete={"vehicleMasterDelete"}
        idKey={"vehicle_master_id"}
      />
    </>
  );
}

export default VechileMasterList;
("");
