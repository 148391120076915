import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import { selectDropdowns } from "../../../redux/features/allDropdownSlice";
import { useSelector } from "react-redux";
import { getboardgroupstatus } from "../../../redux/api";
import { toast } from "react-toastify";
import { tenderTicketSchema } from "../../TenderSchema/tenderSchema";
import Select from "react-select";
import "../../TenderStyle/TenderStyle.css";

function AddTenderTicket({
  showTicketModal,
  setShowTicketModal,
  getKanbanTicket,
  boardGroup,
}) {
  const [validationError, setValidationError] = useState({});
  const [spinner, SetSpinner] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState("");
  const [minStartDate, setMinStartDate] = useState("");
  const [groupStatus, setGroupStatus] = useState([]);
  const me = "<<me>>";
  const allDropdowns = useSelector(selectDropdowns);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const departmentUsers = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender"
  );
  const userList = departmentUsers?.filter((user) => user.id !== user_id);
  const tenderList = allDropdowns?.tenderList;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const reportingPersonList = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender" || user.id === 5
  );

  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let initialValues = {
    tender_id: "",
    board_group_id: "",
    title: "",
    description: "",
    priority: "",
    estimated_time: "1",
    reporting_to: "",
    start_date: "",
    due_date: "",
    assignee: "",
    board_group_status_id: "",
    ticket_type: 8,
    required_qty: 8,
    ticket_attachement: "",
    due_time: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: tenderTicketSchema,
    onSubmit: (values) => {
      submitTicket(values);
    },
  });

  function getGroupStatusDropdown() {
    if (values.board_group_id) {
      getboardgroupstatus({
        user_id: user_id,
        board_group_id: values.board_group_id,
      })
        .then((response) => {
          setGroupStatus(response?.data?.response?.statusDropDown);
        })
        .catch((err) => {});
    }
  }

  function submitTicket(value) {
    let startDate = values.start_date
      ? `${values.start_date.getFullYear()}-${
          values.start_date.getMonth() + 1
        }-${values.start_date.getDate()}`
      : "";
    let dueDate = values.due_date
      ? `${values.due_date.getFullYear()}-${
          values.due_date.getMonth() + 1
        }-${values.due_date.getDate()}`
      : "";
    SetSpinner(true);
    let url = baseUrl + "storetenderticket";
    let data = { ...value, user_id, start_date: startDate, due_date: dueDate };
    axios
      .post(url, data, config)
      .then((response) => {
        SetSpinner(false);
        setShowTicketModal(false);
        getKanbanTicket();
        toast.success("Ticket created successfully");
      })
      .catch((error) => {
        SetSpinner(false);
        toast.error("Something went wrong, Please try again later");
      });
  }

  function getDate(id) {
    let tender = tenderList.find((e) => e.id === +id);
    setMaxEndDate(tender.closingDate);
    setMinStartDate(tender.publishedDate);
  }

  useEffect(() => {
    if (values.board_group_id) {
      getGroupStatusDropdown();
    }
  }, [showTicketModal, values.board_group_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Modal
        show={showTicketModal}
        onHide={() => setShowTicketModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-0">CREATE TICKET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row tender-ticket">
              <div
                className="col-md-7"
                style={{ maxHeight: "600px", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3 react-select">
                      <label className="form-label">
                        Tender<span className="text-danger">*</span>
                      </label>
                      <Select
                        placeholder="Select Tender"
                        className="form-select-control border-secondary"
                        options={tenderList}
                        getOptionLabel={(tenderList) => tenderList["tenderNo"]}
                        getOptionValue={(tenderList) => tenderList["id"]}
                        onChange={(value) => {
                          getDate(value.id);
                          setFieldValue("tender_id", value.id);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: "lightgrey",
                            primary: "lightgrey",
                          },
                        })}
                      />

                      {touched.tender_id && errors.tender_id ? (
                        <p className="text-danger m-0">{errors.tender_id}</p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.tender_id}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group ">
                      <label className="form-label">
                        Ticket Title<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control border-secondary"
                        placeholder="Enter Ticket"
                        onBlur={handleBlur}
                        value={values.title}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title ? (
                        <p className="text-danger m-0">{errors.title}</p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.title}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Board Group<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select border-secondary"
                      name="board_group_id"
                      onBlur={handleBlur}
                      value={values.board_group_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected hidden>
                        Select Board Group
                      </option>
                      {boardGroup?.map((element, index) => {
                        return (
                          <option key={index} value={Number(element.id)}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                    {touched.board_group_id && errors.board_group_id ? (
                      <p className="text-danger m-0">{errors.board_group_id}</p>
                    ) : (
                      <p className="text-danger m-0">
                        {validationError?.board_group_id}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Board Group Status<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select border-secondary"
                      name="board_group_status_id"
                      onBlur={handleBlur}
                      value={values.board_group_status_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected hidden>
                        Select Group Status
                      </option>
                      {groupStatus?.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                    {touched.board_group_status_id &&
                    errors.board_group_status_id ? (
                      <p className="text-danger m-0">
                        {errors.board_group_status_id}
                      </p>
                    ) : (
                      <p className="text-danger m-0">
                        {validationError?.board_group_status_id}
                      </p>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control border-secondary"
                      value={values.description}
                      name="description"
                      placeholder="Enter Ticket Description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.description && errors.description ? (
                      <p className="text-danger m-0">{errors.description}</p>
                    ) : (
                      <p className="text-danger m-0">
                        {validationError?.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5 bg-light py-2">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Reporting To<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary"
                          id=""
                          name="reporting_to"
                          onBlur={handleBlur}
                          value={values.reporting_to}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select a Reporting Person
                          </option>
                          {reportingPersonList?.map((element) => {
                            return (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.reporting_to && errors.reporting_to ? (
                          <p className="text-danger m-0">
                            {errors.reporting_to}
                          </p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.reporting_to}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Assignee<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary"
                          id=""
                          name="assignee"
                          onBlur={handleBlur}
                          value={values.assignee}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select an Assignee
                          </option>
                          <option value={user_id}>{me}</option>
                          {departmentUsers?.map((element) => {
                            return (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.assignee && errors.assignee ? (
                          <p className="text-danger m-0">{errors.assignee}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.assignee}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="StartDate" className="form-label w-50">
                        Start Date<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <DatePicker
                          type="date"
                          name="start_date"
                          placeholderText={new Date().toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                          className="form-control border-secondary w-100" 
                          onBlur={handleBlur}
                          selected={values.start_date}
                          onChange={(date) => setFieldValue("start_date", date)}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          startDate={values.start_date}
                          endDate={values.due_date}
                          minDate={new Date(minStartDate)}
                          maxDate={new Date(maxEndDate)}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        {touched.start_date && errors.start_date ? (
                          <p className="text-danger m-0">{errors.start_date}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.start_date}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label
                        htmlFor="CompletionDate"
                        className="form-label w-50"
                      >
                        Due Date<span className="text-danger">*</span>
                      </label>

                      <div className="w-100 d-block">
                        <DatePicker
                          type="date"
                          className="form-control border-secondary w-100"
                          name="due_date" 
                          placeholderText={new Date().toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                          onBlur={handleBlur}
                          selected={values.due_date}
                          onChange={(date) => setFieldValue("due_date", date)}
                          selectsEnd
                          startDate={values.start_date}
                          endDate={values.due_date}
                          minDate={values.start_date}
                          maxDate={new Date(maxEndDate)}
                          dateFormat="dd/MM/yyyy"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        {touched.due_date && errors.due_date ? (
                          <p className="text-danger m-0">{errors.due_date}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.due_date}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="" className="form-label w-50">
                        Due Time<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <input
                          className="form-control"
                          type="time"
                          min="00:00"
                          max="23:59"
                          name="due_time"
                          value={values.due_time}
                          onChange={handleChange}
                        ></input>
                        {touched.due_time && errors.due_time ? (
                          <p className="text-danger m-0">{errors.due_time}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.due_time}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Priority<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary "
                          name="priority"
                          onBlur={handleBlur}
                          value={values.priority}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select Ticket Priority
                          </option>
                          <option value="critical">Critical</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                          <option value="future">Future</option>
                        </select>
                        {touched.priority && errors.priority ? (
                          <p className="text-danger m-0">{errors.priority}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.priority}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 mb-3">
                    <div className="form-group d-flex">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Estimated Time<span className="text-danger">*</span>
                      </label>
                      <div className="w-100 d-block">
                        <input
                          type="type"
                          name="estimated_time"
                          onBlur={handleBlur}
                          className="form-control border-secondary w-100"
                          placeholder="In Hours"
                          value={values.estimated_time}
                          onChange={handleChange}
                        />
                        {touched.estimated_time && errors.estimated_time ? (
                          <p className="text-danger m-0">
                            {errors.estimated_time}
                          </p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.estimated_time}
                          </p>
                        )}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Creating Ticket...</p>
                </div>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setShowTicketModal(false)}
                  >
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Add Ticket
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTenderTicket;
