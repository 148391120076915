import React from "react";

function AddRateList({ values, handleChange, errors, touched }) {
  return (
    <div>
      <div className="row">
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Purchase Price{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Purchase Price"
            onChange={handleChange}
            value={values.purchase_price}
            name="purchase_price"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            MRP{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter MRP "
            onChange={handleChange}
            value={values.mrp}
            name="mrp"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST(%){""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter GST(%)"
            onChange={handleChange}
            value={values.gst}
            name="gst"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Date{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Date"
            type="date"
            onChange={handleChange}
            value={values.gst_date}
            name="gst_date"
          />
        </div>
      </div>
    </div>
  );
}

export default AddRateList;
