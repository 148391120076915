import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate} from "react-router-dom";
import "../../HrDepartment.scss"
import ShowError from "../organisationStructure/OrganizationComponents/ShowError";
import { addEmployee ,employeeList} from "../../hrApi";
import { toast } from "react-toastify";
import { compensationSchema } from "../AddEmployeeSchema";
import {
  Box,
  CircularProgress} from "@mui/material";

const CompensationsForm = ({formStage,setFormStage,setActiveKey,combinedDetails, validationError,
  setValidationError}) => {

  const initialValues = {

    pay_group: "",
    annual_salary: "",
    enable_payroll: false,
    payroll_settings: [],
    salary_structure: "",
    tax_regime: "",
  };
  const [isFocused, setIsFocused] = useState("");
  const [ischecked, setIsChecked] = useState(false);
  const[loader,setLoader] = useState(false)
  const[IsPayrollEnabled,setIsPayrollEnabled] = useState(false)

  const[CheckedItems,setCheckedItems] = useState([])
  const[payRollError,setPayRollError] = useState(false)
  const navigate = useNavigate();
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
   
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: compensationSchema,
    onSubmit: (values) => {
      
    if(IsPayrollEnabled &&(CheckedItems?.length==0)){
      setPayRollError(true)
      
    }
    else{addEmployeeApi(values)}
      
    },
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setCheckedItems((prev) => {
      const updatedItems = checked
        ? [...prev, id]
        : prev.filter(item => item !== id);
  
      // Assuming setFieldValue is available in your context
      setFieldValue("payroll_settings", updatedItems);
      setPayRollError(false)
      return updatedItems;
    });
  };
  const handlePayrollCheckChange = (event) => {
    setIsPayrollEnabled(event.target.checked);
    setFieldValue("enable_payroll",event.target.checked)
  };
  const handleBlur = () => {
    setIsFocused("");
  };
    async function addEmployeeApi(value) {
    setLoader(true);
    const payload ={...combinedDetails,...value}
    try {
      const res = await addEmployee(payload);
   
      toast.success("Employee added successfully",{autoClose:1000});
   
   
      resetForm();
      navigate("/emp_Directory")
    } catch (error) {
      setValidationError(error?.response?.data?.response)
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row justified-content-between mx-2">
          <div className="col-5 ">
            <label
              htmlFor=""
              className={`form-label fw-bold font-12 font-weight-500  ${
                isFocused == "pay_group" ? "text-danger" : "text-black"
              }`}
              style={{
                paddingLeft: ".75rem",
                marginTop: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Pay Group
            </label>
            <div
              className={`${
                isFocused == "pay_group"
                  ? "add_emp_input_active"
                  : "add_emp_input"
              }`}
            >
              <select
                name="pay_group"
                style={{ paddingLeft: ".75rem" }}
                className="form-control form-control-sm border-secondary"
                placeholder="Select"
                onFocus={(event) => handleFocus(event)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option>Select</option>
                <option>N/a</option>
              </select>
              <ShowError
                      touched={touched.pay_group}
                      message={errors.pay_group}
                      validationError={validationError?.pay_group}
                    />
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-5 ">
            <label
              htmlFor=""
              className={`form-label fw-bold font-12 font-weight-500  ${
                isFocused == "annual_salary" ? "text-danger" : "text-black"
              }`}
              style={{
                paddingLeft: ".75rem",
                marginTop: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Annual Salary
            </label>
            <div
              className={`${
                isFocused == "annual_salary"
                  ? "add_emp_input_active"
                  : "add_emp_input"
              }`}
            >
              <input
                type="text"
                name="annual_salary"
                style={{ paddingLeft: ".75rem" }}
                className="form-control form-control-sm border-secondary"
                onFocus={(event) => handleFocus(event)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
               <ShowError
                      touched={touched.annual_salary}
                      message={errors.annual_salary}
                      validationError={validationError?.pay_group}
                    />
            </div>
          </div>
          <div className="col-6 ms-1">
            <div class="form-check">
              <input
                style={{ marginTop: "1.5rem" }}
                className="form-check-input"
                type="checkbox"
                value=""
                id="enable_payroll"
                onChange={(event) => {
                  handlePayrollCheckChange(event);
                }}
              />
              <label
                className="form-check-label checkbox-label"
                for="payroll"
                style={{ paddingLeft: "-1.25rem", marginTop: "1.3rem" }}
              >
                Enable payroll for this employee
              </label>
            </div>
          </div>
          <div className="col-6"></div>
         {IsPayrollEnabled&&<><h6 className="payroll_setting ms-3">Payroll Settings</h6>
          <div className=" mb-3">
            <div className="row">
              <div className="col-xl-4  col-lg-12  col-sm-12 form-check">
                <input
                  className="form-check-input ms-3"
                  type="checkbox"
                  value=""
                  id="PF"
                  onChange={(event) => {
                    handleCheckboxChange(event);
                  }}
                />
                <label
                  className={`form-check-label checkbox-label ms-2 ${
                    ischecked && activeCheckboxId == "PF"
                      ? "active-checkbox"
                      : ""
                  }`}
                  for="PF"
                >
                  Provident Fund (PF)eligible
                </label>
              </div>

              <div className="col-xl-3 col-lg-12 col-sm-12  form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="ESI"
                  onChange={(event) => {
                    handleCheckboxChange(event);
                  }}
                />
                <label
                  className={`form-check-label checkbox-label ${
                    ischecked && activeCheckboxId == "ESI"
                      ? "active-checkbox"
                      : ""
                  }`}
                  for="ESI"
                >
                  ESI eligible
                </label>
              </div>

              <div className="col-xl-3 col-lg-12 col-sm-12  form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="LWF"
                  onChange={(event) => {
                    handleCheckboxChange(event);
                  }}
                />
                <label
                  className={`form-check-label checkbox-label ${
                    ischecked && activeCheckboxId == "LWF"
                      ? "active-checkbox"
                      : ""
                  }`}
                  for="LWF"
                >
                  LWF eligible
                </label>
              </div>
              <div className="col-lg-3"></div>
   
            </div>
            {payRollError==true? <p className="text-danger m-0">This field is Required</p>:""}
          </div></>}
<div className="row">
          <div className="col-5 ">
            <label
              htmlFor=""
              className={`form-label fw-bold font-12 font-weight-500  ${
                isFocused == "salary_structure" ? "text-danger" : "text-black"
              }`}
              style={{
                paddingLeft: ".75rem",
                marginTop: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Salary Structure Type
            </label>
            <div
              className={`${
                isFocused == "salary_structure"
                  ? "add_emp_input_active"
                  : "add_emp_input"
              }`}
            >
              <select
                name="salary_structure"
                style={{ paddingLeft: ".75rem" }}
                className="form-control form-control-sm border-secondary"
                placeholder="Select"
                onFocus={(event) => handleFocus(event)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option>Select</option>
                <option>N/A</option>
              </select>
              <ShowError
                      touched={touched.salary_structure}
                      message={errors.salary_structure}
                    />
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-5 ">
            <label
              htmlFor=""
              className={`form-label fw-bold font-12 font-weight-500  ${
                isFocused == "tax_regime" ? "text-danger" : "text-black"
              }`}
              style={{
                paddingLeft: ".75rem",
                marginTop: "1rem",
                fontFamily: "Poppins",
              }}
            >
              Tax Regime to consider
            </label>
            <div
              className={`${
                isFocused == "tax_regime"
                  ? "add_emp_input_active"
                  : "add_emp_input"
              }`}
            >
              <select
                name="tax_regime"
                style={{ paddingLeft: ".75rem" }}
                className="form-control form-control-sm border-secondary"
                placeholder="Select"
                onFocus={(event) => handleFocus(event)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option>Select</option>
                <option>N/A</option>
              </select>
              <ShowError
                      touched={touched.tax_regime}
                      message={errors.tax_regime}
                    />
            </div>
          </div>
          </div>
        </div>
        <div class="row pt-4">
          <div className="col-7"></div>
          <div
            className="col-5 ps-7 "
            style={{ marginLeft: "Auto", width: "auto" }}
          >
            <button
              type="button"
              class="btn btn-de-danger btn-sm border-danger"
              onClick={() => {
                navigate(-1)
              }}
            >
              Cancel
            </button>
            <button type="Submit" class="hr btn btn-primary mx-2"disabled={loader}>
            {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) :"Create Profile"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CompensationsForm;
