import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { toast } from "react-toastify";
import { getProjects, getAdminDropDown, getUserDropdown } from "../redux/api";
import Select from "react-select";
import "./Style.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import { departSchema } from "../schemas/Index";

const initialValues = {
  name: "",
  email: "",
  image: "",
  department_head: "",
  description: "",
  department_project: [],
};

function ProjectsDepartAdd() {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [departHead, setDepartHead] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [departmentHead, setDepartmentHead] = useState([]);
  const [description, setDescription] = useState(" ");
  const [image, setImage] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [departmentError, setDepartmentError] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;

  function getAllDropdownList() {
    getUserDropdown({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setDepartHead(res?.data?.response?.userList);
      })
      .catch((err) => {
      });
    getProjects({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setProjects(res?.data?.response?.projectSelect);
      })
      .catch((err) => {      });
  }

  const handleImageChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setImage(data.result);
      values.image = data.result;
      setFieldValue(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  };

  const {
    touched,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: departSchema,
    onSubmit: (values) => {
      addDepartment(values);
    },
  });

  const addDepartment = (value) => {
    setSpinner(true);
    const apiurl = baseUrl + "storedepartment";
    const data = { user_id, ...value, accessType: "add" };
    axios
      .post(apiurl, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSpinner(false);
          navigate("/department");
          toast.success("Department Added Successfully");
        }
      })
      .catch((error) => {
        setDepartmentError(error?.response?.data?.response);
        setSpinner(false);
      });
  };

  function departHeadChange(v) {
    values.department_head = v.id;
    setFieldValue(v.id);
  }

  function departProjectChange(v) {
    values.department_project = v.map((e) => e.id);
    setFieldValue(v.map((e) => e.id));
  }

  useEffect(() => {
    getAllDropdownList();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/department">Department /</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Create Department
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Create Department</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} className="department-form">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                              maxLength={40}
                              className="form-control"
                              placeholder="Enter Department  Name"
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.name && errors.name
                                ? errors.name
                                : departmentError?.name?.[0]}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                              maxLength={40}
                              placeholder="Ex: abc@abc.com"
                              className="form-control"
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.email && errors.email
                                ? errors.email
                                : departmentError?.email?.[0]}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="department_head"
                              className="form-label"
                            >
                              Department Head
                            </label>
                            <Select
                              placeholder="Search Department Head"
                              name="department_head"
                              options={departHead}
                              getOptionLabel={(departHead) =>
                                departHead["name"]
                              }
                              getOptionValue={(departHead) => departHead["id"]}
                              onChange={(value) => departHeadChange(value)}
                              onBlur={handleBlur}
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.department_head && errors.department_head
                                ? errors.department_head
                                : departmentError?.department_head?.[0]}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="image" className="form-label">
                              Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="image"
                              name="image"
                              onChange={handleImageChange}
                              onBlur={handleBlur}
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.image && errors.image
                                ? errors.image
                                : departmentError?.image?.[0]}
                            </p>
                          </div>
                        </div>
                        {image ? (
                          <div className="col-md-2">
                            <div className="form-group mb-3">
                              <img
                                src={image}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-10">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="department_project"
                              className="form-label"
                            >
                              Department Projects
                            </label>
                            <Select
                              placeholder="Search Project"
                              isMulti
                              name="deaprtment_project"
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  zIndex: "1000",
                                }),
                              }}
                              options={projects}
                              getOptionLabel={(projects) =>
                                projects["project_title"]
                              }
                              getOptionValue={(projects) => projects["id"]}
                              onChange={(value) => departProjectChange(value)}
                            />
                            {/* <p className="text-danger mb-0 mt-2">{departmentError?.department_project?.[0]}</p> */}
                          </div>
                        </div>

                        {/* <div className="col-md-10">
                          <div className="form-group mb-3">
                            <label htmlFor="description" className="form-label">
                              Description
                            </label>
                            <CKEditor
                              style={{ zIndex: "0" }}
                              editor={ClassicEditor}
                              data={values.description}
                              placeholder="Description"
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescription(data);
                                values.description = data
                                setFieldValue(data)
                              }}
                            />
                            <p className="text-danger mb-0 mt-2">{touched.description && errors.description ? errors.description :departmentError?.description?.[0]}</p>
                          </div>
                        </div> */}
                        <div className="col-md-10">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="description"
                              className="form-label d-block"
                            >
                              Description
                            </label>
                            <textarea
                              name="description"
                              className="form-control"
                              value={values.description}
                              placeholder="Description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.description && errors.description
                                ? errors.description
                                : departmentError?.description?.[0]}
                            </p>
                          </div>
                        </div>
                      </div>

                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Creating Department...</p>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-3 mt-3"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm me-3 mt-3 border-danger "
                            onClick={() => navigate("/department")}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProjectsDepartAdd;
