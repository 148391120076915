import React,{useState,useEffect} from "react";

import JobWorkTimeCommonTable from "../../MaterialUiComponent/JobWorkTimeCommonTable";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, setLoading } from "../../../redux/features/Hr/LoaderSlice";
import { fetchWorkerType, getWorkerType } from "../../../redux/features/Hr/WorkerTypeSlice";
import { toast } from "react-toastify";
import { deleteWorkerType } from "../../hrApi";
import { toTitleCase } from "../../CommonFunction";

const WorkerTypeTabMain = ({ getEditData={getEditData}}) => {
  const dispatch = useDispatch();

  const loader = useSelector(selectLoading);
  const workerTypeList = useSelector(getWorkerType);
  const [deleteModalOPen, setDeleteModalOPen] = useState(false);

  useEffect(() => {
    dispatch(fetchWorkerType(dispatch));
  }, []);
  const handleClose = () => {
    setDeleteModalOPen(false);
  };
  const columns = [
    { id: "name", label: "Type", minWidth: "6rem" ,format:toTitleCase}, // Increase width
    { id: "description", label: "Description", minWidth: "25rem" }, // Increase width
    { id: "appliesTo", label: "Applies To", minWidth: "8rem" },
    { id: "min_contract_duration", label: "Min Contract Limit", minWidth: "8rem" },
    { id: "max_contract_duration", label: "Max Contract Limit", minWidth: "8rem" },
    // Increase width
    { id: "actions", label: "Action", minWidth: "8rem" }, // Keep as is
  ];


  const workerTypeDelete = async (id) => {
    dispatch(setLoading(true));
    const reqpayload = { id: id };
    try {
      const response = await deleteWorkerType(reqpayload);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchWorkerType());
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <section className="inProbation">
      <div className="bg-white mt-2 rounded mx-3">
        <div className="d-flex justify-content-between align-items-center px-3 mb-3">
          <h4 className="text-uppercase">Worker Type</h4>
          <div className="d-flex align-items-center">
            <input
              className="days_selector_style mb-lg-2 mb-3 mr-lg-3"
              type="text"
              placeholder="Search.."
            />
            <button className="search_Button" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
        <JobWorkTimeCommonTable columns={columns} rows={workerTypeList}  getEditData={getEditData}  deleteData={workerTypeDelete}
          loader={loader}
          handleClose={handleClose}
          deleteModalOPen={deleteModalOPen}
          setDeleteModalOPen={setDeleteModalOPen}/>
      </div>
    </section>
  );
};

export default WorkerTypeTabMain;
