import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";
import "../Tender/TenderStyle/TenderStyle.css";
import { Spinner } from "react-bootstrap";
import CommonTh from "../Tender/Common/CommonTh";
import CommonTd from "../Tender/Common/CommonTd";

function TenderList() {
  const navigate = useNavigate();
  const [tenderData, setTenderData] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [allAttachments, setAllAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tenderId, setTenderId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const [searchData, setSearchData] = useState([]);
  const [year, setYear] = useState("");
  const [contractFilterValue, setContractFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [titleModal, setTitleModal] = useState(false);
  const [titleData, setTitleData] = useState("");
  const [month, setMonth] = useState("");
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  var currentDate = new Date();
  var currentMonthFormatted = currentDate.toLocaleString("en-US", {
    month: "2-digit",
    minimumIntegerDigits: 2,
  });


  function formatDate(value) {
    var date = new Date(value);
    if (value) {
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return formattedDate;
  }

  async function tenderDataFetch() {
    setLoading(true);
    let url = baseUrl + "gettenderlist";
    await axios
      .post(url, { user_id, search: null, year, month }, config)
      .then((res) => {
        if (res.data.response.isSuccess) {
          setTenderData(res.data.response.TendersList);
          setSearchData(res.data.response.TendersList);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("something went wrong");
      });
  }

  async function DeleteTender() {
    let url = baseUrl + "setdeleteTender";
    await axios
      .post(url, { user_id, tenderId }, config)
      .then((res) => {
        tenderDataFetch();
        toast.success("Tender Deleted Sucessfully");
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  }


  async function getAttachments(tenderAttachment) {
    setAllAttachments(tenderAttachment);
    setAttachmentModal(true);
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  function deleteAttachment(id, index) {
    let url = baseUrl + "setDeleteTenderAttechment";
    const data = { user_id, attachmentId: id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        toast.success("Deleted Successfully");
        const modifiedAttachment = [...allAttachments];
        modifiedAttachment.splice(index, 1);
        setAllAttachments(modifiedAttachment);
        tenderDataFetch();
        setConfirmDelete(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setConfirmDelete(false);
      });
  }
  useEffect(() => {
    const filteredResult = tenderData.filter(
      (tenderValue) =>
        tenderValue.tenderNo
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        tenderValue.itemDecsription
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setSearchData(filteredResult);
  }, [searchValue]);

  useEffect(() => {
    tenderDataFetch();
  }, [year, month]);

  useEffect(() => {
    const filteredResult = tenderData?.filter((tenderList) => {
      return tenderList?.contractType
        ?.toLowerCase()
        .includes(contractFilterValue.toLowerCase());
    });
    setSearchData(filteredResult);
  }, [contractFilterValue]);

  useEffect(() => {
    setMonth(currentMonthFormatted);
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center pb-5"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <>
          <div className="card-header">
            <div className="row">
              <div className="col-2">
                <h3 className="card-title font-20 d-flex align-items-center">
                  TENDER LIST
                </h3>
              </div>

              <div className="col-10 d-flex justify-content-end">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-2">
                    <select
                      className="form-control border-danger"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Sort by Year
                      </option>
                      <option value="">All Tenders</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      className="form-control border-danger"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Sort by Month
                      </option>
                      <option value="">All Tenders</option>
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      className="form-control border-danger"
                      value={contractFilterValue}
                      onChange={(e) => setContractFilterValue(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Sort by Contract Type
                      </option>
                      <option value="">All Contracts</option>
                      <option value="Works">Works</option>
                      <option value="Store">Stores</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <div className="input-group me-3">
                      <input
                        type="text"
                        name="example-input1-group2"
                        className="form-control form-control-sm border-danger"
                        placeholder="Search by Title or Number"
                        style={{ fontSize: "1rem" }}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="table-responsive table-container-scroll"
            style={{ overflowX: "auto" }}
          >
            <table
              className="table tender-list-table"
              style={{ tableLayout: "unset" }}
            >
              <thead className="thead-light">
                <tr>
                  <CommonTh value="S No." width="3rem"></CommonTh>
                  <CommonTh value="Created Date"></CommonTh>
                  <CommonTh value="Railway Zone"></CommonTh>
                  
                  <CommonTh value="Sub Divison"></CommonTh>
                  <CommonTh value="Tender No." width="7.4rem"></CommonTh>
                  <CommonTh value="Tender Title"></CommonTh>
                  <CommonTh value="Published Date" width="7.4rem"></CommonTh>
                  <CommonTh value="Closing Date" width="7.4rem"></CommonTh>
                  <CommonTh value="Closing Time" width="4.6rem"></CommonTh>
                  <CommonTh value="Tender Type" width="5rem"></CommonTh>
                  <CommonTh value="Contract Type" width="5.4rem"></CommonTh>
                  <CommonTh value="Bidding System" width="8rem"></CommonTh>
                  <CommonTh value="Inspection Agency" width="8rem"></CommonTh>
                  <CommonTh value="Approving Agency " width="8rem"></CommonTh>
                  <CommonTh value="Quantity" width="5.4rem"></CommonTh>
                  <CommonTh value="UOM" width="7.4rem"></CommonTh>
                  <CommonTh value="Validity of offer" width="7.4rem"></CommonTh>
                  <CommonTh value="Advertised Value" width="7.8rem"></CommonTh>
                  <CommonTh
                    value="Tender Document Cost (TDC)"
                    width="8.5rem"
                  ></CommonTh>
                  <CommonTh value="Amount (TDC)" width="8rem"></CommonTh>
                  <CommonTh
                    value="Earnest Money Deposit (EMD)"
                    width="9rem"
                  ></CommonTh>
                  <CommonTh value="Amount (EMD)" width="8.5rem"></CommonTh>
                  <CommonTh value="Payment Terms" width=""></CommonTh>
                  <CommonTh value="Eligibility Creteria"></CommonTh>
                  <CommonTh
                    value="Higher Authority Approval"
                    width="7.4rem"
                  ></CommonTh>
                  <CommonTh
                    value="Intending Participation"
                    width="8rem"
                  ></CommonTh>
                  <CommonTh value="Bidding Status" width="8rem"></CommonTh>
                  <CommonTh value="Validity Extension" width="8rem"></CommonTh>
                  <CommonTh
                    value="Period of Completion (Days)"
                    width="8rem"
                  ></CommonTh>
                  <CommonTh
                    value="Technical Eligibility"
                    width="8rem"
                  ></CommonTh>
                  <CommonTh value="RA Participation" width="8rem"></CommonTh>
                  <CommonTh value="RA Date" width="8rem"></CommonTh>
                  <CommonTh value="RA Result" width="8rem"></CommonTh>
                  <CommonTh value="Date of Negotiation" width="8rem"></CommonTh>
                  <CommonTh value="Remark"></CommonTh>
                  <CommonTh value="Price Sheet" width="4rem"></CommonTh>
                  <CommonTh value="Attachments" width="7.8rem"></CommonTh>
                </tr>
              </thead>
              <tbody>
                {searchData?.map((tender, i) => (
                  <tr
                    key={i}
                    style={
                      tender.biddingStatus === "Not Awarded"
                        ? { backgroundColor: "#FA7070DB " }
                        : tender.biddingStatus === "Awarded"
                        ? { backgroundColor: "#a8e28d" }
                        : tender.biddingStatus === "Bidded"
                        ? { backgroundColor: "#fdec8b" }
                        : tender.biddingStatus === "Not Bidded"
                        ? { backgroundColor: "#dcdfd8" }
                        : { backgroundColor: "" }
                    }
                  >
                    <th scope="row">
                      <h6 style={{ minWidth: "3rem", maxWidth: "3rem" }}>
                        {i + 1}
                      </h6>
                    </th>
                    <>
                      <CommonTd value={tender.railwayZoneName}></CommonTd>
                      <CommonTd value={tender.subDivisionName}></CommonTd>
                      <CommonTd
                        value={tender.tenderNo}
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd value={tender.itemDecsription}></CommonTd>
                      <CommonTd
                        value={formatDate(tender.publishedDate)}
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={formatDate(tender.closingDate)}
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd value={tender.time} width="4.6rem"></CommonTd>
                      <CommonTd
                        value={tender.tenderType}
                        width="5rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.contractType}
                        width="5.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.biddingSystem}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.inspectionAgencyName}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.approvingAgency}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.quantity}
                        width="5.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.uonName}
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={
                          tender.validityOfOffer &&
                          tender.validityOfOffer + " Days"
                        }
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={formatNumber(tender.advertisedValue)}
                        width="7.8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.tenderDocCost}
                        width="8.5rem"
                      ></CommonTd>
                      <CommonTd
                        value={formatNumber(tender.ifApplicableAmountCost)}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd value={tender.EMD} width="9rem"></CommonTd>
                      <CommonTd
                        value={formatNumber(tender.ifApplicableAmountEmd)}
                        width="8.5rem"
                      ></CommonTd>
                      <CommonTd value={tender.paymentTerms}></CommonTd>
                      <CommonTd value={tender.eligibilityCriteria}></CommonTd>
                      <CommonTd
                        value={tender.higherAuthorityapproval}
                        width="7.4rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.intendingParticipation}
                        width="8rem"
                      ></CommonTd>
                      <td>
                        <h6
                          style={{ minWidth: "8rem", maxWidth: "8rem" }}
                          className={
                            tender.biddingStatus === "Bidded"
                              ? "m-0 badge badge-soft-warning font-16 fw-bold py-2"
                              : tender.biddingStatus === "Not Bidded"
                              ? "m-0 badge badge-soft-secondary font-16 fw-bold py-2"
                              : tender.biddingStatus === "Awarded"
                              ? "m-0 badge badge-soft-success font-16 fw-bold py-2"
                              : tender.biddingStatus === "Not Awarded"
                              ? "m-0 badge badge-soft-danger font-16 fw-bold py-2"
                              : ""
                          }
                        >
                          {tender.biddingStatus ? tender.biddingStatus : "---"}
                        </h6>
                      </td>

                      <CommonTd
                        value={
                          tender.validityExtension &&
                          tender.validityExtension + " Days"
                        }
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={
                          tender.periodOfCompletion &&
                          tender.periodOfCompletion + " Days"
                        }
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.tenchnicalEligibility}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={tender.RAPaticipation}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd
                        value={formatDate(tender.RADate)}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd value={tender.RAResult} width="8rem"></CommonTd>
                      <CommonTd
                        value={formatDate(tender.DateOfNagotiation)}
                        width="8rem"
                      ></CommonTd>
                      <CommonTd value={tender.remarks}></CommonTd>
                      <td>
                        {tender.priceSheet ? (
                          <Link
                            to={attachmentBaseUrl + tender.priceSheet}
                            target="_blank"
                          >
                            <h6 style={{ minWidth: "4rem", maxWidth: "4rem" }}>
                              <FaEye
                                style={{
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  cursor: "pointer",
                                }}
                              />
                            </h6>
                          </Link>
                        ) : (
                          <h6 style={{ minWidth: "4rem", maxWidth: "4rem" }}>
                            ---
                          </h6>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          getAttachments(tender.tenderattachements)
                        }
                      >
                        <h6 style={{ minWidth: "7.8rem", maxWidth: "7.8rem" }}>
                          <FaEye
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </h6>
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Delete Model start */}
          <div className="modal fade" id="modalDelete">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body py-5 text-center">
                  <h4 className="lh-base mb-4">
                    Are you sure you want to <br /> delete this record
                  </h4>
                  <button
                    type="button"
                    className="btn btn-success me-3"
                    onClick={() => DeleteTender()}
                    data-bs-dismiss="modal"
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    // onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Modal show={titleModal} onHide={() => setTitleModal(false)} centered>
            <Modal.Header closeButton className="m-0 p-3">
              <Modal.Title className="m-0 p-0">Tender Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <h5>{titleData}</h5>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={attachmentModal}
            onHide={() => setAttachmentModal(false)}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="m-0">Tender Attachments</Modal.Title>
            </Modal.Header>
            <Modal.Body className="fw-bold text-center font-20">
              <div className="row">
                {allAttachments.length
                  ? allAttachments?.map((e, i) => {
                      return (
                        <div key={i} className="col-6">
                          {confirmDelete ? (
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <p className="m-0 text-dark">Are you sure?</p>
                              <div>
                                <button
                                  className="btn-danger btn-sm fw-bold me-3"
                                  onClick={() => deleteAttachment(e.id, i)}
                                >
                                  Yes
                                </button>
                                <button
                                  className="btn-warning btn-sm fw-bold"
                                  onClick={() => setConfirmDelete(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <a
                                href={attachmentBaseUrl + e.name}
                                target="_blank"
                                className="text-start "
                                rel="noopener noreferrer"
                              >
                                Attachment {i + 1}
                              </a>
                              <i
                                className="las la-trash-alt font-20 px-1 text-dark"
                                style={{ cursor: "pointer" }}
                                onClick={() => setConfirmDelete(true)}
                              ></i>
                            </div>
                          )}

                          <Link to={attachmentBaseUrl + e.name} target="_blank">
                            {e.name?.endsWith(".pdf") ? (
                              <iframe
                                title={`Attachment ${i}`}
                                src={attachmentBaseUrl + e.name}
                                style={{ border: "1px solid #ccc" }}
                                frameBorder={0}
                                border="0"
                                className="w-100"
                              ></iframe>
                            ) : (
                              <div>
                                <img
                                  src={attachmentBaseUrl + e.name}
                                  className="w-100"
                                  style={{
                                    objectFit: "cover",
                                    aspectRatio: "1/1",
                                  }}
                                ></img>
                              </div>
                            )}
                          </Link>
                          {/* Add more conditions for different document types */}
                        </div>
                      );
                    })
                  : "No Attachment on this tender"}
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default TenderList;
