export function fileToBase64(filePath, name, updaterFunction) {
  if (filePath) {
    const reader = new FileReader();
    reader.readAsDataURL(filePath);
    reader.onload = () => {
      const base64 = reader.result;
      updaterFunction(name, base64);
    };
  }
}
