import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import MaterialStatus from "./MaterialStatus";
import { useParams } from "react-router-dom";
const MaterialItemDetail = ({ projectDropDown, listMaterialInStatus,detail }) => {
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const [selectedproject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(id);
  const [poDetail, setPoDetail] = useState([]);
  const [projectPO, setProjectPO] = useState([]);
  const [selectedPO, setSelectedPO] = useState(null);
  // const [partialModal, setPartialModal] = useState(false);
 
  const [projectNameBoolean, setProjectNameBoolean] = useState(false);


  function formatNumber(value) {
    if (!value) {
      return false;
    }
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }
  const handleProjectChange = (selectedOption) => {
    setProjectNameBoolean(true);
    setSelectedPO(null);
    setPoDetail([]);
    setSelectedProject(selectedOption);
    setSelectedProjectId(selectedOption?.id);

    projectPoDetail(selectedOption?.id);
    // setFieldValue
  };
  const projectPoDetail = (project_id) => {
    const data = { user_id, project_id };
    const url = `${baseUrl}fetchpodetailsproject`;
    setProjectPO([]);
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setProjectPO(res?.data?.response?.PurchaseOrderDetails);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };
  const pOItemDetail = () => {
    const data = { user_id, project_id: selectedProjectId };
    const url = `${baseUrl}getpurchaseordersproject?search=${selectedPO}`;
    setPoDetail([]);
    axios
      .post(url, data, config)
      .then((res) => {
  
        
        if (res?.status == 200) {
         
          setPoDetail(res?.data?.response?.PurchaseOrdersProject);
        }
      })
      .catch((err) => toast.error("something went wrong",{ autoClose: 2000}));
  };

  useEffect(() => {
    const project = projectDropDown.find((item) => item?.id == id);
    if (projectNameBoolean == false) {
      setSelectedProject(project);
    }

//     if(detail){
// setSelectedProject({id:detail?.project_id,project_title:detail?.project?.project?.pd_projectTitle})}
    projectPoDetail(selectedProjectId);
    if (selectedProjectId && selectedPO) {
      pOItemDetail();
    }
  }, [selectedProjectId, selectedPO]);

  return (
    <>
      <form action="">
        <div className="row">
          <div className="col-md-5">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Project
              </label>
              <div class="input-group search_matarial">
                <Select
                  placeholder="Search By Title"
                  style={{ width: "75%" }}
                  className="form-select-control border-secondary"
                  name="project_id"
                  isSearchable={true}
                  options={projectDropDown}
                  getOptionLabel={(projectDropDown) =>
                    projectDropDown["project_title"]
                  }
                  getOptionValue={(projectDropDown) => projectDropDown["id"]}
                  value={selectedproject}
                  onChange={handleProjectChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgrey",
                      primary: "lightgrey",
                    },
                  })}
                />

                <button type="button" class="btn btn-primary btn-sm">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                PO Number
              </label>
              <select
                name="po_no"
                value={selectedPO}
                onChange={(e) => {
                  setSelectedPO(e.target.value);
                }}
                className="form-control"
              >
                <option value="">Select</option>
                {projectPO?.map((projectPO) => (
                  <option
                    key={projectPO?.id}
                    value={projectPO?.purchase_order_no}
                  >
                    {projectPO?.purchase_order_no}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Supplier Name
              </label>
              <input
                className="form-control"
                name="supplier_contact_person
            "
                value={poDetail?.[0]?.supplier_contact_person}
              ></input>
            
            </div>
          </div>
        </div>
        {selectedProjectId &&
        selectedPO &&
        poDetail?.[0]?.item_details?.length ? (
          <div className="row pt-4">
            <table class="table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    Item name
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    HSN code
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    Qunatity
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    UNIT
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    RATE
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    Inward doc no.
                  </th>
                  <th
                    scope="col"
                    style={{
                      width: "5%",
                      color: "#b9b9b9",
                      fontSize: "0.9rem",
                    }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {poDetail?.[0]?.item_details?.map((item) => (
                  <tr key={item?.id}>
                    <th scope="row">
                      {item?.item_master_details?.item_name
                        ? item?.item_master_details?.item_name
                        : "--"}
                    </th>
                    <td>
                      {item?.item_master_details?.hsn_code
                        ? item?.item_master_details?.hsn_code
                        : "--"}
                    </td>
                    <td>{item?.qty ? item?.qty : "--"}</td>
                    <td>
                      {item?.item_master_details?.unit
                        ? item?.item_master_details?.unit
                        : "--"}
                    </td>

                    <td>{item?.rate ? formatNumber(item?.rate) : "--"}</td>
                    <td>
                      {item?.item_master_details?.item_code
                        ? item?.item_master_details?.item_code
                        : "--"}
                    </td>
                    <td>
                      <MaterialStatus
                        item={item}
                        listMaterialInStatus={listMaterialInStatus}
                        // partialModal={partialModal}
                        // setPartialModal={setPartialModal}
                        selectedProjectId={selectedProjectId}
                        selectedPO={selectedPO}
                        user_id={user_id}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="row pt-4"> No item Found,Please select PO</div>
        )}
      </form>
    </>
  );
};

export default MaterialItemDetail;
