import React from "react";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "./Style.css";

export default function ManageRole() {
  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="/emd-paid">EMD Paid Status /</Link>
                      </li> */}
                    </ol>
                  </div>
                  {/* <h4 className="page-title">User Roles</h4> */}
                </div>
              </div>
            </div>
            <div className="row border-bottom border-secondary border-1">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      User Roles{" "}
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="">
                  <div className="mt-4 table-border-radius">
                    <Table responsive>
                      <thead>
                        <tr className="bg-white">
                          <th className="text-black">Scope: All Employees</th>
                          <th className="text-black">
                            Select users for the role{" "}
                          </th>
                          <th className="text-black"></th>
                          <th className="text-black text-end">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm me-3 mt-3"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm me-3 mt-3"
                            >
                              Submit
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td colSpan="2">
                            <div className="card  p-4 border-radius  border ">
                              <div className="card-body border-bottom border-secondary border-1  ">
                                <div className="d-flex gap-5 align-items-center">
                                  <div className="d-flex justify-content-between align-items-center g-4">
                                    <img
                                      src="assets/images/users/user-3.jpg"
                                      alt=""
                                      className="rounded-circle thumb-sm me-1"
                                    />{" "}
                                    <p className="dashboard-text font-11 fw-semibold mt-0">
                                      {" "}
                                      Piyush Kanwal
                                    </p>
                                    <Link to="#">
                                    <img
                                      src="assets/images/users/cut.png"
                                      alt="" 
                                    />
                                    </Link>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center g-4">
                                    <img
                                      src="assets/images/users/user-1.jpg"
                                      alt=""
                                      className="rounded-circle thumb-sm me-1"
                                    />{" "}
                                    <p className="dashboard-text font-11 fw-semibold mt-0">
                                      {" "}
                                      Piyush Kanwal
                                    </p>
                                    <Link to="#">
                                    <img
                                      src="assets/images/users/cut.png"
                                      alt=""
                                    />
                                    </Link>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center g-4">
                                    <img
                                      src="assets/images/users/user-2.jpg"
                                      alt=""
                                      className="rounded-circle thumb-sm me-1"
                                    />{" "}
                                    <p className="dashboard-text font-11 fw-semibold  mt-0">
                                      {" "}
                                      Piyush Kanwal
                                    </p>
                                    <Link to="#">
                                    <img
                                      src="assets/images/users/cut.png"
                                      alt="" 
                                    />
                                    </Link>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>

                        {/* <tr>
                            <td colSpan={3}>
                              <Link to="javascript:void(0);" className="btn btn-danger w-100"> View All</Link>
                            </td>
                          </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
