import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/esm/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { NavLink } from "react-router-dom";

export default function Admins({ keys }) {
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const placeholderImage = imageBaseUrl + "assets/user.jpg";
  const [deletingUserId, setDeletingUserId] = useState(null);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [show, setShow] = useState(false);
  const [toplevel, setTopLevel] = useState([]);
  const [totalAdmin, setTotalAdmin] = useState("");
  const [perPageAdmin, setPerPageAdmin] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function handleClose() {
    setShow(false);
  }

  function handleDeleteUser() {
    const url = apiurl + "setdeleteuser";
    const body = {
      user_id: user_id,
      duser_id: deletingUserId,
      accessType: "delete",
    };
    axios
      .post(url, body, config)
      .then((res) => {
        if (res.status === 200) {
          fetchAdmin(currentPage);
          if ((totalAdmin - 1) % perPageAdmin === 0) {
            setCurrentPage(currentPage - 1);
          }
          toast.success("Admin Deleted Successfully");
          handleClose();
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
      });
    setDeletingUserId(null);
  }

  function fetchAdmin(value) {
    setLoader(true);
    if (keys === "admin") {
      let url;
      if (search.length > 0) {
        url = apiurl + `getadminlist?page=${1}`;
      }
      if (search.length === 0) {
        url = apiurl + `getadminlist?page=${value}`;
      }
      var body = { user_id: user_id, search, accessType: "view" };
      axios
        .post(url, body, config)
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            setTopLevel(res?.data?.response?.adminList);
            // setTotalAdmin(res?.data?.response?.adminList?.total);
            // setPerPageAdmin(res?.data?.response?.adminList?.per_page);
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err?.response?.data?.status === "Token is Expired") {
          }
        });
    }
  }

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  /////////// Pagination //////////
  const recordsPerPage = perPageAdmin;
  const npage = toplevel ? Math.ceil(totalAdmin / recordsPerPage) : [];
  const numbers = [...Array(npage + 1).keys()].slice(1);

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCpage(id) {
    setCurrentPage(id);
  }

  useEffect(() => {
    fetchAdmin(currentPage);
  }, [currentPage, keys, search]);

  return (
    <>
      {loader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="row">
                  <div className="col-6">
                    {/* <h4 className="card-title">Our Admins</h4> */}
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <div className="input-group w-50 me-3">
                      <input
                        type="text"
                        name="example-input1-group2"
                        className="form-control form-control-sm border-danger"
                        placeholder="Search"
                        style={{ fontSize: "1rem" }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                    <div>
                      <Link to="/ProjectsUsersAdd">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          Add New
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end"></div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {toplevel.length ? (
                  toplevel?.map((obj, i) => {
                    return (
                      <div key={i} className="col-md-3">
                        <div className="card user-card">
                          <div className="card-body">
                            <img
                              src={
                                imageBaseUrl + obj.profileimage
                                  ? imageBaseUrl + obj.profileimage
                                  : placeholderImage
                              }
                              alt=""
                              className="d-block mx-auto mb-3 mt-2 rounded-circle"
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                              }}
                            />
                            <div className="text-center mb-3">
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 450, hide: 100 }}
                                overlay={renderTooltip(obj.name)}
                              >
                                <NavLink to={`/user-profile/${obj.id}`}>
                                  <h5
                                    className="mb-2 text-capitalize"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {" "}
                                    {obj.name}
                                  </h5>
                                </NavLink>
                              </OverlayTrigger>
                              <span className="badge badge-soft-danger font-14 fw-semibold mb-2">
                                {obj.userDesignation}
                              </span>
                            </div>
                            <div className="m-2">
                              <p className=" mb-2">
                                <span className="fw-semibold">Phone:</span>{" "}
                                {obj.phone}
                              </p>
                              <p className=" mb-2">
                              <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 100 }}
                                    overlay={renderTooltip(obj.email)}
                                  >
                                    <p className="mb-2">
                                      <span
                                        className="fw-semibold"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        Email:
                                      </span>{" "}
                                      {obj.email}
                                    </p>
                                  </OverlayTrigger>
                              </p>
                              <p className=" mb-2">
                                <span className="fw-semibold">
                                  Joining Date:
                                </span>{" "}
                                {formatDate(obj.joining_date)}
                              </p>
                              <Link
                                to={`/edit-user/${obj.id}`}
                                className="btn btn-warning btn-sm px-3 mt-2 mb-0"
                              >
                                <i className="las la-pen text-white font-16 text-dark"></i>
                              </Link>
                              &nbsp;&nbsp;
                              <button
                                onClick={() => {
                                  setDeletingUserId(obj.id);
                                  setShow(true);
                                }}
                                className="btn btn-danger btn-sm px-3 mt-2"
                              >
                                <i className="las la-trash-alt text-white font-16 text-dark"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <h5 className="text-secondary">No Admin Found</h5>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button className="btn btn-success me-3" onClick={handleDeleteUser}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={handleClose}>
            No
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
