import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { errorsMessage } from "../../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import Select from "react-select";
import { getProjectCoachList } from "../../../../redux/api";

const CoachProduction = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  validationError,
  projectDropdown,
  allDropdowns,
  spinner,
  mode,
  setFieldValue,
  projectLotDropdown,
}) => {
  const navigate = useNavigate();

  const handleDispatchDateChange = () => {
    if (values.dispatchDate && values.coachWarrantyPeriod_IR_GCC) {
      const dispatchDate = new Date(values.dispatchDate);
      const sixYearExpiryDate = new Date(
        dispatchDate.setFullYear(dispatchDate.getFullYear() + +(values.coachWarrantyPeriod_IR_GCC))
      );
      const sixYearExpiryDateString = sixYearExpiryDate
        .toISOString()
        .split("T")[0];
      setFieldValue("warrentyExpiryDate", sixYearExpiryDateString);
    }
    if (values.dispatchDate && values.puPeriod) {
      const dispatchDate = new Date(values.dispatchDate);
      const tenYearExpiryDate = new Date(
        dispatchDate.setFullYear(dispatchDate.getFullYear() + +(values.puPeriod))
      );
      const tenYearExpiryDateString = tenYearExpiryDate
        .toISOString()
        .split("T")[0];
      setFieldValue("puWarrantyDate", tenYearExpiryDateString);
    }
  };
 useEffect(() => {
  handleDispatchDateChange()
 },[values.coachWarrantyPeriod_IR_GCC,values.puPeriod,values.dispatchDate])
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue(name, base64);
      };
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4">
                    {mode == "add" ? "Add" : "Edit"} Coach Production
                  </h4>
                  <form className="">
                    <div className="row mb-3">
                      {/* <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            {" "}
                            Project
                          </label>
                          <Select
                            placeholder="Select Project Title"
                            className="form-select-control border-secondary"
                            options={projectDropdown}
                            getOptionLabel={(projectDropdown) =>
                              projectDropdown["project_title"]
                            }
                            getOptionValue={(projectDropdown) =>
                              projectDropdown["id"]
                            }
                            onChange={(value) => {
                              setFieldValue("project_id", value.id);
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary25: "lightgrey",
                                primary: "lightgrey",
                              },
                            })}
                          />
                          <p className="text-danger m-0">
                            {touched.project_id && errors.project_id
                              ? errors.project_id
                              : validationError?.project_id}
                          </p>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Lot
                          </label>
                          <select
                            className="form-control border-secondary"
                            placeholder="Enter Status"
                            onChange={handleChange}
                            value={values.lotId}
                            name="lotId"
                          >
                            <option>Select</option>
                            {projectLotDropdown?.map((element, i) => (
                              <option key={element.id} value={element.id}>
                                Lot {i + 1}
                              </option>
                            ))}
                          </select>
                          <p className="text-danger m-0">
                            {touched.lotId && errors.lotId
                              ? errors.lotId
                              : validationError?.lotId}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Furnishing No./Railway No./New Coach No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter furnishing no."
                            name="furnishingNo"
                            value={values.furnishingNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.furnishingNo && errors.furnishingNo
                              ? errors.furnishingNo
                              : validationError?.furnishingNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Shell No. / Old Coach No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter coach no."
                            name="coachNo"
                            value={values.coachNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.coachNo && errors.coachNo
                              ? errors.coachNo
                              : validationError?.coachNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Coach allotted to zone
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter allotted coach"
                            name="coachAllottedTo"
                            value={values.coachAllottedTo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.coachAllottedTo && errors.coachAllottedTo
                              ? errors.coachAllottedTo
                              : validationError?.coachAllottedTo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Allotment Date
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="allotmentDate"
                            value={values.allotmentDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.allotmentDate && errors.allotmentDate
                              ? errors.allotmentDate
                              : validationError?.allotmentDate}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Completion Date (Furnishing)
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="completionDate_Furnishing"
                            value={values.completionDate_Furnishing}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.completionDate_Furnishing &&
                            errors.completionDate_Furnishing
                              ? errors.completionDate_Furnishing
                              : validationError?.completionDate_Furnishing}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Completion Date (Electrical)
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="completionDate_Electrical"
                            value={values.completionDate_Electrical}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.completionDate_Electrical &&
                            errors.completionDate_Electrical
                              ? errors.completionDate_Electrical
                              : validationError?.completionDate_Electrical}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Dispatch Date
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="dispatchDate"
                            value={values.dispatchDate}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.dispatchDate && errors.dispatchDate
                              ? errors.dispatchDate
                              : validationError?.dispatchDate}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            UOM
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter uom"
                            name="UOM"
                            value={values.UOM}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.UOM && errors.UOM
                              ? errors.UOM
                              : validationError?.UOM}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Coach Warranty Period (As per IR GCC)
                          </label>
                          <input
                            className="form-control border-secondary"
                            type="number"
                            placeholder="Enter warranty period"
                            name="coachWarrantyPeriod_IR_GCC"
                            value={values.coachWarrantyPeriod_IR_GCC}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          {/* <p className="text-danger m-0">
                            {touched.coachWarrantyPeriod_IR_GCC &&
                            errors.coachWarrantyPeriod_IR_GCC
                              ? errors.coachWarrantyPeriod_IR_GCC
                              : validationError?.coachWarrantyPeriod_IR_GCC}
                          </p> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Warranty Expiry Date  
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="warrentyExpiryDate"
                            value={values.warrentyExpiryDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.warrentyExpiryDate &&
                            errors.warrentyExpiryDate
                              ? errors.warrentyExpiryDate
                              : validationError?.warrentyExpiryDate}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            PU Foam Warranty period
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter warranty period"
                            name="puPeriod"
                            type="number"
                            value={values.puPeriod}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.puPeriod &&
                            errors.puPeriod
                              ? errors.puPeriod
                              : validationError?.puPeriod}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            PU Foam Warranty Expiry Date  
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name="puWarrantyDate"
                            value={values.puWarrantyDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.puWarrantyDate &&
                            errors.puWarrantyDate
                              ? errors.puWarrantyDate
                              : validationError?.puWarrantyDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Allotment certification
                          </label>
                          <input
                            type="file"
                            className="form-control border-secondary"
                            placeholder="Enter warranty period"
                            name="AllotmentCertification"
                            onChange={handleFileInputChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.puPeriod &&
                            errors.puPeriod
                              ? errors.puPeriod
                              : validationError?.puPeriod}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Completion certification
                          </label>
                          <input
                            type="file"
                            className="form-control border-secondary"
                            placeholder="Enter warranty period"
                            name="CompletionCertification"
                            onChange={handleFileInputChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.puPeriod &&
                            errors.puPeriod
                              ? errors.puPeriod
                              : validationError?.puPeriod}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row mb-3">
                      <h4>Warranty Claim</h4>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Reported Date
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name=" "
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Attended Date
                          </label>
                          <input
                            type="date"
                            className="form-control border-secondary"
                            name=" "
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Cost Incurred
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter cost"
                            name=""
                          ></input>
                        </div>
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-end">
                      <div className="mt-3">
                        <Button variant="primary" onClick={handleSubmit}>
                          Save
                        </Button>
                        <button
                          type="button"
                          className="btn btn-de-danger btn-sm border-danger"
                          style={{ marginLeft: "1rem" }}
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachProduction;
