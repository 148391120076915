import React, { useEffect } from "react";
import { Link, useNavigate, NavLink, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../redux/features/authSlice";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/Index";
import { useState } from "react";
import { PiEyeClosedLight } from "react-icons/pi";
import { PiEyeLight } from "react-icons/pi";


const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const { error, loading , previousUrl } = useSelector((state) => ({ ...state.auth }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, action) => {
      dispatch(login({ values, navigate, toast , previousUrl }));
    },
  });

  const mystyle = {
    backgroundImage: "url('assets/images/p-1.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  useEffect(() => {
    resetForm();
    if(userProfile){
      navigate("/dashboard")
    }
    
  }, []);

  return (
    <>
    {/* {userProfile && <Navigate to="/dashboard"/>} */}
      <div className="auth-page" style={mystyle}>
        <div className="container-md">
          <div className="row vh-100 d-flex justify-content-center">
            <div className="col-12 align-self-center">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 mx-auto">
                    <div className="card">
                      <div className="card-body p-0 auth-header-box">
                        <div className="text-center p-3">
                          <Link to="/" className="logo logo-admin">
                            <img
                              src="assets/images/logo.png"
                              height="50"
                              alt="logo"
                              className="auth-logo"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <form className="my-4" onSubmit={handleSubmit}>
                          <div className="form-group mb-2">
                            <label className="form-label" htmlFor="email">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                              // maxLength={40}
                              className="form-control"
                              id="email"
                              placeholder="Enter Email"
                            />
                          </div>
                          {touched.email && errors.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                          {/* <!--end form-group--> */}

                          <div
                            className="form-group mb-2"
                            style={{ position: "relative" }}
                          >
                            <label
                              className="form-label"
                              htmlFor="userpassword"
                            >
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type={viewPassword ? "text" : "password"}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                              maxLength={16}
                              className="form-control"
                              id="userpassword"
                              placeholder="Enter Password"
                            />
                            {viewPassword ? (
                              <PiEyeLight
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: ".8rem",
                                  top: "2.2rem",
                                  fontSize: "1.2rem",
                                }}
                                onClick={() => setViewPassword(false)}
                              />
                            ) : (
                              <PiEyeClosedLight
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: ".8rem",
                                  top: "2.2rem",
                                  fontSize: "1.2rem",
                                }}
                                onClick={() => setViewPassword(true)}
                              />
                            )}
                          </div>
                          {touched.password && errors.password ? (
                            <p className="text-danger">{errors.password}</p>
                          ) : null}
                          {/* <!--end form-group--> */}

                          <div className="form-group row mt-3">
                            <div className="col-sm-6">
                              {/* <div className="form-check form-switch form-switch-success">
                                                                <input className="form-check-input" type="checkbox"
                                                                    id="customSwitchSuccess" />
                                                                <label className="form-check-label" htmlFor="customSwitchSuccess">Remember
                                                                    me</label>
                                                            </div> */}
                            </div>
                            {/* <!--end col--> */}
                            <div className="col-sm-6 text-end">
                              <NavLink
                                to="/ForgotPassword"
                                className="text-muted font-13 text-decoration-underline"
                              >
                                <i className="dripicons-lock"></i> Forgot
                                password?
                              </NavLink>
                            </div>
                            {/* <!--end col--> */}
                          </div>
                          {/* <!--end form-group--> */}

                          <div className="form-group mb-0 row">
                            <div className="col-12">
                              <div className="d-grid mt-3">
                                {loading ? (
                                  <div className="text-center">
                                    <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                                    <p>Loading...</p>
                                  </div>
                                ) : (
                                  <input
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    value="Login"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                        <h5 className="text-danger text-center fw-bold">
                          {error}
                        </h5>
                        {/* <!--end form--> */}
                      </div>
                      {/* <!--end card-body--> */}
                    </div>
                    {/* <!--end card--> */}
                  </div>
                  {/* <!--end col--> */}
                </div>
                {/* <!--end row--> */}
              </div>
              {/* <!--end card-body--> */}
            </div>
            {/* <!--end col--> */}
          </div>
          {/* <!--end row--> */}
        </div>
      </div>
    </>
  );
}

export default Login;
