import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import '../Pages/Style.css'

export default function ScheduleModal({
  editAbleData,
  show,
  handleClose,
  getScheduleList,
  kanbanSchedule,
  scheduleData
}) {
  const [name, setName] = useState("");
  const [schDescription, setSchDescription] = useState("");
  const [schStartDate, setSchStartDate] = useState(null);
  const [schEndDate, setSchEndDate] = useState(null);
  const [formError, setFormError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [dateError, setDateError] = useState(null);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const scheduleId = editAbleData && editAbleData.id;
  const param = useParams();
  let project_id = Number(param.id);

  function settingStates() {
    setName("");
    setSchDescription("");
    setSchStartDate(null);
    setSchEndDate(null);
  }

  useEffect(() => {
    if (editAbleData) {
      setName(editAbleData.name);
      editAbleData.description && setSchDescription(editAbleData.description);
      setSchStartDate(new Date(editAbleData.start_date));
      setSchEndDate(new Date(editAbleData.end_date));
      setFormError(null);
      setDateError(null);
    } else {
      settingStates();
      setFormError(null);
      setDateError(null);
    }
  }, [editAbleData, show]);

  let start_date = schStartDate
    ? `${schStartDate.getFullYear()}-${
        schStartDate.getMonth() + 1
      }-${schStartDate.getDate()}`
    : "";

  let end_date = schEndDate
    ? `${schEndDate.getFullYear()}-${
        schEndDate.getMonth() + 1
      }-${schEndDate.getDate()}`
    : "";
  let description = schDescription;

  function addSchedule(e) {
    e.preventDefault();
    const url = baseUrl + "storeschedule";
    const data = {
      user_id,
      project_id,
      name,
      description,
      start_date,
      end_date,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.data.success === true) {
          settingStates();
          getScheduleList();
          handleClose();
          toast.success("Added Successfully");
          kanbanSchedule();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Error validation") {
          setFormError(error?.response?.data?.response);
        }
        if (
          error?.response?.data?.response ===
          "Schedule is already added for selected Date."
        ) {
          setDateError(`Schedule is already added for selected Date.`);
        }
      });
  }

  function updateSchedule(e) {
    e.preventDefault();
    setSpinner(true);
    const url = baseUrl + "editschedule";
    const data = {
      user_id,
      project_id,
      scheduleId,
      name,
      description,
      start_date,
      end_date,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Schedule Update Successfully");
          settingStates();
          handleClose();
          getScheduleList();
          setSpinner(false);
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error?.response?.data?.message === "Error validation") {
          setFormError(error?.response?.data?.response);
        } else if (
          error?.response?.data?.response ===
          "Schedule is already added for selected Date."
        ) {
          setDateError(`Schedule is already added for selected Date.`);
        }
      });
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editAbleData ? "Update Schedule" : "Add Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="form-group ">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <p className="m-0 text-danger">
                    {formError && formError.name}
                  </p>
                </div>

               
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="StartDate" className="form-label w-50">
                      Start Date
                    </label>
                    <DatePicker
                      type="date"
                      className="form-control w-100"
                      placeholderText="09/25/2023"
                      selected={schStartDate}
                      onChange={(date) => {
                        setSchStartDate(date);
                        setDateError(null);
                      }}
                      selectsStart
                      dateFormat="dd/MM/yyyy"
                      startDate={schStartDate}
                      endDate={schEndDate}
                      // minDate={new Date()}
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      // filterDate={isDateDisabled}
                    />
                    <p className="m-0 text-danger">
                      {formError && formError.start_date}
                    </p>
                    <p className="m-0 text-danger">{dateError && dateError}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="CompletionDate" className="form-label w-50">
                      Due Date
                    </label>
                    <DatePicker
                      type="date"
                      className="form-control w-100"
                      placeholderText="09/30/2023"
                      selected={schEndDate}
                      onChange={(date) => {
                        setSchEndDate(date);
                        setDateError(null);
                      }}
                      selectsEnd
                      startDate={schStartDate}
                      endDate={schEndDate}
                      minDate={schStartDate}
                      dateFormat="dd/MM/yyyy"
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      // filterDate={isDateDisabled}
                    />
                    <p className="m-0 text-danger">
                      {formError && formError.end_date}
                    </p>
                    <p className="m-0 text-danger">{dateError && dateError}</p>
                  </div>
                </div>

                <div className="col-md-12 my-3">
                  <label className="form-label">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    placeholder="Description"
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSchDescription(data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Please Wait</p>
            </div>
          ) : (
            ((
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            ),
            !editAbleData ? (
              <Button variant="primary" onClick={addSchedule}>
                Add Schedule
              </Button>
            ) : (
              <Button variant="primary" onClick={updateSchedule}>
                Save Changes
              </Button>
            ))
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
