import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProbationPolicyTab from "./ProbationPolicyTab";
// import AddProbationPolicyModal from "./AddProbationModal"
import DeleteModal from "../../Organisation/organisationStructure/OrganizationComponents/DeleteModal";
import { deleteProbationPolicy } from "../../hrApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { fetchProbationPolicy } from "../../../redux/features/Hr/ProbationPolicySlice";
const ProbationPolicyTabCard = ({ selectedProbationPolicy, getEditData }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const deleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };
  const deleteData = async () => {
    dispatch(setLoading(true));
    const reqpayload = { id: selectedProbationPolicy?.id };

    try {
      const response = await deleteProbationPolicy(reqpayload);
      dispatch(fetchProbationPolicy(dispatch));
      toast.success(response?.data?.message, { autoClose: 2000 });
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <section className="probation-policy-card">
      <div className="d-flex justify-content-between mt-4  ms-3">
        <div className="mt-4 ">
          <h5 className="fw-bold tab-card-heading ">
            {" "}
            {selectedProbationPolicy?.name}
          </h5>
          <h6 className="m-0">40 Employees</h6>
        </div>
        <div className="dropdown">
          <button
            className="dropdown-toggle border-0 bg-transparent"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MoreVertIcon />
          </button>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ minWidth: "8rem" }}
          >
            <li>
              <button
                className="dropdown-item"
                onClick={() => getEditData(selectedProbationPolicy)}
              >
                Edit
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => deleteModalOpen()}
              >
                Delete
              </button>
            </li>
          </ul>
        </div>
      </div>
      <DeleteModal
        deleteModalOpen={deleteModal}
        handleDeletemodalClose={handleClose}
        deleteData={deleteData}
        loader={loader}
      />
      <ProbationPolicyTab selectedProbationPolicy={selectedProbationPolicy} />
    </section>
  );
};

export default ProbationPolicyTabCard;
