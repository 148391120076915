import React from 'react'

import CommonTable from '../../MaterialUiComponent/CommonTable'

const InProbation = () => {
  const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'designation', label: 'Designation', minWidth: 150 },
    { id: 'email', label: 'Email Address', minWidth: 200 },
    { id: 'department', label: 'Department', minWidth: 150 },
    { id: 'probationEndDate', label: 'Probation End Date', minWidth: 150, format: (value) => new Date(value).toLocaleDateString('en-US') },
    { id: 'extension', label: 'Extension', minWidth: 100 },
    { id: 'actions', label: '', minWidth: 100 },
  ];
  
  // Sample data
  const rows = [
    { name: 'John Doe', designation: 'Software Engineer', email: 'john.doe@example.com', department: 'Engineering', probationEndDate: '2024-12-31', extension: '1234',image:"" },
    { name: 'Jane Smith', designation: 'Product Manager', email: 'jane.smith@example.com', department: 'Product', probationEndDate: '2024-11-15', extension: '5678',image:"" },
    // Add more sample data here
  ];


  return (
    <>
    <section className='inProbation'>
<div className='bg-white mt-2 rounded mx-3'>
<div className='d-flex justify-content-between align-items-center mx-3 mb-3'>
    <h4>EMPLOYEEES IN PROBATION</h4>
    <div className="d-flex align-items-center">
      <input className='days_selector_style mb-lg-2 mb-3 mr-lg-3' type="text" placeholder="Search.." />
      <button className="search_Button" type="submit"><i className="fa fa-search"></i></button>
    </div>
</div>

<CommonTable rows={rows} columns={columns} />
</div>


    </section>

    </>
  )
}

export default InProbation


