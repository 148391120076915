import React from 'react';
import AsideLIst from './AsideLIst';
import TabContentCard from './TabContentCard';

const MainCard = ({ asideContent, tabContent,asideHeading }) => {

  return (
    <section className='tab-content'>
      <div className='row main-row mx-3'>
        <AsideLIst asideHeading= {asideHeading}>
          {asideContent}
         
        </AsideLIst>
        <TabContentCard>
          {tabContent}
        </TabContentCard>
      </div>
    </section>
  );
}

export default MainCard;
