import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../organisationStructure/OrgStucture.css";
import BusinessUnitSummary from "./BusinessUnitSummary";
import BusinessUnitEmployees from "./BusinnesUnitEmployees";


function OrgBusinessUnitTabs({ businessUnit, editData, setEditData, }) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    
    <Box
    className ="organisation_Structure_Tab"
      sx={{
        width: "100%",
        typography: "body1",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // background: "rgba(245, 245, 245, 1)",
              borderBottom: "1px solid rgba(207, 207, 207, 1)",
            }}
          >
            <Tab
              label="Summary"
              value="1"
              sx={{
                flex: 1,
                textAlign: "center",
            
              }}
            />
            <Tab
              label="Employees"
              value="2"
              sx={{ flex: 1, textAlign: "center" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <BusinessUnitSummary businessUnit={businessUnit} editData={editData} setEditData={setEditData}  />
        </TabPanel>
        <TabPanel value="2">
          <BusinessUnitEmployees businessUnit={businessUnit} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default OrgBusinessUnitTabs;
