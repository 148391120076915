import axios from "axios";

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});

export const getKanbandBoardList = (data) =>
  API.post("/departmentsprojectkanbanborardjourney", data);

export const addKanbanBoardJourney = (data) =>
  API.post("/addorupdatekanbanboardjourney", data);
export const listkanbanboardjourney = (data) =>
  API.post("/listkanbanboardjourney", data);
export const addKanbanBoardJourneyStages = (data) =>
  API.post("/addkanbanboardjourneystage", data);
export const addkanbanboardjourneysubstage = (data) =>
  API.post("/addkanbanboardjourneysubstage", data);
export const updateSubStages = (data) =>
  API.post("/updatekanbanboardjourneysubstage", data);
export const updateKanbanStages = (data) =>
  API.post("/updatekanbanboardjourneystage", data);
