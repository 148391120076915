import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { QMSSchema } from "../../schemas/StoreSchema/QMSSchema";
import Spinner from "react-bootstrap/Spinner";
const QMSList = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const userdetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userdetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const appBaseUrl = process.env.REACT_APP_BASE_URL;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [showModal, setShowModal] = useState(false);
  const [QMSList, setQMSList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [docsId, setDocsId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    resetForm();
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const openDeleteModal = (id) => {
    setDeleteModal(true);
    setDocsId(id);
  };
  function capitalizeWords(name) {
    return name.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }
  const editModal = (QMS) => {

    setDocsId(QMS?.id);
    setShowModal(true);
    setEditMode(true);
    setValidationError(false);
    setFieldValue("name", QMS?.name);
    setFieldValue("type", QMS?.type);
    setFieldValue("file", QMS?.file);
  };
  const initialValue = {
    name: "",
    type: "",
    file: "",
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: QMSSchema,
    onSubmit: (values) => {
      if (editMode) {
        updateQMS(values);
      } else {
        addQMS(values);
      }
    },
  });
  const getAttachmentBase64 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue("file", base64);
      };
    }
  };

  const docName = (filepath) => {
    const result = filepath.split("QmDocs")[1];

    return result;
  };
  const getQMSList = () => {
    const url = `${baseUrl}getQmDocslist`;
    const data = { user_id };
    setSpinner(true)
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false)
        setQMSList(res?.data?.response?.QmDocsList);
      })
      .catch((err) =>{
        setSpinner(false)
        toast.error("Please try again later ,Something went wrong ")}
      );
  };
  const addQMS = (values) => {
    const url = `${baseUrl}storeQmDocs`;
    const data = { user_id, ...values };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status == 200) {
          toast.success("QMS added successFully", { autoClose: 2000 });
          resetForm();
          handleClose();
          getQMSList();
        }
      })
      .catch((err) => {
        setSpinner(false);
        setValidationError(err?.response?.data?.response);
        toast.error("Please try again later ,Something went wrong ", {
          autoClose: 2000,
        });
        // handleClose()
      });
  };
  const updateQMS = async () => {
    const url = `${baseUrl}updateQmDocs`;
    let updatedFile;
    let data 
    if (values?.file?.substring(0, 7) === "assets/") {
      updatedFile = `${appBaseUrl}${values.file}`;
      data = { docsId, user_id, ...values, file: updatedFile };
    }else{data = { docsId, user_id, ...values };}
     
    setSpinner(true);
 
    try {
      const res = await axios.post(url, data, config);
      setSpinner(false);
      toast.success("QMS update Successfully", { autoClose: 2000 });
      handleClose();
      getQMSList();
      setEditMode(false);
    } catch (err) {
      setSpinner(false);
      setValidationError(err?.response?.data?.response);
      toast.error("Please try again later, Something went wrong", {
        autoClose: 2000,
      });
    }
  };
  const deleteQMS = (docsId) => {
    const url = `${baseUrl}deleteQmDocs`;
    const data = { user_id, docsId };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status == 200) {
          toast.success("QMS deleted successfully", { autoClose: 2000 });
          closeDeleteModal();
          getQMSList();
        }
      })
      .catch((err) => {
        setSpinner(false);
        toast.error("Plaese try again later,Something went wrong");
      });
  };
  useEffect(() => {
    getQMSList();
  }, []);
  return (
    <div className="page-wrapper">
       {spinner ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body tabsall">
              <div className="row">
                <div className="d-flex align-content-center justify-content-between">
                  <div className="col-sm-4">
                    <div className="page-title-box">
                      <h2 className="page-title">
                        {" "}
                        QUALITY MANAGEMENT SYSTEM DOCUMENT{" "}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setShowModal(true);
                        setEditMode(false);
                        setValidationError(false);
                      }}
                    >
                      Add New
                    </button>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    {QMSList?.length ? (
                      <table class="table">
                        <thead>
                          <tr style={{ border: "0" }}>
                            <th
                              scope="col"
                              style={{
                                width: "10%",
                                color: "#b9b9b9",
                                fontSize: "0.9rem",
                              }}
                            >
                              S.NO.
                            </th>
                            <th
                              scope="col"
                              style={{ color: "#b9b9b9", fontSize: "0.9rem" }}
                            >
                              NAME
                            </th>
                            <th
                              scope="col"
                              className="col-2"
                              style={{ color: "#b9b9b9", fontSize: "0.9rem" }}
                            >
                              DOCUMENT TYPE
                            </th>
                            <th
                              scope="col"
                              style={{ color: "#b9b9b9", fontSize: "0.9rem" }}
                            >
                              DOCUMENT
                            </th>
                            <th
                              scope="col"
                              style={{ color: "#b9b9b9", fontSize: "0.9rem" }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {QMSList?.map((QMS, index) => (
                            <tr style={{ borderBottom: "1px solid #E0E0E0" }}>
                              <td>{index + 1}</td>
                              <td>{QMS?.name == null ? "--" :capitalizeWords(QMS?.name)}</td>

                              <td>{QMS?.type == null ? "--" : capitalizeWords(QMS?.type)}</td>

                              <td style={{ color: "#0084CE" }}>
                                {" "}
                                <Link
                                  style={{ color: "#0084CE" }}
                                  to={`${appBaseUrl}${QMS?.file}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                 {QMS?.name == null ? "--" : QMS?.name}
                                </Link>
                              </td>

                              <td>
                                <FaRegEdit
                                  style={{ fontSize: "1.3rem" }}
                                  onClick={() => {
                                    editModal(QMS);
                                  }}
                                />{" "}
                                <span
                                  onClick={() => {
                                    openDeleteModal(QMS?.id);
                                  }}
                                >
                                  <RiDeleteBin5Line
                                    style={{ fontSize: "1.3rem" }}
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      "No Data Found"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={showModal}
            onHide={handleClose}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-uppercase mb-0">
                {editMode ? "Update" : "Add"} QMS
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
                <div className="col-12 mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16">
                    Doc Name
                  </label>
                  <div className="input-group me-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control form-control-sm border-secondary"
                      placeholder="Enter Doc no"
                      style={{ fontSize: ".9rem" }}
                      value={values?.name}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="text-danger m-0">
                    {touched.name && errors.name
                      ? errors.name
                      : validationError?.name}
                  </p>
                </div>

                <div className="col-12 mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16">
                    Doc Type
                  </label>
                  <div className="input-group me-3">
                    <input
                      type="text"
                      name="type"
                      className="form-control form-control-sm border-secondary"
                      placeholder="Enter Doc Type"
                      style={{ fontSize: ".9rem" }}
                      value={values?.type}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="text-danger m-0">
                    {touched.type && errors.type
                      ? errors.type
                      : validationError?.type}
                  </p>
                </div>

                <div className="col-12 mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16">
                    Upload Doc
                  </label>
                  <div className="input-group me-3">
                    <input
                      type="file"
                      name="file"
                      className="form-control form-control-sm border-secondary"
                      placeholder="upload File"
                      style={{ fontSize: ".9rem" }}
                      onChange={(event) => {
                        getAttachmentBase64(event);
                      }}
                    />
                  </div>
                  <p className="text-danger m-0">
                    {touched.file && errors.file
                      ? errors.file
                      : validationError?.file}
                  </p>
                </div>

                {spinner ? (
                  <div className="">
                    <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                    <p>Wait...</p>
                  </div>
                ) : (
                  <div className="gap-2 mb-3 mx-2 mt-4">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </div>
                )}
              </form>
            </Modal.Body>
          </Modal>
          <Modal
            show={deleteModal}
            onHide={closeDeleteModal}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-uppercase mb-0">Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3 className="text-secondary text-center mx-3">
                Are you sure, <br /> Do you want to Delete this?
              </h3>
            </Modal.Body>
            <div className="d-flex gap-3 justify-content-center mb-3">
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <Button variant="primary" onClick={() => deleteQMS(docsId)}>
                  Delete
                </Button>
              )}
            </div>
          </Modal>
        </div>
      </div>)}
    </div>
  );
};

export default QMSList;
