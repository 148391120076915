import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const ExitSearchBar = ({ tabName, departmentChange }) => {
  const [exitType, setExitType] = useState("");
  const [department, setDepartment] = useState("");

  const [age, setAge] = React.useState('');

  const handleChangeNew = (event) => {
    setAge(event.target.value);
  };

  const handleChange = (event) => {
    setExitType(event.target.value);
  };

  return (
    <div>
      <div className="d-flex px-3 justify-content-between align-items-center mb-3">
        <h5 className="fw-bold">{tabName}</h5>

        <div className="d-flex  justify-content-between align-items-center gap-3 mt-3">
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Age</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Age"
              onChange={handleChangeNew}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          {tabName === "Exited Employees" && (
            <FormControl
              fullWidth
              sx={{ minWidth: 150 }} size="small" 
            >
              <InputLabel
                id="demo-simple-select-label"
                sx={{ verticalAlign: "middle" }}
              >
                OK To Rehire
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={Status}
                label="OK To Rehire"
                onChange={handleChange}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={2}>No</MenuItem>
              </Select>
            </FormControl>
          )}
          {tabName === "In Exit Process" && (
            <FormControl
              fullWidth
              sx={{ minWidth: 150 }} size="small" 
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={Status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          )}
          {tabName !== "Notice Period" && (
            <>
              <FormControl
                fullWidth
               sx={{ minWidth: 200 }}  
                 size="small"
              >
                <InputLabel id="demo-simple-select-label">ExitType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={exitType}
                  label="ExitType"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
              <FormControl  sx={{ minWidth: 300 }}   size="small">
                <InputLabel
                  id="demo-simple-select-label"
                  className="text-center"
                >
                  Department
                </InputLabel>
                <Select
                  
                  labelId="demo-simple-select-label"
                  id="named-select"
                  // id="demo-simple-select"
                  value={department}
                  label="Department"
                  onChange={departmentChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          <div className="d-flex align-items-center">
            <input
              className="days_selector_style mt-0 mb-lg-0 mb-3 mr-lg-3"
              type="text"
              placeholder="Search.."
            />
            <button className="search_Button" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitSearchBar;
