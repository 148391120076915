import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UpcomingTender from "../TenderComponents/UpcomingTender";

function DashboardAlert({ show, setShow, upcomingTenders, raTenders }) {
  function upcomingTendersData() {}

  useEffect(() => {
    upcomingTendersData();
  }, []);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        centered
        className="alert-modal"
      >
        <Modal.Header closeButton className="">
          <h4 className="m-0 font-18 fw-bold text-white">UPCOMING TENDERS</h4>
        </Modal.Header>

        <Modal.Body style={{ minHeight: "400px", maxHeight: "600px" }}>
          {upcomingTenders.length ? (
            <div className="mb-3">
              <h5 className="fw-bold">
                Upcoming Tenders{" "}
                <span className="fw-normal font-16">(7 Days)</span>
              </h5>
              <UpcomingTender tenderData={upcomingTenders}></UpcomingTender>
            </div>
          ):(
            <div>
              <h5 className="fw-bold">
              Upcoming Tenders{" "}
                <span className="fw-normal font-16">(7 Days)</span>
              </h5>
              <p className="mb-0 fw-bold mt-3">No Tender for this category in next 7 days</p>
            </div>
          )}
          {raTenders.length ? (
            <div className="">
              <h5 className="fw-bold">
                Upcoming Reverse Auction{" "}
                <span className="fw-normal font-16">(3 Days)</span>
              </h5>
              <UpcomingTender tenderData={raTenders}></UpcomingTender>
            </div>
          ):(
            <div>
              <h5 className="fw-bold">
                Upcoming Reverse Auction{" "}
                <span className="fw-normal font-16">(3 Days)</span>
              </h5>
              <p className="mb-0 fw-bold mt-3">No Tender for this category in next 3 days</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DashboardAlert;
