import React, { useEffect, useState } from "react";
import BankDetailModal from "./BankDetailModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteModal from "../OrganizationComponents/DeleteModal";
import { toast } from "react-toastify";
import { deletebankDetailApi } from "../OrganizationComponents/organizationStructureApi";
function LegalEntityBank({ bank, key, editData, setEditData, getBank }) {
  console.log("bank",bank)
  const [open, setOpen] = useState(false);
  const[loader,setLoader] = useState(false)
  const[deleteModalOpen,setDeleteModalOPen] = useState(false)
  const[bankIdToDelete,setBankIdToDelete] = useState(null)

  const handleDeletemodalClose =()=>{
    setDeleteModalOPen(false)
  }
  const editClickHandler = () => {
    setOpen(true);
    setEditData(bank);
  };
  const deleteClickHandler =()=>{
    console.log("clicleddddddd")
    setDeleteModalOPen(true)
    setBankIdToDelete(bank?.id)
  }
  const handleClose = () => {
    setOpen(false);
  };
const deleteData =async()=>{
  console.log("4567890")
  const requestPayload={id:bankIdToDelete}
  console.log( "payload",requestPayload)
  setLoader(true)
  try{
const res= await deletebankDetailApi(requestPayload)
setLoader(false)
handleDeletemodalClose()
getBank()
toast.success("bank detail deleted successfully",{autoClose:1000})

  }
  catch(error){
    toast.error(error?.response?.data?.message,{autoClose:1000});
    setLoader(false)
  }

}

  return (
    <div
      style={{
        border: "1px solid rgba(207, 207, 207, 1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 1)",
        padding: "1.5rem",
      }}
      className="legalEntityRegisteredAddress mb-3"
      key={key}
    >
      <div className="row my-2">
        <div className="col-3 mb-5">
          <p>Branch Name</p>
          <p className="bold">{bank?.bank_name}</p>
        </div>
        <div className="col-4">
          <p>Account Number</p>
          <p className="bold">{bank?.account_number}</p>
        </div>
        <div className="col-3">
          <p>IFSC Code </p>
          <p className="bold">{bank?.ifsc} </p>
        </div>
        <div class="col-2 dropdown ps-5">
          <button
            class="dropdown-toggle border-0 bg-transparent"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MoreVertIcon />
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ minWidth: "8rem" }}
          >
            <li>
              <button
                class="dropdown-item"
                onClick={() => {
                  editClickHandler();
                }}
              >
                Edit
              </button>
            </li>
            <li>
              <button class="dropdown-item" onClick={()=>deleteClickHandler()}>Delete</button>
            </li>
          </ul>
        </div>
      </div>
      <BankDetailModal
        open={open}
        handleClose={handleClose}
        editData={editData}
        getBank={getBank}
      />
      <DeleteModal deleteModalOpen ={deleteModalOpen}
  handleDeletemodalClose={handleDeletemodalClose}
  deleteData={deleteData}
  loader={loader}/> 
    </div>
  );
}

export default LegalEntityBank;