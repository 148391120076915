import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  createBankDetailApi,
  updateBankDetailApi,
} from "../OrganizationComponents/organizationStructureApi";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ShowError from "../OrganizationComponents/ShowError";

import {
  CircularProgress,
  FormControl,
  InputLabel,
  Box,
  Select,
  TextField,
} from "@mui/material";
import { addBankDetailSchema } from "../OrganizationComponents/OrganizationSchema";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const BankDetailModal = ({
  open,
  handleClose,
  legalEntityId,
  getBank,
  editData,
}) => {
  const [loader, setLoader] = useState(false);
  const initialValues = {
    bank_name: "",
    account_number: "",
    ifsc: "",
    branch: "",
    corporate_id: "",
    user_id: "",
    alias_id: "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addBankDetailSchema,
    onSubmit: (values) => {
      if (editData) {
        updateBankDetail(values);
      } else {
        createlegalentityBankDetail(values);
      }
    },
  });
  async function createlegalentityBankDetail(values) {
    const requestPayload = { legal_entity_id: legalEntityId, ...values };
    setLoader(true);
    try {
      const res = await createBankDetailApi(requestPayload);
      setLoader(false);
      toast.success("Leagal Enity BankDetail created Successfully");
      handleClose();
      getBank();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }

  async function updateBankDetail(values) {
    const requestPayload = {
      legal_entity_id: editData?.legal_entity_id,
      ...values,
    };
    setLoader(true);
    try {
      const res = await updateBankDetailApi(requestPayload);
      setLoader(false);
      toast.success("Leagal Enity BankDetail updated Successfully");
      getBank();
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    if (editData) {
      setValues(editData);
    } else {
      resetForm();
    }
  }, [editData]);
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {`${editData ? "Update" : "Add"} Bank Details`}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3 pt-2">
              <div className="row">
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Bank Name"
                      name="bank_name"
                      value={values.bank_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.bank_name}
                      message={errors.bank_name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Account Number"
                      name="account_number"
                      value={values.account_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.account_number}
                      message={errors.account_number}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      name="ifsc"
                      value={values.ifsc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="IFSC Code"
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.ifsc}
                      message={errors.ifsc}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Branch"
                      name="branch"
                      value={values.branch}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.branch}
                      message={errors.branch}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Corporate ID"
                      name="corporate_id"
                      value={values.corporate_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.corporate_id}
                      message={errors.corporate_id}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="User ID"
                      name="user_id"
                      value={values.user_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.user_id}
                      message={errors.user_id}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Alias ID"
                      name="alias_id"
                      value={values.alias_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.alias_id}
                      message={errors.alias_id}
                    />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary me-2"
                style={{ borderRadius: ".45rem" }}
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                style={{ borderRadius: ".45rem" }}
                type="Submit"
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Edit Bank "
                ) : (
                  "Add Bank"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default BankDetailModal;
