import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import UserRolesModal from "../Modals/UserRolesModal";
import AddUserRoleModal from "../Modals/AddUserRoleModal";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

function UserRoles() {
  const [show, setShow] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [role_id, setRole_id] = useState(null);
  const [editAbleRole, setEditAbleRole] = useState("");
  const [loader, setLoader] = useState(true);

  function handleclose() {
    setShow(false);
    setShowAddUser(false);
    setEditAbleRole(null);
  }

  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };

  function getRoleList() {
    setLoader(true);
    let url = baseUrl + "getrolelist";
    let data = { user_id, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        setLoader(false);
        setRoleList(response?.data?.response?.roleList);
      })
      .catch((error) => {
        setLoader(false)
        toast.error("Something went wrong");
      });
  }

  function editRole(id) {
    let editData = roleList?.find((e) => e.id === id);
    setEditAbleRole(editData);
    setShowAddUser(true);
  }

  function handleDeleteRole() {
    let url = baseUrl + "deleterole";
    let data = { user_id, role_id, accessType: "delete" };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          setShowDeleteModal(false);
          setRole_id(null);
        }
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {/* <Link to="#!">Analytics /</Link> */}
                      </li>
                      <li className="breadcrumb-item active"> Manage Role</li>
                    </ol>
                  </div>
                  <h4 className="page-title">MANAGE ROLE</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddUser(true)}
                      >
                        + Add Role
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {loader ? (
              <div
                className="mt-5 d-flex align-items-center  justify-content-center"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th className="fw-bolder">S. No.</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleList?.map((e, i) => {
                        return (
                          <tr key={e.id}>
                            <td>{i + 1}</td>
                            <td>
                              <NavLink to={`/assign-permission/${e.id}`}>
                                {e.name}
                              </NavLink>
                            </td>
                            <td>
                              <NavLink to={`/assign-permission/${e.id}`}>
                                <i
                                  className="las la-key  mx-1"
                                  style={{
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Access Role Permission"
                                ></i>
                              </NavLink>
                              <i
                                className="las la-pen  mx-1"
                                disabled={true}
                                onClick={() => {
                                  editRole(e.id);
                                }}
                                style={{
                                  fontSize: "24px",
                                  cursor: "pointer",
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Role Name"
                              ></i>

                              {/* <i
                                onClick={() => {
                                  setRole_id(e.id);
                                  setShowDeleteModal(true);
                                }}
                                className="las la-trash-alt font-20 px-1 text-dark"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Role"
                              ></i> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <UserRolesModal show={show} handleclose={handleclose} />
      <AddUserRoleModal
        show={showAddUser}
        handleClose={handleclose}
        getRoleList={getRoleList}
        editAbleRole={editAbleRole}
      />

      <Modal show={showDeleteModal} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button className="btn btn-success me-3" onClick={handleDeleteRole}>
            Yes
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}

export default UserRoles;
