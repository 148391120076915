import React, { useEffect, useState } from "react";
import TicketModal from "../Modals/AddTicketModal";
import { useParams } from "react-router-dom";
import StatusList from "./KanbanSections/StatusList";
import axios from "axios";
import EditTicketModal from "../Modals/EditTicketModal";
import ScheduleModal from "../Modals/ScheduleModal";
import Spinner from "react-bootstrap/Spinner";
import AddMembersModal from "../Modals/AddMembersModal";
import "../Backoffice/BackofficeStyle/BackofficeStyle.css";

function KanbanBoard({
  getScheduleList,
  ticketTypeData,
  userData,
  tabKey,
  projectMembers,
  fetchProjectDetails,
  lotData,
  kanbanLotId,
  setKanbanLotId,
  poStartDate
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const param = useParams();
  let project_id = Number(param.id);
  const [assignee, setAssignee] = useState(null);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [ticket_id, setTicket_id] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleError, setScheduleError] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [untiTicketList, setUntiTicketList] = useState([]);
  const [lotDetails, setLotDetails] = useState({});

  function formatDate(value) {
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  //// Function For Close Modal Start //////
  function handleClose() {
    setShowTicketModal(false);
    setShowEditModal(false);
    setTicket_id(null);
    setShowScheduleModal(false);
    setShowAddMemberModal(false);
  }
  /////Function For Close Modal End //////

  async function kanbanSchedule() {
    if (tabKey === "kanban" && kanbanLotId) {
      const url = baseUrl + "getboarduntitickets";
      var body = {
        user_id,
        project_id,
        assignee,
        lot_id: kanbanLotId,
      };
      axios
        .post(url, body, config)
        .then(async (res) => {
          if (res.status === 200) {
            let ticketList = await res?.data?.response?.BoardUntiTicketsList;
            ticketList.Lots.length && setLotDetails(ticketList.Lots[0]);
            delete ticketList.Lots;
            delete ticketList.Schedule;
            setUntiTicketList(ticketList);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data.response === "Sorry, No record found") {
            setScheduleError(true);
          }
        });
    }
  }


  function getTicketId(id) {
    setTicket_id(id);
  }

  function modalShow() {
    setShowEditModal(true);
  }

  useEffect(() => {
    kanbanSchedule();
  }, [tabKey, assignee, kanbanLotId]);

  useEffect(() => {
    setLoading(true);
  }, [tabKey]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <section>
          <div className="container-fluid">
            <div className="row mb-3 backoffice-kanban">
              <div className="col-md-4">
                <h4>
                  {`${lotDetails?.poLoaQty} Coaches LOT`}{" "}
                  <span className="font-14">
                    (
                    {formatDate(lotDetails?.startDate) +
                      " - " +
                      formatDate(lotDetails?.completionDate)}
                    )
                  </span>
                </h4>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-1"> </div>

                  {projectMembers.length ? (
                    <>
                      <div className="col-md-4">
                        <div className="form-group">
                          <div className="w-100 d-block">
                            <select
                              className="form-select border-danger"
                              id=""
                              value={kanbanLotId}
                              onChange={(e) => setKanbanLotId(e.target.value)}
                            >
                              <option disabled selected hidden>
                                Select Lot
                              </option>

                              {lotData?.map((e, i) => {
                                return (
                                  <option value={e.id} key={i}>
                                    LOT {i + 1} - ({e.poLoaQty})
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group d-flex align-items-sm-center">
                          <div className="w-100 d-block">
                            <select
                              className="form-select border-danger"
                              id=""
                              onChange={(e) => setAssignee(e.target.value)}
                            >
                              <option disabled selected hidden>
                                Sort By Assignee
                              </option>
                              <option value="">All Ticket</option>
                              {projectMembers?.map((e, i) => {
                                return (
                                  <option value={e.user_id} key={i}>
                                    <p>{e.name}</p>
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 text-end">
                        <button
                          type="button"
                          className="btn w-100 btn-danger btn-sm"
                          onClick={() => setShowTicketModal(true)}
                        >
                          Create Ticket
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-11 text-end">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => setShowAddMemberModal(true)}
                        >
                          Add Members
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="kanban-board backoffice-kanban">
              {Object.keys(untiTicketList).map((key) => (
                <StatusList
                  workStatus={untiTicketList[key]}
                  getTicketId={getTicketId}
                  kanbanSchedule={kanbanSchedule}
                  userData={userData}
                  projectMembers={projectMembers}
                  modalShow={modalShow}
                  currentStatus={key}
                ></StatusList>
              ))}
            </div>
          </div>
        </section>
      )}

      <TicketModal
        showTicketModal={showTicketModal}
        handleClose={handleClose}
        ticketTypeDropdown={ticketTypeData}
        kanbanSchedule={kanbanSchedule}
        projectMembers={projectMembers}
        lotData={lotData}
        poStartDate={poStartDate}
      />
      <EditTicketModal
        showEditModal={showEditModal}
        handleClose={handleClose}
        editTicketId={ticket_id}
        scheduleId={scheduleId}
        kanbanSchedule={kanbanSchedule}
        projectMembers={projectMembers}
        lotData={lotData}
      />
      <ScheduleModal
        show={showScheduleModal}
        handleClose={handleClose}
        kanbanSchedule={kanbanSchedule}
        getScheduleList={getScheduleList}
      />

      <AddMembersModal
        show={showAddMemberModal}
        handleClose={handleClose}
        project_id={project_id}
        fetchProjectDetails={fetchProjectDetails}
      ></AddMembersModal>
    </>
  );
}

export default KanbanBoard;
