import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getRoleList } from "../../redux/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import AddMembersModal from "../../Modals/AddMembersModal";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

export default function Access({ tabKey, fetchProjectDetails }) {
  // console.log("Project detail on access", fetchProjectDetails);

  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const param = useParams();
  let project_id = Number(param.id);
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const [userByRoles, setUserByRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [memberId, setMemberId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setDeleteModal(false);
    setShowAddMemberModal(false);
  };

  function getMembers(value) {
    setLoading(true);
    let url = baseUrl + "getprojectmemberrolewise";
    const data = { user_id, project_id, search: value };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          fetchProjectDetails();
          setLoading(false);
          setUserByRoles(response?.data?.response?.memberRoleWise);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function getRolesList() {
    getRoleList({ user_id: user_id })
      .then((res) => {
        setRoleList(res?.data?.response?.roleList);
      })
      .catch((err) => {});
  }

  function changeRole(id) {
    let url = baseUrl + "changeprojectmemberrole";
    const data = { user_id, project_id, member_id: memberId, role_id: id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          getMembers();
        }
      })
      .catch((error) => {});
  }

  function deleteProjectMember() {
    let url = baseUrl + "deleteprojectmember";
    const data = { user_id, project_id, member_id: memberId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          getMembers();
          toast.success("User Delete successfully from this Project");
        }
      })
      .catch((error) => {
        handleClose();
      });
  }

  async function handleChange(e) {
    await setSearch(e.target.value);
    getMembers(e.target.value);
  }

  useEffect(() => {
    if (tabKey === "settings") {
      getMembers();
      getRolesList();
    }
  }, [tabKey]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <div className="card p-0">
          <div className="card-body p-0">
            <div className="row mb-3">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end">
                  <ul className="list-inline m-0">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control border-danger"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => handleChange(e)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddMemberModal(true)}
                      >
                        Add Members
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Roles</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userByRoles?.map((element) => {
                    return (
                      <tr key={element.id}>
                        <td>
                          <img
                            src={imageBaseUrl + element?.profileimage}
                            alt=""
                            className="rounded-circle thumb-sm me-1"
                          />{" "}
                          {element?.name}
                        </td>
                        <td>{element?.email}</td>

                        <td>
                          <div onClick={() => setMemberId(element.id)}>
                            <Select
                              placeholder="Select Project"
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  zIndex: "1000",
                                }),
                              }}
                              options={roleList}
                              getOptionLabel={(roleList) => roleList["name"]}
                              getOptionValue={(roleList) => roleList["id"]}
                              value={element.roleData}
                              onChange={(value) => changeRole(value.id)}
                            />
                          </div>
                        </td>
                        <td>
                          <i
                            onClick={() => {
                              setDeleteModal(true);
                              setMemberId(element.id);
                            }}
                            className="las la-trash-alt text-dark"
                            style={{ fontSize: "24px", cursor: "pointer" }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <Modal show={deleteModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-secondary text-center mx-3">
            Are you sure, Do you want to Delete this?
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteProjectMember}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <AddMembersModal
        show={showAddMemberModal}
        handleClose={handleClose}
        fetchProjectDetails={getMembers}
        project_id={project_id}
      />
    </>
  );
}
