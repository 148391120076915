import React from "react";
import image from "../../Assets/Group 1000003296.png";

function DepartmentSummary() {
  return (
    <div
      style={{
        border: "1px solid rgba(207, 207, 207, 1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 1)",
        padding: "1.5rem",
      }}
      className="legalEntityRegisteredAddress"
    >
      <div>
        <p className="mb-3"> Business Head</p>
        <div className="d-flex gap-3 align-items-center mb-3">
          <img
            src={image}
            className="rounded-circle"
            style={{ height: "3rem", width: "3rem" }}
          />
          <p>Ritik</p>
        </div>
        <div>
          <h5>Description</h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
        </div>
      </div>
    </div>
  );
}

export default DepartmentSummary;
