import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";

import ShowError  from "../../Organisation/organisationStructure/OrganizationComponents/ShowError"
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { addWorkerType, updateWorkerType } from "../../hrApi";
import { fetchWorkerType } from "../../../redux/features/Hr/WorkerTypeSlice";
import { workerTypeSchema } from "../EmployeeSchema";

// import ShowError from "../OrganizationComponents/ShowError";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px",
    minWidth: "691px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const AddWorkTypeModal = ({ open, setAddWorkerType, editData }) => {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const [validationError, setValidationError] = useState(null);

  const [minContractDurationChecked, setMinContractDurationChecked] =
    useState(false);
  const [maxContractDurationChecked, setMaxContractDurationChecked] =
    useState(false);

  // const handlechekboxChange = (event) => {
  //   const { id, checked } = event.target;

  //   if (id === "min_contract") {
  //     setMinContractDurationChecked(checked);
  //     if (!checked) setFieldValue("min_contract_duration", null);
  //   }
  //   if (id === "max_contract") {
  //     setMaxContractDurationChecked(checked);
  //     if (!checked) setFieldValue("max_contract_duration", null);
  //   }
  // };
  const handleClose = () => {
    setAddWorkerType(false);
  };
  const initialValues = {
    name: "",
    description: "",
    min_contract_duration: "",
    max_contract_duration: "",
    minContractCheckbox: false, // Add initial value for checkbox
    maxContractCheckbox: false,
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema:workerTypeSchema,
    onSubmit: (values) => {
      if (editData) {
        workerTypeUpdate();
      } else {
        workerTypeAdd();
      }
    },
  });
  const handlechekboxChange = (event) => {
    const { id, checked } = event.target;
  
    if (id === "min_contract") {
      setMinContractDurationChecked(checked);
      setFieldValue("minContractCheckbox", checked); // Update Formik state
      if (!checked) setFieldValue("min_contract_duration", null); // Reset duration if unchecked
    }
  
    if (id === "max_contract") {
      setMaxContractDurationChecked(checked);
      setFieldValue("maxContractCheckbox", checked); // Update Formik state
      if (!checked) setFieldValue("max_contract_duration", null); // Reset duration if unchecked
    }
  };

  useEffect(() => {
    if (editData) {
      setValues(editData);
      setMinContractDurationChecked(!!editData?.min_contract_duration);
      setMaxContractDurationChecked(!!editData?.max_contract_duration);
      setFieldValue("minContractCheckbox", !!editData?.min_contract_duration);
      setFieldValue("maxContractCheckbox", !!editData?.max_contract_duration);
    } else {
      resetForm();
      setMinContractDurationChecked(false);
      setMaxContractDurationChecked(false);
    }
  }, [editData, open]);
  const workerTypeAdd = async () => {
    dispatch(setLoading(true));

    try {
      const response = await addWorkerType(values);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchWorkerType());
      handleClose();
    } catch (err) {
      setValidationError(err?.response?.data?.response);

      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const workerTypeUpdate = async () => {
    dispatch(setLoading(true));
    const reqpayload = { id: editData?.id, ...values };
    try {
      const response = await updateWorkerType(reqpayload);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchWorkerType());
      handleClose();
    } catch (err) {
      setValidationError(err?.response?.data?.response);

      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
console.log("errors",errors)
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {`${editData ? "Update" : "Add"}  Type`}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="name"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.name}
                      message={errors.name}
                      validationError={validationError?.name}
                    />
                      
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      multiline={true}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="large"
                      id=""
                      rows={5}
                      label="Description"
                      name="description"
                      value={values.description}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                      <ShowError
                      touched={touched.description}
                      message={errors.description}
                      validationError={validationError?.description}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <Checkbox
                    id="min_contract"
                    checked={minContractDurationChecked}
                    onChange={handlechekboxChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="minContractCheckbox"
                  />
                  <span> set a minimum contract duration</span>
                </div>
                {minContractDurationChecked ? (
                  <div className="col-6 mb-2">
                    <CustomFormControl fullWidth>
                      <CustomTextField
                        name="min_contract_duration"
                        value={values.min_contract_duration}
                        label="Duration"
                          type="number"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    
                        id="outlined-start-adornment"
                        // { style: { fontSize: ".95rem" } }
                        InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              month
                            </InputAdornment>
                          ),
                        }}
                      ></CustomTextField>
                        <ShowError
                      touched={touched.min_contract_duration}
                      message={errors.min_contract_duration}
                      validationError={validationError?.min_contract_duration}
                    />
                    </CustomFormControl>
                  </div>
                ) : (
                  <div className="col-6 mb-2"></div>
                )}
                <div className="col-6 mb-2">
                  <Checkbox
                    id="max_contract"
                    checked={maxContractDurationChecked}
                    onChange={handlechekboxChange}
                    inputProps={{ "aria-label": "controlled" }}
                    name="maxContractCheckbox"
                  />
                  <span> set a maximum contract duration</span>
                </div>
                {maxContractDurationChecked ? (
                  <div className="col-6 mb-2">
                    <CustomFormControl fullWidth>
                      <CustomTextField
                        name="max_contract_duration"
                        type="number"
                        value={values.max_contract_duration}
                        label="Duration"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="outlined-start-adornment"
                        // { style: { fontSize: ".95rem" } }
                        InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              month
                            </InputAdornment>
                          ),
                        }}
                      ></CustomTextField>
                        <ShowError
                      touched={touched.max_contract_duration}
                      message={errors.max_contract_duration}
                      validationError={validationError?.max_contract_duration}
                    />
                    </CustomFormControl>
                  </div>
                ) : (
                  <div className="col-6 mb-2"></div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Update Type "
                ) : (
                  "Add Type"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default AddWorkTypeModal;
