import React,{useState} from "react";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
function InventoryForm({
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  spinner
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const projectDropDown = useSelector(
    (state) => state?.dropdown?.dropdowns?.projectList
  );
  const [selectedproject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const[projectPO,setProjectPO] = useState([])
  const [selectedPO, setSelectedPO] = useState(null);
  const handleProjectChange = (selectedOption) => {
    
    // setPoDetail([]);
    setSelectedProject(selectedOption);
    setSelectedProjectId(selectedOption?.id);

    projectPoDetail(selectedOption?.id);
    // setFieldValue
  };
  const projectPoDetail = (project_id) => {
    const data = { user_id, project_id };
    const url = `${baseUrl}fetchpodetailsproject`;
    setProjectPO([]);
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setProjectPO(res?.data?.response?.PurchaseOrderDetails);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
           
              <div className="card">
                <div className="card-body">
                <h3 className="mb-4"> Add</h3>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-4">
                      <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Project
                          </label>
                        <div className="input-group me-3">
                      
                        <Select
                  placeholder="Search By Title"
                  style={{ width: "79%" }}
                  className="form-select-control border-secondary w-75"
                  name="project_id"
                  isSearchable={true}
                  options={projectDropDown}
                  getOptionLabel={(projectDropDown) =>
                    projectDropDown["project_title"]
                  }
                  getOptionValue={(projectDropDown) => projectDropDown["id"]}
                  value={selectedproject}
                  onChange={handleProjectChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: "lightgrey",
                      primary: "lightgrey",
                    },
                  })}
                />
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            // style={{ zIndex: "-1" }}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                           PO Number
                          </label>
                          <select
                name="po_no"
                value={selectedPO}
                onChange={(e) => {
                  setSelectedPO(e.target.value);
                }}
                className="form-control"
              >
                <option value="">Select</option>
                {projectPO?.map((projectPO) => (
                  <option
                    key={projectPO?.id}
                    value={projectPO?.purchase_order_no}
                  >
                    {projectPO?.purchase_order_no}
                  </option>
                ))}
              </select>
                        </div>
                      </div>
                 
                    </div>
                    
                    <div className="col-md-12 pt-4">
                    <table class="table ">
                        <thead>
                          <tr >
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>MATERIAL NAME</th>
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>Available</th>
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>Used</th>
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>Unused</th>
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>Scrap</th>
                            <th scope="col" style={{color:"#b9b9b9",fontSize:"0.9rem"}}>Action</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{border:"1px solid rgb(236, 239, 245)"}}>
                            <th scope="row">Aman Sharma</th>
                            <td>1123456</td>
                            <td>800</td>
                            <td>Unit</td>

                            <td>₹ 0.00</td>
                            <Link to={"/AddMaterialForm"}> <td className="pt-4">
                              <FaRegEdit style={{ fontSize: "20px" }} />
                            </td></Link>
                     
                          </tr>
                          <tr style={{border:"1px solid rgb(236, 239, 245)"}}>
                            <th scope="row">Aman Sharma</th>
                            <td>1123456</td>
                            <td>800</td>
                            <td>Unit</td>

                            <td>₹ 0.00</td>
                            <Link to={"/AddMaterialForm"}> <td className="pt-4">
                              <FaRegEdit style={{ fontSize: "20px" }} />
                            </td></Link>
                     
                          </tr>
                          <tr style={{border:"1px solid rgb(236, 239, 245)"}}>
                            <th scope="row">Aman Sharma</th>
                            <td>1123456</td>
                            <td><input type="text" style={{borderRadius: "0.2rem",padding: "0.6rem",border: "1px solid #8D93A1"}} /></td>
                            <td><input type="text" style={{borderRadius: "0.2rem",padding: "0.6rem",border: "1px solid #8D93A1"}} /></td>

                            <td><input type="text" style={{borderRadius: "0.2rem",padding: "0.6rem",border: "1px solid #8D93A1"}} /></td>
                            <Link to={"/AddMaterialForm"}> <td className="pt-4">
                              <FaRegEdit style={{ fontSize: "20px" }} />
                            </td></Link>
                     
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryForm;
