import React, { useEffect, useState } from "react";
import LeagalEntityModal from "./LeagalEntityModal";
import OrgLegalEntities from "../OrganizationComponents/OrganizationStructureAsideList";
import LegalEntityHeader from "./LegalEntityHeader";
import Loader from "../../../CommonComponent/Loader";
import NoDataFound from "../../../CommonComponent/NoDataFound";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchLegalEntity,
  getLegalEntity,
} from "../../../../redux/features/Hr/LegalEntitySlice";
import { selectLoading } from "../../../../redux/features/Hr/LoaderSlice";
import MainCard from "../../../CommonComponent/MainCard";
function LegalEntitiesParent({ tabvalue }) {
  const [open, setOpen] = useState(false);
  const [legalEntity, setLegalEntity] = useState("");

  const [editData, setEditData] = useState(null);

  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const legalEntitiesList = useSelector(getLegalEntity);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  function getEditData(legalEntitiesList) {
    setEditData(legalEntitiesList);
    setOpen(true);
  }

  useEffect(() => {
    dispatch(fetchLegalEntity(dispatch));
  }, []);
  useEffect(() => {
    if (legalEntitiesList?.length > 0) {
      setLegalEntity(legalEntitiesList?.[0]);
    }
  }, [legalEntitiesList]);
  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0 text-uppercase fw-normal">
          ORGANISATION STRUCTURE
        </h4>
        <button className="hr btn btn-primary" onClick={handleClickOpen}>
          + Add Legal Entity
        </button>
        <LeagalEntityModal
          open={open}
          handleClose={handleClose}
          editData={editData}
        />
      </div>
      {/* <div className="col-md-3">
        <div
          style={{
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.25)",
            background: "rgba(255, 255, 255, 1)",
            border: "1px",
            borderRadius: "10px",
            padding: "1rem",
          }}
        >
          {loader ? (
            <Loader />
          ) : legalEntitiesList?.length > 0 ? (
            legalEntitiesList?.map((entityDetail, index) => (
              <OrgLegalEntities
                organizationDataList={entityDetail}
                key={index}
                setOrgDetail={setLegalEntity}
                orgDetail={legalEntity}
                name={entityDetail?.entity_name}
                image={entityDetail?.image}
              />
            ))
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      <div className="col-md-9">
        <LegalEntityHeader
          legalEntity={legalEntity}
          getEditData={getEditData}
        />
      </div> */}
      <MainCard
        asideContent={
          loader ? (
            <Loader />
          ) : legalEntitiesList?.length > 0 ? (
            legalEntitiesList?.map((entityDetail, index) => (
              <OrgLegalEntities
                organizationDataList={entityDetail}
                key={index}
                setOrgDetail={setLegalEntity}
                orgDetail={legalEntity}
                name={entityDetail?.entity_name}
                image={entityDetail?.image}
              />
            ))
          ) : (
            <NoDataFound />
          )
        }
        tabContent={
          <LegalEntityHeader
            legalEntity={legalEntity}
            getEditData={getEditData}
          />
        }
        asideHeading={"LEGAL ENTITIES"}
      />
    </div>
  );
}

export default LegalEntitiesParent;
