import React from "react";
import { Navigate } from "react-router-dom";

function HrPrivateRoute({ children }) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const departmentName = user?.response?.userDetails?.departmentName;

  return departmentName === "Human Resource" ? children : <Navigate to="/" />;
}

export default HrPrivateRoute;
