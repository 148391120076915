import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
    
//   </Box>
// );

const cardContent = (
  <React.Fragment>
   <p>jhhhhhh</p>
  </React.Fragment>
);

export default function CommonCard({count,description,icon,color}) {
  return (
    <Box sx={{ minWidth: 180,maxWidth: 180 }}>
      <Card className='rounded border-0' variant="outlined" sx={{minHeight:90,maxHeight:90}}>
<div className='d-flex justify-content-between align-items-center px-2'>
    <h3 className='fw-bolder' style={{color:color}}>{count}</h3>
   {icon}
</div>
<h6 className="mx-2">{description}</h6>


      </Card>
    </Box>
  );
}

