import * as Yup from "yup";
const priceValidation = Yup.string()
  .required("Price is required")
  .matches(/^\d+(\.\d{1,2})?$/, "Price must be a valid number");
export const DeliveryChallanSchema = Yup.object().shape({
  unit_id: Yup.string().required("This field is required"),
  document_type: Yup.string().required("This field is required"),

  document_path: Yup.string().required("This field is required"),


  price: Yup.number().nullable()
    .typeError("You must specify a number")
    .when("document_type", {
      is: "delivery_challan",
      then: (schema) => schema.required("Price field is required "),
      otherwise: (schema) => schema,
    }),
  tax_invoice_no: Yup.string().nullable()
  .when("document_type", {
    is: "tax_invoice",
    then: (schema) => schema.required("Invoice field is required "),
    otherwise: (schema) => schema,
  }),
  // tax_invoice_no: Yup.string().nullable()
  // .when("document_type", {
  //   is: "tax_invoice",
  //   then: Yup.string().required("Invoice field is required "),
  //   otherwise: Yup.string(),
  // }),
});
