import React, { useCallback, useEffect, useState } from "react";
import InvoiceStructure from "./InvoiceStructure";
import CardBody from "./CardBody";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { toast } from "react-toastify";
import {
  addTaxInvoiceApi,
  unnatiProjectDropDownApi,
} from "../commonComponents/storeApi";
import { debounce } from "lodash";
import { addTaxInvoiceSchema } from "./addInvoiceSchema";

function AddInvoice() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [spinner, setSpinner] = useState(false);
  const [serverSideValidation, setServerSideValidation] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValue = {
    invoice_no: "",
    invoice_date: "",
    project_id: "",
    invoice_pdf: "",
    user_id: "",
  };
  const navigate = useNavigate();

  function projectDropDown(term) {
    setLoading(true);
    const data = {
      user_id,
      search: term,
    };
    unnatiProjectDropDownApi(data)
      .then((response) => {
        if (response.status === 200) {
          setProjectList(response?.data?.response?.projectList);
        }
      })
      .catch((error) => {
        //setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function addTaxInvoiceDetail(values) {
    const data = {
      user_id,
      ...values,
    };
    setSpinner(true);
    addTaxInvoiceApi(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Tax Invoice Added successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        //setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  const debouncedFetchProjectDropDown = useCallback(
    debounce((term) => {
      projectDropDown(term);
    }, 800),
    [user_id]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchProjectDropDown(searchTerm);
    }
  }, [searchTerm, debouncedFetchProjectDropDown]);

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: addTaxInvoiceSchema,
    onSubmit: (values) => {
      addTaxInvoiceDetail(values);
    },
  });
  return (
    <form>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid mt-4">
            <h4 className="ms-3 mb-4">Add Tax Invoice</h4>

            <CardBody>
              <InvoiceStructure
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                values={values}
                setFieldValue={setFieldValue}
                projectList={projectList}
                loading={loading}
                setSearchTerm={setSearchTerm}
              />
              <div className="text-end mt-3">
                <button
                  className="btn btn-danger mx-2"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>

                <button
                  className="btn btn-danger"
                  onClick={handleSubmit}
                  values={values}
                >
                  Save
                </button>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddInvoice;
