import Modal from "react-bootstrap/Modal";
import CommonIframe from "../Common/CommonIframe";

function EmdTrackerAttachmentModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ minHeight: "40rem", maxHeight: "40rem" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Attachments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonIframe
            attachments={props.data}
            renderState={props.renderState}
            setRenderState={props.setRenderState}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EmdTrackerAttachmentModal;
