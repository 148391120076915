import { useEffect, useState } from "react";
import React from "react";
// import {jobTitleList}
import JobWorkTimeCommonTable from "../../MaterialUiComponent/JobWorkTimeCommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobTitle,
  getjobTitle,
} from "../../../redux/features/Hr/JobTitleSlice";
import { deleteJobTitle } from "../../hrApi";
import { toast } from "react-toastify";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { toTitleCase } from "../../CommonFunction";

const JobTabMain = ({ getEditData }) => {
  const [deleteModalOPen, setDeleteModalOPen] = useState(false);
  const loader = useSelector(selectLoading);
  const dispatch = useDispatch();
  const jobList = useSelector(getjobTitle);

  useEffect(() => {
    dispatch(fetchJobTitle(dispatch));
  }, []);
  const handleClose = () => {
    setDeleteModalOPen(false);
  };
  const jobTitleDelete = async (id) => {
    dispatch(setLoading(true));
    const reqpayload = { id: id };
    try {
      const response = await deleteJobTitle(reqpayload);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchJobTitle(dispatch));
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const columns = [
    { id: "title", label: "Name ", minWidth: "6rem",format:toTitleCase }, // Increase minWidth
    { id: "description", label: "Description", minWidth: "30rem" }, // Increase minWidth
    { id: "appliesTo", label: "Applies To", minWidth: "8rem" }, // Increase minWidth
    { id: "actions", label: "Action", minWidth: 100 }, // Keep as is
  ];

  return (
    <section className="inProbation">
      <div className="bg-white mt-2 rounded mx-3">
        <div className="d-flex justify-content-between align-items-center px-3 mb-3 ">
          <h4 className="text-uppercase">Job Title</h4>
          <div className="d-flex align-items-center">
            <input
              className="days_selector_style mb-lg-2 mb-3 mr-lg-3"
              type="text"
              placeholder="Search.."
            />
            <button className="search_Button" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
        <JobWorkTimeCommonTable
          columns={columns}
          rows={jobList}
          getEditData={getEditData}
          deleteData={jobTitleDelete}
          loader={loader}
          handleClose={handleClose}
          deleteModalOPen={deleteModalOPen}
          setDeleteModalOPen={setDeleteModalOPen}
        />
      </div>
    </section>
  );
};

export default JobTabMain;
