import { useEffect, useState } from "react";
import axios from "axios";
import { errorsMessage } from "../../constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import VehicleRequestCard from "./VehicleRequestCard";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const VehicleRequestListing = () => {
  const [key, setKey] = useState("home");

  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };

  const [storeVehicleRequestList, setStoreVehicleRequestList] = useState([]);

  const [requestStatus, setRequestStatus] = useState(0);
  const [activeTab, setActiveTab] = useState("0");

  const getVehicleRequestList = (requestStatus = 0) => {
    const url = `${baseUrl}listvehiclerequest`;
    const data = { user_id, request_status: requestStatus };
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status == 200) {
          setStoreVehicleRequestList(
            res?.data?.response?.StoreVehicleRequestList
          );
        }
      })
      .catch((err) => errorsMessage(err?.response?.status));
  };

  const handleTabChange = (eventkey, event) => {
    
   
    // setActiveTab(event.target.innerText)
    setActiveTab(eventkey);
    setRequestStatus(eventkey);
    getVehicleRequestList(eventkey);
  };
  
  useEffect(() => {
    getVehicleRequestList();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="d-flex justify-content-between align-items-center my-4">
                  <h4   style={{fontSize:"24px", fontWeight:"500"}}>VEHICLE REQUEST</h4>
                  <Link to={"/vehicle-request-list/add-Vehicle-request"}>
                    <button className="btn btn-danger">New Request </button>
                  </Link>
                </div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={activeTab}
                  onSelect={(eventkey, event) =>
                    handleTabChange(eventkey, event)
                  }
                  className="mb-3"
                  style={{ fontSize: "1.2rem", fontWeight: 600 }}
                >
                  <Tab eventKey="0" title="Pending">
                    <div className="row row-cols-2">
                      {storeVehicleRequestList?.length?storeVehicleRequestList?.map((request) => {
                        return (
                          <VehicleRequestCard
                            request={request}
                            setStoreVehicleRequestList={
                              setStoreVehicleRequestList
                            }
                          />
                        );
                      }):<div><p>No Request found</p></div>}
                    </div>
                  </Tab>
                  <Tab eventKey="1" title="Approved">
                    <div className="row row-cols-2">
                    {storeVehicleRequestList?.length?storeVehicleRequestList?.map((request) => {
                        return (
                          <VehicleRequestCard
                            request={request}
                            setStoreVehicleRequestList={
                              setStoreVehicleRequestList
                            }
                          />
                        );
                      }):<div><p>No Request found</p></div>}
                    </div>
                  </Tab>
                  <Tab eventKey="2" title="Reject">
                    <div className="row row-cols-2">
                    {storeVehicleRequestList?.length?storeVehicleRequestList?.map((request) => {
                        return (
                          <VehicleRequestCard
                            request={request}
                            setStoreVehicleRequestList={
                              setStoreVehicleRequestList
                            }
                          />
                        );
                      }):<div><p>No Request found</p></div>}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleRequestListing;
