import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { addPurchaseVechileMasterApi } from "../purchaseApi";
import { toast } from "react-toastify";
import axios from "axios";
import { errorsMessage } from "../../constant";
import { vehicleMasterSchema } from "../ValidationSchema/SettingSchema";

function VehicleMasterModal({ show, setShow, updatedList, editData, url }) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [serverSideValidation, setServerSideValidation] = useState();

  const initialValue = {
    transporter_name: "",
    vehicle_no: "",
    vehicle_name: "",
    vehicle_model: "",
    tax_renewal_date: "",
    insurance_renewal_date: "",
    pollution_renewal_date: "",
    fitness_renewal_date: "",
    permit: "",
    permit_days: "",
    permit_date: "",
    driver_name: "",
  };
  console.log("edit data", editData);
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: vehicleMasterSchema,
    onSubmit: (values) => {
      addData(values);
    },
  });

  // function addData(value){
  //   const data = {...value , user_id}
  //   addPurchaseVechileMasterApi(data).then((response)=>{
  //     setShow(false)
  //     updatedList()
  //     toast.success("Form ")
  //    }).catch((error)=>{
  //     console.log("error" , error)
  //    })
  // }

  function addData(values) {
    // setLoadingTicketId(ticket);
    const apiUrl = baseUrl + url;
    const data = {
      user_id,
      ...values,
      ...(editData ? { vehicle_master_id: editData.id } : {}),
    };
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          setShow(false);
          resetForm();
          updatedList();
          toast.success("Form submit successfully");
        }
      })
      .catch((error) => {
        setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        // setLoadingTicketId(null);
      });
  }

  useEffect(() => {
    if (editData) {
      setValues(editData);
    }
  }, [editData]);

  return (
    <>
      <div style={{ minWidth: "467px", marginTop: "400px" }}>
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Vehicle Master</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              margin: "20px 10px 20px 0",
              padding: "0 10px 0 10px",
            }}
          >
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16 ">
                Transporter Name <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-secondary "
                onChange={handleChange}
                value={values.transporter_name}
                onBlur={handleBlur}
                name="transporter_name"
              >
                <option value={"+"}>Select</option>
                <option value="Transporter xyz">Transporter xyz</option>
              </select>
              <p className="text-danger m-0">
                {touched?.transporter_name && errors?.transporter_name
                  ? errors?.transporter_name
                  : serverSideValidation?.transporter_name}
              </p>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Vehicle No
              </label>
              <input
                className="form-control border-secondary"
                placeholder="Enter Vehicle No."
                onChange={handleChange}
                value={values.vehicle_no}
                name="vehicle_no"
              />
              <p className="text-danger m-0">
                {touched?.vehicle_no && errors?.vehicle_no
                  ? errors?.vehicle_no
                  : serverSideValidation?.vehicle_no}
              </p>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16 ">
                Vehicle Name
              </label>
              <input
                className="form-control border-secondary"
                placeholder="Enter Vehicle Name"
                onChange={handleChange}
                value={values.vehicle_name}
                name="vehicle_name"
              />
              <p className="text-danger m-0">
                {touched?.vehicle_name && errors?.vehicle_name
                  ? errors?.vehicle_name
                  : serverSideValidation?.vehicle_name}
              </p>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="" className="form-label fw-bold font-16 ">
                Vehicle Model
              </label>
              <input
                className="form-control border-secondary"
                placeholder="Enter Vehicle Model"
                onChange={handleChange}
                value={values.vehicle_model}
                name="vehicle_model"
              />
              <p className="text-danger m-0">
                {touched?.vehicle_model && errors?.vehicle_model
                  ? errors?.vehicle_model
                  : serverSideValidation?.vehicle_model}
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Tax Renewal Date
                  </label>
                  <input
                    className="form-control border-secondary"
                    type="date"
                    onChange={handleChange}
                    value={values.tax_renewal_date}
                    name="tax_renewal_date"
                  />
                  <p className="text-danger m-0">
                    {touched?.tax_renewal_date && errors?.tax_renewal_date
                      ? errors?.tax_renewal_date
                      : serverSideValidation?.tax_renewal_date}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Insurance Renewal Date
                  </label>
                  <input
                    className="form-control border-secondary"
                    type="date"
                    onChange={handleChange}
                    value={values.insurance_renewal_date}
                    name="insurance_renewal_date"
                  />
                  <p className="text-danger m-0">
                    {touched?.insurance_renewal_date &&
                    errors?.insurance_renewal_date
                      ? errors?.insurance_renewal_date
                      : serverSideValidation?.insurance_renewal_date}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Pollution Renewal Date
                  </label>
                  <input
                    className="form-control border-secondary"
                    type="date"
                    onChange={handleChange}
                    value={values.pollution_renewal_date}
                    name="pollution_renewal_date"
                  />
                  <p className="text-danger m-0">
                    {touched?.pollution_renewal_date &&
                    errors?.pollution_renewal_date
                      ? errors?.pollution_renewal_date
                      : serverSideValidation?.pollution_renewal_date}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Fitness Renewal Date
                  </label>
                  <input
                    className="form-control border-secondary"
                    type="date"
                    onChange={handleChange}
                    value={values.fitness_renewal_date}
                    name="fitness_renewal_date"
                  />
                  <p className="text-danger m-0">
                    {touched?.fitness_renewal_date &&
                    errors?.fitness_renewal_date
                      ? errors?.fitness_renewal_date
                      : serverSideValidation?.fitness_renewal_date}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Permit
                  </label>
                  <input
                    className="form-control border-secondary"
                    placeholder="Enter Permit"
                    onChange={handleChange}
                    value={values.permit}
                    name="permit"
                  />
                  <p className="text-danger m-0">
                    {touched?.permit && errors?.permit
                      ? errors?.permit
                      : serverSideValidation?.permit}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3 ">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Permit Days
                  </label>
                  <select
                    className="form-control border-secondary"
                    onChange={handleChange}
                    value={values.permit_days}
                    name="permit_days"
                  >
                    <option>Select</option>
                    <option value="14">14</option>
                  </select>
                  <p className="text-danger m-0">
                    {touched?.permit_days && errors?.permit_days
                      ? errors?.permit_days
                      : serverSideValidation?.permit_days}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Permit Date
                  </label>
                  <input
                    className="form-control border-secondary"
                    type="date"
                    onChange={handleChange}
                    value={values.permit_date}
                    name="permit_date"
                  />
                  <p className="text-danger m-0">
                    {touched?.permit_date && errors?.permit_date
                      ? errors?.permit_date
                      : serverSideValidation?.permit_date}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label fw-bold font-16 ">
                    Driver Name
                  </label>
                  <input
                    className="form-control border-secondary"
                    placeholder="Enter Driver Name"
                    onChange={handleChange}
                    value={values.driver_name}
                    name="driver_name"
                  />
                  <p className="text-danger m-0">
                    {touched?.driver_name && errors?.driver_name
                      ? errors?.driver_name
                      : serverSideValidation?.driver_name}
                  </p>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button className="btn btn-danger" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default VehicleMasterModal;
