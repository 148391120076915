import React from "react";
import Ellipse from "../../../Assets/Ellipse.png";
import "../../HrDepartment.scss"

import { GiConfirmed } from "react-icons/gi";
const AttendanceDetail = () => {
  return (
    <>
    
        <div className="card ">
          <h4 className="attendance-stats p-3">Attendance Stats</h4>
          <div className="row">
            <div className="col-md-12 border-top"></div>
            <div className="col-md-6 py-2 px-2">
              <div className="d-flex align-items-center">
                <div className="p-4">
                  <img src={Ellipse} />
                </div>
                <div>
                  <h4 className="emp-name-attendance">Ajay Kumar Singh</h4>
                </div>
              </div>
             
                       
            </div>
            <div className="col-md-3 pt-2">
              <p className="attendance-stats-heading pt-3">Avg Hrs / Day</p>
              <h4 className="attendance-stats-value">8 Hr 02 Min</h4>
            </div>
            <div className="col-md-3 pt-2">
              <p className="attendance-stats-heading pt-3">On Time Arrival</p>
              <h4 className="attendance-stats-value ps-4">73%</h4>
            </div>
          </div>
        </div>
       
         
      
     <div className="row">
    
     <div class="table-responsive ms-0 me-0">
     <div className='card mb-0'>
          <div className="d-flex align-items-center justify-content-between">
        
            <h4 className="attendance-stats ps-3">Attendance Logs</h4>
            <select className="days_selector_style mb-3" style={{padding: ".5rem 4rem .5rem 3rem"  }} >
              <option>Last 30 Days </option>
              <option>Last 15 Days </option>
            </select>
            </div>
        
          </div>
  <table class="table table-bordered ">
    <thead >
      <tr>
        <th>Days</th>
        <th>Punch IN</th>
        <th>Punch OUT</th>
        <th>Gross Hours</th>
        <th>Effective Hours</th>
        <th>Logs</th>
      </tr>
    </thead>
    <tbody >
 
      <tr className="border-bottom">
        <td>06 June, Thu</td>
        <td style={{color:"#399600"}}>10:02 AM</td>
        <td style={{color:"#D90000"}}>07:10 PM</td>
        <td>9 Hrs 8 Min</td>
        <td>8 Hrs 45 Min</td>
        {/* <td><img src={vector}></img></td> */}
        <td><GiConfirmed style={{color:"#399600", width: "24px",
    height: "50px "}}/></td>
      </tr>
      
      <tr className="border-bottom">
        <td>06 June, Thu</td>
        <td style={{color:"#399600"}}>10:02 AM</td>
        <td style={{color:"#D90000"}}>07:10 PM</td>
        <td>9 Hrs 8 Min</td>
        <td>8 Hrs 45 Min</td>
        <td><GiConfirmed style={{color:"#399600", width: "24px",
    height: "50px "}}/></td>
      </tr>
    
    </tbody>
  </table>
</div>
     </div>
    </>
  );
};

export default AttendanceDetail;
