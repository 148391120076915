import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../Common/CommonTable";
import "../PurchaseStyle/PurchaseStyle.css";
import CommonTd from "../../Tender/Common/CommonTd";
import CommonPurchaseModal from "../Modal/CommonPurchaseModal";
import CommonPurchaseEditModal from "../Modal/CommonPurchaseEditModal";
import {
  getStoreMasterList,
  listPurchaseTermsAndConditons,
} from "../purchaseApi";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { errorsMessage } from "../../constant";
import CommonPurchaseDelete from "../Modal/CommonPurchaseDelete";
import { Spinner } from "react-bootstrap";
import {
  storeMasterSchema,
  termsAndConditionSchema,
} from "../ValidationSchema/SettingSchema";
import usePagination from "../Common/usePurchasePagination";
import { IoIosSearch } from "react-icons/io";
import usePurchaseURLFilters from "../Common/usePurchaseUrlFilter";
import { useNavigate } from "react-router-dom";

function TermsAndConditionsList() {
  const [show, setShow] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [editShow, setEditShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [filters, setFilters] = usePurchaseURLFilters();
  const [timer, setTimer] = useState(null);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [editData, setEditData] = useState({
    document_type: "",
    type: "",
    sequence_no: "",
    title: "",
    description: "",
  });
  const {
    data: termsAndConditionList,
    loading,
    hasMore,
    loadMore,
    resetPagination,
    fetchData,
  } = usePagination(
    listPurchaseTermsAndConditons,
    user_id,
    "TermMasterList",
    filters.search
  );
  const tableBodyRef = useRef(null);

  const tableHeading = [
    {
      name: "S.No.",
      width: "5rem",
    },
    {
      name: "DOCUMENT TYPE",
      width: "10rem",
    },
    {
      name: "TITLE",
      width: "10rem",
    },
    {
      name: "DESCRIPTION",
      width: "21rem",
    },
    {
      name: "SEQUENCE",
      width: "7rem",
    },
    {
      name: "EDIT",
      width: "3rem",
    },
    {
      name: "DELETE",
      width: "4rem",
    },
  ];

  const handleScroll = () => {
    const tableBody = tableBodyRef.current;
    if (
      tableBody.scrollTop + tableBody.clientHeight >=
        tableBody.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  const handleEditClick = (element) => {
    setEditData(element);
    setEditShow(true);
  };
  const selectFields = [
    {
      label: "Document Type",
      name: "document_type",
      option: [
        { name: "Site", id: "Site" },
        { name: "Godown", id: "Godown" },
        { name: "Factory", id: "Factory" },
      ],
    },
    {
      label: "Type",
      name: "type",
      option: [
        { name: "Site", id: "Site" },
        { name: "Godown", id: "Godown" },
        { name: "Factory", id: "Factory" },
      ],
    },
  ];
  const inputFields = [
    {
      label: "Sequence No.",
      name: "sequence_no",
      placeHolder: "Enter Sequence No.",
    },
    {
      label: "Title*",
      name: "title",
      placeHolder: "Enter Title ",
    },
    {
      label: "Description",
      name: "description",
      placeHolder: "Enter Description",
    },
  ];
  const initialValues = {
    document_type: "",
    type: "",
    sequence_no: "",
    title: "",
    description: "",
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      const newFilters = {
        ...filters,
        search: search,
      };
      setFilters(newFilters);
      navigate(`?search=${newFilters.search}`);
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [search]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <h4 className="page-title">TERMS & CONDITIONS</h4>
              </div>
              <div className="col-sm-4 text-end">
                <div className="postion-relative">
                  <input
                    className="form-control w-75 ms-auto"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <IoIosSearch
                    size={30}
                    className="position-absolute top-0 end-0 mt-1 me-2"
                    style={{ color: "ef4d56" }}
                  />
                </div>
              </div>
              <div className="col-sm-auto text-end">
                <button
                  className="btn btn-danger"
                  onClick={() => setShow(true)}
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div
                  className="table-responsive table-container-scroll"
                  style={{ overflowX: "auto" }}
                >
                  <table
                    className="table common-table"
                    style={{ tableLayout: "unset" }}
                  >
                    <CommonTable tableHeading={tableHeading}></CommonTable>
                    <tbody ref={tableBodyRef}>
                      {termsAndConditionList?.map((element, index) => (
                        <tr key={index}>
                          <CommonTd width="5rem" value={element?.id}></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.document_type}
                          ></CommonTd>
                          <CommonTd
                            width="10rem"
                            value={element?.title}
                          ></CommonTd>

                          <CommonTd
                            width="21rem"
                            value={element?.description}
                          ></CommonTd>
                          <CommonTd
                            width="7rem"
                            value={element?.sequence_no}
                          ></CommonTd>

                          <td>
                            <p
                              style={{
                                minWidth: "3rem",
                                maxWidth: "3rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <FaRegEdit
                                className="text-dark font-20"
                                style={{ margin: "auto" }}
                                onClick={() => handleEditClick(element)}
                              />
                            </p>
                          </td>
                          <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <RiDeleteBinLine
                                data-bs-toggle="modal"
                                data-bs-target="#storeMasterDelete"
                                className="text-danger font-20"
                                style={{ margin: "auto" }}
                                onClick={() => setDeleteId(element.id)}
                              />
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {loading && (
                      <div className="d-flex align-items-center justify-content-center pb-5">
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="danger" />
                      </div>
                    )}
                  </table>
                  {/* Purchase Add  modal  */}
                  <CommonPurchaseModal
                    show={show}
                    setShow={setShow}
                    url={"addpurchasetermsmaster"}
                    heading={"TERM & CONDITIONS "}
                    initialValues={initialValues}
                    inputFields={inputFields}
                    updatedList={fetchData}
                    validationSchema={termsAndConditionSchema}
                    selectFields={selectFields}
                    optionkey={"name"}
                  />
                  {/* Edit Modal */}
                  {editShow && (
                    <CommonPurchaseEditModal
                      show={editShow}
                      setShow={setEditShow}
                      url={"updatepurchasetermsmaster"}
                      heading={"TERM & CONDITIONS"}
                      updatedList={fetchData}
                      initialValues={editData}
                      inputFields={inputFields}
                      idKey={"term_master_id"}
                      // validationSchema={storeMasterSchema}
                      selectFields={selectFields}
                      optionkey={"name"}
                    />
                  )}
                  {/* Delete Modal */}
                  <CommonPurchaseDelete
                    group_id={deleteId}
                    updatedList={resetPagination}
                    url={"deletepurchasestoremaster"}
                    groupDelete={"storeMasterDelete"}
                    idKey={"store_master_id"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditionsList;
