import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import LogisticForm from "./LogisticForm";
import { useNavigate } from "react-router-dom";
const AddLogisticForm = () => {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const navigate = useNavigate()
  const [validationError, setValidationError] = useState({});
  const [spinner, setSpinner] = useState(false);
  const[logisticId,setLogisticId] = useState(null)
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const initialValues = {
    vehicle_requirement_date: "",
    vehicle_requirement_time: "",
    origin_point: "",
    destination_point: "",
    project_po_no: "",
    coach_material_set: "",
    vehicle_arriving_date_time: "",
    dispatch_date_time: "",
    expected_delivery_date_time: "",
    actual_delivery_date_time: "",

    transporter_name: "",
    types_of_vehicle: "",
    driver_no: "",
    vehicle_no: "",
    docket_no: "",
    transit_mode: "",
    cost_of_vehicle: "",
    approved_by: "",
    invoice_no: "",
    invoice_value: "",
    receiver_name: "",
    receiver_contact_no: "",
    remarks: "",
  };

  const storeLogistic = (value) => {
    setSpinner(true)
    let url = baseUrl + "addstorelogistics";
    let data = { ...value, user_id };
    axios
      .post(url, data, config)
      .then((res) => {

      setSpinner(false);
        setValidationError({})
        if (res.status === 200) {
        
  
        setSpinner(false);
          toast.success("Form submit successfully");
          resetForm();
         
          setValidationError({})
          navigate(-1);
        }
      })
      .catch((err) => {
    
        setSpinner(false);
       
        setValidationError(err?.response?.data?.response)
      
      });
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: addTenderchema,
    onSubmit: (values) => {
      console.log(values);
      storeLogistic(values);
    },
  });

  return (

<>
<LogisticForm
handleChange={handleChange}
values={values}
handleSubmit={handleSubmit}
errors={errors}
touched={touched}
handleBlur={handleBlur}

validationError={validationError}

spinner={spinner}
mode="add"
setFieldValue={setFieldValue}

/>
</>
  );
};

export default AddLogisticForm;
