import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import InventoryForm from "./FormInventory";

function AddInventoryForm() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [validationError, setValidationError] = useState(false);
  const[spinner,setSpinner] = useState(false)
const initialValue = {}
const {
  values,
  handleSubmit,
  handleChange,
  resetForm,
  errors,
  touched,
  setFieldValue,
  handleBlur,
} = useFormik({
  initialValues: initialValue,
 
  // validationSchema: DeliveryChallanSchema,
  onSubmit: (values) => {
    
    // editChallan(values);
  },
});
const addInventory = ()=>{
  const data ={user_id,...values}
  const url = `${baseUrl}`
  setSpinner(false)
  axios.post(url,data,config).then((res)=>{
    if(res.status==200){
      setSpinner(false)
      toast.success("Inventory add successfully",{autoClose:2000})
    }
  }).catch((err)=>{
    setSpinner(false)
    toast.error("Please try again later,Something went wrong")
    setValidationError(err?.response?.data?.response)
  })
}
  return (
<InventoryForm
 handleChange={handleChange}
 values={values}
 handleSubmit={handleSubmit}
 errors={errors}
 touched={touched}
 handleBlur={handleBlur}
 mode="add"
 setFieldValue={setFieldValue}
 validationError={validationError}
 spinner={spinner}
/>
   
    );
}

export default AddInventoryForm;
