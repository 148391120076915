import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { financialTargetSchema } from "../schemas/otherForms";
import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";

function AddFinancialTargetModal({
  show,
  handleClose,
  getTargetList,
  targetId,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [itemId, setItemId] = useState(null);
  const [yearError, setYearError] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const targetYears = [
    "2022-2023",
    "2023-2024",
    "2024-2025",
    "2025-2026",
    "2026-2027",
    "2027-2028",
  ];

  const initialValues = {
    target_name: "",
    target_value: "",
    achieved_value: "",
    target_year: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: financialTargetSchema,
    onSubmit: (values) => {
      if (targetId) {
        updateData(values);
      } else {
        addTarget(values);
      }
    },
  });

  function getEditData() {
    let url = baseUrl + "editfinancialyeartarget";
    let data = { user_id, target_id: targetId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setValues(response.data.response.TargetDetail);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong, please try again later");
      });
  }

  function addTarget(value) {
    setSpinner(true);
    let url = baseUrl + "storefinancialyeartarget";
    let data = { ...value, user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Target added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong, please try again later");
        if (error.response.data.message === "Error validation") {
          setYearError(error.response.data.response);
        }
      });
  }

  function updateData(value) {
    setSpinner(true);
    let url = baseUrl + "updatefinancialyeartarget";
    let data = { ...value, user_id, target_id: targetId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          getTargetList();
          handleClose();
          toast.success("Target Updated successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong, please try again later");
      });
  }

  useEffect(() => {
    resetForm();
    setYearError(null);
    if (targetId) {
      getEditData();
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">
            Target Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="target_name">
              <Form.Label>Target Name</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="target_name"
                placeholder="Target Name"
                value={values.target_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.target_name && touched.target_name
                  ? errors.target_name
                  : yearError?.target_name[0]}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="target_value">
              <Form.Label>Target Value</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="target_value"
                placeholder="Target Value"
                value={values.target_value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.target_value && touched.target_value
                  ? errors.target_value
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="achieved_value">
              <Form.Label>Achieved Value</Form.Label>
              <Form.Control
                type="text"
                className="border-secondary"
                name="achieved_value"
                placeholder="Achieved Values"
                value={values.achieved_value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger">
                {errors.achieved_value && touched.achieved_value
                  ? errors.achieved_value
                  : null}
              </p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="target_year">
              <Form.Label>Target Year </Form.Label>
              <select
                className="form-control border-secondary"
                name="target_year"
                value={values.target_year}
                onChange={handleChange}
              >
                <option value="" disabled selected hidden>
                  Select a Target Year
                </option>
                <option value="2023-2024">2023-2024</option>
                <option value="2024-2025">2024-2025</option>
                <option value="2025-2026">2025-2026</option>
                <option value="2026-2027">2026-2027</option>
                <option value="2027-2028">2027-2028</option>
                <option value="2028-2029">2028-2029</option>
                <option value="2029-2030">2029-2030</option>
              </select>
              <p className="text-danger">
                {errors.target_year && touched.target_year
                  ? errors.target_year
                  : null}
              </p>
            </Form.Group>
            {spinner ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Wait...</p>
              </div>
            ) : (
              <div className="mt-3 d-flex gap-2 justify-content-end">
                <Button variant="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  {targetId ? "Update" : "Add"}
                </Button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddFinancialTargetModal;
