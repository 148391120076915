import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress } from "@mui/material";

function DeleteModal({
  deleteModalOpen,
  handleDeletemodalClose,
  deleteData,
  loader,
}) {

  return (
    <div>
      <React.Fragment>
        <Dialog
          open={deleteModalOpen}
          onClose={handleDeletemodalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          disableScrollLock
        >
          <div className="text-center pt-4">
            <DeleteIcon className="text-primary" style={{ fontSize: "8rem" }} />
            <h4 className="fs-4"> Are you sure</h4>
            <p className="fs-6">
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
          </div>
          <DialogActions className="d-flex justify-content-center pb-4">
            <button
              onClick={handleDeletemodalClose}
              className="btn btn-outline-primary"
            >
              Cancel
            </button>
            <button
              onClick={() => deleteData()}
              className="btn btn-primary"
              disabled={loader}
            >
              {loader ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                "Delete"
              )}
            </button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export default DeleteModal;
