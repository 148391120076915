import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoProjectSymlink } from "react-icons/go";
import Accordion from "react-bootstrap/Accordion";
import "../PurchaseStyle/PurchaseStyle.css";
import { IoMdSettings } from "react-icons/io";
import { settingList, managementList } from "../AsidebarListing";
import { GoDotFill } from "react-icons/go";

function PurchaseAsidebar() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const profileimage = user?.response?.userDetails?.profileimage;
  const userName = user?.response?.userDetails?.name;
  const email = user?.response?.userDetails?.email;
  return (
    <>
      <div className="left-sidebar purchase-asidebar">
        <div className="brand">
          <Link to="/dashboard" className="logo">
            <span>
              <img
                src="/assets/images/logo.png"
                alt="logo-large"
                className="logo-lg logo-light"
              />
              <img
                src="/assets/images/logo.png"
                alt="logo-large"
                className="logo-lg logo-dark"
              />
            </span>
          </Link>
        </div>
        <div className="sidebar-user-pro media border-end">
          <div className="position-relative mx-auto">
            <img
              src={imageBaseUrl + profileimage}
              alt="user"
              className="rounded-circle thumb-md"
            />
          </div>
          <div className="media-body ms-2 user-detail align-self-center">
            <h5 className="font-14 m-0 fw-bold">{userName} </h5>
            <p
              className="opacity-50 mb-0"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "78%",
              }}
            >
              {email}
            </p>
          </div>
        </div>
        <div className="border-end">
          <ul className="nav nav-tabs menu-tab nav-justified" role="tablist">
            <li className="nav-item text-light fw-bold">PURCHASE DEPARTMENT</li>
          </ul>
        </div>
        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                    color: "black",
                  }}
                >
                  <NavLink className="inactive-link" to="/dashboard">
                    <i className="fas fa-home"></i> &nbsp;&nbsp; Dashboard
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink
                    className="inactive-link"
                    to="/purchase-project-review"
                  >
                    <GoProjectSymlink /> &nbsp;&nbsp; Projects
                  </NavLink>
                </li>

                <li>
                  <Accordion className="purchase-asidebar-accordian">
                    <Accordion.Item
                      eventKey="0"
                      className="purchase-management"
                      // className="inactive-link"
                    >
                      <Accordion.Header>
                        <GoProjectSymlink /> &nbsp;&nbsp; Management
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {managementList.map((element, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink className="nav-link" to={element.url}>
                                  <GoDotFill></GoDotFill>&nbsp;&nbsp;{" "}
                                  {element.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="settings">
                      <Accordion.Header>
                        <IoMdSettings />
                        &nbsp;&nbsp; Settings
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {settingList.map((element, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink className="nav-link" to={element.url}>
                                  <GoDotFill></GoDotFill>&nbsp;&nbsp;{" "}
                                  {element.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </li>
              </ul>
            </div>
          </div>
        </div>
        ;
      </div>
    </>
  );
}

export default PurchaseAsidebar;
