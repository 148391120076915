import React from "react";

function TaxDetail({ handleChange, values,errors
 , touched,
  serverSideValidation }) {
  return (
    <div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Nature{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.nature}
            name="nature"
          >
            <option value={""}>Select </option>
          </select>
          <p className="text-danger m-0">
                            {touched.nature && errors.nature
                              ? errors.nature
                              : serverSideValidation?.nature}
                          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Tax Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleChange}
            value={values?.tax_type}
            name="tax_type"
          >
            <option value={""}>Select </option>
          </select>
          <p className="text-danger m-0">
                            {touched.tax_type && errors.tax_type
                              ? errors.tax_type
                              : serverSideValidation?.tax_type}
                          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Value(%)
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Railway No."
            onChange={handleChange}
            value={values?.value_percent}
            name="value_percent"
          />
           <p className="text-danger m-0">
                            {touched.value_percent && errors.value_percent
                              ? errors.value_percent
                              : serverSideValidation?.value_percent}
                          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Tax Amt
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Railway No."
            onChange={handleChange}
            value={values?.tax_amt}
            name="tax_amt"
          />
          <p className="text-danger m-0">
                            {touched.tax_amt && errors.tax_amt
                              ? errors.tax_amt
                              : serverSideValidation?.tax_amt}
                          </p>
        </div>
        <div className="col-md-6 mt-3">
          <table
            style={{ borderCollapse: "collapse", border: "1px solid black" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "10rem",
                  }}
                  className="text-center"
                >
                  <h4 className="fw-bold">HEAD ID</h4>
                </th>
                <th
                  style={{ border: "1px solid black", padding: "10px" }}
                  className="text-center"
                >
                  <h4 className="fw-bold">HEAD NAME</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  className="text-center"
                >
                  <input type="checkbox" checked={true} />
                </td>
                <td
                  style={{ border: "1px solid black", padding: "10px" }}
                  className="text-center"
                >
                  Total Amount
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TaxDetail;
