import React from "react";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "./Style.css";

export default function Responsibility() {
  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="/emd-paid">EMD Paid Status /</Link>
                      </li> */}
                    </ol>
                  </div>
                  {/* <h4 className="page-title">User Roles</h4> */}
                </div>
              </div>
            </div>
            <div className="row border-bottom border-secondary border-1">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      User Roles{" "}
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="">
                  <div className="mt-4 table-border-radius">
                    <Table responsive>
                      <thead>
                        <tr className="bg-table-black">
                          <th className="text-light">User Role</th>
                          <th className="text-light">Scope & Permissions</th>
                          <th className="text-light"></th>
                          <th className="text-light"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-bottom">
                          <td className="border-end">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Global Admin{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Streamline global administration with our
                              efficient and user-friendly tools.
                            </p>
                          </td>
                          <td colspan="2">
                            <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-2.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold  mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-4.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0 "> Piyush Kanwal</p>
                              </div>
                             
                            </div>
                          </td>
                          <td>
                            <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="border-end text-danger">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Tender Manager{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Secure project bids with our comprehensive tender
                              management solutions.
                            </p>
                          </td>
                          <td colspan="2">
                          <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                             
                            </div>
                          </td>
                          <td>
                          <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="border-end">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Procurement Manager{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Optimize purchases, enhance efficiency with
                              advanced procurement solutions.
                            </p>
                          </td>
                          <td colspan="2">
                          <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-2.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold  mt-0"> Piyush Kanwal</p>
                              </div>
                             
                            </div>
                          </td>
                          <td>
                          <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="border-end">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Inspection Executive{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Ensure quality through meticulous inspection
                              services.
                            </p>
                          </td>
                          <td colspan="2">
                          <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-2.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold  mt-0"> Piyush Kanwal</p>
                              </div>
                             
                            </div>
                          </td>
                          <td>
                          <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                              </Link>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="border-end">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Manufacturing Manager{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Innovative manufacturing for superior products and
                              efficiency.
                            </p>
                          </td>
                          <td colspan="2">
                          <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                            </div>
                          </td>
                          <td>
                          <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                            </Link>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td className="border-end">
                            <p className="dashboard-text font-16 fw-semibold">
                              {" "}
                              Delivery Admin{" "}
                            </p>
                            <p className="dashboard-text font-14">
                              Swift and reliable delivery for seamless customer
                              satisfaction.
                            </p>
                          </td>
                          <td colspan="2">
                          <div className="d-flex gap-5 align-items-center">
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-3.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-1.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold mt-0"> Piyush Kanwal</p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center g-3">
                                <img
                                  src="assets/images/users/user-2.jpg"
                                  alt=""
                                  class="rounded-circle thumb-sm me-1"
                                />{" "}
                                <p className="dashboard-text font-11 fw-semibold  mt-0"> Piyush Kanwal</p>
                              </div>
                             
                            </div>
                          </td>
                          <td>
                          <Link to="/manage-roles">
                              <p className="text-danger text-center">
                                {" "}
                                + Manage Users{" "}
                              </p>
                            </Link>
                          </td>
                        </tr>

                        {/* <tr>
                            <td colSpan={3}>
                              <Link to="javascript:void(0);" className="btn btn-danger w-100"> View All</Link>
                            </td>
                          </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
