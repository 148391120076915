import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, departmentName } = props;
  const [open, setOpen] = React.useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;

  function formatDate(dates) {
    const dateString = dates;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }

  return (
    <>
      <TableRow style={{ marginTop: "1rem" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {formatDate(row.created_at) || "- - -"}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.project?.pd_projectTitle || "- - -"}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row.store?.store_name || "- - -"}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {row?.user?.name || "- - -"}
        </TableCell>
        <TableCell
          component="th"
          style={{
            wordWrap: "break-word",
            color: "#23272E",
            fontWeight: "600",
          }}
        >
          {formatDate(row.required_date) || "- - -"}
        </TableCell>
        {departmentName === "Store" ? (
          <TableCell>
            {" "}
            <Link
              to={"/requisitionform/edit-requisition-form"}
              state={{ data: row }}
            >
              <FiEdit style={{ width: "18.92px", height: "19px" }} />
            </Link>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={"100%"}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                sx={{ border: "none" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5rem" }}>S.No.</TableCell>
                    <TableCell style={{ width: "30rem" }}>Name</TableCell>
                    <TableCell>Required Qty</TableCell>
                    <TableCell> Issued Qty</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Batch No.</TableCell>
                    <TableCell>Issued to</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.items?.length ? (
                    row?.items?.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ height: "4rem" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{item.item_name || " - - -"}</TableCell>
                        <TableCell>
                          {item.required_qty || "- - -"}
                        </TableCell>{" "}
                        <TableCell>{item.issued_qty || "- - -"}</TableCell>
                        <TableCell>{item.unit || "- - - "}</TableCell>
                        <TableCell>{item.batch_no || "- - -"}</TableCell>
                        <TableCell>{item.issued_to || "- - -"}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No items to display. Please add some first to see them
                        here.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

// const rows = [
//   createData("Frozen yoghurt", "fda", 6.0, 24, 4.0, 3.99, 12),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
//   createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
//   createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
// ];

export default function RequistionCollapsibleTable({
  requistionData,
  departmentName,
}) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "78vh" }}>
      <Table
        aria-label="collapsible table"
        style={{ overflow: "auto", border: "none" }}
      >
        <TableHead>
          <TableRow sx={{ width: "100%" }}>
            <TableCell sx={{ width: 100 }} />
            <TableCell style={{ width: "10rem" }}>Date</TableCell>
            <TableCell style={{ width: "25rem" }}>Project</TableCell>
            <TableCell style={{ width: "20rem" }}>SITE Name/LOCATION</TableCell>
            <TableCell style={{ width: "15rem" }}> Requested by</TableCell>
            <TableCell style={{ width: "10rem" }}>Required date</TableCell>
            <TableCell style={{ width: "10rem" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requistionData.map((row) => (
            <Row key={row.id} row={row} departmentName={departmentName} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
