import React, { useState } from "react";
import "../../organisationStructure/OrgStucture.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteLeagalEntityApi } from "../OrganizationComponents/organizationStructureApi";
import DeleteModal from "../OrganizationComponents/DeleteModal";
import { toast } from "react-toastify";
import { useDispatch ,useSelector} from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../../redux/features/Hr/LoaderSlice";
import { fetchLegalEntity } from "../../../../redux/features/Hr/LegalEntitySlice";

function LegalEntityRegistrationInformation({ legalEntity, getEditData }) {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  //  const[loader,setLoader] = useState(false)
  const handleDeleteClicked = (id) => {
    setOpen(true);
    setDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function deleteLeagalEntity() {
    dispatch(setLoading(true));
    try {
      const res = await deleteLeagalEntityApi({ id: deleteId });

      dispatch(fetchLegalEntity(dispatch));
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  }

  return (
    <div
     
      className="legalEntityRegisteredAddress position-relative"
    >
      <div className="dropdown position-absolute top-0 end-0 me-2 mt-3">
        <button
          className="dropdown-toggle border-0 bg-transparent"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <MoreVertIcon />
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ minWidth: "8rem" }}
        >
          <li>
            <button
              className="dropdown-item"
              onClick={() => getEditData(legalEntity)}
            >
              Edit
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => {
                handleDeleteClicked(legalEntity?.id);
              }}
            >
              Delete
            </button>
          </li>
        </ul>
        <DeleteModal
          deleteModalOpen={open}
          handleDeletemodalClose={handleClose}
          deleteData={deleteLeagalEntity}
          loader={loader}
        />
      </div>
      <div className="row my-2">
        <div className="col-4">
          <p>Registered Address</p>
          <p className="bold">{legalEntity?.address_line_1}</p>
          <p className="bold">{legalEntity?.address_line_2}</p>
        </div>
        <div className="col-4">
          <p>Country of Incorporation</p>
          <p className="bold">{legalEntity?.country}</p>
        </div>
        <div className="col-3">
          <p>Currency </p>
          <p className="bold">India </p>
        </div>
        <div className="col-1"></div>
      </div>
      <div className="my-5">
        <h4 className="tab-card-heading">Incorporation Information </h4>
      </div>
      <div className="row mb-4 ">
        <div className="col-4">
          <p>Legal Name</p>
          <p className="bold">{legalEntity?.legal_name}</p>
        </div>
        <div className="col-4">
          <p>Company Identification Number (CIN)</p>
          <p className="bold"> {legalEntity?.cin}</p>
        </div>
        <div className="col-4">
          <p>Date of Incorporation</p>
          <p className="bold">{legalEntity?.date_of_incorp}</p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-4">
          <p>Type of Business</p>
          <p className="bold">{legalEntity?.business_type}</p>
        </div>
        <div className="col-4">
          <p>Nature of Business</p>
          <p className="bold">{legalEntity?.business_nature}</p>
        </div>
        <div className="col-4">
          <p>Sector</p>
          <p className="bold">{legalEntity?.sector}</p>
        </div>
      </div>
    </div>
  );
}

export default LegalEntityRegistrationInformation;
