import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import OrganizationStructureAsideList from "../OrganizationComponents/OrganizationStructureAsideList";
import OrgBusinessUnitHeader from "./OrgBusinessUnitHeader";
import BusinessUnitModal from "./BusinessUnitModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "../../../../redux/features/Hr/LoaderSlice";
import {fetchBusinessUnit, getbusinessUnit} from "../../../../redux/features/Hr/BusinessUnitslice"
import MainCard from "../../../CommonComponent/MainCard";
import Loader from "../../../CommonComponent/Loader";
function BusinessUnitParent() {
  const dispatch = useDispatch()
  const loader = useSelector(selectLoading)
  const [open, setOpen] = useState(false);
  const [businessUnit, setBusinessUnit] = useState("");
  // const [businessUnitList, setBusinessUnitList] = useState([]);
  const businessUnitList = useSelector(getbusinessUnit)
  const [editData, setEditData] = useState(false);

  // const getBusinessUnit = () => {
  //   setLoader(true);
  //   businessUnitListApi()
  //     .then((res) => {
  //       setLoader(false);
  //       const data = res?.data?.response?.data;
  //       setBusinessUnitList(data);
  //       if (data?.length) {
  //         setBusinessUnit(data[0]);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       toast.error(err.response.data.message, { autoClose: 1000 });
  //     });
  // };
  useEffect(() => {
   dispatch(fetchBusinessUnit())
  }, []);
  useEffect(()=>{
setBusinessUnit(businessUnitList?.[0])
  },[businessUnitList])
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        <h4 className="p-4 pb-0  mt-0">ORGANISATION STRUCTURE</h4>
        <button className="hr btn btn-primary" onClick={handleClickOpen}>
          + Add Unit
        </button>
        <BusinessUnitModal
          open={open}
          handleClose={handleClose}
          editData={editData}
    
        />
      </div>
      {/* <div className="col-md-3">
        <div
          style={{
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.25)",
            background: "rgba(255, 255, 255, 1)",
            border: "1px",
            borderRadius: "10px",
          }}
        >
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : businessUnitList?.length > 0 ? (
            businessUnitList?.map((businessUnitDetail, index) => (
              <OrganizationStructureAsideList
                organizationDataList={businessUnitDetail}
                key={index}
                setOrgDetail={setBusinessUnit}
                orgDetail={businessUnit}
                name={businessUnitDetail?.name}
                employee={businessUnitDetail?.total_employees}
              />
            ))
          ) : (
            <div>No Business Unit Found</div>
          )}
        </div>
      </div>

      <div className="col-md-9">
        <OrgBusinessUnitHeader
          businessUnit={businessUnit}
          editData={editData}
          setEditData={setEditData}
     
        />
      </div> */}
      <MainCard
       asideContent={loader ? (
        <Loader />
      ) :  businessUnitList?.length > 0 ? (
        businessUnitList?.map((businessUnitDetail, index) => (
          <OrganizationStructureAsideList
            organizationDataList={businessUnitDetail}
            key={index}
            setOrgDetail={setBusinessUnit}
            orgDetail={businessUnit}
            name={businessUnitDetail?.name}
            employee={businessUnitDetail?.total_employees}
          />
        ))
      ) : (
        <div>No Business Unit Found</div>
      )}
      tabContent={ <OrgBusinessUnitHeader
        businessUnit={businessUnit}
        editData={editData}
        setEditData={setEditData}
   
      />}
      asideHeading={"BUSINESS UNIT"}
      />
    </div>
  );
}

export default BusinessUnitParent;
