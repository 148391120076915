import React from "react";
function CommonInput({
  handleChange,
  values,
  type,
  name,
  labelValue,
  handleBlur,
  errors,
  touched,
  disabled,
  defaultValue,
  readOnly,
  placeHolder,
  className,
  accept,
}) {
  return (
    <>
      <label htmlFor="" className="form-label">
        {labelValue}
      </label>
      <input
        readOnly={readOnly}
        type={type}
        className={`form-control ${className}`}
        id={name}
        name={name}
        placeholder={placeHolder}
        {...(type === "file" ? { multiple: true } : {})}
        value={values}
        //{...(values ? { value: values } : { defaultValue: defaultValue })}
        onChange={handleChange}
        accept=".pdf, image/*, .doc, .docx"
        disabled={disabled}
      />
     {/* {touched.name && errors.name ? errors.name : null} */}
    </>
  );
}

export default CommonInput;
