import Accordion from "react-bootstrap/Accordion";
import EditProjectDetailForm from "../../Components/ProjectForms/EditForms/EditProjectDetailForm";
import EditTenderAttachment from "../TenderComponents/Forms/EditTenderAttachment";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorsMessage } from "../../constant";
import EditTenderDetails from "../TenderComponents/Forms/EditTenderDetails";

function TenderEditPage() {
  const [spinner, setSpinner] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const navigate = useNavigate();
  const location = useLocation();
  const { initialData } = location?.state ?? {};
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [activeAccordionKey, setActiveAccordionKey] = useState("0");

  const changeKey = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };

  function uploadAttachments(values, reset) {
    setSpinner(true);
    let data = { ...values, user_id, tenderId: initialData.id };
    axios
      .post(baseUrl + "storetenderattechments", data, config)
      .then((response) => {
        setSpinner(false);
        reset();
        navigate(-1);
        toast.success("Added successfully");
      })
      .catch((error) => {
        setSpinner(false);
        errorsMessage(error?.response?.status);
      });
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="row">
            <Accordion activeKey={activeAccordionKey}>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => changeKey("0")}>
                  <h5 className="fw-bold">UPDATE TENDER DETAILS</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <EditTenderDetails changeKey={changeKey} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => changeKey("1")}>
                  <h5 className="fw-bold">ADD NEW ATTACHMENTS</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <EditTenderAttachment
                    uploadAttachments={uploadAttachments}
                    spinner={spinner}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenderEditPage;
