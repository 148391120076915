import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import "../TenderStyle/TenderStyle.css";
import { Spinner } from "react-bootstrap";
import CommonTh from "../Common/CommonTh";
import CommonTd from "../Common/CommonTd";
import TenderAttachmentModal from "../TenderModal/TenderAttachmentModal";

function TenderTable({ searchData, tenderDataFetch, Loading }) {
  console.log(searchData);
  const navigate = useNavigate();

  const [tenderId, setTenderId] = useState(null);

  const [modalShow, setModalShow] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const [titleModal, setTitleModal] = useState(false);
  const [titleData, setTitleData] = useState("");
  const [tenderAttachmentData, setTenderAttachmentData] = useState("");
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [modalSpinner, setModalSpinner] = useState(false);
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const [renderState, setRenderState] = useState(false);

  function formatDate(value) {
    if (value) {
      var date = new Date(value);
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }

    return formattedDate;
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  async function getAttachments() {
    setModalSpinner(true);
    axios
      .post(
        baseUrl + "gettenderattechmentslist",
        { user_id, tenderId: tenderId },
        config
      )
      .then((response) => {
        const strSplit = response?.data?.response?.attechmentList;
        setTenderAttachmentData(response?.data?.response?.attechmentList);
        setModalSpinner(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setModalSpinner(false);
      });
    // setAllAttachments(tenderAttachment);
    // setAttachmentModal(true);
  }

  function editData(initialData) {
    navigate("/tenders/edit-tender", {
      state: {
        initialData,
      },
    });
  }

  async function DeleteTender() {
    let url = baseUrl + "setdeleteTender";
    await axios
      .post(url, { user_id, tenderId }, config)
      .then((res) => {
        tenderDataFetch();
        toast.success("Tender Deleted Sucessfully");
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  }

  useEffect(() => {
    if (tenderId) {
      getAttachments();
    }
  }, [tenderId, renderState]);

  return (
    <div>
      {Loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <div
          className="table-responsive table-container-scroll"
          style={{ overflowX: "auto" }}
        >
          <table
            className="table tender-list-table"
            style={{ tableLayout: "unset" }}
          >
            <thead className="thead-light">
              <tr>
                <CommonTh value="S No." width="3rem"></CommonTh>
                <CommonTh value="Created Date"></CommonTh>
                <CommonTh value="Railway Zone"></CommonTh>
                <CommonTh value="Sub Divison"></CommonTh>
                <CommonTh value="Tender No." width="7.4rem"></CommonTh>
                <CommonTh value="Tender Title"></CommonTh>
                <CommonTh value="Published Date" width="7.4rem"></CommonTh>
                <CommonTh value="Closing Date" width="7.4rem"></CommonTh>
                <CommonTh value="Closing Time" width="4.6rem"></CommonTh>
                <CommonTh value="Tender Type" width="5rem"></CommonTh>
                <CommonTh value="Contract Type" width="5.4rem"></CommonTh>
                <CommonTh value="Bidding System" width="8rem"></CommonTh>
                <CommonTh value="Inspection Agency" width="8rem"></CommonTh>
                <CommonTh value="Approving Agency " width="8rem"></CommonTh>
                <CommonTh value="Quantity" width="5.4rem"></CommonTh>
                <CommonTh value="UOM" width="7.4rem"></CommonTh>
                <CommonTh value="Validity of offer" width="7.4rem"></CommonTh>
                <CommonTh value="Advertised Value" width="7.8rem"></CommonTh>
                <CommonTh
                  value="Tender Document Cost (TDC)"
                  width="8.5rem"
                ></CommonTh>
                <CommonTh value="Amount (TDC)" width="8rem"></CommonTh>
                <CommonTh
                  value="Earnest Money Deposit (EMD)"
                  width="9rem"
                ></CommonTh>
                <CommonTh value="Amount (EMD)" width="8.5rem"></CommonTh>
                <CommonTh value="Payment Terms" width=""></CommonTh>
                <CommonTh value="Eligibility Creteria"></CommonTh>
                <CommonTh
                  value="Higher Authority Approval"
                  width="7.4rem"
                ></CommonTh>
                <CommonTh
                  value="Intending Participation"
                  width="8rem"
                ></CommonTh>
                <CommonTh value="Bidding Status" width="8rem"></CommonTh>
                <CommonTh value="Awarded Status" width="8rem"></CommonTh>
                <CommonTh value="Validity Extension" width="8rem"></CommonTh>
                <CommonTh
                  value="Period of Completion (Days)"
                  width="8rem"
                ></CommonTh>
                <CommonTh value="Technical Eligibility" width="8rem"></CommonTh>
                <CommonTh value="RA Participation" width="8rem"></CommonTh>
                <CommonTh value="RA Date" width="8rem"></CommonTh>
                <CommonTh value="RA Result" width="8rem"></CommonTh>
                <CommonTh value="Date of Negotiation" width="8rem"></CommonTh>
                <CommonTh value="Remark"></CommonTh>
                <CommonTh value="Price Sheet" width="4rem"></CommonTh>
                <CommonTh value="Attachments" width="7.8rem"></CommonTh>

                <th>
                  <p className="fw-bold font-18">Action</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {searchData?.map((tender, i) => (
                <tr
                  key={i}
                  // style={

                  //   tender?.biddingStatus === "Not Awarded"
                  //     ? { backgroundColor: "#FA7070DB " }
                  //     : tender?.biddingStatus === "Awarded"
                  //     ? { backgroundColor: "#a8e28d" }
                  //     : tender?.biddingStatus === "Bidded"
                  //     ? { backgroundColor: "#fdec8b" }
                  //     : tender?.biddingStatus === "Not Bidded"
                  //     ? { backgroundColor: "#dcdfd8" }

                  //     : {backgroundColor:""}

                  // }
                  style={
                    tender?.higherAuthorityapproval === "Pending"
                      ? { backgroundColor: "#FFC0CB" }
                      : tender?.awardedStatus === "Not Awarded"
                      ? { backgroundColor: "#FA7070DB " }
                      : tender?.awardedStatus === "Awarded"
                      ? { backgroundColor: "#a8e28d" }
                      : tender?.biddingStatus === "Bidded"
                      ? { backgroundColor: "#fdec8b" }
                      : tender?.biddingStatus === "Not Bidded"
                      ? { backgroundColor: "#dcdfd8" }
                      : { backgroundColor: "" }
                  }
                 
                >
                  <th scope="row">
                    <h6 style={{ minWidth: "3rem", maxWidth: "3rem" }}>
                      {i + 1}
                    </h6>
                  </th>
                  <>
                    <CommonTd
                      value={
                        tender?.created_at
                          ? formatDate(tender.created_at.substring(0, 10))
                          : ""
                      }
                    ></CommonTd>

                    <CommonTd value={tender?.railwayZoneName}></CommonTd>
                    <CommonTd value={tender?.subDivisionName}></CommonTd>
                    <CommonTd
                      value={tender?.tenderNo}
                      width="7.4rem"
                    ></CommonTd>
                    <CommonTd value={tender?.itemDecsription}></CommonTd>

                    <CommonTd
                      value={formatDate(tender?.publishedDate)}
                      width="7.4rem"
                    ></CommonTd>
                    <CommonTd
                      value={formatDate(tender?.closingDate)}
                      width="7.4rem"
                    ></CommonTd>
                    <CommonTd value={tender?.time} width="4.6rem"></CommonTd>
                    <CommonTd
                      value={tender?.tenderType}
                      width="5rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.contractType}
                      width="5.4rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.biddingSystem}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.inspectionAgencyName}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.approvingAgency}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.quantity}
                      width="5.4rem"
                    ></CommonTd>
                    <CommonTd value={tender?.uonName} width="7.4rem"></CommonTd>
                    <CommonTd
                      value={
                        tender?.validityOfOffer &&
                        tender?.validityOfOffer + " Days"
                      }
                      width="7.4rem"
                    ></CommonTd>
                    <CommonTd
                      value={formatNumber(tender?.advertisedValue)}
                      width="7.8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.tenderDocCost}
                      width="8.5rem"
                    ></CommonTd>
                    <CommonTd
                      value={formatNumber(tender?.ifApplicableAmountCost)}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd value={tender?.EMD} width="9rem"></CommonTd>
                    <CommonTd
                      value={formatNumber(tender?.ifApplicableAmountEmd)}
                      width="8.5rem"
                    ></CommonTd>
                    <CommonTd value={tender?.paymentTerms}></CommonTd>
                    <CommonTd value={tender?.eligibilityCriteria}></CommonTd>
                    <CommonTd
                      value={tender?.higherAuthorityapproval}
                      width="7.4rem"
                    ></CommonTd>

                    <CommonTd
                      value={tender?.intendingParticipation}
                      width="8rem"
                    ></CommonTd>
                    <td>
                      <h6 style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                        {tender?.biddingStatus ? tender?.biddingStatus : "---"}
                      </h6>
                    </td>
                    <td>
                      <h6 style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                        {tender?.awardedStatus ? tender?.awardedStatus : "---"}
                      </h6>
                    </td>

                    <CommonTd
                      value={
                        tender?.validityExtension &&
                        tender?.validityExtension + " Days"
                      }
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={
                        tender?.periodOfCompletion &&
                        tender?.periodOfCompletion + " Days"
                      }
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.tenchnicalEligibility}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={tender?.RAPaticipation}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd
                      value={formatDate(tender?.RADate)}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd value={tender?.RAResult} width="8rem"></CommonTd>
                    <CommonTd
                      value={formatDate(tender?.DateOfNagotiation)}
                      width="8rem"
                    ></CommonTd>
                    <CommonTd value={tender?.remarks}></CommonTd>
                    <td>
                      {tender?.priceSheet ? (
                        <Link
                          to={attachmentBaseUrl + tender?.priceSheet}
                          target="_blank"
                        >
                          <h6 style={{ minWidth: "4rem", maxWidth: "4rem" }}>
                            <FaEye
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                              }}
                            />
                          </h6>
                        </Link>
                      ) : (
                        <h6 style={{ minWidth: "4rem", maxWidth: "4rem" }}>
                          ---
                        </h6>
                      )}
                    </td>
                    <td onClick={() => setTenderId(tender?.id)}>
                      <h6
                        style={{ minWidth: "7.8rem", maxWidth: "7.8rem" }}
                        onClick={() => setModalShow(true)}
                      >
                        <FaEye
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            cursor: "pointer",
                          }}
                        />
                      </h6>
                    </td>
                    <td>
                      <h6>
                        <i
                          className="las la-pen text-dark font-20 px-1"
                          style={{ cursor: "pointer" }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Zone Details"
                          onClick={() => editData(tender)}
                        ></i>
                        <Link
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#modalDelete"
                        >
                          <i
                            onClick={() => setTenderId(tender?.id)}
                            className="las la-trash-alt font-20 px-1 text-dark"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Category"
                          ></i>
                        </Link>
                      </h6>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="modal fade" id="modalDelete">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5 text-center">
              <h4 className="lh-base mb-4">
                Are you sure you want to <br /> delete this record
              </h4>
              <button
                type="button"
                className="btn btn-success me-3"
                onClick={() => DeleteTender()}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                // onClick={() => setShowModal(false)}
              >
                No
              </button>{" "}
              b
            </div>
          </div>
        </div>
      </div>
      <TenderAttachmentModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={tenderAttachmentData}
        renderState={renderState}
        setRenderState={setRenderState}
        modalSpinner={modalSpinner}
      />
      <Modal show={titleModal} onHide={() => setTitleModal(false)} centered>
        <Modal.Header closeButton className="m-0 p-3">
          <Modal.Title className="m-0 p-0">Tender Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>{titleData}</h5>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TenderTable;
