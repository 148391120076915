import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../PurchaseStyle/PurchaseStyle.css";
import { getPurchaseTickets } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PurchaseProjectReview = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [ticketData, setTicketData] = useState([]);
  const [boardGroupStatus, setBoardGroupStatus] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [initialStatus, setInitialStatus] = useState([]);
  const navigate = useNavigate();

  function purchaseTicketData(index = null) {
    getPurchaseTickets({ user_id })
      .then((res) => {
        setTicketData(res.data.response.ticketsList);
        setBoardGroupStatus(res.data.response.board_group);
        const ticketList = res.data.response.ticketsList;
        const arrayList = Array.from(
          { length: ticketList.length },
          (_, index) => ({
            board_group_status_id: ticketList[index].board_group_status_id,
            board_group_status_name: ticketList[index].board_group_status,
            loading: false,
          })
        );
        setInitialStatus(arrayList);
      })
      .catch((err) => {
        errorsMessage(err?.status);
      })
      .finally(() => {
        {
          index !== null
            ? setInitialStatus((prevInitialStatus) => {
                const updatedStatus = [...prevInitialStatus];
                updatedStatus[index].loading = false;
                return updatedStatus;
              })
            : null;
        }
      });
  }

  function changeStatusList(board_group_id, e, ticket, index) {
    setInitialStatus((prevInitialStatus) => {
      const updatedStatus = [...prevInitialStatus];
      updatedStatus[index].loading = true;
      return updatedStatus;
    });
    let newStatusId = e.target.value;
    const url = baseUrl + "updateprojectticketboardgroup";
    const data = {
      user_id,
      board_group_id,
      board_group_status_id: newStatusId,
      ticketId: ticket,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          purchaseTicketData(index);
          toast.success("Status changed successfully");
        }
      })
      .catch((error) => {
        setInitialStatus((prevInitialStatus) => {
          const updatedStatus = [...prevInitialStatus];
          updatedStatus[index].loading = false;
          return updatedStatus;
        });
        errorsMessage(error.status);
      });
  }
  function handleNavigate(data, boardData) {
    navigate("/purchase-project-detail", {
      state: { data, boardData },
    });
  }
  useEffect(() => {
    purchaseTicketData();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active"> PROJECTS REVIEW</li>
                    </ol>
                  </div>
                  <h4 class="page-title">PROJECTS REVIEW </h4>
                </div>
              </div>
            </div>
            <div className="text-end">
              <Link to={"/non-tender/purchaseOrder"}>
                <Button variant="contained" endIcon={<ArrowForwardIcon />}>
                  For Non Tender PO
                </Button>
              </Link>
            </div>
            <div className="row">
              {ticketData.map((element, index) => (
                <div className="col-md-6" key={index}>
                  <div className="project_review-card" key={index}>
                    <div
                      className="review-card mb-4"
                      onClick={() => handleNavigate(element, boardGroupStatus)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-0">Project Type</p>
                      <h6>{element.pd_projectTitle}</h6>
                      <p>{element.pd_projectItemDescription}</p>
                    </div>
                    <div className="d-flex justify-content-between review_date border-bottom">
                      <p>
                        Assigned Date:&nbsp;{" "}
                        <span>{formatDate(element.start_date)}</span>
                      </p>
                      <p>
                        Due Date:&nbsp;{" "}
                        <span>{formatDate(element.due_date)}</span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between review_date mt-3">
                      <p>
                        Reporting To:&nbsp;&nbsp;&nbsp;
                        <span>{element.reporting_name}</span>
                      </p>
                      <p>
                        Priority:&nbsp;&nbsp;&nbsp;
                        <span>{element.priority}</span>
                      </p>
                    </div>
                    <div className="row d-flex align-items-center mt-2">
                      <div className="col-md-2">
                        <label style={{ fontSize: "1rem" }}>Status:</label>
                      </div>
                      <div className="col-md-5">
                        {initialStatus[index].loading ? (
                          <Spinner animation="border" variant="danger" />
                        ) : (
                          <select
                            className="form-control border-secondary"
                            onChange={(event) =>
                              changeStatusList(
                                element.board_group_id,
                                event,
                                element.id,
                                index
                              )
                            }
                            value={initialStatus[index].board_group_status_id}
                          >
                            <option value="">Select</option>
                            {boardGroupStatus[element.board_group_id]?.map(
                              (status, index) => (
                                <option key={index} value={status.id}>
                                  {status.name}
                                </option>
                              )
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="pt-2 review_date">
                      <p>
                        Ticket Description : <span>{element.description}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PurchaseProjectReview;
