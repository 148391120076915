import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "./Material.css";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import { errorsMessage } from "../../constant";
import Select from "react-select";
const MatarialList = () => {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const [materialList, setMaterialList] = useState([]);
  const [storeUnitList, setStoreUnitList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreId, setselectedStoreId] = useState(null);
  const[loader,setLoader] = useState(false)
  const getStoreUnitList = () => {
    const data = { user_id };
    const url = `${baseUrl}listpurchasestoremaster`;
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setStoreUnitList(res?.data?.response?.CityMasterList);
        }
      })
      .catch((err) => {
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  const getMaterialList = (search = null) => {
    setMaterialList([])
    const data = { user_id };
    const url =
      search != null
        ? `${baseUrl}materialinprojectdetails?delivery_store=${search}`
        : `${baseUrl}materialinprojectdetails`;
        setLoader(true)
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          setMaterialList(res?.data?.response?.StoreMaterialInProjectList);
        }
      })
      .catch((err) => {
        setLoader(false)
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);

    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }
  useEffect(() => {
    getStoreUnitList();
    getMaterialList();
  }, []);
  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    setselectedStoreId(selectedOption?.id);
    getMaterialList(selectedOption?.id);
  };
  return (
    <div className="page-wrapper">
       {loader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <div>
                      <h2
                        className="page-title ms-4"
                        style={{ fontSize: "24px", fontWeight: "500" }}
                      >
                        {" "}
                        MATERIAL IN REVIEW{" "}
                      </h2>
                    </div>

                    <div style={{ width: "35%" }}>
                      <div class="input-group search_matarial d-flex">
                        <Select
                          placeholder="Select Store"
                          style={{ width: "75%" }}
                          className="form-select-control border-secondary"
                          name="id"
                          isSearchable={true}
                          options={storeUnitList}
                          getOptionLabel={(storeUnitList) =>
                            storeUnitList["store_name"]
                          }
                          getOptionValue={(storeUnitList) =>
                            storeUnitList["id"]
                          }
                          value={selectedStore}
                          onChange={handleStoreChange}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: "lightgrey",
                              primary: "lightgrey",
                            },
                          })}
                        />

                        <button type="button" class="btn btn-primary btn-sm">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 table-uniq">
                  <div className="table-responsive">
                   {materialList.length>0? <table class="table">
                      <thead className="mb-2">
                        <tr>
                          <th scope="col" class="pb-3">
                            Project Name
                          </th>
                          <th scope="col" class="pb-3">
                            LOA No.
                          </th>
                          <th scope="col" class="pb-3">
                            Completion Date
                          </th>
                          <th scope="col" class="pb-3">
                            Railway Zone
                          </th>
                          <th scope="col" class="pb-3"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {materialList?.map((detail) => (
                          //  <Link to ={`/matarial-in/material-detail/${detail?.project_id}`}>
                          <tr>
                            <td>
                              <Link
                                to={`/matarial-in/material-detail/${detail?.project_id}` }
                                state={{ detail: detail }}
                              >
                                {detail?.project_details?.pd_projectTitle
                                  ? detail?.project_details?.pd_projectTitle
                                  : "--"}{" "}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/matarial-in/material-detail/${detail?.project_id}`}
                                state={{ detail: detail }}
                              >
                                {detail?.project_details?.pd_poloaNo
                                  ? detail?.project_details?.pd_poloaNo
                                  : "--"}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/matarial-in/material-detail/${detail?.project_id}`}
                                state={{ detail: detail }}
                              >
                                {detail?.project_details?.pd_endDate
                                  ? formatDate(
                                      detail?.project_details?.pd_endDate
                                    )
                                  : "--"}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/matarial-in/material-detail/${detail?.project_id}`}
                                state={{ detail: detail }}
                              >
                                {detail?.project_details?.railway_zone?.name
                                  ? detail?.project_details?.railway_zone?.name
                                  : "--"}
                              </Link>
                            </td>

                            {/* <td className="pt-4">
                                <FaRegEdit style={{ fontSize: "20px" }} />
                              </td> */}
                          </tr>
                          // </Link>
                        ))}
                      </tbody>
                    </table>:<div>No Data Found</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </div>
  );
};

export default MatarialList;
