import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "../../HrDepartment.scss"



const EmployeeExpense = () => {


  return (
    <>
    
   
      
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="card bordeRadius10px">
                <div className="assignedAssets">Total Expense</div>

                <hr />
                <div className="row">
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">
                      Current Compensation
                    </span>
                    <span className="currentSalaryValue">
                      INR 6,00,000 / Annum
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="card bordeRadius10px">
                <div className="assignedAssets">This Month</div>
                <hr />
                <div className="row">
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Legal Entity</span>
                    <span className="currentSalaryValue">ACME India</span>
                  </div>
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <div className="card ">
                <div className="assignedAssets">Expenses Summary</div>
                <hr />
                <div className="table_24">
                  <table className="table border0px">
                    <thead className="thead-light">
                      <tr className="borderBottomOpx">
                        <th scope="col" className="headingStyle">
                        Expense Type
                        </th>
                        <th scope="col" className="headingStyle">
                        Project
                        </th>
                        <th scope="col" className="headingStyle">
                        Date
                        </th>
                        <th scope="col" className="headingStyle">
                        Amount
                        </th>
                        <th scope="col" className="headingStyle">
                        Slip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="boxShadowNone">
                      <tr>
                        <td className="cellStyle">Mark</td>
                        <td className="cellStyle">Otto</td>
                        <td className="cellStyle">@mdo</td>
                        <td className="cellStyle">@mdo</td>
                        <td className="headingStylePayslip">
                          <u>View Payslip</u>
                        </td>
                      </tr>
                      <tr>
                        <td className="cellStyle">Mark</td>
                        <td className="cellStyle">Otto</td>
                        <td className="cellStyle">@mdo</td>
                        <td className="cellStyle">@mdo</td>
                        <td className="headingStylePayslip">
                          <u>View Payslip</u>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
          </Grid>
      
  
    </>
  );
};
export default EmployeeExpense;
