import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setLoading } from "./LoaderSlice";
import { legalEntityApi } from "../../../HrDepartment/Organisation/organisationStructure/OrganizationComponents/organizationStructureApi";
const initialState = { legalEntity: [] };
export const fetchLegalEntity = createAsyncThunk(
  "fetch/legalEntityList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await legalEntityApi();
      return response?.data?.response?.data;
    } catch (err) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const legalEntitySlice = createSlice({
  name: "legalEntity",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchLegalEntity.pending, (state) => {})
      .addCase(fetchLegalEntity.fulfilled, (state, action) => {
     

        state.legalEntity = action.payload;
      })
      
      .addCase(fetchLegalEntity.rejected, (state, action) => {});
  },
});
export const getLegalEntity = (state) => state?.legalEntityList?.legalEntity;

export default legalEntitySlice.reducer;
