import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaEdit } from "react-icons/fa";
import ControlledRadioButtonsGroup from "./RadioGroup";
import styles from "./OffCanvasComponent.module.css";
import { formatDate, formatNumber } from "../../../constant";

const OffCanvasComponent = ({
  currentValue,
  updatedValue,
  name,
  text,
  amendedFieldApi,
  spinner,
  projectData,
  fieldType,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [type, setType] = useState("correction");

  return (
    <>
      <button
        className={`${styles.toggleButton}`}
        type="button"
        onClick={handleShow}
      >
        <FaEdit size="30" color="red" />
      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ width: "500px" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Update {text}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ControlledRadioButtonsGroup type={type} setType={setType} />
          <div className={styles.inputGroup}>
            <label htmlFor="totalOrderValue" className={styles.inputLabel}>
              {text}
            </label>
            <input
              type="number"
              id="totalOrderValue"
              className={styles.textInput}
              placeholder="Enter Total Order Value"
              value={currentValue}
              onChange={(e) => updatedValue(e.target.value)}
              name={name}
            />
          </div>

          <h6 className={styles.logsTitle}>Amendment Logs</h6>
          <table className={styles.logsTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Previous Value</th>
                <th>Updated Value</th>
              </tr>
            </thead>
            <tbody>
              {projectData?.map((row, index) => (
                <tr key={index}>
                  <td>{formatDate(row.created_at)}</td>
                  <td>
                    {fieldType === "value"
                      ? formatNumber(row?.pd_totalOrderValue_Before)
                      : formatNumber(row?.pd_totalOrderQty_Before)}
                  </td>
                  <td>
                    {fieldType === "value"
                      ? formatNumber(row?.pd_totalOrderValue_After)
                      : formatNumber(row?.pd_totalOrderQty_After)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Wait a moment....</p>
            </div>
          ) : (
            <div
              className="mb-2 text-end d-flex align-items-end justify-content-end"
            >
              <Button
                variant="danger"
                onClick={() => amendedFieldApi(name, currentValue, type)}
              >
                Update
              </Button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvasComponent;
