import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";

import {
  createBusinessUnitApi,
  updateBusinessUnitApi,
} from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
import { addBusinessUnitSchema } from "../OrganizationComponents/OrganizationSchema";
import ShowError from "../OrganizationComponents/ShowError";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../../redux/features/Hr/LoaderSlice";
import { fetchBusinessUnit } from "../../../../redux/features/Hr/BusinessUnitslice";
import {
  fetchLegalEntity,
  getLegalEntity,
} from "../../../../redux/features/Hr/LegalEntitySlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    width: "1000px ", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const BusinessUnitModal = ({ open, handleClose, editData }) => {
  // const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const legalEntityList = useSelector(getLegalEntity);
  const initialValues = {
    name: "",
    head: "",
    legal_entity_id: "",
    description: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addBusinessUnitSchema,
    onSubmit: (values) => {
      if (editData) {
        updateBusinessUnit(values);
      } else {
        addBusinessUnit(values);
      }
    },
  });

  async function addBusinessUnit(value) {
    dispatch(setLoading(true));
    try {
      const res = await createBusinessUnitApi(value);
      toast.success("Business unit added successfully");
      handleClose();
      dispatch(fetchBusinessUnit(dispatch));
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      dispatch(setLoading(false));
    }
  }

  async function updateBusinessUnit(value) {
    dispatch(setLoading(false));
    const requestPayload = { id: editData?.id, ...values };
    try {
      const res = await updateBusinessUnitApi(value);
      toast.success("Business Unit Updated Successfully", { autoClose: 1000 });
      handleClose();
      dispatch(fetchBusinessUnit(dispatch));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    if (editData) {
      setValues(editData);
    } else {
      resetForm();
    }
  }, [editData,open]);
  useEffect(() => {
    dispatch(fetchLegalEntity());
  }, []);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {editData ? "Update" : "Add"} Business Unit
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="name"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError touched={touched.name} message={errors.name} />
                  </CustomFormControl>
                </div>

                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Business Head
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Business Head"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="head"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.head}
                    >
                      <MenuItem value="Suraj Pandey">Suraj Pandey</MenuItem>
                      <MenuItem value="Onkar">Onkar</MenuItem>
                    </CustomSelect>
                    <ShowError touched={touched.head} message={errors.head} />
                  </CustomFormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Legal Entity
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Business Head"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="legal_entity_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.legal_entity_id}
                    >
                      {legalEntityList?.length > 0 ? (
                        legalEntityList?.map((legal) => (
                          <MenuItem value={legal?.id}>
                            {legal?.entity_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="0">Select</MenuItem>
                      )}
                    </CustomSelect>
                    <ShowError
                      touched={touched.legal_entity_id}
                      message={errors.legal_entity_id}
                    />
                  </CustomFormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Description"
                      variant="outlined"
                      size="large"
                      name="description"
                      value={values.description}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.description}
                      message={errors.description}
                    />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Edit Businee unit "
                ) : (
                  "Add Business Unit"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default BusinessUnitModal;
