import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { AddWorkDetail } from "../../hrApi";
import { errorsMessage } from "../../../constant";
import "../../HrDepartment.scss"
import ShowError from "../organisationStructure/OrganizationComponents/ShowError";
import { workDetailSchema } from "../AddEmployeeSchema";

const WorkDetailForm = ({formStage,
  setFormStage,
  setActiveKey,
  setWorkDetail,validationError}) => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState("");

  // useEffect(() => {
  //     setFieldValue("employee_id", employee_id);
  // }, [employee_id]);

  const initialValues = {
  
    leave_plan: "",
    shift: "",
    weekly_off: "",
    holiday_list: "",
    holiday_tracking: "",
  };

  const handleFocus = (event) => {
    setIsFocused(event.target.name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };



  const {
    values,
    errors,
    touched,
    handleChange,
 
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: workDetailSchema,
    onSubmit: (values) => {
      setWorkDetail(values)
     setFormStage(4)
     setActiveKey("Compensations")
    },
  });

  useEffect(()=>{
    setWorkDetail(values)
  },[values])
  return (
    <form onSubmit={  handleSubmit}>
      <div className="row justified-content-between mx-2">
        <div className="col-6">
          <label
            htmlFor="leave_plan"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "leave_plan" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Leave Plan
          </label>
          <div
            className={`${
              isFocused === "leave_plan"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="leave_plan"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={  values.leave_plan}
              onChange={  handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              <option value="2">Long Leave</option>
              <option value="1">Short Leave</option>
            </select>
            <ShowError
                      touched={ touched.leave_plan}
                      message={ errors.leave_plan}
                      validationError={ validationError?.leave_plan}
                    />
          </div>
        </div>
        <div className="col-6">
          <label
            htmlFor="shift"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "shift" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Shift
          </label>
          <div
            className={`${
              isFocused === "shift" ? "add_emp_input_active" : "add_emp_input"
            }`}
          >
            <select
              name="shift"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={  values.shift}
              onChange={  handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              <option value="2">10:30 AM to 7:00 PM</option>
              <option value="1">9:00 AM to 5:30 PM</option>
            </select>
            <ShowError
                      touched={ touched.shift}
                      message={ errors.shift}
                      validationError={ validationError?.shift}
                    />
          </div>
        </div>
        <div className="col-6">
          <label
            htmlFor="weekly_off"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "weekly_off" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Weekly Off
          </label>
          <div
            className={`${
              isFocused === "weekly_off"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="weekly_off"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={  values.weekly_off}
              onChange={  handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              <option value="7">Sunday </option>
              <option value="1">Monday</option>
            </select>
            <ShowError
                      touched={ touched.weekly_off}
                      message={ errors.weekly_off}
                      validationError={ validationError?.weekly_off}
                    />
          </div>
        </div>
        <div className="col-6">
          <label
            htmlFor="holiday_list"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "holiday_list" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Holiday List
          </label>
          <div
            className={`${
              isFocused === "holiday_list"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <input
              type="text"
              name="holiday_list"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={  values.holiday_list}
              onChange={  handleChange}
              placeholder="Enter Holiday List"
            />
              <ShowError
                      touched={ touched.holiday_list}
                      message={ errors.holiday_list}
                      validationError={ validationError?.holiday_list}
                    />
          </div>
        </div>
        <div className="col-6">
          <label
            htmlFor="holiday_tracking"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "holiday_tracking" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Holiday Tracking
          </label>
          <div
            className={`${
              isFocused === "holiday_tracking"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <input
              type="text"
              name="holiday_tracking"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={  values.holiday_tracking}
              onChange={  handleChange}
              placeholder="Enter Holiday Tracking"
            />
             <ShowError
                      touched={ touched.holiday_tracking}
                      message={ errors.holiday_tracking}
                      validationError={ validationError?.holiday_tracking}
                    />
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-7"></div>
        <div className="col-5" style={{ marginLeft: "auto", width: "auto" }}>
       
          <button type="submit" className="hr btn btn-primary mx-2">
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default WorkDetailForm;
