import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function EditTenderAttachment({ uploadAttachments,spinner }) {
  const [attachmentName, setAttachmentName] = useState({
    tenderDocs: [],
    commonDocs: [],
    bidDocs: [],
    pOCopies: [],
    miscellaneous: [],
    EmdDocs: [],
    PostTenderDocs:[]
  });
  const initialValues = {
    tenderDocs: [],
    commonDocs: [],
    bidDocs: [],
    pOCopies: [],
    miscellaneous: [],
    EmdDocs: [],
    PostTenderDocs:[]
  };
  const tenderFields = [
    { name: "tenderDocs", label: "Tender Docs" },
    { name: "commonDocs", label: "Common Docs" },
    { name: "bidDocs", label: "Bid Docs" },
    { name: "pOCopies", label: "PO Copies" },
    { name: "miscellaneous", label: "Miscellaneous" },
    { name: "EmdDocs", label: "Emd Docs" },
    { name: "PostTenderDocs", label: "Post Tender Docs" },
  ];

  const navigate = useNavigate();

  const handleFileChange = (e, i) => {
    const files = Array.from(e.target.files);

    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        const attachmentArraybase64 = filesArray.map(
          (element) => element
        );
        const attachmentList = { ...values };
        const currentArray = [...attachmentList[e.target.name]];
        attachmentList[e.target.name] = [
          ...currentArray,
          ...attachmentArraybase64,
        ];
        setValues(attachmentList);
        const attachmentNameList = { ...attachmentName };
        const currentName = [...attachmentNameList[e.target.name]];
        attachmentNameList[e.target.name] = [...currentName, ...filesArray];

        setAttachmentName(attachmentNameList);
      }
    );
  };

  function removeAttachment(i, key) {
    const updatedNameList = { ...attachmentName };
    updatedNameList[key] = updatedNameList[key].filter(
      (_, index) => index !== i
    );
    setAttachmentName(updatedNameList);
    const updatedvalues = { ...values };
    updatedvalues[key] = updatedvalues[key].filter((_, index) => index !== i);
    setValues(updatedvalues);
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({
          name: file.name,
          base64String: reader.result,
        });
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const { handleSubmit, values, resetForm, setValues } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      uploadAttachments(values, resetForm);
    },
  });
  return (
    <div className="form-group mb-3">
      <form onSubmit={handleSubmit}>
        <div className="row">
          {tenderFields.map((element, index) => (
            <div key={index} className="col-3 g-3">
              <label htmlFor="" className="form-label font-16 fw-bold">
                {element.label}
              </label>
              <input
                type="file"
                multiple
                onChange={(e) => handleFileChange(e, index)}
                className="form-control border-secondary"
                accept=".pdf"
                name={element.name}
              />
              {attachmentName[element.name].map((preview, i) => (
                <div
                  key={i}
                  className="d-flex justify-content-between w-100 my-2 shadow px-2 py-1 rounded"
                >
                  <a
                    href={preview.base64}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-3"
                  >
                    <p>{preview.name}</p>
                  </a>
                  <button
                    className="border-0 bg-white"
                    onClick={() => removeAttachment(i, element.name)}
                    type="button"
                  >
                    {" "}
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>

        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Adding tender attachments...</p>
          </div>
        ) : (
          <div className="mt-3">
            <button className="btn btn-danger">Add Attachments</button>
            <button
              className="btn btn-de-danger btn-sm border-danger ms-3"
              onClick={() => navigate(-1)}
              type="button"
            >
              Cancel
            </button>{" "}
          </div>
        )}
      </form>
    </div>
  );
}

export default EditTenderAttachment;
