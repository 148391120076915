import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { timeTypeList } from "../../../HrDepartment/hrApi";
import { setLoading } from "./LoaderSlice";
const initialState = { timeType: [] };
export const fetchTimeType = createAsyncThunk(
  "fetch/timeTypeList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await timeTypeList();

      return response?.data?.response?.data;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const timeTypeSlice = createSlice({
  name: "timeType",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchTimeType.pending, (state) => {})
      .addCase(fetchTimeType.fulfilled, (state, action) => {
        state.timeType = action.payload;
      })
      
      .addCase(fetchTimeType.rejected, (state, action) => {});
  },
});
export const getTimeType = (state) => state?.TimeTypeLists?.timeType;

export default timeTypeSlice.reducer;
