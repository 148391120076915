import React from "react";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

function CustomerTable({ detail }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  function formatDate(dates) {
    const dateString = dates;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }
  return (
    <div class="col-md-12">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ border: "0" }}>
              <th
                scope="col"
                style={{
                  width: "4rem",
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                }}
              >
                S.No.
              </th>
              <th
                scope="col"
                style={{
                  width: "8rem",
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                }}
              >
                Customer Name
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                GSTIN
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Railway Zone
              </th>
              <th
                scope="col"
                className="col-2"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Mobile Number
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Email
              </th>

              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {detail.map((item, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #E0E0E0" }}>
                <td>{index + 1}</td>
                <td>{item?.name || "- - -"}</td>
                <td>{item?.gst_in || "- - -"}</td>
                <td>{item?.invoice_no || "- - -"}</td>
                <td>{item?.mobile_Number || "- - -"}</td>
                <td>{item?.email || "- - -"}</td>
                <td>
                  <Link to="/updateCustomerDetail" state={{ detail: item }}>
                    <span>
                      <FaEdit style={{ width: "30px", height: "25px" }} />
                    </span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerTable;
