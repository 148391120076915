import React from "react";

function CompanyDetailForm({handleChange,values,errors,touched,serverSideValidation}) {
  return (
    <div>
      
        <div className="row">
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold">
              Company Type
            </label>
            <select
              className="form-control border-secondary "
              onChange={handleChange}
              value={values.company_type}
              name="company_type"
            >
              <option value="">Select</option>
              <option value="">Company 1</option>
              <option value="">Company 2</option>
            </select>
            <p className="text-danger m-0">
            
            {touched?.company_type && errors?.company_type
              ? errors?.company_type
              : serverSideValidation?.company_type}
          </p>
          </div>

          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold">
              Discount Percentage
            </label>
            <input
              className="form-control border-secondary"
              placeholder="Enter Discount Percentage"
              onChange={handleChange}
              value={values.discount_percente}
              name="discount_percente"
              
            ></input>
            <p className="text-danger m-0">
            
            {touched?.discount_percente && errors?.discount_percente
              ? errors?.discount_percente
              : serverSideValidation?.discount_percente}
          </p>
          </div>

          <div className="col-6">
            <label htmlFor="" className="form-label fw-bold">
              Remark
            </label>
            <input
              className="form-control border-secondary"
              placeholder="Enter Remark"
              onChange={handleChange}
              value={values.remarks}
              name="remarks"
              
            ></input>
             <p className="text-danger m-0">
            
            {touched?.remarks && errors?.remarks
              ? errors?.remarks
              : serverSideValidation?.remarks}
          </p>
          </div>
        </div>
      
    </div>
  );
}

export default CompanyDetailForm;
