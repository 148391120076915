import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DashboardProgress from "../../../Components/DashboardProgress";
import { setLogout } from "../../../redux/features/authSlice";
import PendingStatusAlert from "./WarrantyTracker/PendingStatusAlert";
import { getPendingWarrantyTrackerList } from "../../../redux/api";
import { errorsMessage } from "../../../constant";
function BackOfficeDashboard() {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [targetYear, setTargetYear] = useState("2023-2024");
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pendingStatusList, setPendingStatusList] = useState([])
  const dispatch = useDispatch();

  function getFinancialYearTarget() {
    const url = baseUrl + "getfinancialyeardata";
    let data = { user_id, target_year: targetYear, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          setFinancialData(response.data.response.getFinancialYearData);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(setLogout());
          toast.error("Token is expired, Please Login again");
        }
      });
  }
  function PendingStatusTableList() {
    setLoading(true);
    getPendingWarrantyTrackerList({ user_id })
      .then((res) => {
        setPendingStatusList(res?.data?.response?.TrakerList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
useEffect(()=>{
  PendingStatusTableList()
},[getToken])
  useEffect(() => {
    getFinancialYearTarget();
  }, [targetYear]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          {/* <!-- Page-Title --> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="float-end">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active"> Dashboard</li>
                  </ol>
                </div>
                <h4 className="page-title"> DASHBOARD </h4>
              </div>
            </div>
          </div>
          {/* <!-- end page title end breadcrumb --> */}

          <div className="row">
            <div className="col-md-5">
              <div className="card overflow-hidden card-radius">
                <div className="card-body">
                  <div>
                    <h3 className="text-dark font-24 fw-bold line-height-lg">
                      ACME INDIA INDUSTRIES <br />
                      PVT. LTD.
                    </h3>
                    <p className="font-16 pb-1 dashboard-text">
                      We Design and Develop Clean and High Quality Web
                      Applications
                    </p>
                    {/* <div className=" py-3 mb-3">
                        <Link to="#" className="btn btn-primary px-3">
                          Know More
                        </Link>
                      </div> */}
                  </div>
                  <div className="mt-4 table-border-radius">
                    <Table responsive>
                      <thead>
                        <tr className="bg-table-black">
                          <th
                            className="text-light"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Click to Change Year"
                          >
                            {" "}
                            <select
                              className="form-control bg-black text-light border-0 fw-bold"
                              name="target_year"
                              style={{ cursor: "pointer" }}
                              value={targetYear}
                              onChange={(e) => setTargetYear(e.target.value)}
                            >
                              <option value="" disabled selected hidden>
                                Select a Target Year
                              </option>

                              <option value="2023-2024">2023-2024</option>
                              <option value="2024-2025">2024-2025</option>
                              <option value="2025-2026">2025-2026</option>
                              <option value="2026-2027">2026-2027</option>
                              <option value="2027-2028">2027-2028</option>
                              <option value="2028-2029">2028-2029</option>
                              <option value="2029-2030">2029-2030</option>
                            </select>
                          </th>
                          <th className="text-light">Target</th>
                          <th className="text-light">Achieved</th>
                        </tr>
                      </thead>
                      <tbody>
                        {financialData.length ? (
                          financialData.map((element, index) => {
                            return (
                              <tr className="border-bottom">
                                <td className="border-end">
                                  <p className="dashboard-text text-danger">
                                    {element.target_name}
                                  </p>
                                </td>
                                <td className="border-end">
                                  <p className="dashboard-text">
                                    {Number(
                                      element.target_value
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  </p>
                                </td>
                                <td>
                                  <p className="dashboard-text">
                                    {" "}
                                    {Number(
                                      element.achieved_value
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}{" "}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan="3">No Data for Selected Year</td>
                            </tr>
                          </>
                        )}
                        {/* <tr className="border-bottom">
                            <td className="border-end">
                              <p className="dashboard-text">
                                {" "}
                                Paymrnt Received (April 23 - Aug 23){" "}
                              </p>
                            </td>
                            <td className="border-end">
                              <p className="dashboard-text"> 608,232,942.60 </p>
                            </td>
                            <td>
                              <p className="text-danger"> 549,412,589.80 </p>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="border-end text-danger">
                              <p className="text-danger">
                                {" "}
                                Payment (Aug) Balance Payment{" "}
                              </p>
                            </td>
                            <td className="border-end">
                              <p className="dashboard-text"> 141,274,311.37 </p>
                            </td>
                            <td className="px-0 py-2">
                              <p className="text-danger border-bottom ps-4 pb-2">
                                {" "}
                                #131,132,187.00{" "}
                              </p>
                              <p className="ps-4">&nbsp;</p>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="border-end">
                              <p className="dashboard-text">
                                {" "}
                                BG limit & Utilised
                              </p>
                              <p className="text-danger">Balance BG limit </p>
                            </td>
                            <td className="border-end">
                              <p className="dashboard-text"> 140,000,000.00 </p>
                            </td>
                            <td>
                              <p className="dashboard-text"> 133,751,425.15 </p>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="border-end">
                              <p className="dashboard-text"> VSV Claim </p>
                            </td>
                            <td className="border-end">
                              <p className="dashboard-text"> </p>
                            </td>
                            <td>
                              <p className="text-danger"> #REF! </p>
                            </td>
                          </tr> */}

                        {/* <tr>
                            <td colSpan={3}>
                              <Link to="javascript:void(0);" className="btn btn-danger w-100"> View All</Link>
                            </td>
                          </tr> */}
                      </tbody>
                    </Table>
                    <PendingStatusAlert pendingStatusList={pendingStatusList} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="row ">
                <div className="col-lg-3 col-md-6">
                  <div className="card overflow-hidden card-radius">
                    <div className="card-body">
                      <div className="row d-flex">
                        <div className="col-3">
                          {/* <i className="ti ti-users font-36 align-self-center text-dark"></i> */}
                          <img
                            src="assets/images/dashboard-icons/users.svg"
                            alt="dashboard-icon"
                            className="img-fluid w-100"
                          />
                        </div>
                        <div id="dash_spark_1" className="mb-3"></div>
                        <div className="col-12 ms-auto align-self-center">
                          <h3 className="text-dark my-0 font-22 fw-bold">
                            1200+
                          </h3>
                          <p className="dashboard-text mb-0 font-14 fw-semibold mt-1">
                            Employee strength
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card overflow-hidden card-radius">
                    <div className="card-body">
                      <div className="row d-flex">
                        <div className="col-3">
                          {/* <i className="ti ti-clock font-36 align-self-center text-dark"></i> */}
                          <img
                            src="assets/images/dashboard-icons/time.svg"
                            alt="dashboard-icon"
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-12 ms-auto align-self-center">
                          <div id="dash_spark_2" className="mb-3"></div>
                        </div>
                        <div className="col-12 ms-auto align-self-center">
                          <h3 className="text-dark my-0 font-22 fw-bold">
                            1800+
                          </h3>
                          <p className="dashboard-text mb-0 font-14 fw-semibold">
                            Newly Furnished Coaches
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card overflow-hidden card-radius">
                    <div className="card-body">
                      <div className="row d-flex">
                        <div className="col-3">
                          {/* <i class="fa-solid fa-chart-pie font-36 align-self-center text-dark"></i> */}
                          <img
                            src="assets/images/dashboard-icons/graph.svg"
                            alt="dashboard-icon"
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-12 ms-auto align-self-center">
                          <div id="dash_spark_3" className="mb-3"></div>
                        </div>
                        <div className="col-12 ms-auto align-self-center">
                          <h3 className="text-dark my-0 font-22 fw-bold">
                            1600+
                          </h3>
                          <p className="dashboard-text mb-0 font-14 fw-semibold">
                            Coaches Refurbished
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card overflow-hidden card-radius">
                    <div className="card-body">
                      <div className="row d-flex">
                        <div className="col-3">
                          {/* <i class="fa-solid fa-door-open font-36 align-self-center text-dark"></i> */}
                          <img
                            src="assets/images/dashboard-icons/door.svg"
                            alt="dashboard-icon"
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-12 ms-auto align-self-center">
                          <h3 className="text-dark my-0 font-22 fw-bold mt-1">
                            7000+
                          </h3>
                          <p className="dashboard-text mb-0 font-14 fw-semibold">
                            Doors
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="card card-radius">
                    <div>
                      <div className="row ">
                        <div className="col-6 ps-4 align-self-end">
                          {/* <i class="fa-solid fa-toilet font-36 align-self-center text-dark"></i> */}
                          <img
                            src="assets/images/dashboard-icons/toilet.svg"
                            alt="dashboard-icon"
                            className="img-fluid"
                            style={{ width: "30%" }}
                          />
                          <div className="pb-4 mt-2">
                            <p className="dashboard-text lh-4 mb-0 font-14 fw-semibold">
                              Toilet <br /> Upgraded
                            </p>
                          </div>
                        </div>
                        <div className="col-6 ps-0 border-start">
                          <div className="ps-4 pb-3 pt-3">
                            <h3 className="text-dark my-0 font-22 fw-bold">
                              3000
                            </h3>
                            <p className="dashboard-text mb-0 font-14 fw-semibold">
                              Target
                            </p>
                          </div>
                          <div className="border-top ps-4 pt-3 pb-3">
                            <h3 className="text-dark my-0 font-22 fw-bold">
                              500
                            </h3>
                            <p className="dashboard-text mb-0 font-14 fw-semibold">
                              Achieved
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card card-radius overflow-hidden  ">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="card-title">Progress Analysis </h4>
                        </div>
                        {/* <div className="col-auto">
                            <div className="dropdown">
                              <Link
                                to="#"
                                className="btn btn-sm btn-outline-light dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                This Year
                                <i className="las la-angle-down ms-1"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  Today
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Last Week
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Last Month
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  This Year
                                </Link>
                              </div>
                            </div>
                          </div> */}
                      </div>
                    </div>
                    {/* <!--end card-header--> */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <DashboardProgress />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <Accordion defaultActiveKey="0" className="table-border-radius">
              <Accordion.Item eventKey="0" className="table-border-radius">
                <Accordion.Header className="mt-0 bg-table-black">
                  FY 23-24 - Project Target (Turn Key)
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="">
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="table-border-radius mt-4">
                <Accordion.Header className="bg-table-black mt-0" r>
                  Supply & Installation
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div>
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="table-border-radius mt-4">
                <Accordion.Header className="bg-table-black mt-0">
                  VCB
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <div>
                    <Table className="mb-0 dashboard-table" responsive>
                      <thead>
                        <tr className="bg-white border">
                          <th className="border text-danger font-16 th-small">
                            SN
                          </th>
                          <th className="border text-danger font-16 ">
                            Manager
                          </th>
                          <th className="border text-danger font-16 ">
                            Projects
                          </th>
                          <th className="border text-danger font-16 th-small-mid">
                            Total
                          </th>
                          <th className="border text-danger font-16 th-mid">
                            Complete
                          </th>
                          <th className="border text-danger font-16 td-min-long">
                            DP
                          </th>
                          <th className="border text-danger font-16 th-long">
                            Status (as on 04 Oct)
                          </th>
                          <th className="border text-danger font-16">
                            <select
                              class="form-select form-select-lg text-danger fw-bold border-0"
                              name=""
                              id=""
                            >
                              <option value="">Oct</option>
                              <option value="">Nov</option>
                              <option value="">Dec</option>
                              <option value="">Jan</option>
                              <option value="">Feb</option>
                              <option value="">Mar</option>
                            </select>
                          </th>
                          <th className="border text-danger font-16 font-14">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border">
                          <td className="border th-small">
                            <p>1</p>
                          </td>
                          <td className="border">
                            <p> Bheem Pandey</p>
                          </td>
                          <td className="border">
                            <p> MCF- DDG </p>
                          </td>
                          <td className="border td-mid">
                            <p> 63</p>
                          </td>
                          <td className="border ">
                            <p> 19</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              15-07.10.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              15-06.11.2023
                            </p>
                            <p className="px-4 pb-2">14-06.12.2023</p>
                          </td>
                          <td className="border">
                            <p>Project on time Pay by - 15 Oct</p>
                          </td>
                          <td className="border">
                            <p> 15</p>
                          </td>
                          <td className="border ">
                            <p> 122,842,015.97 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>2</p>
                          </td>
                          <td className="border">
                            <p> Sivesh Mishra</p>
                          </td>
                          <td className="border">
                            <p> BEML- Mockup </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border">
                            <p> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="px-4 pb-2">
                              20.09.2023 <br /> (to re-fix)
                            </p>
                          </td>

                          <td className="border">
                            <p className="text-danger">
                              80% work completed expected by 15 Oct Billing &
                              payment - 10 day process Pay by - 30 Oct
                            </p>
                          </td>
                          <td className="border">
                            <p> 1</p>
                          </td>
                          <td className="border th-min-long">
                            <p> 5,896,000.00 </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>3</p>
                          </td>
                          <td className="border">
                            <p> Dheeraj</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">Vijaywada</p>
                            <p className="px-4 pb-2">BCL</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 40</p>
                            <p className="px-4 pb-2"> 217</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 33</p>
                            <p className="px-4 pb-2"> 50</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              13.10.2023
                            </p>
                            <p className="px-4 pb-2"> 08.10.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="text-danger border-bottom px-4 pb-2">
                              {" "}
                              34-35 - offer for inspection on 30 Set 36 - Offer
                              by 3 Oct
                            </p>
                            <p className="text-danger px-4 pb-2">
                              {" "}
                              today lrc- dy by wed- frid - braith pay
                            </p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              2 (37-38)
                            </p>
                            <p className="px-4 pb-2"> 15 (56-70)</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className=" border-bottom px-4 pb-2">
                              17,687,814.30
                            </p>
                            <p className="px-4 pb-2">&nbsp; </p>
                          </td>
                        </tr>
                        <tr className="border">
                          <td className="border">
                            <p>4</p>
                          </td>
                          <td className="border">
                            <p> RK Pandey</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              CR- Perambur
                            </p>
                            <p className="border-bottom px-4 pb-2"> CRGKP</p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              Bhavnagar
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              ICF - LRAAC
                            </p>
                            <p className="border-bottom px-4 pb-2"> RCF RA</p>
                            <p className="border-bottom px-4 pb-2"> NER- NMG</p>
                            <p className="px-4 pb-2"> NR RCNK</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 16</p>
                            <p className="border-bottom px-4 pb-2"> 65</p>
                            <p className="border-bottom px-4 pb-2"> 25</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 6</p>
                            <p className="border-bottom px-4 pb-2"> 22</p>
                            <p className="border-bottom px-4 pb-2"> 175</p>
                            <p className="px-4 pb-2"> 80</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2"> 8</p>
                            <p className="border-bottom px-4 pb-2"> 41</p>
                            <p className="border-bottom px-4 pb-2"> 14</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="border-bottom px-4 pb-2"> 2</p>
                            <p className="border-bottom px-4 pb-2"> 0</p>
                            <p className="px-4 pb-2"> 0</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.01.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.11.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              30.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              31.03.2024
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              25.08.2023
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              18.09.2024
                            </p>
                            <p className="px-4 pb-2">03.05.2024</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 4</p>
                            <p className="border-bottom px-4 pb-2">&nbsp;</p>
                            <p className="border-bottom px-4 pb-2"> 1</p>
                            <p className="border-bottom px-4 pb-2"> 3</p>
                            <p className="border-bottom px-4 pb-2"> 15</p>
                            <p className="px-4 pb-2">&nbsp;</p>
                          </td>
                          <td className="border px-0 py-1">
                            <p className="border-bottom px-4 pb-2">&nbsp; </p>
                            <p className="border-bottom px-4 pb-2">
                              27,069,096.00{" "}
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              2,839,501.56
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              {" "}
                              5,961,950.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              43,442,970.91
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              119,889,824.00
                            </p>
                            <p className="border-bottom px-4 pb-2">
                              27,105,231.60{" "}
                            </p>
                            <p className="px-4 pb-2">324,987,474.05</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="px-3" colSpan={9}>
                            <div className="d-flex justify-content-between">
                              <h6 className="font-16">Total</h6>
                              <h6 className="font-16">982,709,614.11</h6>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card table-border-radius">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                        AIIPL- Billing (Turnover)
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount Billed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <div className="text-start">
                            <h6 className="font-16">Total</h6>
                            <h6 className="font-16">Target</h6>
                            <h6 className="font-16">Balance Target</h6>
                          </div>
                          <div className="text-end">
                            <h6 className="text-danger font-16">#REF!</h6>
                            <h6 className="text-danger font-16">
                              1,500,000,000.00
                            </h6>
                            <h6 className="text-danger font-16">#REF!</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card table-border-radius ">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={2} className="text-light">
                        Payment Received
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount Billed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="px-3">
                        <div className="d-flex justify-content-between">
                          <h6 className="font-16">Total</h6>
                          <h6 className="text-danger font-16">
                            549,412,589.80
                          </h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card table-border-radius ">
                <Table responsive>
                  <thead>
                    <tr className="bg-table-black">
                      <th colSpan={3} className="text-light">
                        Payment Received
                      </th>
                    </tr>
                    <tr className="bg-white">
                      <th>Month</th>
                      <th>Amount Billed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom">
                      <td className="border-end">
                        <p> April </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> May </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> June </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> July </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> August </p>
                      </td>
                      <td>
                        <p> #REF! </p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> September </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> October </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> November </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> December </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> January </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> February </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td className="border-end text-danger">
                        <p> March </p>
                      </td>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border-bottom">
                      <td>
                        <h6 className="font-16">Total</h6>
                      </td>
                      <td>
                        <h6 className="text-danger font-16">0.00</h6>
                      </td>
                      <td>
                        <h6 className="text-danger font-16">0.00</h6>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BackOfficeDashboard;
