import * as Yup from "yup";

export const leagalEntitySchema = Yup.object({
  entity_name: Yup.string().required("Please enter entity name"),
  legal_name: Yup.string().required("Please enter leagal name"),

  cin: Yup.string().required("Please enter company identification number"),
  date_of_incorp: Yup.string().required("Please enter date"),
  business_type: Yup.string().required("Please enter business type"),
  sector: Yup.string().required("Please enter sector"),
  business_nature: Yup.string().required("Please enter business nature"),
  address_line_1: Yup.string().required("Please enter address line 1"),
  address_line_2: Yup.string().required("Please enter address line 2"),
  state: Yup.string().required("Please enter state"),
  city: Yup.string().required("Please enter city"),
  zip: Yup.string().required("Please enter zip"),
});

export const addAuthorizeSignatureSchema = Yup.object({
  name: Yup.string().required("Please enter signature name"),
  designation: Yup.string().required("Please enter signature designation"),
  email: Yup.string().required("Please enter email name"),
  father_name: Yup.string().required("Please enter father name name"),
  pan: Yup.string().required("Please enter pan name"),
  address_line_1: Yup.string().required("Please enter address"),
  address_line_2: Yup.string().required("Please enter address"),

  state: Yup.string().required("Please enter state"),
  city: Yup.string().required("Please enter city"),
  zip: Yup.string().required("Please enter zip"),
});

export const addBankDetailSchema = Yup.object({
  bank_name: Yup.string().required("Please enter bank name"),
  account_number: Yup.string().required("Please enter account number"),
  ifsc: Yup.string().required("Please enter ifsc code"),
  branch: Yup.string().required("Please enter branch name"),
  corporate_id: Yup.string().required("Please enter corporate id "),
  user_id: Yup.string().required("Please enter user id"),
  alias_id: Yup.string().required("Please enter alias id"),
});
export const addBusinessUnitSchema = Yup.object({
  name: Yup.string().required("Please enter  name"),
  head: Yup.string().required("Please enter business head name"),
  description: Yup.string().required("Please enter description"),
  legal_entity_id:Yup.string().required("Please select legal Entity"),
});

export const addLocationSchema = Yup.object({
  name: Yup.string().required("Please enter  name"),
  head: Yup.string().required("Please enter location head"),
  group_email: Yup.string().required("Please enter group email"),
  address_line_1: Yup.string().required("Please enter address line 1"),
  address_line_2: Yup.string().required("Please enter address line 2"),
  country: Yup.string().required("Please enter country"),
  state: Yup.string().required("Please enter state"),
  city: Yup.string().required("Please enter city"),
  zip: Yup.string().required("Please enter zip"),
  description: Yup.string().required("Please enter description"),
});
