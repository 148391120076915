import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import doneSound from "../../Assets/Noti.mp3";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { AiFillCaretDown } from "react-icons/ai";
import { getboardgroupstatus } from "../../redux/api";
import { toast } from "react-toastify";
import TicketDescriptionModal from "../../Tender/TenderModal/TicketDescriptionModal";

export default function StatusList({
  workStatus,
  getTicketId,
  modalShow,
  kanbanSchedule,
  currentStatus,
  projectMembers,
}) {
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [ticketDescription, setTicketDescription] = useState("");
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const audioPlayer = useRef(null);
  const [assigneeLoader, setAssigneeLoader] = useState(false);
  const [clickedTicket, setClickedTicket] = useState(null);
  const [loadingTicketId, setLoadingTicketId] = useState(null);
  const [groupStatus, setGroupStatus] = useState([]);

  function formatDate(value) {
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function doubleClickhHandle(id) {
    getTicketId(id);
    modalShow();
  }

  function getTicket(id) {
    let ticket = workStatus.find((element) => element.id === +id);
    setClickedTicket(ticket);
  }

  function playAudio() {
    audioPlayer.current.play();
  }

  async function changeAssignee(assignee_id, ticket) {
    setAssigneeLoader(ticket);
    const url = baseUrl + "changeticketassignee";
    const body = { user_id, ticketId: clickedTicket.id, assignee_id };
    await axios
      .post(url, body, config)
      .then((response) => {
        if (response.status === 200) {
          kanbanSchedule();
          playAudio();
        }
      })
      .catch((error) => {})
      .finally(() => {
        setAssigneeLoader(null);
      });
  }

  function changeStatusList(status, ticket) {
    setLoadingTicketId(ticket);
    const url = baseUrl + "updateprojectticketboardgroup";
    const data = {
      user_id,
      board_group_id: workStatus[0]?.board_group_id,
      board_group_status_id: status,
      ticketId: ticket,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          kanbanSchedule();
          toast.success("Status changed successfully");
        }
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong, Please try again");
      })
      .finally(() => {
        setLoadingTicketId(null);
      });
  }

  function getGroupStatusDropdown() {
    getboardgroupstatus({
      user_id: user_id,
      board_group_id: workStatus[0]?.board_group_id,
    })
      .then((response) => {
        setGroupStatus(response?.data?.response?.statusDropDown);
      })
      .catch((err) => {});
  }

  function changeBoardGroup(ticket) {
    const url = baseUrl + "changeprojectboardgroup";
    const data = {
      user_id,
      board_group_id: workStatus[0]?.board_group_id,
      ticket_id: ticket,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          kanbanSchedule();
          toast.success("Group changed successfully");
        }
      })
      .catch((error) => {
        toast.error("Oops! Something went wrong, Please try again");
      })
      .finally(() => {
        setLoadingTicketId(null);
      });
  }

  function ifDelayed(time) {
    const dueTime = new Date(time).getTime();
    const currentTime = new Date().getTime();
    if (currentTime < dueTime) {
      return false;
    } else {
      return true;
    }
  }
  console.log("currentStatus", currentStatus);
  return (
    <div className="kanban-col" style={{ maxWidth: "40%" }}>
      <audio ref={audioPlayer} src={doneSound} />

      <div className="kanban-main-card overflow-visible">
        <div className="kanban-box-title">
          <h2 className="header-title mb-3 text-wrap font-18">
            {currentStatus}
          </h2>
        </div>

        {workStatus.length ? (
          workStatus.map((ticket, i) => {
            return (
              <div
                className="card"
                key={ticket.id}
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
                onDoubleClick={() => doubleClickhHandle(ticket.id)}
                onClick={() => getTicket(ticket.id)}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h5
                        className="my-1
                         fw-bold mb-3 text-wrap"
                      >
                        <i className="mdi mdi-circle-outline me-2 font-14 text-warning"></i>
                        {ticket.title}
                      </h5>
                    </div>
                  </div>

                  <p
                    className="mb-3 mt-1 text-wrap"
                    onClick={() => {
                      setShowDescriptionModal(true);
                      setTicketDescription(ticket.description)
                    }}
                  >
                    {ticket.description?.length <= 120 ? (
                      ticket.description
                    ) : (
                      <>
                        {ticket.description.slice(0, 120)}
                        <span
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                        >
                          ...read More
                        </span>
                      </>
                    )}
                  </p>

                  <div className="row mt-2 mb-3 border-bottom">
                    <div className="col-6">
                      <p className="m-0">Start Date :</p>
                      <p style={{ fontWeight: 550, margin: 0 }}>
                        {formatDate(ticket?.start_date)}
                      </p>
                    </div>
                    <div className="col-6 text-end">
                      <p className="m-0">Due Date :</p>

                      <p
                        className={ifDelayed(ticket.due_date) && "text-danger"}
                        style={{ fontWeight: 550, margin: 0 }}
                      >
                        {formatDate(ticket.due_date)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <p className="text-wrap m-0 fw-bold status-dropdown">
                        {loadingTicketId === ticket.id ? (
                          <Spinner animation="border" variant="danger" />
                        ) : (
                          <Dropdown as={ButtonGroup} className="" drop="end">
                            <Button variant="success">
                              {ticket.board_group_status_name}
                            </Button>
                            <div onClick={getGroupStatusDropdown}>
                              <Dropdown.Toggle
                                split
                                variant="success"
                                id="dropdown-basic"
                              >
                                <AiFillCaretDown />
                              </Dropdown.Toggle>
                            </div>
                            <Dropdown.Menu>
                              {groupStatus &&
                                groupStatus.map((element) => {
                                  return (
                                    <div
                                      key={element.id}
                                      onClick={() =>
                                        changeStatusList(element.id, ticket.id)
                                      }
                                    >
                                      <Dropdown.Item
                                        style={{
                                          borderBottom: "1px solid lightgray",
                                        }}
                                        active={
                                          element.id ===
                                          ticket.board_group_status_id
                                        }
                                      >
                                        {element?.name}
                                      </Dropdown.Item>
                                    </div>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </p>
                    </div>
                    <div className="col-3 text-end">
                      {assigneeLoader === ticket.id ? (
                        <Spinner animation="border" variant="danger" />
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="p-0 bg-transparent border-0"
                          >
                            <Link className="float-end">
                              <img
                                src={
                                  imageBaseUrl + ticket.assignee?.profileimage
                                }
                                alt={ticket.assignee?.name.split(" ")[0]}
                                className="thumb-xs rounded-circle"
                              />
                              <p className="m-0" style={{ fontSize: "13px" }}>
                                {ticket.assignee.name.split(" ")[0]}
                              </p>
                            </Link>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="text-center bg-dark text-white py-2 mb-2">
                              Change Assignee
                            </div>

                            {projectMembers &&
                              projectMembers.map((element) => {
                                return (
                                  <div
                                    key={element.user_id}
                                    onClick={() =>
                                      changeAssignee(element.user_id, ticket.id)
                                    }
                                  >
                                    <Dropdown.Item>
                                      <img
                                        src={
                                          imageBaseUrl + element?.profileimage
                                        }
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          borderRadius: "50%",
                                        }}
                                      />{" "}
                                      {element?.name}
                                    </Dropdown.Item>
                                  </div>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {ticket.board_group_status_name === "Completed" &&
                    ticket.board_group_name !== "Completed" && (
                      <button
                        className="next-stage-btn"
                        onClick={() => changeBoardGroup(ticket.id)}
                      >
                        MOVE TO NEXT GROUP
                      </button>
                    )}

                  {ticket.board_group_name === "Todo" && (
                    <button
                      className="next-stage-btn"
                      onClick={() => changeBoardGroup(ticket.id)}
                    >
                      START TICKET
                    </button>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <TicketDescriptionModal
        show={showDescriptionModal}
        setShow={setShowDescriptionModal}
        description={ticketDescription}
      ></TicketDescriptionModal>
    </div>
  );
}
