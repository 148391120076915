import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "../HrDepartment.scss"
const EmployeeAttendance = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <span className="dashboard">ATTENDANCE</span>

            <Box sx={{ flexGrow: 1 }} className="mt-2">
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="gap_24 height_227px">
                        <div className="d-flex justify-content-between ">
                          <h5 className="emp_login_fontStyle_leave">
                            Attendance Stats
                          </h5>
                          <select className="days_selector_style_leave">
                            <option>Last 30Days</option>
                            <option>Last 15Days</option>
                          </select>
                        </div>
                        <div className="employee_count">
                          {/* <div className=""> */}
                          <div className="total_employee width_180px margin_l_5">
                            <span
                              className="fourty"
                              style={{ fontSize: "20px" }}
                            >
                              9 Hrs 50min
                            </span>
                            <div className="absolute_fix">
                              <span className="">AVG HRS / DAY</span>
                              <br />
                              <span className="lastMonth">
                                +8% from last month
                              </span>
                            </div>
                          </div>
                          {/* </div> */}
                          {/* <div className=""> */}

                          <div className="registered width_180px">
                            <span
                              className="fourty"
                              style={{ fontSize: "20px" }}
                            >
                              70%
                            </span>
                            <div className="absolute_fix">
                              <span className="">On Time Arrival</span>
                              <br />
                              <span className="lastMonth">
                                +8% from last month
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="gap_24 height_227px">
                        <Typography className="requestHeading">
                        Actions
                        </Typography>

                        <div className="textOfpARRAt justifySpa">
                          <div className=" g-17 w-100">
                            <div className="display_flex_justify">
                            <div className="total_employee  " style={{height:"100%",width:"150px"}}>
                         
                            <div className="mt-2 mb-2">
                              <span className="">AVG HRS / DAY</span>
                              <br />
                              <span className="lastMonth">
                                +8% from last month
                              </span>
                            </div>
                          </div>
                           <div >
                            <div className="w_160px  ">
                              <button className="buttonFeedT w-100">
                              Work From Home
                              </button>
                            </div>
                            <div className="w_160px mt-2">
                              <button className="buttonFeedF w-100">
                               On Duty
                              </button>
                            </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <>
                <div className="row mt-5">
                  <div class="table-responsive ms-0 me-0">
                    <div className="card mb-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="attendance-stats ps-3">
                          Attendance Logs
                        </h4>
                        <select
                          className="days_selector_style mb-3"
                          style={{ padding: ".5rem 4rem .5rem 3rem" }}
                        >
                          <option>Last 30 Days </option>
                          <option>Last 15 Days </option>
                        </select>
                      </div>
                    </div>
                    <table class="table table-bordered ">
                      <thead>
                        <tr>
                          <th>Days</th>
                          <th>Punch IN</th>
                          <th>Punch OUT</th>
                          <th>Gross Hours</th>
                          <th>Effective Hours</th>
                          <th>Status</th>
                          <th>Logs</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-bottom">
                          <td>06 June, Thu</td>
                          <td style={{ color: "#399600" }}>10:02 AM</td>
                          <td style={{ color: "#D90000" }}>07:10 PM</td>
                          <td>06 June, Thu</td>
                          <td>10:02 AM</td>
                          <td>
                            <button className="chartButton">Present </button>
                          </td>
                          <td className="headingStylePayslip">
                            <u>View</u>
                          </td>
                        </tr>
                        <tr className="border-bottom">
                          <td>06 June, Thu</td>
                          <td style={{ color: "#399600" }}>10:02 AM</td>
                          <td style={{ color: "#D90000" }}>07:10 PM</td>
                          <td>06 June, Thu</td>
                          <td>10:02 AM</td>

                          <td>
                            <button className="chartButtonRejected">
                            Holiday{" "}
                            </button>
                          </td>
                          <td className="headingStylePayslip">
                            <u>View</u>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeAttendance;
