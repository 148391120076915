import * as Yup from "yup";

export const probationPolicySchema = Yup.object({
  name: Yup.string().required("This field is required."),

  description: Yup.string().required("This field is required."),

  duration: Yup.number().required("This field is required."),
  max_extension: Yup.number().required("This field is required."),
});

export const jobTitleSchema = Yup.object({
  title: Yup.string().required("This field is required."),

  description: Yup.string().required("This field is required."),
});
export const timeTypeSchema = Yup.object({
  type: Yup.string().required("This field is required."),

  description: Yup.string().required("This field is required."),
});







export const workerTypeSchema = Yup.object({
  name: Yup.string().required("This field is required."),

  description: Yup.string().required("This field is required."),

  // Checkbox fields for conditional validation
  minContractCheckbox: Yup.boolean(),
  maxContractCheckbox: Yup.boolean(),

  min_contract_duration: Yup.number()
    .nullable()
    .typeError('Minimum duration must be a number')
    .when('minContractCheckbox', {
      is: true,
      then: (schema) => schema.required('Minimum duration is required'),
    }),

  max_contract_duration: Yup.number()
    .nullable()
    .typeError('Maximum duration must be a number')
    .when('maxContractCheckbox', {
      is: true,
      then: (schema) => schema.required('Maximum duration is required'),
    })
    .test(
      'min-max-duration',
      'Maximum duration must be greater than or equal to minimum duration',
      function (value) {
        const { min_contract_duration, maxContractCheckbox } = this.parent;

        // Only perform the check if both fields are filled and max duration checkbox is checked
        if (maxContractCheckbox && value != null && min_contract_duration != null) {
          return value >= min_contract_duration;
        }

        return true; // If either field is not filled, pass validation
      }
    ),
});

