import React, { useState,useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../HrDepartment.scss"
import { updateAddress } from "../../hrApi";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, setLoading } from "../../../redux/features/Hr/LoaderSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import EmployeeDetail from "./EmployeeDetail";
import { FiLoader } from "react-icons/fi";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius:"10px"
};

export default function EditEmployeeAddresses({ handleClose, open,data ,employeeDetail }) {
  const [isFocused, setIsFocused] = useState("");
  const[validationError,setValidationError] = useState(null)
  const dispatch = useDispatch()
  const loader = useSelector(selectLoading)
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };
  const initialValues = {
   current_address:"",
   permanent_address:"",
   emergency_contact:""
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    // handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: addBusinessUnitSchema,
  
    onSubmit: (values) => {
      updateEmployeeAddress();
    },
  });
  useEffect(()=>{
setValues(data)
  },[data,open])
  const updateEmployeeAddress =async()=>{
    dispatch(setLoading(true))
    const reqpayload ={employee_id:data?.id,...values}
try{
  const response = await updateAddress(reqpayload)
  handleClose()
  employeeDetail()
  toast.success(response?.data?.response?.message,{autoClose:2000})
}catch(error){
  setValidationError(error?.response?.data?.response)
  toast.error(error?.response?.data?.message);
}finally{dispatch(setLoading(false))}
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="edit_primary_details">Addresses</Typography>
          <form onSubmit={handleSubmit}>
            <div className="row">
            <div  className="col-5">
            <label htmlFor="" className={`form-label fw-bold font-12 font-weight-500  ${isFocused=="current_address" ? 'text-danger' : 'text-black'}`} style={{paddingLeft:".75rem",marginTop:"1rem"}}>
           
            Current Address
            </label>
            <div className={`${isFocused=="current_address" ? 'add_emp_input_active' : 'add_emp_input'}`}>
              <input
                type="text"
                name="current_address"
                style={{paddingLeft:".75rem"}}
                className="form-control form-control-sm border-secondary"
                onFocus={(event)=>handleFocus(event)}
                onBlur={handleBlur}
                value={values?.current_address}
                onChange={handleChange}
                placeholder="Current Address"
              />
            </div>
          </div>
              <div className="col-2"></div>
              <div  className="col-5">
            <label htmlFor="" className={`form-label fw-bold font-12 font-weight-500  ${isFocused=="permanent_address" ? 'text-danger' : 'text-black'}`} style={{paddingLeft:".75rem",marginTop:"1rem"}}>
          
            Permanent Address
            </label>
            <div className={`${isFocused=="permanent_address" ? 'add_emp_input_active' : 'add_emp_input'}`}>
              <input
                type="text"
                name="permanent_address"
                style={{paddingLeft:".75rem"}}
                className="form-control form-control-sm border-secondary"
                onFocus={(event)=>handleFocus(event)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.permanent_address}
                placeholder="Permanent Address"
              />
            </div>
          </div>
             
             
          <div  className="col-5">
            <label htmlFor="" className={`form-label fw-bold font-12 font-weight-500  ${isFocused=="emergency_contact" ? 'text-danger' : 'text-black'}`} style={{paddingLeft:".75rem",marginTop:"1rem"}}>
            Emergency Contact
            </label>
            <div className={`${isFocused=="emergency_contact" ? 'add_emp_input_active' : 'add_emp_input'}`}>
              <input
                type="number"
                name="emergency_contact"
                style={{paddingLeft:".75rem"}}
                className="form-control form-control-sm border-secondary"
                onFocus={(event)=>handleFocus(event)}
                onBlur={handleBlur}
                value={values?.emergency_contact}
                onChange={handleChange}
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
             
      
            </div>
            <div class="row pt-4 ">
              <div className="col-7"></div>
              <div
                className="col-5 ps-5 "
                style={{ marginLeft: "Auto", width: "auto" }}
              >
                <button
                  type="button"
                  class="btn btn-de-danger btn-sm border-danger"
                  onClick={() => handleClose()}
                  disabled={loader}
                >
                  Cancel
                </button>
                <button type="Submit" class="hr btn btn-primary mx-2" disabled={loader}>
                {loader ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
