import Modal from "react-bootstrap/Modal";

function TicketDescriptionModal({ show, setShow, description }) {

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">Ticket Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
    </Modal>
  );
}

export default TicketDescriptionModal;
