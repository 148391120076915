import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { loPoaSchema } from "../../../schemas/newProjectSchema";
import ScopeOfWork from "../AddForms/ScopeOfWork";

function PoLoaEditForm({ scopeOfWorkData, changeKey, activeAccordionKey }) {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const param = useParams();
  const project_id = param.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [otherTotal, setotherTotal] = useState("");
  const [totalValue, setTotalValue] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState({});
  const navigate = useNavigate();
  const [basicMultipleData, setBasicMultipleData] = useState([
    { basicPerUnit: "", basicTotal: "", basicTotalQuantity: "" },
  ]);

  const [lcb_otherMultipleData, setOtherMultipleData] = useState([
    { otherPerUnit: "", otherTotal: "", otherQuantity: "" },
  ]);
  const [lcb_installationMultipleData, setInstallationData] = useState([
    {
      lcb_installationPerUnit: "",
      lcb_installationTotal: "",
      lcb_installationQty: "",
    },
  ]);
  const [lcb_packagingMultipleData, setPackagingData] = useState([
    { lcb_packagingPerUnit: "", lcb_packagingTotal: "", lcb_packagingQty: "" },
  ]);
  const [lcb_freightMultipleData, setFreightData] = useState([
    { lcb_freightPerUnit: "", lcb_freightTotal: "", lcb_freightQty: "" },
  ]);
  const [valueAfterGst, setValueAfterGst] = useState(0);
  const initialValues = {
    lcb_basicMultipleData: basicMultipleData,
    lcb_installationMultipleData: lcb_installationMultipleData,
    lcb_packagingMultipleData: lcb_packagingMultipleData,
    lcb_freightMultipleData: lcb_freightMultipleData,
    lcb_otherMultipleData: lcb_otherMultipleData,
    lcb_installationPerUnit: "",
    lcb_installationTotal: "",
    lcb_installationQty: "",
    lcb_packagingPerUnit: "",
    lcb_packagingTotal: "",
    lcb_packagingQty: "",
    lcb_freightPerUnit: "",
    lcb_freightTotal: "",
    lcb_freightQty: "",
    lcb_gstValue: "",
    // lcb_gstUnit: "",
    // // lcb_gstQty: "",
    lcb_gstTotal: "",
    lcb_poloaValue: "",
    lcb_paymentTerms: "",
    lcb_contractSigningAuthority: "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: loPoaSchema,

    onSubmit: (values) => {
      updateLoPoaData(values);
    },
  });

  /////// Add and Remove Basic Inputs Start ///////
  const addBasicInputs = () => {
    setBasicMultipleData([
      ...basicMultipleData,
      { basicPerUnit: "", basicTotal: "", basicTotalQuantity: "" },
    ]);
    setValues({
      ...values,
      lcb_basicMultipleData: [
        ...values.lcb_basicMultipleData,
        {
          basicPerUnit: "",
          basicTotal: "",
          basicTotalQuantity: "",
        },
      ],
    });
  };

  const removeBasicInputs = (i) => {
    const deleteVal = [...basicMultipleData];

    deleteVal.splice(i, 1);
    setBasicMultipleData(deleteVal);

    setValues({ ...values, lcb_basicMultipleData: deleteVal });
  };

  /////// Add and Remove Basic Inputs Start ///////

  const handleInputChange = (event) => {
    const updatedValue = event.target.value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      handleChange(event);
    }
  };

  //////// Add , Remove and Handle Change for Other Multiple Inputs Start /////

  const addOtherInputs = () => {
    setOtherMultipleData([
      ...lcb_otherMultipleData,
      { otherPerUnit: "", otherTotal: "", otherQuantity: "" },
    ]);
    setValues({
      ...values,
      lcb_otherMultipleData: [
        ...values.lcb_otherMultipleData,
        { otherPerUnit: "", otherTotal: "", otherQuantity: "" },
      ],
    });
  };
  const removeOtherInputs = (i) => {
    const deleteVal = [...lcb_otherMultipleData];
    deleteVal.splice(i, 1);
    setOtherMultipleData(deleteVal);
    setValues({ ...values, lcb_otherMultipleData: deleteVal });
  };
  const handleOtherChange = (e, i) => {
    const { name, value } = e.target;
    const updatedValue = value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      const onchangeVal = [...lcb_otherMultipleData];
      onchangeVal[i][name] = value;
      setOtherMultipleData(onchangeVal);
      setFieldValue("lcb_otherMultipleData", onchangeVal);
    }
  };
  const addInstallationInput = () => {
    setInstallationData([
      ...lcb_installationMultipleData,
      {
        lcb_installationPerUnit: "",
        lcb_installationTotal: "",
        lcb_installationQty: "",
      },
    ]);
    setValues({
      ...values,
      lcb_installationMultipleData: [
        ...values.lcb_installationMultipleData,
        {
          lcb_installationPerUnit: "",
          lcb_installationTotal: "",
          lcb_installationQty: "",
        },
      ],
    });
  };
  const deleteInstallationInput = (i) => {
    const deleteVal = [...lcb_installationMultipleData];
    deleteVal.splice(i, 1);
    setInstallationData(deleteVal);
    setValues({ ...values, lcb_installationMultipleData: deleteVal });
  };
  const handleInstallationChange = (e, i) => {
    const { name, value } = e.target;
    const updatedValue = value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      const onchangeVal = [...lcb_installationMultipleData];
      onchangeVal[i][name] = value;
      setInstallationData(onchangeVal);
      setFieldValue("lcb_installationMultipleData", onchangeVal);
    }
  };
  const addPackagingInput = () => {
    setPackagingData([
      ...lcb_packagingMultipleData,
      {
        lcb_packagingPerUnit: "",
        lcb_packagingTotal: "",
        lcb_packagingQty: "",
      },
    ]);
    setValues({
      ...values,
      lcb_packagingMultipleData: [
        ...values.lcb_packagingMultipleData,
        {
          lcb_packagingPerUnit: "",
          lcb_packagingTotal: "",
          lcb_packagingQty: "",
        },
      ],
    });
  };
  const deletePackageingInput = (i) => {
    const deleteVal = [...lcb_packagingMultipleData];
    deleteVal.splice(i, 1);
    setPackagingData(deleteVal);
    setValues({ ...values, lcb_packagingMultipleData: deleteVal });
  };
  const handlePackagingChange = (e, i) => {
    const { name, value } = e.target;
    const updatedValue = value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      const onchangeVal = [...lcb_packagingMultipleData];
      onchangeVal[i][name] = value;
      setPackagingData(onchangeVal);
      setFieldValue("lcb_packagingMultipleData", onchangeVal);
    }
  };
  const addFreightInput = () => {
    setFreightData([
      ...lcb_freightMultipleData,
      { lcb_freightPerUnit: "", lcb_freightTotal: "", lcb_freightQty: "" },
    ]);
    setValues({
      ...values,
      lcb_freightMultipleData: [
        ...values.lcb_freightMultipleData,
        { lcb_freightPerUnit: "", lcb_freightTotal: "", lcb_freightQty: "" },
      ],
    });
  };
  const deleteFreightInput = (i) => {
    const deleteVal = [...lcb_freightMultipleData];
    deleteVal.splice(i, 1);
    setFreightData(deleteVal);
    setValues({ ...values, lcb_freightMultipleData: deleteVal });
  };
  const handleFreightChange = (e, i) => {
    const { name, value } = e.target;
    const updatedValue = value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      const onchangeVal = [...lcb_freightMultipleData];
      onchangeVal[i][name] = value;
      setFreightData(onchangeVal);
      setFieldValue("lcb_freightMultipleData", onchangeVal);
    }
  };

  //////// Add , Remove and Handle Change for Other Multiple Inputs End /////

  /////// Get Edit Data Start ///////
  function getLoPoaDetail() {
    let url = apiurl + "getuntiprojectsecond";
    let data = { user_id, project_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
    
        let basicData = await response?.data?.response?.projectSecond
          ?.lcb_basicMultipleData;
        setBasicMultipleData(basicData);
        if (response?.data?.response?.projectSecond?.lcb_otherMultipleData) {
          setOtherMultipleData(
            response?.data?.response?.projectSecond?.lcb_otherMultipleData
          );
        }
        if (
          response?.data?.response?.projectSecond?.lcb_installationMultipleData
        ) {
          setInstallationData(
            response?.data?.response?.projectSecond
              ?.lcb_installationMultipleData
          );
        }
        if (
          response?.data?.response?.projectSecond?.lcb_packagingMultipleData
        ) {
          setPackagingData(
            response?.data?.response?.projectSecond?.lcb_packagingMultipleData
          );
        }
        if (response?.data?.response?.projectSecond?.lcb_freightMultipleData) {
          setFreightData(
            response?.data?.response?.projectSecond?.lcb_freightMultipleData
          );
        }
        let data = await response?.data?.response?.projectSecond;
        // delete data.lcb_otherMultipleData;
        setValues({
          ...data,
          lcb_basicMultipleData: basicData ? basicData : basicMultipleData,
        });
      })
      .catch((error) => {
        navigate("/projects");
        toast.error("Something went wrong");
      });
  }

  /////// Get Edit Data End ///////

  ////// Update Data Start ///////

  function updateLoPoaData(value) {
    setSpinner(true);
    let url = apiurl + "storeuntiprojectsecond";
    let data = {
      ...value,
      user_id,
      project_id,
      // lcb_otherMultipleData: lcb_otherMultipleData,
      // lcb_installationMultipleData: lcb_installationMultipleData,
      // lcb_packagingMultipleData: lcb_packagingMultipleData,
      // lcb_freightMultipleData: lcb_freightMultipleData,
    };

    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        toast.success("Data Updated Successfully");
        getLoPoaDetail();
        if (!scopeOfWorkData.length) {
          changeKey("2");
        } else {
          navigate("/projects");
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
        setSpinner(false);
        toast.error("Something went wrong, Please try again");
      });
  }

  //// Update Data End ///////

  function sumOfAllTotals() {
    const otherDataSum = lcb_otherMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + +currentValue.otherTotal;
      },
      0
    );
    const basicDataSum = values.lcb_basicMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + +currentValue.basicTotal;
      },
      0
    );

    const installationDataSum = lcb_installationMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.lcb_installationTotal;
      },
      0
    );
    const FreightDataSum = lcb_freightMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.lcb_freightTotal;
      },
      0
    );
    const PackagingDataSum = lcb_packagingMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.lcb_packagingTotal;
      },
      0
    );
    const totalValue =
      otherDataSum +
      basicDataSum +
      installationDataSum +
      PackagingDataSum +
      FreightDataSum;

    const gst = (totalValue * values.lcb_gstValue) / 100;
    setTotalValue(totalValue);

    setValueAfterGst(gst);
    setFieldValue("lcb_gstTotal", gst);
  }

  function sumOfAllUnits() {
    const basicTotal = values.lcb_basicMultipleData?.reduce(
      (accumulator, currentValue) => {
        return accumulator + Number(currentValue.basicPerUnit);
      },
      0
    );

    const total =
      basicTotal +
      +values.lcb_installationPerUnit +
      +values.lcb_freightPerUnit +
      +values.lcb_packagingPerUnit;

    const gst = total * Number(values.lcb_gstValue / 100);

    setFieldValue("lcb_gstUnit", gst);
  }

  useEffect(() => {
    getLoPoaDetail();
  }, []);

  useEffect(() => {
    sumOfAllTotals();
  }, [
    lcb_otherMultipleData,
    basicMultipleData,
    values,
    lcb_installationMultipleData,
    lcb_freightMultipleData,
    lcb_packagingMultipleData,
    values.lcb_gstValue,
  ]);

  useEffect(() => {
    sumOfAllUnits();
  }, [
    values.lcb_basicMultipleData,
    values.lcb_installationPerUnit,
    values.lcb_freightPerUnit,
    values.lcb_packagingPerUnit,
    values.lcb_gstValue,
  ]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div>
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-2 align-self-center"></div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-3">
                      <h6 className="fw-bold text-secondary">Per Unit Price</h6>
                    </div>
                    <div className="col-md-3">
                      <h6 className="fw-bold text-secondary">Quantity </h6>
                    </div>
                    <div className="col-md-3">
                      <h6 className="fw-bold text-secondary"> Total</h6>
                    </div>
                  </div>
                </div>
              </div>

              {values.lcb_basicMultipleData.map((val, i) => (
                <div className="row mb-3" key={i}>
                  <div className="col-md-2 align-self-center">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label fw-bold font-16 mb-0 mb-0"
                      >
                        Basic{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="basicPerUnit"
                            placeholder="Per Unit"
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_basicMultipleData[${i}].basicPerUnit`,
                                true
                              );
                            }}
                            value={values.lcb_basicMultipleData[i].basicPerUnit}
                            onChange={(e) => {
                              if (/^\d*\.?\d*$/.test(e.target.value)) {
                                const newValues = [
                                  ...values.lcb_basicMultipleData,
                                ];
                                newValues[i].basicPerUnit = e.target.value;
                                setFieldValue(
                                  `lcb_basicMultipleData`,
                                  newValues
                                );
                              }
                            }}
                          />

                          <p className="text-danger m-0">
                            {touched.lcb_basicMultipleData &&
                            touched.lcb_basicMultipleData[i]?.basicPerUnit &&
                            errors?.lcb_basicMultipleData &&
                            errors?.lcb_basicMultipleData[i]?.basicPerUnit
                              ? errors?.lcb_basicMultipleData[i]?.basicPerUnit
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.basicPerUnit`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="basicTotalQuantity"
                            placeholder="Quantity"
                            value={
                              values.lcb_basicMultipleData[i].basicTotalQuantity
                            }
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_basicMultipleData[${i}].basicTotalQuantity`,
                                true
                              );
                            }}
                            onChange={(e) => {
                              if (/^\d*\.?\d*$/.test(e.target.value)) {
                                const newValues = [
                                  ...values.lcb_basicMultipleData,
                                ];
                                newValues[i].basicTotalQuantity =
                                  e.target.value;
                                setFieldValue(
                                  `lcb_basicMultipleData`,
                                  newValues
                                );
                              }
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_basicMultipleData &&
                            touched.lcb_basicMultipleData[i]
                              ?.basicTotalQuantity &&
                            errors?.lcb_basicMultipleData &&
                            errors?.lcb_basicMultipleData[i]?.basicTotalQuantity
                              ? errors?.lcb_basicMultipleData[i]
                                  ?.basicTotalQuantity
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.basicTotalQuantity`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="basicTotal"
                            name="basicTotal"
                            readOnly
                            placeholder="Total"
                            value={(val.basicTotal =
                              Number(val.basicPerUnit) *
                              Number(val.basicTotalQuantity)).toFixed(2)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          style={{ width: "40px" }}
                          className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2 "
                          onClick={() => addBasicInputs()}
                        >
                          +
                        </button>
                        {i != 0 ? (
                          <button
                            type="button"
                            style={{ width: "40px" }}
                            className="btn btn-de-danger d-inline-flex justify-content-center align-items-center btn-sm fs-3 p-2 border-danger"
                            onClick={() => removeBasicInputs(i)}
                            disabled={i == 0}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {lcb_installationMultipleData.map((val, i) => (
                <div className="row mb-3" key={i}>
                  <div className="col-md-2 align-self-center">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label fw-bold font-16 mb-0"
                      >
                        Installation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_installationPerUnit"
                            name="lcb_installationPerUnit"
                            placeholder="Per Unit"
                            value={val.lcb_installationPerUnit}
                            onChange={(e) => handleInstallationChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_installationMultipleData[${i}].lcb_installationPerUnit`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_installationMultipleData &&
                            touched.lcb_installationMultipleData[i]
                              ?.lcb_installationPerUnit &&
                            errors?.lcb_installationMultipleData &&
                            errors?.lcb_installationMultipleData[i]
                              ?.lcb_installationPerUnit
                              ? errors?.lcb_installationMultipleData[i]
                                  ?.lcb_installationPerUnit
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_installationPerUnit`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_installationQty"
                            name="lcb_installationQty"
                            placeholder="Quantity"
                            value={val.lcb_installationQty}
                            onChange={(e) => handleInstallationChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_installationMultipleData[${i}].lcb_installationQty`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_installationMultipleData &&
                            touched.lcb_installationMultipleData[i]
                              ?.lcb_installationQty &&
                            errors?.lcb_installationMultipleData &&
                            errors?.lcb_installationMultipleData[i]
                              ?.lcb_installationQty
                              ? errors?.lcb_installationMultipleData[i]
                                  ?.lcb_installationQty
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_installationQty`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_installationTotal"
                            name="lcb_installationTotal"
                            readOnly
                            placeholder=""
                            value={(val.lcb_installationTotal =
                              Number(val.lcb_installationPerUnit) *
                              Number(val.lcb_installationQty)).toFixed(2)}
                            onChange={(e) => handleInstallationChange(e, i)}
                          />

                          <p className="text-danger">
                            {validationError?.lcb_installationTotal}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          style={{ width: "40px" }}
                          className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2"
                          onClick={() => addInstallationInput()}
                        >
                          +
                        </button>
                        {i != 0 ? (
                          <button
                            type="button"
                            style={{ width: "40px" }}
                            className="btn btn-de-danger d-inline-flex justify-content-center align-items-center btn-sm fs-3 p-2 border-danger"
                            onClick={() => deleteInstallationInput(i)}
                            disabled={i == 0}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {lcb_packagingMultipleData.map((val, i) => (
                <div className="row mb-3" key={i}>
                  <div className="col-md-2 align-self-center">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label fw-bold font-16 mb-0"
                      >
                        Packaging{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_packagingPerUnit"
                            name="lcb_packagingPerUnit"
                            placeholder="Per Unit"
                            value={val.lcb_packagingPerUnit}
                            onChange={(e) => handlePackagingChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_packagingMultipleData[${i}].lcb_packagingPerUnit`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_packagingMultipleData &&
                            touched.lcb_packagingMultipleData[i]
                              ?.lcb_packagingPerUnit &&
                            errors?.lcb_packagingMultipleData &&
                            errors?.lcb_packagingMultipleData[i]
                              ?.lcb_packagingPerUnit
                              ? errors?.lcb_packagingMultipleData[i]
                                  ?.lcb_packagingPerUnit
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_packagingPerUnit`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id=""
                            name="lcb_packagingQty"
                            placeholder="Quantity"
                            value={val.lcb_packagingQty}
                            onChange={(e) => handlePackagingChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_packagingMultipleData[${i}].lcb_packagingQty`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_packagingMultipleData &&
                            touched.lcb_packagingMultipleData[i]
                              ?.lcb_packagingQty &&
                            errors?.lcb_packagingMultipleData &&
                            errors?.lcb_packagingMultipleData[i]
                              ?.lcb_packagingQty
                              ? errors?.lcb_packagingMultipleData[i]
                                  ?.lcb_packagingQty
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_packagingQty`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_packagingTotal"
                            name="lcb_packagingTotal"
                            placeholder=""
                            readOnly
                            value={(val.lcb_packagingTotal =
                              Number(val.lcb_packagingPerUnit) *
                              Number(val.lcb_packagingQty)).toFixed(2)}
                            onChange={(e) => handlePackagingChange(e, i)}
                          />

                          <p className="text-danger">
                            {validationError?.lcb_packagingTotal}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          style={{ width: "40px" }}
                          className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2"
                          onClick={() => addPackagingInput()}
                        >
                          +
                        </button>
                        {i != 0 ? (
                          <button
                            type="button"
                            style={{ width: "40px" }}
                            className="btn btn-de-danger d-inline-flex justify-content-center align-items-center btn-sm fs-3 p-2 border-danger"
                            onClick={() => deletePackageingInput(i)}
                            disabled={i == 0}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {lcb_freightMultipleData.map((val, i) => (
                <div className="row mb-3" key={i}>
                  <div className="col-md-2 align-self-center">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label fw-bold font-16 mb-0"
                      >
                        Freight{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_freightPerUnit"
                            name="lcb_freightPerUnit"
                            placeholder="Per Unit"
                            value={val.lcb_freightPerUnit}
                            onChange={(e) => handleFreightChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_freightMultipleData[${i}].lcb_freightPerUnit`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_freightMultipleData &&
                            touched.lcb_freightMultipleData[i]
                              ?.lcb_freightPerUnit &&
                            errors?.lcb_freightMultipleData &&
                            errors?.lcb_freightMultipleData[i]
                              ?.lcb_freightPerUnit
                              ? errors?.lcb_freightMultipleData[i]
                                  ?.lcb_freightPerUnit
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_freightPerUnit`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_freightQty"
                            name="lcb_freightQty"
                            placeholder="Quantity"
                            value={val.lcb_freightQty}
                            onChange={(e) => handleFreightChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_freightMultipleData[${i}].lcb_freightQty`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_freightMultipleData &&
                            touched.lcb_freightMultipleData[i]
                              ?.lcb_freightQty &&
                            errors?.lcb_freightMultipleData &&
                            errors?.lcb_freightMultipleData[i]?.lcb_freightQty
                              ? errors?.lcb_freightMultipleData[i]
                                  ?.lcb_freightQty
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.lcb_freightQty`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="lcb_freightTotal"
                            name="lcb_freightTotal"
                            placeholder="Total"
                            readOnly
                            value={(val.lcb_freightTotal =
                              Number(val.lcb_freightPerUnit) *
                              Number(val.lcb_freightQty)).toFixed(2)}
                            onChange={(e) => handleFreightChange(e, i)}
                          />

                          <p className="text-danger">
                            {validationError?.lcb_freightTotal}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="button"
                          style={{ width: "40px" }}
                          className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2"
                          onClick={() => addFreightInput()}
                        >
                          +
                        </button>
                        {i != 0 ? (
                          <button
                            type="button"
                            style={{ width: "40px" }}
                            className="btn btn-de-danger d-inline-flex justify-content-center align-items-center btn-sm fs-3 p-2 border-danger"
                            onClick={() => deleteFreightInput(i)}
                            disabled={i == 0}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {lcb_otherMultipleData?.map((val, i) => (
                <div className="row mb-3" key={i}>
                  <div className="col-md-2 align-self-center">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className="form-label fw-bold font-16 mb-0 mb-0"
                      >
                        Other
                      </label>
                    </div>
                  </div>

                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="otherPerUnit"
                            name="otherPerUnit"
                            placeholder="Per Unit"
                            value={val.otherPerUnit}
                            onChange={(e) => handleOtherChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_otherMultipleData[${i}].lcb_freightQty`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_otherMultipleData &&
                            touched.lcb_otherMultipleData[i]?.otherPerUnit &&
                            errors?.lcb_otherMultipleData &&
                            errors?.lcb_otherMultipleData[i]?.otherPerUnit
                              ? errors?.lcb_otherMultipleData[i]?.otherPerUnit
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.otherPerUnit`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id=""
                            name="otherQuantity"
                            placeholder="Quantity"
                            value={val.otherQuantity}
                            onChange={(e) => handleOtherChange(e, i)}
                            onBlur={(e) => {
                              setFieldTouched(
                                `lcb_otherMultipleData[${i}].lcb_freightQty`,
                                true
                              );
                            }}
                          />
                          <p className="text-danger m-0">
                            {touched.lcb_otherMultipleData &&
                            touched.lcb_otherMultipleData[i]?.otherQuantity &&
                            errors?.lcb_otherMultipleData &&
                            errors?.lcb_otherMultipleData[i]?.otherQuantity
                              ? errors?.lcb_otherMultipleData[i]?.otherQuantity
                              : Object.entries(validationError)
                                  ?.filter(([key, value]) =>
                                    key.includes(`${i}.otherQuantity`)
                                  )
                                  ?.map(([key, value]) => (
                                    <p className="m-0 text-danger" key={key}>
                                      {value[0]}
                                    </p>
                                  ))}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-secondary"
                            id="otherTotal"
                            name="otherTotal"
                            placeholder="Total"
                            readOnly
                            value={(val.otherTotal =
                              Number(val.otherPerUnit) *
                              Number(val.otherQuantity)).toFixed(2)}
                            onChange={(e) => handleOtherChange(e, i)}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <button
                          type="button"
                          style={{ width: "40px" }}
                          className="btn btn-primary d-inline-flex justify-content-center align-items-center btn-sm me-2 fs-3 p-2"
                          onClick={() => addOtherInputs()}
                        >
                          +
                        </button>
                        {i != 0 ? (
                          <button
                            type="button"
                            style={{ width: "40px" }}
                            className="btn btn-de-danger d-inline-flex justify-content-center align-items-center btn-sm fs-3 p-2 border-danger"
                            onClick={() => removeOtherInputs(i)}
                            disabled={i == 0}
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row mb-3">
                <div className="col-md-2 align-self-center">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className="form-label fw-bold font-16 mb-0 mb-0 d-flex align-items-center gap-1"
                    >
                      GST@
                      <input
                        className="form-control border-secondary"
                        type="text"
                        id="lcb_gstValue"
                        name="lcb_gstValue"
                        placeholder=""
                        value={values.lcb_gstValue}
                        onChange={handleInputChange}
                        style={{ width: "30%" }}
                      />
                      %
                    </label>
                    {touched.lcb_gstValue && errors.lcb_gstValue ? (
                      <p className="text-danger">{errors.lcb_gstValue}</p>
                    ) : (
                      <p className="text-danger">
                        {validationError?.lcb_gstValue}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="lcb_gstTotal"
                          name="lcb_gstTotal"
                          placeholder="Total"
                          readOnly
                          value={valueAfterGst}
                          onChange={handleInputChange}
                        />
                        {touched.lcb_gstTotal && errors.lcb_gstTotal ? (
                          <p className="text-danger">{errors.lcb_gstTotal}</p>
                        ) : (
                          <p className="text-danger">
                            {validationError.lcb_gstTotalerror}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-2 align-self-center">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className="form-label fw-bold font-16 mb-0 mb-0"
                    >
                      PO/LOA Value{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="lcb_poloaValue"
                          name="lcb_poloaValue"
                          readOnly
                          placeholder=""
                          value={(values.lcb_poloaValue =
                            Number(totalValue) +
                            Number(values.lcb_packagingTotal) +
                            Number(values.lcb_installationTotal) +
                            Number(values.lcb_gstTotal) +
                            Number(values.lcb_freightTotal)).toFixed(2)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-2 align-self-center">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className="form-label fw-bold font-16 mb-0 mb-0"
                    >
                      PAYMENT TERMS{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="lcb_paymentTerms"
                          name="lcb_paymentTerms"
                          placeholder="Payment Terms"
                          value={values.lcb_paymentTerms}
                          onChange={handleChange}
                        />
                        {touched.lcb_paymentTerms && errors.lcb_paymentTerms ? (
                          <p className="text-danger">
                            {errors.lcb_paymentTerms}
                          </p>
                        ) : (
                          <p className="text-danger">
                            {validationError?.lcb_paymentTerms}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-2 align-self-center">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className="form-label fw-bold font-16 mb-0 mb-0"
                    >
                      CONTRACT SIGNING AUTHORITY{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-secondary"
                          id="lcb_contractSigningAuthority"
                          name="lcb_contractSigningAuthority"
                          placeholder="Contract Signing Authority"
                          value={values.lcb_contractSigningAuthority}
                          onChange={handleChange}
                        />
                        {touched.lcb_contractSigningAuthority &&
                        errors.lcb_contractSigningAuthority ? (
                          <p className="text-danger">
                            {errors.lcb_contractSigningAuthority}
                          </p>
                        ) : (
                          <p className="text-danger">
                            {validationError?.lcb_contractSigningAuthority}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait a moment....</p>
                </div>
              ) : (
                <>
                  <button type="submit" className="btn btn-primary btn-sm me-3">
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-de-danger btn-sm border-danger"
                    onClick={() => navigate("/projects")}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default PoLoaEditForm;
