import * as Yup from "yup";
// export const addWarrantyTrackerSchema = Yup.object({
//   WarrantyRejectionAdviceNo: Yup.string().required("This field is required"),
//   WarrantyRejectionAdviceDate: Yup.string().required("This field is required"),
//   ToAttendAt: Yup.string().required("This field is required"),
//   DefectType: Yup.string().required("This field is required"),
//   Qty: Yup.string().required("This field is required"),
//   Status: Yup.string().required("This field is required"),
//   ClaimAmount_ToDeductFromBill: Yup.string().required("This field is required"),
//   LastDateToAttend: Yup.string().required("This field is required"),
//   ReportingRly: Yup.string().required("This field is required"),
//   DescOfDefect: Yup.string().required("This field is required"),
//   ConsigneeToContact:Yup.string().required("This field is required"),
//   ContactNo:Yup.string().required("This field is required"),

// });

const addwarrantyTrackerSchema = Yup.object({
  WarrantyRejectionAdviceNo: Yup.string().required("This field is required"),
  WarrantyRejectionAdviceDate: Yup.string().required("This field is required"),
  ToAttendAt: Yup.string().required("This field is required"),
  AttendAddress: Yup.string().required("This field is required"),
  // DefectType: Yup.string().required("This field is required"),
  Qty: Yup.string().required("This field is required"),
  Status: Yup.string().required("This field is required"),

  ClaimAmount_ToDeductFromBill: Yup.string().required("This field is required"),
  LastDateToAttend: Yup.string().required("This field is required"),

  ReportingRly: Yup.string().required("This field is required"),
  DescOfDefect: Yup.string().required("This field is required"),

  ConsigneeToContact: Yup.string().required("This field is required"),
  ContactNo: Yup.string().required("This field is required"),
  CoachNo_ProductSN: Yup.string().required("This field is required"),
  WcCloserCrnNo: Yup.string().when("Status", {
    is: "Pending",
    then: (schema) => schema.nullable().typeError("AssignedTo can be any type"),
    otherwise: (schema) => schema.required("This field is required"),
  }),
  WC_CloserDate_AsPerCRN: Yup.string().when("Status", {
    is: "Pending",
    then: (schema) => schema.nullable().typeError("AssignedTo can be any type"),
    otherwise: (schema) => schema.required("This field is required"),
  }),
  AssignedTo: Yup.string().when("Status", {
    is: "Pending",
    then: (schema) => schema.nullable().typeError("AssignedTo can be any type"),
    otherwise: (schema) => schema.required("This field is required"),
  }),
  // DeductionStatus: Yup.string().when("Status", {
  //   is: "Pending",
  //   then: (schema) => schema.nullable().typeError("AssignedTo can be any type"),
  //   otherwise: (schema) => schema.required("This field is required"),
  // }),
  // DeductionStatusDate: Yup.string().when("Status", {
  //   is: "Pending",
  //   then: (schema) => schema.nullable().typeError("AssignedTo can be any type"),
  //   otherwise: (schema) => schema.required("This field is required"),
  // }),
});
export default addwarrantyTrackerSchema;
