import React from "react";
import "../TenderStyle/TenderStyle.css";
import CommonTh from "../Common/CommonTh";
import CommonTd from "../Common/CommonTd";

function UpcomingTender({ tenderData }) {
  function formatDate(value) {
    if (value) {
      var date = new Date(value);
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }

    return formattedDate;
  }

  return (
    <div
      className="table-responsive table-container-scroll"
      style={{ overflowX: "auto" }}
    >
      <table className="table alert-table" style={{ tableLayout: "unset" }}>
        <thead className="thead-light">
          <tr style={{ borderTop: "1px solid black" }}>
            <CommonTh value="Railway Zone" width="10rem"></CommonTh>
            <CommonTh value="Tender No." width="10rem"></CommonTh>
            <CommonTh value="Tender Title" width="10rem"></CommonTh>
            <CommonTh value="Closing Date" width="10rem"></CommonTh>
            <CommonTh value="Closing Time" width="10rem"></CommonTh>
          </tr>
        </thead>
        <tbody style={{ maxHeight: "120px" }}>
          {tenderData?.map((tender, index) => {
            return (
              <tr key={index}>
                <CommonTd value={tender.railwayZone} width="10rem"></CommonTd>
                <CommonTd value={tender.tenderNo} width="10rem"></CommonTd>
                <CommonTd value={tender.tenderTitle} width="10rem"></CommonTd>
                <CommonTd
                  value={formatDate(tender.closingDate)}
                  width="10rem"
                  color="#f60000b0"
                ></CommonTd>
                <CommonTd value={tender.closingTime} width="10rem" ></CommonTd>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default UpcomingTender;
