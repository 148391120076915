import { useEffect, useRef, useState } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { RxCross2 } from "react-icons/rx";
import { FiEdit3 } from "react-icons/fi";
import {
  addKanbanBoardJourneyStages,
  addkanbanboardjourneysubstage,
  listkanbanboardjourney,
  updateKanbanStages,
  updateSubStages,
} from "./kanbanApi";
import KanbanModal from "./KanbanModal";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function KanbanCreateProject() {
  const [kanbanBoardModal, setkanbanBoardModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [stagesInJourney, setStagesInJourney] = useState({
    journey_id: "",
    stage_name: "",
  });
  const [dataForSubStages, setDataForSubStages] = useState({
    journey_id: "",
    stage_id: "",
  });
  const [subStageNameUpdate, setSubStageNameUpdate] = useState([]);
  const handleEditmodal = () => {
    setEditModal(false);
  };
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const [kanbanBoardJourney, setKanbanBoardList] = useState([]);
  const [subStageName, setSubStageName] = useState({
    stage_id: "",
    sub_stage_name: "",
    position: "",
  });
  const [subStagesFieldActive, setSubStagesFieldActive] = useState([]);
  const [stageNameUpdate, setStageNameUpdate] = useState(false);
  const [stageName, SetStageName] = useState({
    stage_name: "",
  });
  const location = useLocation();
  const { state } = location;
  const user_id = userProfile?.response?.userDetails?.id;

  function listKanbanJourney() {
    listkanbanboardjourney({ user_id, project_id: "19" })
      .then((res) => {
        setKanbanBoardList(res?.data?.response?.ProjectKanbanBoardList);
        console.log(res?.data?.response?.ProjectKanbanBoardList);
        setkanbanBoardModal(false);
        const updatedDataForSubStages =
          res?.data?.response?.ProjectKanbanBoardList?.filter(
            (element) =>
              Number(element?.journey_id) ===
              Number(dataForSubStages?.journey_id)
          );

        const data = updatedDataForSubStages[0]?.stages?.filter((element) => {
          return (
            Number(element.stage_id) === Number(dataForSubStages?.stage_id)
          );
        });
        setDataForSubStages(data[0]);
      })
      .catch((err) => console.log(err));
  }
  function handleSubStageChange(e) {
    const { value } = e.target;

    setSubStageName((prev) => ({
      ...prev,
      sub_stage_name: value,
      stage_id: dataForSubStages?.stage_id,
    }));
  }
  function handleUpdateSubStageChange(e, index) {
    const { value } = e.target;
    setSubStageNameUpdate((...prev) => {
      const updatedInput = [...prev];
      updatedInput[index] = value;
      return updatedInput;
    });
  }
  function updateSubStage(id, index) {
    updateSubStages({
      ...subStageName,
      kanban_journey_sub_stage_id: id,
      user_id,
      sub_stage_name: subStageNameUpdate[index],
    })
      .then((res) => {
        setDataForSubStages((prev) => {
          const updatedData = { ...prev };
          const getArrayCopy = [...updatedData.sub_stages];

          const updateObject = getArrayCopy[index];

          const updatedSubStage = {
            ...updateObject,
            sub_stage_name: subStageNameUpdate[index],
          };

          getArrayCopy[index] = updatedSubStage;

          return { ...updatedData, sub_stages: getArrayCopy };
        });

        setSubStagesFieldActive((prev) => {
          const updatedInput = [...prev];
          updatedInput[index] = !updatedInput[index];
          return updatedInput;
        });

        listKanbanJourney();
      })
      .catch((err) => console.log(err));
  }

  function handleChange(e, id) {
    const { value } = e.target;
    setStagesInJourney((prev) => ({
      ...prev,
      journey_id: id,
      stage_name: value,
    }));
  }
  function handleUpdateStage(e) {
    const { value } = e.target;

    SetStageName((prev) => ({
      ...prev,
      kanban_journey_stage_id: dataForSubStages?.stage_id,
      stage_name: value,
    }));
  }
  function addKanbanStages() {
    addKanbanBoardJourneyStages({ ...stagesInJourney, user_id })
      .then((res) => {
        listKanbanJourney();
        setStagesInJourney({ ...stagesInJourney, stage_name: "" });
      })
      .catch((err) => console.log(err));
  }
  function updateKanbanStagesApi() {
    updateKanbanStages({ ...stageName, user_id })
      .then((res) => {
        listKanbanJourney();
        setStageNameUpdate(false);
      })
      .catch((err) => {
        toast.error("Something went wrong while updating name");
      });
  }

  function addKanbanBoardSubStages() {
    addkanbanboardjourneysubstage({ ...subStageName, user_id })
      .then((res) => {
        setDataForSubStages((prev) => {
          const updatedData = { ...prev };
          const getArrayCopy = [...updatedData.sub_stages];
          getArrayCopy.push(subStageName);
          return { ...updatedData, sub_stages: getArrayCopy };
        });
        setSubStageName((prev) => {
          const updatedName = { ...prev, sub_stage_name: "" };
          return updatedName;
        });
        listKanbanJourney();
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    listKanbanJourney();
  }, [state]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="card mt-4">
              <div className="card-body">
                <div className="col-md-4">
                  <div className="p-3">
                    <h4>Eastern railway Project</h4>
                    <p>
                      Better access to customer information · Faster response
                      times · Improved on-time delivery · Improved order
                      accurac
                    </p>

                    {/* <div>
                      <input type="Search" className="form-control p-3" />
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <button
                    className="btn btn-danger text-end"
                    onClick={() => setkanbanBoardModal(true)}
                    //style={{ backgroundColor: "#9d9d9d" }}
                  >
                    Manage Kanban
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="card border"
            // style={{
            //   maxHeight: "30rem",
            //   overflowY: "scroll",
            // }}
          >
            <div className="card-body">
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  overflowX: "scroll",
                  paddingBottom: "30rem",
                  maxHeight: "35rem",
                  overflowY: "scroll",
                }}
              >
                {kanbanBoardJourney?.map((element, i) => (
                  <div className="col-md-2">
                    <div
                      style={{
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      }}
                      className="border"
                    >
                      <div
                        style={{
                          background: "black",
                          color: "white",
                          padding: "0.6rem",
                          borderTopLeftRadius: "0.4rem",
                          borderTopRightRadius: "0.4rem",
                        }}
                      >
                        {element?.journey_name}
                      </div>
                      {element?.stages?.map((stages, index) => (
                        <div className="mt-2 p-3 ps-3 pt-2 pb-0 edit_textarea">
                          <div
                            className="d-flex justify-content-between mb-2"
                            style={{
                              background: "#F7F7F7",
                              padding: "0.7rem 0.5rem 1rem 0.5rem",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                maxWidth: "90%",
                                minWidth: "90%",
                              }}
                            >
                              {stages?.stage_name}
                            </p>

                            <span
                              className="text-danger"
                              onClick={() => {
                                setDataForSubStages({
                                  ...stages,
                                  journey_name: element?.journey_name,
                                });
                                let arrayLength = stages.sub_stages.length;
                                let status = Array.from(
                                  { length: arrayLength },
                                  () => false
                                );
                                setSubStagesFieldActive(status);
                                let subStagesName = stages.sub_stages?.map(
                                  (element) => element.sub_stage_name
                                );
                                setSubStageNameUpdate(!subStagesName);
                                SetStageName({
                                  ...stageName,
                                  stage_name: stages?.stage_name,
                                });
                                setStageNameUpdate(false);
                                setEditModal(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <LuPencilLine />
                            </span>
                          </div>
                        </div>
                      ))}
                      <div className="mt-2 p-4 ps-3 pt-2 pb-0 edit_textarea">
                        <div className="d-flex justify-content-between mb-2">
                          <p
                            data-bs-toggle="collapse"
                            href={`#_${i}`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`_${i}`}
                          >
                            + Create
                          </p>
                        </div>
                        <div className="collapse" id={`_${i}`}>
                          <textarea
                            name="stage_name"
                            cols="30"
                            rows="4"
                            placeholder="Enter here"
                            className="form-control"
                            value={stagesInJourney?.stage_name}
                            onChange={(e) =>
                              handleChange(e, element.journey_id)
                            }
                          ></textarea>

                          <div className="mt-2 mb-2 p-2">
                            <button
                              type="submit"
                              style={{
                                background: "#D03438",
                                color: "white",
                                border: "1px solid #D03438",
                                borderRadius: "0.2rem",
                              }}
                              data-bs-toggle="collapse"
                              href={`#_${i}`}
                              aria-controls={`_${i}`}
                              onClick={addKanbanStages}
                            >
                              <IoCheckmarkSharp />
                            </button>
                            <button
                              className="ms-2"
                              style={{
                                background: "#E2E2E2",
                                color: "black",
                                border: "1px solid #E2E2E2",
                                borderRadius: "0.2rem",
                              }}
                              data-bs-toggle="collapse"
                              href={`#_${i}`}
                              aria-controls={`_${i}`}
                            >
                              <RxCross2 />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <Modal
                  show={editmodal}
                  onHide={handleEditmodal}
                  centered
                  backdrop="static"
                  className="modal-md"
                >
                  <div className="stage-bg">
                    {!stageNameUpdate ? (
                      <p
                        style={{
                          minWidth: "22rem",
                          minHeight: "2rem",
                          maxWidth: "22rem",
                          cursor: "pointer",
                        }}
                        className="mb-0"
                        onClick={() => {
                          setStageNameUpdate(!stageNameUpdate);
                        }}
                      >
                        {stageName?.stage_name}{" "}
                      </p>
                    ) : (
                      <input
                        type="text"
                        className="bg-transparent w-50"
                        style={{
                          border: "0",
                          borderBottom: "1px solid gray",
                        }}
                        onDoubleClick={updateKanbanStagesApi}
                        value={stageName?.stage_name}
                        name="stage_name"
                        onChange={handleUpdateStage}
                        //onFocus={handleFocus}
                        autoFocus={true}
                      />
                    )}

                    <button
                      data-bs-toggle="collapse"
                      href={`#addInput`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={`addInput`}
                    >
                      Add
                    </button>
                  </div>
                  <Modal.Body>
                    {dataForSubStages?.sub_stages?.length ? (
                      dataForSubStages?.sub_stages?.map((subStages, index) =>
                        !subStagesFieldActive[index] ? (
                          <div className="row mb-2">
                            <div className="col-md-10">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={subStages?.sub_stage_name}
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <FiEdit3
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setSubStagesFieldActive((prev) => {
                                      const updatedInput = [...prev];
                                      updatedInput[index] =
                                        !updatedInput[index];
                                      return updatedInput;
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row mb-2">
                            <div className="col-md-10">
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={subStageNameUpdate[index]}
                                  onChange={(e) =>
                                    handleUpdateSubStageChange(e, index)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="d-flex justify-content-center gap-2">
                                <button
                                  style={{
                                    background: "#D03438",
                                    color: "white",
                                    border: "1px solid #D03438",
                                    borderRadius: "0.2rem",
                                  }}
                                >
                                  <IoCheckmarkSharp
                                    onClick={() =>
                                      updateSubStage(
                                        subStages.sub_stage_id,
                                        index
                                      )
                                    }
                                  />
                                </button>
                                <button
                                  style={{
                                    background: "#E2E2E2",
                                    color: "black",
                                    border: "1px solid #E2E2E2",
                                    borderRadius: "0.2rem",
                                  }}
                                >
                                  <RxCross2
                                    onClick={() =>
                                      setSubStagesFieldActive((prev) => {
                                        const updatedInput = [...prev];
                                        updatedInput[index] =
                                          !updatedInput[index];
                                        return updatedInput;
                                      })
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <p style={{ color: "black" }}>
                        The stage does not contain any sub-stages. Please create
                        one by clicking on the Add Button
                      </p>
                    )}

                    <div className="row mb-2 collapse" id="addInput">
                      <div className="col-md-10">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            name="sub_stage_name"
                            value={subStageName?.sub_stage_name}
                            onChange={(e) => handleSubStageChange(e)}
                            placeholder="Enter Sub Stage"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="d-flex justify-content-center gap-2">
                          <button
                            style={{
                              background: "#D03438",
                              color: "white",
                              border: "1px solid #D03438",
                              borderRadius: "0.2rem",
                            }}
                            onClick={addKanbanBoardSubStages}
                          >
                            <IoCheckmarkSharp />
                          </button>
                          <button
                            style={{
                              background: "#E2E2E2",
                              color: "black",
                              border: "1px solid #E2E2E2",
                              borderRadius: "0.2rem",
                            }}
                            data-bs-toggle="collapse"
                            href={`#addInput`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`addInput`}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <div className="d-flex gap-3 justify-content-start mb-3 ps-4">
                    <Button variant="secondary" onClick={handleEditmodal}>
                      Cancel
                    </Button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KanbanModal
        kanbanBoardModal={kanbanBoardModal}
        setkanbanBoardModal={setkanbanBoardModal}
      />
    </div>
  );
}

export default KanbanCreateProject;
