import React, { useCallback, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  getItemMasterList,
  getUnitMasterList,
} from "../../Purchase/purchaseApi";
import { debounce } from "lodash";
import Select from "react-select";
import { errorsMessage } from "../../constant";

function ItemsDetail({
  itemsValue,
  handleItemChange,
  handleAddDetails,
  multipleItemDetail,
  setItemValues,
  handleDeleteItem,
  handleEditItem,
  itemIndex,
  itemsValidations,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [unitMasterList, setUnitMasterList] = useState([]);
  const [itemMasterList, setItemMasterList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };
  // Get Unit Data for Dropdown
  function unitData() {
    getUnitMasterList({ user_id })
      .then((res) => {
        setUnitMasterList(res?.data?.response?.UnitMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }
  // Get Item master Data for dropdown
  function itemMasterData(searchTerm) {
    setLoading(true);
    getItemMasterList({ user_id, search: searchTerm })
      .then((res) => {
        setItemMasterList(res?.data?.response?.ItemMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // debouncing for item master data

  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      itemMasterData(term);
    }, 800),
    [user_id]
  );
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);

  // unit data function call
  useEffect(() => {
    unitData();
  }, []);

  // Calculate amount based on qty and rate
  useEffect(() => {
    const qty = Number(itemsValue.qty);
    const rate = Number(itemsValue.rate);

    if (qty && rate) {
      const taxableAmount = qty * rate;
      setItemValues((prev) => ({
        ...prev,
        amount: taxableAmount,
      }));
    }
  }, [itemsValue.qty, itemsValue.rate]);

  // Calculate GST amount based on amount and gst_rate
  useEffect(() => {
    const amount = Number(itemsValue.amount);
    const gstRate = Number(itemsValue.gst_rate);
    if (amount && gstRate) {
      const gstAmount = (amount * gstRate) / 100;
      setItemValues((prev) => ({
        ...prev,
        gst_amount: gstAmount,
      }));
    }
  }, [itemsValue.amount, itemsValue.gst_rate]);

  // Calculate total amount based on amount and gst_amount
  useEffect(() => {
    const amount = Number(itemsValue.amount);
    const gstAmount = Number(itemsValue.gst_amount);

    if (amount && gstAmount) {
      const totalAmount = amount + gstAmount;
      setItemValues((prev) => ({
        ...prev,
        total_amount: totalAmount,
      }));
    }
  }, [itemsValue.amount, itemsValue.gst_amount]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Name{" "}
          </label>
          <Select
            placeholder="Select Item from Name"
            className="form-select-control border-dark"
            options={[
              { id: "", product_name: "Select an item" },
              ...itemMasterList,
            ]}
            getOptionLabel={(itemMasterList) => itemMasterList["product_name"]}
            isLoading={loading}
            getOptionValue={(itemMasterList) =>
              itemMasterList[("item_code", "id", "product_name")]
            }
            onChange={(value) => {
              setItemValues((prevState) => ({
                ...prevState,
                item_name: value.product_name,
                item_id: value.id,
                item_code: value.item_code,
                hsn_number: value.hsn_code || "",
              }));
            }}
            onInputChange={(value) => setSearchTerm(value)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "lightgrey",
                primary: "lightgrey",
              },
            })}
            styles={customStyles}
          />
          <p className="text-danger p-1 mx-1">{itemsValidations?.item_id}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            HSN Code
          </label>
          <input
            className="form-control"
            placeholder="Enter HSN code"
            name="hsn_number"
            value={itemsValue?.hsn_number}
            onChange={handleItemChange}
          />
          <p className="text-danger p-1 mx-1">{itemsValidations?.hsn_number}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Unit{" "}
          </label>
          <select
            id="dropdown"
            name="unit"
            value={itemsValue?.unit}
            onChange={handleItemChange}
            className="form-control"
          >
            <option value="">Select</option>
            {unitMasterList?.map((unit) => (
              <option value={unit?.id} key={unit?.id}>
                {unit?.unit_name}
              </option>
            ))}
          </select>
          <p className="text-danger p-1 mx-1">{itemsValidations?.unit}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Qty
          </label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Qty"
            name="qty"
            value={itemsValue?.qty}
            onChange={handleItemChange}
          />
          <p className="text-danger p-1 mx-1">{itemsValidations?.qty}</p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Rate
          </label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Rate"
            name="rate"
            value={itemsValue?.rate}
            onChange={handleItemChange}
          />
          <p className="text-danger p-1 mx-1">{itemsValidations?.rate}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Amount
          </label>
          <input
            className="form-control"
            placeholder="Amount"
            type="number"
            name="amount"
            value={itemsValue?.amount}
            onChange={handleItemChange}
          />
          <p className="text-danger p-1 mx-1">{itemsValidations?.amount}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST Type{" "}
          </label>
          <select
            type="number"
            name="gst_type"
            value={itemsValue?.gst_type}
            onChange={handleItemChange}
            className="form-control"
          >
            <option value="">Select</option>
            <option value="Inter State">Inter State</option>
            <option value="Intra State">Intra State</option>
          </select>
          <p className="text-danger p-1 mx-1">{itemsValidations?.gst_type}</p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST Rate (%){" "}
          </label>
          <select
            name="gst_rate"
            value={itemsValue?.gst_rate}
            onChange={handleItemChange}
            className="form-control"
          >
            <option value="">Select</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="28">28</option>
          </select>
          <p className="text-danger p-1 mx-1">{itemsValidations?.gst_rate}</p>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              GST Amount
            </label>
            <input
              className="form-control"
              placeholder="Enter Total Amount"
              type="number"
              name="gst_amount"
              value={itemsValue?.gst_amount}
              onChange={handleItemChange}
            />
            <p className="text-danger p-1 mx-1">
              {itemsValidations?.gst_amount}
            </p>
          </div>
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Total Amount
            </label>
            <input
              className="form-control"
              placeholder="Enter Total Amount"
              type="number"
              name="total_amount"
              value={itemsValue?.total_amount}
              onChange={handleItemChange}
            />
            <p className="text-danger p-1 mx-1">
              {itemsValidations?.total_amount}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <button
          className="btn btn-danger"
          type="button"
          onClick={() => handleAddDetails()}
        >
          Add Details
        </button>
      </div>

      <div className="col-md-12 mt-3">
        <div className="table-responsive table-container-scroll">
          <table
            style={{ borderCollapse: "collapse", border: "1px solid black" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">S.No.</h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "25rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Item Name</h6>
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Item Code</h6>
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">HSN Code </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Quantity </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Unit </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "6rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Rate </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "6rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Amount </h6>
                </th>
                {/* <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Total Amount </h6>
                </th> */}
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Gst Amount </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Total Amount </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Action </h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {multipleItemDetail?.map((element, i) => (
                <tr
                  key={i}
                  style={{ backgroundColor: itemIndex === i ? "#ffeded" : "" }}
                >
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element?.item_name}
                  </td>
                  {/* <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.item_id}
                  </td> */}
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element?.item_code}
                  </td>

                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element?.hsn_number}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element?.qty}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.unit}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.rate}
                  </td>

                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.amount}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.gst_amount}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.total_amount}
                  </td>

                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    <RiDeleteBinLine
                      className="text-danger font-20"
                      style={{ margin: "auto", cursor: "pointer" }}
                      onClick={() => handleDeleteItem(i)}
                    />
                    <FaEdit
                      className="text-danger font-20"
                      style={{
                        margin: "auto",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEditItem(i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ItemsDetail;
