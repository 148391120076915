import * as Yup from "yup";
export const VehicleRequestSchema = Yup.object({
    requested_date_for: Yup.date().required("This field is required"),
    project_name: Yup.string().required("This field is required"),
    po_no: Yup.string().required("This field is required"),
    transit_mode: Yup.string().required("This field is required"),
    material_type: Yup.string().required("This field is required"),
    required_vehicle_size: Yup.string().required("This field is required"),
    material_weight: Yup.string().required("This field is required"),
    loading_address: Yup.string().required("This field is required"),
    la_pin_code: Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    la_contact_name: Yup.string().required("This field is required"),
    la_contact_number: Yup.string()
    .required('This field is required')
    .matches(/^\d+$/, 'This field must contain only numbers')
    .length(10, 'This field must be 10 digits long'),
    delivery_address: Yup.string().required("This field is required"),
    da_pin_code: Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    da_contact_number:Yup.string()
    .required('This field is required')
    .matches(/^\d+$/, 'This field must contain only numbers')
    .length(10, 'This field must be 10 digits long'),
    requestee_signature: Yup.string().required("This field is required"),
    approvee_hod_signature: Yup.string().required("This field is required"),
    remarks: Yup.string().required("This field is required"),
  
  });



  export const AcceptRequestSchema = Yup.object({
    approved_date:Yup.date().required("This field is required"),
    transporter_name:Yup.string().required("This field is required"),
    mobile_number: Yup.string()
    .required('This field is required')
    .matches(/^\d+$/, 'This field must contain only numbers')
    .length(10, 'This field must be 10 digits long'),
    loading_date:Yup.date().required("This field is required"),
    delivery_date:Yup.date()
    .required('This field is required')
    .min(Yup.ref('loading_date'), 'Delivery date must not be before loading date'),
    delivery_time:Yup.string().required("This field is required"),
    vehicle_cost:Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    transit_mode:Yup.string().required("This field is required"),
    vehicle_cancellation_cost:Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    detention_charges:Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    extra_cost_over_hw:Yup.number().typeError('Only numbers are allowed').required('This field is required'),
    remarks:Yup.string().required("This field is required"),
    logistic_head_signature:Yup.string().required("This field is required"),
    approvee_hod_signature:Yup.string().required("This field is required"),


  })