import React from "react";
import { Navigate } from "react-router-dom";


function PurchasePrivateRoute({ children }) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const departmentName = user?.response?.userDetails?.departmentName;
  return departmentName === "Purchase" ? children : <Navigate to="/" />;
}

export default PurchasePrivateRoute;
