import React, { useState } from "react";

import "../../HrDepartment.scss"
// import Research1 from "../../Assets/research1.png";
import Research1 from "../../../Assets/research1.png"

import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "../../LeaveDashboard/Style.css";
import { Typography } from "@mui/material";
// import LinearWithValueLabel from "../../Components/ProgressBar";
import  LinearWithValueLabel from "../../../Components/ProgressBar"
// import TwoSimplePieChart from "../../Components/PieChart";
import TwoSimplePieChart from "../../../Components/PieChart"
import { LineChart } from "@mui/x-charts/LineChart";


import Ellipse from "../../../Assets/Ellipse.png"
import { GiConfirmed } from "react-icons/gi";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const LeaveDashboard = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <span className="dashboard">LEAVE</span>

            <Box sx={{ flexGrow: 1 }} className="mt-2">
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="gap_24 height_227px">
                        <div className="d-flex justify-content-between ">
                          <h5 className="emp_login_fontStyle_leave">
                            Leave Stats
                          </h5>
                          <select className="days_selector_style_leave">
                            <option>Last 30Days</option>
                            <option>Last 15Days</option>
                          </select>
                        </div>
                        <div className="employee_count">
                          {/* <div className=""> */}
                          <div className="total_employee width_180px margin_l_5">
                            <span className="fourty">2</span>
                            <div className="absolute_fix">
                              <span className="">Leaves Taken</span>
                            </div>
                          </div>
                          {/* </div> */}
                          {/* <div className=""> */}

                          <div className="registered width_180px">
                            <span className="fourty">12</span>
                            <div className="absolute_fix">
                              <span className="">Available Leaves</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="gap_24 height_227px">
                        <Typography className="requestHeading">
                          Requests
                        </Typography>

                        <div className="textOfpARRAt justifySpa">
                          <div className="m-auto d-flex g-17">
                            <div className="w_160px mr-2 marginRight_10px">
                              <button className="buttonFeedT w-100">
                                Request Leave
                              </button>
                            </div>
                            <div className="w_160px marginLeft_10px">
                              <button className="buttonFeedF w-100">
                                Request Comp Off
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <div className="gap_24 height_227px">
                    <Typography className="requestHeadings">
                      Leave Balance
                    </Typography>
                    <div className="mt-2 borderLeft_10">
                      <div className="mt-2">
                        <span className="leaveProgress pl-2Fix">
                          Sick Leave (6 days)
                        </span>
                        <LinearWithValueLabel />
                      </div>
                      <div className="mt-2">
                        <span className="leaveProgress pl-2Fix">
                          Sick Leave (12 days)
                        </span>
                        <LinearWithValueLabel />
                      </div>
                      <div className="mt-2">
                        <span className="leaveProgress pl-2Fix">
                          Sick Leave (10 days)
                        </span>
                        <LinearWithValueLabel />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mt-5">
                <Grid item xs={4}>
                  <div className="gap_24" style={{ height: "350px" }}>
                    <Typography className="requestHeadings">
                      Consumed Leave Types
                    </Typography>

                    <TwoSimplePieChart />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className="gap_24" style={{ height: "350px" }}>
                    <Typography className="requestHeadings">
                      Monthly Stats
                    </Typography>
                    <LineChart
                      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                      series={[
                        {
                          data: [2, 5.5, 2, 8.5, 1.5, 5],
                          area: true,
                        },
                      ]}
                      width={800}
                      height={300}
                    />
                  </div>
                </Grid>
              </Grid>
              <>
              
                    

                <div className="row mt-5">
                  <div class="table-responsive ms-0 me-0">
                    <div className="card mb-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="attendance-stats ps-3">Leave History</h4>
                        <select
                          className="days_selector_style mb-3"
                          style={{ padding: ".5rem 4rem .5rem 3rem" }}
                        >
                          <option>Last 30 Days </option>
                          <option>Last 15 Days </option>
                        </select>
                      </div>
                    </div>
                    <table class="table table-bordered ">
                      <thead>
                        <tr>
                          <th>Dates</th>
                          <th>Leave Type</th>
                          <th>Status</th>
                          <th>Action Taken On</th>
                          <th>Leave ote</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-bottom">
                          <td>06 June, Thu</td>
                          <td>10:02 AM</td>
                          <td>
                            <button className="chartButton">Approved </button>
                          </td>
                          <td>9 Hrs 8 Min</td>
                          <td>8 Hrs 45 Min</td>
                        </tr>

                        <tr className="border-bottom">
                          <td>06 June, Thu</td>
                          <td>10:02 AM</td>
                          <td>
                            <button className="chartButtonRejected">
                              Rejected{" "}
                            </button>
                          </td>
                          <td>9 Hrs 8 Min</td>
                          <td>8 Hrs 45 Min</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveDashboard;
