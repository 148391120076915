import React, { useEffect, useState } from "react";
import { errorsMessage } from "../../constant";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
const RequestDetail = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const location = useLocation();
  const { request_status, id } = location.state;
  const [requestDetail, setRequestDetail] = useState([]);
const[vehicleApprovedDetails,setVehicleApprovedDetails] = useState([])
function formatDate(value) {
  var date = new Date(value);
  if (value) {
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }
  return formattedDate;
}
function formatNumber(value) {
  if (!value) {
    return false;
  }
  return Number(value).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
}
function convertToAmPm(timeStr) {

  var timeComponents = timeStr?.split(':');
  var hours = parseInt(timeComponents?.[0]);
  var minutes = parseInt(timeComponents?.[1]);


  var meridiem = (hours >= 12) ? 'PM' : 'AM';


  hours = (hours > 12) ? hours - 12 : hours;
  hours = (hours === 0) ? 12 : hours;


  minutes = (minutes < 10) ? '0' + minutes : minutes;


  return hours + ':' + minutes + ' ' + meridiem;
}



  const getRequestDetail = () => {
    const url = `${baseUrl}listvehiclerequest`;
    const data = { user_id, request_status: request_status, id };
    axios
      .post(url, data, config)
      .then((res) => {
      
        setRequestDetail(res?.data?.response?.StoreVehicleRequestList);
        if(res?.data?.response?.StoreVehicleRequestList){
setVehicleApprovedDetails(res?.data?.response?.StoreVehicleRequestList?.[0]?.vehicle_approved_details
)
        }
      })
      .catch((err) => {
        errorsMessage("something went wrong")
     
      });
  };


  useEffect(() => {
  
      getRequestDetail();
  
 
 
  }, [request_status, id]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <h5
                      style={{
                        textAlign: "left",
                        marginBottom: "35px",
                        color: "#000000",
                      }}
                    >
                      REQUEST DETAILS
                    </h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4
                          style={{
                            textAlign: "left",
                            color: "#000000",
                            fontSize: "24px",
                          }}
                        >
                          {requestDetail?.[0]?.project?.pd_projectTitle}
                        </h4>
                        <h6 className="mb-4" style={{ textAlign: "left" }}>
                          PO : {requestDetail?.[0]?.po_no}
                        </h6>
                      </div>
                      <div>
                       {request_status!==1&& <Link
                          to={`/accept_vehicle_request/${requestDetail?.[0]?.id}`}
                        >
                          <button className="btn btn-danger">
                            Accept Request
                          </button>
                        </Link>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h5 className="request-table-heading text-start">
                        VEHICLE REQUEST
                      </h5>
                      <table
                        className="table common-table table-bordered"
                        style={{ border: "1px solid #B6B6B6" }}
                      >
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Transit Mode
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.transit_mode}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Vehicle Size
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.required_vehicle_size}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Material Type
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.material_type}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Material Weight
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.material_weight}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Loading Address
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.loading_address}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Contact Name
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.la_contact_name}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Contact Number
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.la_contact_number}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Delivery Address
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.delivery_address}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Contact Name
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.da_contact_name}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Contact Number
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.da_contact_number}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Remark
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            {requestDetail?.[0]?.remarks}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Signature (Request by Name)
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            <img
                              src={`https://dev-unti.fictivebox.tech/${requestDetail?.[0]?.requestee_signature}`}
                            />
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Signature (Approved by HOD or Project Leader)
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                            <img
                              src={`https://dev-unti.fictivebox.tech/${requestDetail?.[0]?.approvee_hod_signature}`}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>

                   {request_status == 1&&  <div className="col-md-6">
                      <h5 className="request-table-heading text-start">
                        ACCEPTED REQUEST
                      </h5>
                      <table
                        className="table common-table"
                        style={{ border: "1px solid #B6B6B6" }}
                      >
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Transporter Name
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            {vehicleApprovedDetails?.transporter_name}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Mobile Number
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                           {vehicleApprovedDetails?.mobile_number}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Loading Date
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                             {formatDate(vehicleApprovedDetails?.loading_date)}
                          </td>
                        </tr>
                        {/* <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Vehicle Arriving Time
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            10:26 AM
                          </td>
                        </tr> */}
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Transit Mode
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                          {vehicleApprovedDetails?.transit_mode}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Delivery Date
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            {formatDate(vehicleApprovedDetails?.delivery_date)}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Delivery Time
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            {convertToAmPm(vehicleApprovedDetails?.delivery_time)}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Vehicle Cost
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                         {formatNumber(vehicleApprovedDetails?.vehicle_cost)}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Detention Charges
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                          {formatNumber(vehicleApprovedDetails?.detention_charges)}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Extra Cost
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            {formatNumber(vehicleApprovedDetails?.extra_cost_over_hw)}
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                            Remark
                          </th>
                          <td
                            style={{
                              borderRight: "1px solid #B6B6B6",
                              borderBottom: "1px solid #B6B6B6",
                            }}
                          >
                             {vehicleApprovedDetails?.remarks}
                          </td>
                        </tr>
                        <tr
                          className="request-table-row"
                          style={{ borderBottom: "1px solid #B6B6B6" }}
                        >
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Signature (Logistics Head)
                          </th>
                          <td style={{ borderBottom: "1px solid #B6B6B6" }}>
                          <img
                              src={`https://dev-unti.fictivebox.tech/${vehicleApprovedDetails?.logistic_head_signature}`}
                            />
                          </td>
                        </tr>
                        <tr className="request-table-row">
                          <th style={{ borderRight: "1px solid #B6B6B6" }}>
                            Signature (Approved by HOD or Project Leader)
                          </th>
                          <td style={{ borderRight: "1px solid #B6B6B6" }}>
                          <img
                              src={`https://dev-unti.fictivebox.tech/${vehicleApprovedDetails?.approvee_hod_signature}`}
                            />
                          </td>
                        </tr>
                      </table>
                    </div> }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDetail;
