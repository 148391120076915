import React from "react";
import ".././Pages/BillingDetailPdf.css";

function BillingDetailPdf({ billingDetail }) {
  return (
    <div>
      <div
        style={{
          background: "#f4f4f4;",
          padding: "0.7rem",
          border: "2px solid #e3e3e3",
          fontWeight: "500",
        }}
      >
        {" "}
        <h5
          className="text-start"
          style={{ color: "#000444", letterSpacing: ".1px", lineHeight: 1.5 }}
        >
          BILLING & PAYMENT DETAILS
        </h5>
      </div>
      <table className="rwd-table">
        <thead></thead>
        <tbody>
          <tr>
            <th>Invoice No.</th>
            <th>Date</th>
            <th>Qty</th>
            <th>Invoice Amount</th>
            <th>Payment Status</th>
            <th>Date</th>
            <th>Amount Received</th>
            <th>Total Deduction</th>
            <th>Payment Due</th>
          </tr>
          {billingDetail?.map((element, i) => {
            return (
              <tr key={i}>
                <td data-th="Supplier Code">{element?.bpd_invoiceNo}</td>
                <td data-th="Supplier Name">{element?.bpd_invoiceDate}</td>
                <td data-th="Invoice Number">{element?.bpd_invoiceQuantity}</td>
                <td data-th="Invoice Date">{element?.bpd_invoiceAmount}</td>
                <td data-th="Due Date">{element?.bpd_poloaCompletionStatus}</td>
                <td data-th="Net Amount">---</td>
                <td data-th="Due Date">---</td>
                <td data-th="Net Amount">{element?.bpd_totalDeduction}</td>
                <td data-th="Net Amount">{element?.bpd_paymentDue}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default BillingDetailPdf;
