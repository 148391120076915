import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../organisationStructure/OrgStucture.css";
import LegalEntitiesParent from "./OrganizationLegalEntity/LegalEntitiesParent";
import BusinessUnitParent from "./OrganizationBusinessUnit/BusinessUnitParent";
import DepartmentParent from "./OrganizationDepartment/DepartmentParent";
import LocationParent from "./OrganizationLocation/LocationParent";

export default function OrgStructureTabs() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };

  return (
    <section className="organisation_Structure_Tab">
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              boxShadow: "0px 0px 49.15px 0px rgba(0, 0, 0, 0.05)",
              display: "flex",
              justifyContent: "space-between",
              width: "60%",
            }}
          >
            <Tab
              label="Legal Entity"
              value="1"
              sx={{ flex: 1, textAlign: "center" }}
            />
            <Tab
              label="Business Unit"
              value="2"
              sx={{ flex: 1, textAlign: "center" }}
            />
            <Tab
              label="Department"
              value="3"
              sx={{ flex: 1, textAlign: "center" }}
            />
            <Tab
              label="Location"
              value="4"
              sx={{ flex: 1, textAlign: "center" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          {" "}
          <LegalEntitiesParent />
        </TabPanel>
        <TabPanel value="2">
          <BusinessUnitParent />
        </TabPanel>
        <TabPanel value="3">
          <DepartmentParent />
        </TabPanel>
        <TabPanel value="4">
        <LocationParent/>
        </TabPanel>
      </TabContext>
    </Box>
    </section>
  );
}
