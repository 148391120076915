import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Users from "./UsersAndAdmin/Users";
import Admins from "./UsersAndAdmin/Admins";
import Modal from "react-bootstrap/Modal";

function ProjectsUsers() {
  //Get User List Start
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [show, setShow] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const navigate = useNavigate();
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [search, setSearch] = useState("");
  const [key, setKey] = useState("users");

  function handleClose() {
    setShow(false);
  }

  function handleDeleteUser() {
    const url = apiurl + "setdeleteuser";
    const body = {
      user_id: user_id,
      duser_id: deletingUserId,
      accessType: "delete",
    };
    axios
      .post(url, body, config)
      .then((res) => {
        if (res.status === 200) {
          toast.success("User Deleted Successfully");
          handleClose();
          setSearch("");
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
      });
    setDeletingUserId(null);
  }

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-end"></div>
            </div>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              style={{ fontSize: "1.2rem", fontWeight: 600 }}
            >
              <Tab eventKey="users" title="USERS">
                <Users search={search} keys={key} />
              </Tab>
              <Tab eventKey="admin" title="ADMINS">
                <Admins keys={key} search={search} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <Footer />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button className="btn btn-success me-3" onClick={handleDeleteUser}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={handleClose}>
            No
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectsUsers;
