// import * as React from "react";
// import Stack from "@mui/material/Stack";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Radio from "@mui/material/Radio";
// import { BarChart } from "@mui/x-charts/BarChart";
// import { axisClasses } from "@mui/x-charts/ChartsAxis";

// function TickParamsSelector({
//   tickPlacement,
//   tickLabelPlacement,
//   setTickPlacement,
//   setTickLabelPlacement,
// }) {
//   return (
//     <Stack
//     //   direction="column"
//     //   justifyContent="space-between"
//     //   sx={{ width: "100%" }}
//     >
//       {/* <FormControl>
//         <FormLabel id="tick-placement-radio-buttons-group-label">
//           tickPlacement
//         </FormLabel>
//         <RadioGroup
//           row
//           aria-labelledby="tick-placement-radio-buttons-group-label"
//           name="tick-placement"
//           value={tickPlacement}
//           onChange={(event) => setTickPlacement(event.target.value)}
//         >
//           <FormControlLabel value="start" control={<Radio />} label="start" />
//           <FormControlLabel value="end" control={<Radio />} label="end" />
//           <FormControlLabel value="middle" control={<Radio />} label="middle" />
//           <FormControlLabel
//             value="extremities"
//             control={<Radio />}
//             label="extremities"
//           />
//         </RadioGroup>
//       </FormControl>
//       <FormControl>
//         <FormLabel id="label-placement-radio-buttons-group-label">
//           tickLabelPlacement
//         </FormLabel>
//         <RadioGroup
//           row
//           aria-labelledby="label-placement-radio-buttons-group-label"
//           name="label-placement"
//           value={tickLabelPlacement}
//           onChange={(event) => setTickLabelPlacement(event.target.value)}
//         >
//           <FormControlLabel value="tick" control={<Radio />} label="tick" />
//           <FormControlLabel value="middle" control={<Radio />} label="middle" />
//         </RadioGroup>
//       </FormControl> */}
//     </Stack>
//   );
// }

// const dataset = [
//   {
//     london: 59,
//     paris: 57,
//     newYork: 86,
//     seoul: 21,
//     month: "Jan",
//   },
//   {
//     london: 50,
//     paris: 52,
//     newYork: 78,
//     seoul: 28,
//     month: "Feb",
//   },
//   {
//     london: 47,
//     paris: 53,
//     newYork: 106,
//     seoul: 41,
//     month: "Mar",
//   },
//   {
//     london: 54,
//     paris: 56,
//     newYork: 92,
//     seoul: 73,
//     month: "Apr",
//   },
//   {
//     london: 57,
//     paris: 69,
//     newYork: 92,
//     seoul: 99,
//     month: "May",
//   },
//   {
//     london: 60,
//     paris: 63,
//     newYork: 103,
//     seoul: 144,
//     month: "June",
//   },
//   {
//     london: 59,
//     paris: 60,
//     newYork: 105,
//     seoul: 319,
//     month: "July",
//   },
//   {
//     london: 65,
//     paris: 60,
//     newYork: 106,
//     seoul: 249,
//     month: "Aug",
//   },
//   {
//     london: 51,
//     paris: 51,
//     newYork: 95,
//     seoul: 131,
//     month: "Sept",
//   },
//   {
//     london: 60,
//     paris: 65,
//     newYork: 97,
//     seoul: 55,
//     month: "Oct",
//   },
//   {
//     london: 67,
//     paris: 64,
//     newYork: 76,
//     seoul: 48,
//     month: "Nov",
//   },
//   {
//     london: 61,
//     paris: 70,
//     newYork: 103,
//     seoul: 25,
//     month: "Dec",
//   },
// ];
// const valueFormatter = (value) => `${value}cr`;

// const chartSetting = {
//   yAxis: [
//     {
//       label: "In(Crore)",
//     },
//   ],
//   series: [{ dataKey: "seoul", label: "Sales Overview", valueFormatter }],
//   height: 340,
//   sx: {
//     [`& .${axisClasses.directionY} .${axisClasses.label} `]: {
//       transform: "translateX(-10px)",

//     },
//   },
// };

// export default function TickPlacementBars() {
//   const [tickPlacement, setTickPlacement] = React.useState("middle");
//   const [tickLabelPlacement, setTickLabelPlacement] = React.useState("middle");
//   const barColors = ["#0095ff", "#00ff00", "#0000ff", "#ffff00", "#ff00ff"];
//   return (
//     <div style={{ width: "100%" }}>
//       <TickParamsSelector
//         tickPlacement={tickPlacement}
//         tickLabelPlacement={tickLabelPlacement}
//         setTickPlacement={setTickPlacement}
//         setTickLabelPlacement={setTickLabelPlacement}
//       />
//       <BarChart
//         dataset={dataset}
//         xAxis={[
//           {
//             scaleType: "band",
//             dataKey: "month",
//             tickPlacement,
//             tickLabelPlacement,
//           },
//         ]}
//         {...chartSetting}
//         colors={barColors}
//       />
//     </div>
//   );
// }

import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  tickFormatter,
  Tooltip,
  Legend,
} from "recharts";
const DashoardBarGraph = ({ monthOrderCount }) => {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const data = [
    { year: 2024, month: 1, count: 0 },
    { year: 2024, month: 3, count: 4550 },
    { year: 2024, month: 2, count: 400 },
    { year: 2024, month: 5, count: 7526 },
  ];

  let sortedData;
  let maxCount;
  if (monthOrderCount?.length) {
    sortedData = [...monthOrderCount].sort((a, b) => a.month - b.month);
    maxCount = Math.max(...sortedData.map((item) => item.count));
  }

  return (
    <div style={{ width: "100%" }}>
      <BarChart width={600} height={400} data={sortedData}>
        <Bar dataKey="count" fill="rgb(0, 149, 255)" />

        <Tooltip 
          formatter={(values, name, props) => {
            console.log(props)
            const { payload } = props;
            if(payload){const formatPayload = {
              ...payload,
              month: monthName[payload?.month - 1],
            };

            return [
              `Count: ${formatPayload.count}, Month: ${formatPayload.month}`,
            ];}else {return[]}
            
          }}
        />

        <XAxis
          dataKey="month"
          tickFormatter={(values) => monthName[values - 1]}
        />
        <YAxis domain={[0, maxCount]} />
        {/* <Legend /> */}
      </BarChart>
    </div>
  );
};

export default DashoardBarGraph;
