import React from "react";
import CommonTable from "../Common/CommonTable";

function AddParameterList() {
  const tableHeading = [
    {
      name: "SubID",
      width: "",
    },
    {
      name: "Sub Classification Name",
      width: "",
    },
    {
      name: "Prefix Type",
      width: "",
    },
    {
      name: "Classification Name",
      width: "",
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-6">
              <h4 className="page-title">ADD PARAMETER</h4>
            </div>
            <div className="col-sm-6 text-end">
              <button className="btn btn-danger">Add New</button>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <CommonTable tableHeading={tableHeading}></CommonTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddParameterList;
