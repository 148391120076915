import React,{useState} from 'react'
import CommonTableExits from '../../MaterialUiComponent/CommonTableExits'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitSearchBar from './ExitSearchBar';



const PendingApprovals = ({tabName}) => {
   
    const columns = [
        { id: 'Employee', label: 'Employee', minWidth:"10rem" },
        { id: 'Exit type& Resign date', label: 'Exit type& Resign Date', minWidth: 200 },
        { id: 'Notice Date', label: 'Notice Date', minWidth: 150 },
        { id: 'Last Day', label: 'Last Day', minWidth: 150 },
        { id: 'Reporting Manager', label: 'Reporting Manager', minWidth: 180, format: (value) => new Date(value).toLocaleDateString('en-US') },
        { id: 'Location', label: 'Location', minWidth: 150 },
        { id: 'Comment', label: 'Comment', minWidth: 150 },
        { id: 'actions', label: 'Action', minWidth: 150 },
      ];
      
      // Sample data
      const rows = [
        { Employee: 'John Doe', "Exit type& Resign date": 'Software Engineer', "Notice Date": '2024-12-9', "Last Day": '2024-12-16', "Reporting Manager": 'Onkar', Location: 'noida',Comment:"--" },
       
        // Add more sample data here
      ];
      const departmentChange =()=>{
        console.log(tabName)
      }

  return (
 <section className="pending-approval">
{/* <div className='bg-white mx-3'>
<div className='d-flex px-3 justify-content-between align-items-center'>
<h5 className='fw-bold'>Pending Approvals</h5>
<div className='d-flex  justify-content-between align-items-center' >
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">ExitType</InputLabel>
        <Select
        size='small'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={exitType}
          label="ExitType"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Department</InputLabel>
        <Select
        size='small'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={department}
          label="Department"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>


<div className="d-flex align-items-center">
      <input className='days_selector_style mb-lg-2 mb-3 mr-lg-3' type="text" placeholder="Search.." />
      <button className="search_Button" type="submit"><i className="fa fa-search"></i></button>
    </div>


</div>

</div>

<CommonTableExits columns={columns} rows={rows} actionfield={<MoreVertIcon/>}/>
</div> */}
<div className='bg-white mx-3'>
<ExitSearchBar tabName={tabName} departmentChange={departmentChange}/>
<CommonTableExits columns={columns} rows={rows} actionfield={<p  style={{color:"#4667b6"}}>Take Action</p>}/>
</div>
 </section>
  )
}

export default PendingApprovals
