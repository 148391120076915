import React, { useEffect, useState } from "react";
import TenderTable from "../TenderComponents/TenderTable";
import axios from "axios";
import { toast } from "react-toastify";
function EmdTrashBin() {
  const [tenderDataFetch, setTenderDataFetch] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  async function getEmdTrashData() {
    await axios
      .post(
        baseUrl + "getunapprovedTenders",
        {
          user_id,
          //   search: null,
          //   startDate: null,
          //   endDate: null,
          //   contractType: null,
          //   biddingStatus: null,
        },
        config
      )
      .then((res) => {
        if (res.data.response.isSuccess) {
            setTenderDataFetch(res.data.response.tendersList);
          //  setSearchData( res.data.response.TendersList);
          //   setLoading(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toast.error("Something went wrong");
      });
  }
  useEffect(() => {
    getEmdTrashData();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid"></div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <h4 className="text-dark f-18 my-3 ">EMD Trash Bin</h4>
                <TenderTable searchData={tenderDataFetch}></TenderTable>;
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmdTrashBin;
