import React, { useState, useEffect } from "react";
import InProbation from "./InProbation";
import ProbationPolicy from "./ProbationPolicy";
import AddProbationPolicyModal from "./AddProbationModal";

import { useDispatch, useSelector } from "react-redux";
import { getProbationPolicy, fetchProbationPolicy } from "../../../redux/features/Hr/ProbationPolicySlice";
import { selectLoading } from "../../../redux/features/Hr/LoaderSlice";
const ProbationMain = () => {
  const [activeTab, setActiveTab] = useState("InProbation");
  const [addProbationModal, setAddProbationModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const probationPolicyList = useSelector(getProbationPolicy)
  const [selectedProbationPolicy, setSelectedProbationPolicy] = useState();
 const loader = useSelector(selectLoading)

const dispatch = useDispatch()
  useEffect(() => {
  dispatch(fetchProbationPolicy(dispatch))
  }, []);
  const handleClose = () => {
    setAddProbationModal(false);
  };
  const openModal = () => {
    setEditData(null);
    setAddProbationModal(true);
  };
  const getEditData = (policy) => {
    setEditData(policy);
    setAddProbationModal(true);
  };
  useEffect(()=>{
    setSelectedProbationPolicy(probationPolicyList?.[0]);
  },[probationPolicyList])

  return (
    <>
      <div className="ms-3 mt-4 mb-3 d-flex justify-content-between">
        <div>
          <button
            className={`hr ${
              activeTab === "InProbation" ? " btn btn-primary probation-active-class me-3" : " me-3  btn btn-outline-secondary text-black"
            }`}
            onClick={() => setActiveTab("InProbation")}
          >
            {" "}
            In Probation
          </button>
          <button
            className={`hr ${
              activeTab === "Probation Policy" ? " btn btn-primary probation-active-class" : "   btn btn-outline-secondary text-black"
            }`}
            onClick={() => setActiveTab("Probation Policy")}
          >
            {" "}
            Probation Policies
          </button>
        </div>
        {activeTab == "Probation Policy" && (
          <div className="me-3">
            <button
              className="red-button btn btn-primary"
              onClick={() => openModal()}
            >
              {" "}
              Add Policy
            </button>
          </div>
        )}
      </div>

      {activeTab == "Probation Policy" ? (
        <ProbationPolicy
          probationPolicyList={probationPolicyList}
          selectedProbationPolicy={selectedProbationPolicy}
          setSelectedProbationPolicy={setSelectedProbationPolicy}
          getEditData={getEditData}
          getProbationPolicy={getProbationPolicy}
          loader={loader}
        />
      ) : (
        <InProbation />
      )}
      <AddProbationPolicyModal
        open={addProbationModal}
        handleClose={handleClose}
        editData={editData}
        getProbationPolicy={getProbationPolicy}
      />
    </>
  );
};

export default ProbationMain;
