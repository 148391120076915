import React from "react";
import styles from "./TableComponent.module.css"; // Import the CSS module
import { formatDate, formatNumber } from "../../../constant";

const LogsTableOnHover = ({ projectData, type }) => {
  return (
    <div className={styles.container}>
      <div className={styles.hoverTarget}>
        View Logs
        <div className={styles.tooltip}>
          <table className={styles.customTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Previous Value</th>
                <th>Updated Value</th>
              </tr>
            </thead>
            <tbody>
              {projectData?.map((row, index) => (
                <tr key={index}>
                  <td>{formatDate(row.created_at)}</td>
                  <td>
                    {type === "value"
                      ? formatNumber(row?.pd_totalOrderValue_Before)
                      : formatNumber(row?.pd_totalOrderQty_Before)}
                  </td>
                  <td>
                    {type === "value"
                      ? formatNumber(row?.pd_totalOrderValue_After)
                      : formatNumber(row?.pd_totalOrderQty_After)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LogsTableOnHover;
