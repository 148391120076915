import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { fetchDropdown } from "../../../../redux/features/allDropdownSlice";
import { useDispatch } from "react-redux";

function WarrantyTracker({
  values,
  handleChange,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  validationError,
  projectDropdown,
  allDropdowns,
  spinner,
  mode,
  setFieldValue,
  getAttachmentBase64,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [defectType, setDefectType] = useState([]);
  const [projectLotCoachList, setProjectLotCoachList] = useState([]);
  const navigate = useNavigate();
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const railwayZoneList = allDropdowns.railWayZoneList;
  const dispatch = useDispatch();
  function getDefectlist() {
    const url = baseUrl + "getdefecttypelist";
    let data = { user_id, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          // setData( response?.data?.response?.
          //   DefectTypeList);
          setDefectType(response?.data?.response?.DefectTypeList);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }
  function getCoachList() {
    const url = baseUrl + "getcoachlist";
    let data = { user_id, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        setProjectLotCoachList(response?.data?.response?.coachList);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }
  function fetchDropdownApi() {
    dispatch(
      fetchDropdown({
        user_id,
        config,
      })
    );
  }

  const [selectedValue, setSelectedValue] = useState(null);
  const [railwayRlyelectedValue, setRailwayRlySelectedValue] = useState(null);
  const [toAttendAtselectedValue, setToAttendAtselectedValue] = useState(null);
  console.log(allDropdowns.railWayZoneList);

  useEffect(() => {
    if (values.CoachNo_ProductSN) {
      const initialOption = projectLotCoachList.find(
        (option) => +option.id === +values.CoachNo_ProductSN
      );
      setSelectedValue(initialOption);
    }
    if (values.ReportingRly) {
      const initialOption = allDropdowns.railWayZoneList.find(
        (option) => +option.id === +values.ReportingRly
      );
      setRailwayRlySelectedValue(initialOption);
    }
    if (values.ToAttendAt) {
      const initialOption = allDropdowns.railWayZoneList.find(
        (option) => +option.id === +values.ToAttendAt
      );
      setToAttendAtselectedValue(initialOption);
    }
  }, [
    values.CoachNo_ProductSN,
    projectLotCoachList,
    values.ToAttendAt,
    values.ReportingRly,
    allDropdowns.railWayZoneList,
  ]);

  useEffect(() => {
    getDefectlist();
    getCoachList();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>{mode == "add" ? "Add" : "Edit"} Warranty</h4>
                  <form className="department-form">
                    <div className="row">
                      {/* <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Rly
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter S.No."
                            name="Rly"
                            value={values.Rly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.Rly && errors.Rly
                              ? errors.Rly
                              : validationError?.Rly}
                          </p>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            WR Advice No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter WR Advice No."
                            name="WarrantyRejectionAdviceNo"
                            value={values.WarrantyRejectionAdviceNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.WarrantyRejectionAdviceNo &&
                              errors.WarrantyRejectionAdviceNo
                              ? errors.WarrantyRejectionAdviceNo
                              : validationError?.WarrantyRejectionAdviceNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            WR Advice Date
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Tender Number"
                            name="WarrantyRejectionAdviceDate"
                            value={values.WarrantyRejectionAdviceDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="date"
                          ></input>
                          <p className="text-danger m-0">
                            {touched.WarrantyRejectionAdviceDate &&
                              errors.WarrantyRejectionAdviceDate
                              ? errors.WarrantyRejectionAdviceDate
                              : validationError?.WarrantyRejectionAdviceDate}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="pdfFile"
                            className="form-label fw-bold font-16"
                          >
                            WR Advice File
                          </label>
                          <input
                            className="form-control border-secondary"
                            name="WRAdviceFile"
                            type="file"
                            onChange={(event) => {
                              getAttachmentBase64(event);
                            }}
                            onBlur={handleBlur}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Complaint No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Complaint No."
                            name="ComplaintNo"
                            value={values.ComplaintNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.ComplaintNo && errors.ComplaintNo
                              ? errors.ComplaintNo
                              : validationError?.ComplaintNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Reporting Rly
                            </label>
                            <Link to={"/railway-zone-add"} target="_blank">
                              <button
                                className="btn btn-danger p-1 "
                                style={{
                                  height: "1.4rem",
                                  lineHeight: "0 ",
                                }}
                                type="button"
                              >
                                Add
                              </button>
                            </Link>
                          </div>
                          <div onClick={fetchDropdownApi}>
                            <Select
                              placeholder="Select Railway Zone"
                              className="form-select-control border-secondary"
                              name="ReportingRly"
                              options={railwayZoneList}
                              getOptionLabel={(railwayZoneList) =>
                                railwayZoneList["name"]
                              }
                              getOptionValue={(railwayZoneList) =>
                                railwayZoneList["id"]
                              }
                              value={railwayRlyelectedValue}
                              onChange={(railwayRlyelectedValue) => {
                                setRailwayRlySelectedValue(
                                  railwayRlyelectedValue
                                );
                                setFieldValue(
                                  "ReportingRly",
                                  railwayRlyelectedValue
                                    ? railwayRlyelectedValue.id
                                    : null
                                );
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "lightgrey",
                                  primary: "lightgrey",
                                },
                              })}
                            />
                          </div>
                          <p className="text-danger m-0">
                            {touched.ReportingRly && errors.ReportingRly
                              ? errors.ReportingRly
                              : validationError?.ReportingRly}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              To Attend At
                            </label>
                            <Link to={"/railway-zone-add"} target="_blank">
                              <button
                                className="btn btn-danger p-1 "
                                style={{
                                  height: "1.4rem",
                                  lineHeight: "0 ",
                                }}
                                type="button"
                              >
                                Add
                              </button>
                            </Link>
                          </div>
                          <div onClick={fetchDropdownApi}>
                            <Select
                              placeholder="Select Railway Zone"
                              className="form-select-control border-secondary"
                              name="ToAttendAt"
                              options={railwayZoneList}
                              getOptionLabel={(railwayZoneList) =>
                                railwayZoneList["name"]
                              }
                              getOptionValue={(railwayZoneList) =>
                                railwayZoneList["id"]
                              }
                              value={toAttendAtselectedValue}
                              onChange={(toAttendAtselectedValue) => {
                                setToAttendAtselectedValue(
                                  toAttendAtselectedValue
                                );
                                setFieldValue(
                                  "ToAttendAt",
                                  toAttendAtselectedValue
                                    ? toAttendAtselectedValue.id
                                    : null
                                );
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "lightgrey",
                                  primary: "lightgrey",
                                },
                              })}
                            />
                          </div>
                          <p className="text-danger m-0">
                            {touched.ReportingRly && errors.ReportingRly
                              ? errors.ReportingRly
                              : validationError?.ReportingRly}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            To Attend At (Address)
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Complete Attend Address"
                            name="AttendAddress"
                            value={values.AttendAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.AttendAddress && errors.AttendAddress
                              ? errors.AttendAddress
                              : validationError?.AttendAddress}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Po. No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Po.No."
                            name="PoNo"
                            value={values.PoNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.PoNo && errors.PoNo
                              ? errors.PoNo
                              : validationError?.PoNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Po. Date
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Po. Date"
                            name="PoDate"
                            value={values.PoDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="date"
                          ></input>
                          <p className="text-danger m-0">
                            {touched.PoDate && errors.PoDate
                              ? errors.PoDate
                              : validationError?.PoDate}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Coach No. (Product SN.)
                          </label>
                          {/* <Select
                            placeholder="Select Coach No."
                            className="form-select-control border-secondary"
                            name="CoachNo_ProductSN"
                            options={projectLotCoachList}
                            getOptionLabel={(projectLotCoachList) =>
                              projectLotCoachList["coachNo"]
                            }
                            getOptionValue={(projectLotCoachList) =>
                              projectLotCoachList["id"]
                            }
                            value={selectedValue}
                            onChange={(selectedOption) => {
                              setSelectedValue(selectedOption);
                              setFieldValue(
                                "CoachNo_ProductSN",
                                selectedOption ? selectedOption.id : null
                              );
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 4,
                              colors: {
                                ...theme.colors,
                                primary25: "lightgrey",
                                primary: "lightgrey",
                              },
                            })}
                          /> */}
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Coach No."
                            name="CoachNo_ProductSN"
                            value={values.CoachNo_ProductSN}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.CoachNo_ProductSN &&
                              errors.CoachNo_ProductSN
                              ? errors.CoachNo_ProductSN
                              : validationError?.CoachNo_ProductSN}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Warranty Period
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Warrant Period"
                            type="date"
                            name="WarrantyPeriod"
                            value={values.WarrantyPeriod}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.WarrantyPeriod && errors.WarrantyPeriod
                              ? errors.WarrantyPeriod
                              : validationError?.WarrantyPeriod}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Defect Type
                          </label>
                          <select
                            className="form-control border-secondary"
                            placeholder="Enter Defect Type"
                            name="DefectType"
                            value={values.DefectType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={""}>Select</option>
                            {defectType?.map((element) => (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                          </select>

                          <p className="text-danger m-0">
                            {touched.DefectType && errors.DefectType
                              ? errors.DefectType
                              : validationError?.DefectType}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Description of Defect
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Description of Defect"
                            name="DescOfDefect"
                            value={values.DescOfDefect}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.DescOfDefect && errors.DescOfDefect
                              ? errors.DescOfDefect
                              : validationError?.DescOfDefect}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Make
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Description of Defect"
                            name="Make"
                            value={values.Make}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.Make && errors.Make
                              ? errors.Make
                              : validationError?.Make}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Qty.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Qty."
                            name="Qty"
                            value={values.Qty}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.Qty && errors.Qty
                              ? errors.Qty
                              : validationError?.Qty}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Status
                          </label>
                          <select
                            className="form-control border-secondary"
                            placeholder="Enter Status"
                            name="Status"
                            value={values.Status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={""}>Select</option>
                            <option value={"Attended"}>Attended</option>
                            <option value={"Pending"}>Pending</option>
                            {mode === "edit" && (
                              <option value={"Attended (CRN Pending)"}>
                                Attended (CRN Pending)
                              </option>
                            )}
                          </select>
                          <p className="text-danger m-0">
                            {touched.Status && errors.Status
                              ? errors.Status
                              : validationError?.Status}
                          </p>
                        </div>
                      </div>
                      {mode === "edit" && (
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="pdfFile"
                              className="form-label fw-bold font-16"
                            >
                              Joint Letter CRN Proof
                            </label>
                            <input
                              className="form-control border-secondary"
                              name="JointLetterCrnProof"
                              type="file"
                              onChange={(event) => {
                                getAttachmentBase64(event);
                              }}
                              onBlur={handleBlur}
                            ></input>
                          </div>
                        </div>
                      )}

                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            WC Closer CRN No.
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter WC Closer CRN No."
                            name="WcCloserCrnNo"
                            value={values.WcCloserCrnNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.WcCloserCrnNo && errors.WcCloserCrnNo
                              ? errors.WcCloserCrnNo
                              : validationError?.WcCloserCrnNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            WC Closer Date
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter WC Closer Date"
                            name="WC_CloserDate_AsPerCRN"
                            value={values.WC_CloserDate_AsPerCRN}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="date"
                          ></input>
                          <p className="text-danger m-0">
                            {touched.WC_CloserDate_AsPerCRN &&
                              errors.WC_CloserDate_AsPerCRN
                              ? errors.WC_CloserDate_AsPerCRN
                              : validationError?.WC_CloserDate_AsPerCRN}
                          </p>
                        </div>
                      </div>
                      {mode === "edit" && (
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="pdfFile"
                              className="form-label fw-bold font-16"
                            >
                              WC Closer CRN File
                            </label>
                            <input
                              className="form-control border-secondary"
                              name="WCCloserCRNFile"
                              type="file"
                              onChange={(event) => {
                                getAttachmentBase64(event);
                              }}
                              onBlur={handleBlur}
                            ></input>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Assigned to
                          </label>
                          <select
                            className="form-control border-secondary"
                            placeholder="Enter Assigned to"
                            name="AssignedTo"
                            value={values.AssignedTo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={""}>Select</option>
                            {allDropdowns?.userList?.map((element) => (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                          </select>
                          <p className="text-danger m-0">
                            {touched.AssignedTo && errors.AssignedTo
                              ? errors.AssignedTo
                              : validationError?.AssignedTo}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Last Date To Attend
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Tender Number"
                            name="LastDateToAttend"
                            value={values.LastDateToAttend}
                            onBlur={handleBlur}
                            type="date"
                          ></input>
                          <p className="text-danger m-0">
                            {touched.LastDateToAttend && errors.LastDateToAttend
                              ? errors.LastDateToAttend
                              : validationError?.LastDateToAttend}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Claim Amount (to deduct from Bill)
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Claim Amount"
                            name="ClaimAmount_ToDeductFromBill"
                            value={values.ClaimAmount_ToDeductFromBill}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.ClaimAmount_ToDeductFromBill &&
                              errors.ClaimAmount_ToDeductFromBill
                              ? errors.ClaimAmount_ToDeductFromBill
                              : validationError?.ClaimAmount_ToDeductFromBill}
                          </p>
                        </div>
                      </div>

                      {mode === "edit" && (
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="pdfFile"
                              className="form-label fw-bold font-16"
                            >
                              Claim Amount Deduction Proof
                            </label>
                            <input
                              className="form-control border-secondary"
                              name="ClaimAmountTBDFile"
                              type="file"
                              onChange={(event) => {
                                getAttachmentBase64(event);
                              }}
                              onBlur={handleBlur}
                            ></input>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Deduction Status
                          </label>
                          <select
                            className="form-control border-secondary"
                            placeholder="Enter Deduction Status"
                            name="DeductionStatus"
                            value={values.DeductionStatus}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={""}> Select </option>
                            <option value={"RETURNED"}> RETURNED </option>
                            <option value={"DEDUCTED (DUE FOR RETURN)"}>
                              {" "}
                              DEDUCTED (DUE FOR RETURN){" "}
                            </option>
                            <option value={"NOT DEDUCTED"}>
                              {" "}
                              NOT DEDUCTED{" "}
                            </option>
                            <option value={"TO DEDUCT"}> TO DEDUCT </option>
                          </select>
                          <p className="text-danger m-0">
                            {touched.DeductionStatus && errors.DeductionStatus
                              ? errors.DeductionStatus
                              : validationError?.DeductionStatus}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Deduction Status Date
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Tender Number"
                            name="DeductionStatusDate"
                            value={values.DeductionStatusDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="date"
                          ></input>
                          <p className="text-danger m-0">
                            {touched.DeductionStatusDate &&
                              errors.DeductionStatusDate
                              ? errors.DeductionStatusDate
                              : validationError?.DeductionStatusDate}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Deduction details
                          </label>
                          <textarea
                            className="form-control border-secondary"
                            placeholder="Enter Deduction details "
                            name="deductionDetails"
                            value={values.deductionDetails}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></textarea>
                          <p className="text-danger m-0">
                            {touched.deductionDetails && errors.deductionDetails
                              ? errors.deductionDetails
                              : validationError?.deductionDetails}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Consignee to Contact
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Consignee to Contact"
                            name="ConsigneeToContact"
                            value={values.ConsigneeToContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.ConsigneeToContact &&
                              errors.ConsigneeToContact
                              ? errors.ConsigneeToContact
                              : validationError?.ConsigneeToContact}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Contact Number
                          </label>
                          <input
                            className="form-control border-secondary"
                            placeholder="Enter Contact Number   "
                            name="ContactNo"
                            value={values.ContactNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></input>
                          <p className="text-danger m-0">
                            {touched.ContactNo && errors.ContactNo
                              ? errors.ContactNo
                              : validationError?.ContactNo}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>wait...</p>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Button variant="primary" onClick={handleSubmit}>
                            Save
                          </Button>

                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm border-danger"
                            style={{ marginLeft: "1rem" }}
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarrantyTracker;
