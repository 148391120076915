import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../Assets/acme-india.png";
import axios from "axios";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Link
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    // marginTop: 18,
    padding: 10,
    flexGrow: 1,
    // border: "1px solid #e1e1e1",
  },
  sectionNext: {
    marginTop: 30,
    padding: 10, 
    flexGrow: 1,
  },
  subSection: {
    marginTop: 3,
    // marginBottom: 6,
  },
  bottomSection: {

  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5",
  },

  centerText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5",
    fontSize: "9px",
  },
  image: {
    width: "120px", // Set the width of the image to 100% of the container
    height: "30px", // Set the height of the image to 100% of the container 
  },

  heading: {
    fontSize: 10,
    border: "1px solid #e1e1e1",
    backgroundColor: "#f4f4f4",
    padding: 6,
    fontWeight: 500,
    marginBottom: 4,
  },
  // Custom container for a row of `pra` elements
  praRow: {
    flexDirection: "row",
    justifyContent: "space-between", // Space between the columns
    marginBottom: 5, // Adjust the margin as needed
  },

  // Style for each column within the `praRow`
  praColumn: {
    flex: 1, // Equal width for each column
    fontSize: 8,
    paddingLeft: 5   ,
    paddingRight: 5,
    // Add left padding for spacing
  },
  praColumnHeading: {
    flex: 1, // Equal width for each column
    fontSize: 11,
    paddingLeft: 5,
    paddingRight: 5,
    // Add left padding for spacing
    fontWeight: 900, 
  },

  // Style for the last column to remove the right border
  lastPraColumn: {
    flex: 1,
    fontSize: 8,
    paddingLeft: 5,
  },
  pra: {
    fontSize: 8,
  },
  header_image: {
    width: "50px",
  },
});

// Create Document Component
function ProjectReport() {
  const [pLoaCompletionStatusarray, setpLoaCompletionStatusarray] = useState([
    {
      1: "Completed",
      2: "Pending",
      3: "Terminated",
    },
  ]);

  const [paymentStatusarray, setpaymentStatusarray] = useState([
    {
      1: "Received",
      2: "Pending",
      3: "Done",
    },
  ]);
  const [AcceptedRejectedarray, setAcceptedRejectedarray] = useState([
    {
      1: "Accepted",
      2: "Rejected",
    },
  ]);

  const [ritesIcStatusarray, setritesIcStatusarray] = useState([
    {
      1: "Submitted",
      2: "Accepted",
    },
  ]);

  const [TPTAgencyarray, setTPTAgencyarray] = useState([
    {
      1: "Rites",
      2: "Consignee",
      3: "Omgc TC",
      4: "Rites N Region",
      5: "Inter Tek",
      6: "TUV Nodes",
      7: "RDSO",
    },
  ]);

  function formatDate(date) {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    // Split the formatted date into day, month, and year parts
    const [month, day, year] = formattedDate.split(" ");
    // Convert the month abbreviation to uppercase
    const capitalizedMonth = month.toUpperCase();
    // Return the formatted date with uppercase month abbreviation and desired format
    return `${day} ${capitalizedMonth} ${year}`;
  }

  const [projectDetails, setprojectDetails] = useState([]);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  //alert(imageBaseUrl);

  const [other, setOther] = useState([]);

  const params = useParams();

  const fetchData = () => {
    // const url = apiurl + "getprojectdetailspdf";
    const url = apiurl + "untiprojectdetailpdf";

    var body = {
      user_id: user_id,
      project_id: params.id,
    };
    axios
      .post(url, body, config)
      .then((res) => {
        setprojectDetails(res?.data?.response?.projectDetails);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="ProjectReport">
      <PDFViewer width="100%" height="950" showToolbar={true}>
        <Document title={projectDetails?.pd_projectTitle}>
          <Page size="A4">
            <View style={styles.imageContainer}>
              <Image src={logo} style={styles.image} />
            </View>
            <View style={styles.section}>
              <Text style={styles.heading}>Project details</Text> 
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>File No  </Text>
                  <Text style={styles.praColumnHeading}>Railway Zone </Text>
                  <Text style={styles.praColumnHeading}>Sub Division </Text>
                  <Text style={styles.praColumnHeading}>Work Status </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.pd_fileNo}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_railwayZone}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_subDivision}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_workStatus}</Text>
                </View>
              </View>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Work Status Remark  </Text>
                  <Text style={styles.praColumnHeading}>Tender No </Text>
                  <Text style={styles.praColumnHeading}>Tender Date  </Text>
                  <Text style={styles.praColumnHeading}>Tender Quantity </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.pd_workStatusRemark}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_tenderNo}</Text>
                  <Text style={styles.praColumn}>{formatDate(projectDetails?.pd_tenderDate)}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_tenderQuantity}</Text>
                </View>
              </View>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}> PO/LOA No.  </Text>
                  <Text style={styles.praColumnHeading}>PO/LOA Date. </Text>
                  <Text style={styles.praColumnHeading}>Total Order Value  </Text>
                  <Text style={styles.praColumnHeading}>PO/LOA Docs </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.pd_poloaNo}</Text>
                  <Text style={styles.praColumn}>{formatDate(projectDetails?.pd_poloaDate)}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_totalOrderValue}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_poloaDocs}</Text>
                </View>
              </View>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Project Title</Text>
                  <Text style={styles.praColumnHeading}>Project Item/Description </Text>
                  <Text style={styles.praColumnHeading}></Text>
                  <Text style={styles.praColumnHeading}></Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.pd_projectTitle}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.pd_projectItemDescription}</Text>
                  <Text style={styles.praColumn}></Text>
                  <Text style={styles.praColumn}></Text>
                </View>
              </View>
              <View style={styles.bottomSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>PO/LOA Quantity</Text>
                  <Text style={styles.praColumnHeading}>UOM</Text>
                  <Text style={styles.praColumnHeading}>Start Date</Text>
                  <Text style={styles.praColumnHeading}>Completion Date</Text>
                  <Text style={styles.praColumnHeading}>DP Extension</Text>
                </View>
              </View>

              {projectDetails.pd_poloaQuantityMutipleData?.map((item, i) => (

                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{item.poLoaQty}</Text>
                  <Text style={styles.praColumn}>{item.unit}</Text>
                  <Text style={styles.praColumn}>{formatDate(item.startDate)}</Text>
                  <Text style={styles.praColumn}>{formatDate(item.completionDate)}</Text>
                  <Text style={styles.lastPraColumn}>{item.dpExtension}</Text>
                </View>
              ))}
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>Railway rate as per LOA/PO Value</Text>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}></Text>
                  <Text style={styles.praColumnHeading}>Per Unit</Text>
                  <Text style={styles.praColumnHeading}>Total</Text>
                </View>
              </View> 

              {projectDetails.lcb_basicMultipleData?.map((item, i) => (
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Basic</Text>
                  <Text style={styles.praColumn}>{item.basicPerUnit}</Text>
                  <Text style={styles.praColumn}>{item.basicTotal}</Text>
                </View>
              ))}

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>Installation</Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_installationPerUnit}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_installationTotal}
                </Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>Packaging</Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_packagingPerUnit}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_packagingTotal}
                </Text>
              </View>


              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>Freight</Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_freightPerUnit}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_freightTotal}
                </Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>GST@{projectDetails?.lcb_gstValue}%</Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_gstUnit}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_gstTotal}
                </Text>
              </View>

              {projectDetails.lcb_otherMultipleData?.map((item, i) => (
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Other</Text>
                  <Text style={styles.praColumn}>{item.otherPerUnit}</Text>
                  <Text style={styles.praColumn}>{item.otherTotal}</Text>
                </View>
              ))}
              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>PO/LOA Value </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_poloaValue}
                </Text>
                <Text style={styles.praColumn}></Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>PAYMENT TERMS </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.lcb_paymentTerms}
                </Text>
                <Text style={styles.praColumn}></Text>
              </View>
              <Text style={styles.pra}> </Text>
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>EMD/SD/PG PAID DETAILS</Text>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Type</Text>
                  <Text style={styles.praColumnHeading}>To Pay</Text>
                  <Text style={styles.praColumnHeading}>Paid/Exempted</Text>
                  <Text style={styles.praColumnHeading}>Transaction <Text style={styles.praColumnHeading}>No. Date</Text></Text>
                  <Text style={styles.praColumnHeading}>Expiry Date</Text>
                </View>
              </View> 

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  EMD
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.emd_toPay}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.emd_paidExempted}
                </Text>
                <Text style={styles.praColumn}>
                  {formatDate(projectDetails?.emd_paidTransactionNoDate)}
                </Text>
                <Text style={styles.lastPraColumn}>
                  {formatDate(projectDetails?.emd_expiryDate)}
                </Text>
              </View> 

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  PG
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.pg_toPay}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.pg_paidExempted}
                </Text>
                <Text style={styles.praColumn}>
                  {formatDate(projectDetails?.pg_paidTransactionNoDate)}
                </Text>
                <Text style={styles.lastPraColumn}>
                  {formatDate(projectDetails?.pg_expiryDate)}
                </Text>
              </View>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Type</Text>
                  <Text style={styles.praColumnHeading}>Return Date</Text>
                  <Text style={styles.praColumnHeading}>Return Amount</Text>
                  <Text style={styles.praColumnHeading}>Due</Text>
                  <Text style={styles.praColumnHeading}>Attachment</Text>
                </View>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  EMD
                </Text>
                <Text style={styles.praColumn}>
                  {formatDate(projectDetails?.emd_returnDate)}
                </Text>
                <Text style={styles.lastPraColumn}>
                  {projectDetails?.emd_returnAmount}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.emd_due}
                </Text>
                <Text style={styles.lastPraColumn}> 
                  <Link src={imageBaseUrl + projectDetails?.emd_attachement}>View Attachment</Link>
                </Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  SD
                </Text>
                <Text style={styles.praColumn}>
                  {formatDate(projectDetails?.sd_returnDate)}
                </Text>
                <Text style={styles.lastPraColumn}>
                  {projectDetails?.sd_returnAmount}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.sd_due}
                </Text>
                <Text style={styles.lastPraColumn}>
                <Link src={imageBaseUrl + projectDetails?.sd_attachement}>View Attachment</Link> 
                </Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  PG
                </Text>
                <Text style={styles.praColumn}>
                  {formatDate(projectDetails?.pg_returnDate)}
                </Text>
                <Text style={styles.lastPraColumn}>
                  {projectDetails?.pg_returnAmount}
                </Text>
                <Text style={styles.praColumn}>
                  {projectDetails?.pg_due}
                </Text>
                <Text style={styles.lastPraColumn}>
                <Link src={imageBaseUrl + projectDetails?.pg_attachement}>View Attachment</Link>  
                </Text>
              </View>

              <View style={styles.praRow}>
                <Text style={styles.praColumnHeading}>
                  Remark   {projectDetails?.remark}
                </Text>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.heading}>Material Inspection Details</Text>
              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>
                    Agency
                  </Text>
                  <Text style={styles.praColumnHeading}>
                    Case No.
                  </Text>
                  <Text style={styles.praColumnHeading}>
                    RITES IC Status
                  </Text>
                  <Text style={styles.praColumnHeading}>
                    Date & Qty.
                  </Text>
                  <Text style={styles.praColumnHeading}>
                    IC Submission
                  </Text>
                  <Text style={styles.praColumnHeading}>Attachment</Text>
                </View>
              </View>

              {projectDetails.MaterialInspectionDetailMultipleData?.map((item, i) => (
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{TPTAgencyarray[0][item.InspectionAgency]}</Text>
                  <Text style={styles.praColumn}>{item.InspectionCaseNo}</Text>
                  <Text style={styles.praColumn}>{AcceptedRejectedarray[0][item.ritesIcStatus]}</Text>
                  <Text style={styles.praColumn}>{formatDate(item.inspectionDate)}</Text>
                  <Text style={styles.praColumn}>{item.ICSubmissionStatus}</Text>
                  <Text style={styles.lastPraColumn}> 
                    <Link src={imageBaseUrl + item.inspectionAttachment}>View Attachment</Link>  
                    </Text>
                </View>
              ))}
            </View>

            <View style={styles.imageContainer}>
              <Text style={styles.centerText}>
                Note : This is computerized generated report
              </Text>
            </View>
          </Page>

          <Page size="A4">
            <View style={styles.sectionNext}>
              <Text style={styles.heading}>BILLING & PAYMENT DETAILS</Text>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Invoice No.  </Text>
                  <Text style={styles.praColumnHeading}>Invoice Date </Text>
                  <Text style={styles.praColumnHeading}>Invoice Quantity </Text>
                  <Text style={styles.praColumnHeading}>Invoice Amount </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_invoiceNo}</Text>
                  <Text style={styles.praColumn}>{formatDate(projectDetails?.bpd_invoiceDate)}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_invoiceQuantity}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_invoiceAmount}</Text>
                </View>
              </View>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Accepted/Rejected </Text>
                  <Text style={styles.praColumnHeading}>Atteched R Note </Text>
                  <Text style={styles.praColumnHeading}>R Note No. / Date </Text>
                  <Text style={styles.praColumnHeading}>Payment Status  </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{ritesIcStatusarray[0][projectDetails?.bpd_materialAcceptedRejected]}</Text>
                  <Text style={styles.praColumn}>
                  <Link src={imageBaseUrl + projectDetails?.bpd_attachedRNote}>View Attachment</Link></Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_rNoteNoDate}</Text>
                  <Text style={styles.praColumn}>{paymentStatusarray[0][projectDetails?.bpd_paymentStatus]}</Text>
                </View>
              </View>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Amount Received </Text>
                  <Text style={styles.praColumnHeading}>SD (Security Deposit) </Text>
                  <Text style={styles.praColumnHeading}>LD (Liquidity Damage) </Text>
                  <Text style={styles.praColumnHeading}>Penalty (Delay)  </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_amountRecieved}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_sd_securityDeposite}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_ld_liquidityDamage}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_penalty}</Text>
                </View>
              </View>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>Store Dedposit </Text>
                  <Text style={styles.praColumnHeading}>Material Deducted </Text>
                  <Text style={styles.praColumnHeading}>Primery/ CESS Surcharge </Text>
                  <Text style={styles.praColumnHeading}>TDS(Income Tax)  </Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_storeDeposite}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_materialDeductied}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_primaryCessSurcharge}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_tds}</Text>
                </View>
              </View>

              <View style={styles.subSection}>
                <View style={styles.praRow}>
                  <Text style={styles.praColumnHeading}>GST </Text>
                  <Text style={styles.praColumnHeading}>Payment Due </Text>
                  <Text style={styles.praColumnHeading}>PO/LOA Completion Status </Text>
                  <Text style={styles.praColumnHeading}></Text>
                </View>
                <View style={styles.praRow}>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_gst}</Text>
                  <Text style={styles.praColumn}>{projectDetails?.bpd_paymentDue}</Text>
                  <Text style={styles.praColumn}>{pLoaCompletionStatusarray[0][projectDetails?.bpd_poloaCompletionStatus]}</Text>
                  <Text style={styles.praColumn}></Text>
                </View>
              </View>
            </View>

            <View style={styles.imageContainer}>
              <Text style={styles.centerText}>
                Note : This is computerized generated report
              </Text>
            </View>
          </Page>

        </Document>
      </PDFViewer>
    </div>
  );
}

export default ProjectReport;
