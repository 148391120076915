import axios from "axios";
import { toast } from "react-toastify";
const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});

// Legal entity api
export const legalEntityApi = () => API.get("/list-legal-entity");
export const auhorizeSignatoriesApi = (id) =>
  API.get(`/list-auth-sign?legal_entity_id=${id}`);
export const addLegalEntityAuthorizeSignatureApi =(data)=>
  API.post(`/add-auth-sign`,data)
export const bankApi = (id) =>
  API.get(`/list-bank-details?legal_entity_id=${id}`);

export const createLegalEntityApi = (data) => API.post(`/add-legal-entity`, data)

export const updateLeagalEntityApi  = (data) => API.patch(`/update-legal-entity`, data)

export const deleteLeagalEntityApi = (data) => API.post(`/delete-legal-entity` , data)


export const createAuthorizedSignatureApi = (data) => API.post(`/add-auth-sign`, data)
export const updateAuthorizedSignatureApi = (data) => API.patch(`/update-auth-sign`, data)
export const deleteAuthorizedSignatureApi = (data) => API.delete(`/delete-auth-sign`, {data})
// legal entity bank Api
export const createBankDetailApi =(data)=> API.post('/add-bank-details',data)
export const updateBankDetailApi =(data)=> API.patch('/update-bank-details',data)
export const deletebankDetailApi = (data) => API.delete(`/delete-bank-details`, {data})
//Business unit api
export const businessUnitListApi = () => API.get(`/list-business-unit`);
export const createBusinessUnitApi =(data)=> API.post('/add-business-unit',data)
export const updateBusinessUnitApi =(data)=>API.patch('/update-business-unit',data)
export const deleteBusinessUnitApi=(data)=>API.delete('/delete-business-unit',{data})
//Location data

export const locationListApi = () => API.get("/list-locations");
export const createLocationApi =(data)=> API.post('/add-locations',data)
export const updateLocationApi = (data)=> API.patch('/update-locations',data)
export const deleteLocationApi=(data)=>API.delete('/delete-locations',{data})
