import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import React, { useEffect, useState } from "react";
import AddressDetailForm from "./AddressDetailForm";
import AccountDetailForm from "./AccountDetailForm";
import CompanyDetailForm from "./CompanyDetailForm";
import BankDetailForm from "./BankDetailForm";
import ContactDetailForm from "./ContactDetailForm";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { errorsMessage } from "../../../constant";
import { toast } from "react-toastify";
import {
  getCityMasterList,
  getCountryMasterList,
  getStateMasterList,
} from "../../purchaseApi";
import addVendorFormSchema from "./AddVendorFormSchema";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log(eventKey)
  );

  return (
    <button
      type="button"
      style={{
        background: "#fff",
        border: "none",
        padding: 0,
        lineHeight: "0",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function EditVendorFormPage() {
  const [activeKey, setActiveKey] = useState([]);
  const location = useLocation();
  const editData = location?.state?.editData ?? "";
  const [checkboxes, setCheckboxes] = useState({
    //employeeDetail: false,
    is_address_check: editData?.is_address_check,
    accountDetail: editData?.accountDetail,
    companyDetail: editData?.companyDetail,
    is_bank_details_check: editData?.is_bank_details_check,
    contact_details_check: editData?.contact_details_check,
  });

  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();

  const [serverSideValidation, setServerSideValidation] = useState("");
  const [subGroupDropdown, setSubGroupDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [stateBankDropdown, setStateBankDropdown] = useState([]);
  const [cityBankDropdown, setCityBankDropdown] = useState([]);
  const [cityDropdown, setCityDropdown] = useState([]);

  const initialValue = {
    party_code: editData?.party_code,
    under_invoice: editData?.under_invoice,
    party_name: editData?.party_name,
    under_account: editData?.under_account,
    address_type: editData?.address_type,
    country_id: editData?.country_id,
    state_id: editData?.state_id,
    city_id: editData?.city_id,
    address: editData?.address,
    pin_code: editData?.pin_code,
    telephone: editData?.telephone,
    email: editData?.email,
    mobile: editData?.mobile,
    website: editData?.website,
    fax_no: editData?.fax_no,
    opening_balance: editData?.opening_balance,
    opening_balance_type: editData?.opening_balance_type,
    currency: editData?.currency,
    credit_limit: editData?.credit_limit,
    credit_limit_days: editData?.credit_limit_days,
    grade: editData?.grade,
    pan_no: editData?.pan_no,
    cts_no: editData?.cts_no,
    gst_no: editData?.gst_no,
    pvt_marka: editData?.pvt_marka,
    sales_target: editData?.sales_target,
    ecc_no: editData?.ecc_no,
    status: editData?.status,
    company_type: editData?.company_type,
    discount_percente: editData?.discount_percente,
    remarks: editData?.remarks,
    show_remark: editData?.show_remark,
    bank_name: editData?.bank_name,
    account_holder: editData?.account_holder,
    account_no: editData?.account_no,
    swift_code: editData?.swift_code,
    ifsc_code: editData?.ifsc_code,
    country: editData?.country,
    state: editData?.state,
    city: editData?.city,
    bank_address: editData?.bank_address,
    bank_pin_code: editData?.bank_pin_code,
    bank_telephone: editData?.bank_telephone,
    bank_email: editData?.bank_email,
    bank_mobile: editData?.bank_mobile,
    bank_website: editData?.bank_website,
    bank_fax_no: editData?.bank_fax_no,
    contact_person: editData?.contact_person,
    contact_job_title: editData?.contact_job_title,
    contact_job_designation: editData?.contact_job_designation,
    contact_mobile: editData?.contact_mobile,
    contact_telephone: editData?.contact_mobile,
    contact_email: editData?.contact_email,
    transport: editData?.transport,
    agent_name: editData?.agent_name,
    msme_no: editData?.msme_no,
    is_msme_check: editData?.is_msme_check,
  };

  const intialSetCheckBox = () => {
    let newActiveKey = [...activeKey];
    if (editData.is_address_check) {
      newActiveKey.push("is_address_check");
    }
    if (editData.accountDetail) {
      newActiveKey.push("accountDetail");
    }
    if (editData.companyDetail) {
      newActiveKey.push("companyDetail");
    }
    if (editData.is_bank_details_check) {
      newActiveKey.push("is_bank_details_check");
    }
    if (editData.contact_details_check) {
      newActiveKey.push("contact_details_check");
    }
    // else {
    //   newActiveKey = newActiveKey.filter((key) => key !== name);
    // }
    setActiveKey(newActiveKey);
  };
  useEffect(() => {
    intialSetCheckBox();
  }, []);

  //Check box code
  const handleCheckboxChange = (name) => {
    const UpdatedCheckBoxes = { ...checkboxes, [name]: !checkboxes[name] };
    setCheckboxes(UpdatedCheckBoxes);

    let newActiveKey = [...activeKey];

    if (UpdatedCheckBoxes[name]) {
      newActiveKey.push(name);
    } else {
      newActiveKey = newActiveKey.filter((key) => key !== name);
    }
    setActiveKey(newActiveKey);
  };

  //api fetch code
  function editItemMaster(values) {
    const apiUrl = baseUrl + "updatepartymmaster";
    const data = {
      party_master_id: editData.id,
      user_id,
      ...values,
      ...checkboxes,
    };
    setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          toast.success("Form submit successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  //Formik Code
  const { values, handleSubmit, handleChange, resetForm, errors, touched, handleBlur } =
    useFormik({
      initialValues: initialValue,
      validationSchema: addVendorFormSchema(checkboxes),
      onSubmit: (values) => {
        editItemMaster(values);
      },
    });
  function getCountryDropdownList() {
    // setLoading(true);
    getCountryMasterList({ user_id })
      .then((res) => {
        setCountryDropdown(res?.data?.response?.CountryMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    getCountryDropdownList();
  }, []);

  function getDropDown() {
    getStateMasterList({ user_id })
      .then((res) => {
        let stateDropdown = res?.data?.response?.StateMasterList;
        let data = stateDropdown.filter((element) => {
          return element.country_id === +values.country_id;
        });
        setStateDropdown(data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  function getCityDropDown() {
    getCityMasterList({ user_id })
      .then((res) => {
        let stateDropdown = res?.data?.response?.CityMasterList;
        let data = stateDropdown.filter((element) => {
          return element.state_id === +values.state_id;
        });
        setCityDropdown(data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function bankStategetDropDown() {
    getStateMasterList({ user_id })
      .then((res) => {
        let stateDropdown = res?.data?.response?.StateMasterList;
        let data = stateDropdown.filter((element) => {
          return element.country_id === +values.country;
        });
        setStateBankDropdown(data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function getBankCityDropDown() {
    getCityMasterList({ user_id })
      .then((res) => {
        let stateDropdown = res?.data?.response?.CityMasterList;
        let data = stateDropdown.filter((element) => {
          return element.state_id === +values.state;
        });
        setCityBankDropdown(data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  useEffect(() => {
    if (values.country) {
      bankStategetDropDown();
    }
  }, [values.country]);
  useEffect(() => {
    if (values.state) {
      getBankCityDropDown();
    }
  }, [values.state]);

  useEffect(() => {
    if (values.country_id) {
      getDropDown();
    }
  }, [values.country_id]);
  useEffect(() => {
    if (values.state_id) {
      getCityDropDown();
    }
  }, [values.state_id]);

  return (
    <div className="page-wrapper" style={{ marginLeft: "1rem" }}>
      <div className="page-content-tab responsive-tab">
        <h4>VENDOR MASTER</h4>
        <form>
          <div className="row">
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Branch Name{" "}
              </label>
              <input
                className="form-control border-secondary"
                value={values?.party_name}
                name="party_name"
                onChange={handleChange}
                placeholder="Enter Branch Code"
              />
            </div>
            {/* <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Party Code*{" "}
              </label>
              <input
                className="form-control border-secondary"
                value={values?.party_code}
                name="party_code"
                onChange={handleChange}
                placeholder="Enter Party Code"
              />
            </div> */}
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Under Invoice*{" "}
              </label>
              <select
                className="form-control border-secondary"
                value={values?.under_invoice}
                name="under_invoice"
                onChange={handleChange}
              >
                <option value={""}>Select </option>
                <option value={"Yes"}>Yes </option>
                <option value={"No"}>No </option>
              </select>
            </div>
            <div className="col-md-3 mt-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                Under Account*
              </label>
              <select
                className="form-control border-secondary"
                value={values?.under_account}
                name="under_account"
                onChange={handleChange}
              >
                <option value={""}>Select </option>
                <option value={"BankAccounts"}>Bank Accounts </option>
                <option value={"BankOd"}>Bank OD A/c </option>
              </select>
            </div>
          </div>
          <Accordion activeKey={activeKey} style={{ marginTop: "1rem" }}>
            {/* <Card>
              <Card.Header>
                <input
                  type="checkbox"
                  className="border border-danger"
                  checked={checkboxes.employeeDetail}
                  onChange={() => handleCheckboxChange("employeeDetail")}
                />
                <CustomToggle
                  eventKey="employeeDetail"
                  isChecked={checkboxes.employeeDetail}
                >
                  <h5
                    className="fw-bold d-inline-block "
                    style={{ marginLeft: "5px" }}
                  >
                    Employee Detail
                  </h5>{" "}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="employeeDetail">
                <Card.Body>
                  <EmployeeDetailForm
                    values={values}
                    handleChange={handleChange}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card>
              <Card.Header>
                <CustomToggle eventKey="is_address_check">
                  <input
                    type="checkbox"
                    className="border border-danger"
                    checked={checkboxes.is_address_check}
                    onChange={() => handleCheckboxChange("is_address_check")}
                  />
                </CustomToggle>
                <h5
                  className="fw-bold d-inline-block"
                  style={{ marginLeft: "5px" }}
                >
                  Address Detail
                </h5>
              </Card.Header>
              <Accordion.Collapse eventKey="is_address_check">
                <Card.Body>
                  <AddressDetailForm
                    values={values}
                    handleChange={handleChange}
                    countryDropdown={countryDropdown}
                    stateDropdown={stateDropdown}
                    cityDropdown={cityDropdown}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <CustomToggle eventKey="accountDetail">
                  <input
                    type="checkbox"
                    className="border border-danger"
                    checked={checkboxes.accountDetail}
                    onChange={() => handleCheckboxChange("accountDetail")}
                  />
                </CustomToggle>
                <h5
                  className="fw-bold d-inline-block"
                  style={{ marginLeft: "5px" }}
                >
                  Account Detail
                </h5>
              </Card.Header>
              <Accordion.Collapse eventKey="accountDetail">
                <Card.Body>
                  <AccountDetailForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <CustomToggle eventKey="companyDetail">
                  <input
                    type="checkbox"
                    className="border border-danger"
                    checked={checkboxes.companyDetail}
                    onChange={() => handleCheckboxChange("companyDetail")}
                  />
                </CustomToggle>
                <h5
                  className="fw-bold d-inline-block"
                  style={{ marginLeft: "5px" }}
                >
                  Company Detail
                </h5>
              </Card.Header>
              <Accordion.Collapse eventKey="companyDetail">
                <Card.Body>
                  <CompanyDetailForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header style={{ background: "##F3F3F3" }}>
                <CustomToggle eventKey="is_bank_details_check">
                  <input
                    type="checkbox"
                    className="border border-danger"
                    checked={checkboxes.is_bank_details_check}
                    onChange={() =>
                      handleCheckboxChange("is_bank_details_check")
                    }
                  />
                </CustomToggle>
                <h5
                  className="fw-bold d-inline-block"
                  style={{ marginLeft: "5px" }}
                >
                  Bank Detail
                </h5>
              </Card.Header>

              <Accordion.Collapse eventKey="is_bank_details_check">
                <Card.Body>
                  <BankDetailForm
                    values={values}
                    handleChange={handleChange}
                    countryDropdown={countryDropdown}
                    stateBankDropdown={stateBankDropdown}
                    cityBankDropdown={cityBankDropdown}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <CustomToggle eventKey="contact_details_check">
                  <input
                    type="checkbox"
                    className="border border-danger"
                    checked={checkboxes.contact_details_check}
                    onChange={() =>
                      handleCheckboxChange("contact_details_check")
                    }
                  />
                </CustomToggle>
                <h5
                  className="fw-bold d-inline-block"
                  style={{ marginLeft: "5px" }}
                >
                  Contact Detail
                </h5>
              </Card.Header>
              <Accordion.Collapse eventKey="contact_details_check">
                <Card.Body>
                  <ContactDetailForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          {loading ? (
            <div className="text-end mt-3">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Adding Item...</p>
            </div>
          ) : (
            <div className="text-end mt-3">
              <button
                className="btn btn-danger mx-2"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button className="btn btn-danger" onClick={handleSubmit}>
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
export default EditVendorFormPage;
