import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "../HrDepartment.scss";
import EmployeeDirectory from "./EmployeeDirectory";
import ProbationMain from "./Probation/ProbationMain";
import JobTabMain from "./Job/JobTabMain";
import AddJobTitleModal from "./Job/AddjobTitleModal";
import TimeTypeTabMain from "./TimeType/TimeTypeMain";
import WorkerTypeTabMain from "./WorkerType/WorkerTypeMain";
import AddTimeTypeModal from "./TimeType/AddTimeTypeModal";
import AddWorkTypeModal from "./WorkerType/AddWorkTypeModal";

const EmployeeMainTab = () => {
  const [value, setValue] = useState("Employees Directory");
  const [addJobTitle, SetAddJobTitle] = useState(false);
  const [addWorkerType, setAddWorkerType] = useState(false);
  const [addTimeType, setAddTimeType] = useState(false);
  const [editData, setEditData] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModal = () => {
    if (value === "Job Title") {
      SetAddJobTitle(true);
      setEditData(null);
    } else if (value === "Worker Type") {
      setAddWorkerType(true);
      setEditData(null);
    } else if (value === "Time Type") {
      setAddTimeType(true);
      setEditData(null);
    } else if (value === "Worker Type") {
      setAddWorkerType(true);
      setEditData(null);
    }
  };
  const addButton = () => {
    if (
      value === "Worker Type" ||
      value === "Job Title" ||
      value === "Time Type"
    ) {
      return (
        <div className="d-flex justify-content-between align-items-center me-3">
          <span className="text-uppercase dashboard ms-2 mt-3">Employees</span>
          <button
            className="red-button rounded btn btn-primary fw-bold me-3"
            onClick={() => openModal()}
          >{`Add ${value}`}</button>{" "}
        </div>
      );
    } else {
      return (
        <span className="text-uppercase dashboard ms-2 mt-3">Employees</span>
      );
    }
  };
  const getEditData = (data) => {
 
    if (value === "Job Title") {
      SetAddJobTitle(true);
      setEditData(data);
    } else if (value === "Time Type") {
      setAddTimeType(true);
      setEditData(data);
    } else if (value === "Worker Type") {
      setAddWorkerType(true);
      setEditData(data);
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab p-0">
          <div className="container-fluid">
            <section className="employee-main-tab">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  {/* Tab headers in one row */}
                  <div className="row">
                    <div className="" style={{ backgroundColor: "white" }}>
                      <div className="col-md-8">
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          variant="scrollable"
                          scrollButtons="auto"
                          className="custom-tablist"
                        >
                          <Tab
                            label="Employees Directory"
                            value="Employees Directory"
                          />
                          <Tab label="Probation" value="Probation" />
                          <Tab label="Job Titles" value="Job Title" />
                          <Tab label="Worker Type" value="Worker Type" />
                          <Tab label="Time Type" value="Time Type" />
                        </TabList>
                      </div>
                    </div>

                    {/* <span  className='dashboard ms-3 mt-3'>EMPLOYEES</span> */}
                    <span className="dashboard ms-3 mt-3">{addButton()}</span>
                  </div>

                  {/* Tab content in another row */}
                  <div className="row">
                    <div className="accordian ">
                      <TabPanel value="Employees Directory" className="p-0">
                        <EmployeeDirectory />
                      </TabPanel>
                      <TabPanel className="p-0" value="Probation">
                        {" "}
                        <ProbationMain />{" "}
                      </TabPanel>
                      <TabPanel className="p-0" value="Job Title">
                        {" "}
                        <JobTabMain
                          getEditData={getEditData}
                         
                        />
                      </TabPanel>
                      <TabPanel className="p-0" value="Worker Type">
                        <WorkerTypeTabMain  getEditData={getEditData} />{" "}
                      </TabPanel>
                      <TabPanel className="p-0" value="Time Type">
                        <TimeTypeTabMain   getEditData={getEditData}/>
                      </TabPanel>
                    </div>
                  </div>
                </TabContext>
              </Box>
              <AddJobTitleModal
                open={addJobTitle}
                SetAddJobTitle={SetAddJobTitle}
                editData={editData}
              />
              <AddTimeTypeModal
                open={addTimeType}
                setAddTimeType={setAddTimeType}
                editData={editData}
              />
              <AddWorkTypeModal
                open={addWorkerType}
                setAddWorkerType={setAddWorkerType}
                editData={editData}
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMainTab;
