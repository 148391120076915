import { elements } from "chart.js";
import * as Yup from "yup";

export const DeliveryChallanSchema = Yup.object({
  billing_party_id: Yup.string().required("This field is required."),
  branch_id: Yup.string().required("This field is required."),
  date_of_supply: Yup.date().required("This field is required."),
  shipping_party_id: Yup.string().required("This field is required."),
  store_id: Yup.string().required("This field is required."),
});

export function deliveryChallanItemsValidation(items) {
  console.log("items", items);
  const DeliveryChallanitems = { ...items };
  console.log("checl", DeliveryChallanitems);
  let error = false;

  Object.entries(DeliveryChallanitems).forEach(([key, value]) => {
    if (!value) {
      DeliveryChallanitems[key] = "This field is required";
      error = true;
    } else {
      DeliveryChallanitems[key] = "";
    }
  });
  console.log("What is ", DeliveryChallanitems);
  return { DeliveryChallanitems, error };
}
