import React, { useState } from "react";
import CommonTableExits from "../../MaterialUiComponent/CommonTableExits";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitSearchBar from "./ExitSearchBar";

const RevertedExits = ({ tabName }) => {
  const columns = [
    { id: "Employee", label: "Employee", minWidth: "10rem" },
    {
      id: "Exit type& Resign date",
      label: "Exit type& Resign Date",
      minWidth: "12rem",
    },
    { id: "Notice Date", label: "Notice Date", minWidth: 150 },
    { id: "Last Day", label: "Last Day", minWidth: 150 },
    {
      id: "Reporting Manager",
      label: "Reporting Manager",
      minWidth: 200,
      format: (value) => new Date(value).toLocaleDateString("en-US"),
    },
    { id: "Location", label: "Location", minWidth: 150 },
    { id: "Comment", label: "Comment", minWidth: 150 },
    { id: "Status", label: "Status", minWidth: 150 },
    { id: "Cancelled By", label: "Cancelled By", minWidth: 150 },
  ];

  // Sample data
  const rows = [
    {
      Employee: "John Doe",
      "Exit type& Resign date": "20-12-2021",
      "Notice Date": "2024-12-9",
      "Last Day": "2024-12-16",
      "Reporting Manager": "Onkar",
      Location: "noida",
      Comment: "--",
      Status: "Revoke",
      "Cancelled By": "Suresh Rajput",
    },

    // Add more sample data here
  ];
  const departmentChange = () => {
    console.log(tabName);
  };

  return (
    <section className="reverted-Exits">
      <div className="bg-white mx-3">
        <ExitSearchBar tabName={tabName} departmentChange={departmentChange} />
        <CommonTableExits
          columns={columns}
          rows={rows}
          actionfield={<p style={{ color: "#4667b6" }}>Take Action</p>}
        />
      </div>
    </section>
  );
};

export default RevertedExits;
