import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../HrDepartment.scss";
import ShowError from "../organisationStructure/OrganizationComponents/ShowError";
import { basicDetailSchema } from "../AddEmployeeSchema";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchWorkerType,
  getWorkerType,
} from "../../../redux/features/Hr/WorkerTypeSlice";

const AddEmployeeForm = ({
  formStage,
  setFormStage,
  setActiveKey,
  setBasicDetail,
  validationError
}) => {
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState("");
  const dispatch = useDispatch();
  const WorkerTypeList = useSelector(getWorkerType);

  useEffect(() => {
    dispatch(fetchWorkerType(dispatch));
  
  }, []);
  const initialValues = {
    name: "",
    gender: "",
    mobile: "",
    email: "",
    dob: "",
    worker_type: "",
    employee_number: "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: basicDetailSchema,
    onSubmit: (values) => {
      setBasicDetail(values);
      setFormStage(2);
      setActiveKey("Job Details");
    },
  });

  useEffect(() => {
    setBasicDetail(values);
  }, [values]);
  const inputData = [
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Enter Name",
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: [
        { id: "1", name: "male" },
        { id: "2", name: "female" },
      ],
      placeholder: "Select Gender",
    },
    {
      name: "mobile",
      label: "Mobile Number",
      type: "number",
      placeholder: "Enter Mobile Number",
    },
    {
      name: "email",
      label: "Email Address",
      type: "text",
      placeholder: "Enter Email Address",
    },
    {
      name: "dob",
      label: "Date Of Birth",
      type: "date",
      placeholder: "Enter Date Of Birth",
    },
    {
      name: "worker_type",
      label: "Worker Type",
      type: "select",
      // options: ["Select", "Part Time", "Full Time"],
      options: WorkerTypeList,
      placeholder: "Select Worker Type",
    },
    {
      name: "employee_number",
      label: "Employee Number",
      type: "text",
      placeholder: "Enter Number",
    },
  ];
  const handleFocus = (event) => {
    setIsFocused(event.target.name);
  };

  // const handleBlur = () => {
  //   setIsFocused("");
  // };

  return (
    <form>
      <div className="row justify-content-between ">
        {inputData.map(({ name, label, type, options, placeholder }, index) => (
          <div className="col-5 mx-3" key={index}>
            <label
              htmlFor={name}
              className={`form-label fw-bold font-12 font-weight-500 ${
                isFocused === name ? "text-danger" : "text-black"
              }`}
              style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
            >
              {label}
            </label>
            <div
              className={`${
                isFocused === name ? "add_emp_input_active" : "add_emp_input"
              }`}
            >
              {type === "select" ? (
                <select
                  name={name}
                  style={{ paddingLeft: ".75rem" }}
                  className="form-control form-control-sm border-secondary"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values[name]}
                  onChange={handleChange}
                  placeholder={placeholder}
                >
                 
                  <option>select</option>

                  {options?.map((worker) => (
                    <option value={worker?.id}>{worker?.name}</option>
                  ))}
                </select>
              ) : (
                <input
                  type={type}
                  name={name}
                  style={{ paddingLeft: ".75rem" }}
                  className="form-control form-control-sm border-secondary"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values[name]}
                  onChange={handleChange}
                  placeholder={placeholder}
                />
              )}
              <ShowError touched={touched[name]} message={errors[name]} validationError={validationError?.[name]} />
            </div>
          </div>
        ))}
      </div>
      <div className="row pt-4">
        <div className="col-7"></div>
        <div
          className="col-5 ps-5"
          style={{ marginLeft: "Auto", width: "auto" }}
        >
          <button
            type="button"
            className="hr btn btn-primary me-5"
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddEmployeeForm;
