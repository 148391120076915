import { useEffect, useState } from "react";
import axios from "axios";
import { errorsMessage } from "../../constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

const VehicleRequestCard = ({ request, setStoreVehicleRequestList }) => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = useState(false);

  const [storeVehicleRequestId, setStoreVehicleRequestId] = useState(null);
  const handleClose = () => {
    setDeleteModal(false);
  };
  const getVehicleRequestList = (requestStatus = 0) => {
    const url = `${baseUrl}listvehiclerequest`;
    const data = { user_id, request_status: requestStatus };
    axios
      .post(url, data, config)
      .then((res) => {
        if (res?.status) {
          setStoreVehicleRequestList(
            res?.data?.response?.StoreVehicleRequestList
          );
        }
      })
      .catch((err) => errorsMessage(err?.response?.status));
  };
  const deleteVehicleRequestList = (storeVehicleRequestId) => {
    const url = `${baseUrl}deletevehiclerequest`;
    const data = { user_id, store_vehicle_request_id: storeVehicleRequestId };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status === 200) {
          getVehicleRequestList();
          handleClose();
          toast.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong , Please try again later");
      });
  };

  function formatDate(value) {
    var date = new Date(value);
    if (value) {
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return formattedDate;
  }

  return (
    <>
      <div className="col-md-6">
        <div
          className="card"
          style={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            padding: "0.4rem",
          }}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between position-relative">
                  <div>
                    <div
                      className="mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/requestDetail", {
                          state: {
                            request_status: request?.request_status,
                            id: request?.id,
                          },
                        });
                      }}
                    >
                      <h4 className="mb-1">
                        {request?.project?.pd_projectTitle}
                      </h4>
                      <p className="m-0 tender-detail">
                        PO :{" "}
                        <span className="text-secondary fw-bold">
                          {request?.po_no}
                        </span>{" "}
                      </p>
                    </div>
                    <div
                      className="position-absolute top-0"
                      style={{ right: "29px" }}
                    >
                      <p
                        style={{
                          background: "#FFE5CB",
                          color: "#FFA041",
                          padding: "0.3rem 0.7rem",
                          borderRadius: "0.2rem",
                        }}
                      >
                        {formatDate(request?.requested_date_for)}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div class="dropdown">
                      <div
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <PiDotsThreeOutlineVerticalFill
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <ul className="dropdown-menu p-2">
                        <li
                          style={{ cursor: "pointer" }}
                          className="mb-1"
                          onClick={() =>
                            navigate(
                              "/vehicle-request-list/edit-vehicle-request",
                              {
                                state: request,
                              }
                            )
                          }
                        >
                          Edit
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDeleteModal(true);
                            setStoreVehicleRequestId(request?.id);
                          }}
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-3 border-bottom">
              <div className="row">
                <div className="col-md-6">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Transit Mode :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.transit_mode}
                    </span>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Material Type :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.material_type}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="py-3 border-bottom">
              <div className="row">
                <div className="col-md-6">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Vehicle Size :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.required_vehicle_size}
                    </span>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Material Weight :
                    <span className="text-secondary fw-bold">
                      {request?.material_weight}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="py-3 border-bottom">
              <div className="row">
                <div className="col-md-12">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Loading Address :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.loading_address}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="py-3 border-bottom">
              <div className="row">
                <div className="col-md-12">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Delivery Address :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.delivery_address}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="py-3 border-bottom">
              <div className="row">
                <div className="col-md-12">
                  <p className="m-0 tender-detail" style={{ fontSize: "1rem" }}>
                    Remark :{" "}
                    <span className="text-secondary fw-bold">
                      {request?.remarks}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-secondary text-center mx-3">
            Are you sure, <br /> Do you want to Delete this?
          </h3>
        </Modal.Body>
        <div className="d-flex gap-3 justify-content-center mb-3">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Wait...</p>
            </div>
          ) : (
            <Button
              variant="primary"
              onClick={() => deleteVehicleRequestList(request?.id)}
            >
              Delete
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default VehicleRequestCard;
