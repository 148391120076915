import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import BackgroundLetterAvatars from "./LetterAvatar";
import ImageAvatars from "./ImageAvatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatDate } from "../CommonFunction";
// Updated column definitions

export default function CommonTableExits({ rows, columns, actionfield }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="exits-table mx-3">
      <Paper sx={{ width: "100%", overflow: "hidden",boxShadow:"none" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table className="border-0" stickyHeader aria-label="sticky table">
            <TableHead className="table-head">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{
                      minWidth: column.minWidth,
                      width: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align="left">
                              {/*  */}
                              {column.id === "Employee" ? (
                                <BackgroundLetterAvatars name={row?.Employee} />
                              ) : column.id === "actions" ? (
                                actionfield
                              ) : column.id === "Exit type& Resign date" ? (
                                <>
                                  <h6>
                                    {row?.["Exit Type"]
                                      ? row["Exit Type"]
                                      : "--"}
                                  </h6>
                                  <p>
                                    {row?.["Resign Date"]
                                      ? formatDate(row["Resign Date"])
                                      : "--"}
                                  </p>
                                </>
                              ) : column.id === "Notice Date" ? (
                                row?.["Notice Date"] ? (
                                  formatDate(row["Notice Date"])
                                ) : (
                                  "--"
                                )
                              ) : column.id === "Cancelled By" ? (
                                <>
                                  <h6>{row?.["Cancelled By"]}</h6>
                                  <p>2-9-2020</p>
                                </>
                              ) : (
                                value || "--"
                              )}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
