import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import debounce from "lodash/debounce";
import { errorsMessage } from "../../constant";
const RequistionFormInput = ({
  itemdetail,
  index,
  setItems,
  items,
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  spinner,
}) => {
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [itemList, setItemList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ item_id: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };

  const handleItemChange = (selectedOption, index) => {
    setSelectedItem(selectedOption);

    setItems((prev) => {
      const updateItem = [...prev];
      updateItem[index] = { ...updateItem[index], item_id: selectedOption?.id };

      setFieldValue("items", updateItem);

      return updateItem;
    });
  };
  const getItem = (term) => {
    setLoading(true);
    const url = `${baseUrl}listpurchaseitemmaster`;
    const data = { user_id, search: term };
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setItemList(res?.data?.response?.ItemMasterList);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorsMessage("Please try again later ,Something went wrong");
      });
  };

  useEffect(() => {
    if (selectedItem?.item_id === "") {
      if (mode == "edit") {
        setSelectedItem(itemdetail);
      }
    }
  }, [itemdetail, mode]);

  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      getItem(term);
    }, 600),
    [user_id]
  );
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);
  useEffect(() => {
    if (mode == "edit") {
      getItem(itemdetail?.item_name);
      setItems((prev) => {
        const updateItem = [...prev];
        updateItem[index] = {
          ...updateItem[index],
          item_id: itemdetail?.id,
        };

        setFieldValue("items", updateItem);

        return updateItem;
      });
    }
  }, []);
  return (
    <>
      <div className="col-2">
        <label htmlFor="" className="form-label fw-bold font-16 ">
          Name
        </label>
        <div className="input-group me-3 d-block">
          <Select
            placeholder="Select Item "
            className="form-select-control border-dark"
            options={[{ id: "", product_name: "Select an item" }, ...itemList]}
            getOptionLabel={(itemMasterList) => itemMasterList["product_name"]}
            isLoading={loading}
            getOptionValue={(itemMasterList) => itemMasterList["item_code"]}
            value={
              itemList.find((option) => option.id === items[index].item_id) || {
                id: "",
                product_name: "Select an item",
              }
            }
            onChange={(value) => {
              handleItemChange(value, index);
            }}
            onInputChange={(value) => setSearchTerm(value)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: "lightgrey",
                primary: "lightgrey",
              },
            })}
            styles={customStyles}
          />
        </div>
        <p className="text-danger m-0 ">
          {touched.items &&
          errors.items &&
          errors.items[index] &&
          errors.items[index].item_id
            ? errors.items[index].item_id
            : validationError?.[`items.${index}.item_id`]}
        </p>
      </div>
    </>
  );
};

export default RequistionFormInput;
