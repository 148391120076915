import Accordion from "react-bootstrap/Accordion";
import TenderAdd from "../TenderComponents/Forms/AddTenderDetails";
import TenderAttachments from "../TenderComponents/Forms/AddTenderAttachment";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { errorsMessage } from "../../constant";

function TenderAddPage() {
  const [spinner, setSpinner] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const [validationError , setValidationError] = useState({})
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const navigate = useNavigate();
  const [tenderId, setTendersId] = useState(null);
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [activeAccordionKey, setActiveAccordionKey] = useState("0");

  const changeKey = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };

  function storeTender(value) {
    setSpinner(true);
    let url = baseUrl + "storetender";
    let data = { ...value, user_id, tenderattachements: "" };

    axios
      .post(url, data, config)
      .then((response) => {
        setTendersId(response.data.response.tendersId);
        setSpinner(false);
        toast.success("Added successfully");
        changeKey("1");
        setValidationError({})
      })
      .catch((error) => {
        setSpinner(false);
        // console.log(error)
        setValidationError(error?.response?.data?.response)
        errorsMessage(error?.response?.status)
      });
  }
  function uploadAttachments(values, reset) {
    setSpinner(true);
    let data = { ...values, user_id, tenderId };
    axios
      .post(baseUrl + "storetenderattechments", data, config)
      .then((response) => {
        setSpinner(false);
        reset();
        navigate("/tenders");
        toast.success("Added successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setSpinner(false);
      });
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="row">
            <Accordion activeKey={activeAccordionKey}>
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => changeKey("0")}>
                  <h5 className="fw-bold">ADD TENDER DETAILS</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <TenderAdd storeTender={storeTender} spinner={spinner} validationError={validationError}/>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => changeKey("1")}>
                  <h5 className="fw-bold">ADD TENDER ATTACHMENTS</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <TenderAttachments uploadAttachments={uploadAttachments} spinner={spinner}/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenderAddPage;
