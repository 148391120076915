import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setLoading } from "./LoaderSlice";
import { businessUnitListApi } from "../../../HrDepartment/Organisation/organisationStructure/OrganizationComponents/organizationStructureApi";
const initialState = { businessUnit: [] };
export const fetchBusinessUnit = createAsyncThunk(
  "fetch/businessUnitList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await businessUnitListApi();
      return response?.data?.response?.data;
    } catch (err) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const businessUnitSlice = createSlice({
  name: "businessUnit",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBusinessUnit.pending, (state) => {})
      .addCase(fetchBusinessUnit.fulfilled, (state, action) => {
        state.businessUnit = action.payload;
      })

      .addCase(fetchBusinessUnit.rejected, (state, action) => {});
  },
});
export const getbusinessUnit = (state) => state?.businessUnitList?.businessUnit;

export default businessUnitSlice.reducer;
