import React, { useCallback, useEffect, useState } from "react";
import InventoryDetailPageCard from "./InventoryDetailPageCard";
import InventoryDetailPageLogsTable from "./InventoryDetailPageLogsTable";
import BasicBreadcrumbs from "./BreadCrumbs";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";
import { errorsMessage } from "../../constant";
import { debounce, filter } from "lodash";
import { getInventoryLogsListingApi } from "../commonComponents/storeApi";
import { useLocation } from "react-router-dom";
import { getStoreMasterList } from "../../Purchase/purchaseApi";
import BasicPagination from "../commonComponents/Pagination";

function InventoryDetailPage() {
  const [open, setOpen] = useState(true);
  const [inventoryLogsData, setInventoryLogsData] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    storeId: "",
    startDate: "",
    endDate: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [storeDropdown, setStoreDropdown] = useState([]);
  const [page, setPage] = useState(1);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const state = useLocation();

  function inventoryLogsList(term, page = 1, store_id, sDate, eDate) {
    setOpen(true);
    getInventoryLogsListingApi({
      user_id,
      search: term,
      page: page,
      item_id: state?.state?.itemDetail?.id,
      store_id,
      from: sDate,
      to: eDate,
    })
      .then((res) => {
        setInventoryLogsData(
          res?.data?.response?.InventoryLogsList?.logs?.data
        );
        setPageCount(res?.data?.response?.InventoryItemsList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  }

  function getStoreMasterDropDown() {
    getStoreMasterList({ user_id })
      .then((res) => {
        setStoreDropdown(res?.data?.response?.CityMasterList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  useEffect(() => {
    getStoreMasterDropDown();
  }, []);

  const debouncedFetchInventoryLogsList = useCallback(
    debounce((term, page, storeId, sDate, eDate) => {
      inventoryLogsList(term, page, storeId, sDate, eDate);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchInventoryLogsList(
      filters?.search,
      page,
      filters?.storeId,
      filters?.startDate,
      filters?.endDate
    );
  }, [filters, debouncedFetchInventoryLogsList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  function handleDateChange(name, value) {
    setFilters({ ...filters, [name]: value });
  }

  return (
    <div className="page-wrapper mt-3">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="text-end mt-2">
            <BasicBreadcrumbs />
          </div>
          <InventoryDetailPageCard itemDetail={state?.state?.itemDetail} />
          <InventoryDetailPageLogsTable
            inventoryLogsData={inventoryLogsData}
            filters={filters}
            handleChange={handleChange}
            storeDropdown={storeDropdown}
            handleDateChange={handleDateChange}
            startDate={filters?.startDate}
            endDate={filters?.endDate}
          />
          <SimpleBackdrop open={open} />
          <div className="text-end mt-2">
            <BasicPagination
              handlePaginateChange={handlePaginateChange}
              page={page}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetailPage;
