import React, { useState,useEffect } from "react";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../HrDepartment.scss";
import { useFormik } from "formik";
import { updatePrimaryDetail } from "../../hrApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { Box, CircularProgress } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function EditPrimaryDetail({ handleClose, open, data ,employeeDetail}) {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const [isFocused, setIsFocused] = useState("");
  const[validationError,setValidationError] = useState(null)
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };
  const initialValues = {
    employee_id: data?.id,
   employee_name: "",
    // data?.employee_name,
    gender: "",
    dob: "",
    blood_group: "A+",
    nationality:"",
    is_handicaped: "",
    marital_status:"",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    // handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: addBusinessUnitSchema,
  
    onSubmit: (values) => {
      updateEmployeePrimaryDetail();
    },
  });

  const updateEmployeePrimaryDetail = async () => {
    dispatch(setLoading(true));
    const reqpayload ={employee_id:data?.id,...values}
    try {
      const response = await updatePrimaryDetail(reqpayload);
  handleClose()
  employeeDetail()
      toast.success(response?.data?.response?.message);
    } catch (error) {

      setValidationError(error?.response?.data?.response)
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(()=>{
if(data){
  setValues(data)
}
  },[data,open])

  return (
    <div>
   
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="edit_primary_details">
            Edit Primary Details
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "employee_name" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Name
                </label>
                <div
                  className={`${
                    isFocused == "employee_name"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="employee_name"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    value={values?.employee_name}
                    onChange={handleChange}
                    placeholder="Enter  Name "
                  />
                </div>
              </div>

              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "gender" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Gender
                </label>
                <div
                  className={`${
                    isFocused == "gender"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="gender"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select Gender"
                    value={values?.gender}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "dob" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Date Of Birth
                </label>
                <div
                  className={`${
                    isFocused == "dob"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="date"
                    name="dob"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Enter Date Of Birth"
                    value={values?.dob}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "nationality" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Nationality
                </label>
                <div
                  className={`${
                    isFocused == "nationality"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="nationality"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select Country"
                    onChange={handleChange}
                    value={values?.nationality}
                  >
                    <option>Select</option>
                    <option value="India">India</option>
                  </select>
                </div>
              </div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "is_handicaped" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Physically handicaped
                </label>
                <div
                  className={`${
                    isFocused == "is_handicaped"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="is_handicaped"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select Status"
                    onChange={handleChange}
                    value={values?.is_handicaped}
                  >
                    <option>Select</option>
                    <option value={"Yes"}>yes</option>
                    <option value={"No"}>NO</option>
                  </select>
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "marital_status" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Martial Status
                </label>
                <div
                  className={`${
                    isFocused == "marital_status"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="marital_status"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select Marital Status"
                    onChange={handleChange}
                    value={values?.marital_status}
                  >
                    <option>Select</option>
                    <option value="Married">Married</option>
                    <option value="UnMarried">UnMarried</option>
                    <option value="Divorce">Divorce</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row pt-4 ">
              <div className="col-7"></div>
              <div
                className="col-5 ps-5 "
                style={{ marginLeft: "Auto", width: "auto" }}
              >
                <button
                  type="button"
                  class="btn btn-de-danger btn-sm border-danger"
                  onClick={() => handleClose()}
                  disabled={loader}
                >
                  Cancel
                </button>
                <button
                  type="Submit"
                  class="hr red_button btn btn-primary mx-2"
                  disabled={loader}
                >
                  {loader ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
