import { createSlice } from "@reduxjs/toolkit";
var currentDate = new Date();
var currentMonthFormatted = currentDate.toLocaleString("en-US", {
  month: "2-digit",
  minimumIntegerDigits: 2,
});
var year = currentDate.getFullYear();

export const currentTenderSlice = createSlice({
  name: "currentTender",
  initialState: {
    currentTender: 0,
    currentMonth: currentMonthFormatted,
    currentYear: year,
    biddingStatus: "",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    contractType: "",
    awardedStatus: "",
  },
  reducers: {
    setCurrentTender: (state, action) => {
      state.currentTender = action.payload;
    },
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
    },
    setCurrentYear: (state, action) => {
      state.currentYear = action.payload;
    },
    setBiddingStatus: (state, action) => {
      state.biddingStatus = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setContractType: (state, action) => {
      state.contractType = action.payload;
    },
    setAwardedStatus: (state, action) => {
      state.awardedStatus = action.payload;
    },
  },
});

export const { setCurrentTender } = currentTenderSlice.actions;
export const {
  setCurrentMonth,
  setCurrentYear,
  setBiddingStatus,
  setStartDate,
  setEndDate,
  setContractType,
  setAwardedStatus,
} = currentTenderSlice.actions;

export const selectCurrentTender = (state) => state.currentTender.currentTender;
export const selectCurrentMonth = (state) => state.currentTender.currentMonth;
export const selectCurrentYear = (state) => state.currentTender.currentYear;
export const selectBiddingStatus = (state) => state.currentTender.biddingStatus;
export const selectStartDate = (state) => state.currentTender.startDate;
export const selectEndDate = (state) => state.currentTender.endDate;
export const selectAwardedStatus = (state) => state.currentTender.awardedStatus;
export const selectContractType = (state) => state.currentTender.contractType;

export default currentTenderSlice.reducer;
