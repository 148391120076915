import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";




import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";

import { toast } from "react-toastify";
import { LocalAtmSharp } from "@mui/icons-material";
import { addTimeType, updateTimeType } from "../../hrApi";
import { fetchTimeType } from "../../../redux/features/Hr/TimeTypeSlice";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { timeTypeSchema } from "../EmployeeSchema";

import ShowError from "../../Organisation/organisationStructure/OrganizationComponents/ShowError";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px",
    minWidth: "691px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const AddTimeTypeModal = ({ open, setAddTimeType, editData }) => {
  const[validationError,setValidationError] = useState(null)
  const loader = useSelector(selectLoading);
  const dispatch = useDispatch()
  const handleClose = () => {
    setAddTimeType(false);
  };
  const initialValues = {
    type: "",
    description: "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: timeTypeSchema,
    onSubmit: (values) => {
      if (editData) {
        timeTypeUpdate();
      } else {
        timeTypeAdd();
      }
    },
  });


  useEffect(() => {
    if (editData) {
      setValues(editData);
    } else {
      resetForm();
    }
  }, [editData,open]);
  const timeTypeAdd = async () => {
    dispatch(setLoading(true));
    try {
      const response = await addTimeType(values);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchTimeType());
      handleClose();
    } catch (err) {
      setValidationError(err?.response?.data?.response);

      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };
  const timeTypeUpdate = async () => {
    dispatch(setLoading(true));
    const reqpayload = { id: editData?.id, ...values };
    try {
      const response = await updateTimeType(reqpayload);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchTimeType());
      handleClose();
    } catch (err) {
      setValidationError(err?.response?.data?.response);

      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
   
          { `${editData ? 'Update' : 'Add'}  Type`}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="type"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.type}
                      message={errors.type}
                      validationError={validationError?.type}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      multiline={true}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="large"
                      id=""
                      rows={5}
                      label="Description"
                      name="description"
                      value={values.description}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                       <ShowError
                      touched={touched.description}
                      message={errors.description}
                      validationError={validationError?.description}
                    />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Update Type "
                ) : (
                  "Add Type"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default AddTimeTypeModal;
