import React from "react";
import LegalEntityName from "../OrganizationComponents/OrganizationStructureHeaderName";
import LegalEntityHeaderTabs from "./LegalEntityHeaderTabs";

function LegalEntityHeader({ legalEntity, getEditData }) {
  return (
    <div>
      <LegalEntityName
        name={legalEntity?.entity_name}
        image={legalEntity?.image}
      />
      <LegalEntityHeaderTabs
        legalEntity={legalEntity}
        getEditData={getEditData}
      />
    </div>
  );
}

export default LegalEntityHeader;
