import React from 'react'
import "../HrDepartment.scss"

const SearchBar = () => {
  return (<>


      
        <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center employees-directory mx-4'>
  <span  className='dashboard '>Employees Directory</span>
  <div className='d-flex flex-column flex-lg-row align-items-center justify-content-lg-start mt-lg-0 '>
    <select className="days_selector_style mb-lg-0 mb-3 mr-lg-3">
      <option>Sort By</option>
    </select>
    <select className="days_selector_style mb-lg-0 mb-3 mr-lg-3">
      <option>Accounts</option>
    </select>
    <div className="d-flex align-items-center">
      <input className='days_selector_style mb-lg-2 mb-3 mr-lg-3' type="text" placeholder="Search.." />
      <button className="search_Button" type="submit"><i className="fa fa-search"></i></button>
    </div>
  </div>
</div>




</>     
  )
}

export default SearchBar