import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import { format } from "date-fns";

export default function DateRangePickerExample({
  startDate,
  endDate,
  handleChange,
}) {
  return (
    <div className="d-flex gap-2">
      <DatePicker
        showIcon
        selected={startDate}
        onChange={(date) => handleChange("startDate", date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
        selectsStart
        startDate={startDate}
        endDate={endDate}
        customInput={<StartDatePickerInput />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        isClearable
      />
      <DatePicker
        showIcon
        selected={endDate}
        onChange={(date) => handleChange("endDate", date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        customInput={<EndDatePickerInput />}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={!startDate}
        isClearable
      />
    </div>
  );
}

const inputStyle = {
  height: "2.4rem",
  width: "10rem",
  padding: "0.5rem",
  borderRadius: "4px",
  border: "1px solid #ccc",
  background: "transparent",
  textAlign: "start",
};

const StartDatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button style={inputStyle} onClick={onClick} ref={ref}>
    <SlCalender color="red" style={{ marginLeft: "4px", marginRight: "2px" }} />
    {value || "Start date"}
  </button>
));

const EndDatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button style={inputStyle} onClick={onClick} ref={ref}>
    <SlCalender color="red" style={{ marginLeft: "4px", marginRight: "2px" }} />
    {value || "End date"}
  </button>
));
