import Dropdown from 'react-bootstrap/Dropdown';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
function Exit({setInitiateExitModal}) {
  
  return (
    <Dropdown>
      <Dropdown.Toggle className=' text-black bg-white border-0 W-0' id="dropdown-basic">
      <PiDotsThreeOutlineVerticalFill
                                style={{ cursor: "pointer" }}
                                // onClick={() => {
                                //   setInitiateExit((prev) => !prev);
                                // }}
                              />
      </Dropdown.Toggle>

      <Dropdown.Menu >
        <Dropdown.Item  className='hr-toggle' onClick={()=>setInitiateExitModal(true)}>Initiate Exit</Dropdown.Item >
       
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Exit;