import React from "react";
import Spinner from "react-bootstrap/Spinner";
const ProbationAsideList = ({
  selectedProbationPolicy,
  setSelectedProbationPolicy,
  PolicyItem,loader
}) => {
  return (
    <>
   
          <div className="side-list-container mb-3">
        <div
          className="probation-policy-title p-3"
          onClick={() => setSelectedProbationPolicy(PolicyItem)}
          style={{
            background:
              selectedProbationPolicy?.id === PolicyItem?.id ? "#30384E" : null,
            cursor: "pointer",
            borderRadius: ".5rem",
          }}
        >
          <h6 className="text-white fs-6 p-0 m-0">{PolicyItem?.name}</h6>
          <p className="text-white m-0 pt-1 fw-lighter"> 40 Employees</p>
        </div>
      </div>
    </>
  );
};

export default ProbationAsideList;
