import React, { useEffect, useState } from "react";
import BackOfficeTableTh from "../../Common/BackOfficeTableTh";
import BackOfficeTableTd from "../../Common/BackOfficeTableTd";
import { Link, useNavigate } from "react-router-dom";
import { getProjectWiseCoachFurnishedlist } from "../../../redux/api";
import { errorsMessage } from "../../../constant";

function CoachProductionList({ project_id }) {
  const [loading, setLoading] = useState(false);
  const [coachProductionList, setcoachProductionList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;

  function coachProductionData() {
    setLoading(true);
    getProjectWiseCoachFurnishedlist({ user_id, project_id })
      .then((res) => {
        setcoachProductionList(res?.data?.response?.furnishedCoachList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    coachProductionData();
  }, []);
  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  console.table(coachProductionList);
  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-flex justify-content-end align-items-center mt-4">
            <div className="mx-4">
              <Link
                to={`/projects/projectdetail/add-coachProduction/${project_id}`}
              >
                <button className="btn btn-danger">Add Coach Production</button>
              </Link>
            </div>
          </div>
          <div
            className="table-responsive table-container-scroll"
            style={{ overflowX: "auto", marginTop: "2rem" }}
          >
            <table
              className="table table-striped sd-list-table"
              style={{ tableLayout: "unset" }}
            >
              <thead className="thead-light">
                <tr>
                  <BackOfficeTableTh
                    value="S No."
                    width="5rem "
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Furnishing No./ Railway No./ New Coach No."
                    width="13rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Shell No. /  Old Coach No."
                    width="13rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Coach allotted to"
                    width="13rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Allotment Date"
                    width="13rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Completion Date (Furnishing)"
                    width="13rem  "
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Completion Date (Electrical)"
                    width=" 13rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Dispatch Date"
                    width="13rem "
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Coach Warranty Period (As per IR GCC)"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="UOM"
                    width="13rem "
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Warranty Expiry Date (3 Yrs)"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="PU Foam Warranty period"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="PU Foam Warranty Expiry Date (10 Yrs)"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Completion certification"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Allotment certification"
                    width="16rem"
                  ></BackOfficeTableTh>
                  <BackOfficeTableTh
                    value="Action"
                    width="8rem"
                  ></BackOfficeTableTh>
                </tr>
              </thead>
              <tbody class="table-body">
                {coachProductionList?.map((data, i) => (
                  <tr key={i}>
                    <BackOfficeTableTd
                      value={++i}
                      width="5rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.furnishingNo}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.coachNo}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.coachAllottedTo}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.allotmentDate)}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.completionDate_Furnishing)}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.completionDate_Electrical)}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.dispatchDate)}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.coachWarrantyPeriod_IR_GCC}
                      width="16rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.UOM}
                      width="13rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.warrentyExpiryDate)}
                      width="16rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={data.puPeriod}
                      width="16rem"
                    ></BackOfficeTableTd>
                    <BackOfficeTableTd
                      value={formatDate(data.puWarrantyDate)}
                      width="16rem"
                    ></BackOfficeTableTd>
                    <td style={{ width: "8rem" }}>
                      {data.CompletionCertification ? (
                        <Link
                          to={baseURL + data.CompletionCertification}
                          target="_blank"
                        >
                          <h6>Compl. Cert. Docs.</h6>
                        </Link>
                      ) : (
                        "- - -"
                      )}
                    </td>
                    <td style={{ width: "8rem" }}>
                      {data.AllotmentCertification ? (
                        <Link
                          to={baseURL + data.AllotmentCertification}
                          target="_blank"
                        >
                          <h6>Allot. cert. Docs.</h6>
                        </Link>
                      ) : (
                        "- - -"
                      )}
                    </td>
                    <td style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                      <i
                        className="las la-pen text-dark font-20 px-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/edit-coachProduction", {
                            state: data,
                          });
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachProductionList;
