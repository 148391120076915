import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import TenderStatusList from "../TenderComponents/TenderStatusList";
import AddTenderTicket from "../TenderComponents/Forms/AddTenderTicket";
import axios from "axios";
import EditTenderTicket from "../TenderComponents/Forms/EditTenderTicket";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../redux/features/allDropdownSlice";
import { selectCurrentTender } from "../TenderSlice";
import { useDispatch } from "react-redux";
import { setCurrentTender } from "../TenderSlice";
import "../TenderStyle/TenderStyle.css";
import { getBoardGroup } from "../../redux/api";

function TenderKanban() {
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [tenderTickets, setTenderTickets] = useState(true);
  const [editTicketModal, setEditTicketModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const [boardGroup, setBoardGroup] = useState([]);
  const [ticketId, setTicketId] = useState(0);
  const [tenderDetails, setTenderDetails] = useState({});
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const idFromParam = param?.id;
  const allDropdowns = useSelector(selectDropdowns);
  const tenderId = useSelector(selectCurrentTender);
  const tenderList = allDropdowns.tenderList;

  function handleClose() {
    setShowTicketModal(false);
    setEditTicketModal(false);
    setTicketId(0);
  }

  function getKanbanTicket() {
    let url = baseUrl + "gettenderboardtickets";
    let data = { user_id, tender_id: tenderId };
    axios
      .post(url, data, config)
      .then(async (response) => {
        let ticketList = await response?.data?.response?.BoardTenderTicketsList;
        setTenderDetails(ticketList.tenderDetails);
        delete ticketList.tenderDetails;
        setTenderTickets(ticketList);
      })
      .catch((error) => {});
  }

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollWidth = scrollRef.current.scrollWidth;
      const maxScroll = scrollWidth - scrollRef.current.clientWidth;
      const scrollBy = 0.5 * maxScroll;
      scrollRef.current.scrollBy({
        left: scrollBy,
        behavior: 'smooth',
      });
    }
  };

  function getBoardGroupDropdown() {
    getBoardGroup({ user_id: user_id })
      .then((response) => {
        setBoardGroup(response?.data?.response?.groupDropDown);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (tenderId || idFromParam) {
      getKanbanTicket();
    }
  }, [tenderId]);

  useEffect(() => {
    if (idFromParam) {
      dispatch(setCurrentTender(param.id));
    }
  }, [idFromParam]);

  useEffect(() => {
    getBoardGroupDropdown();
  }, []);

  return (
    <div>
      <div>
        <div className="page-wrapper">
          <div className="page-content-tab">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box pb-0">
                    <div className="float-end"></div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6">
                  <h4 className="m-0" style={{ fontWeight: "600" }}>
                    TENDER KANBAN
                  </h4>
                </div>

                <div className="col-lg-6 text-end">
                  <div className="text-end">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div className="input-group">
                          <select
                            className="form-select border-danger"
                            id=""
                            value={tenderId}
                            onChange={(e) => {
                              dispatch(setCurrentTender(e.target.value));
                              navigate(`/tender-kanban/${e.target.value}`);
                            }}
                          >
                            <option value="">Select Tender</option>
                            {tenderList?.map((tender) => {
                              return (
                                <option key={tender.id} value={tender.id}>
                                  {tender.tenderNo}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </li>
                      <li className="list-inline-item">
                        <Link to="">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => setShowTicketModal(true)}
                          >
                            Create Ticket
                          </button>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card tender-kanban">
                <div className="card-body">
                  <div className="row">
                    <section>
                      <div className="container-fluid">
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <h4>
                              {tenderDetails.itemDecsription}{" "}
                              {/* <span className="font-14">
                                ({formatDate(tenderDetails.publishedDate)} -{" "}
                                {formatDate(tenderDetails.closingDate)})
                              </span> */}
                            </h4>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-3"> </div>
                              <div className="col-md-3"> </div>

                              <>
                                <div className="col-md-3"></div>
                                <div className="col-md-3 text-end"></div>
                              </>
                            </div>
                          </div>
                        </div>

                        <div className="kanban-board" ref={scrollRef}>
                          {Object.keys(tenderTickets).map((key) => (
                            <TenderStatusList
                              stage={key}
                              tickets={tenderTickets[key]}
                              setEditTicketModal={setEditTicketModal}
                              setTicketId={setTicketId}
                              getTenderKanban={getKanbanTicket}
                              boardGroupList={boardGroup}
                              handleScroll={handleScroll}
                            ></TenderStatusList>
                          ))}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddTenderTicket
        showTicketModal={showTicketModal}
        setShowTicketModal={setShowTicketModal}
        getKanbanTicket={getKanbanTicket}
        boardGroup={boardGroup}
      ></AddTenderTicket>

      <EditTenderTicket
        editTicketModal={editTicketModal}
        setEditTicketModal={setEditTicketModal}
        getKanbanTicket={getKanbanTicket}
        handleClose={handleClose}
        ticketId={ticketId}
        boardGroup={boardGroup}
      ></EditTenderTicket>
    </div>
  );
}

export default TenderKanban;
