import axios from "axios";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { errorsMessage } from "../../constant";
import { useEffect, useState } from "react";
import { RiPassPendingFill } from "react-icons/ri";

function BillingupdatePayment({
  show,
  setShow,
  billingData,
  fetchBillingPayment,
  setFetchBillingPayment,
}) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const value = { amount: "", date: "" };
  const initialValue = {
    pending_details: [value],
  };

  const [pendingPayment, setPendingPayment] = useState({
    pending_details: [value],
  });
  function listBillingDetail() {
    const apiUrl = baseUrl + "listprojectbillingpartialpayment";
    const data = {
      user_id,
      project_billing_id: billingData.id,
    };
    // setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.data.response.pending_details.length !== 0) {

          setPendingPayment(response.data.response);
          setValues(response.data.response);
        }
      })
      .catch((error) => {
        // setServerSideValidation(error?.response?.data?.response);
        // errorsMessage(error?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    listBillingDetail();
  }, [billingData]);
  function addPendingPaymentData(values) {
    const apiUrl = baseUrl + "addprojectbillingpartialpayment";
    const data = {
      user_id,
      ...values,
      project_billing_id: billingData.id,
    };
    // setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          listBillingDetail();
          setFetchBillingPayment(!fetchBillingPayment);
          handleClose();
          toast.success("Form submit successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        // setServerSideValidation(error?.response?.data?.response);
        // errorsMessage(error?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValue,
    // validationSchema: itemMasterSchema,
    onSubmit: (values) => {
      addPendingPaymentData(values);
    },
  });
  function handleChangeMultiple(e, i) {
    const { name, value } = e.target;

    const newPendingDetails = [...values.pending_details];

    newPendingDetails[i] = {
      ...newPendingDetails[i],
      [name]: value,
    };

    setValues({
      ...values,
      pending_details: newPendingDetails,
    });
    setPendingPayment({
      ...pendingPayment,
      pending_details: newPendingDetails,
    });
  }

  function addFields() {
    let updatedBillingData = { ...pendingPayment };

    let singleBillPendingDetails = [...updatedBillingData.pending_details];

    singleBillPendingDetails.push({ ...value });

    updatedBillingData.pending_details = singleBillPendingDetails;

    setPendingPayment(updatedBillingData);

    setValues(updatedBillingData);
  }
  const removeBillingData = (i) => {
    const updatedData = { ...pendingPayment };

    const deleteVal = [...updatedData.pending_details];

    deleteVal.splice(i, 1);

    setPendingPayment({
      ...updatedData,
      pending_details: deleteVal,
    });

    setValues({
      ...values,
      pending_details: deleteVal,
    });
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton centered>
            <Modal.Title className="font-16 m-0">Payment tracker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-3 ">
              <form onSubmit={handleSubmit}>
                {pendingPayment?.pending_details?.map((element, i) => (
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        {" "}
                        Date
                      </label>
                      <input
                        className="form-control border-secondary"
                        type="date"
                        value={element.date}
                        name="date"
                        onChange={(e) => handleChangeMultiple(e, i)}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          {" "}
                          Amount Received
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Amount Received"
                          value={element.amount}
                          name="amount"
                          onChange={(e) => handleChangeMultiple(e, i)}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="" className="form-label">
                        Action
                      </label>
                      <div>
                        <button
                          type="button"
                          className="btn btn-success me-2 fw-bolder"
                          onClick={() => addFields()}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger fw-bolder"
                          onClick={() => removeBillingData(i)}
                          hidden={i === 0}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="text-end">
                  <button className=" btn btn-danger">Submit</button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default BillingupdatePayment;
