import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

function EditDesignation() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const editUser = location?.state?.editUser ?? ""
  const [name , setName] =useState(editUser.name)
  const designationId = editUser.id  
  const [nameError , setNameError]= useState('')
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;

  
  const handleSubmit = e => {
    e.preventDefault()
    const url = baseUrl + 'editdesignation'
    const data = {
        user_id, designationId, name
    }
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    axios.post(url,data, {
        headers: {
            'Authorization': bearer_token,
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        // return  response;
        if (response.data.success) {               
            navigate("/designation");
        }
    })
    .catch((error) => {
        if (error.response.data.message === 'Error validation') {
         setNameError(error.response.data.response.name);
       }
     
    });
 }

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="#!">Projects /</Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/designation">Designation/</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Designation</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Designation</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Dedignation
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter name"
                              maxLength={40}
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                             <p style={{ color: "red" }}>{nameError}</p>               
                          </div>
                        </div>
                    
                      </div>
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm me-3"
                        >
                          Update
                        </button>
                        <Link to="/designation">
                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm"
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditDesignation;
