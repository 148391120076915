import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useURLFilters = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    contractType: "",
    endDate: "",
    month: "",
    search: "",
    startDate: "",
    year: "",
    biddingStatus: "",
    awardedStatus: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const parsedFilters = {
      search: searchParams.get("search") || "",
      contractType: searchParams.get("contractType") || "",
      endDate: searchParams.get("endDate"),
      month: searchParams.get("month") || "",
      startDate: searchParams.get("startDate"),
      year: searchParams.get("year") || "",
      biddingStatus: searchParams.get("biddingStatus") || "",
      awardedStatus: searchParams.get("awardedStatus") || "",
    };
    setFilters(parsedFilters);
  }, [location.search]);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    const searchParams = new URLSearchParams();
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]) {
        if (Array.isArray(newFilters[key])) {
          searchParams.set(key, newFilters[key].join(","));
        } else {
          searchParams.set(key, newFilters[key]);
        }
      }
    });
    const newSearch = searchParams.toString();
    return newSearch;
  };

  return [filters, updateFilters];
};

export default useURLFilters;
