import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function BackOfficeTableTd({ value, width }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <td style={{ minWidth: width, maxWidth: width }}>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 100 }}
          overlay={renderTooltip(value)}
        >
          <h6>{value ? value : "---"}</h6>
        </OverlayTrigger>
      </td>
    </>
  );
}

export default BackOfficeTableTd;
