import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import CommonInput from "./CommonInput";
import CommonOptions from "./CommonOptions";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sdvalidationSchema } from "./TdcEmdSchema";

function SdDetail({ renderState, setRenderState }) {
  const param = useParams();
  const project_id = Number(param.id);
  const [spinner, setSpinner] = useState(false);
  const billingContainerRef = useRef(null);
  const [index, setIndex] = useState(0);

  const sdInitial = {
    sd_toPay: 0,
    sd_paidExempted: "",
    sd_paidTransactionNo: "",
    sd_paidTransactionNoDate: "",
    sd_expiryDate: "",
    sd_returnDate: "",
    sd_returnAmount: 0,
    sd_due: 0,
    sd_remark: "",
    sd_attachement: [],
    row_id: "",
    sd_returnStatus: "",
  };

  function saveDataOnserver(url, value, project_id) {
    const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    setSpinner(true);
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(
        apiurl + url,
        { ...value, user_id, project_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setRenderState(!renderState);
          setSpinner(false);
          resetForm()
          setSdMultipleData([sdInitial])
          toast.success("Data added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
      });
    return spinner;
  }

  const [sdMultipleData, setSdMultipleData] = useState([sdInitial]);

  const initialValues = {
    sdMultipleData: [sdInitial],
  };

  const handleFileChange = (e, i) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        const newValues = [...values.sdMultipleData];
        newValues[i].sd_attachement = filesArray;
        setFieldValue("sd_attachement", filesArray);
      }
    );
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  function multHandleChange(e, i) {
    setIndex(i);
    const { name, value } = e.target;
    const newValues = [...values.sdMultipleData];
    newValues[i] = {
      ...newValues[i],
      [name]: value,
    };
    setValues({
      ...values,
      sdMultipleData: newValues,
    });
  }

  function numberMultHandleChange(e, i) {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {
      const newValues = [...values.sdMultipleData];
      newValues[i][name] = e.target.value;
      setFieldValue(name, value);
    }
  }
  function addSdMultipleData() {

    setSdMultipleData([...sdMultipleData, sdInitial]);
    setValues({
      ...values,
      sdMultipleData: [...values.sdMultipleData, sdInitial],
    });
    if (billingContainerRef.current) {
      billingContainerRef.current.scrollLeft = 0;
      billingContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }
  function removeSdInput(i) {
    const deleteInput = [...values.sdMultipleData];
    deleteInput.splice(i, 1);
    setValues({ ...values, sdMultipleData: deleteInput });
    setSdMultipleData(deleteInput);
  }
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    // touched,
    setValues,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: sdvalidationSchema,
    onSubmit: (values) => {
      saveDataOnserver("storeuntiprojectsd", values, project_id);
    },
  });
  useEffect(() => {
    if (
      values?.sdMultipleData[index]?.sd_paidExempted === "Not Applicable" ||
      values?.sdMultipleData[index]?.sd_paidExempted === "Exempted"
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        sdMultipleData: [
          ...prevValues.sdMultipleData.slice(0, index),
          {
            ...prevValues.sdMultipleData[index],
            sd_toPay: 0,
            sd_paidTransactionNo: "",
            sd_paidTransactionNoDate: "",
            sd_expiryDate: "",
            sd_returnDate: "",
            sd_returnAmount: 0,
            sd_remark: "",
            sd_attachement: [],
          },
          ...prevValues.sdMultipleData.slice(index + 1),
        ],
      }));
    }
  }, [values?.sdMultipleData[index]?.sd_paidExempted]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div
          ref={billingContainerRef}
          className="row mb-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 12rem)",
            gridGap: "1rem",
            overflowY: "hidden",
            overflowX: "scroll",
            paddingBlock: "18px 24px",
          }}
        >
          {sdMultipleData.map((_, i) => (
            <React.Fragment key={i}>
              <div className="form-group mb-12 col">
                <CommonOptions
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values?.sdMultipleData[i]?.sd_paidExempted}
                  name="sd_paidExempted"
                  labelValue="Paid/Exempted"
                  handleBlur={handleBlur}
                />
                <p className="text-danger">
                  {Object.keys(errors).length !== 0
                    ? errors?.sdMultipleData[i]?.sd_paidExempted
                    : null}
                </p>
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => numberMultHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_toPay}
                  type="text"
                  name="sd_toPay"
                  labelValue="Amount"
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>
              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_paidTransactionNo}
                  type="text"
                  name="sd_paidTransactionNo"
                  labelValue="Transaction No."
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  placeHolder={"SD Paid Transaction no."}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>
              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_paidTransactionNoDate}
                  type="date"
                  name="sd_paidTransactionNoDate"
                  labelValue="Transaction Date"
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_expiryDate}
                  type="date"
                  name="sd_expiryDate"
                  labelValue="Expiry Date"
                  handleBlur={handleBlur}
                  //  touched={touched}
                  errors={errors}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>
              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_returnDate}
                  type="date"
                  name="sd_returnDate"
                  labelValue="Return Date"
                  handleBlur={handleBlur}
                  //  touched={touched}
                  errors={errors}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => numberMultHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_returnAmount}
                  type="text"
                  name="sd_returnAmount"
                  labelValue="Return Amount"
                  handleBlur={handleBlur}
                  //  touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  values={
                    (values.sdMultipleData[i].sd_due =
                      values.sdMultipleData[i].sd_toPay -
                      values.sdMultipleData[i].sd_returnAmount)
                  }
                  type="text"
                  name="sd_due"
                  labelValue="Due Amount"
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                  handleChange={(e) => multHandleChange(e, i)}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  values={
                    (values.sdMultipleData[i].sd_returnStatus =
                      values.sdMultipleData[i].sd_due !== 0
                        ? "Pending"
                        : "Completed")
                  }
                  type="text"
                  name="sd_returnStatus"
                  labelValue="Return Status"
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                  handleChange={(e) => multHandleChange(e, i)}
                  className={
                    values.sdMultipleData[i].sd_due !== 0
                      ? "bg-danger"
                      : "bg-success"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => multHandleChange(e, i)}
                  values={values.sdMultipleData[i].sd_remark}
                  type="text"
                  name="sd_remark"
                  labelValue="Remark"
                  handleBlur={handleBlur}
                  // touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                  placeHolder={"SD Remark"}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>

              <div className="form-group mb-12 col">
                <CommonInput
                  handleChange={(e) => handleFileChange(e, i)}
                  type="file"
                  name="sd_attachement"
                  labelValue="Attachment"
                  handleBlur={handleBlur}
                  //  touched={touched}
                  errors={errors}
                  defaultValues={values.sd_attachement}
                  disabled={
                    values?.sdMultipleData[i]?.sd_paidExempted === "Exempted" ||
                    values?.sdMultipleData[i]?.sd_paidExempted ===
                      "Not Applicable"
                  }
                />
              </div>
              {/* <div className="form-group mb-12 col ">
                <label htmlFor="" className="form-label">
                  Attachment Preview
                </label>

                <p
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    width: "50px",
                  }}
                  onClick={() => {
                    setModalShow(true)}}
                >
                  <FaEye />
                </p>
                <Modals
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                   data={data.length ?  data[i].sd_attachement:[] }
                />
              </div> */}
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Action
                </label>
                <div>
                  <button
                    className="btn btn-success btn-sm"
                    type="button"
                    onClick={() => addSdMultipleData()}
                  >
                    +
                  </button>
                  <button
                    className="btn btn-danger btn-sm ms-3"
                    type="button"
                    hidden={i === 0}
                    onClick={() => removeSdInput(i)}
                  >
                    -
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="text-end mt-3">
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Updating ...</p>
            </div>
          ) : (
            <button className="btn btn-danger">Save</button>
          )}
        </div>
      </div>
    </form>
  );
}

export default SdDetail;
