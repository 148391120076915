import * as Yup from "yup";

export const basicDetailSchema = Yup.object({
 name: Yup.string().required("Please enter first name"),
  
  //   department_id: Yup.string().required("Please enter department name"),
  gender: Yup.string().required("Please enter gender"),
  mobile: Yup.string().required("Please enter mobile")
  .required('Please enter mobile')
  .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
  email: Yup.string().required("Please enter email")
  .email('Invalid email format'),
  dob: Yup.string().required("Please enter dob"),
  worker_type: Yup.string().required("Please enter worker type"),
  employee_number: Yup.string().required("Please enter employee number"),
});

export const jobDetailSchema = Yup.object({
  joining_date: Yup.string().required("Please enter joining date "),
  job_title: Yup.string().required("Please enter  job title"),
  department_id: Yup.string().required("Please enter department"),
  reporting_person: Yup.string().required("Please enter reporting person"),
  time_type: Yup.string().required("Please enter time type"),
  location: Yup.string().required("Please enter location"),
  probation_policy: Yup.string().required("Please enter probation policy"),
  notice_period: Yup.string().required("Please enter notice period"),
});

export const compensationSchema = Yup.object({
  pay_group: Yup.string().required("Please enter pay group"),
  annual_salary: Yup.string().required("Please enter annual salary"),
  
  salary_structure: Yup.string().required("Please enter salary structure"),
  tax_regime: Yup.string().required("Please enter tax regime"),
});

export const workDetailSchema = Yup.object({
  leave_plan: Yup.string().required("Please enter  leave plan"),
  shift: Yup.string().required("Please enter  shift"),
  weekly_off: Yup.string().required("Please enter weekly off"),
  holiday_list: Yup.string().required("Please enter holiday list"),
  holiday_tracking: Yup.string().required("Please enter holiday tracking"),
});
