import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function UserRolesModal({ show, handleclose, MyVerticallyCenteredModal }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleclose}
        {...MyVerticallyCenteredModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="mb-0">
            Role Name
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              {/* <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="" className="form-label">
                   Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder=""
                  />
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="designation" className="form-label">
                    People in this role can:
                  </label>
                  <select className="form-control" name="designation">
                    <option value="">a</option>
                    <option>b</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="designation" className="form-label">
                    Assign this Role to
                  </label>
                  <select className="form-control" name="designation">
                    <option value="">a</option>
                    <option>b</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    name="description"
                    autoComplete="off"
                    placeholder="Max Limit 400"
                    className="form-control"
                    row="5"
                  ></textarea>
                  {/* <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            placeholder= "Description"
                            onChange={(event, editor) => {
                            const data = editor.getData();
                        setDescription(data)
                     }}        
                /> */}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleclose}>
            Discard
          </Button>
          {/* <Button variant="danger">Add</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserRolesModal;
