import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

function CommonPurchaseDelete({
  updatedList,
  group_id,
  url,
  groupDelete,
  idKey,
}) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const [spinner, setSpinner] = useState(false);
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };

  function deleteData(id) {
    axios
      .post(baseUrl + url, { user_id, [idKey]: id }, config)
      .then((response) => {
        if (response.data.success) {
          //   setSpinner(false);
          updatedList();
          toast.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        // setSpinner(false);
        toast.error("Something went wrong");
      });
  }

  return (
    <div className="modal fade" id={groupDelete}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body py-5 text-center">
            <h4 className="lh-base mb-4">
              Are you sure you want to <br /> delete this record
            </h4>
            <button
              type="button"
              className="btn btn-success me-3"
              onClick={() => deleteData(group_id)}
              data-bs-dismiss="modal"
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonPurchaseDelete;
