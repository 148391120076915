import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Formik, useFormik } from "formik";
import { editTicketSchema } from "../schemas/Index";
import { getTicketTypeList, getBoardGroup } from "../redux/api";
import Comments from "./Comments";

export default function EditTicketModal({
  showEditModal,
  handleClose,
  editTicketId,
  lotData,
  kanbanSchedule,
  projectMembers,
}) {
  const [validationError, setValidationError] = useState(null);
  const [loader, setLoader] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [ticketTypeDropdown, setTicketTypeDropdown] = useState([]);
  const [boardGroup, setBoardGroup] = useState([]);
  const [groupStatus, setGroupStatus] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const param = useParams();
  const tableBottomRef = useRef();
  const [maxEndDate, setMaxEndDate] = useState("");
  const [minStartDate, setMinStartDate] = useState("");
  let project_id = Number(param.id);
  const scrollToBottom = () => {
    tableBottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  let initialValues = {
    lot_id: "",
    ticket_type: "8",
    board_group_id: "",
    title: "",
    description: "",
    priority: "",
    estimated_time: "1",
    reporting_to: "",
    start_date: "",
    due_date: "",
    assignee: "",
    required_qty: "20",
    ticket_attachement: "",
    board_group_status_id: "",
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editTicketSchema,
    onSubmit: (values) => {
      // console.log("Clicked");
      updateTicket(values);
    },
  });

  async function getEditData() {
    if (editTicketId !== null) {
      const url = baseUrl + "getedituntiticketdata";
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const data = {
        user_id: user_id,
        ticketId: editTicketId,
        project_id,
      };
      await axios
        .post(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            let allData = res?.data?.response?.UntiticketData;
            let startDate = new Date(
              res?.data?.response?.UntiticketData?.start_date
            );
            let endDate = new Date(
              res?.data?.response?.UntiticketData?.due_date
            );
            let data = {
              ...allData,
              start_date: startDate,
              due_date: endDate,
              completed_qty: 0,
            };

            setValues(data);
          }
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  }

  function getDropdowns() {
    if (showEditModal) {
      getTicketTypeList({ user_id: user_id })
        .then((response) => {
          setTicketTypeDropdown(response?.data?.response?.TicketTypeList);
        })
        .catch((err) => {});

      getBoardGroup({ user_id: user_id })
        .then((response) => {
          setBoardGroup(response?.data?.response?.groupDropDown);
        })
        .catch((err) => {});
    }
  }

  function getboardgroupstatusdropdown() {
    if (showEditModal) {
      const url = baseUrl + "getboardgroupstatusdropdown";
      let data = { user_id, board_group_id: values.board_group_id };
      const getToken = user?.response?.token;
      const bearer_token = `Bearer ${getToken}`;
      axios
        .post(url, data, {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setGroupStatus(response?.data?.response?.statusDropDown);
        })
        .catch((error) => {});
    }
  }

  useEffect(() => {
    if (showEditModal) {
      getEditData();
    }
    resetForm();
    getDropdowns();
    setValidationError("");
    setLoader(true);
  }, [showEditModal]);

  let startDate = values.start_date
    ? `${values.start_date.getFullYear()}-${
        values.start_date.getMonth() + 1
      }-${values.start_date.getDate()}`
    : "";
  let dueDate = values.due_date
    ? `${values.due_date.getFullYear()}-${
        values.due_date.getMonth() + 1
      }-${values.due_date.getDate()}`
    : "";

  function updateTicket(value) {
    setSpinner(true);
    const url = baseUrl + "updateuntiticket";
    const data = {
      ...value,
      user_id,
      ticketId: editTicketId,
      start_date: startDate,
      due_date: dueDate,
      project_id,
    };

    const config = {
      headers: {
        Authorization: bearer_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Ticket Updated Successfully");
          setSpinner(false);
          resetForm();
          // getScheduleList();
          handleClose();
          kanbanSchedule();
          setValidationError("");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
        setSpinner(false);
      });
  }

  function getDate(id) {
    if (values.lot_id) {
      let lot = lotData.find((e) => e.id === +id);
      setMaxEndDate(lot.completionDate);
      setMinStartDate(lot.startDate);
    }
  }

  function boardGrouphandleChange(e) {
    const { name, value } = e.target;
    setFieldValue("board_group_status_id", "");
    setFieldValue([name], value);
  }

  useEffect(() => {
    getDate(values.lot_id);
  }, [values.lot_id]);

  useEffect(() => {
    if (values.board_group_id) {
      getboardgroupstatusdropdown();
    }
  }, [values.board_group_id]);

  return (
    <div>
      <Modal show={showEditModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <h4 className="m-0 text-white">Update ticket</h4>
        </Modal.Header>
        <Modal.Body>
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "100vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <div
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: "35rem",
              }}
            >
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className="col-md-7"
                    style={{ maxHeight: "600px", overflowY: "scroll" }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label">Select LOT</label>
                          <select
                            className="form-select border-secondary "
                            name="lot_id"
                            value={values.lot_id}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected hidden>
                              Select a LOT
                            </option>
                            {lotData?.map((e, i) => {
                              return (
                                <option value={e.id} key={i}>
                                  {`LOT ${i + 1} (${e.unit})`}
                                </option>
                              );
                            })}
                          </select>
                          <p className="m-0 text-danger">{errors.lot_id}</p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group ">
                          <label className="form-label">Ticket Title</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control border-secondary"
                            placeholder="Enter Ticket"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <p className="m-0 text-danger">{errors.title}</p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Board Group</label>
                        <select
                          className="form-select border-secondary"
                          name="board_group_id"
                          onBlur={handleBlur}
                          value={values.board_group_id}
                          onChange={boardGrouphandleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select Board Group
                          </option>
                          {boardGroup.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {element.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.board_group_id && errors.board_group_id ? (
                          <p className="text-danger m-0">
                            {errors.board_group_id}
                          </p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.board_group_id}
                          </p>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Board Group Status</label>
                        <select
                          className="form-select border-secondary"
                          name="board_group_status_id"
                          onBlur={handleBlur}
                          value={values.board_group_status_id}
                          onChange={handleChange}
                        >
                          <option value="" selected hidden>
                            Select
                          </option>
                          {groupStatus.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {element.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.board_group_status_id &&
                        errors.board_group_status_id ? (
                          <p className="text-danger m-0">
                            {errors.board_group_status_id}
                          </p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.board_group_status_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        name="description"
                        rows={4}
                        className="form-control border-secondary"
                        value={values.description}
                        placeholder="Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="m-0 text-danger">{errors.description}</p>
                    </div>
                  </div>
                  <div className="col-md-5 bg-light py-2">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="mt-0">Details</h5>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group d-flex align-items-sm-center">
                          <label htmlFor="Sprint" className="form-label w-50">
                            Asignee
                          </label>
                          <div className="w-100 d-block">
                            <select
                              className="form-select border-secondary"
                              id=""
                              name="assignee"
                              value={values.assignee}
                              onChange={handleChange}
                            >
                              <option value="" disabled selected hidden>
                                Select a Assignee
                              </option>
                              {projectMembers?.map((e, i) => {
                                return (
                                  <option value={e.user_id} key={i}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="m-0 text-danger">{errors.assignee}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group d-flex align-items-sm-center">
                          <label
                            htmlFor="StartDate"
                            className="form-label w-50"
                          >
                            Start Date
                          </label>
                          <div className="w-100 d-block">
                            <DatePicker
                              type="date"
                              name="start_date"
                              className="form-control border-secondary w-100"
                              placeholderText="09/25/2023"
                              selected={values.start_date}
                              onChange={(date) =>
                                setFieldValue("start_date", date)
                              }
                              selectsStart
                              dateFormat="dd/MM/yyyy"
                              // startDate={values.start_date}
                              // endDate={values.due_date}
                              minDate={new Date(minStartDate)}
                              maxDate={new Date(maxEndDate)}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <p className="m-0 text-danger">
                              {errors.start_date}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group d-flex align-items-sm-center">
                          <label
                            htmlFor="CompletionDate"
                            className="form-label w-50"
                          >
                            Due Date
                          </label>
                          <div className="w-100 d-block">
                            <DatePicker
                              type="date"
                              className="form-control border-secondary w-100"
                              name="due_date"
                              placeholderText="09/30/2023"
                              selected={values.due_date}
                              onChange={(date) =>
                                setFieldValue("due_date", date)
                              }
                              selectsEnd
                              // startDate={values.start_date}
                              // endDate={values.due_date}
                              minDate={new Date(minStartDate)}
                              // maxDate={new Date(maxEndDate)}
                              dateFormat="dd/MM/yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <p className="m-0 text-danger">{errors.due_date}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group d-flex">
                          <label htmlFor="Sprint" className="form-label w-50">
                            Priority
                          </label>
                          <div className="w-100 d-block">
                            <select
                              className="form-select border-secondary "
                              name="priority"
                              value={values.priority}
                              onChange={handleChange}
                            >
                              <option value="" disabled selected hidden>
                                Select Ticket Priority
                              </option>
                              <option value="critical">Critical</option>
                              <option value="high">High</option>
                              <option value="medium">Medium</option>
                              <option value="low">Low</option>
                              <option value="future">Future</option>
                            </select>
                            <p className="m-0 text-danger">{errors.priority}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="form-group d-flex">
                          <label htmlFor="Sprint" className="form-label w-50">
                            Estimated Time
                          </label>
                          <div className="w-100 d-block">
                            <input
                              type="type"
                              name="estimated_time"
                              className="form-control border-secondary w-100"
                              placeholder="In Hours"
                              value={values.estimated_time}
                              onChange={handleChange}
                            />
                            <p className="m-0 text-danger">
                              {errors.estimated_time}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="form-group d-flex align-items-sm-center">
                          <label htmlFor="Sprint" className="form-label w-50">
                            Reporting To
                          </label>
                          <div className="w-100 d-block">
                            <select
                              className="form-select border-secondary mb-3"
                              id=""
                              name="reporting_to"
                              value={values.reporting_to}
                              onChange={handleChange}
                            >
                              <option value="" disabled selected hidden>
                                Select a Reporting Person
                              </option>
                              {projectMembers?.map((e, i) => {
                                return (
                                  <option value={e.user_id} key={i}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="m-0 text-danger">
                              {errors.reporting_to}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  {spinner ? (
                    <div className="">
                      <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                      <p>Updating Ticket...</p>
                    </div>
                  ) : (
                    <Button variant="primary" type="submit">
                      Update Ticket
                    </Button>
                  )}
                </Modal.Footer>
              </form>

              <Comments
                handleClose={handleClose}
                editTicketId={editTicketId}
                scrollToBottom={scrollToBottom}
              />
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer  style={{ background: '#0f172a!important' }} className="p-0" >
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
        <div ref={tableBottomRef} />
      </Modal>
    </div>
  );
}
