import React from "react";
import Footer from "../Components/Footer";
import TenderDashboard from "../Tender/Pages/TenderDashboard";
import BackOfficeDashboard from "../Backoffice/BackofficeComponents/Trackers/BackOfficeDashboard";
import { Navigate } from "react-router-dom";
import StoreDashboard from "../StoreDepartment/StoreDashboard";
import PurchaseDashboard from "../Purchase/PurchaseComponents/PurchaseDashboard";
import OrganistaionDashboard from "../HrDepartment/Organisation/OrganisationDashboard";
function Index() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const department = user?.response.userDetails.departmentName;
  console.log("department",department);

  return (
    <>
      {department === "Tender" ? (
        <TenderDashboard />
      ) : department === "Purchase" ? (
        <PurchaseDashboard />
      ) : department === "Store" ? (
        <StoreDashboard />
      ): department === "Human Resource" ? (
        <OrganistaionDashboard />
      ) : (
        <BackOfficeDashboard />
      )}
      <Footer />
    </>
  );
}


export default Index;
