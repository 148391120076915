import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "./Style.css";
import { useLocation, useParams } from "react-router-dom";
import AddMembersModal from "../Modals/AddMembersModal";
import TenderList from "./TenderList";

function DepartmentListDetail() {
  const param = useParams();
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const placeholderImage = imageBaseUrl + "assets/user.jpg";
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [members, setMembers] = useState([]);
  const [departmentDetails, setDepartmentDetails] = useState([]);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [projectId, setProjectId] = useState([]);
  const detailData = {
    user_id: user_id,
    departmentId: param.id,
  };

  function handleClose() {
    setShowAddMemberModal(false);
  }

  function detailHandle() {
    const url = apiurl + "getdepartmentdetails";

    axios
      .post(url, detailData, config)
      .then((res) => {
        setDepartmentDetails(res?.data?.response?.departmentdetails);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    detailHandle();
  }, []);

  function remainDate(completeDate) {
    let days;
    let months;
    const currentTime = new Date().getTime();
    const futureTime = new Date(completeDate).getTime();
    const remainingTime =
      futureTime > currentTime
        ? futureTime - currentTime
        : currentTime - futureTime;
    months = Math.floor(remainingTime / (1000 * 60 * 60 * 24 * 30));
    days = Math.floor(
      (remainingTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );
    return futureTime > currentTime
      ? `${months} Months and ${days} Days Remaining`
      : `Project is Delayed by ${months} Months and ${days} Days`;
  }

  function getProjectsMembers(id) {
    let projectList = departmentDetails?.departmentProjects?.map(
      (element) => element
    );
    let projectMembs = projectList.find((e) => e.id === id);
    setMembers(projectMembs?.projectMembers);
  }

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box pb-2">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/department">Department /</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Department Details
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="department__card_detail">
                  <div className="card">
                    <div className="py-4 card-body">
                      <div className="">
                        <div className="row">
                          <div className="col-12">
                            <h2 className="fw-bold mt-0">
                              {departmentDetails?.name}{" "}
                              <span className="fw-normal font-14">
                                ({departmentDetails?.email})
                              </span>
                            </h2>
                            <p className="mb-2">
                              <span style={{ fontWeight: 500 }}>
                                Department Head :{"  "}
                              </span>
                              <span className=" text-capitalize">
                                {departmentDetails?.departmentHead?.name}
                              </span>
                            </p>
                            <p className="m-0">
                              <span style={{ fontWeight: 500 }}>
                                Description :{"  "}
                              </span>
                              <span className="font-14">
                                {departmentDetails?.description}
                              </span>
                            </p>
                          </div>
                        </div>
                        {/* <h5>
                         Head:{" "}
                          {departmentDetails?.departmentHead?.name}
                        </h5> */}
                      </div>
                    </div>
                  </div>

                  {detailData.departmentId === "12" ? (
                    <TenderList />
                  ) : (
                    <div className="card">
                      <div className="py-4 card-body">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item fw-bold" role="presentation">
                            PROJECT DETAILS
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="department-project"
                            role="tabpanel"
                            aria-labelledby="department-project-tab"
                          >
                            <div className="table-responsive browser_users">
                              <table className="table table-hover mb-0 detail-table">
                                <thead>
                                  <tr>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Project Type</th>
                                    <th scope="col">Railway Zone</th>
                                    <th scope="col">Team Members</th>
                                    <th scope="col">Estimation Time</th>
                                    <th scope="col">Add Members</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {departmentDetails?.departmentProjects?.map(
                                    (element, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">
                                            <NavLink
                                              to={`/ProjectsProjectDetail/${element.id}`}
                                            >
                                              {element.project_title}
                                            </NavLink>
                                          </th>

                                          <td>
                                            {element.projectType
                                              ? element.projectType
                                              : "Not declared yet"}
                                          </td>

                                          <td>{element.railwayZone} </td>

                                          <td
                                            to=""
                                            className=" "
                                            data-bs-toggle="modal"
                                            data-bs-target="#membersList"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              getProjectsMembers(element.id)
                                            }
                                          >
                                            <div>
                                              <img
                                                src={
                                                  element?.projectMembers[0]
                                                    ?.profileimage
                                                    ? imageBaseUrl +
                                                      element?.projectMembers[0]
                                                        ?.profileimage
                                                    : placeholderImage
                                                }
                                                alt=""
                                                className="rounded-circle thumb-xs text-center"
                                              />{" "}
                                              {element?.projectMembers.length >
                                                1 && (
                                                <span
                                                  style={{
                                                    padding: "0.3rem",
                                                    borderRadius: "50%",
                                                    backgroundColor:
                                                      "lightCyan",
                                                  }}
                                                >
                                                  {`+ ${
                                                    element?.projectMembers
                                                      .length - 1
                                                  }`}
                                                </span>
                                              )}
                                              <h6 className="m-0">
                                                {
                                                  element?.projectMembers[0]
                                                    ?.name
                                                }
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            {remainDate(element.completionDate)}
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-danger"
                                              onClick={() => {
                                                setProjectId(element.id);
                                                setShowAddMemberModal(true);
                                              }}
                                            >
                                              Add Members
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>{" "}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="member-project"
                            role="tabpanel"
                            aria-labelledby="member-project-tab"
                          >
                            <table className="table border-dashed detail-table">
                              <thead>
                                <tr>
                                  <th scope="col">User</th>
                                  <th scope="col">Designation</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Contact number</th>
                                </tr>
                              </thead>
                              <tbody>
                                {departmentDetails?.departmentMembers?.map(
                                  (element, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">
                                          {" "}
                                          <img
                                            src={
                                              element.profileimage
                                                ? imageBaseUrl +
                                                  element.profileimage
                                                : placeholderImage
                                            }
                                            alt="user"
                                            className="rounded-circle thumb-md img-fluid"
                                          />
                                          <span className="ms-2">
                                            {element.name}
                                          </span>
                                        </th>

                                        <td>{element.roleName}</td>
                                        <td>{element.email}</td>
                                        <td>{element.phone}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="membersList">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close mb-3 ms-auto d-block"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="table-responsive">
                <table
                  className="table table-striped mb-0"
                  style={{ border: "1px solid #f0f0f0" }}
                >
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Designation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((element, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-capitalize">
                            <img
                              src={
                                element.profileimage
                                  ? imageBaseUrl + element.profileimage
                                  : placeholderImage
                              }
                              alt=""
                              className="rounded-circle thumb-xs me-1"
                            />{" "}
                            {element.name}
                          </td>
                          <td>{element.roleName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddMembersModal
        show={showAddMemberModal}
        handleClose={handleClose}
        fetchData={detailHandle}
        project_id={projectId}
      />
      <Footer />
    </>
  );
}

export default DepartmentListDetail;
