import React, { useState, useEffect } from "react";
import { errorsMessage } from "../../constant";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
function ChallanForm({
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  spinner
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [selectedStore, setSelectedStore] = useState();
  const [selectedStoreId, setSelectedStoreId] = useState();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const navigate = useNavigate()
  const [unitList, setUnitList] = useState([]);

  const getStoreList = () => {
    const data = { user_id };
    const url = `${baseUrl}listpurchasestoremaster`;
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setUnitList(res?.data?.response?.CityMasterList);
        }
      })
      .catch((err) => {
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  useEffect(() => {
    getStoreList();
  }, []);
  useEffect(() => {
 
    if (mode == "edit" && values?.unit_id) {
    
      const selectedStore = unitList?.find((unit) => unit.id === values?.unit_id);
  
      if (selectedStore) {
        setSelectedStore(selectedStore);
        setSelectedStoreId(selectedStore.id);
      }
    }
  }, [mode,values?.unit_id,unitList]);
  const getAttachmentBase64 = (event) => {
    const file = event.target.files[0];
    const { name } = event.target;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFieldValue(name, base64);
      };
    }
  };
  const handleStoreChange = (selectedOption) => {
    setFieldValue("unit_id", selectedOption?.id);
    setSelectedStore(selectedOption);
    setSelectedStoreId(selectedOption?.id);
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-title-box d-flex justify-content-between align-items-center">
                    <h2 className="page-title">
                      {" "}
                      {mode == "add" ? "ADD" : "EDIT"} DELIVERY CHALLAN{" "}
                    </h2>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Select Unit
                        </label>
                        {/* <select
                          name="unit_id"
                          id="unit_id"
                          value={values?.unit_id}
                          onChange={handleChange}
                          className="form-control border-secondary"
                        >
                          <option value="">Select</option>
                          {unitList?.map((unit) => {
                            return (
                              <option value={unit?.id}>
                                {unit?.store_name}
                              </option>
                            );
                          })}
                        </select> */}
                        <div className="d-flex" style={{ width: "100%" }}>
                          <div class="input-group search_matarial d-flex align-items-center">
                            <Select
                              placeholder="Search By Title"
                              style={{ width: "100%" }}
                              className="form-select-control border-secondary"
                              name="unit_id"
                              id="unit_id"
                              isSearchable={true}
                              options={unitList}
                              getOptionLabel={(unitList) =>
                                unitList["store_name"]
                              }
                              getOptionValue={(storeUnitList) =>
                                storeUnitList["id"] 
                              }
                              value={selectedStore}
                              onChange={handleStoreChange}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                  ...theme.colors,
                                  primary25: "lightgrey",
                                  primary: "lightgrey",
                                },
                              })}
                            />
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                            >
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>

                        <p className="text-danger m-0">
                          {touched.unit_id && errors.unit_id
                            ? errors.unit_id
                            : validationError?.unit_id}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Document Type
                        </label>
                        <select
                          name="document_type"
                          id="document_type"
                          value={values?.document_type}
                          onChange={handleChange}
                          className="form-control border-secondary"
                          disabled={mode=="edit"&&true}
                          // value={values?.doc}
                        >
                          <option value="">Select</option>
                          <option value="delivery_challan">
                            Delivery Challan
                          </option>
                          <option value="tax_invoice">Tax Invoice</option>
                        </select>
                        <p className="text-danger m-0">
                          {touched.document_type && errors.document_type
                            ? errors.document_type
                            : validationError?.document_type}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Upload Document
                        </label>
                        <input
                          type="file"
                          name="document_path"
                          id="document_path"
                          className="form-control border-secondary"
                          placeholder="Enter amount"
                          onChange={(event) => {
                            getAttachmentBase64(event);
                          }}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.document_path && errors.document_path
                            ? errors.document_path
                            : validationError?.document_path}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Price
                        </label>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          value={values?.price}
                          onChange={handleChange}
                          className="form-control border-secondary"
                          placeholder="Enter price"
                        ></input>
                        <p className="text-danger m-0">
                          {touched.price && errors.price
                            ? errors.price
                            : validationError?.price}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Upload EWAY Bill
                        </label>
                        <input
                          type="file"
                          className="form-control border-secondary"
                          placeholder="Enter amount"
                          name="eway_document_path"
                          id="eway_document_path"
                          onChange={getAttachmentBase64}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.eway_document_path &&
                          errors.eway_document_path
                            ? errors.eway_document_path
                            : validationError?.eway_document_path}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Tax Invoice No.
                        </label>
                        <input
                          type="text"
                          name="tax_invoice_no"
                          id="tax_invoice_no"
                          value={values?.tax_invoice_no}
                          onChange={handleChange}
                          className="form-control border-secondary"
                          placeholder="Enter tax invoice number"
                        ></input>
                        <p className="text-danger m-0">
                          {touched.tax_invoice_no && errors.tax_invoice_no
                            ? errors.tax_invoice_no
                            : validationError?.tax_invoice_no}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Upload Receiving
                        </label>
                        <input
                          type="file"
                          className="form-control border-secondary"
                          placeholder="Enter amount"
                          name="rec_document_path"
                          id="rec_document_path"
                          onChange={getAttachmentBase64}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.rec_document_path && errors.rec_document_path
                            ? errors.rec_document_path
                            : validationError?.rec_document_path}
                        </p>
                      </div>
                    </div>
                  </div>
                  {spinner ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Wait...</p>
              </div>
            ):
                  (<div className="row pt-4">
                    <div>
                      <button
                        type="button"
                        className="btn btn-de-danger btn-sm border-danger"
                        onClick={()=>{navigate(-1)}}
                      >
                        Cancel
                      </button>
                      <button type="Submit" className="btn btn-danger mx-2">
                        {mode == "add" ? "Add" : "Update"}
                      </button>
                    </div>
                  </div>)}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallanForm;
