import React, { useState, useEffect, useRef } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";
import AddMaterialPurchaseModal from "../Modals/AddMaterialPurchaseModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function MaterialCostingItem() {
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [materialItems, setMaterialItems] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [search, setSearch] = useState("");
  const tableBottomRef = useRef();
  const tableTopRef = useRef();
  const [loader, setLoader] = useState(true); 
  const [editableItem, setEditableItem] = useState("");
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  function handleClose() {
    setShowAddModal(false);
    setDeleteModal(false);
    setEditableItem(null);
  }

  function getItemsList() {
    setLoader(true);
    const url = baseUrl + "coachesitemlist";
    let data = { user_id, search, accessType: "view" };
    axios
      .post(url, data, config)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setData(response?.data?.response?.coachesItemList);
          setMaterialItems(
            response?.data?.response?.coachesItemList.slice(0, 8)
          );
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong");
      });
  }

  function getEditData(id) {
    let editItem = data.find((e) => e.id === id);
    setEditableItem(editItem);
  }

  function deleteItem() {
    let url = baseUrl + "deletecoachesitem";
    let data = { user_id, item_id: itemId };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          getItemsList();
          handleClose();
          toast.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong , Please try again later");
      });
  }

  const scrollToBottom = () => {
    tableBottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function showMore() {
    const additionalData = data.slice(
      materialItems.length,
      materialItems.length + 8
    );
    setMaterialItems((prev) => prev.concat(additionalData));
    scrollToBottom();
  }

  function moveToTop() {
    scrollToTop();
  }

  useEffect(() => {
    getItemsList();
  }, [search]);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"></li>
                      <li className="breadcrumb-item active"></li>
                    </ol>
                  </div>
                  <h4 className="page-title">Materials Items</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddModal(true)}
                      >
                        Add Item
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loader ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="card-title">Material Costing Item</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead className="thead-light">
                            <tr>
                              <th>S. No.</th>
                              <th>Item Name</th>
                              <th>QPC</th>
                              <th>UOM</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {materialItems?.map((element, index) => {
                              return (
                                <tr key={element.id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{element.item_name}</td>
                                  <td>{element.QPC}</td>
                                  <td>{element.UOM}</td>
                                  <td>
                                    <i
                                      className="las la-pen text-dark font-20 px-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        getEditData(element.id);
                                        setShowAddModal(true);
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Material Item"
                                    ></i>

                                    <i
                                      className="las la-trash-alt font-20 px-1 text-dark"
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setItemId(element.id);
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete Material Item"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-end">
                        {materialItems.length > 8 && (
                          <button
                            className="btn btn-secondary btn-sm mx-2"
                            onClick={() => moveToTop()}
                          >
                            <i className="fa fa-arrow-up mx-2"></i>
                          </button>
                        )}

                        {materialItems.length !== data.length &&
                          data.length > 8 && (
                            <button
                              className="btn btn-success btn-sm mx-2"
                              onClick={() => showMore()}
                            >
                              Show More
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddMaterialPurchaseModal
        show={showAddModal}
        handleClose={handleClose}
        getItemsList={getItemsList}
        editableItem={editableItem}
      />

      <Modal show={deleteModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-secondary text-center mx-3">
            Are you sure, <br /> Do you want to Delete this?
          </h3>
        </Modal.Body>
        <div className="d-flex gap-3 justify-content-center mb-3">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteItem}>
            Delete
          </Button>
        </div>
      </Modal>

      <div ref={tableBottomRef} />
      <div ref={tableTopRef} />
      <Footer></Footer>
    </>
  );
}

export default MaterialCostingItem;
