import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { deepOrange } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Emp from "../Assets/message-square-dots_duotone.png";
import "../../HrDepartment.scss"
const EmployeePerformance = () => {
  const [value, setValue] = useState("PRAISE");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }} className="finance10PX">
        <TabContext value={value}>
          {/* Tab headers in one row */}
          <div className="row">
            <div
              className="employee-Accordian"
              style={{
                backgroundColor: "white",
                width: "97.5%",
                marginLeft: "0.625rem",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                className="custom-tablist"
              >
                <Tab label="PRAISE" value="PRAISE" />
                <Tab label="FEEDBACK" value="FEEDBACK" />
              </TabList>
            </div>
          </div>

          {/* Tab content in another row */}
          <div className="row">
            <TabPanel value="PRAISE">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="card ">
                    <div className="assignedAssets">Praises</div>
                    <hr />
                    <div className="table_24">
                      <div className="row">
                        <div className="col-lg-3 d-flex">
                          <div>
                            <Avatar
                              sx={{ bgcolor: deepOrange[500] }}
                              alt="Remy Sharp"
                              src="/broken-image.jpg"
                            >
                              B
                            </Avatar>
                          </div>
                          <div className="displayGrid">
                            <span className="currentSalaryValue">
                              Deepak Rajput
                            </span>
                            <span className="currentSalaryHeading mt-2">
                              HR
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-3 dateFormate">15 May, 2024</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="FEEDBACK">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="card bordeRadius10px">
                    <div className="assignedAssets">Feedback</div>
                    <hr />

                    <div className="marginAuto">
                      <div>
                        <img src={Emp} className="mAutoDisplayFlex" />
                        <p className="textOfpARRAf">
                          No Feedback Received Yet!
                        </p>
                        <p className="textOfpARRAs">
                          You can start by giving feedback or requesting
                          feedback from others
                        </p>
                        <div className="textOfpARRAt justifySpa">
                            <div className="m-auto d-flex g-17">
                            <div className="mr-2m">
                          <button className="buttonFeedF">Give Feedback</button>
                          </div>
                          <div className="ml-2m">
                          <button className="buttonFeedT">
                            Request a feedback
                          </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </TabContext>
      </Box>
    </>
  );
};
export default EmployeePerformance;
