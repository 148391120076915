import React, { useState } from "react";
import AuthorizeSignatorieModal from "./AuthorizeSignatorieModal";

function AddAuhorizeSignatoriesButton({ legalEntity, getAuthoizeSignatory }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mb-4">
      <button
        style={{
          width: "100%",
          border: "2px dotted rgba(70, 103, 182, 1)",
          background: "rgba(242, 246, 255, 1)",
          borderRadius: "8px",
        }}
        onClick={handleClickOpen}
      >
        <p
          style={{
            textAlign: "left",
            padding: ".5rem",
            margin: 0,
            color: "rgba(70, 103, 182, 1)",
          }}
        >
          + Add Authorized Signatory
        </p>
      </button>
      <AuthorizeSignatorieModal
        open={open}
        handleClose={handleClose}
        legalEntity={legalEntity}
        getAuthoizeSignatory={getAuthoizeSignatory}
        mode="add"
      />
    </div>
  );
}

export default AddAuhorizeSignatoriesButton;
