import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import error from "../Assets/error.png";

export default function ErrorPage() {
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className=" w-25">
                  <img className="img-fluid mb-3" src={error}></img>
                  <h2 className="text-danger mb-5">
                    <em>
                      Oops! There is some issue at the moment , Please try again
                    </em>
                  </h2>
                  <div className="d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={refreshPage}
                    >
                      Try&nbsp;Again
                    </button>
            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
