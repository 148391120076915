import { createSlice } from '@reduxjs/toolkit';

export const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState: {
    projectCurrentPage: 1, 
  },
  reducers: {
    setNewCurrentPage: (state, action) => {
      state.projectCurrentPage = action.payload;
    },
  },
});

export const { setNewCurrentPage } = currentPageSlice.actions;
export const selectCurrentPage = (state) => state.currentPage.projectCurrentPage;

export default currentPageSlice.reducer;