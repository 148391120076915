import React, { useEffect, useState } from "react";
import axios from "axios";
import { errorsMessage } from "../constant";

import Spinner from "react-bootstrap/Spinner";
const AllStore = () => {
  const userdetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userdetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const appBaseUrl = process.env.REACT_APP_BASE_URL;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [storeList, setStoreList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const getStoreList = () => {
    const data = { user_id };
    const url = `${baseUrl}listpurchasestoremaster`;
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setSpinner(false);
          setStoreList(res?.data?.response?.CityMasterList);
        }
      })
      .catch((err) => {
        setSpinner(false);
        errorsMessage("Please try again later ,Something went wrong");
      });
  };
  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        {spinner ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh", zIndex: "1" }}
          >
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="page-content-tab responsive-tab">
            <div className="container-fluid">
              <div className="card mt-4">
                <div className="card-body tabsall">
                  <div className="row">
                    <div className="d-flex align-content-center justify-content-between">
                      <div className="col-sm-4">
                        <div className="page-title-box">
                          <h2 className="page-title"> STORE </h2>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="table-responsive">
                        {storeList?.length ? (
                          <table class="table">
                            <thead>
                              <tr style={{ border: "0" }}>
                                <th
                                  scope="col"
                                  style={{
                                    width: "10%",
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  S.NO.
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="col-2"
                                  style={{
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Address
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Store Type
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Contact Person
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    color: "#b9b9b9",
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  Contact Number
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {storeList?.map((store, index) => (
                                <tr
                                  style={{ borderBottom: "1px solid #E0E0E0" }}
                                >
                                  <td>{index + 1}</td>
                                  <td>
                                    {" "}
                                    {store?.store_name == null
                                      ? "--"
                                      : store?.store_name}
                                  </td>

                                  <td>
                                    {" "}
                                    {store?.store_address == null
                                      ? "--"
                                      : store?.store_address}
                                  </td>
                                  <td style={{ color: "#0084CE" }}>
                                    {store?.store_type == null
                                      ? "--"
                                      : store?.store_type}
                                  </td>

                                  <td style={{ color: "#0084CE" }}>
                                    {store?.keeper_name == null
                                      ? "--"
                                      : store?.keeper_name}
                                  </td>

                                  <td style={{ color: "#0084CE" }}>
                                    {store?.contact_no == null
                                      ? "--"
                                      : store?.contact_no}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          "No Data Found"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllStore;
