import Access from "./SettingSections/Access";

function ProjectSetting({ tabKey , fetchProjectDetails }) {

  // console.log("Project detail on Setting" , fetchProjectDetails)

  return (
    <>
      <div className="pt-2">
        <ul className="nav nav-pills nav-justified" role="tablist">
          <li className="nav-item waves-effect waves-light"></li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane pt-3 active"
            id="project-seting-access"
            role="tabpanel"
          >
            <Access tabKey={tabKey} fetchProjectDetails={fetchProjectDetails}/>
          </div>
        </div>
      </div>

      {/* modal edit notifition */}

      {/* modal edit notifition */}
    </>
  );
}

export default ProjectSetting;
