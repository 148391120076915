import React from 'react'
import Spinner from "react-bootstrap/Spinner";
const Loader = () => {
  return (
    <>
    <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "28vh" }}
          >
            {/* <Spinner animation="grow" variant="danger" /> */}
            <Spinner animation="border" variant="secondary" />
          </div>
      
    </>
  )
}

export default Loader
