import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
const MaterialStatus = ({
  item,
  listMaterialInStatus,
  selectedProjectId,
  selectedPO,
  user_id,
}) => {
  const [partialModal, setPartialModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [disabled, setDisabled] = useState(false);
  const [partialValue, setPartialValue] = useState(null);
  const [partialValueError, setPartialValueError] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const statusUpdate = (partial_qty = "", status) => {
    const url = `${baseUrl}addmaterialdetails`;
    const data = {
      user_id,
      project_id: selectedProjectId,
      purchase_order_no: selectedPO,
      item_details: [
        {
          item_id: item?.id,
          item_statuses: status,
          partial_qty: partial_qty,
        },
      ],
    };

    axios
      .post(url, data, config)
      .then((res) => {
        if (res?.status == 200) {
          setDisabled(true);
          toast.success("Item Status update successfully",{ autoClose: 2000});
        }
      })
      .catch((err) => toast.error("Something went wrong",{ autoClose: 2000}));
  };
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    if (e.target.value !== "3") {
      const defaultPartialQty = "";
      statusUpdate(defaultPartialQty, e.target.value);
    }

    if (e.target.value === "3") {
      setPartialModal(true);
    } else {
      setPartialModal(false);
    }
  };
  function handleClose() {
    setPartialModal(false);
  }
  const partialHandler = () => {
    if (partialValue) {
      statusUpdate(partialValue, selectedStatus);
      handleClose();
    } else {
      setPartialValueError(true);
    }
  };
  useEffect(() => {
    if(item?.item_statuses==null){setSelectedStatus(0)}else{setSelectedStatus(item?.item_statuses)
      setDisabled(true); }
    
    // if (selectedStatus !== 0) {
    //   setDisabled(true);
    // }
  }, [selectedPO,item]);

  return (
    <>
      <select
        disabled={disabled}
        name=""
        id=""
        value={selectedStatus}
   
        style={{ padding: "0.3rem 0.7rem" }}
        onChange={handleStatusChange}
      >
        <option value="0">Select</option>
  
        {listMaterialInStatus?.map((materialStatus) => (
          <option value={materialStatus?.id}>{materialStatus?.name}</option>
        ))}
      </select>
      <Modal
        show={partialModal}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">
            Partial Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Partial Status</h4>
          <h6 className="text-secondary">
          Please enter the partial amount in the field below.
          </h6>
          <div className="my-3">
            <label htmlFor="" className="mb-1">
              Enter partial
            </label>
            <input
              type="text"
              placeholder="Enter"
              className="form-control"
              value={partialValue}
              onChange={(e) => {
                setPartialValue(e.target.value);
                setPartialValueError(false);
              }}
            />
            {partialValueError && (
              <p className="text-danger m-0">Please Enter the Partial Value</p>
            )}
          </div>
        </Modal.Body>
        <div className="d-flex gap-3 mb-3 mx-3">
          <Button variant="primary" onClick={partialHandler}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MaterialStatus;
