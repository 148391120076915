import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addTenderchema } from "../../../schemas/Index";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import {
  getrailwayzone,
  getsubdivisionwithzone,
  getunitlist,
} from "../../../redux/api";
import { selectDropdowns } from "../../../redux/features/allDropdownSlice";
import { errorsMessage } from "../../../constant";

function EditTenderDetails({ changeKey }) {
  const [spinner, setSpinner] = useState(false);
  const [railwayZoneList, setRailwayzoneList] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [subdivisionwithzoneList, setsubdivisionwithzoneList] = useState([]);
  const [untiList, setUntiList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [priceSheetName, setPriceSheetName] = useState("");
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const navigate = useNavigate();
  const location = useLocation();
  const { initialData } = location?.state ?? {};
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const allDropdowns = useSelector(selectDropdowns);
  const inspectionAgency = allDropdowns?.inspectionAgency;

  const initialValues = {
    railwayZone: initialData.railwayZone,
    subDivision: initialData.subDivision,
    tenderNo: initialData.tenderNo,
    tenderType: initialData.tenderType,
    coachtype: initialData.coachtype,
    contractType: initialData.contractType,
    tenderDocCost: initialData.tenderDocCost,
    ifApplicableAmountCost: initialData.ifApplicableAmountCost,
    EMD: initialData.EMD,
    ifApplicableAmountEmd: initialData.ifApplicableAmountEmd,
    advertisedValue: initialData.advertisedValue,
    itemDecsription: initialData.itemDecsription,
    quantity: initialData.quantity,
    uom: initialData.uom,
    closingDate: initialData.closingDate,
    time: initialData.time,
    higherAuthorityapproval: initialData.higherAuthorityapproval,
    intendingParticipation: initialData.intendingParticipation,
    remarks: initialData.remarks,
    publishedDate: initialData.publishedDate,
    paymentTerms: initialData.paymentTerms,
    eligibilityCriteria: initialData.eligibilityCriteria,
    biddingStatus: initialData.biddingStatus,
    priceSheet: initialData.priceSheet,
    validityOfOffer: initialData.validityOfOffer,
    validityExtension: initialData.validityExtension,
    tenchnicalEligibility: initialData.tenchnicalEligibility,
    RAPaticipation: initialData.RAPaticipation,
    RADate: initialData.RADate,
    RAResult: initialData.RAResult,
    DateOfNagotiation: initialData.DateOfNagotiation,
    IREPSRefNo: initialData.IREPSRefNo,
    transactionId: initialData.transactionId,
    transactionDate: initialData.transactionDate,
    transactionTime: initialData.transactionTime,
    tenderAwardStatus: initialData.tenderAwardStatus,
    tenderReturnStatus: initialData.tenderReturnStatus,
    tenderReturnDate: initialData.tenderReturnDate,
    tenderEmdReturnRemarks: initialData.tenderEmdReturnRemarks,
    biddingSystem: initialData.biddingSystem,
    inspectionAgency: initialData.inspectionAgency,
    approvingAgency: initialData.approvingAgency,
    periodOfCompletion: initialData.periodOfCompletion,
    awardedStatus: initialData.awardedStatus,
  };

  function EditTender(value) {
    setSpinner(true);
    let attachments = selectedFiles.map((element) =>
      element.map((e, i) => e.base64)
    );
    let tenderattachements = [].concat(...attachments);
    let url = baseUrl + "updatetender";
    let data = {
      ...value,
      user_id,
      tenderId: initialData.id,
      tenderattachements,
      priceSheet: values.priceSheet?.startsWith("assets")
        ? attachmentBaseUrl + values.priceSheet
        : values.priceSheet,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        setSpinner(false);
        toast.success("Update successfully");
        changeKey("1");
      })
      .catch((error) => {
        setSpinner(false);
        errorsMessage(error?.response?.status);
        if (error?.response?.status === 422) {
          setValidationError(error?.response?.data?.response);
        }
      });
  }

  function priceSheetChange(e) {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setFieldValue("priceSheet", data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setPriceSheetName(e.target.files[0].name);
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addTenderchema,
    onSubmit: (values) => {
      EditTender(values);
    },
  });
  function getDropdowns() {
    getrailwayzone({ user_id: user_id })
      .then((res) => {
        setRailwayzoneList(res?.data?.response?.RailwayZoneList);
      })
      .catch((err) => {});
  }
  function getSubDivisionWithZone() {
    getsubdivisionwithzone({
      user_id: user_id,
      railway_zone: values.railwayZone,
    })
      .then((res) => {
        setsubdivisionwithzoneList(res?.data?.response?.getSubDivisionWithZone);
      })
      .catch((err) => {});
    getunitlist({ user_id: user_id })
      .then((res) => {
        setUntiList(res?.data?.response?.unitList);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getDropdowns();
  }, []);
  useEffect(() => {
    getSubDivisionWithZone();
  }, [values.railwayZone]);

  useEffect(() => {
    if (values.tenderDocCost === "Not Applicable") {
      setFieldValue("ifApplicableAmountCost", 0);
    }
  }, [values.tenderDocCost]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (values.EMD === "Not Applicable" || values.EMD === "Exempted") {
      setValues({
        ...values,
        IREPSRefNo: "",
        transactionId: "",
        transactionDate: "",
        transactionTime: "",
        tenderReturnStatus: "",
        tenderReturnDate: "",
        tenderEmdReturnRemarks: "",
        ifApplicableAmountEmd: 0,
      });
    }
  }, [values.EMD]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form className="department-form" onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                      Coach Type 
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Coach Type"
                        name="coachtype"
                        value={values.coachtype}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      {/* <p className="text-danger m-0">
                        {touched.coachtype && errors.coachtype
                          ? errors.coachtype
                          : validationError?.coachtype}
                      </p> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Railway Zone <span className="text-danger">*</span>
                      </label>
                      <select
                        type="text"
                        id="railwayZone"
                        className="form-control border-secondary"
                        name="railwayZone"
                        value={values.railwayZone}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        {railwayZoneList.map((railwayZone) => (
                          <option key={railwayZone.id} value={railwayZone.id}>
                            {railwayZone.name}
                          </option>
                        ))}
                      </select>
                      <p className="text-danger m-0">
                        {touched.railwayZone && errors.railwayZone
                          ? errors.railwayZone
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Sub Division{" "}
                      </label>
                      <select
                        type="text"
                        id="subDivision"
                        className="form-control border-secondary"
                        name="subDivision"
                        value={values.subDivision}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        {subdivisionwithzoneList.map((subDivision) => (
                          <option key={subDivision.id} value={subDivision.id}>
                            {subDivision.name}
                          </option>
                        ))}
                      </select>
                      <p className="text-danger m-0">
                        {touched.subDivision && errors.subDivision
                          ? errors.subDivision
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Tender Number <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Tender Number"
                        name="tenderNo"
                        value={values.tenderNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.tenderNo && errors.tenderNo
                          ? errors.tenderNo
                          : validationError?.tenderNo}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Published Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder="Enter Closing Date"
                        name="publishedDate"
                        value={values.publishedDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.publishedDate && errors.publishedDate
                          ? errors.publishedDate
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Closing Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder="Enter Closing Date"
                        name="closingDate"
                        value={values.closingDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.closingDate && errors.closingDate
                          ? errors.closingDate
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Closing Time <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control border-secondary"
                        placeholder="Enter Time"
                        name="time"
                        min="00:00"
                        max="23:59"
                        value={values.time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.time && errors.time ? errors.time : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Contract Type <span className="text-danger">*</span>
                      </label>

                      <select
                        type="text"
                        id="contractType"
                        className="form-control border-secondary"
                        name="contractType"
                        value={values.contractType}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Works">Works</option>
                        <option value="Stores">Stores</option>
                        <option value="GEM (Government E-Marketing Portal)">
                          GEM (Government E-Marketing Portal)
                        </option>
                        <option value="Other">Other</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.contractType && errors.contractType
                          ? errors.contractType
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Tender Type <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder="Enter Tender Type"
                        name="tenderType"
                        value={values.tenderType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select</option>
                        <option value="Open">Open</option>
                        <option value="Special">Special</option>
                        <option value="Limited">Limited</option>
                        <option value="PAC">PAC</option>
                        <option value="Not Open">Not Open</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.tenderType && errors.tenderType
                          ? errors.tenderType
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Bidding System
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder="Enter Bidding System"
                        name="biddingSystem"
                        value={values.biddingSystem}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select</option>
                        <option value="Single Packet">Single Packet</option>
                        <option value="Double Packet">Double Packet</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.biddingSystem && errors.biddingSystem
                          ? errors.biddingSystem
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Inspection Agency{" "}
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder="Enter Bidding System"
                        name="inspectionAgency"
                        value={values.inspectionAgency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select</option>
                        {inspectionAgency?.map((element) => {
                          return (
                            <option key={element.id} value={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Approving Agency{" "}
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Approving Agency"
                        name="approvingAgency"
                        value={values.approvingAgency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Tender Title <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Tender Title"
                        name="itemDecsription"
                        value={values.itemDecsription}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.itemDecsription && errors.itemDecsription
                          ? errors.itemDecsription
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Quantity <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Quanity"
                        name="quantity"
                        value={values.quantity}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.quantity && errors.quantity
                          ? errors.quantity
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        UOM <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder="Enter UOM"
                        name="uom"
                        value={values.uom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        {untiList.map((list) => (
                          <option key={list.id} value={list.id}>
                            {list.name}
                          </option>
                        ))}
                      </select>
                      <p className="text-danger m-0">
                        {touched.uom && errors.uom ? errors.uom : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Validity of offer (Days)
                        <span className="text-danger">*</span>
                        <span className="text-danger"></span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Validity of offer (Days)"
                        name="validityOfOffer"
                        value={values.validityOfOffer}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      <p className="text-danger m-0">
                        {touched.validityOfOffer && errors.validityOfOffer
                          ? errors.validityOfOffer
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Advertised value <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Amount"
                        name="advertisedValue"
                        value={values.advertisedValue}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.advertisedValue && errors.advertisedValue
                          ? errors.advertisedValue
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Tender Document Cost (TDC)
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        type="text"
                        id="tenderDocCost"
                        className="form-control border-secondary"
                        name="tenderDocCost"
                        value={values.tenderDocCost}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="To Pay">To Pay</option>
                        <option value="Applicable">Applicable</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.tenderDocCost && errors.tenderDocCost
                          ? errors.tenderDocCost
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Amount (TDC) <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Tender Doc Cost"
                        name="ifApplicableAmountCost"
                        value={values.ifApplicableAmountCost}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={values.tenderDocCost === "Not Applicable"}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.ifApplicableAmountCost &&
                        errors.ifApplicableAmountCost
                          ? errors.ifApplicableAmountCost
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Earnest Money Deposit (EMD){" "}
                      </label>

                      <select
                        type="text"
                        id="EMD"
                        className="form-control border-secondary"
                        name="EMD"
                        value={values.EMD}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Applicable">Applicable</option>
                        <option value="Not Applicable">Not Applicable</option>
                        <option value="Exempted">Exempted</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.EMD && errors.EMD ? errors.EMD : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Amount (EMD){" "}
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Amount"
                        name="ifApplicableAmountEmd"
                        value={values.ifApplicableAmountEmd}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                      <p className="text-danger m-0">
                        {touched.ifApplicableAmountEmd &&
                        errors.ifApplicableAmountEmd
                          ? errors.ifApplicableAmountEmd
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        IREPS Ref. No.
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter IREPS Ref. No."
                        name="IREPSRefNo"
                        value={values.IREPSRefNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Transaction ID
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Transaction ID"
                        name="transactionId"
                        value={values.transactionId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Transaction Date
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder=""
                        name="transactionDate"
                        value={values.transactionDate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                      <p className="text-danger m-0">
                        {touched.transactionDate && errors.transactionDate
                          ? errors.transactionDate
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Transaction Time
                      </label>
                      <input
                        type="time"
                        className="form-control border-secondary"
                        placeholder="Enter Amount"
                        name="transactionTime"
                        value={values.transactionTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                      <p className="text-danger m-0">
                        {touched.transactionTime && errors.transactionTime
                          ? errors.transactionTime
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Return Status
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="tenderReturnStatus"
                        value={values.tenderReturnStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Due">Due</option>
                        <option value="Returned">Returned</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.tenderReturnStatus && errors.tenderReturnStatus
                          ? errors.tenderReturnStatus
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Return Date
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder="Enter Payment Terms"
                        name="tenderReturnDate"
                        value={values.tenderReturnDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                      <p className="text-danger m-0">
                        {touched.tenderReturnDate && errors.tenderReturnDate
                          ? errors.tenderReturnDate
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        EMD Remarks
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter EMD Remarks"
                        name="tenderEmdReturnRemarks"
                        value={values.tenderEmdReturnRemarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          values.EMD === "Not Applicable" ||
                          values.EMD === "Exempted"
                        }
                      ></input>
                      <p className="text-danger m-0">
                        {touched.tenderEmdReturnRemarks &&
                        errors.tenderEmdReturnRemarks
                          ? errors.tenderEmdReturnRemarks
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Payment Terms{" "}
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Payment Terms"
                        name="paymentTerms"
                        value={values.paymentTerms}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.paymentTerms && errors.paymentTerms
                          ? errors.paymentTerms
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Eligibility Criteria
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Eligibility Criteria"
                        name="eligibilityCriteria"
                        value={values.eligibilityCriteria}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.eligibilityCriteria &&
                        errors.eligibilityCriteria
                          ? errors.eligibilityCriteria
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Higher Authority Approval{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        type="text"
                        id="higherAuthorityapproval"
                        className="form-control border-secondary"
                        name="higherAuthorityapproval"
                        value={values.higherAuthorityapproval}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Approved">Approved</option>
                        <option value="Not Approved">Not Approved</option>
                        <option value="Pending">Pending</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.higherAuthorityapproval &&
                        errors.higherAuthorityapproval
                          ? errors.higherAuthorityapproval
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Intending Participation
                        <span className="text-danger">*</span>
                      </label>

                      <select
                        type="text"
                        id="intendingParticipation"
                        className="form-control border-secondary"
                        name="intendingParticipation"
                        value={values.intendingParticipation}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="To be decided">To be decided</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.intendingParticipation &&
                        errors.intendingParticipation
                          ? errors.intendingParticipation
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Bidding Status
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="biddingStatus"
                        value={values.biddingStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Bidded">Bidded</option>
                        <option value="Not Bidded">Not Bidded</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.biddingStatus && errors.biddingStatus
                          ? errors.biddingStatus
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Awarded Status
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="awardedStatus"
                        value={values.awardedStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Awarded">Awarded</option>
                        <option value="Not Awarded">Not Awarded</option>
                        <option value="PostPoned">PostPoned</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.awardedStatus && errors.awardedStatus
                          ? errors.awardedStatus
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Validity Extension
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Validity Extension"
                        name="validityExtension"
                        value={values.validityExtension}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.validityExtension && errors.validityExtension
                          ? errors.validityExtension
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Period of Completion (Days)
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter Period of Completion"
                        name="periodOfCompletion"
                        value={values.periodOfCompletion}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.periodOfCompletion && errors.periodOfCompletion
                          ? errors.periodOfCompletion
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Technical Eligibility
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="tenchnicalEligibility"
                        value={values.tenchnicalEligibility}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Developmental">Developmental</option>
                        <option value="Bulk">Bulk (Regular)</option>
                        <option value="Not Eligible">Not Eligible</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.tenchnicalEligibility &&
                        errors.tenchnicalEligibility
                          ? errors.tenchnicalEligibility
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        RA Participation
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="RAPaticipation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.RAPaticipation}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Allowed">Allowed</option>
                        <option value="Not Allowed">Not Allowed</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.RAPaticipation && errors.RAPaticipation
                          ? errors.RAPaticipation
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        RA Date
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder=""
                        name="RADate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.RADate}
                      />
                      <p className="text-danger m-0">
                        {touched.RADate && errors.RADate ? errors.RADate : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        RA Result
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="RAResult"
                        value={values.RAResult}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Awarded">Awarded</option>
                        <option value="Not Awarded">Not Awarded</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.RAResult && errors.RAResult
                          ? errors.RAResult
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Date of Negotiation
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder=""
                        name="DateOfNagotiation"
                        value={values.DateOfNagotiation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="text-danger m-0">
                        {touched.DateOfNagotiation && errors.DateOfNagotiation
                          ? errors.DateOfNagotiation
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Remarks
                      </label>
                      <input
                        className="form-control border-secondary"
                        placeholder="Enter remarks"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.remarks && errors.remarks
                          ? errors.remarks
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Price Sheet
                      </label>
                      <input
                        type="file"
                        className="form-control border-secondary"
                        name="priceSheet"
                        onChange={(e) => priceSheetChange(e)}
                        accept=".pdf"
                      ></input>
                      <p className="text-danger m-0">
                        {touched.remarks && errors.remarks
                          ? errors.remarks
                          : null}
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label font-16 fw-bold">
                        Attachment
                      </label>
                      <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="form-control border-secondary"
                        accept="application/pdf,image/*"
                      />
                      <p className="text-danger m-0">
                        {touched.tenderattachements && errors.tenderattachements
                          ? errors.tenderattachements
                          : null}
                      </p>
                      {selectedFiles.map((file, index) =>
                        file.map((pdf, i) => (
                          <div
                            className="d-flex justify-content-between w-100 my-2 shadow px-2 py-1 rounded"
                            key={index}
                          >
                            <p className="mb-0">
                              <a
                                href={file[i].base64}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="me-3"
                              >
                                {file[i].name}
                              </a>
                            </p>
                            <button
                              className="border-0 bg-white"
                              onClick={() => removeAttachment(index, i)}
                              type="button"
                            >
                              {" "}
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </div>
                        ))
                      )}
                    </div>
                  </div> */}
                </div>
                <div>
                  {spinner ? (
                    <div className="">
                      <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                      <p>Updating Tender...</p>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <Button variant="primary" type="submit">
                        Update Tender
                      </Button>

                      <button
                        type="button"
                        className="btn btn-de-danger btn-sm border-danger"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => changeKey("1")}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default EditTenderDetails;
