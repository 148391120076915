import React, { useEffect, useState } from "react";
import Billing from "./Billing";
import TdcDetail from "./TdcDetail";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EmdDetail from "./EmdDetail";
import SdDetail from "./SdDetail";
import PgDetail from "./PgDetail";
import Preview from "./Preview";
import axios from "axios";
import { useParams } from "react-router-dom";

function Finance({ tabKey }) {
  //tabs state
  const [key, setKey] = useState("tdc");
  const [loading, setLoading] = useState(false);
  const [sdData, setSdMultipleData] = useState([]);
  const [pgMultipleData, setPgMultipleData] = useState([]);
  const [tdcData, setTdcData] = useState("");
  const [emdData, setEmdData] = useState("");
  const [billing, setBilling] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const param = useParams();
  const project_id = Number(param.id);
  const [renderState, setRenderState] = useState(true);

  function financialList() {
    setLoading(true);
    let url = baseUrl + "getuntiprojectfinancial";
    let data = { user_id, project_id };
    axios
      .post(url, data, { headers: { Authorization: bearer_token } })
      .then((response) => {
        setLoading(false);

        if (response.data.response.financialDetails.Tdc) {
          setTdcData(response.data.response.financialDetails.Tdc);
        }
        if (response.data.response.financialDetails.Emd) {
          setEmdData(response.data.response.financialDetails.Emd);
        }
        if (response.data.response.financialDetails.sdMultipleData) {
          setSdMultipleData(
            response.data.response.financialDetails.sdMultipleData
          );
        }
        if (response.data.response.financialDetails.pgMultipleData) {
          setPgMultipleData(
            response.data.response.financialDetails.pgMultipleData
          );
        }
        if (response.data.response.financialDetails.projectBilling) {
          setBilling(response.data.response.financialDetails.projectBilling);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    if (tabKey === "finance") {
      financialList();
    }
  }, [renderState]);
  return (
    <>
      <div className="pt-2">
        <ul className="nav nav-pills nav-justified" role="tablist">
          <li className="nav-item waves-effect waves-light">
            {" "}
            <a
              className="nav-link active col-3"
              data-bs-toggle="tab"
              href="#emdSdPg"
              role="tab"
              aria-selected="false"
            >
              EMD/SD/PG Details
            </a>{" "}
          </li>
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#billing"
              role="tab"
              aria-selected="false"
            >
              Billing
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane pt-3 active" id="emdSdPg" role="tabpanel">
            {" "}
            <Tabs
              id="fill-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              justify
            >
              <Tab eventKey="tdc" title="TDC">
                <TdcDetail
                  tdcData={tdcData}
                  renderState={renderState}
                  setRenderState={setRenderState}
                />
              </Tab>
              <Tab eventKey="emd" title="EMD">
                <EmdDetail
                  emdData={emdData}
                  renderState={renderState}
                  setRenderState={setRenderState}
                />
              </Tab>
              <Tab eventKey="sd" title="SD">
                <SdDetail
                  renderState={renderState}
                  setRenderState={setRenderState}
                />
              </Tab>
              <Tab eventKey="pg" title="PG">
                <PgDetail
                  renderState={renderState}
                  setRenderState={setRenderState}
                />
              </Tab>
              <Tab eventKey="preview" title="Preview">
                <Preview
                  tdcData={tdcData}
                  emdData={emdData}
                  sdData={sdData}
                  pgData={pgMultipleData}
                  renderState={renderState}
                  setRenderState={setRenderState}
                  billing={billing}
                />
              </Tab>
            </Tabs>
          </div>
          <div className="tab-pane pt-3" id="billing" role="tabpanel">
            <>
              <Billing
                tabKey={tabKey}
                renderState={renderState}
                setRenderState={setRenderState}
              ></Billing>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Finance;
