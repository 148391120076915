import * as Yup from "yup";
const coachProductionSchema = Yup.object({
  // furnishingNo: Yup.string().required("This field is required"),
  coachNo: Yup.string().required("This field is required"),
  // coachAllottedTo: Yup.string().required("This field is required"),
  allotmentDate: Yup.string().required("This field is required"),
  // completionDate_Furnishing: Yup.string().required("This field is required"),
  // completionDate_Electrical: Yup.string().required("This field is required"),
  // dispatchDate: Yup.string().required("This field is required"),
  // coachWarrantyPeriod_IR_GCC: Yup.string().required("This field is required"),
  // UOM: Yup.string().required("This field is required"),
  // warrentyExpiryDate: Yup.string().required("This field is required"),
  lotId: Yup.string().required("This field is required"),
});
export default coachProductionSchema;
