import React from "react";
import OrgBusinessUnitTabs from "./OrgBusinessUnitTabs";
import OrganizationStructureHeaderName from "../OrganizationComponents/OrganizationStructureHeaderName";

function OrgBusinessUnitHeader({ businessUnit,editData,setEditData }) {
  return (
    <div >
      <OrganizationStructureHeaderName name={businessUnit?.name} editData={editData} />
      <OrgBusinessUnitTabs businessUnit={businessUnit} editData={editData} setEditData={setEditData}  />
    </div>
  );
}

export default OrgBusinessUnitHeader;
