import React, { useState, useEffect } from "react";
import image from "../../Assets/Group 1000003296.png";
import OrganizationStructureAsideList from "../OrganizationComponents/OrganizationStructureAsideList";
import OrgLocationHeader from "./OrgLocationHeader";
import LocationModal from "./LocationModal";
import API, {
  locationListApi,
} from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import MainCard from "../../../CommonComponent/MainCard";
function LocationParent() {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState("");
  const [loader, setLoader] = useState(false);
  const [editData, setEditData] = useState(null);

  const [locationList, setLocationList] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getLocationList = async () => {
    setLoader(true);
    try {
      const res = await locationListApi();
      const data = res?.data?.response?.data;
      setLocationList(data);
      if (data?.length) {
        setLocation(data[0]);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getLocationList();
  }, []);

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        <h4 className="p-4 pb-0  mt-0 fw-normal">ORGANISATION STRUCTURE</h4>
        <button className="hr btn btn-primary" onClick={() => handleClickOpen()}>
          + Add Location
        </button>
      </div>
      {/* <div className="col-md-3">
        <div
          style={{
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.25)",
            background: "rgba(255, 255, 255, 1)",
            border: "1px",
            borderRadius: "10px",
          }}
        >
           {loader ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ):locationList.length>0?
          (locationList?.map((locationDetail, index) => (
            <OrganizationStructureAsideList
              organizationDataList={locationDetail}
              key={index}
              setOrgDetail={setLocation}
              orgDetail={location}
              name={locationDetail?.name}
              // loader={loader}
            />
          ))):<div>No Location Found </div>}
        </div>
      </div> */}

      {/* <div className="col-md-9">
        <OrgLocationHeader location={location} editData={editData} getLocationList={getLocationList} setEditData={setEditData} />
      </div> */}
      <MainCard
        asideContent={
          loader ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : locationList.length > 0 ? (
            locationList?.map((locationDetail, index) => (
              <OrganizationStructureAsideList
                organizationDataList={locationDetail}
                key={index}
                setOrgDetail={setLocation}
                orgDetail={location}
                name={locationDetail?.name}
                // loader={loader}
              />
            ))
          ) : (
            <div>No Location Found </div>
          )
        }
        tabContent={
          <OrgLocationHeader
            location={location}
            editData={editData}
            getLocationList={getLocationList}
            setEditData={setEditData}
          />
        }
        asideHeading={"LOCATION"}
      />
      <LocationModal
        open={open}
        handleClose={handleClose}
        editData={editData}
        getLocationList={getLocationList}
      />
    </div>
  );
}

export default LocationParent;
