import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
const VehicleRequestForm = ({
  handleChange,
  values,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  mode,
  setFieldValue,
  validationError,
  spinner,
  state,
}) => {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const signatureRef = useRef();
  const signatureRefApprovedHOD = useRef();
  const navigate = useNavigate();
  const projectDropDown = useSelector(
    (state) => state?.dropdown?.dropdowns?.projectList
  );
  const [projectPO, setProjectPO] = useState([]);
  const [selectedproject, setSelectedProject] = useState(null);

  const handleSignatureEnd = () => {
    const signatureLogisticHeadDataUrl = signatureRef.current.toDataURL();

    const signatureApprovedHODDataUrl =
      signatureRefApprovedHOD.current.toDataURL();

    setFieldValue("requestee_signature", signatureLogisticHeadDataUrl);
    setFieldValue("approvee_hod_signature", signatureApprovedHODDataUrl);
  };
  const projectPoDetail = (project_id) => {
    const data = { user_id, project_id };
    const url = `${baseUrl}fetchpodetailsproject`;
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          setProjectPO(res?.data?.response?.PurchaseOrderDetails);
        }
      })
      .catch((err) => {
        toast.error("something went wrong", { autoClose: 2000 });
      });
  };
  useEffect(() => {
    if (values.project_name) {
      projectPoDetail(values.project_name);
    }
  }, [values.project_name]);
  const handleProjectChange = (selectedOption) => {
    setFieldValue("project_name", selectedOption?.id);

    setSelectedProject(selectedOption);
    if(mode=="edit"){
      setFieldValue("po_no","")
    }
  


  };
  useEffect(() => {
    if (mode == "edit") {
      setSelectedProject({
        id: state?.project?.id,
        project_title: state?.project?.pd_projectTitle,
      });
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="fw-bold mb-4">
                {`${mode === "add" ? "ADD" : "UPDATE"} VEHICLE REQUEST`}{" "}
              </h5>
              <form className="department-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control border-secondary"
                        placeholder="select"
                        name="requested_date_for"
                        value={values.requested_date_for}
                        onChange={handleChange}
                      ></input>
                      <p className="text-danger m-0">
                        {touched.requested_date_for && errors.requested_date_for
                          ? errors.requested_date_for
                          : validationError?.requested_date_for}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Project Name
                      </label>

                      <Select
                        placeholder="Search By Title"
                        style={{ width: "75%" }}
                        className="form-select-control border-secondary"
                        name="project_name"
                        isSearchable={true}
                        options={projectDropDown}
                        getOptionLabel={(projectDropDown) =>
                          projectDropDown["project_title"]
                        }
                        getOptionValue={(projectDropDown) =>
                          projectDropDown["id"]
                        }
                        value={selectedproject}
                        onChange={handleProjectChange}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: "lightgrey",
                            primary: "lightgrey",
                          },
                        })}
                      />
                      <p className="text-danger m-0">
                        {touched.project_name && errors.project_name
                          ? errors.project_name
                          : validationError?.project_name}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        PO Number
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="po_no"
                        value={values.po_no}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        {projectPO?.map((project) => (
                          <option
                            key={project?.id}
                            value={project?.purchase_order_no}
                          >
                            {/* {project.id} */}
                            {project?.purchase_order_no}
                          </option>
                        ))}
                      </select>
                      <p className="text-danger m-0">
                        {touched.po_no && errors.po_no
                          ? errors.po_no
                          : validationError?.po_no}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label fw-bold font-16">
                        Transit Mode
                      </label>
                      <select
                        className="form-control border-secondary"
                        placeholder=""
                        name="transit_mode"
                        value={values.transit_mode}
                        onChange={handleChange}
                      >
                        <option value="" className="text-secondary">
                          Select
                        </option>
                        <option value="Due">By Bus</option>
                        <option value="Returned">By Train</option>
                      </select>
                      <p className="text-danger m-0">
                        {touched.transit_mode && errors.transit_mode
                          ? errors.transit_mode
                          : validationError?.transit_mode}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Material Type
                        </label>
                        <input
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter Material Type"
                          name="material_type"
                          value={values.material_type}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.material_type && errors.material_type
                            ? errors.material_type
                            : validationError?.material_type}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Required Vehicle Size
                        </label>
                        <input
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter Size"
                          name="required_vehicle_size"
                          value={values.required_vehicle_size}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.required_vehicle_size &&
                          errors.required_vehicle_size
                            ? errors.required_vehicle_size
                            : validationError?.required_vehicle_size}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Material Weight
                        </label>
                        <input
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter Weight"
                          name="material_weight"
                          value={values.material_weight}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.material_weight && errors.material_weight
                            ? errors.material_weight
                            : validationError?.material_weight}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Evidence & Confirmation
                        </label>
                        <textarea
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter here"
                          name="evidence_and_confirmation"
                          value={values.evidence_and_confirmation}
                          onChange={handleChange}
                        ></textarea>
                        <p className="text-danger m-0">
                          {touched.evidence_and_confirmation &&
                          errors.evidence_and_confirmation
                            ? errors.evidence_and_confirmation
                            : validationError?.evidence_and_confirmation}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Material Insurance
                        </label>
                        <textarea
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter here"
                          name="material_insurance"
                          value={values.material_insurance}
                          onChange={handleChange}
                        ></textarea>
                        <p className="text-danger m-0">
                          {touched.material_insurance &&
                          errors.material_insurance
                            ? errors.material_insurance
                            : validationError?.material_insurance}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Vehicle Hold
                        </label>
                        <textarea
                          type="text"
                          className="form-control border-secondary"
                          placeholder="Enter here"
                          name="vehicle_hold"
                          value={values.vehicle_hold}
                          onChange={handleChange}
                        ></textarea>
                        <p className="text-danger m-0">
                          {touched.vehicle_hold && errors.vehicle_hold
                            ? errors.vehicle_hold
                            : validationError?.vehicle_hold}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>

                  <div className="row">
                    <h5 className="fw-bold my-4">LOADING ADDRESS</h5>
                    <div className="col-md-9">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Loading Address
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Address "
                          name="loading_address"
                          value={values.loading_address}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.loading_address && errors.loading_address
                            ? errors.loading_address
                            : validationError?.loading_address}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Pincode
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Pincode "
                          name="la_pin_code"
                          value={values.la_pin_code}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.la_pin_code && errors.la_pin_code
                            ? errors.la_pin_code
                            : validationError?.la_pin_code}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Contact Name
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter  Name"
                          name="la_contact_name"
                          value={values.la_contact_name}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.la_contact_name && errors.la_contact_name
                            ? errors.la_contact_name
                            : validationError?.la_contact_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Contact Number
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Number"
                          name="la_contact_number"
                          value={values.la_contact_number}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.la_contact_number && errors.la_contact_number
                            ? errors.la_contact_number
                            : validationError?.la_contact_number}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="fw-bold my-4">DELIVERY ADDRESS</h5>
                    <div className="col-md-9">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Delivery Address
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Address "
                          name="delivery_address"
                          value={values.delivery_address}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.delivery_address && errors.delivery_address
                            ? errors.delivery_address
                            : validationError?.delivery_address}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Pincode
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Pincode "
                          name="da_pin_code"
                          value={values.da_pin_code}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.da_pin_code && errors.da_pin_code
                            ? errors.da_pin_code
                            : validationError?.da_pin_code}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Contact Name
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter  Name"
                          name="da_contact_name"
                          value={values.da_contact_name}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.da_contact_name && errors.da_contact_name
                            ? errors.da_contact_name
                            : validationError?.da_contact_name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label
                          htmlFor=""
                          className="form-label fw-bold font-16"
                        >
                          Contact Number
                        </label>
                        <input
                          className="form-control border-secondary"
                          placeholder="Enter Number"
                          name="da_contact_number"
                          value={values.da_contact_number}
                          onChange={handleChange}
                        ></input>
                        <p className="text-danger m-0">
                          {touched.da_contact_number && errors.da_contact_number
                            ? errors.da_contact_number
                            : validationError?.da_contact_number}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Signature (Logistic Head)
                          </label>
                          <div
                            style={{
                              border: "1px solid #8D93A1",
                              borderRadius: "0.2rem",
                              padding: "2rem",
                            }}
                          >
                            {mode == "add" ? (
                              <SignatureCanvas
                              penColor='blue'
                                ref={signatureRef}
                                name="requestee_signature"
                                value={values?.requestee_signature}
                                onEnd={handleSignatureEnd}
                                canvasProps={{
                                  width: "300px",
                                  height: "100px",
                                  className: "signatureCanvas",
                                }}
                              />
                            ) : (
                              <img
                                src={`https://dev-unti.fictivebox.tech/${values?.requestee_signature}`}
                              />
                            )}
                          </div>
                          {mode=="add"?<button type="button" style={{padding:".5rem",borderRadius:"5px"}}  onClick={() => signatureRef.current.clear()}>Clear Signature</button>:""}
                          <p className="text-danger m-0">
                            {touched.requestee_signature &&
                            errors.requestee_signature
                              ? errors.requestee_signature
                              : validationError?.requestee_signature}
                          </p>
                        
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Signature (Approved by HOD or Project Leader)
                          </label>
                          <div
                            style={{
                              border: "1px solid #8D93A1",
                              borderRadius: "0.2rem",
                              padding: "2rem",
                            }}
                          >
                            {mode == "add" ? (
                              <>
                              <SignatureCanvas
                              penColor='blue'
                                ref={signatureRefApprovedHOD}
                                name="approvee_hod_signature"
                                value={values?.approvee_hod_signature}
                                onEnd={handleSignatureEnd}
                                canvasProps={{
                                  width: "300px",
                                  height: "100px",
                                  className: "signatureCanvas",
                                }}
                                
                              />
                              {/* <button className="text-right"  onClick={() => signatureRefApprovedHOD.current.clear()}>Clear Signature</button> */}
                              </>
                             
                            ) : (
                              <img
                                src={`https://dev-unti.fictivebox.tech/${values?.approvee_hod_signature}`}
                              />
                            )}
                          </div>
                          {mode=="add"?<button type="button" style={{padding:".5rem",borderRadius:"5px"}}  onClick={() => signatureRefApprovedHOD.current.clear()}>Clear Signature</button>:""}
                          <p className="text-danger m-0">
                            {touched.approvee_hod_signature &&
                            errors.approvee_hod_signature
                              ? errors.approvee_hod_signature
                              : validationError?.approvee_hod_signature}
                          </p>
                          
                        </div>
                      </div>

                      <div className="col-md-9">
                        <div className="form-group mb-3">
                          <label
                            htmlFor=""
                            className="form-label fw-bold font-16"
                          >
                            Remark
                          </label>
                          <textarea
                            className="form-control border-secondary"
                            placeholder="Enter Remark "
                            name="remarks"
                            value={values.remarks}
                            onChange={handleChange}
                          ></textarea>
                          <p className="text-danger m-0">
                            {touched.remarks && errors.remarks
                              ? errors.remarks
                              : validationError?.remarks}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {spinner ? (
                    <div className="">
                      <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                      <p>Wait...</p>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-de-danger btn-sm border-danger"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-danger btn-sm border-danger"
                        style={{ marginLeft: "1rem" }}
                        // onClick={() => navigate(-1)}
                      >
                        {mode == "add" ? "ADD" : "UPDATE"}
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleRequestForm;
