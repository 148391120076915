import React from "react";
import "../../HrDepartment.scss"
const UploadDegreeDetail = () => {
  return (
    <>
      <div className="row">
        <div className="card" style={{borderRadius:"5px"}}>
          <h4 className="ps-3">Details</h4>
          <div className="border-bottom"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="pt-2 ps-3">
                <p className="document-detail-heading">College / University</p>
                <p className="document-detail-value">
                  University / College Name
                </p>
              </div>

              <div className="pt-2 ps-3">
                <p className="document-detail-heading">Year of Joining</p>
                <p>10 June, 2016</p>
              </div>
              <div className="pt-2 ps-3" >
                <p className="document-detail-heading">
                  Branch / Specialization
                </p>
                <p>Accounts</p>
              </div>
            </div>
            <div className="col-md-6 pt-2">
              <div className="pt-2">
                <p className="document-detail-heading">Degree</p>
                <p className="document-detail-value">
                  Master of Business Administration
                </p>
              </div>

              <div className='pt-2'>
                <p className="document-detail-heading">Year of Completion</p>
                <p className="document-detail-value">26 July, 2018</p>
              </div>
              <div className="pt-2">
                <p className="document-detail-heading">CGPA / Percentage</p>
                <p className="document-detail-value">8.2</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDegreeDetail;
