import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "react-bootstrap/Spinner";
import "../Style.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function Users({ keys }) {
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const placeholderImage = imageBaseUrl + "assets/user.jpg";
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState("");
  const [perPageUsers, setPerPageUsers] = useState(4);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function handleClose() {
    setShow(false);
  }

  function fetchUsers(value) {
    if (keys === "users") {
      let url;
      if (search.length > 0) {
        url = apiurl + `getuserlist`;
      }
      if (search.length === 0) {
        url = apiurl + `getuserlist`;
      }
      var body = { user_id: user_id, search, accessType: "view" };
      axios
        .post(url, body, config)
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            setUsers(res?.data?.response?.userList);
            // setTotalUsers(res?.data?.response?.userList?.total);
            // setPerPageUsers(res?.data?.response?.userList?.per_page);
          }
        })
        .catch((err) => {
          setLoader(false);
          if (err?.response?.data?.status === "Token is Expired") {
          }
        });
    }
  }

  function handleDeleteUser() {
    const url = apiurl + "setdeleteuser";
    const body = {
      user_id: user_id,
      duser_id: deletingUserId,
      accessType: "delete",
    };
    axios
      .post(url, body, config)
      .then((res) => {
        if (res.status === 200) {
          fetchUsers(currentPage);
          if ((totalUsers - 1) % perPageUsers === 0) {
            setCurrentPage(currentPage - 1);
          }
          toast.success("User Deleted Successfully");
          handleClose();
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
      });
    setDeletingUserId(null);
  }

  /////////// Pagination //////////
  const recordsPerPage = perPageUsers;
  const npage = users ? Math.ceil(totalUsers / recordsPerPage) : [];
  const numbers = [...Array(npage + 1).keys()].slice(1);

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCpage(id) {
    setCurrentPage(id);
  }

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, search, keys]);

  return (
    <>
      {loader ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-6">
                      {/* <h4 className="card-title">Our Users</h4> */}
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <div className="input-group w-50 me-3">
                        <input
                          type="text"
                          name="example-input1-group2"
                          className="form-control form-control-sm border-danger"
                          placeholder="Search"
                          style={{ fontSize: "1rem" }}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                      <div>
                        <Link to="/ProjectsUsers/ProjectsUsersAdd">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            Add New
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {users.length ? (
                      users?.map((obj, i) => {
                        return (
                          <div key={i} className="col-md-3">
                            <div className="card user-card">
                              <div className="card-body">
                                <img
                                  src={
                                    imageBaseUrl + obj.profileimage
                                      ? imageBaseUrl + obj.profileimage
                                      : placeholderImage
                                  }
                                  alt=""
                                  className="d-block mx-auto mb-3 mt-2 rounded-circle"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                                <div className="text-center mb-3">
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 450, hide: 100 }}
                                    overlay={renderTooltip(obj.name)}
                                  >
                                    <NavLink to={`/user-profile/${obj.id}`}>
                                      <h5
                                        className="mb-2 text-capitalize"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {" "}
                                        {obj.name}
                                      </h5>
                                    </NavLink>
                                  </OverlayTrigger>
                                  <span className="badge badge-soft-danger font-14 fw-semibold mb-2">
                                    {obj.userDesignation}
                                  </span>
                                </div>
                                <div className="m-2">
                                  <p className=" mb-2">
                                    <span className="fw-semibold">Phone:</span>{" "}
                                    {obj.phone}
                                  </p>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 100 }}
                                    overlay={renderTooltip(obj.email)}
                                  >
                                    <p className="mb-2">
                                      <span
                                        className="fw-semibold"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        Email:
                                      </span>{" "}
                                      {obj.email}
                                    </p>
                                  </OverlayTrigger>
                                  <p className=" mb-2">
                                    <span className="fw-semibold">
                                      Joining Date:
                                    </span>{" "}
                                    {formatDate(obj.joining_date)}
                                  </p>
                                  <Link
                                    to={`/ProjectsUsers/edit-user/${obj.id}`}
                                    className="btn btn-warning btn-sm px-3 mt-2 mb-0"
                                  >
                                    <i className="las la-pen text-white font-16 text-dark"></i>
                                  </Link>
                                  &nbsp;&nbsp;
                                  <button
                                    onClick={() => {
                                      setDeletingUserId(obj.id);
                                      setShow(true);
                                    }}
                                    className="btn btn-danger btn-sm px-3 mt-2"
                                  >
                                    <i className="las la-trash-alt text-white font-16 text-dark"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h5 className="text-secondary">No User Found</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button className="btn btn-success me-3" onClick={handleDeleteUser}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={handleClose}>
            No
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
