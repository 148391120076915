import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import AddPermissionModal from "../Modals/AddPermissionModal";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

export default function PermissionList() {
  const [showAddModal, setShowAddModal] = useState(false);
  function handleClose() {
    setShowAddModal(false);
    setEditAblePermission("");
  }
  const [editAblePermission, setEditAblePermission] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const [loader, setLoader] = useState(true);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };

  function getPermission() {
    setLoader(true);
    let url = baseUrl + "permissionlist";
    let data = { user_id };
    axios
      .post(url, data, config)
      .then((response) => {
        setLoader(false);
        setPermissionList(response?.data?.response?.permissionList);
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Something went wrong");
      });
  }

  function editPermission(id) {
    let editData = permissionList?.find((e) => e.id === id);
    setEditAblePermission(editData);
    setShowAddModal(true);
  }

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <>
      

      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {/* <Link to="#!">Analytics /</Link> */}
                      </li>
                      <li className="breadcrumb-item active"> Permissions</li>
                    </ol>
                  </div>
                  <h4 className="page-title">MANAGE PERMISSIONS</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddModal(true)}
                      >
                        + Add Permission
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="thead-light">
                          <tr>
                            <th>S. No.</th>
                            <th>Permission Group</th>
                            <th>Short Code</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {permissionList?.map((e, i) => {
                            return (
                              <tr key={e.id}>
                                <td>{i + 1}</td>
                                <td className="text-capitalize">{e.name}</td>
                                <td>{e.short_code}</td>
                                <td>
                                  <i
                                    onClick={() => editPermission(e.id)}
                                    className="las la-pen text-dark font-20 px-1"
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit Permission Group"
                                  ></i>

                                  {/* <button className="btn btn-danger btn-sm px-3" onClick={()=>setShowDeleteModal(true)}>
                  <i className="las la-trash-alt text-white font-16 text-dark"></i>
                </button> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddPermissionModal
        show={showAddModal}
        handleClose={handleClose}
        editAblePermission={editAblePermission}
        getPermission={getPermission}
      />

      <Modal show={showDeleteModal} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button className="btn btn-success me-3">Yes</button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Body>
      </Modal>
      <Footer />
      <Footer />
    </>
  );
}
