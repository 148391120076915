/* eslint-disable no-console */
import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../redux/features/allDropdownSlice";

export default function AddMembersModal({
  show,
  handleClose,
  fetchProjectDetails,
  project_id,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const dropdowns = useSelector(selectDropdowns);
  const userData = dropdowns.userList;

  const [team_member, setTeam_member] = useState([]);
  const [spinner, setSpinner] = useState(false);

  function handleSubmit(e) {
    setSpinner(true);
    e.preventDefault();
    let url = baseUrl + "addprojectmember";
    const data = {
      user_id,
      project_id: [project_id],
      departmentId: null,
      team_member,
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSpinner(false);
          handleClose();
          toast.success("Added Successfully");
          fetchProjectDetails();
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong, Try again later");
      });
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-3">
              <label className="form-label">Add Members</label>
              <Select
                placeholder="Seacrh Members"
                isMulti
                options={userData}
                getOptionLabel={(userData) => userData["name"]}
                getOptionValue={(userData) => userData["id"]}
                onChange={(value) => setTeam_member(value.map((e) => e.id))}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {spinner ? (
            <div className="">
              <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
              <p>Wait...</p>
            </div>
          ) : (
            <Button variant="primary" onClick={(e) => handleSubmit(e)}>
              Add Member
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
