import React, { useEffect, useState } from "react";
import OrganizationStructureAsideList from "../OrganizationComponents/OrganizationStructureAsideList";
import OrgDepartmentHeader from "./OrgDepartmentHeader";
import { businessUnitListApi } from "../OrganizationComponents/organizationStructureApi";
import MainCard from "../../../CommonComponent/MainCard";

function DepartmentParent() {
  const [open, setOpen] = React.useState(false);
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
const loader = false;
  const getDepartment = () => {
    businessUnitListApi().then((res) => {
      const data = res?.data?.response?.data;
      setDepartmentList(data);
      if (data?.length) {
        setDepartment(data[0]);
      }
    });
  };
  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <div className="row">
      <h4 className="mb-0 text-uppercase fw-normal">ORGANISATION STRUCTURE</h4>
      <MainCard
        asideContent={
          loader ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : departmentList.length > 0 ? (
            departmentList?.map((departmentDetail, index) => (
              <OrganizationStructureAsideList
                organizationDataList={departmentDetail}
                key={index}
                setOrgDetail={setDepartment}
                orgDetail={department}
              />
            ))
          ) : (
            <div>No Department Found </div>
          )
        }
        tabContent={<OrgDepartmentHeader department={department} />}
        asideHeading={"DEPARTMENT"}
      />
      {/* <div className="col-md-3">
        <div
          style={{
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.25)",
            background: "rgba(255, 255, 255, 1)",
            border: "1px",
            borderRadius: "10px",
          }}
        >
          {departmentList?.map((departmentDetail, index) => (
            <OrganizationStructureAsideList
              organizationDataList={departmentDetail}
              key={index}
              setOrgDetail={setDepartment}
              orgDetail={department}
            />
          ))}
        </div>
      </div>

      <div className="col-md-9">
        <OrgDepartmentHeader department={department} />
      </div> */}
    </div>
  );
}

export default DepartmentParent;
