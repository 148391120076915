import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import JobWorkTimeCommonTable from "../../MaterialUiComponent/JobWorkTimeCommonTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimeType,
  fetchTimeType,
} from "../../../redux/features/Hr/TimeTypeSlice";

import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { deleteTimeType } from "../../hrApi";
import { toTitleCase } from "../../CommonFunction";
const TimeTypeTabMain = ({ getEditData = { getEditData } }) => {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const timeTypeList = useSelector(getTimeType);
  const [deleteModalOPen, setDeleteModalOPen] = useState(false);
  const columns = [
    { id: "type", label: "Type", minWidth: "6rem",format:toTitleCase }, // Increase width
    { id: "description", label: "Description", minWidth: "30rem" }, // Increase width
    { id: "appliesTo", label: "Applies To", minWidth: "8rem" }, // Increase width
    { id: "actions", label: "Action", minWidth: 100 }, // Keep as is
  ];
  useEffect(() => {
    dispatch(fetchTimeType(dispatch));
  }, []);
  const handleClose = () => {
    setDeleteModalOPen(false);
  };

  const timeTypeDelete = async (id) => {
    dispatch(setLoading(true));
    const reqpayload = { id: id };
    try {
      const response = await deleteTimeType(reqpayload);
      toast.success(response?.data?.message, { autoClose: 2000 });
      dispatch(fetchTimeType());
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message, { autoClose: 2000 });
    } finally {
      dispatch(setLoading(true));
    }
  };
  return (
    <section className="inProbation">
      <div className="bg-white mt-2 rounded mx-3">
        <div className="d-flex justify-content-between align-items-center px-3 mb-3">
          <h4 className="text-uppercase">Time Type</h4>
          <div className="d-flex align-items-center">
            <input
              className="days_selector_style mb-lg-2 mb-3 mr-lg-3"
              type="text"
              placeholder="Search.."
            />
            <button className="search_Button" type="submit">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <JobWorkTimeCommonTable
          columns={columns}
          rows={timeTypeList}
          getEditData={getEditData}
          deleteData={timeTypeDelete}
          loader={loader}
          handleClose={handleClose}
          deleteModalOPen={deleteModalOPen}
          setDeleteModalOPen={setDeleteModalOPen}
        />
      </div>
    </section>
  );
};

export default TimeTypeTabMain;
