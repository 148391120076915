import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../HrDepartment.scss"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function EditEmployeeOrgnization({ handleClose, open }) {
  const [isFocused, setIsFocused] = useState("");
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="edit_primary_details">Job Details</Typography>
          <form>
            <div className="row">
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "Department" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Department
                </label>
                <div
                  className={`${
                    isFocused == "Department"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="Department"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select"
                  >
                    <option>Select</option>
                    <option>Manufaturing</option>
                    <option>Quality</option>
                  </select>
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5 ">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "location" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Location
                </label>
                <div
                  className={`${
                    isFocused == "location"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="location"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select"
                  >
                    <option>Select</option>
                    <option>Delhi NCR</option>
                    <option>Noida</option>
                  </select>
                </div>
              </div>

              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "joining_date" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Legal Entity
                </label>
                <div
                  className={`${
                    isFocused == "joining_date"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="Legal Entity"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "rep_manager" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Cost Center
                </label>
                <div
                  className={`${
                    isFocused == "rep_manager"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="Cost Center"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "rep_manager" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Reports To
                </label>
                <div
                  className={`${
                    isFocused == "rep_manager"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="Reports To"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "rep_manager" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Manager of Manager
                </label>
                <div
                  className={`${
                    isFocused == "rep_manager"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="Manager of Manager"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "prob_policy" ? "text-danger" : "text-black"
                  }`}
                  style={{
                    paddingLeft: ".75rem",
                    marginTop: "1rem",
                    fontFamily: "Poppins",
                  }}
                >
                  Probation Policy
                </label>
                <div
                  className={`${
                    isFocused == "prob_policy"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <select
                    name="prob_policy"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Select"
                  >
                    <option>Select</option>
                    <option>3Month</option>
                    <option>6Month</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div className="col-7"></div>
              <div
                className="col-5"
                style={{ marginLeft: "Auto", width: "auto" }}
              >
                <button
                  type="button"
                  class="btn btn-de-danger btn-sm border-danger"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
                <button type="Submit" class="btn btn-danger mx-2">
                  Continue
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
