import React, { useState , useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import ProjectDetailsForm from "../Components/ProjectForms/AddForms/ProjectDetailsForm";
import ScopeOfWork from "../Components/ProjectForms/AddForms/ScopeOfWork";
import LOAForm from "../Components/ProjectForms/AddForms/LOAForm";
import "./Style.css";

function NewProjectAdd() {
  const [activeAccordionKey, setActiveAccordionKey] = useState("0");
  const [projectId, setProjectId] = useState("");

  const changeKey = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };

  function getProjectId(id) {
    setProjectId(id);
  }

 
  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to=""></Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/projects">Projects/</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add New Project
                      </li>
                    </ol>
                  </div>
                  <h5 className="fw-bold">ADD NEW PROJECT</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Accordion activeKey={activeAccordionKey}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() => changeKey("0")}
                          className="accordianHeader"
                        >
                          PROJECTS DETAILS
                        </Accordion.Header>
                        <Accordion.Body>
                          <ProjectDetailsForm
                            changeKey={changeKey}
                            getProjectId={getProjectId}
                          ></ProjectDetailsForm>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header
                          onClick={() => changeKey("1")}
                          className="accordianHeader"
                        >
                          LOA/PO COST BIFURCATION
                        </Accordion.Header>
                        <Accordion.Body>
                          <LOAForm
                            projectId={projectId}
                            changeKey={changeKey}
                          ></LOAForm>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header
                          onClick={() => changeKey("2")}
                          className="accordianHeader"
                        >
                          SCOPE OF WORK
                        </Accordion.Header>
                        <Accordion.Body>
                          <ScopeOfWork projectId={projectId}></ScopeOfWork>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewProjectAdd;
