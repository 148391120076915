import React, { useEffect, useState } from "react";
import CommonTable from "../Common/CommonTable";
import "../PurchaseStyle/PurchaseStyle.css";
import CommonTd from "../../Tender/Common/CommonTd";
import CommonPurchaseModal from "../Modal/CommonPurchaseModal";
import CommonPurchaseEditModal from "../Modal/CommonPurchaseEditModal";
import { getUnitMasterList } from "../purchaseApi";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { errorsMessage } from "../../constant";
import CommonPurchaseDelete from "../Modal/CommonPurchaseDelete";
import { unitMasterSchema } from "../ValidationSchema/SettingSchema";
import { Spinner } from "react-bootstrap";
import { IoIosSearch } from "react-icons/io";

function UnitMasterList() {
  const [show, setShow] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [unitMasterList, setUnitMasterList] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({
    name: "",
    prefix_type: "",
    sub_group_id: "",
  });

  const tableHeading = [
    {
      name: "Unit Id",
      width: "5rem",
    },
    {
      name: "Unit Name",
      width: "10rem",
    },
    {
      name: "Edit",
      width: "3rem",
    },
    {
      name: "Delete",
      width: "4rem",
    },
  ];

  function unitData() {
    setLoading(true);
    getUnitMasterList({ user_id, search })
      .then((res) => {
        setUnitMasterList(res?.data?.response?.UnitMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    unitData();
  }, [search]);

  const handleEditClick = (element) => {
    setEditData(element);
    setEditShow(true);
  };

  const inputFields = [
    {
      label: "Unit Name*",
      name: "unit_name",
      placeHolder: "Enter Unit Name",
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <h4 className="page-title">UNIT MASTER</h4>
              </div>
              <div className="col-sm-4 text-end">
                <div className="postion-relative">
                  <input
                    className="form-control w-75 ms-auto"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IoIosSearch
                    size={30}
                    className="position-absolute top-0 end-0 mt-1 me-2"
                    style={{ color: "ef4d56" }}
                  />
                </div>
              </div>
              <div className="col-sm-auto text-end">
                <button
                  className="btn btn-danger"
                  onClick={() => setShow(true)}
                >
                  Add New
                </button>
              </div>
            </div>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive table-container-scroll"
                    style={{ overflowX: "auto" }}
                  >
                    <table
                      className="table common-table"
                      style={{ tableLayout: "unset" }}
                    >
                      <CommonTable tableHeading={tableHeading}></CommonTable>
                      <tbody>
                        {unitMasterList?.map((element, index) => (
                          <tr key={index}>
                            <CommonTd
                              width="5rem"
                              value={element.id}
                            ></CommonTd>

                            <CommonTd
                              width="10rem"
                              value={element?.unit_name}
                            ></CommonTd>
                            <td>
                              <p
                                style={{
                                  minWidth: "3rem",
                                  maxWidth: "3rem",
                                  margin: "auto",
                                  padding: "auto",
                                  cursor: "pointer",
                                }}
                                className="text-center"
                              >
                                <FaRegEdit
                                  className="text-dark font-20"
                                  style={{ margin: "auto" }}
                                  onClick={() => handleEditClick(element)}
                                />
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  minWidth: "4rem",
                                  maxWidth: "4rem",
                                  margin: "auto",
                                  padding: "auto",
                                  cursor: "pointer",
                                }}
                                className="text-center"
                              >
                                <RiDeleteBinLine
                                  data-bs-toggle="modal"
                                  data-bs-target="#unitMasterDelete"
                                  className="text-danger font-20"
                                  style={{ margin: "auto" }}
                                  onClick={() => setDeleteId(element.id)}
                                />
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Purchase Add  modal  */}
                    <CommonPurchaseModal
                      show={show}
                      setShow={setShow}
                      url={"addpurchaseunitmaster"}
                      heading={"UNIT MASTER"}
                      initialValues={{
                        unit_name: "",
                      }}
                      inputFields={inputFields}
                      updatedList={unitData}
                      validationSchema={unitMasterSchema}
                    />
                    {/* Edit Modal */}
                    {editShow && (
                      <CommonPurchaseEditModal
                        show={editShow}
                        setShow={setEditShow}
                        url={"updatepurchaseunitmaster"}
                        heading={"UNIT MASTER"}
                        updatedList={unitData}
                        initialValues={editData}
                        inputFields={inputFields}
                        idKey={"unit_master_id"}
                        validationSchema={unitMasterSchema}
                      />
                    )}
                    {/* Delete Modal */}
                    <CommonPurchaseDelete
                      group_id={deleteId}
                      updatedList={unitData}
                      url={"deletepurchaseunitmaster"}
                      groupDelete={"unitMasterDelete"}
                      idKey={"unit_master_id"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UnitMasterList;
