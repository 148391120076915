import React from "react";
import LocationSummary from "./LocationSummary";
import OrganizationStructureHeaderName from "../OrganizationComponents/OrganizationStructureHeaderName";

function OrgLocationHeader({ location,editData, getLocationList, setEditData }) {
  return (
    <div >
      {/* style={{ backgroundColor: "rgba(245, 245, 245, 1)" }} */}
      <OrganizationStructureHeaderName name={location?.name} />
      <LocationSummary detail={location} editData={editData} getLocationList={getLocationList} setEditData={setEditData}/>
    </div>
  );
}

export default OrgLocationHeader;
