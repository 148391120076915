import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { workerTypeList } from "../../../HrDepartment/hrApi";
import { setLoading } from "./LoaderSlice";
const initialState = { workerType: [] };
export const fetchWorkerType = createAsyncThunk(
  "fetch/workerTypeList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await workerTypeList();

      return response?.data?.response?.data;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const WorkerTypeSlice = createSlice({
  name: "workerType",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchWorkerType.pending, (state) => {})
      .addCase(fetchWorkerType.fulfilled, (state, action) => {
        state.workerType = action.payload;
      })
     
      .addCase(fetchWorkerType.rejected, (state, action) => {});
  },
});
export const getWorkerType = (state) => state?.WorkerTypeLists?.workerType;

export default WorkerTypeSlice.reducer;
