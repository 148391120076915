import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DashboardProgress from "../Components/DashboardProgress";
import TickPlacementBars from "./DashoardBarGraph";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { errorsMessage } from "../constant";
import { Link } from "react-router-dom";
import DashoardBarGraph from "./DashoardBarGraph";

function StoreDashboard() {
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [loader, setLoader] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const getDashboardData = () => {
    const url = `${baseUrl}storedashboarddetails`;
    const data = { user_id };
    setLoader(true);
    axios
      .post(url, data, config)
      .then((res) => {
   
        if (res.status == 200) {
          setLoader(false);
          setDashboardData(res?.data?.response?.StoreDashboard);
        }
      })
      .catch((err) => {
        setLoader(false);
        errorsMessage("Please try again later,Something went wrong");
      });
  };
  console.log("data", dashboardData);
  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        {loader ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="page-content-tab responsive-tab">
            <div className="container-fluid">
              <div className="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <h4
                      class="page-title my-3"
                      style={{ fontSize: "24px", fontWeight: "500" }}
                    >
                      {" "}
                      DASHBOARD{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          {" "}
                          Total Item Value
                        </p>
                        <h6>
                          {" "}
                          {dashboardData?.total_item_value == null
                            ? "--"
                            : dashboardData?.total_item_value}
                        </h6>
                      </div>
                      <div>
                        <svg
                          width="82"
                          height="81"
                          viewBox="0 0 82 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.21"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.973999 40.6523V60.8632C0.973999 71.9089 9.92829 80.8632 20.974 80.8632H41.1849H61.3958C72.4415 80.8632 81.3958 71.9089 81.3958 60.8632V40.6523V20.4414C81.3958 9.39571 72.4415 0.441406 61.3958 0.441406H41.1849H20.974C9.9283 0.441406 0.973999 9.3957 0.973999 20.4414V40.6523Z"
                            fill="#8280FF"
                          />
                          <path
                            opacity="0.587821"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M28.6748 31.7189C28.6748 35.667 31.8753 38.8675 35.8234 38.8675C39.7715 38.8675 42.972 35.667 42.972 31.7189C42.972 27.7709 39.7715 24.5703 35.8234 24.5703C31.8753 24.5703 28.6748 27.7709 28.6748 31.7189ZM46.5462 38.8682C46.5462 41.8292 48.9466 44.2296 51.9077 44.2296C54.8687 44.2296 57.2691 41.8292 57.2691 38.8682C57.2691 35.9071 54.8687 33.5067 51.9077 33.5067C48.9466 33.5067 46.5462 35.9071 46.5462 38.8682Z"
                            fill="#8280FF"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M35.7936 42.4404C27.3557 42.4404 20.4329 46.777 19.7402 55.3065C19.7024 55.7711 20.591 56.7376 21.0392 56.7376H50.5615C51.904 56.7376 51.9249 55.6573 51.904 55.3079C51.3803 46.5387 44.3503 42.4404 35.7936 42.4404ZM61.6582 56.7384H54.7671C54.767 52.7156 53.4378 49.0033 51.1947 46.0166C57.2827 46.0831 62.2537 49.1611 62.6278 55.6661C62.6429 55.9281 62.6278 56.7384 61.6582 56.7384Z"
                            fill="#8280FF"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                            fill="#00B69B"
                          />
                        </svg>
                      </span>{" "}
                      <span>1.3%</span> Up from last month
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          New Order Requests
                        </p>
                        <h6>
                          {" "}
                          {dashboardData?.new_order_requests == null
                            ? "--"
                            : dashboardData?.new_order_requests}
                        </h6>
                      </div>
                      <div>
                        <svg
                          width="81"
                          height="81"
                          viewBox="0 0 81 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.21"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.360352 40.6523V60.8632C0.360352 71.9089 9.31464 80.8632 20.3603 80.8632H40.5713H60.7822C71.8279 80.8632 80.7822 71.9089 80.7822 60.8632V40.6523V20.4414C80.7822 9.39571 71.8279 0.441406 60.7822 0.441406H40.5713H20.3603C9.31466 0.441406 0.360352 9.3957 0.360352 20.4414V40.6523Z"
                            fill="#FEC53D"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.4656 33.0351L37.7569 43.0183C37.9431 43.1258 38.1384 43.2035 38.3371 43.2527V62.6146L21.6988 52.768C20.9344 52.3157 20.4656 51.4934 20.4656 50.6052V33.0351ZM60.6765 32.7695V50.605C60.6765 51.4932 60.2077 52.3154 59.4433 52.7678L42.805 62.6144V43.0829C42.8455 43.0626 42.8857 43.0411 42.9255 43.0181L60.6765 32.7695Z"
                            fill="#FEC53D"
                          />
                          <path
                            opacity="0.499209"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.0088 28.1899C21.22 27.9232 21.4866 27.6978 21.7974 27.5322L39.3896 18.1622C40.1282 17.7688 41.014 17.7688 41.7525 18.1622L59.3448 27.5322C59.5844 27.6598 59.7977 27.823 59.9796 28.0133L40.6916 39.1492C40.5648 39.2225 40.4478 39.3062 40.3413 39.3988C40.2347 39.3062 40.1178 39.2225 39.9909 39.1492L21.0088 28.1899Z"
                            fill="#FEC53D"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                            fill="#00B69B"
                          />
                        </svg>
                      </span>{" "}
                      <span>1.3%</span> Up from last month
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          Total Challan
                        </p>
                        <h6>
                          {dashboardData?.delivery_challans == null
                            ? "--"
                            : dashboardData?.delivery_challans}
                        </h6>
                      </div>
                      <div>
                        <svg
                          width="82"
                          height="81"
                          viewBox="0 0 82 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.21"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.746094 40.6523V60.8632C0.746094 71.9089 9.70039 80.8632 20.7461 80.8632H40.957H61.1679C72.2136 80.8632 81.1679 71.9089 81.1679 60.8632V40.6523V20.4414C81.1679 9.39571 72.2136 0.441406 61.1679 0.441406H40.957H20.7461C9.7004 0.441406 0.746094 9.3957 0.746094 20.4414V40.6523Z"
                            fill="#4AD991"
                          />
                          <path
                            d="M26.3622 55.2488H57.6373C58.7888 55.2488 59.7223 56.1823 59.7223 57.3338C59.7223 58.4854 58.7888 59.4188 57.6373 59.4188H24.2771C23.1256 59.4188 22.1921 58.4854 22.1921 57.3338V23.9737C22.1921 22.8222 23.1256 21.8887 24.2771 21.8887C25.4287 21.8887 26.3622 22.8222 26.3622 23.9737V55.2488Z"
                            fill="#4AD991"
                          />
                          <path
                            opacity="0.5"
                            d="M34.1383 46.2492C33.3508 47.0893 32.0313 47.1319 31.1912 46.3443C30.3511 45.5567 30.3086 44.2373 31.0962 43.3972L38.9149 35.0571C39.6766 34.2447 40.9423 34.1742 41.7894 34.8971L47.9605 40.1631L56.0009 29.9787C56.7144 29.0749 58.0255 28.9206 58.9293 29.6342C59.8331 30.3477 59.9874 31.6588 59.2738 32.5626L49.8913 44.4472C49.1585 45.3754 47.8011 45.5089 46.9014 44.7412L40.5962 39.3608L34.1383 46.2492Z"
                            fill="#4AD991"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="34"
                          height="33"
                          viewBox="0 0 34 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.3303 24.308L25.3997 21.2386L18.8587 14.6976L13.4973 20.059L3.56519 10.1135L5.4551 8.22363L13.4973 16.2658L18.8587 10.9044L27.303 19.3352L30.3725 16.2658V24.308H22.3303Z"
                            fill="#F93C65"
                          />
                        </svg>
                      </span>{" "}
                      <span className="text-danger">1.3%</span> Up from last
                      month
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          Vehicle Requests
                        </p>
                        <h6>
                          {dashboardData?.vehicle_requests == null
                            ? "--"
                            : dashboardData?.vehicle_requests}
                        </h6>
                      </div>
                      <div>
                        <svg
                          width="81"
                          height="81"
                          viewBox="0 0 81 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.132568 40.6523V60.8632C0.132568 71.9089 9.08686 80.8632 20.1326 80.8632H40.3435H60.5544C71.6001 80.8632 80.5544 71.9089 80.5544 60.8632V40.6523V20.4414C80.5544 9.39571 71.6001 0.441406 60.5544 0.441406H40.3435H20.1326C9.08687 0.441406 0.132568 9.3957 0.132568 20.4414V40.6523Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.78"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M38.509 32.3551C38.5359 32.0059 38.827 31.7363 39.1772 31.7363H39.7373C40.0816 31.7363 40.3699 31.9972 40.4041 32.3398L41.2374 40.6721L47.1547 44.0534C47.3635 44.1727 47.4924 44.3948 47.4924 44.6353V45.1561C47.4924 45.598 47.0722 45.9189 46.6459 45.8027L38.1974 43.4985C37.8873 43.414 37.6809 43.1211 37.7055 42.8006L38.509 32.3551Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.901274"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M30.5881 20.5279C30.2341 20.106 29.5506 20.2675 29.4228 20.8032L27.2333 29.9822C27.1292 30.4187 27.4751 30.8322 27.9232 30.8068L37.3657 30.2717C37.9165 30.2405 38.1958 29.5944 37.8412 29.1718L35.4261 26.2936C36.9876 25.76 38.6434 25.4812 40.3437 25.4812C48.7333 25.4812 55.5345 32.2824 55.5345 40.672C55.5345 49.0616 48.7333 55.8628 40.3437 55.8628C31.9541 55.8628 25.1529 49.0616 25.1529 40.672C25.1529 39.2636 25.3436 37.8842 25.7155 36.5584L22.2741 35.593C21.8209 37.2084 21.5786 38.9119 21.5786 40.672C21.5786 51.0357 29.98 59.4371 40.3437 59.4371C50.7074 59.4371 59.1088 51.0357 59.1088 40.672C59.1088 30.3083 50.7074 21.9069 40.3437 21.9069C37.7366 21.9069 35.2537 22.4386 32.9975 23.3994L30.5881 20.5279Z"
                            fill="#FF9066"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                            fill="#00B69B"
                          />
                        </svg>
                      </span>{" "}
                      <span>1.3%</span> Up from last month
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          Order Delivered
                        </p>
                        <h6>456</h6>
                      </div>
                      <div>
                        <svg
                          width="81"
                          height="81"
                          viewBox="0 0 81 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.132568 40.6523V60.8632C0.132568 71.9089 9.08686 80.8632 20.1326 80.8632H40.3435H60.5544C71.6001 80.8632 80.5544 71.9089 80.5544 60.8632V40.6523V20.4414C80.5544 9.39571 71.6001 0.441406 60.5544 0.441406H40.3435H20.1326C9.08687 0.441406 0.132568 9.3957 0.132568 20.4414V40.6523Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.78"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M38.509 32.3551C38.5359 32.0059 38.827 31.7363 39.1772 31.7363H39.7373C40.0816 31.7363 40.3699 31.9972 40.4041 32.3398L41.2374 40.6721L47.1547 44.0534C47.3635 44.1727 47.4924 44.3948 47.4924 44.6353V45.1561C47.4924 45.598 47.0722 45.9189 46.6459 45.8027L38.1974 43.4985C37.8873 43.414 37.6809 43.1211 37.7055 42.8006L38.509 32.3551Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.901274"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M30.5881 20.5279C30.2341 20.106 29.5506 20.2675 29.4228 20.8032L27.2333 29.9822C27.1292 30.4187 27.4751 30.8322 27.9232 30.8068L37.3657 30.2717C37.9165 30.2405 38.1958 29.5944 37.8412 29.1718L35.4261 26.2936C36.9876 25.76 38.6434 25.4812 40.3437 25.4812C48.7333 25.4812 55.5345 32.2824 55.5345 40.672C55.5345 49.0616 48.7333 55.8628 40.3437 55.8628C31.9541 55.8628 25.1529 49.0616 25.1529 40.672C25.1529 39.2636 25.3436 37.8842 25.7155 36.5584L22.2741 35.593C21.8209 37.2084 21.5786 38.9119 21.5786 40.672C21.5786 51.0357 29.98 59.4371 40.3437 59.4371C50.7074 59.4371 59.1088 51.0357 59.1088 40.672C59.1088 30.3083 50.7074 21.9069 40.3437 21.9069C37.7366 21.9069 35.2537 22.4386 32.9975 23.3994L30.5881 20.5279Z"
                            fill="#FF9066"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                            fill="#00B69B"
                          />
                        </svg>
                      </span>{" "}
                      <span>1.3%</span> Up from last month
                    </p>
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="purchase_card mb-3">
                    <div className="d-flex justify-content-between">
                      <div className="purchase_card_header">
                        <p style={{ fontSize: "0.996rem", color: "#292929" }}>
                          Material In Count
                        </p>
                        <h6>
                          {dashboardData?.material_in_count == null
                            ? "--"
                            : dashboardData?.material_in_count}
                        </h6>
                      </div>
                      <div>
                        <svg
                          width="81"
                          height="81"
                          viewBox="0 0 81 81"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.132568 40.6523V60.8632C0.132568 71.9089 9.08686 80.8632 20.1326 80.8632H40.3435H60.5544C71.6001 80.8632 80.5544 71.9089 80.5544 60.8632V40.6523V20.4414C80.5544 9.39571 71.6001 0.441406 60.5544 0.441406H40.3435H20.1326C9.08687 0.441406 0.132568 9.3957 0.132568 20.4414V40.6523Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.78"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M38.509 32.3551C38.5359 32.0059 38.827 31.7363 39.1772 31.7363H39.7373C40.0816 31.7363 40.3699 31.9972 40.4041 32.3398L41.2374 40.6721L47.1547 44.0534C47.3635 44.1727 47.4924 44.3948 47.4924 44.6353V45.1561C47.4924 45.598 47.0722 45.9189 46.6459 45.8027L38.1974 43.4985C37.8873 43.414 37.6809 43.1211 37.7055 42.8006L38.509 32.3551Z"
                            fill="#FF9066"
                          />
                          <path
                            opacity="0.901274"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M30.5881 20.5279C30.2341 20.106 29.5506 20.2675 29.4228 20.8032L27.2333 29.9822C27.1292 30.4187 27.4751 30.8322 27.9232 30.8068L37.3657 30.2717C37.9165 30.2405 38.1958 29.5944 37.8412 29.1718L35.4261 26.2936C36.9876 25.76 38.6434 25.4812 40.3437 25.4812C48.7333 25.4812 55.5345 32.2824 55.5345 40.672C55.5345 49.0616 48.7333 55.8628 40.3437 55.8628C31.9541 55.8628 25.1529 49.0616 25.1529 40.672C25.1529 39.2636 25.3436 37.8842 25.7155 36.5584L22.2741 35.593C21.8209 37.2084 21.5786 38.9119 21.5786 40.672C21.5786 51.0357 29.98 59.4371 40.3437 59.4371C50.7074 59.4371 59.1088 51.0357 59.1088 40.672C59.1088 30.3083 50.7074 21.9069 40.3437 21.9069C37.7366 21.9069 35.2537 22.4386 32.9975 23.3994L30.5881 20.5279Z"
                            fill="#FF9066"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="mb-0 pt-4">
                      {" "}
                      <span>
                        {" "}
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.9438 8.22363L25.0132 11.2931L18.4723 17.834L13.1108 12.4726L3.17871 22.4181L5.06862 24.308L13.1108 16.2658L18.4723 21.6273L26.9165 13.1964L29.986 16.2658V8.22363H21.9438Z"
                            fill="#00B69B"
                          />
                        </svg>
                      </span>{" "}
                      <span>1.3%</span> Up from last month
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-4">
                <h4 className="mb-4 mt-3">STORE LOCATION</h4>

                <p
                  className="card-title text-end "
                  style={{ fontSize: "1rem", cursor: "pointer",paddingBottom:"1rem"  }}
                >
                  <Link to ="/all_store">
                  View All
                  </Link>
                </p>
                {dashboardData?.store_records?.length ? (
                  dashboardData?.store_records?.map((store) => {
                    return (
                      <div className="col-md-3">
                        <div
                          style={{
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            padding: "0.8rem",
                            borderRadius: "0.4rem",
                            maxHeight: "17rem",
                            minHeight: "15rem",
                          }}
                        >
                          <h6
                            className="mb-0"
                            style={{ fontSize: "1.125rem", fontWeight: "600" }}
                          >
                            {store?.store_name == null
                              ? "--"
                              : store?.store_name}
                          </h6>
                          <p style={{ fontSize: "0.75rem", fontWeight: "500" }}>
                            {store?.store_address == null
                              ? "--"
                              : store?.store_address}
                          </p>

                          <p
                            className="text-center"
                            style={{
                              background: "#FFE0E1",
                              color: "#D03438",
                              padding: "0.4rem",
                              width: "45%",
                              borderRadius: "0.4rem",
                            }}
                          >
                            {store?.store_type == null
                              ? "--"
                              : store?.store_type}
                          </p>
                          <div>
                            <p className="mb-2">
                              Store Head : <span>{store?.keeper_name == null
                              ? "--"
                              : store?.keeper_name}</span>
                            </p>
                            <p>
                              Number : <span>{store?.contact_no == null
                              ? "--"
                              : store?.contact_no}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>No Store Found</div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-radius overflow-hidden mt-5">
                    <div className="card-header border-0">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4
                            className="card-title"
                            style={{ fontSize: "1.1rem" }}
                          >
                            Order/Delivery Overview{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <DashoardBarGraph monthOrderCount={dashboardData?.monthly_order_counts
} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-radius overflow-hidden mt-5">
                    <div className="card-header border-0">
                      <div className="row align-items-center">
                        <div className="col d-flex justify-content-between">
                          <h4
                            className="card-title"
                            style={{ fontSize: "1.1rem" }}
                          >
                            Recent Vehicle Requests{" "}
                          </h4>
                          <p
                            className="card-title"
                            style={{ fontSize: "1rem" }}
                          >
                           <Link to ={"/vehicle-request-list"}> View All </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      {dashboardData?.recentvehicleRequests?.length ? (
                        <Table>
                          <thead>
                            <tr>
                              <th className="col-2">S.No</th>
                              <th>Project Name</th>
                              <th>Material Type</th>

                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboardData?.recentvehicleRequests?.map(
                              (item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {item?.project?.pd_projectTitle == null
                                      ? "--"
                                      : item?.project?.pd_projectTitle}
                                  </td>
                                  <td>
                                    {item?.material_type == null
                                      ? "--"
                                      : item?.material_type}
                                  </td>
                                  <td>
                                    {" "}
                                    <Badge bg="success">
                                      {item?.request_status == null
                                        ? "--"
                                        : item?.request_status == "1"
                                        ? "Success"
                                        : "Pending"}
                                    </Badge>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        "No Request Found"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}
export default StoreDashboard;
