import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getTicketTypeList, getBoardGroup } from "../redux/api";
import { useFormik } from "formik";
import { ticketSchema } from "../schemas/Index";
import { errorsMessage } from "../constant";

let initialValues = {
  lot_id: "",
  ticket_type: "6",
  board_group_id: "",
  title: "",
  description: "",
  priority: "",
  estimated_time: "0",
  reporting_to: "",
  start_date: "",
  due_date: "",
  assignee: "",
  board_group_status_id: "",
  required_qty: "20",
  ticket_attachement: "",
};

export default function TicketModal({
  showTicketModal,
  handleClose,
  kanbanSchedule,
  projectMembers,
  lotData,
  poStartDate,
}) {
  console.log("po start date", poStartDate);
  const [validationError, setValidationError] = useState(null);
  const [ticketTypeDropdown, setTicketTypeDropdown] = useState([]);
  const [spinner, SetSpinner] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState("");
  const [minStartDate, setMinStartDate] = useState("");
  const [boardGroup, setBoardGroup] = useState([]);
  const [groupStatus, setGroupStatus] = useState([]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ticketSchema,
    onSubmit: (values) => {
      addTicket(values);
    },
  });

  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const param = useParams();
  let project_id = Number(param.id);

  let startDate = values.start_date
    ? `${values.start_date.getFullYear()}-${
        values.start_date.getMonth() + 1
      }-${values.start_date.getDate()}`
    : "";
  let dueDate = values.due_date
    ? `${values.due_date.getFullYear()}-${
        values.due_date.getMonth() + 1
      }-${values.due_date.getDate()}`
    : "";

  function addTicket(value) {
    SetSpinner(true);
    const url = baseUrl + "storeuntiticket";
    let data = {
      ...value,
      user_id,
      start_date: startDate,
      due_date: dueDate,
      project_id,
    };
    axios
      .post(url, data, config)
      .then((response) => {
        SetSpinner(false);
        if (response.data.success === true) {
          toast.success("Added Successfully");
          handleClose();
          kanbanSchedule();
        }
      })
      .catch((error) => {
        SetSpinner(false);
        errorsMessage(error?.response?.status);
        if (error?.response?.data?.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
      });
  }

  function getDropdowns() {
    if (showTicketModal) {
      getTicketTypeList({ user_id: user_id })
        .then((response) => {
          setTicketTypeDropdown(response?.data?.response?.TicketTypeList);
        })
        .catch((err) => {});

      getBoardGroup({ user_id: user_id, project_id: project_id })
        .then((response) => {
          setBoardGroup(response?.data?.response?.groupDropDown);
        })
        .catch((err) => {});
    }
  }

  function getboardgroupstatusdropdown() {
    if (showTicketModal) {
      const url = baseUrl + "getboardgroupstatusdropdown";
      let data = { user_id, board_group_id: values.board_group_id };
      const getToken = user?.response?.token;
      const bearer_token = `Bearer ${getToken}`;
      axios
        .post(url, data, {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setGroupStatus(response?.data?.response?.statusDropDown);
        })
        .catch((error) => {});
    }
  }

  function getDate(id) {
    if (values.lot_id) {
      let lot = lotData.find((e) => e.id === +id);
      setMaxEndDate(lot?.dpExtension ? lot?.dpExtension : lot.completionDate);
      setMinStartDate(lot.startDate);
    }
  }

  useEffect(() => {
    getDropdowns();
    resetForm();
    setValidationError(null);
  }, [showTicketModal]);

  useEffect(() => {
    getDate(values.lot_id);
  }, [values.lot_id]);

  useEffect(() => {
    if (values.board_group_id) {
      getboardgroupstatusdropdown();
    }
  }, [values.board_group_id]);

  return (
    <>
      <Modal show={showTicketModal} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <h4 className="text-white">Create ticket</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div
                className="col-md-7"
                style={{ maxHeight: "600px", overflowY: "scroll" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Select LOT</label>
                      <select
                        className="form-select border-secondary "
                        name="lot_id"
                        onBlur={handleBlur}
                        value={values.lot_id}
                        onChange={(e) => {
                          setFieldValue("lot_id", e.target.value);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Select a LOT
                        </option>
                        {lotData?.map((e, i) => {
                          return (
                            <option value={e.id} key={i}>
                              {`LOT ${i + 1} (${e.poLoaQty})`}
                            </option>
                          );
                        })}
                      </select>
                      {touched.lot_id && errors.lot_id ? (
                        <p className="text-danger m-0">{errors.lot_id}</p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.lot_id}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group ">
                      <label className="form-label">Ticket Title</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control border-secondary"
                        placeholder="Enter Ticket"
                        onBlur={handleBlur}
                        value={values.title}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title ? (
                        <p className="text-danger m-0">{errors.title}</p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.title}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Board Group</label>
                    <select
                      className="form-select border-secondary"
                      name="board_group_id"
                      onBlur={handleBlur}
                      value={values.board_group_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected hidden>
                        Select Board Group
                      </option>
                      {boardGroup.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                    {touched.board_group_id && errors.board_group_id ? (
                      <p className="text-danger m-0">{errors.board_group_id}</p>
                    ) : (
                      <p className="text-danger m-0">
                        {validationError?.board_group_id}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Board Group Status</label>
                    <select
                      className="form-select border-secondary"
                      name="board_group_status_id"
                      onBlur={handleBlur}
                      value={values.board_group_status_id}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected hidden>
                        Select
                      </option>
                      {groupStatus.map((element, index) => {
                        return (
                          <option key={index} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </select>
                    {touched.board_group_status_id &&
                    errors.board_group_status_id ? (
                      <p className="text-danger m-0">
                        {errors.board_group_status_id}
                      </p>
                    ) : (
                      <p className="text-danger m-0">
                        {validationError?.board_group_status_id}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control border-secondary"
                    value={values.description}
                    name="description"
                    placeholder="Enter Ticket Description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.description && errors.description ? (
                    <p className="text-danger m-0">{errors.description}</p>
                  ) : (
                    <p className="text-danger m-0">
                      {validationError?.description}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-5 bg-light py-2">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="mt-0">Details</h5>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Asignee
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary"
                          id=""
                          name="assignee"
                          onBlur={handleBlur}
                          value={values.assignee}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select a Assignee
                          </option>
                          {projectMembers?.map((e, i) => {
                            return (
                              <option value={e.user_id} key={i}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.assignee && errors.assignee ? (
                          <p className="text-danger m-0">{errors.assignee}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.assignee}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="StartDate" className="form-label w-50">
                        Start Date
                      </label>
                      <div className="w-100 d-block">
                        <DatePicker
                          type="date"
                          name="start_date"
                          className="form-control border-secondary w-100"
                          placeholderText="09/25/2023"
                          onBlur={handleBlur}
                          selected={values.start_date}
                          onChange={(date) => setFieldValue("start_date", date)}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          startDate={values.start_date}
                          endDate={values.due_date}
                          minDate={new Date(poStartDate)}
                          maxDate={new Date(maxEndDate)}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        {touched.start_date && errors.start_date ? (
                          <p className="text-danger m-0">{errors.start_date}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.start_date}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label
                        htmlFor="CompletionDate"
                        className="form-label w-50"
                      >
                        Due Date
                      </label>
                      <div className="w-100 d-block">
                        <DatePicker
                          type="date"
                          className="form-control border-secondary w-100"
                          name="due_date"
                          placeholderText="09/30/2023"
                          onBlur={handleBlur}
                          selected={values.due_date}
                          onChange={(date) => setFieldValue("due_date", date)}
                          selectsEnd
                          startDate={values.start_date}
                          endDate={values.due_date}
                          minDate={values.start_date}
                          maxDate={new Date(maxEndDate)}
                          dateFormat="dd/MM/yyyy"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        {touched.due_date && errors.due_date ? (
                          <p className="text-danger m-0">{errors.due_date}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.due_date}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Priority
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary "
                          name="priority"
                          onBlur={handleBlur}
                          value={values.priority}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select Ticket Priority
                          </option>
                          <option value="critical">Critical</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                          <option value="future">Future</option>
                        </select>
                        {touched.priority && errors.priority ? (
                          <p className="text-danger m-0">{errors.priority}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.priority}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 mb-3">
                    <div className="form-group d-flex">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Estimated Time
                      </label>
                      <div className="w-100 d-block">
                        <input
                          type="type"
                          name="estimated_time"
                          onBlur={handleBlur}
                          className="form-control border-secondary w-100"
                          placeholder="In Hours"
                          value={values.estimated_time}
                          onChange={handleChange}
                        />
                        {touched.estimated_time && errors.estimated_time ? (
                          <p className="text-danger m-0">{errors.estimated_time}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.estimated_time}
                          </p>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 mb-3">
                    <div className="form-group d-flex align-items-sm-center">
                      <label htmlFor="Sprint" className="form-label w-50">
                        Reporting To
                      </label>
                      <div className="w-100 d-block">
                        <select
                          className="form-select border-secondary mb-3"
                          id=""
                          name="reporting_to"
                          onBlur={handleBlur}
                          value={values.reporting_to}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected hidden>
                            Select a Reporting Person
                          </option>
                          {projectMembers?.map((e, i) => {
                            return (
                              <option value={e.user_id} key={i}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                        {touched.reporting_to && errors.reporting_to ? (
                          <p className="text-danger m-0">
                            {errors.reporting_to}
                          </p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.reporting_to}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Create Ticket...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    Add Ticket
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
