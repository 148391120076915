import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CommonTh from "./CommonTh";
import CommonTd from "./CommonTd";
// import CommonTable from "../Common/CommonTable";
const LogisticList = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const user_id = userProfile?.response?.userDetails?.id;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [logisticList, setLogisticList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [logisticId, setLogisticId] = useState(null);
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  function formatDate(value) {
    var date = new Date(value);
    if (value) {
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return formattedDate;
  }
  function formatDateTime(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const period = hours >= 12 ? "pm" : "am";

    hours = hours % 12 || 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${day} ${month} ${year} ${hours}.${minutes}${period}`;

    return formattedDate;
  }
  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);

    let formattedHours = hours % 12 || 12;

    const period = hours >= 12 ? "pm" : "am";

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedTime = `${formattedHours}.${formattedMinutes}${period}`;

    return formattedTime;
  }

  const getLogisticList = () => {
    let url = baseUrl + "liststorelogistics";
    let data = { user_id };
    axios
      .post(url, data, config)
      .then((res) => {
        setLogisticList(res.data.response.StoreLogisticsList);

    ;
      })
      .catch((err) => {
    
      });
  };
  const deleteLogistic = () => {
    let url = baseUrl + "deletestorelogistics";
    let data = { user_id, store_logistics_id: logisticId };
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status === 200) {
          getLogisticList();
          handleClose();
          toast.success("Deleted Successfully");
        }
      })

      .catch((err) => {
      
        toast.error("Something went wrong , Please try again later");
      });
  };
  const handleClose = () => {
    setDeleteModal(false);
  };
  useEffect(() => {
    getLogisticList();
  }, []);
  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center pb-5"
          style={{ height: "70vh" }}
        >
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      ) : (
        <>
          <div className="page-wrapper">
            <div className="page-content-tab responsive-tab">
              <div className="container-fluid">
                <div className="row">
                  <div class="col-sm-12">
                    <div class="page-title-box">
                      <div class="float-end">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item active"> Dashboard</li>
                        </ol>
                      </div>
                      <h4 class="page-title"> DASHBOARD </h4>
                    </div>
                  </div>
                </div>
                <div className="card-header">
                  <div className="row">
                    <div className="d-flex justify-content-between">
                      <h3 className="card-title font-20">LOGISTICS</h3>
                      <div className="mx-4">
                        <Link to={"/addlogistic"}>
                          <button className="btn btn-danger">Add </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="table-responsive table-container-scroll"
                  style={{ overflowX: "auto" }}
                >
                  <table
                    className="table tender-list-table"
                    style={{ tableLayout: "unset" }}
                  >
                    <thead style={{ background: "#0F172A" }}>
                      <tr>
                        <CommonTh value="S No." width="3rem"></CommonTh>
                        <CommonTh value="REQUIREMENT DATE &"></CommonTh>
                        <CommonTh value="Origin Point "></CommonTh>

                        <CommonTh value="Destination Point "></CommonTh>
                        <CommonTh value="Project Name /Po No."></CommonTh>
                        <CommonTh value="Coach set of Material"></CommonTh>
                        <CommonTh value="Vehicle Arriving Date & Time"></CommonTh>
                        <CommonTh value="Dispatch Date & Time"></CommonTh>
                        <CommonTh value="Expected Deliver Date & Time"></CommonTh>
                        <CommonTh value="Actual Deliver Date & Time"></CommonTh>
                        <CommonTh value="Transporter Name"></CommonTh>
                        <CommonTh value="Type Of Vehicle"></CommonTh>
                        <CommonTh value="Driver No."></CommonTh>
                        <CommonTh value="Vehicle No. "></CommonTh>
                        <CommonTh value="Docket No."></CommonTh>
                        <CommonTh value="Transit Mode"></CommonTh>
                        <CommonTh value="Cost Of Vehicle"></CommonTh>
                        <CommonTh value="Approved By"></CommonTh>
                        <CommonTh value="Invoice No."></CommonTh>
                        <CommonTh value="Invoice Value"></CommonTh>
                        <CommonTh value="Receiver Name"></CommonTh>
                        <CommonTh value="Receiver Contact No."></CommonTh>
                        <CommonTh value="Remarks"></CommonTh>
                        <CommonTh value="Action" width="7rem"></CommonTh>
                      </tr>
                    </thead>
                    <tbody>
                      {logisticList?.map((logistic, i) => (
                        <tr key={i}>
                          <th scope="row">
                            <h6 style={{ minWidth: "3rem", maxWidth: "3rem" }}>
                              {i + 1}
                            </h6>
                          </th>
                          <CommonTd
                            value={`${formatDate(
                              logistic?.vehicle_requirement_date
                            )} ${formatTime(
                              logistic?.vehicle_requirement_time
                            )}`}
                          />
                          <CommonTd value={logistic?.origin_point}></CommonTd>
                          <CommonTd
                            value={logistic?.destination_point}
                          ></CommonTd>
                          <CommonTd value={logistic?.project_po_no}></CommonTd>
                          <CommonTd
                            value={logistic?.coach_material_set}
                          ></CommonTd>
                          {/* <CommonTd value={logistic?.coach_material_set}></CommonTd>
                    <CommonTd value={logistic?.coach_material_set}></CommonTd> */}
                          <CommonTd
                            value={formatDateTime(
                              logistic?.vehicle_arriving_date_time
                            )}
                          ></CommonTd>
                          <CommonTd
                            value={formatDateTime(logistic?.dispatch_date_time)}
                          ></CommonTd>
                          <CommonTd
                            value={formatDateTime(
                              logistic?.expected_delivery_date_time
                            )}
                          ></CommonTd>
                          <CommonTd
                            value={formatDateTime(
                              logistic?.actual_delivery_date_time
                            )}
                          ></CommonTd>
                          <CommonTd
                            value={logistic?.transporter_name}
                          ></CommonTd>
                          <CommonTd
                            value={logistic?.types_of_vehicle}
                          ></CommonTd>
                          <CommonTd value={logistic?.driver_no}></CommonTd>
                          <CommonTd value={logistic?.vehicle_no}></CommonTd>
                          <CommonTd value={logistic?.docket_no}></CommonTd>
                          <CommonTd value={logistic?.transit_mode}></CommonTd>
                          <CommonTd
                            value={logistic?.cost_of_vehicle}
                          ></CommonTd>
                          <CommonTd value={logistic?.approved_by}></CommonTd>
                          <CommonTd value={logistic?.invoice_no}></CommonTd>
                          <CommonTd value={logistic?.invoice_value}></CommonTd>
                          <CommonTd value={logistic?.receiver_name}></CommonTd>
                          <CommonTd
                            value={logistic?.receiver_contact_no}
                          ></CommonTd>
                          <CommonTd value={logistic?.remarks}></CommonTd>
                          <td style={{ minWidth: "10rem", maxWidth: "10rem" }}>
                            <i
                              className="las la-pen text-dark font-20 px-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/editlogistic", {
                                  state: logistic,
                                });
                              }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit Material Item"
                            ></i>

                            <i
                              className="las la-trash-alt font-20 px-1 text-dark"
                              onClick={() => {
                                setDeleteModal(true);
                                setLogisticId(logistic?.id);
                              }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete Material Item"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Modal
                  show={deleteModal}
                  onHide={handleClose}
                  centered
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="text-uppercase mb-0">
                      Delete
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h3 className="text-secondary text-center mx-3">
                      Are you sure, <br /> Do you want to Delete this?
                    </h3>
                  </Modal.Body>
                  <div className="d-flex gap-3 justify-content-center mb-3">
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={deleteLogistic}>
                      Delete
                    </Button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LogisticList;
