import { createSlice } from "@reduxjs/toolkit";

export const backOfficeSlice = createSlice({
  name: "backOffice",
  initialState: {
    category: "",
    status: "",
    initialTab: "card",
  },
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTab: (state, action) => {
      state.initialTab = action.payload;
    },
  },
});

export const { setCategory, setStatus, setTab } = backOfficeSlice.actions;

export const selectCategory = (state) => state.backOffice.category;
export const selectStatus = (state) => state.backOffice.status;
export const selectInitialTab = (state) => state.backOffice.initialTab;

export default backOfficeSlice.reducer;
