import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { addPolicy, updateProbationPolicy } from "../../hrApi";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";

import { toast } from "react-toastify";

import ShowError from "../../Organisation/organisationStructure/OrganizationComponents/ShowError";
import { probationPolicySchema } from "../EmployeeSchema";
import { useDispatch, useSelector } from "react-redux";
import { fetchProbationPolicy } from "../../../redux/features/Hr/ProbationPolicySlice";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const AddProbationPolicyModal = ({
  open,
  handleClose,
  editData,
  getProbationPolicy,
}) => {
  const loader = useSelector(selectLoading);
  const [validationError, setValidationError] = useState(null);
  const dispatch = useDispatch();
  const initialValues = {
    name: editData?.name || "",
    description: editData?.description || "",
    duration: editData?.duration || "",
    max_extension: editData?.max_extension || "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: probationPolicySchema,
    onSubmit: (values) => {
      if (editData) {
        PolicyUpdate(values);
      } else {
        console.log("else editData", editData);
        PolicyAdd(values);
      }
    },
  });

  const PolicyAdd = async () => {
    dispatch(setLoading(true));
    try {
      const response = await addPolicy(values);
      toast.success(response?.data?.message, { autoClose: 2000 });
      handleClose();
      dispatch(fetchProbationPolicy(dispatch));
    } catch (err) {
      // console.log(err?.response?.data?.response)
      setValidationError(err?.response?.data?.response);

      toast.error(err?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const PolicyUpdate = async () => {
    dispatch(setLoading(true));
    const reqpayload = { ...values, id: editData?.id };
    try {
      const response = await updateProbationPolicy(reqpayload);

      toast.success(response?.data?.message, { autoClose: 2000 });
      handleClose();
      dispatch(fetchProbationPolicy(dispatch));
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (editData) {
      setValues(
        editData
        //   {
        //   name: editData.name || "",
        //   description: editData.description || "",
        //   duration: editData.duration || "",
        //   max_extension: editData.max_extension || "",
        // }
      );
    } else {
      resetForm();
    }
  }, [editData, open]);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {editData ? "Update Probation Policy" : "Add Probation Policy"}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="name"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values?.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.name}
                      message={errors.name}
                      validationError={validationError?.name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      multiline={true}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="large"
                      id=""
                      rows={5}
                      label="Description"
                      name="description"
                      value={values?.description}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.description}
                      message={errors.description}
                      validationError={validationError?.description}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      name="duration"
                      value={values?.duration}
                      label="Duration"
                      type="number"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="outlined-start-adornment"
                      // { style: { fontSize: ".95rem" } }
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">month</InputAdornment>
                        ),
                      }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.duration}
                      message={errors.duration}
                      validationError={validationError?.duration}
                    />
                  </CustomFormControl>
                </div>

                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Max Probation Extension
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Max Probation Extension"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="max_extension"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.max_extension}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </CustomSelect>
                    <ShowError
                      touched={touched.max_extension}
                      message={errors.max_extension}
                      validationError={validationError?.max_extension}
                    />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Update Probation Policy"
                ) : (
                  "Add Policy"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default AddProbationPolicyModal;
