import React, { useCallback, useEffect, useState } from "react";
import InventoryTable from "./InventoryTable";
import { getInventoryListingApi } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";
import { debounce } from "lodash";
import BasicPagination from "../commonComponents/Pagination";
import { Spinner } from "react-bootstrap";
import SimpleBackdrop from "../commonComponents/ShowBackDrop";

function Inventory() {
  const [inventoryData, setInventoryData] = useState([]);
  const [search, searchTerm] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;

  function inventoryList(term, page = 1) {
    getInventoryListingApi({ user_id, search: term, page: page })
      .then((res) => {
        setInventoryData(res?.data?.response?.InventoryItemsList?.data);
        setPageCount(res?.data?.response?.InventoryItemsList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const debouncedFetchInventoryList = useCallback(
    debounce((term, page) => {
      inventoryList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchInventoryList(searchTerm, page);
  }, [searchTerm, debouncedFetchInventoryList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  return (
    <div className="page-wrapper mt-3">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <>
            <InventoryTable inventory={inventoryData} />
            <SimpleBackdrop open={loading} />
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
