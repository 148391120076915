import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  addLegalEntityAuthorizeSignatureApi,
  updateAuthorizedSignatureApi,
} from ".././OrganizationComponents/organizationStructureApi";
import { Box, CircularProgress } from "@mui/material";
import { addAuthorizeSignatureSchema } from "../OrganizationComponents/OrganizationSchema";
import ShowError from "../OrganizationComponents/ShowError";
import "../../../HrDepartment.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const AuthorizeSignatorieModal = ({
  open,
  handleClose,
  legalEntity,
  getAuthoizeSignatory,
  mode,
}) => {
  const [loader, setLoader] = useState(false);
  const [validationError, setValidationError] = useState(null);
  
  const initialValues = {
    name: "",
    designation: "",
    email: "",
    father_name: "",
    pan: "",
    address_line_1: "",
    address_line_2: "",
    state: "",
    city: "",
    zip: "",
  };
  useEffect(() => {
    if (mode === "edit") {
      setValues(legalEntity);
    } else {
      resetForm();
    }
  }, [mode, legalEntity]);

  async function addAuthorizeSignature(value) {
    setLoader(true);
    try {
      const requestData = {
        legal_entity_id: legalEntity?.id,
        country: "India",
        ...values,
      };
      const res = await addLegalEntityAuthorizeSignatureApi(requestData);
      setLoader(false);
      if (res.status == 200) {
        toast.success("Authorized Signature Added Successfully", {
          autoClose: 1000,
        });
        getAuthoizeSignatory();
        handleClose();

        resetForm();
      }
    } catch (error) {
      // setLoader(false);
      toast.error(error?.response?.data?.message,{autoClose:1000});
    } finally {
      setLoader(false);
    }
  }
  const updateAuthorizesSignatory = async () => {
    setLoader(true);
    try {
      const requestData = {
        legal_entity_id: legalEntity?.legal_entity_id,
        country: "India",
        ...values,
      };

      const res = await updateAuthorizedSignatureApi(requestData);
      
      // setLoader(false);
      if (res.status == 200) {
        toast.success("Authorized Signature update Successfully", {
          autoClose: 1000,
        });
        getAuthoizeSignatory();
        handleClose();

        resetForm();
      }
    } catch (error) {
      // setLoader(false);

      toast.error(error?.response?.data?.message,{autoClose:1000});
    } finally {
      setLoader(false);
    }
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addAuthorizeSignatureSchema,
    onSubmit: (values) => {
      if (mode == "add") {
        addAuthorizeSignature(values);
      } else {
        updateAuthorizesSignatory();
      }
    },
  });
  const handleCancelButtonClick = () => {
    handleClose();
    resetForm();
  };
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {`${mode === "edit" ? "Update" : "Add"} Signatory Information`}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3 pt-2">
              <div className="row">
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Signatroy name"
                      name="name"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      value={values?.name}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError touched={touched.name} message={errors.name} />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Signatory Designation"
                      name="designation"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      value={values?.designation}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.designation}
                      message={errors.designation}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      name="email"
                      onChange={handleChange}
                      label="Email Address"
                      variant="outlined"
                      size="small"
                      value={values?.email}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError touched={touched.email} message={errors.email} />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      name="father_name"
                      onChange={handleChange}
                      label="Signatory Father’s Name"
                      variant="outlined"
                      size="small"
                      value={values?.father_name}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.father_name}
                      message={errors.father_name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      name="pan"
                      onChange={handleChange}
                      label="PAN Number"
                      variant="outlined"
                      size="small"
                      value={values?.pan}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError touched={touched.pan} message={errors.pan} />
                  </CustomFormControl>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5
                    className="text-uppercase fw-normal mb-4"
                    style={{ color: "#A6A6A6" }}
                  >
                    Address Detail
                  </h5>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 1"
                      variant="outlined"
                      name="address_line_1"
                      onChange={handleChange}
                      size="small"
                      value={values?.address_line_1}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_1}
                      message={errors.address_line_1}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 2"
                      name="address_line_2"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      value={values?.address_line_2}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_2}
                      message={errors.address_line_2}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="State"
                      name="state"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      value={values?.state}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError touched={touched.state} message={errors.state} />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="City"
                      name="city"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      value={values?.city}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError touched={touched.city} message={errors.city} />
                  </CustomFormControl>
                </div>
                <div className="col-6">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Zip Code"
                      name="zip"
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      value={values?.zip}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                    ></CustomTextField>
                    <ShowError touched={touched.zip} message={errors.zip} />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleCancelButtonClick}
                type="button"
                className="btn btn-outline-primary me-2"
                style={{ borderRadius: ".45rem" }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loader}
                style={{ borderRadius: ".45rem" }}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : mode === "edit" ? (
                  "Update Signatory"
                ) : (
                  "Add Signatory"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default AuthorizeSignatorieModal;
