import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogisticForm from "./LogisticForm";
import axios from 'axios'
import { toast } from "react-toastify";
const EditLogisticForm = () => {
    const [spinner, setSpinner] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    const config = { headers: { Authorization: bearer_token } };
    const navigate = useNavigate();
    const { state } = useLocation();
    const initialValues = {
        vehicle_requirement_date: state?.vehicle_requirement_date,
        vehicle_requirement_time: state?.vehicle_requirement_time,
        origin_point: state?.origin_point,
        destination_point: state?.destination_point,
        project_po_no: state?.project_po_no,
        coach_material_set: state?.coach_material_set,
        vehicle_arriving_date_time: state?.vehicle_arriving_date_time,
        dispatch_date_time: state?.dispatch_date_time,
        expected_delivery_date_time: state?.expected_delivery_date_time,
        actual_delivery_date_time: state?.actual_delivery_date_time,
    
        transporter_name: state?.transporter_name,
        types_of_vehicle: state?.types_of_vehicle,
        driver_no: state?.driver_no,
        vehicle_no: state?.vehicle_no,
        docket_no: state?.docket_no,
        transit_mode: state?.transit_mode,
        cost_of_vehicle: state?.cost_of_vehicle,
        approved_by: state?.approved_by,
        invoice_no: state?.invoice_no,
        invoice_value: state?.invoice_value,
        receiver_name: state?.receiver_name,
        receiver_contact_no: state?.receiver_contact_no,
        remarks: state?.remarks,
      };
      const updateLogistic = (value) => {
        setSpinner(true)
        let url = baseUrl + "updatestorelogistics";
        let data = { ...value, user_id,store_logistics_id:state?.id };
        axios
          .post(url, data, config)
          .then((res) => {
            if (res.status === 200) {
            
              toast.success("Logistic updated successfully");
              navigate(-1);
            }
         
      
            setValidationError({})
          })
          .catch((err) => {
          
            setSpinner(false);
            
            setValidationError(err?.response?.data?.response)
          
          });
      };
      const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        errors,
        touched,
        setFieldValue,
        handleBlur,
      } = useFormik({
        initialValues: initialValues,
        // validationSchema: addwarrantyTrackerSchema,
        onSubmit: (values) => {
          updateLogistic(values);
        },
      });
  return (
    <LogisticForm
handleChange={handleChange}
values={values}
handleSubmit={handleSubmit}
errors={errors}
touched={touched}
handleBlur={handleBlur}

validationError={validationError}

spinner={spinner}
mode="edit"
setFieldValue={setFieldValue}

/>

  )
}

export default EditLogisticForm