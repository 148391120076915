import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listProbationPolicy } from "../../../HrDepartment/hrApi";
import { setLoading } from "./LoaderSlice";
const initialState = { probationPolicy: [] };
export const fetchProbationPolicy = createAsyncThunk(
  "probationPolicy/fetchList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true)); // Set loader to true before API call
    try {
      const response = await listProbationPolicy();
      return response?.data?.response?.data;
    } catch (error) {
    } finally {
      dispatch(setLoading(false)); // Set loader to false after API call
    }
  }
);

const probationPolicySlice = createSlice({
  name: "ProbationPolicy",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProbationPolicy.pending, (state) => {})
      .addCase(fetchProbationPolicy.fulfilled, (state, action) => {
        state.probationPolicy = action.payload;
      })

      .addCase(fetchProbationPolicy.rejected, (state, action) => {});
  },
});
export const getProbationPolicy = (state) =>
  state?.ProbationPolicyList?.probationPolicy;

export default probationPolicySlice.reducer;
