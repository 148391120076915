import * as Yup from "yup";

export const allForms = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .min(2, "There should atleast two characters"),
});

export const clientCategory = Yup.object({
  name: Yup.string().required("This field is required").min(2),
  parent: Yup.string().required("This field is required"),
});

export const RailwayZone = Yup.object({
  name: Yup.string().required("This field is required").min(2),
  catId: Yup.string().required("This field is required"),
});

export const FeatureListSchema = Yup.object({
  perm_group_id: Yup.string().required("This field is required"),
  icon: Yup.string().required("This field is required"),
  feature: Yup.string().required("This field is required"),
  url: Yup.string().required("This field is required"),
});

export const ProfileSchema = Yup.object({
  name: Yup.string()
  .matches(/^[a-zA-Z ]*$/, "Title cannot contain numbers or special characters")
  .required("This field is required")
  .min(3, "Title must be at least 3 characters long"),
  address: Yup.string().required("This field is required"),
  phone: Yup.string()
  .required("This field is required")
  .length(10, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(/@[^.]*\./, "Please enter a valid email address")
    .required("Email is required"),
});

export const NewPasswordSchema = Yup.object({
  new_password: Yup.string()
    .required("This field is required")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)
    .min(6)
    .max(16),
  confirm_password: Yup.string()
    .required("This field is required")
    .oneOf(
      [Yup.ref("new_password"), null],
      "Confirm password is not matching with new password"
    ),
  current_password: Yup.string().required("This field is required"),
});

export const subdivisonSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  railway_zone: Yup.string().required("This field is required"),
});

export const boardGroupSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  board_group_id: Yup.string().required("This field is required"),
 
});

export const changePasswordSchema = Yup.object({
  newPassword: Yup.string()
    .required("This field is required")
    .test(
      "password",
      "Password should contain at least 8 characters, one lowercase letter, one uppercase letter, one number, and one special character (@$!%*?&)",
      (value) => {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(
          value
        );
      }
    ),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm password is not matching with New password"
    ),
});

export const boardSchema = Yup.object({
  name: Yup.string()
    .required("This field is required")
    .min(2, "There should atleast two characters"),
  department_id: Yup.string()
    .required("This field is required")
});
