import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getRoleList } from "../redux/api";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { ProfileSchema } from "../schemas/settingSchema";
import NewPassword from "./NewPassword";
import notification from "../Assets/done1.mp3";
import { useParams } from "react-router-dom";

export default function PersonalInfo({ getDetails }) {
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const audioPlayer = useRef(null);
  const param = useParams();
  const [read, setRead] = useState([true]);

  function playAudio() {
    audioPlayer.current.play();
  }

  const initialValues = {};

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      submitData(values);
    },
  });

  function getUserDetail() {
    setRead(true);
    let url = apiurl + "getusereditdetails";
    let data = { user_id: param.id };
    axios
      .post(url, data, config)
      .then((response) => {
        setValues(response?.data?.response?.editData);
        getDetails(response?.data?.response?.editData);
      })
      .catch((error) => {
      });
  }

  function submitData(value) {
    let url = apiurl + "updateadminprofiledetail";
    let data = { ...value, user_id };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          getUserDetail();
          playAudio();
        }
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getUserDetail();
    resetForm();
  }, []);

  return (
    <>
      <div className="row">
        <audio ref={audioPlayer} src={notification} />
        <div className="col-lg-6 col-xl-6">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-6">
                  <h4 className="card-title">Personal Information</h4>
                </div>
                <div className="col-6 text-end">
                  {+user_id === +param.id && (
                    <i
                      className="las la-pen text-dark font-20"
                      style={{ cursor: "pointer" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit Profile"
                      onClick={() => setRead(false)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                    Name
                  </label>
                  <div className="col-lg-9 col-xl-8">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="las la-signature"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        aria-describedby="basic-addon1"
                        onBlur={handleBlur}
                        value={values.name}
                        onChange={handleChange}
                        readOnly={read}
                      />
                    </div>
                    {touched.name && errors.name ? (
                      <p className="text-danger my-0 ms-5">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                    Contact Phone
                  </label>
                  <div className="col-lg-9 col-xl-8">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="las la-phone"></i>
                      </span>
                      <input
                        className="form-control"
                        placeholder="Phone"
                        aria-describedby="basic-addon1"
                        name="phone"
                        onBlur={handleBlur}
                        value={values.phone}
                        onChange={handleChange}
                        readOnly={read}
                        minLength={10}
                        maxLength={10}
                      />
                    </div>
                    {touched.phone && errors.phone ? (
                      <p className="text-danger my-0 ms-5">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                    Email Address
                  </label>
                  <div className="col-lg-9 col-xl-8">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="las la-at"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        aria-describedby="basic-addon1"
                        name="email"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        readOnly={read}
                      />
                    </div>
                    {touched.email && errors.email ? (
                      <p className="text-danger my-0 ms-5">{errors.email}</p>
                    ) : null}
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                    Address
                  </label>
                  <div className="col-lg-9 col-xl-8">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="las la-thumbtack"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        aria-describedby="basic-addon1"
                        name="address"
                        onBlur={handleBlur}
                        value={values.address}
                        onChange={handleChange}
                        readOnly={read}
                      />
                    </div>
                    {touched.address && errors.address ? (
                      <p className="text-danger my-0 ms-5">{errors.address}</p>
                    ) : null}
                  </div>
                </div>
                {!read && (
                  <div className="form-group mb-3 row">
                    <div className="col-lg-9 col-xl-8 offset-lg-3">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger ms-3"
                        onClick={() => getUserDetail()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-6">
          {+user_id === +param.id && <NewPassword />  }
        </div>
      </div>
    </>
  );
}
