import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Tender/TenderStyle/TenderStyle.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../TableStyle/TableStyle.css";
import { Link } from "react-router-dom";

function ProjectGridView({ projects }) {
  const [showModal, setShowModal] = useState(false);
  const [titleData, setTitleData] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;

  function handleClose() {
    setShowModal(false);
    setTitleData("");
    setModalHeader("");
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  return (
    <>
      <div
        className="table-responsive table-container-scroll"
        style={{ overflowX: "auto" }}
      >
        <table
          className="table table-striped project-list-table"
          style={{ tableLayout: "unset" }}
        >
          <thead className="thead-light">
            <tr>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "6rem", maxWidth: "6rem" }}
                >
                  File No.
                </p>
              </th>
              <th>
                <p className="fw-bold font-18">Railway Zone</p>
              </th>
              <th>
                <p className="fw-bold font-18">Sub Division</p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.3rem", maxWidth: "7.3rem" }}
                >
                  Work Status
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "6.5rem", maxWidth: "6.5rem" }}
                >
                  Tender Number
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Tender Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "5rem", maxWidth: "5rem" }}
                >
                  Tender Quantity
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "10rem", maxWidth: "10rem" }}
                >
                  PO/LOA No.
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  PO/LOA Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "10rem", maxWidth: "10rem" }}
                >
                  Total Order Value
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7rem", maxWidth: "7rem" }}
                >
                  Total Order Quantity
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7rem", maxWidth: "7rem" }}
                >
                  PO/LOA Docs.
                </p>
              </th>
              <th>
                <p className="fw-bold font-18">Project Title</p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Project Start Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Project End Date
                </p>
              </th>

              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "12rem", maxWidth: "12rem" }}
                >
                  Project Category
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Project Manager
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Purchase Manager
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Inspection Agency
                </p>
              </th>
              <th>
                <p className="fw-bold font-18">Project Description</p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Actual Completion Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Completion Certificate Docs
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Noc Docs
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "5rem", maxWidth: "5rem" }}
                >
                  PO/LOA Quantity
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  UOM
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  Start Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  End Date
                </p>
              </th>
              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}
                >
                  DP Extension
                </p>
              </th>

              <th>
                <p
                  className="fw-bold font-18"
                  style={{ minWidth: "32rem", maxWidth: "32rem" }}
                >
                  LOA/PO Cost Bifurcation
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {projects?.map((project, i) => (
              <tr key={i}>
                <>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_fileNo)}
                    >
                      <h6 style={{ minWidth: "6rem", maxWidth: "6rem" }}>
                        {project.pd_fileNo.length > 20
                          ? project.pd_fileNo.slice(0, 20) + "..."
                          : project.pd_fileNo}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_railwayZone)}
                    >
                      <h6>
                        {project.pd_railwayZone.length > 20
                          ? project.pd_railwayZone.slice(0, 20) + "..."
                          : project.pd_railwayZone}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_subDivision)}
                    >
                      <h6>
                        {project.pd_subDivision.length > 20
                          ? project.pd_subDivision.slice(0, 20) + "..."
                          : project.pd_subDivision}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_workStatus)}
                    >
                      <h6 style={{ minWidth: "7.3rem", maxWidth: "7.3rem" }}>
                        {project.pd_workStatus.length > 20
                          ? project.pd_workStatus.slice(0, 20) + "..."
                          : project.pd_workStatus}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_tenderNo)}
                    >
                      <h6 style={{ minWidth: "6.5rem", maxWidth: "6.5rem" }}>
                        {project.pd_tenderNo.length > 20
                          ? project.pd_tenderNo.slice(0, 20) + "..."
                          : project.pd_tenderNo}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(formatDate(project.pd_tenderDate))}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_tenderDate.length > 20
                          ? formatDate(project.pd_tenderDate).slice(0, 20) +
                            "..."
                          : formatDate(project.pd_tenderDate)}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_tenderQuantity)}
                    >
                      <h6 style={{ minWidth: "5rem", maxWidth: "5rem" }}>
                        {project.pd_tenderQuantity.length > 20
                          ? project.pd_tenderQuantity.slice(0, 20) + "..."
                          : project.pd_tenderQuantity}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_poloaNo)}
                    >
                      <h6 style={{ minWidth: "10rem", maxWidth: "10rem" }}>
                        {project.pd_poloaNo.length > 20
                          ? project.pd_poloaNo.slice(0, 20) + "..."
                          : project.pd_poloaNo}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(formatDate(project.pd_poloaDate))}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_poloaDate.length
                          ? formatDate(project.pd_poloaDate)
                          : "..."}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_totalOrderValue)}
                    >
                      <h6
                        style={{
                          minWidth: "10rem",
                          maxWidth: "10rem",
                          cursor: "pointer",
                        }}
                      >
                        {project.pd_totalOrderValue.length > 20
                          ? Number(
                              project.pd_totalOrderValue.slice(0, 20)
                            ).toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            }) + "..."
                          : Number(project.pd_totalOrderValue).toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_totalOrderQty)}
                    >
                      <h6 style={{ minWidth: "7rem", maxWidth: "7rem" }}>
                        {project?.pd_totalOrderQty?.slice(0, 20)}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <h6 style={{ minWidth: "7rem", maxWidth: "7rem" }}>
                      <a
                        className="fw-bold text-primary text-decoration-underline"
                        target="_blank"
                        href={attachmentBaseUrl + project.pd_poloaDocs}
                      >
                        PO/LOA Doc
                      </a>
                    </h6>
                  </td>

                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_projectTitle)}
                    >
                      <h6>{project.pd_projectTitle}</h6>
                    </OverlayTrigger>
                  </td>

                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(formatDate(project?.pd_startDate))}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project?.pd_startDate
                          ? formatDate(project?.pd_startDate?.slice(0, 20))
                          : "----"}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(formatDate(project?.pd_endDate))}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project?.pd_endDate
                          ? formatDate(project?.pd_endDate?.slice(0, 20))
                          : "----"}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.projectCategoryName)}
                    >
                      <h6 style={{ minWidth: "12rem", maxWidth: "12rem" }}>
                        {project.projectCategoryName}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_projectManager)}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_projectManager?.slice(0, 20)}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_purchaseManager)}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_purchaseManager?.slice(0, 20)}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_InspectionAgency)}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_InspectionAgency?.slice(0, 20)}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_projectItemDescription)}
                    >
                      <h6>{project.pd_projectItemDescription?.slice(0, 20)}</h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 100 }}
                      overlay={renderTooltip(project.pd_completionDate)}
                    >
                      <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {project.pd_completionDate
                          ? formatDate(project.pd_completionDate)?.slice(0, 20)
                          : "- - -"}
                      </h6>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {project?.pd_completionCertificate ? (
                      <h4 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        <Link
                          to={
                            attachmentBaseUrl +
                            project?.pd_completionCertificate
                          }
                          target="_blank"
                          className="fw-bold text-primary text-decoration-underline"
                        >
                          Comp. Cert.
                        </Link>
                      </h4>
                    ) : (
                      <h4 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        - - -
                      </h4>
                    )}
                  </td>
                  <td>
                    {project?.pd_noc ? (
                      <h4 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        <Link
                          to={attachmentBaseUrl + project?.pd_noc}
                          target="_blank"
                          className="fw-bold text-primary text-decoration-underline"
                        >
                          NOC Docs
                        </Link>
                      </h4>
                    ) : (
                      <h4 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                        {" "}
                        - - -
                      </h4>
                    )}
                  </td>
                  <td>
                    {project.lotData?.map((element, index) => {
                      return (
                        <h6 style={{ minWidth: "5rem", maxWidth: "5rem" }}>
                          {element?.poLoaQty}
                        </h6>
                      );
                    })}
                  </td>
                  <td>
                    {project.lotData?.map((element, index) => {
                      return (
                        <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                          {element?.unitName}
                        </h6>
                      );
                    })}
                  </td>
                  <td>
                    {project.lotData?.map((element, index) => {
                      return (
                        <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                          {formatDate(element?.startDate)}
                        </h6>
                      );
                    })}
                  </td>
                  <td>
                    {project.lotData?.map((element, index) => {
                      return (
                        <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                          {" "}
                          {formatDate(element?.completionDate)}
                        </h6>
                      );
                    })}
                  </td>
                  <td>
                    {project.lotData?.map((element, index) => {
                      return (
                        <h6 style={{ minWidth: "7.4rem", maxWidth: "7.4rem" }}>
                          {element?.dpExtension ? element?.dpExtension : "---"}
                        </h6>
                      );
                    })}
                  </td>
                  <td>
                    {project?.lcb_poloaValue ? (
                      <table style={{ minWidth: "32rem", maxWidth: "32rem" }}>
                        <thead>
                          <tr>
                            <th
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              Particular
                            </th>
                            <th
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              PER UNIT
                            </th>
                            <th
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              TOTAL
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: "10rem" }}>
                          {project?.lcb_basicMultipleData?.map(
                            (element, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  Basic
                                </td>

                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.basicPerUnit}
                                </td>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.basicTotal}
                                </td>
                              </tr>
                            )
                          )}
                          {project?.lcb_installationMultipleData?.map(
                            (element, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  Installation
                                </td>

                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_installationPerUnit}
                                </td>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_installationTotal}
                                </td>
                              </tr>
                            )
                          )}
                          {project?.lcb_packagingMultipleData?.map(
                            (element, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  Packaging
                                </td>

                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_packagingPerUnit}
                                </td>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_packagingTotal}
                                </td>
                              </tr>
                            )
                          )}
                          {project?.lcb_freightMultipleData?.map(
                            (element, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  Freight
                                </td>

                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_freightPerUnit}
                                </td>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.lcb_freightTotal}
                                </td>
                              </tr>
                            )
                          )}
                          {project?.lcb_otherMultipleData?.map(
                            (element, key) => (
                              <tr key={key}>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  Other
                                </td>

                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.otherPerUnit}
                                </td>
                                <td
                                  style={{
                                    minWidth: "10rem",
                                    maxWidth: "10rem",
                                  }}
                                >
                                  {element?.otherTotal}
                                </td>
                              </tr>
                            )
                          )}

                          <tr>
                            <td
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              GST @{project?.lcb_gstValue}%
                            </td>
                            <td
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            ></td>
                            <td
                              style={{ minWidth: "10rem", maxWidth: "10rem" }}
                            >
                              {project?.lcb_gstTotal}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <h6 style={{ minWidth: "10rem", maxWidth: "10rem" }}>
                        ----
                      </h6>
                    )}
                  </td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="m-0">{modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="fw-bold text-center font-20">
          {titleData}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectGridView;
