import React, { useEffect, useState } from "react";
import empimg from "../../Assets/empimg.png";
import frame from "../../Assets/Frame.png";
import SearchBar from "../Organisation/SearchBar";
import { useNavigate } from "react-router-dom";
import "../HrDepartment.scss";
import { employeeList } from "../hrApi";
import { toast } from "react-toastify";

import Spinner from "react-bootstrap/Spinner";

const EmployeeDirectory = () => {
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState();
  const [loader, setLoader] = useState(false);
  const employee = async () => {
    setLoader(true);

    try {
      const res = await employeeList();
      setAllEmployee(res?.data?.response?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    employee();
  }, []);
  const viewProfile = (id) => {
    navigate(`/employee_detail/${id}`);
  };
  return (
    <>
      <SearchBar />

      <div className="row mx-2" style={{ marginTop: "20px" }}>
        {loader ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "28vh" }}
          >
            {/* <Spinner animation="grow" variant="danger" /> */}
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : AllEmployee?.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center mx-auto fw-bold bg-light border mt-5"
            style={{ height: "25vh", width: "25%", borderRadius: "10px" }}
          >
            No Employee Found
          </div>
        ) : (
          AllEmployee?.map((empDetail) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={empDetail?.id}>
              <div
                onClick={() => {
                  viewProfile(empDetail?.id);
                }}
                className="card emp curserPointer"
                style={{ borderRadius: "10px" }}
              >
                <div className="d-flex  border-bottom px-lg-3 px-0 py-lg-3 py-0  mx-lg-3 mx-0  px-0 py-0 mx-0 employee-directory ">
                  <div>
                    <img src={empimg} />
                  </div>
                  <div className="px-lg-4 px-0 employee-directory">
                    <h6 className="emp-name">
                      {empDetail?.name ? empDetail?.name : "--"}
                      <span style={{ paddingLeft: "12px" }}>
                        <img src={frame} />
                      </span>
                    </h6>
                    <p className="designation ms-1">
                      {empDetail?.job_title ? empDetail?.job_title : "--"}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 ps-5 py-3 employee-directory">
                    <p className="card_label">Mobile:</p>
                    <p className="card_label">Email:</p>
                    <p className="card_label">Department:</p>

                    <p className="card_label">Date Of Joining:</p>
                    <p className="card_label">Location:</p>
                  </div>
                  <div className="col-md-6 py-3 employee-directory">
                    <p className="card_value">
                      {" "}
                      {empDetail?.phone ? empDetail.phone : "--"}
                    </p>
                    <p className="card_value">
                      {" "}
                      {empDetail?.email ? empDetail?.email : "--"}
                    </p>
                    <p className="card_value">
                      {empDetail?.department_name
                        ? empDetail?.department_name
                        : "--"}
                    </p>
                    <p className="card_value">
                      {empDetail?.joining_date ? empDetail?.joining_date : "--"}
                    </p>
                    <p className="card_value">
                      {empDetail?.location ? empDetail?.location : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default EmployeeDirectory;
