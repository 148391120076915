import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";

export const login = createAsyncThunk(
  "auth/login",
  async ({ values, navigate, toast, previousUrl }, { rejectWithValue }) => {
    try {
      const response = await api.signIn(values);
      if (response.data.success === true) {
        if (response.data.message === "firstTime") {
          toast.success("First time login. Please change you password.");
          navigate("/ChangePassword", { state: { email: values.email } });
          return response.data;
        } else {
          toast.success("Login Successfully");
          if (previousUrl) {
            // Redirect to the previous URL
            navigate("/Dashboard");
          } else {
            // If no previous URL, redirect to the dashboard
            navigate("/Dashboard");
          }
          return response.data;
        }
      } else {
        if (response?.data?.message === "error") {
          toast.error(response?.data?.response);
        } else if (response?.data?.message === "Error validation") {
          toast.error(response?.data?.response?.password[0]);
        }
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    error: "",
    loading: false,
    previousUrl: "",
  },

  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state, action) => {
      localStorage.clear();
      state.user = null;
    },
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload;
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
      state.user = action.payload;
      state.error = "";
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.response;
    },
  },
});

export const { setUser, setLogout, setPreviousUrl } = authSlice.actions;

export default authSlice.reducer;
