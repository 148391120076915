import React, { useEffect, useState } from "react";
import { errorsMessage } from "../../constant";
import { getStateMasterList } from "../../Purchase/purchaseApi";
import { railwayZoneList } from "../commonComponents/storeApi";

function CustomerStructure({
  handleChange,
  values,
  serverSideValidation,
  errors,
  handleBlur,
  touched,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [stateMasterList, setStateMasterList] = useState([]);
  const [railwayZone, setRailwayZone] = useState([]);

  function stateDataDropDown() {
    getStateMasterList({ user_id })
      .then((res) => {
        setStateMasterList(res?.data?.response?.StateMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }

  function getRailwayZoneDropDown() {
    railwayZoneList({ user_id })
      .then((res) => {
        setRailwayZone(res?.data?.response?.RailwayZoneList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    getRailwayZoneDropDown();
    stateDataDropDown();
  }, []);

  return (
    <div className="row">
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Name
        </label>
        <input
          className="form-control"
          placeholder="Enter Customer Name"
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.name
            : touched.name && errors.name
            ? errors.name
            : null}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          GSTIN
        </label>
        <input
          className="form-control"
          placeholder="Enter GSTIN"
          name="gst_in"
          type="text"
          value={values.gst_in}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.gst_in
            : touched.gst_in && errors.gst_in
            ? errors.gst_in
            : null}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Address
        </label>
        <input
          className="form-control"
          placeholder="Enter Address"
          name="address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.address
            : touched.address && errors.address
            ? errors.address
            : null}
        </p>
      </div>
      <div className="col-3 ">
        <label htmlFor="" className="form-label fw-bold font-16">
          State
        </label>
        <select
          className="form-control"
          name="state_code"
          value={values.state_code}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value={""}>Select</option>
          {stateMasterList?.map((state) => (
            <option key={state?.id} value={state?.id}>
              {state?.state_name}
            </option>
          ))}
        </select>
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.state_code
            : touched.state_code && errors.state_code
            ? errors.state_code
            : null}
        </p>
      </div>
      <div className="col-3 mt-4">
        <label htmlFor="" className="form-label fw-bold font-16">
          Railway Zone
        </label>
        <select
          className="form-control"
          name="railway_zone"
          value={values.railway_zone}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value={""}>Select</option>
          {railwayZone?.map((zone) => (
            <option key={zone?.id} value={zone?.id}>
              {zone?.name}
            </option>
          ))}
        </select>
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.railway_zone
            : touched.railway_zone && errors.railway_zone
            ? errors.railway_zone
            : null}
        </p>
      </div>
      <div className="col-3 mt-4">
        <label htmlFor="" className="form-label fw-bold font-16">
          Mobile No.
        </label>
        <input
          className="form-control"
          placeholder="Enter Mobile No."
          name="mobile_Number"
          type="number"
          value={values.mobile_Number}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.mobile_Number
            : touched.mobile_Number && errors.mobile_Number
            ? errors.mobile_Number
            : null}
        </p>
      </div>
      <div className="col-3 mt-4">
        <label htmlFor="" className="form-label fw-bold font-16">
          Email Address
        </label>
        <input
          className="form-control"
          placeholder="Enter Email Address"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className="text-danger m-0">
          {serverSideValidation
            ? serverSideValidation.email
            : touched.email && errors.email
            ? errors.email
            : null}
        </p>
      </div>
    </div>
  );
}

export default CustomerStructure;
