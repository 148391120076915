import React from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

function NewLetterIndex({ tabKey }) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [latterIndexData, setLatterIndexData] = useState([]);
  const param = useParams();
  let project_id = param.id;

  function getLetterIndexList() {
    setLoading(true);
    let url = baseUrl + "listlatterindex";
    let data = { user_id, project_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then(async (response) => {
        let data = await response?.data?.response?.latterIndexList;
        setLatterIndexData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (tabKey === "letterIndex") {
      getLetterIndexList();
    }
  }, [tabKey]);

  return (
    <>
      <div className="pt-2">
        <ul className="nav nav-pills nav-justified" role="tablist">
          <li className="nav-item waves-effect waves-light">
            {" "}
            <a
              className="nav-link active col-3"
              data-bs-toggle="tab"
              href="#tenderDocs"
              role="tab"
              aria-selected="false"
            >
              TENDER DOCS
            </a>{" "}
          </li>
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#poLoaDocs"
              role="tab"
              aria-selected="false"
            >
              PO/LOA DOCS
            </a>
          </li>
          {/* <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#emdDocs"
              role="tab"
              aria-selected="false"
            >
              EDM/SD/PG DOCS
            </a>
          </li>
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#billingDocs"
              role="tab"
              aria-selected="false"
            >
              BILLING DOCS
            </a>
          </li> */}
        </ul>

        <div className="tab-content">
          <div className="tab-pane pt-3 active" id="tenderDocs" role="tabpanel">
            <div className="row">
              {latterIndexData.tenderDoc?.map((element, index) => {
                return (
                  <>
                    <div className="col-md-3 g-3">
                      <a
                        href={attachmentBaseUrl + element.name}
                        target="_blank"
                        className="text-start font-18 fw-bold"
                        rel="noopener noreferrer"
                      >
                        Tender Attachment {index + 1}
                      </a>
                      <Link
                        to={attachmentBaseUrl + element.name}
                        target="_blank"
                      >
                        {element.name?.endsWith(".pdf") ? (
                          <iframe
                            title={`Attachment ${index}`}
                            src={attachmentBaseUrl + element.name}
                            style={{ border: "1px solid #ccc" }}
                            frameBorder={0}
                            border="0"
                            className="w-100"
                          ></iframe>
                        ) : (
                          <div>
                            <img
                              src={attachmentBaseUrl + element.name}
                              className="w-100"
                              style={{ objectFit: "cover", aspectRatio: "1/1" }}
                            ></img>
                          </div>
                        )}
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="tab-pane pt-3" id="poLoaDocs" role="tabpanel">
            <div className="row">
              <div className="col-md-3 g-3">
                <a
                  href={
                    attachmentBaseUrl +
                    latterIndexData.projectIndex?.pd_poloaDocs
                  }
                  target="_blank"
                  className="text-start font-18 fw-bold"
                  rel="noopener noreferrer"
                >
                  PO/LOA Document
                </a>
                <Link
                  to={
                    attachmentBaseUrl +
                    latterIndexData.projectIndex?.pd_poloaDocs
                  }
                  target="_blank"
                >
                  <iframe
                    title={`PO/LOA Attachment`}
                    src={
                      attachmentBaseUrl +
                      latterIndexData.projectIndex?.pd_poloaDocs
                    }
                    style={{ border: "1px solid #ccc" }}
                    frameBorder={0}
                    border="0"
                    className="w-100"
                  ></iframe>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="tab-pane pt-3" id="emdDocs" role="tabpanel">
            <></>
          </div>
          <div className="tab-pane pt-3" id="billingDocs" role="tabpanel">
            <></>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default NewLetterIndex;
