import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../HrDepartment.scss";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { updateEducation } from "../../hrApi";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function EditEmployeeEducation({
  handleClose,
  open,
  data,
  employeeDetail,
}) {
  const [isFocused, setIsFocused] = useState("");
  const [validationError, setValidationError] = useState(null);
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };
  const initialValues = {
    college_university: "",
    degree: "",
    branch_specialization: "",
    join_date: "",
    complete_date: "",
    cgpa_percentage: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    // handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: addBusinessUnitSchema,

    onSubmit: (values) => {
      updateEmployeeEducation();
    },
  });
  useEffect(() => {
    setValues(data);
  }, [data, open]);

  const updateEmployeeEducation = async () => {
    dispatch(setLoading(true));
    const reqpayload = { employee_id: data?.id, ...values };
    try {
      const response = await updateEducation(reqpayload);
      handleClose();
      employeeDetail();
      toast.success(response?.data?.response?.message, { autoClose: 2000 });
    } catch (error) {
      setValidationError(error?.response?.data?.response);
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="employee-profile-section">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="edit_primary_details">Education</Typography>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "college_university"
                      ? "text-danger"
                      : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  College / University
                </label>
                <div
                  className={`${
                    isFocused == "college_university"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="college_university"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    value={values?.college_university}
                    onChange={handleChange}
                    placeholder="College / University"
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "degree" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Degree
                </label>
                <div
                  className={`${
                    isFocused == "degree"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="degree"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.degree}
                    placeholder="Degree"
                  />
                </div>
              </div>

              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "branch_specialization"
                      ? "text-danger"
                      : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Branch / Specialization
                </label>
                <div
                  className={`${
                    isFocused == "branch_specialization"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="branch_specialization"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.branch_specialization}
                    placeholder="Branch / Specialization"
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "join_date" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Year of Joining
                </label>
                <div
                  className={`${
                    isFocused == "join_date"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="date"
                    name="join_date"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.join_date}
                    placeholder="Year of Joining"
                  />
                </div>
              </div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "complete_date" ? "text-danger" : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  Year of Completion
                </label>
                <div
                  className={`${
                    isFocused == "complete_date"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="date"
                    name="complete_date"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.complete_date}
                    placeholder="Year of Completion"
                  />
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <label
                  htmlFor=""
                  className={`form-label fw-bold font-12 font-weight-500  ${
                    isFocused == "cgpa_percentage"
                      ? "text-danger"
                      : "text-black"
                  }`}
                  style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                >
                  CGPA / Percentage
                </label>
                <div
                  className={`${
                    isFocused == "cgpa_percentage"
                      ? "add_emp_input_active"
                      : "add_emp_input"
                  }`}
                >
                  <input
                    type="text"
                    name="cgpa_percentage"
                    style={{ paddingLeft: ".75rem" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.cgpa_percentage}
                    placeholder="CGPA / Percentag"
                  />
                </div>
              </div>
            </div>
            <div class="row pt-4 ">
              <div className="col-7"></div>
              <div
                className="col-5 ps-5 "
                style={{ marginLeft: "Auto", width: "auto" }}
              >
                <button
                  type="button"
                  class="btn btn-de-danger btn-sm border-danger"
                  onClick={() => handleClose()}
                  disabled={loader}
                >
                  Cancel
                </button>
                <button
                  type="Submit"
                  class="hr btn btn-primary mx-2"
                  disabled={loader}
                >
                  {loader ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
