import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Form from "react-bootstrap/Form";
import CancelIcon from "@mui/icons-material/Cancel";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { GrAttachment } from "react-icons/gr";
import { ImAttachment } from "react-icons/im";

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";
// import {
//   createLegalEntityApi,
//   updateLeagalEntityApi,
// } from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
// import { LocalAtmSharp } from "@mui/icons-material";
// import { leagalEntitySchema } from "../OrganizationComponents/OrganizationSchema";
// import ShowError from "../OrganizationComponents/ShowError";
// import { useDispatch } from "react-redux";
// import { fetchLegalEntity } from "../../../../redux/features/Hr/LegalEntitySlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "900px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const InitiateExitModal = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);

  const initialValues = {
    entity_name: "",
    legal_name: "",
    image: "",
    cin: "",
    date_of_incorp: "",
    business_type: "",
    sector: "",
    business_nature: "",
    address_line_1: "",
    address_line_2: "",
    country: "India",
    state: "",
    city: "",
    zip: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: leagalEntitySchema,
    onSubmit: (values) => {
      //   if (editData) {
      //     updateLeagalEntity(values);
      //   } else {
      //     addLeagalEnity(values);
      //   }
    },
  });

  //   const handleFileUpload = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setFieldValue("image", reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };

  //   const handleDeleteLogo = () => {
  //     setFieldValue("image", "");
  //   };

  //   async function addLeagalEnity(value) {
  //     setLoader(true);
  //     try {
  //       const res = await createLegalEntityApi(value);
  //       toast.success("Leagal Enity Added Successfully");
  //       handleClose();
  //       // getLegalEntity();
  //       dispatch(fetchLegalEntity(dispatch))
  //       resetForm();
  //     } catch (error) {
  //       toast.error(error?.response?.data?.message, { autoClose: 1000 });
  //     } finally {
  //       setLoader(false);
  //     }
  //   }

  //   async function updateLeagalEntity(value) {
  //     try {
  //       const res = await updateLeagalEntityApi(value);
  //       toast.success("Leagal Enity Updated Successfully");
  //       handleClose();
  //       dispatch(fetchLegalEntity(dispatch))
  //     } catch (error) {
  //       console.log("update", error);
  //       toast.error(error?.response?.data?.message);
  //     } finally {
  //       setLoader(false);
  //     }
  //   }

  //   useEffect(() => {
  //     if (editData) {
  //       setValues({ ...editData, image: "" });
  //     } else {
  //       resetForm();
  //     }
  //   }, [editData]);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            Initiate Exit
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3 exit-modal">
              <div className="row mb-4">
                <h6 className="mb-3 initiate-Exit-Color">
                  What is the reason for initiating the exit?
                </h6>
                <div className="col-4">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="Employee wants to resign"
                    name="group1"
                    type="radio"
                    id="inline-radio-2"
                  />
                </div>

                <div className="col-5">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="Company decides to terminate"
                    name="group1"
                    type="radio"
                    id="inline-radio-1"
                  />
                </div>
              </div>

              <div className="row mb-4">
                <h6 className="mb-3 initiate-Exit-Color">
                  Did you have discussion with employee on their decision to
                  exit?
                </h6>
                <div className="col-2 ">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="Yes"
                    name="group2"
                    type="radio"
                    id="inline-radio-3"
                  />
                </div>

                <div className="col-2">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="No"
                    name="group2"
                    type="radio"
                    id="inline-radio-4"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="initiate-Exit-Color mb-3">Summary</h6>
                <div className="col-12">
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Employee want pursue a career in another field."
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "120px" }}
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <h6 className="initiate-Exit-Color mb-3">
                    Please select a reason for resignation
                  </h6>
                  <Form.Select aria-label="Default select example" className="border border-secondary">
                    <option value="1">Explore Other careers</option>
                  </Form.Select>
                </div>
                <div className="col-6">
                  <h6 className="initiate-Exit-Color mb-3">
                    When did employee provide the notice of exit?
                  </h6>

                  <Form.Control
                  className="border border-secondary"
                    type="date"
                    id="inputdate"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="initiate-Exit-Color mb-3">
                  Do you want to recommend a Last Working Day?
                </h6>
                <div className="col-5">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    style={{ accentColor: "#FF6347" }}
                    inline
                    label="Complete notice period (16 Jul, 2023)"
                    name="group4"
                    type="radio"
                    id="inline-radio-full-notice"
                  />
                </div>

                <div className="col-4">
                  <Form.Check
                    className="initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="Other Date"
                    name="group4"
                    type="radio"
                    id="inline-radio-date"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-4">
                  <Form.Check
                    className="checkbox-hire initiate-Exit-Color initiate-Exit-font"
                    inline
                    label="OK to Rehire"
                    name="group5"
                    type="checkbox"
                    id="inline-radio-date"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="mb-3 initiate-Exit-Color">Comment</h6>
                <div className="col-12">
                  <Form.Control
                    className="border border-secondary"
                    as="textarea"
                    placeholder="Leave a comment here"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <h6 style={{ color: "#4667B6" }}>
                  <span>
                  <ImAttachment color="#4667B6" />
                  </span>{" "}
                  Add Attachment
                </h6>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  "Initiate Exit"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default InitiateExitModal;
