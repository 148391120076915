import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function CommonTd({ value, width, color = null }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  return (
    <>
      <td>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 100 }}
          overlay={renderTooltip(value)}
        >
          <h6 style={{ minWidth: width, maxWidth: width, color: color }}>
            {value ? value : "---"}
          </h6>
        </OverlayTrigger>
      </td>
    </>
  );
}

export default CommonTd;
