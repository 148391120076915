import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";

export default function AddPermissionModal({
  show,
  handleClose,
  editAblePermission,
  getPermission,
}) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [name, setName] = useState("");
  const [validationError, setValidationError] = useState("");
  const [spinner, setSpinner] = useState(false);

  function addPermission() {
    setSpinner(true);
    let url = baseUrl + "storepermission";
    let data = { user_id, name };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getPermission();
          setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error?.response?.data?.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
      });
  }

  function editPermission() {
    setSpinner(true);
    let url = baseUrl + "editpermission";
    let permission_id = editAblePermission.id;
    let data = { user_id, name, permission_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getPermission();
          setName("");
        }
      })
      .catch((error) => {
        setSpinner(false);
        setValidationError(error?.response?.data?.response);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editAblePermission) {
      editPermission(e);
    } else {
      addPermission(e);
    }
  };

  useEffect(() => {
    if (editAblePermission) {
      setName(editAblePermission.name);
    } else {
      setName("");
    }
    setValidationError("");
  }, [editAblePermission]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="form-label">Permission Name</label>
                <input
                  className="form-control border-secondary"
                  placeholder="Enter Permission Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                ></input>
                <p className="text-danger p-0 m-0">{validationError?.name}</p>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  {editAblePermission ? (
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={editPermission}
                    >
                      Update Permission
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={addPermission}>
                      Add Permission
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
