import React,{useState} from 'react'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AttendanceDetail from './AttendanceDetail';
import "../../HrDepartment.scss"
const TimeDetail = () => {
    const [activeTab, setActiveTab] = useState("0");
    const handleTabChange = (eventkey, event) => {
    
   
  
        setActiveTab(eventkey);
      
      };
      
  return (
    <>
      <Tabs
                  id="controlled-tab-example"
                  activeKey={activeTab}
                  onSelect={(eventkey, event) =>
                    handleTabChange(eventkey, event)
                  }
                  className="my-3"
                  style={{ fontSize: "1.2rem", fontWeight: 600 ,color:"#BDBDBD"}}
                >

<Tab eventKey="0" title="Leave">Leave</Tab>
<Tab eventKey="1" title="Attendance"><AttendanceDetail/></Tab>


                </Tabs>
    </>
  )
}

export default TimeDetail