import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios"
import { toast } from "react-toastify";
import { allForms } from "../../schemas/settingSchema";
import { useFormik } from "formik";


function DesignationAdd() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const navigate = useNavigate()
  const [name, setName] = useState("");
  const [nameError , setNameError]= useState('')
  const baseUrl  = process.env.REACT_APP_DEV_API_KEY_NEW;

  const initialValues = {
    name : ''
  }

  const {handleSubmit, values , errors , handleChange }= useFormik({
    initialValues : initialValues,
    validationSchema: allForms,
    onSubmit : (values) => {
      addDesignation(values)
    }
  }) 

  
  function addDesignation(value) {
    const data = {
      user_id,
      ...value,
    };
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    axios.post(baseUrl + "storedesignation", data, {
      headers: {
        Authorization: bearer_token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      if (response.data.success) {               
          navigate("/designation");
          toast.success("Added Successfully");
      }
  })
  .catch((error)=>{
       if (error.response.data.message === 'Error validation') {
        setNameError(error.response.data.response.name);
      }
  })
  }

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="#!">Projects /</Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/designation">Designation/</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Designation</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Add Designation</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Designation
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              id="name"
                              name="name"
                              placeholder="Enter Designation name"
                              maxLength={40}
                              value={values.name}
                              onChange={handleChange}
                            />
                             <p className="text-capitalize" style={{ color: "red" }}>{errors.name ? errors.name  : nameError}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm me-3"
                        >
                          Add Designation
                        </button>
                        <Link to='/designation'>
                        <button
                          type="button"
                          className="btn btn-de-danger btn-sm"
                        >
                          Cancel
                        </button>
                        </Link>
                      </div>
                    </form>

                    {/* <p style={{ color: "red" }}>{error}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DesignationAdd;
