import Select from "react-select";
import React from "react";

function GoodsReciptFormDeliveryChallan({
  validationError,
  handleChange,
  errors,
  touched,
  handleBlur,
  handleDeliveryChallanData,
  handleFileInputChange,
  dropdownList,
  loading,
  values,
  setSearchTerm,
  searchTerm,
}) {
  return (
    <>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Delivery Challan
        </label>
        <Select
          placeholder="Search By PO Number"
          style={{ width: "100%" }}
          className="form-select-control border-secondary add-grn-input"
          options={[{ id: "", challan_no: "Select an item" }, ...dropdownList]}
          isLoading={loading}
          getOptionLabel={(dropdownList) => dropdownList["challan_no"]}
          getOptionValue={(poOrder) => poOrder["id"]}
          value={
            dropdownList.find(
              (option) => option.id === values.delivery_challan_id
            ) || {
              id: "",
              challan_no: "Select an item",
            }
          }
          onInputChange={(value) => setSearchTerm(value)}
          onChange={handleDeliveryChallanData}
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary25: "lightgrey",
              primary: "lightgrey",
            },
          })}
        />
        <p className="text-danger m-0">
          {touched.po_id && errors.po_id
            ? errors.po_id
            : validationError?.po_id}
        </p>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Dispatch from Store
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="doc_no"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Supplier Name"
            style={{ fontSize: ".9rem" }}
            value={values?.dispatch_store_name}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Railway PO Number
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="date"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            value={values?.railway_po_no}
            readOnly
            placeholder="Railway PO Number"
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Delivery Challan Generated By
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="grn_no"
            className="form-control form-control-sm border-secondary"
            placeholder="PO Generated By"
            style={{ fontSize: ".9rem" }}
            value={values?.prepared_by}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Dispatch to Store
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="text"
            name="order_no"
            className="form-control form-control-sm border-secondary"
            placeholder="Store Name "
            style={{ fontSize: ".9rem" }}
            value={values?.delivered_to_store_name}
            readOnly
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Delivery Challan Date
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="date"
            name="invoice_date"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Delivery Location"
            style={{ fontSize: ".9rem" }}
            value={values?.deliveryChallan_Date}
            readOnly
          />

          <p className="text-danger m-0">
            {touched.invoice_date && errors.invoice_date
              ? errors.invoice_date
              : validationError?.invoice_date}
          </p>
        </div>
      </div>
      {/* <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Delivery Challan
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="invoice"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
          />
        </div>
      </div> */}
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Supplier Test Certificate
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="supplier_test_certificate"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
          />
        </div>
      </div>
      <div className="col-3">
        <label htmlFor="" className="form-label fw-bold font-16">
          Incoming Inspection
        </label>
        <div className="input-group me-3 add-grn-input">
          <input
            type="file"
            name="incoming_inspection"
            className="form-control form-control-sm border-secondary"
            style={{ fontSize: ".9rem" }}
            onChange={(event) => {
              handleFileInputChange(event);
            }}
          />
        </div>
      </div>
      <div className="col-10">
        <label htmlFor="" className="form-label fw-bold font-16">
          Comments
        </label>
        <div className="input-group me-3 add-grn-input">
          <textarea
            type="text"
            name="comments"
            className="form-control form-control-sm border-secondary"
            placeholder="Enter Comments"
            style={{ fontSize: ".9rem" }}
            value={values?.comments}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default GoodsReciptFormDeliveryChallan;
