import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import EditProjectDetailForm from "../../Components/ProjectForms/EditForms/EditProjectDetailForm";
import PoLoaEditForm from "../../Components/ProjectForms/EditForms/PoLoaEditForm";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ScopeOfWork from "../../Components/ProjectForms/AddForms/ScopeOfWork";

function EditProjectData() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [activeAccordionKey, setActiveAccordionKey] = useState("0");
  const [scopeOfWorkData, setScopeOfWorkData] = useState([]);
  const navigate = useNavigate();
  const param = useParams();
  let project_id = Number(param.id);
  const changeKey = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };

  function listscopeofwork() {
    let url = baseUrl + "listscopeofwork";
    let data = { user_id, project_id };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          setScopeOfWorkData(response?.data?.response?.scopeOfWorkList);
        }
      })
      .catch((error) => {
        if (error.response.status === 429) {
          navigate("/projects");
          toast.error(
            "Too many request at the moment, Please try again after sometime"
          );
        }
      });
  }

  useEffect(() => {
    listscopeofwork();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      {/* <li className="breadcrumb-item">
                        <Link to=""></Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/projects">Projects/</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Project</li>
                    </ol>
                  </div>
                  <h4 className="fw-bold">Edit Project</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Accordion activeKey={activeAccordionKey}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => changeKey("0")} className="accordianHeader" >
                          PROJECT DETAILS
                        </Accordion.Header>
                        <Accordion.Body>
                          <EditProjectDetailForm
                            changeKey={changeKey}
                          ></EditProjectDetailForm>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => changeKey("1")} className="accordianHeader">
                          LOA/PO COST BIFURCATION
                        </Accordion.Header>
                        <Accordion.Body>
                          <PoLoaEditForm
                            scopeOfWorkData={scopeOfWorkData}
                            changeKey={changeKey}
                            activeAccordionKey={activeAccordionKey}
                          ></PoLoaEditForm>
                        </Accordion.Body>
                      </Accordion.Item>
                      {!scopeOfWorkData.length && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header onClick={() => changeKey("2")} className="accordianHeader">
                            SCOPE OF WORK
                          </Accordion.Header>
                          <Accordion.Body>
                            <ScopeOfWork projectId={project_id}></ScopeOfWork>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditProjectData;
