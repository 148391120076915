import React, { useState } from "react";
import CommonTableExits from "../../MaterialUiComponent/CommonTableExits";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitSearchBar from "./ExitSearchBar";

const ExitedEmployees = ({ tabName }) => {
  const columns = [
    { id: "Employee", label: "Employee", minWidth: "8rem" },
    { id: "Email Address", label: "Email Address", minWidth: "10rem" },
    { id: "Designation", label: "Designation", minWidth: "10rem" },
    { id: "Department", label: "Department", minWidth: "10rem" },
    { id: "Location", label: "Location", minWidth: "10rem" },
    { id: "Reporting Manager", label: "Reporting Manager", minWidth: 200 },
    { id: "Date of Joining", label: "Date of Joining", minWidth: 150 },
    { id: "Notice Date", label: "Notice Date", minWidth: 150 },
    { id: "Exit Date", label: "Exit Date", minWidth: 150 },
    {
      id: "Exit Type & Resign Date",
      label: "Exit Type & Resign Date",
      minWidth: "12rem",
    },

    { id: "Comment", label: "Comment", minWidth: "8rem" },
    { id: "OK to rehire", label: "OK to rehire", minWidth: "12rem" },
  ];

  // Sample data
  const rows = [
    {
      Employee: "John Doe",
      "Email Address": "user@gmail.com",
      Designation: "Graphic Designer",
      Department: "Technical",
      Location: "Delhi",
      "Reporting Manager": "Onkar",
      "date of Joining": 2021 - 12 - 3,
      "Notice Date": "2024-12-9",
      "Exit Date": "2024-12-16",
      "Exit Type & Resign Date": "",
      Comment: "--",
      "OK to rehire": "yes",
    },

    // Add more sample data here
  ];
  const departmentChange = () => {
    console.log(tabName);
  };

  return (
    <section className="exited-Employees">
      <div className="bg-white mx-3">
        <ExitSearchBar tabName={tabName} departmentChange={departmentChange} />
        <CommonTableExits
          columns={columns}
          rows={rows}
          actionfield={<p style={{ color: "#4667b6" }}>Take Action</p>}
        />
      </div>
    </section>
  );
};

export default ExitedEmployees;
