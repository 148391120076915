import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "../../HrDepartment.scss";
import PendingApprovals from "./PendingApprovals";
import ExitSearchBar from "./ExitSearchBar";
import InExitProcess from "./InExitProcess";
import ExitedEmployees from "./ExitedEmployees";
import RevertedExits from "./RevertedExits";
import NoticePeriod from "./NoticePeriod";
import AddNoticePeriodModal from "./AddNoticePeriodModal";

const ExitsMain = () => {
  const [value, setValue] = useState("Pending Approvals");
const[addNoticePeriod,setAddNoticePeriod] = useState(false)
const handleClose =()=>{
  setAddNoticePeriod(false)
}
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab p-0">
          <div className="container-fluid">
            <section className="employee-main-tab">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  {/* Tab headers in one row */}
                  <div className="row">
                    <div className="" style={{ backgroundColor: "white" }}>
                      <div className="col-md-8">
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          variant="scrollable"
                          scrollButtons="auto"
                          className="custom-tablist"
                        >
                          <Tab
                            label="Pending Approvals"
                            value="Pending Approvals"
                          />
                          <Tab
                            label="In Exit Process"
                            value="In Exit Process"
                          />
                          <Tab
                            label="Exited Employees"
                            value="Exited Employees"
                          />
                          <Tab
                            label="Reverted Exited"
                            value="Reverted Exited"
                          />
                          <Tab label="Notice Period" value="Notice Period" />
                        </TabList>
                      </div>
                    </div>
<div className="d-flex justify-content-between align-items-center my-3 pe-3">
                    <span className="dashboard ps-3 ">EXITS</span>
                    {value==="Notice Period"&&<button className='red-button btn btn-primary ' onClick={()=>setAddNoticePeriod(true)}> Add Notice Period</button>}
                    </div>
                  </div>

                  {/* Tab content in another row */}
                  <div className="row">
                    <div className="accordian ">
                      <TabPanel value="Pending Approvals" className="p-0">
                        {/* Specific content for Pending Approvals */}
                        <PendingApprovals tabName={value} />
                      </TabPanel>
                      <TabPanel className="p-0" value="In Exit Process">
                        {/* Specific content for Pending Approvals */}
                        <InExitProcess tabName={value} />
                      </TabPanel>
                      <TabPanel className="p-0" value="Exited Employees">
                        {" "}
                        <ExitedEmployees tabName={value} />
                      </TabPanel>
                      <TabPanel className="p-0" value="Reverted Exited">
                        <RevertedExits tabName={value} />{" "}
                      </TabPanel>
                      <TabPanel className="p-0" value="Notice Period">
                        <NoticePeriod tabName={value} />
                      </TabPanel>
                    </div>
                  </div>
                </TabContext>
              </Box>
              <AddNoticePeriodModal open={addNoticePeriod} handleClose={handleClose}/>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExitsMain;
