import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;

const initialState = {
  purchaseOrderList: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchPurchaseOrderList = createAsyncThunk(
  "purchaseOrderList/fetchPurchaseOrderList",
  async ({ config, user_id }) => {
    try {
      const response = await axios.post(
        `${baseUrl}listpurchaseorder`,
        { user_id },
        config
      );
      return response.data?.response?.PurchaseOrderList;
    } catch (error) {
      console.log("error", error);
      throw new Error("Failed to fetch purchase order list");
    }
  }
);

const purchaseOrderSlice = createSlice({
  name: "purchaseOrderList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPurchaseOrderList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPurchaseOrderList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.purchaseOrderList = action.payload;
        state.error = null;
      })
      .addCase(fetchPurchaseOrderList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectPurchaseOrderList = (state) =>
  state.purchaseOrderList.purchaseOrderList;
export const selectPurchaseOrderStatus = (state) =>
  state.purchaseOrderList.status;
export const selectPurchaseOrderError = (state) =>
  state.purchaseOrderList.error;

export default purchaseOrderSlice.reducer;
