import axios from "axios";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { errorsMessage } from "../../constant";
import { useState } from "react";

function CommonPurchaseModal({
  show,
  setShow,
  url,
  heading,
  inputFields,
  initialValues,
  updatedList,
  selectFields = [],
  validationSchema,
  optionkey,
}) {
  const handleClose = () => setShow(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const departmentId = user?.response?.userDetails?.department_id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const initialValue = initialValues;
  const [serverSideValidation, setServerSideValidation] = useState();

  function addMainGroupMaster(values) {
    // setLoadingTicketId(ticket);
    const apiUrl = baseUrl + url;
    const data = {
      user_id,
      ...values,
      department_id: departmentId,
    };
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          setShow(false);
          resetForm();
          updatedList();
          toast.success("Form submit successfully");
        }
      })
      .catch((error) => {
        setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        // setLoadingTicketId(null);
      });
  }
  const { values, handleSubmit, handleChange, resetForm, errors, touched } =
    useFormik({
      initialValues: initialValue,
      validationSchema,
      onSubmit: (values) => {
        addMainGroupMaster(values);
      },
    });
  return (
    <>
      <div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="font-16 m-0">{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              {selectFields.map((element, i) => (
                <div className="form-group mb-3" key={i}>
                  <label htmlFor="" className="form-label fw-bold font-16">
                    {element.label}
                  </label>
                  <select
                    className="form-control border-secondary"
                    name={element.name}
                    value={values[element.name]}
                    onChange={handleChange}
                  >
                    <option value={""}>Select</option>
                    {element?.option.map((option, index) => {
                      return (
                        <option value={option.id} key={index}>
                          {option[optionkey]}
                        </option>
                      );
                    })}
                  </select>
                  <p className="text-danger m-0">
                    {serverSideValidation
                      ? serverSideValidation[element.name]
                      : touched[element.name] && errors[element.name]
                      ? errors[element.name]
                      : null}
                  </p>
                </div>
              ))}
              {inputFields.map((element, i) => (
                <div className="form-group mb-3" key={i}>
                  <label htmlFor="" className="form-label fw-bold font-16">
                    {element.label}
                  </label>
                  <input
                    className="form-control border-secondary"
                    name={element.name}
                    placeholder={element.placeHolder}
                    value={values[element.name]}
                    onChange={handleChange}
                  />
                  <p className="text-danger m-0">
                    {serverSideValidation
                      ? serverSideValidation[element.name]
                      : touched[element.name] && errors[element.name]
                      ? errors[element.name]
                      : null}
                  </p>
                </div>
              ))}
              <div className="text-end">
                <button className=" btn btn-danger">Submit</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CommonPurchaseModal;
