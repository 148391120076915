import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaFilePdf } from "react-icons/fa";
import { poOrderListApi } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import CommonTable from "../Common/CommonTable";
import CommonTd from "../../Tender/Common/CommonTd";
import DeliveryScheduleModal from "./DeliveyScheduleModal";

function PoProjectPurchaseList({ poOrderList, tabKey }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const tableHeading = [
    { name: "Sr No", width: "2rem" },
    { name: "Order No", width: "10rem" },
    { name: "OrderDate", width: "8rem" },
    { name: "Party Name", width: "20rem" },
    // { name: "Total quantity", width: "10rem" },
    { name: "Grand Total", width: "8rem" },
    { name: "Delivery Schedule", width: "8rem" },
    { name: "Pdf", width: "4rem" },
    { name: "Edit", width: "4rem" },
  ];

  const handleOpenModal = (element) => {
    setSelectedElement(element);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedElement(null);
  };

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-sm-6">
            <h4 className="page-title">Purchase Order</h4>
          </div>
          <div className="col-sm-6 text-end"></div>
        </div>

        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center pb-5"
            style={{ height: "70vh" }}
          >
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="danger" />
          </div>
        ) : (
          <div className="card">
            <div
              className="card-body "
              style={{ padding: "1rem 0", margin: "0" }}
            >
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading} />
                  <tbody>
                    {poOrderList?.map((element, index) => (
                      <tr key={index}>
                        <CommonTd width="2rem" value={index + 1} />
                        <CommonTd
                          width="10rem"
                          value={element?.purchase_order_no}
                        />
                        <CommonTd
                          width="8rem"
                          value={formatDate(element?.order_date)}
                        />
                        <CommonTd
                          width="20rem"
                          value={element?.party_details?.party_name}
                        />
                        {/* <CommonTd width="10rem" value={element?.total_qty} /> */}
                        <CommonTd width="8rem" value={element?.sub_total} />
                        <td>
                          <button
                            onClick={() => handleOpenModal(element)}
                            style={{
                              width: "8rem",
                              border: "1px solid white",
                              background: "rgba(239, 77, 86, .15) ",
                              color: "#ef4d56",
                              padding: "0.5rem",
                            }}
                          >
                            Add/View
                          </button>
                        </td>
                        <td>
                          <p
                            style={{
                              minWidth: "4rem",
                              maxWidth: "4rem",
                              margin: "auto",
                              padding: "auto",
                              cursor: "pointer",
                            }}
                            className="text-center"
                          >
                            <Link to={`/purchase-order-pdf/${element?.id}`}>
                              <FaFilePdf
                                className="text-dark font-20 m-0 p-0"
                                style={{ margin: "auto" }}
                              />
                            </Link>
                          </p>
                        </td>
                        <td>
                          <Link
                            to={
                              tabKey === "Update-Non-Tender"
                                ? "/update-non-tender/purchaseOrder"
                                : "/update-purchase-order"
                            }
                            state={element}
                          >
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <FaRegEdit
                                className="text-dark font-20 m-0 p-0"
                                style={{ margin: "auto" }}
                              />
                            </p>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedElement && (
        <DeliveryScheduleModal
          open={open}
          setOpen={setOpen}
          itemDetails={selectedElement?.item_details}
          id={selectedElement?.id}
          POnumber={selectedElement?.purchase_order_no}
        />
      )}
    </div>
  );
}

export default PoProjectPurchaseList;
