import Accordion from "react-bootstrap/Accordion";
import AddItemMaster from "./AddItemMaster";
import AddStockValue from "./AddStockValue";
import AddRateList from "./AddRateList";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorsMessage } from "../../../constant";
import axios from "axios";
import { useEffect, useState } from "react";
import { itemMasterSchema } from "../../ValidationSchema/SettingSchema";
import { getSubGroupMasterList } from "../../purchaseApi";

function ItemMaster() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const [serverSideValidation, setServerSideValidation] = useState("");
  const [subGroupDropdown, setSubGroupDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        setFieldValue("product_image_url", filesArray[0]);
      }
    );
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  function addItemMaster(values) {
    const apiUrl = baseUrl + "addpurchaseitemmaster";
    const data = {
      user_id,
      ...values,
    };
    setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          toast.success("Form submit successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        setServerSideValidation(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const initialValue = {
    product_name: "",
    main_group: "",
    sub_group: "",
    brand: "",
    material: "",
    product_name: "",
    product_specification: "",
    hsn_code: "",
    product_image_url: "",
    drive_type: "",
    head_type: "",
    unit: "",
    unit_packaging: "",
    item_name: "",
    monthly_consumption: "",
    reorder_level: "",
    minimum_stock: "",
    maximum_stock: "",
    purchase_price: "",
    mrp: "",
    gst: "",
    gst_date: "",
    gst_include: "",
    item_type: "",
  };

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: itemMasterSchema,
    onSubmit: (values) => {
      addItemMaster(values);
    },
  });
  function subGroupDropdownList() {
    getSubGroupMasterList({ user_id })
      .then((res) => {
        let SubGroupDropdown = res?.data?.response?.SubMasterList;
        let data = SubGroupDropdown.filter((element) => {
          return element.main_group_id === +values.main_group;
        });
        setSubGroupDropdown(data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  useEffect(() => {
    if (values.main_group) {
      subGroupDropdownList();
    }
  }, [values.main_group]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <form>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <Link to={"/addItemMaster"}>Item</Link>
                  </Accordion.Header>
                  <Accordion.Body>
                    <AddItemMaster
                      values={values}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      handleFileChange={handleFileChange}
                      serverSideValidation={serverSideValidation}
                      subGroupDropdown={subGroupDropdown}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Stock Value</Accordion.Header>
                  <Accordion.Body>
                    <AddStockValue
                      values={values}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Rate List</Accordion.Header>
                  <Accordion.Body>
                    <AddRateList
                      values={values}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {loading ? (
                <div className="text-end mt-3">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Adding Item...</p>
                </div>
              ) : (
                <div className="text-end mt-3">
                  <button
                    className="btn btn-danger mx-2"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-danger" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMaster;
