export function formatDate(value) {
    var date = new Date(value);
    if (value) {
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return formattedDate;
  }

  export function toTitleCase(str) {
    if (!str) return ''; // Handle empty or undefined strings
    
    // Convert each word to title case (capitalize the first letter and make the rest lowercase)
    return str
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
      .join(' '); // Join the words back into a single string
  }

 // fileUtils.js
export const convertFileToBase64 = (file, callback) => {
  if (!file) {
    console.error("No file provided");
    return;
  }

  const reader = new FileReader();

  reader.onload = () => {
    const base64String = reader.result;
    callback(base64String);  // Call the callback with the Base64 string
  };

  reader.onerror = (error) => {
    console.error("Error reading file:", error);
  };

  reader.readAsDataURL(file);  // Read the file as a Base64-encoded URL
};
