import React, { useState } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { allForms } from "../../schemas/settingSchema";


function EditInspectionAgency() {
  const [validationError,setvalidationError] = useState("");
  const location = useLocation();
  const { initialName, id } = location?.state ?? {};
  const initialValues = {
    name: initialName,
  };
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [spinner, setSpinner] = useState(false);
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const { handleSubmit, values, errors, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: allForms,
    onSubmit: (values) => {
      setSpinner(true);
      EditInspectionApi(values);
      
    },
  });
  function EditInspectionApi(value) {
    const data = {
      user_id,
      InspectionAgencyId:id,
      ...value,
    };
    axios
      .post(baseUrl + "editinspectionagency", data, config)
      .then((response) => {
        if (response.data.success) {
          setSpinner(false)
          setvalidationError("");
          navigate("/inspection-agency");
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setvalidationError(error.response.data.response.name);
          setSpinner(false)
        } else {toast.error("Something went wrong.");
         setSpinner(false);
      }
      });
  }


  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="#!">Projects /</Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/inspection-agency">Inspection Agency/</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Inspection Agency</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Inspection Agency</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter name"
                              maxLength={40}
                              value={values.name}
                              onChange={handleChange}
                              onError={errors}
                            />
                             <p className="text-danger">
                              {errors.name ? errors.name : validationError}
                            </p>
                          </div>
                        </div>
                       
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>wait...</p>
                        </div>
                      ) : (
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm me-3"
                        >
                          Update
                        </button>
                        <Link to="/inspection-agency">
                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm"
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>)}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditInspectionAgency;
