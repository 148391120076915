import React,{useState} from "react";
import image from "../../Assets/Group 1000003296.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationModal from "./LocationModal";
import { deleteLocationApi } from "../OrganizationComponents/organizationStructureApi";
import DeleteModal from "../OrganizationComponents/DeleteModal";
import { toast } from "react-toastify";
function LocationSummary({detail,editData, getLocationList, setEditData}) {

const[open,setOpen] = useState(false)
const[deleteOpen,setDeleteOpen] = useState(false)
const[loader,setLoader] = useState(false)
const handleEditClicked =()=>{

  setOpen(true)
  setEditData(detail)
}
const handleDelete =()=>{
  setDeleteOpen(true)
}
const handleDeletemodalClose =()=>{
  setDeleteOpen(false)
}
const handleClose =()=>{
  setOpen(false)
}
const clicked =()=>{
  alert("clicked")
}
  async function deleteLocation(value) {
    const reqPayload ={id:detail?.id}
    setLoader(true);
    try {
      const res = await deleteLocationApi(reqPayload);
      toast.success("Location update Successfully",{autoClose:1000});
      handleClose();
      getLocationList()
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }

  return (
    <div
      style={{
        border: "1px solid rgba(207, 207, 207, 1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 1)",
        padding: "1.5rem",
      }}
      className="legalEntityRegisteredAddress d-flex justify-content-between"
    >
      <div>
        <p className="mb-3"> Business Head</p>
        <div className="d-flex gap-3 align-items-center mb-3">
          <img
            src={image}
            className="rounded-circle"
            style={{ height: "3rem", width: "3rem" }}
          />
          <p>Ritik</p>
        </div>
        <div>
          <h5>Description</h5>
          <p>
          {detail?.description}
          </p>
        </div>
      </div>
     
       <div className="dropdown">
        <button
          className="dropdown-toggle border-0 bg-transparent"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <MoreVertIcon />
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ minWidth: "8rem" }}
        >
          <li>
            <button
              className="dropdown-item"
              onClick={()=>handleEditClicked()}
            >
              Edit
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={()=>handleDelete()}
            >
              Delete
            </button>
          </li>
        </ul>
        <LocationModal open={open} handleClose={handleClose} editData={editData} getLocationList={getLocationList} />
        <DeleteModal  deleteModalOpen={deleteOpen} handleDeletemodalClose={handleDeletemodalClose} deleteData={deleteLocation} loader={loader}/>
      </div>
     
    </div>
  );
}

export default LocationSummary;
