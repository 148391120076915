import React, { useState } from "react";
import image from "../../Assets/Group 1000003296.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BusinessUnitModal from "./BusinessUnitModal";
import { deleteBusinessUnitApi } from "../OrganizationComponents/organizationStructureApi";
import DeleteModal from "../OrganizationComponents/DeleteModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, setLoading } from "../../../../redux/features/Hr/LoaderSlice";
import { fetchBusinessUnit } from "../../../../redux/features/Hr/BusinessUnitslice";
function BusinessUnitSummary({
  businessUnit,
  editData,
  setEditData,

}) {
  const [open, setOPen] = useState(false);
  const [deleteModalOpen, setDeleteModalOPen] = useState(false);

  const dispatch = useDispatch()
  const loader =useSelector(selectLoading)
  const handleClose = () => {
    setOPen(false);
  };
  const handleEditClicked = () => {
    setEditData(businessUnit);
    setOPen(true);
  };
  const handleDeleteClicked = () => {
    setDeleteModalOPen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOPen(false);
  };
  async function deleteBusinessUnit() {
    dispatch(setLoading(false))
    const requestPayload = { id: businessUnit?.id };
    try {
      const res = await deleteBusinessUnitApi(requestPayload);
      toast.success("Business unit deleted successfully");
      handleDeleteModalClose();
dispatch(fetchBusinessUnit(dispatch))
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
   dispatch(setLoading(false))
    }
  }
  return (
    <div
      style={{
        border: "1px solid rgba(207, 207, 207, 1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 1)",
        padding: "1.5rem",
      }}
      className="legalEntityRegisteredAddress d-flex justify-content-between"
    >
      <div>
        <p className="mb-3">Business Head</p>
        <div className="d-flex gap-3 align-items-center mb-3">
          <img
            src={image}
            className="rounded-circle"
            style={{ height: "3rem", width: "3rem" }}
          />
          <p>{businessUnit?.head}</p>
        </div>
        <div>
          <h5>Description</h5>
          <p>{businessUnit?.description}</p>
        </div>
      </div>
      <div className="dropdown">
        <button
          className="dropdown-toggle border-0 bg-transparent"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <MoreVertIcon />
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ minWidth: "8rem" }}
        >
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleEditClicked()}
            >
              Edit
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => handleDeleteClicked()}
            >
              Delete
            </button>
          </li>
        </ul>
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          handleDeletemodalClose={handleDeleteModalClose}
          deleteData={deleteBusinessUnit}
          loader={loader}
        />
      </div>
      <BusinessUnitModal
        open={open}
        handleClose={handleClose}
        editData={editData}
      
      />
    </div>
  );
}

export default BusinessUnitSummary;
