import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CommonIframe from "../Common/CommonIframe";
import { Spinner } from "react-bootstrap";
import "../TenderStyle/TenderStyle.css";

function TenderAttachmentModal(props) {
  const [key, setKey] = useState("allAttachments");

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="attachment-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Attachments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: "30rem",
            maxHeight: "30rem",
            overflowY: "scroll",
          }}
        >
          {props.modalSpinner ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="allAttachments" title="All Attachments">
                <CommonIframe
                  attachments={props.data?.All}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="tenderDocs" title="Tender Docs">
                <CommonIframe
                  attachments={props.data?.tenderDocs}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="commonDocs" title="Common Docs">
                <CommonIframe
                  attachments={props.data?.commonDocs}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="bidDocs" title="Bid Docs">
                <CommonIframe
                  attachments={props.data?.bidDocs}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="pOCopies" title="PO Copies">
                <CommonIframe
                  attachments={props.data?.pOCopies}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="Miscellaneous" title="Miscellaneous">
                <CommonIframe
                  attachments={props.data?.miscellaneous}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="EmdDocs" title="EMD Docs">
                <CommonIframe
                  attachments={props.data?.EmdDocs}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
              <Tab eventKey="PostTenderDocs" title="Post Tender Docs">
                <CommonIframe
                  attachments={props.data?.PostTenderDocs}
                  renderState={props.renderState}
                  setRenderState={props.setRenderState}
                />
              </Tab>
            </Tabs>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TenderAttachmentModal;
