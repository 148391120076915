import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { allForms } from "../schemas/settingSchema";
import { useFormik } from "formik";
import { departmentDropdown } from "../redux/api";

function AddBoardGroupModal({ show, handleClose, editData, getGroupList }) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [groupId, setGroupId] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  const initialValues = {
    name: "",
    department_id: "",
    is_level:0
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: allForms,
    onSubmit: (values) => {
      if (editData) {
        updateGroup(values);
      } else {
        addGroup(values);
      }
    },
  });

  function addGroup(value) {
    let url = baseUrl + "storeboardgroup";
    let data = { user_id, ...value };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        getGroupList();
        handleClose();
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response.name);
        }
      });
  }

  function updateGroup(value) {
    let url = baseUrl + "updateboardgroup";
    let data = { user_id, ...value, board_group_id: groupId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        getGroupList();
        handleClose();
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response.name);
        }
      });
  }

  useEffect(() => {
    resetForm();
    if (editData) {
      setValues(editData);
      setGroupId(editData.id);
    }
    setValidationError("");
  }, [editData, show]);

  useEffect(() => {
    departmentDropdown({ user_id: user_id })
      .then((res) => {
        setDepartmentList(res?.data?.response?.departmentDropDown);
      })
      .catch((err) => {

      });
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Board Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label htmlFor="reporting_person" className="form-label">
                    Department
                  </label>
                  <select
                    className="form-control border-secondary"
                    id="department_id"
                    name="department_id"
                    value={values.department_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">Select</option>
                    {departmentList?.map((obj, i) => {
                      return (
                        <option value={obj.id} key={i}>
                          {obj.name}
                        </option>
                      );
                    })}
                  </select>
                  {touched.department_id && errors.department_id ? (
                    <p className="text-danger">{errors.department_id}</p>
                  ) : (
                    <p className="text-danger text-capitalize">
                      {validationError?.department_id}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">Board Group</label>
                <input
                  className="form-control border-secondary"
                  placeholder="Enter Board Group"
                  name="name"
                  onBlur={handleBlur}
                  value={values.name}
                  onChange={handleChange}
                ></input>
                <p className="text-danger p-0 m-0">
                  {touched.name && errors.name ? errors.name : validationError}
                </p>
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">Board Group Level</label>
                <input
                  className="form-control border-secondary"
                  placeholder="Enter Board Group Level"
                  name="is_level"
                  onBlur={handleBlur}
                  value={values.is_level}
                  onChange={(e) => setFieldValue('is_level', Number(e.target.value))}
                ></input>
                <p className="text-danger p-0 m-0">
                  {touched.is_level && errors.is_level ? errors.is_level : validationError}
                </p>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    {editData ? "Update Group" : "Add Group"}
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddBoardGroupModal;
