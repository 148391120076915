import React from "react";
import OrgStructureTabs from "./OrgStructureTabs";

function OrganisationStructure() {
  return (
    <div
      className="page-wrapper"
      style={{
        backgroundColor: "#FCFCFC",
        marginLeft: "2rem",
        marginTop: "1rem",
      }}
    >
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid ">
          <h2 className="py-3">ORGANIZATION STRUCTURE</h2>
          <div className="row">
            <OrgStructureTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationStructure;
