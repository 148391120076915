import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function WorkStatus() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [data, setData] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const tableBottomRef = useRef();
  const tableTopRef = useRef();
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);

  //// Fetching Data from APIs /////
  function fetchApis() {
    setLoader(true);
    const url = baseUrl + "getworkstatuslist";
    var body = {
      user_id: user_id,
      search,
      accessType: "view",
    };
    axios
      .post(url, body, config)
      .then((res) => {
        setLoader(false);
        setData(res?.data?.response?.WorkStatusList);
        setWorkStatus(res?.data?.response?.WorkStatusList.slice(0, 8));
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Something went wrong");
      });
  }
  useEffect(() => {
    fetchApis();
  }, [search]);
  //// Fatching Data from APIs End/////

  //// Delete User Start //////

  function handleDelete() {
    const url = baseUrl + "deleteworkstatus";
    const body = {
      user_id: user_id,
      workstatusId: deleteId,
      accessType: "delete",
    };
    axios
      .post(url, body, config)
      .then((res) => {
        fetchApis();
        if (res.data.response.isSuccess || res.data.response.success) {
          toast.success("Deleted Successfully");
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
      });
    setDeleteId(null);
  }
  //// Delete User End //////

  ///// Edit User Start ////
  function editData(id) {
    const editData = data.find((e) => e.id === id);
    navigate("/edit-work-status", { state: { editData: editData } });
  }
  ////// Edit User End /////

  //// Pagination ///////
  const scrollToBottom = () => {
    tableBottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function moreData() {
    const additionalData = data.slice(workStatus.length, workStatus.length + 8);
    setWorkStatus((prev) => prev.concat(additionalData));
    scrollToBottom();
  }

  function lessData() {
    scrollToTop();
  }
  useEffect(() => {
    if (workStatus.length > 8) {
      scrollToBottom();
    }
  }, [workStatus]);
  //// Pagination End /////

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      <li className="breadcrumb-item active">Work Status</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Work Status</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      Here the list of all work Status.{" "}
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/work-status-add">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          Add Work Status
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loader ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="card-title">Our work Status</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead className="thead-light">
                            <tr>
                              <th>S. No.</th>
                              <th>Work Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workStatus?.map((element, index) => {
                              return (
                                <tr key={element.id}>
                                  <th scope="row">{index + 1}</th>
                                  <td className="text-capitalize font-16">
                                    {element.name}
                                  </td>
                                  <td>
                                    <i
                                      onClick={() => editData(element.id)}
                                      className="las la-pen text-dark font-20 px-1"
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Work Status"
                                    ></i>
                                    &nbsp;&nbsp;
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalDelete"
                                    >
                                      <i
                                        onClick={() => setDeleteId(element.id)}
                                        className="las la-trash-alt font-20 px-1 text-dark"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete Work Status"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-end">
                        {workStatus.length > 8 && (
                          <button
                            className="btn btn-secondary btn-sm mx-2"
                            onClick={() => lessData()}
                          >
                            <i className="fa fa-arrow-up mx-2"></i>
                          </button>
                        )}

                        {workStatus.length !== data.length &&
                          data.length > 8 && (
                            <button
                              className="btn btn-success btn-sm mx-2"
                              onClick={() => moreData()}
                            >
                              Show More
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal Start */}
      <div className="modal fade" id="modalDelete">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5 text-center">
              <h4 className="lh-base mb-4">
                Are you sure you want to <br /> delete this record
              </h4>
              <button
                type="button"
                className="btn btn-success me-3"
                onClick={() => handleDelete()}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={tableBottomRef} />
      <div ref={tableTopRef} />
      <Footer></Footer>
    </>
  );
}

export default WorkStatus;
