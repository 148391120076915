import React, { useEffect, useState } from "react";
import LegalEntityBank from "./LegalEntityBank";
import AddBankDetailButton from "./AddBankDetailButton";
import { bankApi } from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
function LegalEntityBankDetail({ legalEntity }) {
  const [bankList, setBankList] = useState([]);
  const [editData, setEditData] = useState(false);
const[loader,setLoader] = useState(false)
  const getBank = () => {
 setLoader(true)
    bankApi(legalEntity?.id).then((res) => {
      setLoader(false)
      const data = res?.data?.response?.data;
      setBankList(data);
    }).catch((err)=>{setLoader(false)
      toast.error(err?.response?.data?.message)
    });
  };
  useEffect(() => {
    getBank();
  }, [legalEntity]);

  return (
    <div>
      <h4 style={{ color: "rgba(70, 103, 182, 1)", marginBottom: "1.8rem" }}>
        Bank Detail
      </h4>
      <AddBankDetailButton
        legalEntityId={legalEntity?.id}
        getBank={getBank}
        editData={editData}
        setEditData={setEditData}
      />
      {loader?(
  <div
    className="d-flex self-align-start justify-content-center pb-5"
    style={{ height: "70vh" }}
  >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
):bankList?.length>0?
      (bankList?.map((bank) => (
        <LegalEntityBank
          bank={bank}
          key={bank?.id}
          editData={editData}
          setEditData={setEditData}
          getBank={getBank}
        />
      ))):<div>No Bank detail found</div>}
    </div>
  );
}

export default LegalEntityBankDetail;
