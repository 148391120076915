import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "../Assets/Group.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "../../HrDepartment.scss"
const EmployeeSlip = () => {
  const [value, setValue] = useState("Salary");
  const [year, setYear] = useState("");

  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const ClickOnSalary = (value) => {
    setValue(value);
  };

  return (
    <>
      <div className="justifyContentSpace">
        <div className="marginBottom24px">
          <button
            className={
              value == "Salary" ? "salaryButtonStyle" : "payslipButtonStyle"
            }
            onClick={() => ClickOnSalary("Salary")}
          >
            Salary
          </button>
          <button
            className={
              value == "Payslip" ? "salaryButtonStyle" : "payslipButtonStyle"
            }
            onClick={() => ClickOnSalary("Payslip")}
          >
            Payslip
          </button>
        </div>
        <div className="paysliipButton">
          {value == "Payslip" && (
            <button className="salaryButtonStyles">Download Payslip</button>
          )}
        </div>
      </div>
      {value == "Salary" && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="card bordeRadius10px">
                <div className="assignedAssets">Current Salary</div>

                <hr />
                <div className="row">
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">
                      Current Compensation
                    </span>
                    <span className="currentSalaryValue">
                      INR 6,00,000 / Annum
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="card bordeRadius10px">
                <div className="assignedAssets">Payroll</div>
                <hr />
                <div className="row">
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Legal Entity</span>
                    <span className="currentSalaryValue">ACME India</span>
                  </div>
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="card bordeRadius10px">
                <div className="justifyContentSpace">
                  <div className="assignedAssets">Salary Timeline</div>
                  <div className="reviseSalary">Revise Salary</div>
                </div>
                <hr />
                <div className="row">
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Legal Entity</span>
                    <span className="currentSalaryValue">ACME India</span>
                  </div>
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div>
                  <div className="col displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
       {value == "Payslip" && (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="card bordeRadius10px">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Year 2024
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={year}
                  label="Year"
                  onChange={handleChange}
                >
                
                  <MenuItem value={10}> 
                   <div className="displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div></MenuItem>
                  <MenuItem value={20}>  
                  <div className="displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div></MenuItem>
                  <MenuItem value={30}>  
                  <div className="displayGrid">
                    <span className="currentSalaryHeading">Pay Cycle</span>
                    <span className="currentSalaryValue">Monthly</span>
                  </div></MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={8}>
          <div className="card cardHeight680PX">
            <div className="datEPayslipStyle">April 2024 Payslip</div>
            <div className="dateParra">Your payslip is password protected. To vie downloaded file, enter you PAN number.</div>
            <div className="card cardHeight680PXH"></div>
          </div>
          </Grid>
        </Grid>
      </div>
       )}
    </>
  );
};
export default EmployeeSlip;
