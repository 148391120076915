// import React, { useState } from "react";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import CommonCard from "../CommonComponent/Card";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import ellipse from "../../Assets/Ellipse 1.png";
// const TimeDashboard = () => {
//   const [department, setDepartment] = useState();
//   const departmentChange = () => {
//     console.log("hj");
//   };
//   return (
//     <>
//       <div className="page-wrapper">
//         <div className="page-content-tab responsive-tab">
//           <div className="container-fluid">
//             <section className="TimeDashboard mx-3">
//               <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
//                 <h3>Today's Stats</h3>
//                 <FormControl sx={{ width: "300px" }}>
//                   <InputLabel
//                     id="demo-simple-select-label"
//                     className="text-center"
//                   >
//                     Department
//                   </InputLabel>
//                   <Select
//                     size="small"
//                     labelId="demo-simple-select-label"
//                     id="named-select"
//                     // id="demo-simple-select"
//                     value={department}
//                     label="Department"
//                     onChange={departmentChange}
//                   >
//                     <MenuItem value={10}>Ten</MenuItem>
//                     <MenuItem value={20}>Twenty</MenuItem>
//                     <MenuItem value={30}>Thirty</MenuItem>
//                   </Select>
//                 </FormControl>
//               </div>
//               <div className="row">
//                 <div className="col-md-7">
//                   <div className="me-2">
//                     <div className="row">
//                       <div className="d-flex align-items-center justify-content-between">
//                         <CommonCard
//                           count={2}
//                           description={"Late Arrivals"}
//                           icon={<AccessTimeIcon sx={{ color: "#751ED3" }} />}
//                           color={"#751ED3"}
//                         />
//                         <CommonCard
//                           count={18}
//                           description={"On Time"}
//                           icon={<AccessTimeIcon sx={{ color: "#3CB272" }} />}
//                           color={"#3CB272"}
//                         />
//                         <CommonCard
//                           count={5}
//                           description={"WFH"}
//                           icon={<AccessTimeIcon sx={{ color: "#B28220" }} />}
//                           color={"#B28220"}
//                         />
//                         <CommonCard
//                           count={3}
//                           description={"Remote Clock-Ins"}
//                           icon={<AccessTimeIcon sx={{ color: "#D33D4C" }} />}
//                           color={"#D33D4C"}
//                         />
//                       </div>
//                     </div>
//                     <div className="row mt-4 ">
//                       <div className="bg-white rounded">
//                         <h3>OverTime Request</h3>
//                         <div className="row ">
//                           <div className="col-md-6">
//                             <div className="me-1 border rounded d-flex justify-content-between align-items-center ">
//                               {/* <div className="d-flex justify-content-between align-items-center"> */}
//                                 <div className="d-flex justify-content-between align-items-center">
//                                   <img src={ellipse} />
//                                   <div className="ps-2">
//                                     <h6 className="m-0">Arun Kumar</h6>
//                                     <p className="text-grey">Sales Executive</p>
//                                   </div>
//                                 </div>
//                                 <div>
//                                   <button className="btn btn-primary mb-2">
//                                     {" "}
//                                     Accept
//                                   </button>
//                                   <button className="btn btn-primary">
//                                     Reject
//                                   </button>
//                                 </div>
//                               {/* </div> */}
//                             </div>
//                           </div>
//                           <div className="col-md-6 ">
//                             <div className="ms-1 border rounded">hjhjjhhh</div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-md-5 bg-white">uuuuu</div>
//               </div>
//             </section>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default TimeDashboard;
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CommonCard from "../CommonComponent/Card";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ellipse from "../../Assets/Ellipse 1.png";

const TimeDashboard = () => {
  const [department, setDepartment] = useState("");

  const departmentChange = (event) => {
    setDepartment(event.target.value);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <section className="TimeDashboard mx-3">
              <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <h3>Today's Stats</h3>
                <FormControl sx={{ width: "300px" }}>
                  <InputLabel id="department-select-label" className="text-center">
                    Department
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="department-select-label"
                    id="department-select"
                    value={department}
                    label="Department"
                    onChange={departmentChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-between">
                      <CommonCard
                        count={2}
                        description={"Late Arrivals"}
                        icon={<AccessTimeIcon sx={{ color: "#751ED3" }} />}
                        color={"#751ED3"}
                      />
                      <CommonCard
                        count={18}
                        description={"On Time"}
                        icon={<AccessTimeIcon sx={{ color: "#3CB272" }} />}
                        color={"#3CB272"}
                      />
                      <CommonCard
                        count={5}
                        description={"WFH"}
                        icon={<AccessTimeIcon sx={{ color: "#B28220" }} />}
                        color={"#B28220"}
                      />
                      <CommonCard
                        count={3}
                        description={"Remote Clock-Ins"}
                        icon={<AccessTimeIcon sx={{ color: "#D33D4C" }} />}
                        color={"#D33D4C"}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="bg-white rounded p-3">
                      <h3>OverTime Request</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="border rounded px-3 py-3">
                            <div className="d-flex justify-content-between align-items-center ">
                            <div className="d-flex align-items-center">
                              <img src={ellipse} alt="Profile" />
                              <div className="ps-2">
                                <h6 className="m-0">Arun Kumar</h6>
                                <p className="text-grey m-0">Sales Executive</p>
                              </div>
                            </div>
                            <div className="d-flex flex-column ms-3">
                              <button className="btn btn-primary mb-2">Accept</button>
                              <button className="btn btn-secondary">Reject</button>
                            </div>
                          </div>
                          <div>
                          <h5>Date</h5>
                          <h6 className="m-0">5 Aug -6 Aug,2024 </h6>
                          </div>
                          <div>
                          <h5>Reason</h5>
                          <h6 className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </h6>
                          </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="border rounded p-3">
                            {/* Additional content here */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5  rounded p-3">
                  {/* Additional content for the right section */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeDashboard;
