import React, { useState } from "react";
import { NewPasswordSchema } from "../schemas/settingSchema";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";

export default function NewPassword() {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [validationError, setValidationError] = useState("");

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values) => {
      changePassword(values);
    },
  });

  function changePassword(value) {
    let url = apiurl + "changeadminpassword";
    let data = { ...value, user_id };
    axios
      .post(url, data, config)
      .then((response) => {
        resetForm();
        toast.success("Password Changed Successfully");
        setValidationError("");
      })
      .catch((error) => {
        setValidationError(error.response.data.response);
      });
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Change Password</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3 row">
              <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                Current Password
              </label>
              <div className="col-lg-9 col-xl-8">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  name="current_password"
                  value={values.current_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.current_password && errors.current_password ? (
                  <p className="text-danger my-0">{errors.current_password}</p>
                ) : (
                  <p className="text-danger">{validationError}</p>
                )}

                {/* <Link
                                        to="#"
                                        className="text-primary font-12"
                                    >
                                        Forgot password ?
                                    </Link> */}
              </div>
            </div>
            <div className="form-group mb-3 row">
              <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                New Password
              </label>
              <div className="col-lg-9 col-xl-8">
                <input
                  className="form-control"
                  type="password"
                  placeholder="New Password"
                  name="new_password"
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.new_password && errors.new_password ? (
                  <p className="text-danger my-0">{errors.new_password}</p>
                ) : null}
              </div>
            </div>
            <div className="form-group mb-3 row">
              <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
                Confirm Password
              </label>
              <div className="col-lg-9 col-xl-8">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Re-Password"
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.confirm_password && errors.confirm_password ? (
                  <p className="text-danger my-0 ">{errors.confirm_password}</p>
                ) : null}
              </div>
            </div>
            <div className="form-group mb-3 row">
              <div className="col-lg-9 col-xl-8 offset-lg-3">
                <button type="submit" className="btn btn-de-primary">
                  Change Password
                </button>
                <button type="button" className="btn btn-de-danger">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
