import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import VehicleRequestForm from "./VehicleRequestForm";
import { VehicleRequestSchema } from "../../schemas/StoreSchema/VehicleRequestSchema";
const EditVehicleRequest = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const userdetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userdetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const { user } = useSelector((state) => ({ ...state.auth }));
  const navigate = useNavigate();
  const { state } = useLocation();
console.log("vehicle",state)
  const vehicle_requested_by_department =
    user?.response?.userDetails?.department_id;
  const vehicle_requested_by_department_user = user?.response?.userDetails?.id;

  const initialValue = {
    requested_date_for: state?.requested_date_for,
    project_name: state?.project_name,
    po_no: state?.po_no,
    transit_mode: state?.transit_mode,
    material_type: state?.material_type,
    required_vehicle_size: state?.required_vehicle_size,
    material_weight: state?.material_weight,
    loading_address: state?.loading_address,
    la_pin_code: state?.la_pin_code,
    la_contact_name: state?.la_contact_name,
    la_contact_number: state?.la_contact_number,
    delivery_address: state?.delivery_address,
    da_pin_code: state?.da_pin_code,
    da_contact_number: state?.da_contact_number,
    da_contact_name: state?.da_contact_name,
    requestee_signature: state?.requestee_signature,
    approvee_hod_signature: state?.approvee_hod_signature,
    remarks: state?.remarks,
    evidence_and_confirmation: state?.evidence_and_confirmation,
    material_insurance: state?.material_insurance,
    vehicle_hold: state?.vehicle_hold,
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur
  } = useFormik({
    initialValues: initialValue,
    validationSchema: VehicleRequestSchema,
    onSubmit: (values) => {
      updateVehicleRequset(values);
    },
  });

  const updateVehicleRequset = (values) => {
    const data = {
      ...values,
      user_id,
      store_vehicle_request_id: state?.id,
      vehicle_requested_by_department,
      vehicle_requested_by_department_user,
    };
    const url = `${baseUrl}updatevehiclerequest`;
    setSpinner(true)
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false)
        if (res.status === 200) {
          toast.success("Vehicle Request update successfully", {
            autoClose: 2000
          });
          navigate(-1);
        }
      })
      .catch((err) => {
        setSpinner(false)
        toast.error("something went wrong");
        //  setValidationError(err?.response?.data?.response)
      });
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <VehicleRequestForm
                  handleChange={handleChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  mode="edit"
                  setFieldValue={setFieldValue}
                  validationError={validationError}
                  spinner={spinner}
                  state={state}
                
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVehicleRequest;
