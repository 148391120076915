import React from "react";
function CommonOptionsInvoice({
  handleChange,
  values,
  name,
  handleBlur,
  billing,
  disabled,
  readOnly,
}) {
  return (
    <>
      <label htmlFor="" className="form-label">
        Invoice No.
      </label>
      <select
        type="text"
        className="form-control"
        id={name}
        name={name}
        value={values}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder=""
        disabled={disabled || readOnly}
      >
        <option value="" className="text-secondary">
          Select
        </option>
        {billing?.map((element) => (
          <option value={element?.bpd_invoiceNo}>
            {element?.bpd_invoiceNo}
          </option>
        ))}
      </select>
    </>
  );
}

export default CommonOptionsInvoice;
