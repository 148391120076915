import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EmployeeProfile from "./EmployeeProfile";
import JobDetail from "./JobDetail";
import DocumentDetail from "./DocumentDetail";
import TimeDetail from "./TimeDetail";
import EmployeeAssets from "./EmployeeAssets";
import EmployeeFinance from "./EmployeeFinances";
import EmployeeExpense from "./EmployeeExpennse";
import EmployeePerformance from "./EmployeePerformance";
import "../../HrDepartment.scss"
const EmployeeDetailTab = ({value,setValue,data,employeeDetail}) => {


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <>
 

    <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          {/* Tab headers in one row */}
          <div
            className="row">
               
         <div className="employee-Accordian" style={{backgroundColor:"white",width:"97.5%",marginLeft:"0.625rem"}} >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                className="custom-tablist"
                
              >
                <Tab label="Profile" value="Profile" />
                <Tab label="Time" value="Time" />
                <Tab label="Job" value="Job" />
                <Tab label="Documents" value="Documents" />
                <Tab label="Onboarding" value="Onboarding" />
                <Tab label="Assets" value="Assets" />
                <Tab label="Finances" value="Finances" />
                <Tab label="Expenses" value="Expenses" />
                <Tab label="Performance" value="Performance" />
             
              </TabList>
              </div>
              </div>
          
         
          {/* Tab content in another row */}
          <div className="row">
            <div className="accordian acordianfix_d">
            <TabPanel value="Profile">
              <EmployeeProfile data={data}  employeeDetail={employeeDetail}/>
            </TabPanel>
            <TabPanel value="Job"><JobDetail data ={data}/></TabPanel>
            <TabPanel value="Documents"><DocumentDetail data ={data} employeeDetail={employeeDetail} /></TabPanel>
            <TabPanel value="Time"><TimeDetail/></TabPanel> 
            <TabPanel value="Assets" className="paddingLeftRight0">
              <EmployeeAssets/>
            </TabPanel>
            <TabPanel value="Finances" className="paddingLeftRight0">
            <EmployeeFinance/>
            </TabPanel>
            <TabPanel value="Expenses" className="paddingLeftRight0">
            <EmployeeExpense/>
            </TabPanel>
            <TabPanel value="Performance" className="paddingLeftRight0">
            <EmployeePerformance/>
            </TabPanel>
            </div>
          </div>
        </TabContext>
      </Box>

     
    
    </>
  );
};

export default EmployeeDetailTab;
