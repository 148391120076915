import React, { useEffect, useState } from "react";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import {
  addSchedulesDeliveryItems,
  getSchedulesDeliveryItemsList,
} from "../purchaseApi";
import { toast } from "react-toastify";

function DeliveryScheduleTable({ setOpen, id, POnumber }) {
  const [data, setData] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const handleChange = (rowIndex, inputIndex, field, value) => {
    const newValue = value === '' ? '' : value;
    const newData = [...data];
    newData[rowIndex].schedule_data[inputIndex][field] = newValue;
    setData(newData);
    let calcData = calculateTotal(newData, rowIndex);
    const currentTotalQty = parseFloat(calcData[rowIndex]?.totalQty) || 0;
    const allowedQty = parseFloat(calcData[rowIndex]?.qty) || 0;
    if (allowedQty - currentTotalQty < 0) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  };

  const calculateTotal = (newData, inputIndex) => {
    if (!newData[inputIndex]?.hasOwnProperty("totalQty")) {
      return;
    }
    const data = _.cloneDeep(newData);

    let total = 0;
    data[inputIndex]?.schedule_data?.map((input) => {
      total += parseFloat(input.quantity) || 0;
    });
    data[inputIndex].totalQty = total;
    setData(data);
    return data;
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    calculateTotal(data);
  }, [data]);

  const addInputs = (index) => {
    setData((prev) => {
      const newData = _.cloneDeep(prev);
      newData[index].schedule_data = [
        ...newData[index].schedule_data,
        {
          quantity: "",
          schedule_date: "",
          remarks: "",
        },
      ];
      calculateTotal(newData);
      return newData;
    });
  };

  const removeInput = (rowIndex, inputIndex) => {
    setData((prev) => {
      const newData = _.cloneDeep(prev);
      if (newData[rowIndex].schedule_data.length > 1) {
        newData[rowIndex].schedule_data.splice(inputIndex, 1);
      }
      calculateTotal(newData);
      return newData;
    });
  };

  const addSechdule = (data) => {
    setLoading(true);
    addSchedulesDeliveryItems({
      user_id,
      po_id: id,
      items: data,
    })
      .then((res) => {
        toast.success("Delivery Sechdule Succesfully");
        setOpen(false);
      })
      .catch((err) => toast.error("Something went wrong"))
      .finally(() => setLoading(false));
  };

  const listItemDetail = () => {
    getSchedulesDeliveryItemsList({
      user_id,
      po_id: id,
    })
      .then((res) => {
        let newData = res?.data?.response?.itemdetails || [];

        let updatedData = newData?.map((item) => ({
          ...item,
          totalQty: "",
          schedule_data: item?.schedule_data?.length
            ? item?.schedule_data
            : [
                {
                  quantity: "",
                  schedule_date: "",
                  remarks: "",
                },
              ],
        }));

        setData(updatedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong in catch");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    listItemDetail();
  }, []);
  const formSubmit = (e) => {
    e.preventDefault();
    addSechdule(data);
  };

  return (
    <div style={{ margin: "10px", background: "#fff", padding: "1rem" }}>
      <div className="d-flex mb-3">
        <label>
          <p style={{ fontSize: "medium", fontWeight: "600", margin: ".3rem" }}>
            PO.No. -{""}
          </p>{" "}
        </label>
        <input
          className="form-control"
          style={{ width: "10rem" }}
          readOnly
          value={POnumber}
        />
      </div>
      <form onSubmit={formSubmit}>
        <table
          style={{
            background: "#fff",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                ITEM CODE
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                ITEM NAME
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                ORDER QTY
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                BAL.
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                SCHEDULE QTY
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                SCHEDULE DATE
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                REMAKS
              </th>
              <th
                style={{
                  fontSize: "small",
                  fontWeight: "700",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                Add/Remove
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.item_code}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.item_details?.product_name}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.qty}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {row?.balance_qty}
                  </td>
                  <td colSpan={4} style={{ padding: 0 }}>
                    <table style={{ width: "100%" }}>
                      {row?.schedule_data?.map((input, inputIndex) => (
                        <tr key={inputIndex}>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="number"
                              className="w-100 form-control"
                              value={input.quantity}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "quantity",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="date"
                              className="w-100 form-control"
                              value={input.schedule_date}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "schedule_date",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            <input
                              type="text"
                              className="w-100 form-control"
                              value={input.remarks}
                              onChange={(e) =>
                                handleChange(
                                  rowIndex,
                                  inputIndex,
                                  "remarks",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {inputIndex === 0 ? (
                              <button
                                type="button"
                                onClick={() => addInputs(rowIndex)}
                                className="btn btn-danger"
                              >
                                <AddIcon />
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() =>
                                  removeInput(rowIndex, inputIndex)
                                }
                                className="btn btn-danger"
                              >
                                <RemoveOutlinedIcon />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>

        {!loading ? (
          <div style={{ marginTop: "10px" }} className="text-end">
            <button
              className="btn btn-danger  me-4"
              type="button"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              disabled={buttonDisable}
            >
              Save
            </button>
          </div>
        ) : (
          <div className="text-end">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>wait...</p>
          </div>
        )}
      </form>
    </div>
  );
}

export default DeliveryScheduleTable;
