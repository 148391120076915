import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const App = () => {
  const data = {
    labels: ["Sick Leave", "Casual Leave", "Paid Leave"],
    datasets: [
      {
        label: "Leave Types",
        data: [4.5, 7, 3.5],
        backgroundColor: ["#FFD700", "#32CD32", "#FF6347"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      {/* <Typography variant="h6" gutterBottom>
        Consumed Leave Types
      </Typography>
      <Typography variant="h4">15 Leaves</Typography> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box mt={2} style={{height:"100%"}}>
        <Typography variant="h4" className="leaveHeading">15 Leaves</Typography> 
          <Box display="flex" alignItems="center" className="mt-4">
            <Box width={16} height={16} bgcolor="#FFD700" mr={1} />
            <Typography>Sick Leave</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} className="mt-4">
            <Box width={16} height={16} bgcolor="#32CD32" mr={1} />
            <Typography>Casual Leave</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} className="mt-4">
            <Box width={16} height={16} bgcolor="#FF6347" mr={1} />
            <Typography>Paid Leave</Typography>
          </Box>
        </Box>
        <Box width="50%">
          <Doughnut data={data} options={options} />
        </Box>
      </Box>
    </>
  );
};

export default App;
