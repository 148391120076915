import React, { useEffect, useState } from "react";
import kanbanboard from "../../Assets/kanabn-img.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IoCheckmarkSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Kanbanproject.style.css";
import { addKanbanBoardJourney, getKanbandBoardList } from "./kanbanApi";
import { LuPencilLine } from "react-icons/lu";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

function KanbanModal({ kanbanBoardModal, setkanbanBoardModal }) {
  const [updateInput, setUpdateInput] = useState([]);
  const [kanbanDepartmentList, setKanbanDepartmentList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [isChecked, setIsChecked] = useState([]);
  const [dataChecked, setDataChecked] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const projectId = useParams();

  function handleClose() {
    setkanbanBoardModal(false);
  }
  function addDataToServer() {
    setLoader(true);
    addKanbanBoardJourney({
      user_id,
      project_id: "19",
      kb_project_details: dataChecked,
    })
      .then((res) => {
        setToggle(!toggle);
        navigate("/kanbancreateproject", { state: toggle });
        setLoader(false);
      })
      .catch((err) => setLoader(false));
  }

  function listKanbanJourney() {
    getKanbandBoardList({ user_id, project_id: projectId })
      .then((res) => {
        setKanbanDepartmentList(res.data.response.ProjectKanbanBoardList);
        let arrayLength = res?.data?.response?.ProjectKanbanBoardList;
        setUpdateInput(Array.from({ length: arrayLength.length }, () => false));
        let checkedData = arrayLength?.map((element) => element.status);
        setIsChecked(checkedData);
        let trueStatusObjects = arrayLength.filter((item) => item.status === 1);
        setDataChecked(trueStatusObjects);
      })
      .catch((err) => console.log(err));
  }
  function changeInput(i) {
    setUpdateInput((prevState) => {
      const updatedInput = [...prevState];
      updatedInput[i] = !updatedInput[i];
      return updatedInput;
    });
  }
  const handleCheckboxChange = (event, index, element) => {
    setIsChecked((prevState) => {
      const updatedChecked = [...prevState];
      updatedChecked[index] = event.target.checked;
      return updatedChecked;
    });
    setDataChecked((prevState) => {
      let data = [...prevState];
      if (event.target.checked) {
        data.push({ ...element, journey_name: element.journey_name });
      } else {
        const indexToUpdate = data.findIndex(
          (item) => item.department_id === element.department_id
        );
        data.splice(indexToUpdate, 1);
      }
      return data;
    });
  };

  useEffect(() => {
    listKanbanJourney();
  }, []);
  return (
    <div>
      <Modal
        show={kanbanBoardModal}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        {/* Modal content */}
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Select</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "auto", maxHeight: "45rem" }}>
          {kanbanDepartmentList?.map((element, index) => {
            return !updateInput[index] ? (
              <div
                key={element.department_id}
                style={{
                  background: "#FFF5F5",
                  border: "1px solid #8B909A",
                  padding: "0.5rem",
                  borderRadius: "0.3rem",
                }}
                className="mb-3"
              >
                <p className="mb-0 d-flex" style={{ color: "red" }}>
                  <span className="mx-2">
                    <div className="custom-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        checked={isChecked[index]}
                        onChange={(e) =>
                          handleCheckboxChange(e, index, element)
                        }
                      />
                    </div>
                  </span>
                  {element.journey_name}
                  {/* <span className="pencil_icon text-danger ms-auto">
                    <LuPencilLine onClick={() => changeInput(index)} />
                  </span> */}
                </p>
              </div>
            ) : (
              <div
                key={element.department_id}
                style={{
                  // background: "#FFF5F5",
                  border: "1px solid #8B909A",
                  padding: "0.5rem",
                  borderRadius: "0.3rem",
                }}
                className="mb-3"
              >
                <div className="d-flex justify-content-between">
                  <input
                    name=""
                    style={{
                      border: "1px solid white",
                      width: "20rem",
                      height: "2rem",
                    }}
                    value={"Ritik"}
                  />
                  <div className="col-md-2">
                    <div>
                      <button
                        style={{
                          background: "#D03438",
                          color: "white",
                          border: "1px solid #D03438",
                          borderRadius: "0.2rem",
                        }}
                      >
                        <IoCheckmarkSharp />
                      </button>
                      <button
                        className="ms-2"
                        style={{
                          background: "#E2E2E2",
                          color: "black",
                          border: "1px solid #E2E2E2",
                          borderRadius: "0.2rem",
                        }}
                      >
                        <RxCross2 />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <div className="m-3 mb-3">
          <Button
            variant="primary"
            className="w-100"
            onClick={() => addDataToServer()}
          >
            {/* <Stack
              sx={{
                color: "white",
                height: "20px",
                width: "20px",
              }}
              spacing={2}
              direction="row"
            >
              <CircularProgress color="inherit" />
            </Stack> */}
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
}
export default KanbanModal;
