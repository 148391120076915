import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { toast } from "react-toastify";
import {
  getProjects,
  getAdminDropDown,
  getUserDropdown,
} from "../../redux/api";
import Select from "react-select";
import { useFormik } from "formik";
import { EditdepartSchema } from "../../schemas/Index";

// import ".././Style.css";

function EditDepartment() {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  // let desc = <div dangerouslySetInnerHTML={{ __html: editDepartmentData?.description }} />;
  const [projects, setProjects] = useState([]);
  const [departHead, setDepartHead] = useState([]);
  const [departmentImage, setDepartmentImage] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [departmentError, setDepartmentError] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const param = useParams();
  const departmentId = param.id;

  const initialValues = {
    name: "",
    email: "",
    image: null,
    department_head: "",
    description: "",
    department_project: [],
  };

  const {
    touched,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditdepartSchema,
    onSubmit: (values) => {
      updateDepartment(values);
    },
  });

  function getAllDropdownList() {
    getUserDropdown({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setDepartHead(res?.data?.response?.userList);
      })
      .catch((err) => {
      });

    getProjects({ user_id: user_id, accessType: "view" })
      .then((res) => {
        setProjects(res?.data?.response?.projectSelect);
      })
      .catch((err) => {
      });
  }

  const handleImageChange = (e) => {
    const data = new FileReader();
    data.addEventListener("load", () => {
      setDepartmentImage(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  };

  let departmentHeadId = values.department_head?.id;
  let departmentProjectId = values?.department_project.map((e) => e.id);

  const updateDepartment = (value) => {
    setSpinner(true);
    const apiurl = baseUrl + "updatedepartment";
    const data = {
      ...value,
      department_head: departmentHeadId,
      department_project: departmentProjectId,
      image: departmentImage ? departmentImage : editImage,
      departmentId,
      user_id,
      accessType: "edit",
    };

    axios
      .post(apiurl, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          navigate("/department");
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        setDepartmentError(error?.response?.data?.response);
      });
  };

  function getEditAbleDepartment() {
    const url = baseUrl + "geteditdepartmentdata";
    const editData = {
      user_id: user_id,
      departmentId,
      accessType: "edit",
    };
    axios
      .post(url, editData, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((res) => {
        let departHead =
          res?.data?.response?.editDepartmentData?.departmentHead;
        let departProject =
          res?.data?.response?.editDepartmentData?.departmentProjects;
        setEditImage(
          imageBaseUrl + res?.data?.response?.editDepartmentData?.image
        );
        setValues({
          ...res?.data?.response?.editDepartmentData,
          department_head: departHead && departHead,
          department_project: departProject,
        });
      })
      .catch((error) => {
        
      });
  }

  function departHeadChange(v) {
    values.department_head = v;
    setFieldValue(v.id);
  }

  function departProjectChange(v) {
    values.department_project = v;
    setFieldValue(v.map((e) => e.id));
  }

  useEffect(() => {
    getAllDropdownList();
    getEditAbleDepartment();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/department">Department /</Link>
                      </li>
                      <li className="breadcrumb-item">Edit Department</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Department</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} className=" department-form">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                              className="form-control"
                              placeholder="Enter Department Name"
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.name && errors.name
                                ? errors.name
                                : departmentError?.name?.[0]}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              value={values.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              autoComplete="off"
                              placeholder="Ex: abc@abc.com"
                              className="form-control"
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.email && errors.email
                                ? errors.email
                                : departmentError?.email?.[0]}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="department_head"
                              className="form-label"
                            >
                              Department Head
                            </label>
                            <Select
                              placeholder="Search Department head"
                              name="department_head"
                              onBlur={handleBlur}
                              options={departHead}
                              getOptionLabel={(departHead) =>
                                departHead["name"]
                              }
                              getOptionValue={(departHead) => departHead["id"]}
                              value={values.department_head}
                              onChange={(value) => departHeadChange(value)}
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.department_head && errors.department_head
                                ? errors.department_head
                                : departmentError?.department_head?.[0]}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group mb-3">
                            <label className="form-label">Image</label>
                            <input
                              type="file"
                              className="form-control"
                              id="image"
                              onBlur={handleBlur}
                              name="image"
                              onChange={handleImageChange}
                              hidden
                            />
                            <label
                              className="d-block form-control"
                              for="image"
                              style={{ border: "1px solid lightGray" }}
                            >
                              {departmentImage
                                ? departmentImage.slice(0, 40)
                                : "Department Image"}
                            </label>
                            <p className="text-danger mb-0 mt-2">
                              {touched.image && errors.image
                                ? errors.image
                                : departmentError?.image?.[0]}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group mb-3">
                            <img
                              src={
                                departmentImage ? departmentImage : editImage
                              }
                              style={{
                                width: "70px",
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-10">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="department_project"
                              className="form-label"
                            >
                              Department Projects
                            </label>
                            <Select
                              placeholder="Search Project"
                              isMulti
                              name="deaprtment_project"
                              onBlur={handleBlur}
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  zIndex: "1000",
                                }),
                              }}
                              options={projects}
                              getOptionLabel={(projects) =>
                                projects["project_title"]
                              }
                              getOptionValue={(projects) => projects["id"]}
                              value={values.department_project.map((e) => e)}
                              onChange={(value) => departProjectChange(value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-10">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="description"
                              className="form-label d-block"
                            >
                              Description
                            </label>
                            <textarea
                              name="description"
                              className="form-control"
                              value={values.description}
                              placeholder="Description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="text-danger mb-0 mt-2">
                              {touched.description && errors.description
                                ? errors.description
                                : departmentError?.description?.[0]}
                            </p>
                          </div>
                        </div>
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Updating Department...</p>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-3 mt-3"
                            onClick={handleSubmit}
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            className="btn btn-de-danger btn-sm me-3 mt-3 border-danger"
                            onClick={() => navigate("/department")}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditDepartment;
