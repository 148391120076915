import React from "react";

function AddStockValue({ values, handleChange, errors, touched }) {
  return (
    <div>
      <div className="row">
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Monthly Consumption{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Monthly Consumption"
            onChange={handleChange}
            value={values.monthly_consumption}
            name="monthly_consumption"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Re order level{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Re order level"
            onChange={handleChange}
            value={values.reorder_level}
            name="reorder_level"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Minimum Stock{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Minimum Stock"
            onChange={handleChange}
            value={values.minimum_stock}
            name="minimum_stock"
          />
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Maximum Stock{""}
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Maximum Stock"
            onChange={handleChange}
            value={values.maximum_stock}
            name="maximum_stock"
          />
        </div>
      </div>
    </div>
  );
}

export default AddStockValue;
