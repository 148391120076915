import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsMessage } from "../../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import Select from "react-select";
import CoachProduction from "./CoachProduction";
import { getProjectCoachList, getProjectLotList } from "../../../../redux/api";

const EditCoachProduction = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const allDropdowns = useSelector(selectDropdowns);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [projectLotDropdown, setProjectLotDropdown] = useState([]);
  const [lastDateToAttend, setLastDateToAttend] = useState("");
  const { state } = useLocation();

  const initialValue = {
    project_id: state?.project_id,
    furnishingNo: state?.furnishingNo,
    coachNo: state?.coachNo,
    coachAllottedTo: state?.coachAllottedTo,
    allotmentDate: state?.allotmentDate,
    completionDate_Furnishing: state?.completionDate_Furnishing,
    completionDate_Electrical: state?.completionDate_Electrical,
    dispatchDate: state?.dispatchDate,
    coachWarrantyPeriod_IR_GCC: state?.coachWarrantyPeriod_IR_GCC,
    UOM: state?.UOM,
    warrentyExpiryDate: state?.warrentyExpiryDate,
    lotId: state?.lotId,
    puPeriod: state?.puPeriod,
    puWarrantyDate: state?.puWarrantyDate,
    AllotmentCertification: "",
    CompletionCertification: "",
  };

  function UpdateCoachProductionData(values) {
    console.log("values", values);
    const apiUrl = baseUrl + "updateprojectwisecoachfurnished";
    const data = {
      user_id,
      ...values,
      furnishedCoachId: state?.id,
    };
    setSpinner(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Coach production update successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        setValidationError(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  function getProjectDropDown() {
    getProjectCoachList({ user_id })
      .then((res) => {
        setProjectDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function getLotDropDown() {
    getProjectLotList({ user_id, project_id: values.project_id })
      .then((res) => {
        setProjectLotDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // Perform any necessary clean-up or finalization tasks here
        // For example, hiding loading indicators
        // setLoading(false);
      });
  }

  useEffect(() => {
    getProjectDropDown();
  }, []);

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,

    onSubmit: (values) => {
      UpdateCoachProductionData(values);
    },
  });
  useEffect(() => {
    if (values.project_id) {
      getLotDropDown();
    }
  }, [values.project_id]);

  return (
    <div>
      <CoachProduction
        handleChange={handleChange}
        values={values}
        handleSubmit={handleSubmit}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        projectDropdown={projectDropdown}
        projectLotDropdown={projectLotDropdown}
        validationError={validationError}
        allDropdowns={allDropdowns}
        spinner={spinner}
        mode="edit"
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default EditCoachProduction;
