import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { boardGroupSchema } from "../schemas/settingSchema";
import { Formik, useFormik } from "formik";

function AddGroupStatusModal({ show, handleClose, editData, getStatusList }) {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [validationError, setValidationError] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [name, setName] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [statusId, setStatusId] = useState(null);

  function getGroupList() {
    let url = baseUrl + "getboarddropdown";
    let data = { user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setGroupList(response?.data?.response.groupDropDown);
      })
      .catch((error) => {

      });
  }

  const initialValues = {
    name: "",
    board_group_id: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: boardGroupSchema,
    onSubmit: (values) => {
      if (editData) {
        updateStatus(values);
      } else {
        addStatus(values);
      }
    },
  });

  function addStatus(value) {
    let url = baseUrl + "storeboardgroupstatus";
    let data = { user_id, ...value };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
     
        handleClose();
        getStatusList();
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response.name);
        }
      });
  }

  function updateStatus(value) {
    let url = baseUrl + "updateboardgroupstatus";
    let data = { user_id, ...value, board_group_status_id: statusId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        handleClose();
        getStatusList();
      })
      .catch((error) => {
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response.name);
        }
      });
  }

  useEffect(() => {
    resetForm();
    if (editData) {
      setValues(editData);
      setStatusId(editData.id);
    }
    getGroupList();
  }, [editData, show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Group Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-3">
                <label className="form-label">Board Group</label>
                <select
                  className="form-control border-secondary"
                  placeholder="Enter Board Group"
                  name="board_group_id"
                  value={values.board_group_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled selected hidden>
                    Select Group
                  </option>
                  {groupList.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                <p className="text-danger p-0 m-0">
                  {errors.board_group_id && touched.board_group_id
                    ? errors.board_group_id
                    : validationError?.board_group_id}
                </p>
              </div>

              <div className="col-12 mb-3">
                <label className="form-label">Group Status</label>
                <input
                  className="form-control border-secondary"
                  placeholder="Enter Group Status"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                <p className="text-danger p-0 m-0">
                  {errors.name && touched.name
                    ? errors.name
                    : validationError?.name}
                </p>
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  {editData ? (
                    <Button type="submit" variant="primary">
                      Update Status
                    </Button>
                  ) : (
                    <Button type="submit" variant="primary">
                      Add Status
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddGroupStatusModal;
