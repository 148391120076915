import React, { useState, useEffect } from "react";
import { employeeProfile } from "../../hrApi";

import ProfilePic from "../../../Assets/ProfilePic.png";

import EmployeeDetailTab from "./EmployeeDetailTab";
import arrow from "../../../Assets/arrow.png";

import "../../HrDepartment.scss";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Exit from "../../MaterialUiComponent/InitiateExit";
import InitiateExitModal from "./InitiateExitModal";
const EmployeeDetail = () => {
  const [value, setValue] = useState("Profile");
  const [initiateExitModal, setInitiateExitModal] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const closeExitModal =()=>{
    setInitiateExitModal(false)
  }

  const employeeDetail = async () => {
    setLoader(true);
    const reqpayload = { employee_id: id };
    try {
      setLoader(false);
      const response = await employeeProfile(reqpayload);
      setEmployeeData(response?.data?.response?.data?.profileData);
    } catch (error) {
      setLoader(false);
      toast.error("Please try again later ,Something went wrong", {
        autoClose: 2000,
      });
    }
  };
  console.log("profiledatatttt", employeeData);
  useEffect(() => {
    employeeDetail();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-8 ">
                {loader ? (
                  <div className="d-flex align-item-center justify-content-center">
                    <i className="fa fa-spinner fa-spin fa-3x text-danger  "></i>
                    <p>Wait...</p>
                  </div>
                ) : (
                  <div className="mx-1">
                    <div className="card emp_detail">
                      {/* <div className="card"> */}

                      <div className="row">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="detail-profile-image m-3">
                                <img className="image1" src={ProfilePic} />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="border-end pt-1 my-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <h4 className="heading">
                                    {employeeData?.employee_name
                                      ? employeeData?.employee_name
                                      : "--"}{" "}
                                  </h4>
                                  <span className="ms-5 me-3 status">IN</span>
                                </div>

                                <p className="designation">
                                  {employeeData?.job_title
                                    ? employeeData?.job_title
                                    : "--"}
                                </p>
                                <div className="row">
                                  <div className="col-md-5 pt-1">
                                    <p className="card-right-heading">
                                      Mobile:{" "}
                                    </p>

                                    <p className="card-right-heading">
                                      Email:{" "}
                                    </p>
                                    <p className="card-right-heading">
                                      Location:
                                    </p>
                                  </div>
                                  <div className="col-md-7 pt-0">
                                    <p className="card-right-value">
                                      9864346788
                                    </p>
                                    <p className="card-right-value">
                                      {employeeData?.personal_email
                                        ? employeeData?.personal_email
                                        : "--"}
                                    </p>
                                    <p className="card-right-value">
                                      {employeeData?.location
                                        ? employeeData?.location
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 ">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "1rem 1rem 0rem 0rem",
                            }}
                          >
                            <div
                              className="dropdown-toggle ms-3"
                              // data-bs-toggle="dropdown"
                              aria-expanded="false"
                              // style={{marginLeft:"12px"}}
                            >
                              <Exit
                                setInitiateExitModal={setInitiateExitModal}
                              />
                            </div>
                          </div>
                          <div className="mx-1">
                            <div className="row">
                              <div className="col-md-6 ">
                                <p className="card-right-heading padding_leftto20px">
                                  EmployeeId:
                                </p>
                                <p className="card-right-heading padding_leftto20px">
                                  Experience:
                                </p>
                                <p className="card-right-heading padding_leftto20px">
                                  Date Of Joining:
                                </p>
                                <p className="card-right-heading padding_leftto20px">
                                  Departments:
                                </p>
                                <p className="card-right-heading padding_leftto20px">
                                  Reporting to:
                                </p>
                              </div>
                              <div className="col-md-6 pt-0">
                                <p className="card-right-value">
                                  {employeeData?.id ? employeeData?.id : "--"}
                                </p>
                                <p className="card-right-value">4year</p>
                                <p className="card-right-value">
                                  {employeeData?.join_date
                                    ? employeeData?.join_date
                                    : "--"}
                                </p>
                                <p className="card-right-value">Accounts</p>

                                <p
                                  className="card-right-value"
                                  // style={{ paddingLeft: ".3rem" }}
                                >
                                  <span
                                    className="abbreviation"
                                    // style={{ marginTop: "0px", marginLeft: "18px" }}
                                  >
                                    VB
                                  </span>
                                  Vijay Bansal
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <EmployeeDetailTab
                      value={value}
                      setValue={setValue}
                      data={employeeData}
                      employeeDetail={employeeDetail}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-12 col-lg-4 ">
                <div className="row">
                  <div className="main-ongoing ">
                    <div className="card ">
                      <h5 className="ongoing-heading pt-2 ps-2">
                        {" "}
                        Ongoing Projects
                      </h5>
                      <hr></hr>
                      <div className="ongoing-Project p-2 m-4 mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="project-name p-0"> Coach Flooring</h5>
                          <p className="date">
                            Due Date: <span>10.06.2023</span>
                          </p>
                        </div>

                        <p className="project-content">
                          Better access to customer information · Faster
                          response times · Improved on-time delivery · Improved
                          order accuracy.
                        </p>
                        <div className="row">
                          <div className="col-md-4">
                            <p className="stage ">STAGE</p>
                            <h4
                              className="inspection p-0"
                              style={{ fontWeight: "500" }}
                            >
                              Inspection
                              <span>
                                {" "}
                                <img src={arrow} />
                              </span>
                            </h4>
                          </div>
                          <div className="col-md-4 text-start">
                            <p className="stage"> SUB STAGE</p>
                            <h4 className="inspection">Inspection</h4>
                          </div>
                          <div className="col-md-4 pt-4 text-end">
                            <p className="stage">
                              Assigned By{" "}
                              <span className="abbreviation">VB</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ongoing-Project p-2 m-4 mt-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="project-name p-0"> Coach Flooring</h5>
                          <p className="date">
                            Due Date: <span>10.06.2023</span>
                          </p>
                        </div>

                        <p className="project-content">
                          Better access to customer information · Faster
                          response times · Improved on-time delivery · Improved
                          order accuracy.
                        </p>
                        <div className="row">
                          <div className="col-md-4">
                            <p className="stage ">STAGE</p>
                            <h4
                              className="inspection "
                              style={{ fontWeight: "500" }}
                            >
                              Inspection
                              <span>
                                {" "}
                                <img src={arrow} />
                              </span>
                            </h4>
                          </div>
                          <div className="col-md-4 text-start">
                            <p className="stage"> SUB STAGE</p>
                            <h4 className="inspection">Inspection</h4>
                          </div>
                          <div className="col-md-4 pt-4 text-end">
                            <p className="stage">
                              ASSIGNED BY{" "}
                              <span className="abbreviation">VB</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {value == "Profile" ? (
                  <div className="row">
                    <div className="main-experience">
                      <div className="card">
                        <h5 className="ongoing-heading pt-2 ps-2">
                          {" "}
                          Experience
                        </h5>
                        <div
                          style={{ borderBottom: "1px solid #E1E1E1" }}
                        ></div>
                        <div>
                          <div class="order-track my-3">
                            <div class="order-track-step mx-2 ">
                              <div class="order-track-status">
                                <span class="order-track-status-dot"></span>
                                <span class="order-track-status-line"></span>
                              </div>
                              <div>
                                <p
                                  className="accordian_abbreviation_circle"
                                  style={{ marginLeft: "-14px" }}
                                >
                                  PS
                                </p>
                              </div>
                              <div class="order-track-text mt-2 mx-2">
                                <p class="order-track-text-stat">
                                  Pixel Media{" "}
                                  <span
                                    className="order-track-text-stat"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {" "}
                                    (Junior Account Manager)
                                  </span>
                                </p>
                                <span class="order-track-text-sub">
                                  Oct 2021 - Jul 2023 | Noida Sector 135
                                </span>
                              </div>
                            </div>
                            <div class="order-track-step mx-2">
                              <div class="order-track-status">
                                <span class="order-track-status-dot"></span>
                                <span class="order-track-status-line"></span>
                              </div>
                              <div>
                                <p
                                  className="accordian_abbreviation_circle"
                                  // style={{ marginLeft: "-14px" }}
                                >
                                  PS
                                </p>
                              </div>
                              <div class="order-track-text mt-2 mx-2">
                                <p class="order-track-text-stat">
                                  Pixel Media{" "}
                                  <span
                                    className="order-track-text-stat"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {" "}
                                    (Junior Account Manager)
                                  </span>
                                </p>
                                <span class="order-track-text-sub">
                                  Oct 2021 - Jul 2023 | Noida Sector 135
                                </span>
                              </div>
                            </div>
                            <div class="order-track-step mx-2">
                              <div class="order-track-status">
                                <span class="order-track-status-dot"></span>
                                <span class="order-track-status-line"></span>
                              </div>
                              <div>
                                <p
                                  className="accordian_abbreviation_circle"
                                  // style={{ marginLeft: "-14px",fontSize:"10px" }}
                                >
                                  PS
                                </p>
                              </div>
                              <div class="order-track-text mt-2 mx-2">
                                <p class="order-track-text-stat">
                                  Pixel Media{" "}
                                  <span
                                    className="order-track-text-stat"
                                    style={{ fontWeight: "400" }}
                                  >
                                    {" "}
                                    (Junior Account Manager)
                                  </span>
                                </p>
                                <span class="order-track-text-sub">
                                  Oct 2021 - Jul 2023 | Noida Sector 135
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <InitiateExitModal open={initiateExitModal } handleClose={closeExitModal}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDetail;
