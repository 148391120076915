import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { errorsMessage } from "../../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import CoachProduction from "./CoachProduction";
import { getProjectCoachList, getProjectLotList } from "../../../../redux/api";
import coachProductionSchema from "./CoachProductionSchema";

const AddCoachProduction = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();
  const allDropdowns = useSelector(selectDropdowns);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [projectLotDropdown, setProjectLotDropdown] = useState([]);
  const params = useParams();
  const initialValue = {
    furnishingNo: "",
    coachNo: "",
    coachAllottedTo: "",
    allotmentDate: "",
    completionDate_Furnishing: "",
    completionDate_Electrical: "",
    dispatchDate: "",
    UOM: "",
    coachWarrantyPeriod_IR_GCC: "",
    warrentyExpiryDate: "",
    puPeriod: "",
    puWarrantyDate: "",
    lotId: "",
    AllotmentCertification: "",
    CompletionCertification: "",
  };

  function AddCoachProductionData(values) {
    const apiUrl = baseUrl + "storeprojectwisecoachfurnished";
    const data = {
      user_id,
      ...values,
      project_id: params.id,
    };
    setSpinner(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          resetForm();
          toast.success("Form submit successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        setValidationError(error?.response?.data?.response);
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  function getProjectDropDown() {
    getProjectCoachList({ user_id })
      .then((res) => {
        setProjectDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function getLotDropDown() {
    getProjectLotList({ user_id, project_id: params.id })
      .then((res) => {
        // console.log("values",res?.data?.response?.data);
        setProjectLotDropdown(res?.data?.response?.data);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        // Perform any necessary clean-up or finalization tasks here
        // For example, hiding loading indicators
        // setLoading(false);
      });
  }

  useEffect(() => {
    getProjectDropDown();
  }, []);

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: coachProductionSchema,
    onSubmit: (values) => {
      AddCoachProductionData(values);
    },
  });
  useEffect(() => {
    if (params.id) {
      getLotDropDown();
    }
  }, [params.id]);

  return (
    <div>
      <CoachProduction
        handleChange={handleChange}
        values={values}
        handleSubmit={handleSubmit}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        projectDropdown={projectDropdown}
        projectLotDropdown={projectLotDropdown}
        validationError={validationError}
        allDropdowns={allDropdowns}
        spinner={spinner}
        mode="add"
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default AddCoachProduction;
