import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import { selectDropdowns } from "../../../redux/features/allDropdownSlice";
import { useSelector } from "react-redux";
import { getboardgroupstatus } from "../../../redux/api";
import { toast } from "react-toastify";
import { tenderTicketSchema } from "../../TenderSchema/tenderSchema";
import TenderTicketComment from "../TenderTicketComment";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import "../../TenderStyle/TenderStyle.css";
import { errorsMessage } from "../../../constant";

export default function EditTenderTicket({
  editTicketModal,
  getKanbanTicket,
  handleClose,
  ticketId,
  boardGroup,
}) {
  const [validationError, setValidationError] = useState({});
  const [spinner, SetSpinner] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState("");
  const [minStartDate, setMinStartDate] = useState("");
  const [groupStatus, setGroupStatus] = useState([]);
  const allDropdowns = useSelector(selectDropdowns);
  const me = "<<me>>";
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const departmentUsers = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender"
  );
  const userList = departmentUsers?.filter((user) => user.id !== user_id);

  const reportingPersonList = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender" || user.id === 5
  );

  const tenderList = allDropdowns?.tenderList;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [activeAccordionKey, setActiveAccordionKey] = useState("");

  const changeKey = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };

  let initialValues = {
    tender_id: "",
    board_group_id: "",
    title: "",
    description: "",
    priority: "",
    estimated_time: "1",
    reporting_to: "",
    start_date: "",
    due_date: "",
    assignee: "",
    board_group_status_id: "",
    ticket_type: 8,
    required_qty: 8,
    ticket_attachement: "",
    completedQuantity: "9",
    due_time: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: tenderTicketSchema,
    onSubmit: (values) => {
      submitTicket(values);
    },
  });

  async function getEditData() {
    if (ticketId) {
      const url = baseUrl + "getedittenderticketdata";
      const config = {
        headers: {
          Authorization: bearer_token,
        },
      };
      const data = {
        user_id: user_id,
        ticketId: ticketId,
      };
      await axios
        .post(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            let allData = res?.data?.response?.TenderticketData;
            let startDate = new Date(
              res?.data?.response?.TenderticketData?.start_date
            );
            let endDate = new Date(
              res?.data?.response?.TenderticketData?.due_date
            );
            let data = {
              ...allData,
              start_date: startDate,
              due_date: endDate,
            };

            setValues(data);
          }
        })
        .catch((error) => {
          setSpinner(false);
        });
    }
  }

  function getGroupStatusDropdown() {
    if (values.board_group_id) {
      getboardgroupstatus({
        user_id: user_id,
        board_group_id: values.board_group_id,
      })
        .then((response) => {
          setGroupStatus(response?.data?.response?.statusDropDown);
        })
        .catch((err) => {});
    }
  }
  function boardGrouphandleChange(e) {
    const { name, value } = e.target;
    setFieldValue("board_group_status_id", "");
    setFieldValue([name], value);
  }

  function submitTicket(value) {
    let startDate = values.start_date
      ? `${values.start_date.getFullYear()}-${
          values.start_date.getMonth() + 1
        }-${values.start_date.getDate()}`
      : "";
    let dueDate = values.due_date
      ? `${values.due_date.getFullYear()}-${
          values.due_date.getMonth() + 1
        }-${values.due_date.getDate()}`
      : "";

    SetSpinner(true);

    let url = baseUrl + "updatetenderticket";
    let data = {
      ...value,
      user_id,
      start_date: startDate,
      due_date: dueDate,
      ticketId,
      completed_qty: "9",
    };
    axios
      .post(url, data, config)
      .then((response) => {
        getKanbanTicket();
        toast.success("Ticket Updated Successfully");
        SetSpinner(false);
      })
      .catch((error) => {
        if (error?.response?.status === 422) {
          setValidationError(error.response.message);
        }
        errorsMessage(error?.response?.status);
      });
  }

  function getDate(id) {
    let tender = tenderList.find((e) => e.id === +id);
    setMaxEndDate(tender.closingDate);
    setMinStartDate(tender.publishedDate);
  }

  useEffect(() => {
    if (values.board_group_id) {
      getGroupStatusDropdown();
    }
  }, [editTicketModal, values.board_group_id]);
  useEffect(() => {
    if (values.tender_id) {
      let tender = tenderList.find((e) => e.id === values.tender_id);
      setMaxEndDate(tender.closingDate);
      setMinStartDate(tender.publishedDate);
    }
  }, [tenderList]);

  useEffect(() => {
    resetForm();
    getEditData();
  }, [editTicketModal]);
  return (
    <>
      <Modal show={editTicketModal} onHide={() => handleClose()} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="m-0">CREATE TICKET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: "35rem",
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="row tender-ticket">
                <div
                  className="col-md-7"
                  style={{ maxHeight: "600px", overflowY: "scroll" }}
                >
                  <div className="row">
                    <div className="col-md-6 react-select">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          Tender<span className="text-danger">*</span>
                        </label>
                        <Select
                          placeholder="Search Tender Title"
                          name="tender_id"
                          onBlur={handleBlur}
                          options={tenderList}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.tenderNo}
                          selectedOption={values.tender_id}
                          value={tenderList?.find(
                            (element) => element.id === values.tender_id
                          )}
                          onChange={(value) => {
                            getDate(value.id);
                            setFieldValue("tender_id", value.id);
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                              ...theme.colors,
                              primary25: "lightgrey",
                              primary: "lightgrey",
                            },
                          })}
                        />

                        {touched.tender_id && errors.tender_id ? (
                          <p className="text-danger m-0">{errors.tender_id}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.tender_id}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <div className="form-group ">
                        <label className="form-label">
                          Ticket Title<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          className="form-control border-secondary"
                          placeholder="Enter Ticket"
                          onBlur={handleBlur}
                          value={values.title}
                          onChange={handleChange}
                        />
                        {touched.title && errors.title ? (
                          <p className="text-danger m-0">{errors.title}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.title}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Board Group<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select border-secondary"
                        name="board_group_id"
                        onBlur={handleBlur}
                        value={values.board_group_id}
                        onChange={boardGrouphandleChange}
                      >
                        <option value="">Select Board Group</option>
                        {boardGroup?.map((element, index) => {
                          return (
                            <option key={index} value={Number(element.id)}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                      {touched.board_group_id && errors.board_group_id ? (
                        <p className="text-danger m-0">
                          {errors.board_group_id}
                        </p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.board_group_id}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Board Group Status<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select border-secondary"
                        name="board_group_status_id"
                        onBlur={handleBlur}
                        value={values.board_group_status_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Group Status</option>
                        {groupStatus?.map((element, index) => {
                          return (
                            <option key={index} value={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </select>
                      {touched.board_group_status_id &&
                      errors.board_group_status_id ? (
                        <p className="text-danger m-0">
                          {errors.board_group_status_id}
                        </p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.board_group_status_id}
                        </p>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        Description<span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control border-secondary"
                        value={values.description}
                        name="description"
                        placeholder="Enter Ticket Description"
                        rows={4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.description && errors.description ? (
                        <p className="text-danger m-0">{errors.description}</p>
                      ) : (
                        <p className="text-danger m-0">
                          {validationError?.description}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-5 bg-light py-2">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex align-items-sm-center">
                        <label htmlFor="Sprint" className="form-label w-50">
                          Reporting To<span className="text-danger">*</span>
                        </label>
                        <div className="w-100 d-block">
                          <select
                            className="form-select border-secondary"
                            id=""
                            name="reporting_to"
                            onBlur={handleBlur}
                            value={values.reporting_to}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected hidden>
                              Select a Reporting Person
                            </option>
                            {reportingPersonList?.map((element) => {
                              return (
                                <option key={element.id} value={element.id}>
                                  {element.name}
                                </option>
                              );
                            })}
                          </select>
                          {touched.reporting_to && errors.reporting_to ? (
                            <p className="text-danger m-0">
                              {errors.reporting_to}
                            </p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.reporting_to}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex align-items-sm-center">
                        <label htmlFor="Sprint" className="form-label w-50">
                          Asignee<span className="text-danger">*</span>
                        </label>
                        <div className="w-100 d-block">
                          <select
                            className="form-select border-secondary"
                            id=""
                            name="assignee"
                            onBlur={handleBlur}
                            value={values.assignee}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected hidden>
                              Select a Assignee
                            </option>
                            <option value={user_id}>{me}</option>
                            {departmentUsers?.map((element) => {
                              return (
                                <option key={element.id} value={element.id}>
                                  {element.name}
                                </option>
                              );
                            })}
                          </select>
                          {touched.assignee && errors.assignee ? (
                            <p className="text-danger m-0">{errors.assignee}</p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.assignee}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex align-items-sm-center">
                        <label htmlFor="StartDate" className="form-label w-50">
                          Start Date<span className="text-danger">*</span>
                        </label>
                        <div className="w-100 d-block">
                          <DatePicker
                            type="date"
                            placeholderText={new Date().toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                            name="start_date"
                            className="form-control border-secondary w-100" 
                            onBlur={handleBlur}
                            selected={values.start_date}
                            onChange={(date) =>
                              setFieldValue("start_date", date)
                            }
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            startDate={values.start_date}
                            endDate={values.due_date}
                            minDate={new Date(minStartDate)}
                            maxDate={new Date(maxEndDate)}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          {touched.start_date && errors.start_date ? (
                            <p className="text-danger m-0">
                              {errors.start_date}
                            </p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.start_date}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex align-items-sm-center">
                        <label
                          htmlFor="CompletionDate"
                          className="form-label w-50"
                        >
                          Due Date<span className="text-danger">*</span>
                        </label>

                        <div className="w-100 d-block">
                          <DatePicker
                            type="date"
                            className="form-control border-secondary w-100"
                            name="due_date" 
                            placeholderText={new Date().toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                            onBlur={handleBlur}
                            selected={values.due_date}
                            onChange={(date) => setFieldValue("due_date", date)}
                            selectsEnd
                            startDate={values.start_date}
                            endDate={values.due_date}
                            minDate={values.start_date}
                            maxDate={new Date(maxEndDate)}
                            dateFormat="dd/MM/yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          {touched.due_date && errors.due_date ? (
                            <p className="text-danger m-0">{errors.due_date}</p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.due_date}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex align-items-sm-center">
                        <label
                          htmlFor="CompletionDate"
                          className="form-label w-50"
                        >
                          Due Time<span className="text-danger">*</span>
                        </label>

                        <input
                          className="form-control border-secondary"
                          type="time"
                          min="00:00"
                          max="23:59"
                          name="due_time"
                          value={values.due_time}
                          onChange={handleChange}
                        ></input>
                        {touched.due_time && errors.due_time ? (
                          <p className="text-danger m-0">{errors.due_time}</p>
                        ) : (
                          <p className="text-danger m-0">
                            {validationError?.due_time}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="form-group d-flex">
                        <label htmlFor="Sprint" className="form-label w-50">
                          Priority<span className="text-danger">*</span>
                        </label>
                        <div className="w-100 d-block">
                          <select
                            className="form-select border-secondary "
                            name="priority"
                            onBlur={handleBlur}
                            value={values.priority}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected hidden>
                              Select Ticket Priority
                            </option>
                            <option value="critical">Critical</option>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                            <option value="future">Future</option>
                          </select>
                          {touched.priority && errors.priority ? (
                            <p className="text-danger m-0">{errors.priority}</p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.priority}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12 mb-3">
                      <div className="form-group d-flex">
                        <label htmlFor="Sprint" className="form-label w-50">
                          Estimated Time<span className="text-danger">*</span>
                        </label>
                        <div className="w-100 d-block">
                          <input
                            type="type"
                            name="estimated_time"
                            onBlur={handleBlur}
                            className="form-control border-secondary w-100"
                            placeholder="In Hours"
                            value={values.estimated_time}
                            onChange={handleChange}
                          />
                          {touched.estimated_time && errors.estimated_time ? (
                            <p className="text-danger m-0">
                              {errors.estimated_time}
                            </p>
                          ) : (
                            <p className="text-danger m-0">
                              {validationError?.estimated_time}
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <Modal.Footer>
                {spinner ? (
                  <div className="">
                    <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                    <p>Updating Ticket...</p>
                  </div>
                ) : (
                  <>
                    <Button variant="secondary" onClick={() => handleClose()}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      Update Ticket
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </form>
            <Accordion activeKey={activeAccordionKey}>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  onClick={() => changeKey("0")}
                  className="accordianHeader"
                >
                  Comments
                </Accordion.Header>
                <Accordion.Body>
                  <TenderTicketComment
                    ticketId={ticketId}
                  ></TenderTicketComment>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="1">
                <Accordion.Header
                  onClick={() => changeKey("1")}
                  className="accordianHeader"
                >
                  Logs
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
