import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { useParams } from "react-router-dom";

export default function AssignPermission() {

  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;


  const [permissionData, setPermissionData] = useState([])

  const param = useParams()
  const role_id = param.id;
  function getRolePermission() {
    let url = baseUrl + 'getrolehaspermission'
    let config = { headers: { Authorization: bearer_token } };
    const data = { user_id, role_id }
    axios.post(url, data, config)
      .then((response) => {

        if (response.status === 200) {
          setPermissionData(response?.data?.response?.RoleHasPermissionList)
        }
      })
      .catch((error) => {
      })
  }

  function editPermission(value, id) {
    let url = baseUrl + 'createassingedpermissionrole'
    let data = { ...value, user_id, role_id, permission_id: id }
    let config = {
      headers: {
        Authorization: bearer_token,
      },
    }
    axios.post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          getRolePermission()
        }
      })
      .catch((error) => {
      })
  }
  

  function handleChange(index, field, id) {
    const updatedPermissionData = [...permissionData];
    updatedPermissionData[index][field] = updatedPermissionData[index][field] ? 0 : 1;
    setPermissionData(updatedPermissionData);
    let getData = permissionData.find((e) => e.id === id)
    editPermission(getData, id)
  }

  useEffect(() => {
    getRolePermission()
  }, [])

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box pb-2">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/UsersRoles">Roles /</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Assign Permissions
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">PERMISSION LIST</h4>

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="department__card_detail">
                  <div className="card">
                    <div className="py-4 card-body">
                      <div className="tab-content" id="pills-tabContent">
                        <div className="table-responsive browser_users">
                          <table className="table table-hover mb-0 detail-table">
                            <thead>
                              <tr>
                                <th scope="col">Permission Group</th>
                                <th scope="col">Feature</th>
                                <th scope="col">Short Code</th>
                                <th scope="col">View </th>
                                <th scope="col">Add</th>
                                <th scope="col">Edit</th>
                                <th scope="col">Delete</th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                permissionData.length && permissionData.map((e, i) => {
                                  return (
                                    <tr key={e.id}>
                                      <th scope="row">{e.permission_group}</th>
                                      <td>{e.feature}</td>
                                      <td>{e.short_code}</td>
                                      <td>
                                        {" "}
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          checked={e.can_view}
                                          onChange={() => handleChange(i, 'can_view', e.id)}

                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          checked={e.can_add}
                                          onChange={() => handleChange(i, 'can_add', e.id)}
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          checked={e.can_edit}
                                          onChange={() => handleChange(i, 'can_edit', e.id)}
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          checked={e.can_delete}
                                          onChange={() => handleChange(i, 'can_delete', e.id)}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
