import axios from "axios";

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});
export const getUsers = (data) => API.post("/getuserlist", data);
export const employeeList = () => API.get("/employee-list");

export const addEmployee = (data) => API.post("/addemployee", data);
//EmployeeProfile
export const employeeProfile = (data) => API.post("/employee-profile", data);
export const updateContactDetail = (data) =>
  API.patch("/employee-contact-details", data);
export const updatePrimaryDetail = (data) =>
  API.patch("/employee-primary-details", data);
export const updateAddress = (data) =>
  API.patch("/employee-address-details", data);
export const updateEducation = (data) =>
  API.patch("/employee-education-details", data);
export const updateDocument = (data) =>
  API.patch("/upload-documents", data);
// Probation Policy
export const addPolicy = (data) => API.post("/add-probation-policy", data);
export const listProbationPolicy = () => API.get("/list-probation-policy");
export const updateProbationPolicy = (data) =>
  API.patch("/update-probation-policy", data);
export const deleteProbationPolicy = (data) =>
  API.delete("/delete-probation-policy", { data });
export const employeePerProbationPolicy = (id) =>
  API.get(`/probation-employee-list?probation_id=${id}`);
// Job Title
export const addJobTitle = (data) => API.post("/add-job-titles", data);
export const jobTitleList = () => API.get("/job-titles-list");
export const updateJobTitle = (data) => API.patch("/update-job-titles", data);
export const deleteJobTitle = (data) =>
  API.delete("/delete-job-titles", { data });
//time Type
export const timeTypeList = () => API.get("/list-time-type");
export const addTimeType = (data) => API.post("/add-time-type", data);
export const updateTimeType = (data) => API.patch("/update-time-type", data);
export const deleteTimeType = (data) =>
  API.delete("/delete-time-type", { data });
//worker Type
export const workerTypeList = () => API.get("/worker-type-list");
export const addWorkerType = (data) => API.post("/add-worker-type", data);
export const updateWorkerType = (data) =>
  API.patch("/update-worker-type", data);
export const deleteWorkerType = (data) =>
  API.delete("/delete-worker-type", { data });
