import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jobTitleList } from "../../../HrDepartment/hrApi";
import { setLoading } from "./LoaderSlice";
const initialState = { jobTitle: [] };
export const fetchJobTitle = createAsyncThunk(
  "fetch/jobTitleList",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobTitleList();
      return response?.data?.response?.data;
    } catch (err) {
    } finally {
      dispatch(setLoading(false));
    }
  }
);

const jobTitleSlice = createSlice({
  name: "jobTitle",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchJobTitle.pending, (state) => {})
      .addCase(fetchJobTitle.fulfilled, (state, action) => {
      

        state.jobTitle = action.payload;
      })
     
      .addCase(fetchJobTitle.rejected, (state, action) => {});
  },
});
export const getjobTitle = (state) => state?.jobTitleList?.jobTitle;

export default jobTitleSlice.reducer;
