import React, { useEffect, useState } from "react";
import CommonTable from "../../MaterialUiComponent/CommonTable";
import { employeePerProbationPolicy } from "../../hrApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  setLoading,
} from "../../../redux/features/Hr/LoaderSlice";
import { toTitleCase } from "../../CommonFunction";
const ProbationEmployeeTable = ({ selectedProbationPolicy }) => {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoading);
  const [employeeList, setEmployeeList] = useState([]);
  const columns = [
    { id: "name", label: "Name", minWidth: 150 },
    {
      id: "designation",
      label: "Designation",
      minWidth: 150,
      format: toTitleCase,
    },
    { id: "email", label: "Email Address", minWidth: 200 },
    { id: "phone", label: "Number", minWidth: 150 },
    ,
  ];
  const fetchEmployeePerPolicy = async () => {
    dispatch(setLoading(true));
    try {
      const response = await employeePerProbationPolicy(
        selectedProbationPolicy?.id
      );

      setEmployeeList(response?.data?.response?.data);
    } catch (err) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (selectedProbationPolicy) {
      fetchEmployeePerPolicy();
    }
  }, [selectedProbationPolicy]);
  // Sample data
  const rows = [
    {
      name: "John Doe",
      designation: "Software Engineer",
      email: "john.doe@example.com",
      number: "98907654321",
      image: "",
    },
    {
      name: "Jane Smith",
      designation: "Product Manager",
      email: "jane.smith@example.com",
      number: "98907654321",
      image: "",
    },
    // Add more sample data here
  ];

  return (
    <div className="inProbation">
      <CommonTable rows={employeeList} columns={columns} loader={loader} />
    </div>
  );
};

export default ProbationEmployeeTable;
