import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { getRequsitionListApi } from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import BasicPagination from "../commonComponents/Pagination";
import RequistionCollapsibleTable from "./RequistionCollapsableTable";

function RequisitionList() {
  const [requistionData, setRequistionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const departmentName = userProfile?.response?.userDetails?.departmentName;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");

  function requistionList(term, page = 1) {
    getRequsitionListApi({ user_id, search: term, page: page })
      .then((res) => {
        setRequistionData(res?.data?.response?.listRequisition?.data);
        setPageCount(res?.data?.response?.listRequisition?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const debouncedFetchRequistionist = useCallback(
    debounce((term, page) => {
      requistionList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchRequistionist(searchTerm, page);
  }, [searchTerm, debouncedFetchRequistionist, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row d-flex mx-3 my-3">
              <div className="col-md-6">
                <h4>REQUISITION FORM LIST</h4>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <input
                  className="me-2 form-control"
                  style={{ width: "20rem" }}
                  placeholder="Search By PO Number"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                {departmentName === "Store" ? (
                  <Link to={"/requisitionform/addrequisitionform"}>
                    <button className="btn btn-danger">Add New</button>{" "}
                  </Link>
                ) : null}
              </div>
            </div>
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <RequistionCollapsibleTable
                requistionData={requistionData}
                departmentName={departmentName}
              />
            )}
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequisitionList;
