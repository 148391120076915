import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLogout, setPreviousUrl } from "../redux/features/authSlice";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { getNotification } from "../redux/api";

function Header() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allNotification, setAllNotification] = useState([]);
  const profileimage = user?.response?.userDetails?.profileimage;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const userLocalStorage = JSON.parse(localStorage.getItem("profile"));
  const user_id = userLocalStorage?.response?.userDetails?.id;
  const handleLogout = () => {
    dispatch(setLogout());
    navigate("/");    
  };

  function getNotificationData() {
    getNotification({ user_id: user_id })
      .then((response) => {
        setAllNotification(response?.data?.response?.InternalNotification);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    dispatch(setPreviousUrl(location.pathname));
  }, []);

  function timeAgo(time) {
    let minutes;
    let hours;
    let days;
    const currentTime = new Date().getTime();
    const commentTime = new Date(time).getTime();
    const agoTime = currentTime - commentTime;
    hours = Math.floor((agoTime / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((agoTime / 1000 / 60) << 0);
    days = Math.floor(hours / 24);

    if (minutes === 0) {
      return "Just now";
    }
    if (minutes < 60) {
      return `${minutes} minute ago`;
    }
    if (hours < 24) {
      return `${hours} hours ago`;
    }
    if (days <= 1) {
      return `${days} days ago`;
    }
  }

  useEffect(() => {
    // getNotificationData();
  }, []);

  return (
    <>
      <div className="topbar" style={{ borderBottom: "1px solid gray" }}>
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  border: "none",
                  backgroundColor: "#f5f5f9",
                  padding: "5px 10px",
                  color: "black",
                  border: "1px solid lightgray",
                }}
              >
                <i className="ti ti-bell font-20"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  minWidth: "40rem",
                  maxWidth: "40rem",
                  minHeight: "14rem",
                  maxHeight: "14rem",
                }}
              >
                <Dropdown.Item
                  className=""
                  style={{ backgroundColor: "#f7f2f2" }}
                >
                  <h6>Notifications</h6>
                </Dropdown.Item>
                <div style={{ overflowY: "scroll", maxHeight: "10rem" }}>
                  {allNotification?.map((notification) => {
                    return (
                      <Dropdown.Item>
                        <div className="d-flex justify-content-between align-items-center py-2 border-bottom">
                          <div>
                            <span className="text-danger me-3">*</span>
                            {notification.notification_message}
                          </div>
                          <div className="text-secondary font-12">
                            {timeAgo(notification.created_at)}
                          </div>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <li className="dropdown">
              <Link
                className="nav-link dropdown-toggle nav-user"
                data-bs-toggle="dropdown"
                to=""
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <img
                    src={imageBaseUrl + profileimage}
                    alt="profile-user"
                    className="rounded-circle me-2 thumb-sm"
                  />
                  <div>
                    <span className="d-none d-md-block fw-semibold font-12 text-capitalize">
                      {" "}
                      {user?.response?.userDetails?.name}{" "}
                      <i className="mdi mdi-chevron-down"></i>
                    </span>
                    <small className="d-none d-md-block font-11">
                      {user?.response?.userDetails?.departmentName}
                    </small>
                  </div>
                </div>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to={`/user-profile/${user_id}`}>
                  <i className="ti ti-user font-16 me-1 align-text-bottom"></i>{" "}
                  Profile
                </Link>
                <div className="dropdown-divider mb-0"></div>
                <Link
                  className="dropdown-item"
                  to=""
                  onClick={() => handleLogout()}
                >
                  <i className="ti ti-power font-16 me-1 align-text-bottom"></i>{" "}
                  Logout
                </Link>
              </div>
            </li>
          </ul>
          <ul className="list-unstyled topbar-nav mb-0"></ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
