import { Email } from "@mui/icons-material";
import * as Yup from "yup";

const addVendorFormSchema = (checkboxes) => { 
  let schema = Yup.object().shape({
    party_name: Yup.string().required("This field is required"),
    under_invoice: Yup.string().required("This field is required"),
    under_account: Yup.string().required("This field is required"),
  });

  if (checkboxes.is_address_check) {
    schema = schema.shape({
      address_type: Yup.string().required("This field is required"),
      country_id: Yup.string().required("This field is required"),
      state_id: Yup.string().required("This field is required"),
      city_id: Yup.string().required("This field is required"),
      address: Yup.string().required("This field is required"),
      pin_code: Yup.string()
        .matches(/^\d+$/, "Pin code must contain only numbers")
        .length(6, "Pin code must be exactly 6 digits")
        .required("This field is required"),
      email: Yup.string().email("Invalid email"),
      telephone: Yup.string()
        .matches(/^[0-9()+\- ]*$/, "Telephone must contain only numbers and special characters (+, -, ())"),
      mobile: Yup.string()
        .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
        .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers"),
      fax_no: Yup.string()
        .matches(/^[0-9()+\- ]*$/, "Fax number must contain only numbers and special characters (+, -, ())"),
      website: Yup.string()
        .url("Must be a valid URL"),
    });
  }

  if (checkboxes.is_bank_details_check) {
    schema = schema.shape({
      bank_name: Yup.string().required("Bank name is required"),
      account_holder: Yup.string().required("Account holder is required"),
      account_no: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers")
        .required("Account number is required"),
      swift_code: Yup.string()
        .nullable()
        .matches(/^[a-zA-Z0-9]{8,11}$/, "SWIFT code must be 8-11 characters long and contain only alphanumeric characters"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      bank_address: Yup.string().required("Bank address is required"),
      bank_pin_code: Yup.string()
      .matches(/^\d+$/, "Pin code must contain only numbers")
      .length(6, "Pin code must be exactly 6 digits")
      .required("This field is required"),
      bank_email: Yup.string().email("Invalid email"),
      bank_telephone: Yup.string()
      .nullable()
      .matches(/^[0-9()+\- ]*$/, "Telephone must contain only numbers and special characters (+, -, ())"),
      bank_mobile: Yup.string()
      .nullable()
      .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers"),
      bank_fax_no: Yup.string()
      .nullable()
      .matches(/^[0-9()+\- ]*$/, "Fax number must contain only numbers and special characters (+, -, ())"),
      bank_website: Yup.string()
      .nullable()
      .url("Must be a valid URL"),
    });
  }
  if (checkboxes.contact_details_check) {
    schema = schema.shape({
      contact_person: Yup.string().required("This field is required"),
      contact_job_designation: Yup.string().required("This field is required"),
      contact_mobile: Yup.string()
      .length(10, "Mobile number must be exactly 10 digits and contain only numbers")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits and contain only numbers"),
      contact_telephone: Yup.string()
      .matches(/^[0-9()+\- ]*$/, "Telephone must contain only numbers and special characters (+, -, ())"),
      contact_email: Yup.string().email("Invalid email"),

    });
  }
  if (checkboxes.accountDetail) {
    schema = schema.shape({
      is_msme_check: Yup.string().required("This field is required"),
      pan_no: Yup.string()
        .matches(/^[a-zA-Z0-9]{10}$/, "PAN number must be exactly 10 characters and contain only numbers or alphabets"),
      gst_no: Yup.string()
        .matches(/^[a-zA-Z0-9]{15}$/, "GST number must be exactly 15 characters and contain only numbers or alphabets"),
      msme_no: Yup.string()
        .length(12, "MSME number must be exactly 12 digits"),
    });
  }

  return schema;
};

export default addVendorFormSchema;
