import React from "react";
import styles from "./LotsLogsTable.module.css";
import { formatDate } from "../../../constant";

function LotsLogsTable({ text, data }) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <div className={styles.lotDetailHeader}>
        <p>Amendment Logs</p>
        <span>{text}</span>
        <p>09-12-2023</p>
      </div>
      <table>
        <thead className={styles.lotsLogsThead}>
          <th>PO/LO Quantity</th>
          <th>UOM</th>
          <th>Start Date</th>
          <th>Completion Date</th>
          <th>DP Extention</th>
        </thead>
        <tbody className={styles.lotsLogsTbody}>
          {data?.map((row, index) => (
            <tr key={row?.id}>
              <td>{row?.poLoaQty || "- - -"}</td>
              <td>{row?.unit || "- - -"}</td>
              <td>{formatDate(row?.startDate) || "- - -"}</td>
              <td>{formatDate(row?.completionDate) || "- - -"}</td>
              <td>{formatDate(row?.dpExtension) || "- - -"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LotsLogsTable;
