import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeliveryScheduleTable from "./DeliveryScheduleTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80rem",
  height: "auto",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: 3, // Add border radius
  boxShadow: 24,
  p: 2, // Add some padding
  background: "#e8e8e8",
  overflow: "auto",
};

export default function DeliveryScheduleModal({
  open,
  setOpen,
  itemDetails,
  id,
  POnumber,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h5 style={{ marginLeft: "0.4rem" }}> Delivery Schedule</h5>{" "}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <DeliveryScheduleTable
              itemDetails={itemDetails}
              setOpen={setOpen}
              id={id}
              POnumber={POnumber}
              
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
