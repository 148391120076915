import React, { useEffect, useState } from "react";
import AddAuhorizeSignatoriesButton from "./AddAuhorizeSignatoriesButton";
import AuthorizeSignatureDetail from "./AuthorizeSignatureDetail";
import { auhorizeSignatoriesApi } from "../OrganizationComponents/organizationStructureApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
function AuthorizeSignatories({ legalEntity }) {
  const [authorizeSignatoriesList, setAuthorizeSignatoriesList] = useState([]);
  const [editData, setEditData] = useState(null);
  const[loader,setLoader] = useState(false)
  const getAuthoizeSignatory = () => {
    setLoader(true)
    auhorizeSignatoriesApi(legalEntity.id).then((res) => {
      setLoader(false)
      const data = res?.data?.response?.data;
      setAuthorizeSignatoriesList(data);
    }).catch((err)=>{setLoader(false)
      toast.error(err.response.data.message)});
  };

  useEffect(() => {
    getAuthoizeSignatory();
  }, [legalEntity]);
  return (
    <div>
      <h4 style={{ color: "rgba(70, 103, 182, 1)", marginBottom: "1.8rem" }}>
        Authorize Signatories
      </h4>
      <AddAuhorizeSignatoriesButton
        legalEntity={legalEntity}
        getAuthoizeSignatory={getAuthoizeSignatory}
      />
   
     {loader ? (
  <div
    className="d-flex self-align-start justify-content-center pb-5"
    style={{ height: "70vh" }}
  >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
) : authorizeSignatoriesList?.length > 0 ? (
  authorizeSignatoriesList?.map((authorizeSignatory) => {
    
    return (
      <AuthorizeSignatureDetail
        authorizeSignatory={authorizeSignatory}
        key={authorizeSignatory?.id}
        getAuthoizeSignatory={getAuthoizeSignatory}
      />
    );
  })
) : (
  <div>No data found</div>
)}

    </div>
  );
}

export default AuthorizeSignatories;
