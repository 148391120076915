import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
function OrganizationStructureHeaderName({ image, name,tabvalue }) {
  return (
    <>
   
    
      <div className="d-flex gap-3 mb-1 align-items-center p-3 ">
        <div>
          <img
            src={image}
            className="rounded-circle"
            style={{ height: "3rem", width: "3rem" }}
          />
        </div>
        <div>
          <h5 className="tab-card-heading">{name}</h5>
          <h6>{15} Employees</h6>
        </div>
      </div>
     
    
    </>
  );
}

export default OrganizationStructureHeaderName;
