import React, { useState } from "react";
import styles from "../Inventory/Inventory.module.css";

function InventoryDetailPageCard({ itemDetail }) {
  

  return (
    <div className={styles["inventoy-card"]}>
      <div className="d-flex p-4 gap-5 mt-4 ">
        <h4 style={{ maxWidth: "57rem" }}>{itemDetail?.product_name}</h4>
        <div className={styles["item-code"]}>
          <h6>{itemDetail?.item_code}</h6>
        </div>
      </div>
      <div className="ps-4 mb-2">
        <p>
          Category: <strong>Raw Material</strong>
        </p>
      </div>
      <hr className="my-3" />
      <div className="d-flex ps-4 gap-5 mb-2">
        <p>
          Total Quantity:
          <strong>
            {itemDetail?.balance} {itemDetail?.unit_name}
          </strong>
        </p>
        {/* <p>
          Total Amount : <strong> 44722</strong>
        </p> */}
      </div>
    </div>
  );
}

export default InventoryDetailPageCard;
