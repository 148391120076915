import React, { useCallback, useEffect, useState } from "react";
import {
  getItemMasterList,
  getListItemBySow,
  getUnitMasterList,
} from "../../purchaseApi";
import Select from "react-select";
import { errorsMessage } from "../../../constant";
import { RiDeleteBinLine } from "react-icons/ri";
import debounce from "lodash/debounce";
import { FaEdit } from "react-icons/fa";

function ItemDetail({
  itemsValue,
  handleItemChange,
  handleAddDetails,
  multipleItemDetail,
  setItemValues,
  handleDeleteItem,
  errors,
  touched,
  serverSideValidation,
  project_id,
  handleEditItem,
  itemIndex,
}) {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [unitMasterList, setUnitMasterList] = useState([]);
  const [itemMasterList, setItemMasterList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };
  function unitData() {
    getUnitMasterList({ user_id })
      .then((res) => {
        setUnitMasterList(res?.data?.response?.UnitMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }
  function itemMasterData(searchTerm) {
    setLoading(true);
    getItemMasterList({ user_id, search: searchTerm })
      .then((res) => {
        setItemMasterList(res?.data?.response?.ItemMasterList);
        console.log(
          res?.data?.response?.ItemMasterList,
          res?.data?.response?.ItemMasterList.length
        );
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      itemMasterData(term);
    }, 800),
    [user_id]
  );
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);
  useEffect(() => {
    unitData();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group mb-3 mt-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Item Name
            </label>
            <Select
              placeholder="Select Item from Name"
              className="form-select-control border-dark"
              options={[
                { id: "", product_name: "Select an item" },
                ...itemMasterList,
              ]}
              getOptionLabel={(itemMasterList) =>
                itemMasterList["product_name"]
              }
              isLoading={loading}
              getOptionValue={(itemMasterList) => itemMasterList["item_code"]}
              onChange={(value) => {
                setItemValues((prevState) => ({
                  ...prevState,
                  item_code: value.item_code,
                  item_id: value.id,
                }));
              }}
              onInputChange={(value) => setSearchTerm(value)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                  ...theme.colors,
                  primary25: "lightgrey",
                  primary: "lightgrey",
                },
              })}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="col-md-3  mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Due Date
          </label>
          <input
            className="form-control border-secondary"
            type="date"
            onChange={handleItemChange}
            value={itemsValue.due_date}
            name="due_date"
          />
          <p className="text-danger m-0">
            {touched.due_date && errors.due_date
              ? errors.due_date
              : serverSideValidation?.due_date}
          </p>
        </div>
        <div className="col-md-6 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Item Description
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Item Description"
            onChange={handleItemChange}
            value={itemsValue.item_description}
            name="item_description"
          />
          <p className="text-danger m-0">
            {touched.item_description && errors.item_description
              ? errors.item_description
              : serverSideValidation?.item_description}
          </p>
        </div>
        <div className="col-md-6 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Specification
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Specification"
            onChange={handleItemChange}
            value={itemsValue.specification}
            name="specification"
          />
          <p className="text-danger m-0">
            {touched.specification && errors.specification
              ? errors.specification
              : serverSideValidation?.specification}
          </p>
        </div>
        <div className="col-md-6 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Remark
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Remark"
            onChange={handleItemChange}
            value={itemsValue.remarks}
            name="remarks"
          />
          <p className="text-danger m-0">
            {touched.remarks && errors.remarks
              ? errors.remarks
              : serverSideValidation?.remarks}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Qty
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Qty"
            onChange={handleItemChange}
            value={itemsValue.qty}
            name="qty"
          />
          <p className="text-danger m-0">
            {touched.qty && errors.qty ? errors.qty : serverSideValidation?.qty}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Rate
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Item Rate"
            onChange={handleItemChange}
            value={itemsValue.rate}
            name="rate"
          />
          <p className="text-danger m-0">
            {touched.rate && errors.rate
              ? errors.rate
              : serverSideValidation?.rate}
          </p>
        </div>

        {/* <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Discount Type{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleItemChange}
            value={itemsValue.disc_type}
            name="disc_type"
          >
            <option value={""}>Select </option>
            <option value={"CGST"}>CGST </option>
            <option value={"SGST"}>SGST </option>
            <option value={"IGST"}>IGST </option>
            <option value={"FREIGHTCHARGES"}>Freight Charges </option>
          </select>
          <p className="text-danger m-0">
            {touched.disc_type && errors.disc_type
              ? errors.disc_type
              : serverSideValidation?.disc_type}
          </p>
        </div> */}
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Disc Per
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Discount Per."
            onChange={handleItemChange}
            value={itemsValue.disc_per}
            name="disc_per"
          />
          <p className="text-danger m-0">
            {touched.disc_per && errors.disc_per
              ? errors.disc_per
              : serverSideValidation?.disc_per}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Disc Amt
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Discount Amount"
            onChange={handleItemChange}
            value={itemsValue.disc_amt}
            name="disc_amt"
          />
          <p className="text-danger m-0">
            {touched.disc_amt && errors.disc_amt
              ? errors.disc_amt
              : serverSideValidation?.disc_amt}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Unit{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleItemChange}
            value={itemsValue.unit_id}
            name="unit_id"
          >
            <option value={""}>Select </option>
            {unitMasterList.map((element) => (
              <option key={element.id} value={element.id}>
                {element.unit_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.unit_id && errors.unit_id
              ? errors.unit_id
              : serverSideValidation?.unit_id}
          </p>
        </div>

        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST(%)
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Gst %"
            onChange={handleItemChange}
            value={itemsValue.gst_percent}
            name="gst_percent"
          />
          <p className="text-danger m-0">
            {touched.gst_percent && errors.gst_percent
              ? errors.gst_percent
              : serverSideValidation?.gst_percent}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            GST Amount
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Gst Amount"
            onChange={handleItemChange}
            value={itemsValue.gst_amt}
            name="gst_amt"
          />
          <p className="text-danger m-0">
            {touched.gst_amt && errors.gst_amt
              ? errors.gst_amt
              : serverSideValidation?.gst_amt}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Amount
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Amount"
            onChange={handleItemChange}
            value={itemsValue.amount}
            name="amount"
          />
          <p className="text-danger m-0">
            {touched.amount && errors.amount
              ? errors.amount
              : serverSideValidation?.amount}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Second Qty
          </label>
          <input
            className="form-control border-secondary"
            placeholder="Enter Second Qty."
            onChange={handleItemChange}
            value={itemsValue.second_qty}
            name="second_qty"
          />
          <p className="text-danger m-0">
            {touched.second_qty && errors.second_qty
              ? errors.second_qty
              : serverSideValidation?.second_qty}
          </p>
        </div>
        <div className="col-md-3 mt-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Second Unit{" "}
          </label>
          <select
            className="form-control border-secondary"
            onChange={handleItemChange}
            value={itemsValue.second_unit_id}
            name="second_unit_id"
          >
            <option value="">Select </option>
            {unitMasterList.map((element) => (
              <option key={element.id} value={element.id}>
                {element.unit_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {touched.second_unit_id && errors.second_unit_id
              ? errors.second_unit_id
              : serverSideValidation?.second_unit_id}
          </p>
        </div>
      </div>
      <div className="mt-2">
        <button
          className="btn btn-danger"
          type="button"
          onClick={() =>
            handleAddDetails(
              setUnitMasterList,
              setItemMasterList,
              unitData,
              itemMasterData
            )
          }
        >
          Add Details
        </button>
      </div>

      <div className="col-md-12 mt-3">
        <div className="table-responsive table-container-scroll">
          <table
            style={{ borderCollapse: "collapse", border: "1px solid black" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">S.No.</h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "25rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Item Code</h6>
                </th>
                {/* <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Item Code </h6>
                </th> */}
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Quantity </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Rate </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Sale Amount </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "6rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Discount </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "6rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Tax Amount </h6>
                </th>
                {/* <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Total Amount </h6>
                </th> */}
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "8rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Unit Name </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Second Qty </h6>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    width: "5rem",
                  }}
                  className="text-center"
                >
                  <h6 className="fw-bold">Action </h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {multipleItemDetail?.map((element, i) => (
                <tr
                  key={i}
                  style={{ backgroundColor: itemIndex === i ? "#ffeded" : "" }}
                >
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {i + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element?.item_code}
                  </td>
                  {/* <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.item_id}
                  </td> */}

                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.qty}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.rate}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.amount}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.disc_per}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.gst_amt}
                  </td>

                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {
                      unitMasterList.find(
                        (item) => Number(item.id) === Number(element.unit_id)
                      )?.unit_name
                    }
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    {element.second_qty}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    className="text-center"
                  >
                    <RiDeleteBinLine
                      // data-bs-toggle="modal"
                      // data-bs-target="#unitMasterDelete"
                      className="text-danger font-20"
                      style={{ margin: "auto", cursor: "pointer" }}
                      onClick={() => handleDeleteItem(i)}
                    />
                    <FaEdit
                      // data-bs-toggle="modal"
                      // data-bs-target="#unitMasterDelete"
                      className="text-danger font-20"
                      style={{
                        margin: "auto",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEditItem(i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ItemDetail;
