import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;

const initialState = {
  dropdowns: {},
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
}


export const fetchDropdown = createAsyncThunk(
  "users/fetchDropdown",
  async ({config,user_id}) => {
    const response = await axios.post(
      `${baseUrl}getalldropdown`,
      { user_id },
      config
    );
    return response.data?.response?.getAllDropDown;
  }
);

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchDropdown.fulfilled, (state, action) => { 
      state.status = 'succeeded'
      state.dropdowns = action.payload ;
      state.error = null
    })
    .addCase(fetchDropdown.pending,(state)=>{
      state.status = 'loading'
    })
    .addCase(fetchDropdown.rejected,(state,action)=>{
      state.status = 'failed'
      state.error = action.error.message
    })
  },
});
export const selectDropdowns = (state) =>state.dropdown.dropdowns;
export const getdropdownError= (state) =>state.dropdown.error;
export const getdropdownStatus= (state) =>state.dropdown.status;



export default dropdownSlice.reducer;
