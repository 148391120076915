import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PendingStatusTable from "./PendingStatusTable";

function PendingStatusAlert({ pendingStatusList }) {
  const [show, setShow] = useState(true);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        centered
        className="alert-modal"
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="m-0 font-16 fw-bold">
            Status (Pending)
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ minHeight: "400px", maxHeight: "600px" }}>
          {pendingStatusList.length ? (
            <div className="mb-3">
              <h5 className="fw-bold">Warranty Rejection Advice (UDM)</h5>
              <PendingStatusTable
                pendingStatusList={pendingStatusList}
              ></PendingStatusTable>
            </div>
          ) : (
            <div className="mt-5">
            <h4 className="text-center mt-5">
              There are no items in the warranty <br/> tracker in pending status.
            </h4>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PendingStatusAlert;
