import MaterialPurchase from "./SettingSections/MaterialPurchase";

function ProjectMaterial({ tabKey, lotData, lotId, setLotId }) {
  return (
    <>
      <div className="pt-2">
        <MaterialPurchase
          tabKey={tabKey}
          lotData={lotData}
          lotId={lotId}
          setLotId={setLotId}
        />
      </div>
    </>
  );
}

export default ProjectMaterial;
