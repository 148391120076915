import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allForms } from "../../schemas/settingSchema";
import { useFormik } from "formik";

function EditTicketType() {
  const location = useLocation();
  const navigate = useNavigate();
  const editTicketData = location?.state?.editTicketData ?? "";
  const tickettypeId = editTicketData.id;
  const [name, setName] = useState(editTicketData.name);
  const [nameError, setNameError] = useState("");
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const [spinner, setSpinner] = useState(false);

  const initialValues = {
    name: "",
  };

  const { handleSubmit, values, errors, handleChange, setValues } = useFormik({
    initialValues: initialValues,
    validationSchema: allForms,
    onSubmit: (values) => {
      updateTicketType(values);
    },
  });

  function updateTicketType(value) {
    setSpinner(true);
    const url = baseUrl + "edittickettype";
    const data = { user_id, tickettypeId, ...value, accessType: "edit" };
    axios
      .post(url, data, config)
      .then((response) => {
        setSpinner(false);
       
        if (response.data.success) {
          navigate("/ticket-type");
          toast.success("Updated Successfully");
        }
        if (response.data.status === "Token is Expired") {
          navigate("/");
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setNameError(error.response.data.response.name);
        }
        if (error.response.data.response === "Already Exist.") {
          setNameError(error.response.data.response);
        }
      });
  }

  useState(() => {
    setValues(editTicketData);
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/ticket-type">Ticket Type/</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Ticket Type
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Edit Ticket Type</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Ticket Type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Ticket Type Name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <p
                              className="text-capitalize"
                              style={{ color: "red" }}
                            >
                              {errors.name ? errors.name : nameError}
                            </p>
                          </div>
                        </div>
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Wait...</p>
                        </div>
                      ) : (
                        <div className="">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-3"
                          >
                            Update
                          </button>
                          <Link to="/ticket-type">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm"
                            >
                              Cancel
                            </button>
                          </Link>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditTicketType;
