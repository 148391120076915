import React, { useCallback, useEffect, useState } from "react";
import BasicPagination from "../commonComponents/Pagination";
import { debounce } from "lodash";
import { getCustomerApi } from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { errorsMessage } from "../../constant";
import { Link } from "react-router-dom";
import CustomerTable from "./CustomerTable";

function CustomerList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [loader, setLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  function customerList(term, page) {
    setLoader(true);
    getCustomerApi({ page })
      .then((res) => {
        setListData(res?.data?.response?.CustomerDetailsList?.data || []);
        setPageCount(res?.data?.response?.CustomerDetailsList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const debouncedFetchTaxInvoiceList = useCallback(
    debounce((term, page) => {
      customerList(term, page);
    }, 600),
    [user_id]
  );

  useEffect(() => {
    debouncedFetchTaxInvoiceList("", page);
  }, [debouncedFetchTaxInvoiceList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card mt-4">
            <div className="card-body tabsall">
              <div className="row">
                <div className="col-sm-4">
                  <div className="page-title-box">
                    <h2 className="page-title"> Customer List </h2>
                  </div>
                </div>
                <div className="col-md-8 d-flex align-items-center justify-content-end">
                  <div className="d-flex" style={{ width: "54%" }}>
                    <div class="input-group search_matarial d-flex align-items-center"></div>
                    <div className="">
                      <Link to={"/addCustomerDetail"}>
                        <button className="btn btn-danger">
                          Add&nbsp;Customer
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "70vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <CustomerTable detail={listData} />
                )}
                <div className="text-end mt-2">
                  <BasicPagination
                    handlePaginateChange={handlePaginateChange}
                    page={page}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerList;
