import React from "react";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

function InvoiceTable({ detail }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  function formatDate(dates) {
    const dateString = dates;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  }
  return (
    <div class="col-md-12">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ border: "0" }}>
              <th
                scope="col"
                style={{
                  width: "4rem",
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                }}
              >
                S.No.
              </th>
              <th
                scope="col"
                style={{
                  width: "8rem",
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                }}
              >
                Railway P.O. number
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Invoice Date
              </th>
              <th
                scope="col"
                className="col-2"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "12rem",
                }}
              >
                Invoice No.
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Received By
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Invoice Pdf
              </th>
              <th
                scope="col"
                style={{
                  color: "#b9b9b9",
                  fontSize: "0.9rem",
                  width: "8rem",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {detail.map((item, index) => (
              <tr key={index} style={{ borderBottom: "1px solid #E0E0E0" }}>
                <td>{index + 1}</td>
                <td>{item?.railway_po_no || "- - -"}</td>
                <td>{formatDate(item?.created_at) || "- - -"}</td>
                <td>{item?.invoice_no || "- - -"}</td>
                <td>{item?.received_by || "- - -"}</td>
                <td>
                  {" "}
                  {item?.invoice_pdf ? (
                    <Link to={baseURL + item?.invoice_pdf} target="_blank">
                      <BsFileEarmarkPdfFill
                        color="#D03438"
                        style={{ width: "22px", height: "22px" }}
                      />
                    </Link>
                  ) : (
                    "- - -"
                  )}
                </td>

                <td>
                  <Link to="/updateTaxInvoice" state={{ detail: item }}>
                    <span>
                      <FaEdit style={{ width: "30px", height: "25px" }} />
                    </span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvoiceTable;
