import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { formatDate } from "../../../constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};
const DPstyles = {};

export default function DpExtensionLogsModal({ open, setOpen, data, index }) {
  const handleClose = () => setOpen(false);

  function formatedDate(dateString) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Adjust by one day

    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={DPstyles.dpLogsPopup}>
          <div className={DPstyles.popupContent}>
            <h2 className={DPstyles.dpLogsPopupHeader}>DP Extension Logs</h2>
            <p className={DPstyles.dpLogsPopupText}>LOT – {index + 1}</p>
            <table className={DPstyles.dpLogsPopupTable}>
              <thead>
                <tr>
                  <th>EXTENDED ON</th>
                  <th>EXTENDED TO</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{formatedDate(row.dpExtensionLogDate)}</td>
                    <td>{formatDate(row.dpExtensionLog)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              className={`btn btn-danger ${DPstyles.closeBtn}`}
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
