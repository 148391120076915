import React from "react";
import { FaEdit } from "react-icons/fa";
import EditJobDetails from "./EditJobDetails";
import EditEmployeeOrgnization from "./EditEmployeeOrgnization";
import "../../HrDepartment.scss"
const JobDetail = ({data}) => {
  const [openJobDetail, setOpenJobDetail] = React.useState(false);
 const [openOrgnization, setOpenOrgnization] = React.useState(false);
  const openJobDetailFn =()=>setOpenJobDetail(true);
  const closeJobDetailFn =()=>setOpenJobDetail(false);
  const openOrgnizationFn =()=>setOpenOrgnization(true)
  const closeOrgnizationFn =()=>setOpenOrgnization(false)
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="accordian-card">
            <div
              className="card accordian-card_fix"
            >
              <div className="d-flex justify-content-between align-items-center ms-4 me-2">
                <h4 className="detail-card-heading">Job Details</h4>
                <FaEdit onClick={openJobDetailFn}/>
              </div>

              <div className=" border-top ps-4 pt-1">
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Employee Number:</div>
                  <div className="card-right-value col-6">{data?.employee_number
?data?.employee_number
:"--"}</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Date of Joining:</div>
                  <div className="card-right-value col-6">{data?.join_date
?data?.join_date
:"--"}</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Job Title:</div>
                  <div className="card-right-value col-6">{data?.job_title
?data?.job_title
:"--"}</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">In Probation?:</div>
                  <div className="card-right-value col-6">
                  {data?.probation_policy
?data?.probation_policy:"--"}

                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Time Type:</div>
                  <div className="card-right-value col-6">{data?.time_type
?data?.time_type
:"--"}</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Notice Period:</div>
                  <div className="card-right-value col-6">{data?.notice_period
?data?.notice_period
:"--"}</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Employee Type:</div>
                  <div className="card-right-value col-6">{data?.
worker_type?data?.
worker_type
:"--"
}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="accordian-card">
            <div className="card accordian-card_fix">
              <div className="d-flex justify-content-between align-items-center ms-4 me-2">
                <h4 className="detail-card-heading">Organization</h4>
                <FaEdit onClick={openOrgnizationFn}/>
              </div>

              <div className=" border-top ms-4">
                <div className="row mt-2 mt-2">
                    <div className="card-right-heading col-6">Department:</div>
                    <div className="card-right-value col-6">Accounts</div>
                    </div>
                    <div className="row mt-2">
                    <div className="card-right-heading col-6">Location:</div>
                    <div className="card-right-value col-6">{data?.location?data?.location:"--"}</div>
                    </div>
                    <div className="row mt-2">
                    <div className="card-right-heading col-6">Legal Entity:</div>
                    <div className="card-right-value col-6">{data?.location?data?.location:"--"}</div>
                    </div>
                    <div className="row mt-2">
                    <div className="card-right-heading col-6">Cost Center:</div>
                    <div className="card-right-value col-6">Not Set</div>
                    </div>
                    <div className="row mt-2">
                    <div className="card-right-heading col-6">Reports To:</div>
                    
                    <div className="card-right-value col-6">
                      {" "}
                      <span className="abbreviation">VB</span>Vijay Bansal
                    </div>
                    </div>
                    <div className="row mt-2">
                    <div className="card-right-heading col-6">Manager of Manager:</div>
                    <div className="card-right-value col-6">
                      {" "}
                      <span className="abbreviation">KS</span>Kartik Singh
                    </div>
                  </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditJobDetails open={openJobDetail} handleClose={closeJobDetailFn}/>
      <EditEmployeeOrgnization open={openOrgnization} handleClose={closeOrgnizationFn}/>
    </>
  );
};

export default JobDetail;
