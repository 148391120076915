import React, { useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import AddEmployeeForm from "./Form/AddEmployeeForm";
import JobDetailForm from "./Form/JobDetailForm";
import WorkDetailForm from "./Form/WorkDetailForm";
import CompensationsForm from "./Form/CompensationsForm";

import "../HrDepartment.scss";

function CustomToggle({ children, eventKey, onClick }) {
  const decoratedOnClick = useAccordionButton(eventKey, onClick);

  return (
    <button
      type="button"
      style={{
        background: "#fff",
        border: "none",
        padding: 0,
        lineHeight: "0",
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
const AddEmployee = () => {
  const [activeKey, setActiveKey] = useState("Basic Details");
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [basicDetail, setBasicDetail] = useState({});
  const [workDetail, setWorkDetail] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [validationError, setValidationError] = useState(null);
  const combinedDetails = {
    ...basicDetail,
    ...workDetail,
    ...jobDetail,
  };
  const [formStage, setFormStage] = useState(1);

  // const payload = () => {
  //   const reqpayload = { ...basicDetail, ...jobDetail, ...workDetail };
  //   return reqpayload;
  // };

  const handleToggle = (eventKey) => {
    if (eventKey === "Basic Details" && formStage >= 1) {
      setActiveKey(activeKey === eventKey ? "" : eventKey);
    } else if (eventKey === "Job Details" && formStage > 1) {
      setActiveKey(activeKey === eventKey ? "" : eventKey);
    } else if (eventKey === "Work Details" && formStage > 2) {
      setActiveKey(activeKey === eventKey ? "" : eventKey);
    } else if (eventKey === "Compensations" && formStage > 3) {
      setActiveKey(activeKey === eventKey ? "" : eventKey);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <span className="dashboard">ADD EMPLOYEE</span>
            <div className="row margin_top add-new-employee">
              <div className="col-lg-3">
                <div className="card ">
                  <div
                    className={
                      activeKey === "Basic Details"
                        ? "d-flex ps-2 pt-2 align-items-center mx-3 my-2 activeDiv rounded "
                        : "d-flex ps-2 pt-2 align-items-center mx-3 my-2"
                    }
                    onClick={() => handleToggle("Basic Details")}
                  >
                    {/* <img className="add_emp_count" src={BasicIcon} /> */}
                    <p
                      className="add_emp_count"
                      style={
                        formStage > 1
                          ? { backgroundColor: "#4667B6", color: "white" }
                          : {}
                      }
                    >
                      1
                    </p>
                    <p className="add_emp ps-2">Basic Details</p>
                  </div>
                  <div
                    className={
                      activeKey === "Job Details"
                        ? "d-flex ps-2 pt-2 align-items-center mx-3 my-2 activeDiv rounded "
                        : "d-flex ps-2 pt-2 align-items-center mx-3 my-2"
                    }
                    onClick={() => handleToggle("Job Details")}
                  >
                    <p
                      className="add_emp_count"
                      style={
                        formStage > 2
                          ? { backgroundColor: "#4667B6", color: "white" }
                          : {}
                      }
                    >
                      2
                    </p>
                    <p className="add_emp ps-2">Job Details</p>
                  </div>
                  <div
                    className={
                      activeKey === "Work Details"
                        ? "d-flex ps-2 pt-2 align-items-center mx-3 my-2 activeDiv rounded "
                        : "d-flex ps-2 pt-2 align-items-center mx-3 my-2"
                    }
                    onClick={() => handleToggle("Work Details")}
                  >
                    <p
                      className="add_emp_count"
                      style={
                        formStage > 3
                          ? { backgroundColor: "#4667B6", color: "white" }
                          : {}
                      }
                    >
                      3
                    </p>
                    <p className="add_emp ps-2">Work Details</p>
                  </div>
                  <div
                    className={
                      activeKey === "Compensations"
                        ? "d-flex ps-2 pt-2 align-items-center mx-3 my-2 activeDiv rounded "
                        : "d-flex ps-2 pt-2 align-items-center mx-3 my-2"
                    }
                    onClick={() => handleToggle("Compensations")}
                  >
                    <p
                      className="add_emp_count"
                      style={
                        formStage > 4
                          ? { backgroundColor: "#4667B6", color: "white" }
                          : {}
                      }
                    >
                      4
                    </p>
                    <p className="add_emp ps-2">Compensations</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 ms-4">
                <Accordion className="add-emp-accordian" activeKey={activeKey}>
                  <Card>
                    <Card.Header className="px-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <p
                          className={
                            activeKey === "Basic Details"
                              ? "add_emp_active "
                              : "add_emp_non_active"
                          }
                        >
                          Basic Details
                        </p>
                        <CustomToggle
                          eventKey="Basic Details"
                          onClick={() => handleToggle("Basic Details")}
                        >
                          {activeKey === "Basic Details" ? (
                            <FaAngleUp
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          ) : (
                            <FaAngleDown
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          )}
                        </CustomToggle>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Basic Details">
                      <Card.Body>
                        <AddEmployeeForm
                          formStage={formStage}
                          setFormStage={setFormStage}
                          setActiveKey={setActiveKey}
                          setBasicDetail={setBasicDetail}
                          validationError={validationError}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="add-emp-accordian" activeKey={activeKey}>
                  <Card>
                    <Card.Header className="px-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <p
                          className={
                            activeKey === "Job Details"
                              ? "add_emp_active"
                              : "add_emp_non_active"
                          }
                        >
                          Job Details
                        </p>
                        <CustomToggle
                          eventKey="Job Details"
                          onClick={() => handleToggle("Job Details")}
                        >
                          {activeKey === "Job Details" ? (
                            <FaAngleUp
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          ) : (
                            <FaAngleDown
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          )}
                        </CustomToggle>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Job Details">
                      <Card.Body>
                        <JobDetailForm
                          formStage={formStage}
                          setFormStage={setFormStage}
                          setActiveKey={setActiveKey}
                          setJobDetail={setJobDetail}
                          validationError={validationError}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="add-emp-accordian" activeKey={activeKey}>
                  <Card>
                    <Card.Header className="px-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <p
                          className={
                            activeKey === "Work Details"
                              ? "add_emp_active"
                              : "add_emp_non_active"
                          }
                        >
                          Work Details
                        </p>
                        <CustomToggle
                          eventKey="Work Details"
                          onClick={() => handleToggle("Work Details")}
                        >
                          {activeKey === "Work Details" ? (
                            <FaAngleUp
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          ) : (
                            <FaAngleDown
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          )}
                        </CustomToggle>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Work Details">
                      <Card.Body>
                        <WorkDetailForm
                          formStage={formStage}
                          setFormStage={setFormStage}
                          setActiveKey={setActiveKey}
                          setWorkDetail={setWorkDetail}
                          validationError={validationError}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="add-emp-accordian" activeKey={activeKey}>
                  <Card>
                    <Card.Header className="px-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <p
                          className={
                            activeKey === "Compensations"
                              ? "add_emp_active"
                              : "add_emp_non_active"
                          }
                        >
                          Compensations
                        </p>
                        <CustomToggle
                          eventKey="Compensations"
                          onClick={() => handleToggle("Compensations")}
                        >
                          {activeKey === "Compensations" ? (
                            <FaAngleUp
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          ) : (
                            <FaAngleDown
                              style={{
                                height: "100px",
                                color: "#c7c7c7",
                              }}
                            />
                          )}
                        </CustomToggle>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="Compensations">
                      <Card.Body>
                        <CompensationsForm
                          formStage={formStage}
                          setFormStage={setFormStage}
                          setActiveKey={setActiveKey}
                          combinedDetails={combinedDetails}
                          validationError={validationError}
                          setValidationError={setValidationError}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
