import React, { useEffect, useState } from "react";
import Loader from "../../CommonComponent/Loader";
import ProbationPolicyTabCard from "./ProbationPolicyTabCard";
import ProbationAsideList from "./ProbationAsideList";
import MainCard from "../../CommonComponent/MainCard";

const ProbationPolicy = ({
  probationPolicyList,
  selectedProbationPolicy,
  setSelectedProbationPolicy,
  getEditData,
  getProbationPolicy,
  loader,
}) => {
  return (
    <>
      <MainCard
        asideContent={
          loader ? (
            <Loader />
          ) : probationPolicyList?.length > 0 ? (
            probationPolicyList.map((PolicyItem) => (
              <ProbationAsideList
                key={PolicyItem.id}
                selectedProbationPolicy={selectedProbationPolicy}
                setSelectedProbationPolicy={setSelectedProbationPolicy}
                PolicyItem={PolicyItem}
                loader={loader}
              />
            ))
          ) : (
            <div>
              <h6 className="text-white ms-3 mt-3">No Policy Found</h6>
            </div>
          )
        }
        tabContent={
          <ProbationPolicyTabCard
            selectedProbationPolicy={selectedProbationPolicy}
            getEditData={getEditData}
            getProbationPolicy={getProbationPolicy}
            loader={loader}
          />
        }
        asideHeading={"Probation Policies"}
      />
    </>
  );
};

export default ProbationPolicy;
