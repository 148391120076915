import React, { useEffect, useState } from "react";
import kanbanboard from "../../Assets/kanabn-img.png";
import "./Kanbanproject.style.css";
import KanbanModal from "./KanbanModal";
const KanbanBoardProject = () => {
  const [kanbanBoardModal, setkanbanBoardModal] = useState(false);
  const handleChange = () => {
    setkanbanBoardModal(true);
  };
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="d-flex"
                style={{ padding: "10rem", backgroundColor: "#F9F9F9" }}
              >
                <div>
                  <img src={kanbanboard} alt="" />
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(245 242 242)",
                    padding: "1.5rem 2rem",
                  }}
                >
                  <h6 style={{ fontSize: "1.2rem" }}>KANBAN BOARD</h6>
                  <p>
                    Better access to customer information · Faster response
                    times · Improved on-time delivery · Improved order accuracy.
                  </p>
                  <button onClick={handleChange} className="butn_conf">
                    Configure
                  </button>

                  <KanbanModal
                    kanbanBoardModal={kanbanBoardModal}
                    setkanbanBoardModal={setkanbanBoardModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanbanBoardProject;
