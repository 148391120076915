import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select from "react-select";
export default function PurchaseMaterialCosting() {
    return (
        <>

            <div className="page-wrapper">
                <div className="page-content-tab">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="float-end">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="#"></Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <Link to="/ProjectsProjectDetail">ProjectsProjectDetail /</Link>
                                            </li>
                                            <li className="breadcrumb-item active">
                                                Project Purchase Material Costing
                                            </li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Project Purchase Material Costing</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="department-form">
                                            <div className="row border p-3  rounded border-dark">
                                                <div class="col-12">
                                                    <h4 className="fw-bold text-uppercase">ITEM WISE DETAILS </h4>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="item_name" className="form-label">ITEM NAME </label>
                                                        <input
                                                            type="text"
                                                            name="item_name"
                                                            id="item_name"
                                                            className="form-control"
                                                            placeholder="Enter Item Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="qpc" className="form-label">QPC</label>
                                                        <input type="text" name="qpc" id="qpc" placeholder="Enter QPC"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="uom" className="form-label">UOM</label>
                                                        <input type="text" name="uom" id="uom" placeholder="Enter UOM"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div class="col-12">
                                                    <h4 className="fw-bold text-uppercase text-secondary">COACH (1) </h4>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="availability" className="form-label">AVAILABLITY </label>
                                                        <input
                                                            type="text"
                                                            name="availability"
                                                            id="availability"
                                                            className="form-control"
                                                            placeholder="Enter Item AVAILABLITY"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="delivery" className="form-label">DELIVERY </label>
                                                        <input type="text" name="delivery" id="delivery" placeholder="Enter DELIVERY"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="vendor" className="form-label">VENDOR </label>
                                                        <input type="text" name="vendor" id="vendor" placeholder="Enter VENDOR"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="status" className="form-label">STATUS </label>
                                                        <input type="text" name="status" id="status" placeholder="Enter STATUS"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <label class="form-label ">ACTION</label>
                                                    <div>
                                                        <button type="button" class="btn btn-success me-2">+</button>
                                                        <button type="button" class="btn btn-danger" disabled="">-</button>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="department_head"
                                                            className="form-label">
                                                            Department Head
                                                        </label>
                                                        <Select placeholder="Search Department Head"
                                                            name="department_head" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="image" className="form-label">
                                                            Image
                                                        </label>
                                                        <input type="file" className="form-control" id="image" name="image"
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div>
                                                <button type="submit" className="btn btn-primary btn-sm me-3 mt-3">
                                                    Submit
                                                </button>
                                                <button type="button" className="btn btn-de-danger btn-sm me-3 mt-3">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}