import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import axios from "axios";
import { toast } from "react-toastify";
function ProjectCategory() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [projectCategoryList,setProjectCategoryList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
 
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  
  
  function getProjectCategory() {
    const data = {
      user_id,
    };
    axios
      .post(baseUrl + "getprojectcategorylist", data, config)
      .then((response) => {
        setProjectCategoryList(response.data.response.ProjectCategoryList)
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }

  function editData(id,name) {
    navigate("/edit-project-category", {
      state: {
        initialName: name,
        id: id,
      },
    })
  }

  function handleDelete () {
    const data = {
      user_id,
      projectCategoryId:deleteId
    };
    axios
      .post(baseUrl + "deleteprojectcategory", data, config)
      .then((response) => {
        getProjectCategory();
        toast.success(" Deleted Successfully");
      
      })
      .catch((error) => {
        toast.error("Something went wrong.");
      });
  }
  useEffect(() => {
    getProjectCategory();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                     
                      <li className="breadcrumb-item active">
                        Project Category
                      </li>
                    </ol>
                  </div>
                  <h4 className="page-title">Project Category</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      Here the list of all Project Category.{" "}
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                  
                    <li className="list-inline-item">
                      <Link to="/add-project-category">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          Add Category
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h4 className="card-title">Our Project Category</h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="thead-light">
                          <tr>
                            <th>SR.No</th>
                            <th>Category</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectCategoryList.map((element, index) => (
                            <tr key={element.id}>
                              <th scope="row">{++index}</th>
                              <td>{element.name}</td>
                              <td>
                                    <i
                                      onClick={() => editData(element.id,element.name)}
                                      className="las la-pen text-dark font-20 px-1"
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Category"
                                    ></i>
                                    &nbsp;&nbsp;
                                    <Link
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalDelete"
                                    >
                                      <i
                                        onClick={() => setDeleteId(element.id)}
                                        className="las la-trash-alt font-20 px-1 text-dark"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete Category"
                                      ></i>
                                    </Link>
                                  </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-end">
                      <nav aria-label="...">
                        <ul className="pagination pagination-sm mb-0 justify-content-end">
                         
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {/* Modal Start */}
       <div className="modal fade" id="modalDelete">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body py-5 text-center">
              <h4 className="lh-base mb-4">
                Are you sure you want to <br /> delete this record
              </h4>
              <button
                type="button"
                className="btn btn-success me-3"
                onClick={() => handleDelete()}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                // onClick={() => setShowModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}

export default ProjectCategory;
