import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false, // Default loading state is false
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload; // Set the loading state to true or false
    },
  },
});

// Export the action to be used in components 
export const { setLoading } = loaderSlice.actions;

// Export the reducer to be combined in the store
export default loaderSlice.reducer;

// Selector to access the loading state
export const selectLoading = (state) => state.loader.loading;
