import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../PurchaseStyle/PurchaseStyle.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MaterialPurchase from "../../Components/SettingSections/MaterialPurchase";
import PurchaseOrder from "../ManagmentPages/PurchaseOrder/PurchaseOrder";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PoProjectPurchaseList from "./PoProjectPurchaseList";
import { errorsMessage, formatDate } from "../../constant";
import NonTenderPurchaseOrder from "../ManagmentPages/PurchaseOrder/NonTenderPurchaseOrder";

const NonTenderPurchaseOrderParent = () => {
  const [key, setKey] = useState("Non-Tender");
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const location = useLocation();
  const state = location?.state?.data;
  const [loading, setLoading] = useState(false);
  const [purchaseOrderOnProject, setPurchaseOrderOnProject] = useState([]);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;

  function PurchaseOrderOnProjectId() {
    const apiUrl = baseUrl + "getpurchaseordersproject";
    const data = {
      user_id,
      type: "non-tender",
    };
    // setLoading(true);
    axios
      .post(apiUrl, data, config)
      .then((response) => {
        if (response.status === 200) {
          setPurchaseOrderOnProject(
            response?.data?.response?.PurchaseOrdersProject
          );
        }
      })
      .catch((error) => {
        if (error) {
          errorsMessage(error?.response?.status);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    PurchaseOrderOnProjectId();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <h4 className="my-4">Non Tender Purchase Order </h4>
            <div className="card">
              <div className="card-body">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="Non-Tender" title="Generate PO">
                    <NonTenderPurchaseOrder
                      tabKey={key}
                      PurchaseOrderOnProjectId={PurchaseOrderOnProjectId}
                    />
                  </Tab>
                  <Tab eventKey="Update-Non-Tender" title="View/Update PO">
                    <PoProjectPurchaseList
                      tabKey={key}
                      poOrderList={purchaseOrderOnProject}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NonTenderPurchaseOrderParent;
