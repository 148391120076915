import React, { useEffect, useState } from "react";
import "../TenderStyle/TenderStyle.css";
import active from "../DashboardIcons/Tender/Active.svg";
import awarded from "../DashboardIcons/Tender/Awarded.svg";
import rejected from "../DashboardIcons/Tender/Rejected.svg";
import total from "../DashboardIcons/Tender/TotalTenders.svg";
import team from "../DashboardIcons/Tender/Team.svg";
import submit from "../DashboardIcons/Tender/Submit.svg";
import DashboardProgress from "../../Components/DashboardProgress";
import { IoSquare } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { getTenderDashboard, getUpcomingTenders } from "../../redux/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDropdowns } from "../../redux/features/allDropdownSlice";
import { Chart } from "react-google-charts";
import { errorsMessage } from "../../constant";
import { setLogout } from "../../redux/features/authSlice";
import { useDispatch } from "react-redux";
import DashboardAlert from "../TenderModal/DashboardAlert";
import useURLFilters from "../useTenderUrlFilter";
import { faTruckField } from "@fortawesome/free-solid-svg-icons";

const TenderDashboard = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [tenderData, setTenderData] = useState({});
  const [upcomingTenders, setUpcomingTenders] = useState([]);
  const [raTenders, setRaTenders] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allDropdowns = useSelector(selectDropdowns);
  const departmentUsers = allDropdowns?.userList?.filter(
    (user) => user.departmentName === "Tender"
  );
  const [filters, setFilters] = useURLFilters();

  function navigateToTender(status) {
    if (status === "Bidded" || status === "Not Bidded") {
      const newSearch = setFilters({
        ...filters,
        biddingStatus: status,
      });
      navigate(`/tenders?${newSearch}`, {
        state: { status: faTruckField },
      });
    } else if (status === "Awarded" || status === "Not Awarded") {
      const newSearch = setFilters({
        ...filters,
        awardedStatus: status,
      });
      navigate(`/tenders?${newSearch}`, {
        state: { status: true },
      });
    }
  }

  function getData() {
    getTenderDashboard({ user_id })
      .then((response) => {
        setTenderData(response?.data?.response.tenderDashboard);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
        if (err?.response?.status === 401) {
          dispatch(setLogout());
          navigate("/");
        }
      });

    getUpcomingTenders({ user_id })
      .then((response) => {
        setUpcomingTenders(response?.data?.response?.tendersList);
        setRaTenders(response?.data?.response?.ratendersList);
      })
      .catch((err) => {
        setShowAlertModal(false);
      });
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }
  let awardedTenders = tenderData?.awarded?.count_awarded_records;
  let notAwardedTenders = tenderData?.notAwarded?.count_awarded_records;
  let biddedTenders = tenderData?.bidded?.count_awarded_records;
  let notBiddedTenders = tenderData?.notBidded?.count_awarded_records;

  const data = [
    ["Status", "Number of Tenders"],
    ["Awarded", awardedTenders],
    ["Not Awarded", notAwardedTenders],
    ["Bidded", biddedTenders],
    ["Not Bidded", notBiddedTenders],
  ];

  const colors = ["#a8e28d", "red", "#fdec8b", "#dcdfd8"];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    upcomingTenders.length || raTenders.length
      ? setShowAlertModal(true)
      : setShowAlertModal(false);
  }, [upcomingTenders, raTenders]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab pb-3">
          <div className="container-fluid tender-dashboard">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active"> Dashboard</li>
                    </ol>
                  </div>
                  <h4 className="page-title fw-bold mb-3"> DASHBOARD </h4>
                  <div className="row">
                    <div className="col-md-4 h-100">
                      <div className="card heading-card">
                        <div className="card-body mb-1">
                          <div className="tender-heading">
                            <h3 className="">Tender</h3>
                            <h3 className="mb-0">Dashboard</h3>
                          </div>
                          <p>
                            Better access to customer information · Faster
                            response times · Improved on-time delivery ·
                            Improved order accuracy.
                          </p>
                          <NavLink to="/tenders/add-tender">
                            <button className="btn btn-danger mb-1 fw-bold">
                              Add New Tender
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div
                          className="col-md-4"
                          onClick={() => navigate("/tenders")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Total Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end icon-img">
                                  <h3
                                    style={{ color: "#6560F0" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.totalTender
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.totalTender
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <div>
                                    <img src={total} alt="Icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Awarded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Awarded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#06B48A" }}
                                    className="fw-bold mt-0"
                                  >
                                    {tenderData?.awarded?.count_awarded_records}
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.awarded
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={awarded} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Not Awarded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Not Awarded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#D90000" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.notAwarded
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.notAwarded
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={rejected} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Bidded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">
                                    Bidded Tenders
                                  </h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#E8BE00" }}
                                    className="fw-bold mt-0"
                                  >
                                    {tenderData?.bidded?.count_awarded_records}
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.bidded?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={submit} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-4"
                          onClick={() => navigateToTender("Not Bidded")}
                        >
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">Not Bidded Tenders</h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#0041D6" }}
                                    className="fw-bold mt-0"
                                  >
                                    {
                                      tenderData?.notBidded
                                        ?.count_awarded_records
                                    }
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3">
                                  {/* <p className="m-0">Amount</p>
                                  <p className="fw-bold m-0">
                                    {formatNumber(
                                      tenderData?.notBidded
                                        ?.total_advertised_value
                                    )}
                                  </p> */}
                                </div>
                                <div className="col-5 text-end">
                                  <img src={active} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 h-100">
                          <div className="card detail-card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-7 tender-type">
                                  <h5 className="fw-bold mt-0">Total Team</h5>
                                </div>
                                <div className="col-5 text-end">
                                  <h3
                                    style={{ color: "#00BFFC" }}
                                    className="fw-bold mt-0"
                                  >
                                    {departmentUsers?.length}
                                  </h3>
                                </div>
                                <div className="col-7 tender-type pt-3"></div>
                                <div className="col-5 text-end">
                                  <img src={team} alt="Icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="card">
                        <div className="card-body">
                          <DashboardProgress />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card pie-chart">
                        <div className="card-body">
                          <span className="chart-title">
                            Bidding Status Ratio
                          </span>
                          <div className="status-color">
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#a8e28d",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Awarded</p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "red",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">
                                Not Awarded
                              </p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#fdec8b",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Bidded</p>
                            </div>
                            <div className="">
                              <IoSquare
                                style={{
                                  color: "#dcdfd8",
                                  height: "1rem",
                                  width: "1rem",
                                  display: "inline",
                                  marginRight: ".3rem",
                                }}
                              />
                              <p className="m-0 d-inline font-10">Not Bidded</p>
                            </div>
                          </div>
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="310px"
                            data={data}
                            options={{
                              colors: colors,
                              pieHole: 0.5,
                              is3D: false,
                              titleTextStyle: {
                                color: "black",
                                fontSize: 14,
                              },
                              legend: {
                                position: "none",
                              },

                              pieSliceTextStyle: {
                                color: "black",
                              },
                            }}
                          />

                          {/* <PieChart
                            lineWidth={30}
                            radius={40}
                            rounded={true}
                            style={{
                              fill: "white",
                              fontSize: "5px",
                              textAnchor: "middle",
                              fontWeight: 600,
                            }}
                            label={({ dataEntry }) => `${dataEntry.value}%`}
                            labelStyle={{ fill: "black" }}
                            segmentsStyle={{
                              transition: "stroke .3s",
                              cursor: "pointer",
                            }}
                            labelPosition={85}
                            data={[
                              {
                                title: "Awarded",
                                value: award,
                                color: "#a8e28d",
                              },

                              {
                                title: "Not Awarded",
                                value: notAward,
                                color: "#FA7070DB",
                              },
                              {
                                title: "Bidded",
                                value: bidded,
                                color: "#fdec8b",
                              },
                              {
                                title: "Not Bidded",
                                value: notBidded,
                                color: "#dcdfd8",
                              },
                            ]}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardAlert
        show={showAlertModal}
        setShow={setShowAlertModal}
        upcomingTenders={upcomingTenders}
        raTenders={raTenders}
      ></DashboardAlert>
    </>
  );
};

export default TenderDashboard;
