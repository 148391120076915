import React, { useEffect, useState } from "react";
import ShowError from "../organisationStructure/OrganizationComponents/ShowError";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { selectDropdowns } from "../../../../src/redux/features/allDropdownSlice";

import "../../HrDepartment.scss";
import { values } from "lodash";
import { jobDetailSchema } from "../AddEmployeeSchema";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobTitle } from "../../../redux/features/Hr/JobTitleSlice";
import { getjobTitle } from "../../../redux/features/Hr/JobTitleSlice";
import {locationListApi} from "../organisationStructure/OrganizationComponents/organizationStructureApi"
import {fetchLegalEntity,getLegalEntity} from "../../../redux/features/Hr/LegalEntitySlice"
import {
  getProbationPolicy,
  fetchProbationPolicy,
} from "../../../redux/features/Hr/ProbationPolicySlice";
import {
  fetchTimeType,
  getTimeType,
} from "../../../redux/features/Hr/TimeTypeSlice";
import { getUsers } from "../../hrApi";
import { fetchBusinessUnit, getbusinessUnit } from "../../../redux/features/Hr/BusinessUnitslice";
const JobDetailForm = ({
  formStage,
  setFormStage,
  setActiveKey,
  setJobDetail,
  validationError
}) => {
  const dispatch = useDispatch(); 
  const[userList,setUserList] = useState([])
  const[location,setLocation] = useState([])
  const allDropdowns = useSelector(selectDropdowns);
  const jobTitleList = useSelector(getjobTitle);
  const legalEntityList = useSelector(getLegalEntity)
  const businessUnitList = useSelector(getbusinessUnit)
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState("");

  const departments = allDropdowns?.departmentList;
  const probationPolicyList = useSelector(getProbationPolicy);
  const timeTypeList = useSelector(getTimeType);

  const getUserList = async () => {
    const reqpayload = { accessType: "view", search: "", user_id: user_id };
    try {
      const response = await getUsers(reqpayload);
     
      setUserList(response?.data?.response?.userList)
    } catch (err) {
    
    }
  };
const getLocation =async()=>{
  try{
const response = await locationListApi()
console.log(response)
setLocation(response?.data?.response?.data)
  }
  catch(error){}

}
  useEffect(() => {
    getUserList();
    getLocation()
  }, []);
  const initialValues = {
    joining_date: "",
    legal_entity:"",
    business_unit:"",
    job_title: "",
    department_id: "",
    location: "",
    reporting_person: "",
    time_type: "",
    probation_policy: "",
    notice_period: "",
  };
  const {
    values,
    errors,
    touched,
    handleChange,

    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: jobDetailSchema,
    onSubmit: (values) => {
      setJobDetail(values);
      setFormStage(3);
      setActiveKey("Work Details");
    },
  });
  const handleFocus = (event) => {
    setIsFocused(event.target.name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };

  useEffect(() => {
    dispatch(fetchJobTitle(dispatch));
    dispatch(fetchProbationPolicy(dispatch));
    dispatch(fetchTimeType(dispatch));
    dispatch(fetchLegalEntity(dispatch))
    dispatch(fetchBusinessUnit(dispatch))
  }, [dispatch]);
  useEffect(() => {
    setJobDetail(values);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row justified-content-between mx-2">
        <div className="col-5">
          <label
            htmlFor="joining_date"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "joining_date" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Joining Date
          </label>
          <div
            className={`${
              isFocused === "joining_date"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <input
              type="date"
              name="joining_date"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.joining_date}
              onChange={handleChange}
            />
            <ShowError
              touched={touched.joining_date}
              message={errors.joining_date}
              validationError={validationError?.joining_date}
            />
          </div>
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <label
            htmlFor="legal_entity"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "legal_entity" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
           Legal Entity
          </label>
          <div
            className={`${
              isFocused === "legal_entity"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="legal_entity"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.legal_entity}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {legalEntityList?.map((legalEntity) => (
                <option value={legalEntity?.id}>{legalEntity?.entity_name}</option>
              ))}
            </select>
          </div>
          <ShowError touched={touched.legal_entity} message={errors.legal_entity} 
           validationError={validationError?.legal_entity}/>
        </div>
        <div className="col-5">
          <label
            htmlFor="business_unit"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "business_unit" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
         Business Unit
          </label>
          <div
            className={`${
              isFocused === "business_unit"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="business_unit"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.business_unit}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {businessUnitList?.map((unit) => (
                <option value={unit?.id}>{unit?.name}</option>
              ))}
            </select>
          </div>
          <ShowError touched={touched.business_unit} message={errors.business_unit} 
           validationError={validationError?.business_unit}/>
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <label
            htmlFor="job_title"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "job_title" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Job Title
          </label>
          <div
            className={`${
              isFocused === "job_title"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="job_title"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.job_title}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {jobTitleList?.map((job) => (
                <option value={job?.id}>{job?.title}</option>
              ))}
            </select>
          </div>
          <ShowError touched={touched.job_title} message={errors.job_title} 
           validationError={validationError?.job_title}/>
        </div>
        <div className="col-5">
          <label
            htmlFor="department"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "department" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Department
          </label>
          <div
            className={`${
              isFocused === "department"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="department_id"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.department_id}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {departments?.map((department) => (
                <option value={department?.id}>{department?.name}</option>
              ))}
            </select>
            <ShowError
              touched={touched.department_id}
              message={errors.department_id}
              validationError={validationError?.department_id}
            />
          </div>
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <label
            htmlFor="reporting_person"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "reporting_person" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Reporting Manager
          </label>

          <div
            className={`${
              isFocused === "reporting_person"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            {/* <input
              type="text"
              name="reporting_person"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.reporting_person}
              onChange={handleChange}
              placeholder="Enter Reporting manager"
            /> */}
            <select
               name="reporting_person"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.reporting_person}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {userList?.map((user)=><option value={user?.id}>{user?.name}</option>)
           }
            </select>
            <ShowError
              touched={touched.reporting_person}
              message={errors.reporting_person}
              validationError={validationError?.reporting_person}
            />
          </div>
        </div>
        <div className="col-5">
          <label
            htmlFor="time_type"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "time_type" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Time Type
          </label>
          <div
            className={`${
              isFocused === "time_type"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="time_type"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.time_type}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
           {timeTypeList?.map((time)=><option  value={time?.id}>{time?.type}</option>)}
            </select>
            <ShowError touched={touched.time_type} message={errors.time_type}
             validationError={validationError?.time_type} />
          </div>
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <label
            htmlFor="location"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "location" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Location
          </label>
          <div
            className={`${
              isFocused === "location"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="location"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.location}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
            {location?.map((location)=><option value={location?.id}>{location?.name}</option>)}
            </select>
            <ShowError touched={touched.location} message={errors.location}   validationError={validationError?.location}/>
          </div>
        </div>
        <div className="col-5">
          <label
            htmlFor="probation_policy"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "probation_policy" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Probation Policy
          </label>
          <div
            className={`${
              isFocused === "probation_policy"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="probation_policy"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.probation_policy}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              {probationPolicyList?.map((policy) => (
                <option value={policy?.id}>{policy?.name}</option>
              ))}
            </select>
            <ShowError
              touched={touched.probation_policy}
              message={errors.probation_policy}
              validationError={validationError?.probation_policy}
            />
          </div>
        </div>
        <div className="col-2"></div>
        <div className="col-5">
          <label
            htmlFor="notice_period"
            className={`form-label fw-bold font-12 font-weight-500 ${
              isFocused === "notice_period" ? "text-danger" : "text-black"
            }`}
            style={{
              paddingLeft: ".75rem",
              marginTop: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Notice Period
          </label>
          <div
            className={`${
              isFocused === "notice_period"
                ? "add_emp_input_active"
                : "add_emp_input"
            }`}
          >
            <select
              name="notice_period"
              style={{ paddingLeft: ".75rem" }}
              className="form-control form-control-sm border-secondary"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.notice_period}
              onChange={handleChange}
              placeholder="Select"
            >
              <option>Select</option>
              <option>1 Month</option>
              <option>2 Month</option>
            </select>
            <ShowError
              touched={touched.notice_period}
              message={errors.notice_period}
              validationError={validationError?.notice_period}
            />
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-7"></div>
        <div className="col-5" style={{ marginLeft: "auto", width: "auto" }}>
          <button type="submit" className="hr btn btn-primary mx-2">
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default JobDetailForm;
