import React from "react";

function CommonTable({ tableHeading }) {
  return (
    <thead className="thead-light">
      <tr style={{ borderTop: "1px solid black" }}>
        {tableHeading?.map((element, index) => {
          return (
            <th key={index}>
              <p
                className="fw-bold font-16 m-0"
                style={{ minWidth: element.width, maxWidth: element.width }}
              >
                {element.name}
              </p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default CommonTable;
