import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import EditPrimaryDetail from "./EditPrimaryDetail";
import EditContactDetails from "./EditContactDetails";
import EditEmployeeAddresses from "./EditEmployeeAddresses";
import EditEmployeeEducation from "./EditEmployeeEducation";
import "../../HrDepartment.scss";
const EmployeeProfile = ({ data,employeeDetail }) => {
  const [open, setOpen] = React.useState(false);
  
  const [openContactDetail, setOpenContactDetail] = React.useState(false);
  const [openEmployeeAddress, setOpenEmployeeAddress] = React.useState(false);
  const [openEmployeeEducation, setOpenEmployeeEducation] =
    React.useState(false);
  const handleOpen = () => {setOpen(true)

  };
  const handleClose = () => setOpen(false);
  const handleContact = () => setOpenContactDetail(true);
  const handleCloseContact = () => setOpenContactDetail(false);
  const handleEmpAddress = () => setOpenEmployeeAddress(true);
  const handleCloseEmpAddress = () => setOpenEmployeeAddress(false);
  const handleEmpEducation = () => setOpenEmployeeEducation(true);
  const handleCloseEmpEducation = () => setOpenEmployeeEducation(false);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="accordian-card">
            <div className="card accordian-card_fix">
              <div className="d-flex justify-content-between align-items-center ms-4 me-2">
                <h4 className="detail-card-heading">Primary Details</h4>
                <FaEdit onClick={handleOpen} />
              </div>
              {/* <hr></hr> */}
              <div className="border-top ps-4 pt-3">
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Name: </div>
                  <div className="card-right-value col-6">
                    {data?.employee_name ? data?.employee_name : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Gender:</div>
                  <div className="card-right-value col-6">
                    {data?.gender ? data?.gender : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Date Of Birth:{" "}
                  </div>
                  <div className="card-right-value col-6">
                    {data?.dob ? data?.dob : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Nationality:</div>
                  <div className="card-right-value col-6">
                    {data?.nationality ? data?.nationality : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Physically Handicaped:{" "}
                  </div>
                  <div className="card-right-value col-6">
                    {data?.is_handicaped ? data?.is_handicaped : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Marital Status:{" "}
                  </div>
                  <div className="card-right-value col-6">
                    {data?.marital_status ? data?.marital_status : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="accordian-card">
            <div className="card accordian-card_fix">
              <div className="d-flex justify-content-between align-items-center ml_4_left_right me-2">
                <h4 className="detail-card-heading">Contact Details</h4>
                <FaEdit onClick={handleContact} />
              </div>

              <div className=" border-top pt-3 ml_4_left_right">
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Work Email: </div>
                  <div className="card-right-value col-6">
                    {data?.work_email ? data?.work_email : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Personal Email:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.personal_email ? data?.personal_email : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Work Phone: </div>
                  <div className="card-right-value col-6">
                    {data?.work_phone ? data?.work_phone : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Personal Phone:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.personal_phone ? data?.personal_phone : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="accordian-card">
            <div className="card accordian-card_fix">
              <div className="d-flex justify-content-between align-items-center ms-4 me-2">
                <h4 className="detail-card-heading">Addresses</h4>
                <FaEdit onClick={handleEmpAddress} />
              </div>
              <div className=" border-top pt-3 ms-4">
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Current Address:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.current_address ? data?.current_address : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Permanent Address:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.permanent_address ? data?.permanent_address : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Emergency Contact:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.emergency_contact ? data?.emergency_contact : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="accordian-card">
            <div className="card accordian-card_fix">
              <div className="d-flex justify-content-between align-items-center ms-4 me-2">
                <h4 className="detail-card-heading">Education</h4>
                <FaEdit onClick={handleEmpEducation} />
              </div>

              <div className=" border-top pt-3 ms-4">
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    College/University:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.college_university ? data?.college_university : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">Degree:</div>
                  <div className="card-right-value col-6">
                    {data?.degree ? data?.degree : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Branch / Specialization:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.branch_specialization
                      ? data?.branch_specialization
                      : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Year of Joining:
                  </div>
                  <div className="card-right-value col-6">10 June, 2016</div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    Year of Completion:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.complete_date ? data?.complete_date : "--"}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="card-right-heading col-6">
                    CGPA / Percentage:
                  </div>
                  <div className="card-right-value col-6">
                    {data?.cgpa_percentage ? data?.cgpa_percentage : "--"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditPrimaryDetail handleClose={handleClose} open={open} data={data} employeeDetail={employeeDetail} />
      <EditContactDetails
        handleClose={handleCloseContact}
        open={openContactDetail}
        employeeId ={data?.id}
        data={data} employeeDetail={employeeDetail}
      />
      <EditEmployeeAddresses
        handleClose={handleCloseEmpAddress}
        open={openEmployeeAddress}
        data={data} employeeDetail={employeeDetail}
      />
      <EditEmployeeEducation
        handleClose={handleCloseEmpEducation}
        open={openEmployeeEducation}
        data={data} employeeDetail={employeeDetail}
      />
    </>
  );
};

export default EmployeeProfile;
