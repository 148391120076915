import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';

function OrganizationStructureAsideList({
  organizationDataList,
  setOrgDetail,
  orgDetail,
  image,
  name,
  employee
}) {
  // organizationDataList={locationDetail}
  //             key={index}
  //             setOrgDetail={setLocation}
  //             orgDetail={location}
  return (
    <>
 
      <div
        className="d-flex gap-3 mb-1 align-items-center p-3 "
        onClick={() => setOrgDetail(organizationDataList)}
        style={{
          background:
            orgDetail?.id === organizationDataList?.id
              ? "#30384E"
              : null,
          cursor: "pointer",
          borderRadius: ".5rem"
        }}
      >
        <div>
          <img
            src={image}
            className="rounded-circle"
            style={{ height: "3rem", width: "3rem" }}
          />
        </div>
        <div>
          <h5 className="text-white fw-600 mt-0">{name}</h5>
          <h6 className="text-white fw-600 mb-0">{employee} Employees</h6>
        </div>
      </div>
    </>
  );
}

export default OrganizationStructureAsideList;
