import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../Common/CommonTable";
import CommonTd from "../../Tender/Common/CommonTd";
import { Link, useNavigate } from "react-router-dom";
import { getPartyMasterList } from "../purchaseApi";
import { errorsMessage } from "../../constant";
import { FaRegEdit } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import usePagination from "../Common/usePurchasePagination";
import { IoIosSearch } from "react-icons/io";
import usePurchaseURLFilters from "../Common/usePurchaseUrlFilter";

function VendorListPage() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const [filters, setFilters] = usePurchaseURLFilters();
  const {
    data: partyMasterList,
    loading,
    hasMore,
    loadMore,
    resetPagination,
  } = usePagination(
    getPartyMasterList,
    user_id,
    "PartyMasterList",
    filters.search
  );
  const tableBodyRef = useRef(null);

  function editData(element) {
    navigate("/vendor-list/edit-vendor", { state: { editData: element } });
  }

  const handleScroll = () => {
    const tableBody = tableBodyRef.current;
    if (
      tableBody.scrollTop + tableBody.clientHeight >=
        tableBody.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);
  const tableHeading = [
    {
      name: "SR No.",
      width: "5rem",
    },
    {
      name: "Main ID",
      width: "7rem",
    },
    {
      name: "Party Code",
      width: "7rem",
    },
    {
      name: "Party Name ",
      width: "20rem",
    },
    {
      name: "Status ",
      width: "10rem",
    },
    {
      name: "Opening Balance ",
      width: "14rem",
    },
    {
      name: "Credit Limit Days ",
      width: "14rem",
    },
    {
      name: "Credit Limit Rs ",
      width: "14rem",
    },
    {
      name: "PAN No. ",
      width: "14rem",
    },

    {
      name: "GST No. ",
      width: "14rem",
    },

    {
      name: "CST No. ",
      width: "14rem",
    },
    {
      name: "Edit ",
      width: "3rem",
    },
  ];
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      const newFilters = {
        ...filters,
        search: search,
      };
      setFilters(newFilters);
      navigate(`?search=${newFilters.search}`);
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [search]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-6">
              <h4 className="page-title">VENDER LIST</h4>
            </div>
            <div className="col-sm-4 text-end">
              <div className="postion-relative">
                <input
                  className="form-control w-75 ms-auto"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch
                  size={30}
                  className="position-absolute top-0 end-0 mt-1 me-2"
                  style={{ color: "ef4d56" }}
                />
              </div>
            </div>
            <div className="col-sm-auto text-end">
              <Link to="/vendor-list/add-new-vendor">
                <button className="btn btn-danger">Add New Vendor</button>
              </Link>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading}></CommonTable>
                  <tbody ref={tableBodyRef}>
                    {partyMasterList.map((element) => (
                      <tr key={element.id}>
                        <CommonTd width="5rem" value={element?.id}></CommonTd>
                        <CommonTd width="7rem" value={element?.id}></CommonTd>
                        <CommonTd
                          width="7rem"
                          value={element?.party_code}
                        ></CommonTd>
                        <CommonTd
                          width="20rem"
                          value={element?.party_name}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.status}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.opening_balance}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.credit_limit_days}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.credit_limit}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.pan_no}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.gst_no}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.cts_no}
                        ></CommonTd>
                        <td>
                          <p
                            style={{
                              minWidth: "3rem",
                              maxWidth: "3rem",
                              margin: "auto",
                              padding: "auto",
                              cursor: "pointer",
                            }}
                            className="text-center"
                          >
                            <FaRegEdit
                              className="text-dark font-20"
                              style={{ margin: "auto" }}
                              onClick={() => editData(element)}
                            />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {loading && (
                    <div className="d-flex align-items-center justify-content-center pb-5">
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorListPage;
