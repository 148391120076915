import React from "react";

function ShowError({ touched, message,validationError }) {
  if(validationError){
    return <p className="text-danger m-0">{validationError}</p>;}
  else{
  return <p className="text-danger m-0">{touched && message}</p>;}
}

export default ShowError;
