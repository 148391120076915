import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function ImageAvatars({name}) {

  return (
    <Stack direction="row" spacing={2}>
      <Avatar alt="Remy Sharp" src="../Organisation/Assets/Group.png" />
      < h6 className ='pt-2 fw-bold'> { name}</h6> 
    </Stack>
  );
}