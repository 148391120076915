import React, { useCallback, useEffect, useState } from "react";
import {
  getPartyMasterList,
  getStoreMasterList,
  getVendorDropDown,
} from "../../Purchase/purchaseApi";
import { errorsMessage } from "../../constant";
import { debounce } from "lodash";
import Select from "react-select";

function
  BillingDetails({
    values,
    handleChange,
    setFieldValue,
    setValues,
    stateDropdown,
    state,
    errors,
    touched,
    serverSideValidation,
    deliveryChallanType
  }) {
  const [vendorDropdown, setVendorDropdown] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const customStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "red", // Change loader color here
    }),

    loadingIndicator: (provided) => ({
      ...provided,
      color: "red", // Change loader color here
    }),
  };
  async function getVendorMasterDropDown(term) {
    setLoading(true);
    try {
      let res;
      if (deliveryChallanType !== "For Internal Store") {
        res = await getPartyMasterList({ user_id, party_name: term });
      } else {
        res = await getStoreMasterList({ user_id, search: term });
      }

      // Check response structure
      if (deliveryChallanType !== "For Internal Store") {
        setVendorDropdown(res?.data?.response?.PartyMasterList || []);
      } else {
        setVendorDropdown(res?.data?.response?.CityMasterList || []);
      }
    } catch (err) {
      console.error("Error fetching vendor data:", err); // Improved error logging
      errorsMessage(err?.response?.status || "Unknown error");
    } finally {
      setLoading(false);
    }
  }

  const debouncedFetchItemMasterData = useCallback(
    debounce((term) => {
      getVendorMasterDropDown(term);
    }, 800),
    [user_id]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchItemMasterData(searchTerm);
    }
  }, [searchTerm, debouncedFetchItemMasterData]);

  function handleUpdateBillingDetail(value) {
    setValues({
      ...values,
      billing_party_id: value?.id,
      billing_gstin: value?.gst_no || "",
      billing_address: deliveryChallanType === "For Internal Store" ? value?.store_address : value?.address || "",
      billing_state_id: value?.state || "",
      billing_pincode: value?.pin_code || "",
    });
  }
  useEffect(() => {
    if (state?.detail?.id) {
      getVendorMasterDropDown(state?.detail?.party_name);
      setFieldValue("billing_party_id", values?.billing_party_id);
    }
  }, [state]);

  return (
    <>
      <div className="row">
        {deliveryChallanType !== "For Internal Store" ?
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Party Name
            </label>
            <Select
              placeholder="Select Item from Name"
              className="form-select-control border-dark"
              options={[
                { id: "", party_name: "Select an Party" },
                ...vendorDropdown,
              ]}
              getOptionLabel={(vendorDropdown) => vendorDropdown["party_name"]}
              getOptionValue={(vendorDropdown) => vendorDropdown["id"]}
              isLoading={loading}
              value={
                vendorDropdown.find(
                  (option) => option.id === values.billing_party_id
                ) || { id: "", party_name: "Select an Party" }
              }
              onChange={(value) => {
                handleUpdateBillingDetail(value);
              }}
              onInputChange={(value) => setSearchTerm(value)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                  ...theme.colors,
                  primary25: "lightgrey",
                  primary: "lightgrey",
                },
              })}
              styles={customStyles}
            />
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.billing_party_id
                : touched.billing_party_id && errors.billing_party_id
                  ? errors.billing_party_id
                  : null}
            </p>
          </div> :
          <div className="col-3">
            <label htmlFor="" className="form-label fw-bold font-16">
              Store Name
            </label>
            <Select
              placeholder="Select Item from Name"
              className="form-select-control border-dark"
              options={[
                { id: "", party_name: "Select an item" },
                ...vendorDropdown,
              ]}
              getOptionLabel={(vendorDropdown) => vendorDropdown["store_name"]}
              getOptionValue={(vendorDropdown) => vendorDropdown["id"]}
              isLoading={loading}
              value={
                vendorDropdown.find(
                  (option) => option.id === values.billing_party_id
                ) || { id: "", store_name: "Select an Store" }
              }
              onChange={(value) => {
                handleUpdateBillingDetail(value);
              }}
              onInputChange={(value) => setSearchTerm(value)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                  ...theme.colors,
                  primary25: "lightgrey",
                  primary: "lightgrey",
                },
              })}
              styles={customStyles}
            />
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.billing_party_id
                : touched.billing_party_id && errors.billing_party_id
                  ? errors.billing_party_id
                  : null}
            </p>
          </div>}
        {deliveryChallanType !== "For Internal Store" ?
          <>
            <div className="col-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                GSTIN
              </label>
              <input
                className="form-control"
                placeholder="Enter GSTIN"
                value={values?.billing_gstin}
                readOnly
              />
              <p className="text-danger m-0">
                {serverSideValidation
                  ? serverSideValidation?.billing_gstin
                  : touched.billing_gstin && errors.billing_gstin
                    ? errors.billing_gstin
                    : null}
              </p>
            </div>
            <div className="col-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                State
              </label>
              <select
                id="dropdown"
                className="form-control"
                readOnly
                value={values?.billing_state_id}
              >
                <option value={""}>Select </option>
                {stateDropdown.map((element) => (
                  <option key={element.id} value={element.id}>
                    {element.state_name}
                  </option>
                ))}
              </select>
              <p className="text-danger m-0">
                {serverSideValidation
                  ? serverSideValidation?.billing_state_id
                  : touched.billing_state_id && errors.billing_state_id
                    ? errors.billing_state_id
                    : null}
              </p>
            </div>
            <div className="col-3">
              <label htmlFor="" className="form-label fw-bold font-16">
                PinCode
              </label>
              <input
                className="form-control"
                placeholder="Enter PinCode"
                readOnly
                value={values?.billing_pincode}
              />
              <p className="text-danger m-0">
                {serverSideValidation
                  ? serverSideValidation?.billing_pincode
                  : touched.billing_pincode && errors.billing_pincode
                    ? errors.billing_pincode
                    : null}
              </p>
            </div>
          </> : <div className="col-8">
            <label htmlFor="" className="form-label fw-bold font-16">
              Address
            </label>
            <input
              className="form-control"
              placeholder="Enter Address"
              readOnly
              value={values?.billing_address}
            />
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.billing_address
                : touched.billing_address && errors.billing_address
                  ? errors.billing_address
                  : null}
            </p>
          </div>}
      </div>
      {deliveryChallanType !== "For Internal Store" ?
        <div className="row mt-3">
          <div className="col-8">
            <label htmlFor="" className="form-label fw-bold font-16">
              Address
            </label>
            <input
              className="form-control"
              placeholder="Enter Address"
              readOnly
              value={values?.billing_address}
            />
            <p className="text-danger m-0">
              {serverSideValidation
                ? serverSideValidation?.billing_address
                : touched.billing_address && errors.billing_address
                  ? errors.billing_address
                  : null}
            </p>
          </div>
        </div> : null}
    </>
  );
}

export default BillingDetails;
