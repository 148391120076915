import React from "react";

function BackOfficeTableTh({ value, width }) {
  return (
    <>
      <th style={{ minWidth: width, maxWidth: width }}>
        <p className="fw-bold font-18">{value}</p>
      </th>
    </>
  );
}

export default BackOfficeTableTh;
