import React from "react";
import OrgDepartmentTabs from "./OrgDepartmentTabs";
import OrganizationStructureHeaderName from "../OrganizationComponents/OrganizationStructureHeaderName";

function OrgDepartmentHeader({ department }) {
  return (
    <div>
      <OrganizationStructureHeaderName OrgHeaderDetail={department} />
      <OrgDepartmentTabs department={department} />
    </div>
  );
}

export default OrgDepartmentHeader;
