import React, { useEffect, useState } from "react";
import LotsLogsTable from "./LotsLogsTable";
import { useParams } from "react-router-dom";
import { getLotsLogsList } from "../../../redux/api";
import { errorsMessage } from "../../../constant";
import LotsLogsDetailPageBreadCrums from "./LotsLogsDetailPageBreadCrums";
import { Skeleton } from "@mui/material";

function ViewLotsLogs() {
  const params = useParams();
  const [logsListBefore, setLogsListBefore] = useState([]);
  const [loader, setLoader] = useState(false)

  function lotsLogsList() {
    setLoader(true)
    getLotsLogsList({ project_id: params.id })
      .then((res) => {
        setLogsListBefore(res.data.response.lotAmendmentLogsList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoader(false)
        });
  }
  useEffect(() => lotsLogsList(), []);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab ">
        <div className="container-fluid ">
          <LotsLogsDetailPageBreadCrums id={params.id} />
          <div className="row">
            {logsListBefore?.map((beforeData, index) => (
              <>
                <div key={index} className="col-md-6">
                  {loader ? <Skeleton variant="rectangular" width={210} height={118} />
                    : <LotsLogsTable data={beforeData.before} text={"Previous"} />
                  }
                </div>
                <div key={index} className="col-md-6">
                  <LotsLogsTable data={beforeData.after} text={"Update"} />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewLotsLogs;
