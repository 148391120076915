// slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: "2023-2024",
};

const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { addItem } = dateSlice.actions;

export default dateSlice.reducer;
