import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Research1 from "../../../Assets/research1.png";
import EditIcon from "../Assets/Group.png";
import EmployeeSlip from "./EmployeePay";
import "../../HrDepartment.scss"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EmployeeFinance = () => {
  const [value, setValue] = useState("SUMMARY");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }} className="finance10PX">
        <TabContext value={value}>
          {/* Tab headers in one row */}
          <div className="row">
            <div
              className="employee-Accordian"
              style={{
                backgroundColor: "white",
                width: "97.5%",
                marginLeft: "0.625rem",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                className="custom-tablist"
              >
                <Tab label="SUMMARY" value="SUMMARY" />
                <Tab label="PAY" value="PAY" />
                <Tab label="MANAGE TAX" value="Job" />
              </TabList>
            </div>
          </div>

          {/* Tab content in another row */}
          <div className="row">
            <TabPanel value="SUMMARY">
              <div className="card ">
                <div className="assignedAssets">Payroll Summary</div>
                <hr />
                <div className="table_24">
                  <table className="table border0px">
                    <thead className="thead-light">
                      <tr className="borderBottomOpx">
                        <th scope="col" className="headingStyle">
                          Last Procecessed Date
                        </th>
                        <th scope="col" className="headingStyle">
                          Working Days
                        </th>
                        <th scope="col" className="headingStyle">
                          Loss of Pay
                        </th>
                        <th scope="col" className="headingStyle">
                          Payslip
                        </th>
                      </tr>
                    </thead>
                    <tbody className="boxShadowNone">
                      <tr>
                        <td className="cellStyle">Mark</td>
                        <td className="cellStyle">Otto</td>
                        <td className="cellStyle">@mdo</td>
                        <td className="headingStylePayslip">
                          <u>Payslip</u>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="card bordeRadius10px">
                    <div className="justifyContentSpace">
                    <div className="assignedAssets">Payment Information</div>
                    <div className="topLeftRight24px"><img src={EditIcon}/></div>
                    </div>
                    <hr />
                    <div className="row padding_24px">
                      <div className="col paymentMode">Payment Mode</div>
                      <div className="col bankTransfer">Bank Transfer</div>
                    </div>
                    <div className="row padding_24px">
                      <div className="col paymentMode">Payment Mode</div>
                      <div className="col bankTransfer">Bank Transfer</div>
                    </div>
                    <div className="row padding_24px">
                      <div className="col paymentMode">Payment Mode</div>
                      <div className="col bankTransfer">Bank Transfer</div>
                    </div>
                    <div className="row padding_24px">
                      <div className="col paymentMode">Payment Mode</div>
                      <div className="col bankTransfer">Bank Transfer</div>
                    </div>
                    <div className="row padding_24px marginBottom20px">
                      <div className="col paymentMode">Payment Mode</div>
                      <div className="col bankTransfer">Bank Transfer</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="card bordeRadius10px">
                    <div className="assignedAssets">PF Account Information</div>
                    <hr />
                 
                    
                      <div
                        className="marginAuto"
                       
                      >
                        <div>
                        <img src={Research1} className="mAutoDisplayFlex"/>
                        <p
                         className="textOfpARRA"
                        >
                          PF details are not available
                        </p>
                        </div>
                      </div>
                    
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="PAY">
                <EmployeeSlip/>
            </TabPanel>
            <TabPanel value="Documents"></TabPanel>
          </div>
        </TabContext>
      </Box>
    </>
  );
};
export default EmployeeFinance;
