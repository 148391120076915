import * as Yup from "yup";

const tdcValidationSchema = Yup.object({
  tdc_paidExempted: Yup.string().required("This field is required"),
});
const emdvalidationSchema = Yup.object({
  emd_paidExempted: Yup.string().required("This field is required"),
});

const pgvalidationSchema = Yup.object({
  pgMultipleData: Yup.array().of(
    Yup.object().shape({
      pg_paidExempted: Yup.string().required("This field is required"),
    })
  ),
});

const sdvalidationSchema = Yup.object().shape({
  sdMultipleData: Yup.array().of(
    Yup.object().shape({
      sd_paidExempted: Yup.string().required("This field is required"),
    })
  ),
});

export {
  tdcValidationSchema,
  emdvalidationSchema,
  sdvalidationSchema,
  pgvalidationSchema,
};
