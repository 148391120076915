import * as Yup from "yup";

export const letterIndexSchema = Yup.object({
  project_title: Yup.string().required("This field is required"),
  file: Yup.mixed().required("This field is required"),
  description: Yup.string().required("This field is required"),
});

export const materialCostingSchema = Yup.object({
    item_name: Yup.string().required("This field is required"),
    QPC: Yup.string().required("This field is required"),
    UOM: Yup.string().required("This field is required"),
});

export const financialTargetSchema = Yup.object({
  target_name: Yup.string().required("This field is required"),
  target_value: Yup.number().typeError("Please enter a valid number").required("This field is required"),
  achieved_value : Yup.number().typeError("Please enter a valid number").required("This field is required"),
  target_year: Yup.string().required("This field is required"),
});


const billingItemSchema = Yup.object().shape({
  bpd_invoiceNo: Yup.string().required('Invoice number is required'),
  bpd_invoiceDate: Yup.string().required('Invoice date is required'),
  // Add validation for other fields as needed
  // ...
});

// Define the Yup schema for the entire billing data array
export const billingDataSchema = Yup.array().of(billingItemSchema);