import React from "react";
import "../../../../Tender/TenderStyle/TenderStyle.css";
import CommonTd from "../../../../Tender/Common/CommonTd";
import CommonTh from "../../../../Tender/Common/CommonTh";

function PendingStatusTable({ pendingStatusList }) {
  function formatDate(value) {
    if (value) {
      var date = new Date(value);
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }

    return formattedDate;
  }
  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  return (
    <div
      className="table-responsive table-container-scroll"
      style={{ overflowX: "auto" }}
    >
      <table className="table alert-table" style={{ tableLayout: "unset" }}>
        <thead className="thead-light">
          <tr style={{ borderTop: "1px solid black" }}>
            <CommonTh value="S.No." width="3rem"></CommonTh>
            <CommonTh value="WR Advice No." width="10rem"></CommonTh>
            <CommonTh value="WR Advise Date" width="10rem"></CommonTh>
            <CommonTh value="To Attend At" width="10rem"></CommonTh>
            <CommonTh value="Defect Type" width="10rem"></CommonTh>
            <CommonTh value="Assigned To" width="10rem"></CommonTh>
            <CommonTh value="Last Date To Attend" width="10rem"></CommonTh>
            <CommonTh
              value="Claim Amount (to deduct from Bill)"
              width="10rem"
            ></CommonTh>
          </tr>
        </thead>
        <tbody style={{ maxHeight: "120px" }}>
          {pendingStatusList?.map((tender, index) => {
            console.log("8888",tender)
            console.log("0000",typeof(tender?.
            ToAttendAt)
            )
            return (
              <tr key={index}>
                <CommonTd value={index + 1} width="3rem"></CommonTd>
                <CommonTd
                  value={tender.WarrantyRejectionAdviceNo}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={formatDate(tender.WarrantyRejectionAdviceDate)}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={tender.ToAttendAt}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={tender.DefectTypeName}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={tender.AssigneeName}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={formatDate(tender.LastDateToAttend)}
                  width="10rem"
                ></CommonTd>
                <CommonTd
                  value={!isNaN(parseFloat(tender.ClaimAmount_ToDeductFromBill))?formatNumber(tender.ClaimAmount_ToDeductFromBill):"--"}
                  width="10rem"
                ></CommonTd>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default PendingStatusTable;
