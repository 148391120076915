import React, { useEffect, useState } from "react";
import { getBranchName, getStoreMasterList } from "../../Purchase/purchaseApi";
import { errorsMessage } from "../../constant";
import { fileToBase64 } from "../../Components/serviceFunctions/base64";

function BasicDetails({
  values,
  handleChange,
  setFieldValue,
  serverSideValidation,
  touched,
  errors,
}) {
  const [branchList, setBranchList] = useState([]);
  const [storeDropdown, setStoreDropdown] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  function getBranchDropdown() {
    getBranchName({ user_id })
      .then((res) => {
        setBranchList(res?.data?.response?.BranchMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      });
  }

  function getStoreMasterDropDown() {
    getStoreMasterList({ user_id })
      .then((res) => {
        setStoreDropdown(res?.data?.response?.CityMasterList);
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  useEffect(() => {
    getBranchDropdown();
    getStoreMasterDropDown();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Branch Name{" "}
          </label>
          <select
            className="form-control border-secondary"
            name="branch_id"
            value={values.branch_id}
            onChange={handleChange}
          >
            <option value={""}>Select</option>
            {branchList?.map((element) => (
              <option key={element?.id} value={element?.id}>
                {element?.branch_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation?.branch_id
              : touched.branch_id && errors.branch_id
              ? errors.branch_id
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Transport Mode{" "}
          </label>
          <select
            className="form-control"
            name="transport_mode"
            value={values.transport_mode}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Road">Road</option>
            <option value="Rail">Rail</option>
            <option value="Air">Air</option>
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.transport_mode
              : touched.transport_mode && errors.transport_mode
              ? errors.transport_mode
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Vehicle Number
          </label>
          <input
            className="form-control"
            placeholder="Vehicle Number"
            name="vehicle_number"
            value={values.vehicle_number}
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.vehicle_number
              : touched.vehicle_number && errors.vehicle_number
              ? errors.vehicle_number
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Date of Supply
          </label>
          <input
            className="form-control"
            type="date"
            name="date_of_supply"
            value={values.date_of_supply}
            onChange={handleChange}
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.date_of_supply
              : touched.date_of_supply && errors.date_of_supply
              ? errors.date_of_supply
              : null}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Store Name{" "}
          </label>
          <select
            name="store_id"
            value={values.store_id}
            onChange={handleChange}
            className="form-control"
          >
            <option value={""}>Select </option>
            {storeDropdown.map((element) => (
              <option key={element.id} value={element.id}>
                {element.store_name}
              </option>
            ))}
          </select>
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.store_id
              : touched.store_id && errors.store_id
              ? errors.store_id
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Insured Policy Number
          </label>
          <input
            className="form-control"
            placeholder="Insured Policy Number"
            value={values.insured_policy_number}
            onChange={handleChange}
            name="insured_policy_number"
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.insured_policy_number
              : touched.insured_policy_number && errors.insured_policy_number
              ? errors.insured_policy_number
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Purchase Order Ref No.
          </label>
          <input
            className="form-control"
            placeholder="Purchase Order Ref No."
            value={values.purchase_order_ref_no}
            onChange={handleChange}
            name="purchase_order_ref_no"
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.purchase_order_ref_no
              : touched.purchase_order_ref_no && errors.purchase_order_ref_no
              ? errors.purchase_order_ref_no
              : null}
          </p>
        </div>
        <div className="col-3">
          <label htmlFor="" className="form-label fw-bold font-16">
            Eway Bill
          </label>
          <input
            type="file"
            className="form-control"
            placeholder="Purchase Order Ref No."
            name="emay_bill"
            onChange={(e) =>
              fileToBase64(e.target.files[0], "emay_bill", setFieldValue)
            }
          />
          <p className="text-danger m-0">
            {serverSideValidation
              ? serverSideValidation.emay_bill
              : touched.emay_bill && errors.emay_bill
              ? errors.emay_bill
              : null}
          </p>
        </div>
      </div>
    </>
  );
}

export default BasicDetails;
