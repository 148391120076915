import React, { useState } from "react"; 
import BankDetailModal from "./BankDetailModal";

function AddBankDetailButton({legalEntityId,getBank,editData,setEditData}) {
 
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setEditData(false)
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mb-4">
      <button
        style={{
          width: "100%",
          border: "2px dotted rgba(70, 103, 182, 1)",
          background: "rgba(242, 246, 255, 1)",
          borderRadius: "8px",
        }}
        onClick={handleClickOpen}
      >
        <p
          style={{
            textAlign: "left",
            padding: ".5rem",
            margin: 0,
            color: "rgba(70, 103, 182, 1)",
          }}
        >
          + Add Bank Details
        </p>
      </button>
      <BankDetailModal open={open} handleClose={handleClose} legalEntityId={legalEntityId} getBank={getBank} editData={editData} />
    </div>
  );
}

export default AddBankDetailButton;
