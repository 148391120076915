import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "../../HrDepartment.scss";
import ProbationEmployeeTable from "./ProbationEmployeeTable";
import PolicyDetailCard from "./PolicyDetailCard";
import NoDataFound from "../../CommonComponent/NoDataFound";

const ProbationPolicyTab = ({selectedProbationPolicy}) => {
  const [value, setValue] = useState("POLICY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="">
        <Box
          sx={{ width: "100%", typography: "body1" }}
         
        >
          <TabContext value={value}>
            {/* Tab headers in one row */}
            <div className="row">
              <div
               
                style={{ backgroundColor: "white" }}
              >
                <div >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    className="custom-tablist border-bottom"
                  >
                    <Tab label="Policy" value="POLICY" />
                    <Tab label="Employees" value="EMPLOYEES" />
                  </TabList>
                </div>
              </div>
            </div>

            {/* Tab content in another row */}
            <div className="row">
              <div className="accordian ">
                <TabPanel
                  value="Employees Directory"
                  className="p-0"
                ></TabPanel>
                <TabPanel className="p-0" value="POLICY">
           { selectedProbationPolicy?<PolicyDetailCard selectedProbationPolicy={selectedProbationPolicy}/>:<NoDataFound/>}
                </TabPanel>
                <TabPanel className="p-0" value="EMPLOYEES">
                  <ProbationEmployeeTable selectedProbationPolicy={selectedProbationPolicy} />
                </TabPanel>
              </div>
            </div>
          </TabContext>
        </Box>
      </section>
    </>
  );
};

export default ProbationPolicyTab;
