import React from "react";

const LogisticForm = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  touched,
  handleBlur,
  validationError,

  spinner,
  mode,
  setFieldValue,
}) => {
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active"> Dashboard</li>
                    </ol>
                  </div>
                  <h4 class="page-title"> DASHBOARD </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="fw-bold">
                      {mode == "add" ? "ADD NEW" : "EDIT"}
                    </h5>
                    <form className="department-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Requirement Date Of Vehicle
                            </label>
                            <input
                              type="date"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="vehicle_requirement_date"
                              value={values.vehicle_requirement_date}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.vehicle_requirement_date &&
                              errors.vehicle_requirement_date
                                ? errors.vehicle_requirement_date
                                : validationError?.vehicle_requirement_date}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Requirement Time Of Vehicle
                            </label>
                            <input
                              type="time"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="vehicle_requirement_time"
                              value={values.vehicle_requirement_time}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.vehicle_requirement_time &&
                              errors.vehicle_requirement_time
                                ? errors.vehicle_requirement_time
                                : validationError?.vehicle_requirement_time}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Origin Point
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter Origin Point"
                              name="origin_point"
                              value={values.origin_point}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.origin_point && errors.origin_point
                                ? errors.origin_point
                                : validationError?.origin_point}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Destination Point
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="Enter destination point"
                              name="destination_point"
                              value={values.destination_point}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.destination_point &&
                              errors.destination_point
                                ? errors.destination_point
                                : validationError?.destination_point}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Project Name/ PO No.
                            </label>
                            <input
                              type="text"
                              name="project_po_no"
                              className="form-control border-secondary"
                              placeholder="Enter project name/ PO no."
                              value={values.project_po_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.project_po_no && errors.project_po_no
                                ? errors.project_po_no
                                : validationError?.project_po_no}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Coach set of Material
                            </label>

                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="Enter set"
                              name="coach_material_set"
                              value={values.coach_material_set}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.coach_material_set &&
                              errors.coach_material_set
                                ? errors.coach_material_set
                                : validationError?.coach_material_set}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Vehicle Arriving Date/Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="vehicle_arriving_date_time"
                              value={values.vehicle_arriving_date_time}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.vehicle_arriving_date_time &&
                              errors.vehicle_arriving_date_time
                                ? errors.vehicle_arriving_date_time
                                : validationError?.vehicle_arriving_date_time}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Dispatch Date/Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="dispatch_date_time"
                              value={values.dispatch_date_time}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.dispatch_date_time &&
                              errors.dispatch_date_time
                                ? errors.dispatch_date_time
                                : validationError?.dispatch_date_time}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Expected Delivery Date/Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="expected_delivery_date_time"
                              value={values.expected_delivery_date_time}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.expected_delivery_date_time &&
                              errors.expected_delivery_date_time
                                ? errors.expected_delivery_date_time
                                : validationError?.expected_delivery_date_time}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Actual Delivery Date/Time
                            </label>
                            <input
                              type="datetime-local"
                              className="form-control border-secondary"
                              placeholder="select"
                              name="actual_delivery_date_time"
                              value={values.actual_delivery_date_time}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.actual_delivery_date_time &&
                              errors.actual_delivery_date_time
                                ? errors.actual_delivery_date_time
                                : validationError?.actual_delivery_date_time}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Transporter Name
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter Name"
                              name="transporter_name"
                              value={values.transporter_name}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.transporter_name &&
                              errors.transporter_name
                                ? errors.transporter_name
                                : validationError?.transporter_name}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Type of Vehicle
                            </label>

                            <input
                              className="form-control border-secondary"
                              placeholder="Enter Vehicle Type"
                              name="types_of_vehicle"
                              value={values.types_of_vehicle}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.types_of_vehicle &&
                              errors.types_of_vehicle
                                ? errors.types_of_vehicle
                                : validationError?.types_of_vehicle}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Driver Number
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter driver Number"
                              name="driver_no"
                              value={values.driver_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.driver_no && errors.driver_no
                                ? errors.driver_no
                                : validationError?.driver_no}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Vehicle Number
                            </label>

                            <input
                              className="form-control border-secondary"
                              placeholder="Enter vehicle number"
                              name="vehicle_no"
                              value={values.vehicle_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.vehicle_no && errors.vehicle_no
                                ? errors.vehicle_no
                                : validationError?.vehicle_no}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Docket Number
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter docket number"
                              name="docket_no"
                              value={values.docket_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.docket_no && errors.docket_no
                                ? errors.docket_no
                                : validationError?.docket_no}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Transit Mode
                            </label>
                            <select
                              className="form-control border-secondary"
                              placeholder=""
                              name="transit_mode"
                              value={values.transit_mode}
                              onChange={handleChange}
                            >
                              <option value="" className="text-secondary">
                                Select
                              </option>
                              <option value="Due">By Bus</option>
                              <option value="Returned">By Train</option>
                            </select>
                            <p className="text-danger m-0">
                              {touched.transit_mode && errors.transit_mode
                                ? errors.transit_mode
                                : validationError?.transit_mode}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Cost of Vehicle
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter Cost of Vehicle"
                              name="cost_of_vehicle"
                              value={values.cost_of_vehicle}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.cost_of_vehicle && errors.cost_of_vehicle
                                ? errors.cost_of_vehicle
                                : validationError?.cost_of_vehicle}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Approved By
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="enter name"
                              name="approved_by"
                              value={values.approved_by}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.approved_by && errors.approved_by
                                ? errors.approved_by
                                : validationError?.approved_by}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Invoice Number
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="Enter invoice number"
                              name="invoice_no"
                              value={values.invoice_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.invoice_no && errors.invoice_no
                                ? errors.invoice_no
                                : validationError?.invoice_no}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Invoice Value
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="Enter invoice value"
                              name="invoice_value"
                              value={values.invoice_value}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.invoice_value && errors.invoice_value
                                ? errors.invoice_value
                                : validationError?.invoice_value}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Receiver Name
                            </label>
                            <input
                              type="text"
                              className="form-control border-secondary"
                              placeholder="Enter receiver name"
                              name="receiver_name"
                              value={values.receiver_name}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.receiver_name && errors.receiver_name
                                ? errors.receiver_name
                                : validationError?.receiver_name}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Receiver Contact Number
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter receiver contact number"
                              name="receiver_contact_no"
                              value={values.receiver_contact_no}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.receiver_contact_no &&
                              errors.receiver_contact_no
                                ? errors.receiver_contact_no
                                : validationError?.receiver_contact_no}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="form-group mb-3">
                            <label
                              htmlFor=""
                              className="form-label fw-bold font-16"
                            >
                              Remark
                            </label>
                            <input
                              className="form-control border-secondary"
                              placeholder="Enter remark"
                              name="remarks"
                              value={values.remarks}
                              onChange={handleChange}
                            ></input>
                            <p className="text-danger m-0">
                              {touched.remarks && errors.remarks
                                ? errors.remarks
                                : validationError?.remarks}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        {spinner ? (
                          <div className="">
                            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                            <p>Adding Logistic...</p>
                          </div>
                        ) : (
                          <div className="mt-3">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm border-danger"
                              style={{ marginLeft: "1rem" }}
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger btn-sm border-danger"
                              style={{ marginLeft: "1rem" }}
                              // onClick={() => navigate(-1)}
                            >
                              {mode === "add" ? "ADD" : "EDIT"}
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default LogisticForm;
