import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../organisationStructure/OrgStucture.css";
import LegalEntityRegistrationInformation from "./LegalEntityRegistrationInformation";
import AuthorizeSignatories from "./AuthorizeSignatories";
import LegalEntityBankDetail from "./LegalEntityBankDetail";

function LegalEntityHeaderTabs({ legalEntity, getEditData }) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className="organisation_Structure_Tab">
    <Box
      sx={{
        width: "100%",
        typography: "body1",
      }}
    >
      <TabContext value={value}>
        <Box >
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // background: "rgba(245, 245, 245, 1)",
              borderBottom: "1px solid rgba(207, 207, 207, 1)",
            }}
          >
            <Tab
              label="registration information"
              value="1"
              sx={{
                flex: 1,
                textAlign: "center",
                // background: "rgba(245, 245, 245, 1)",
              }}
            />
            <Tab
              label="Authorized signature"
              value="2"
              sx={{ flex: 1, textAlign: "center" }}
            />
            <Tab
              label="Bank details"
              value="3"
              sx={{ flex: 1, textAlign: "center" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <LegalEntityRegistrationInformation
            legalEntity={legalEntity}
            getEditData={getEditData}
          />
        </TabPanel>
        <TabPanel value="2" >
          <AuthorizeSignatories legalEntity={legalEntity} />
        </TabPanel>
        <TabPanel value="3" style={{ background: "#FFF" }}>
          <LegalEntityBankDetail legalEntity={legalEntity} />
        </TabPanel>
      </TabContext>
    </Box>
    </section>
  );
}

export default LegalEntityHeaderTabs;
