import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { selectCurrentMonth, selectCurrentYear } from "../TenderSlice";
import { setCurrentMonth, setCurrentYear } from "../TenderSlice";
import { useDispatch, useSelector } from "react-redux";
import CommonTd from "../Common/CommonTd";
import CommonTh from "../Common/CommonTh";
import "react-datepicker/dist/react-datepicker.css";
import { SlCalender } from "react-icons/sl";
import "../TenderStyle/TenderStyle.css";
import DatePicker from "react-datepicker";
import { FaEye } from "react-icons/fa";
import EmdTrackerAttachmentModal from "../TenderModal/EmdTrackerAttachmentModal";
import zIndex from "@mui/material/styles/zIndex";

function EmdReturnTracker() {
  const [tenderData, setTenderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specificDate, setSpecificDate] = useState("");
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const [searchData, setSearchData] = useState([]);
  const [contractFilterValue, setContractFilterValue] = useState("");
  const [biddingStatus, setBiddingStatus] = useState("");
  const [awardedStatus, setAwardedStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [conditionTenders, setConditionTenders] = useState(true);
  const [tenderEmdDocs, setTenderEmdDocs] = useState([]);
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [modalShow, setModalShow] = useState(false);
  const [renderState, setRenderState] = useState(false);
  const [tenderId, setTenderID] = useState(null);
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const month = useSelector(selectCurrentMonth);
  const currentYear = useSelector(selectCurrentYear);

  async function tenderDataFetch() {
    setLoading(true);
    let url = baseUrl + "gettenderlist";
    let start_date = startDate
      ? `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`
      : "";
    let end_date = endDate
      ? `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`
      : "";
    await axios
      .post(
        url,
        {
          user_id,
          search: null,
          startDate: start_date,
          endDate: end_date,
          biddingStatus,
          awardedStatus,
        },
        config
      )
      .then((res) => {
        if (res.data.response.isSuccess) {
          let dueTenders = res.data.response.TendersList.filter(
            (element) =>
              element.EMD === "Paid" && element.tenderReturnStatus === "Due"
          );
          let returnedTenders = res.data.response.TendersList.filter(
            (element) =>
              element.EMD === "Paid" &&
              element.tenderReturnStatus === "Returned"
          );

          if (conditionTenders) {
            setTenderData(dueTenders);
            setSearchData(dueTenders);
          } else {
            setTenderData(returnedTenders);
            setSearchData(returnedTenders);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  }
  async function getAttachments() {
    axios
      .post(
        baseUrl + "gettenderattechmentslist",
        { user_id, tenderId: tenderId },
        config
      )
      .then((response) => {
        setTenderEmdDocs(response?.data?.response?.attechmentList.EmdDocs);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
    // setAllAttachments(tenderAttachment);
    // setAttachmentModal(true);
  }

  useEffect(() => {
    const filteredResult = tenderData.filter(
      (tenderValue) =>
        tenderValue.tenderNo
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        tenderValue.itemDecsription
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
    setSearchData(filteredResult);
  }, [searchValue]);

  useEffect(() => {
    tenderDataFetch();
  }, [
    currentYear,
    month,
    specificDate,
    startDate,
    endDate,
    biddingStatus,
    conditionTenders,
    awardedStatus,
  ]);

  useEffect(() => {
    const filteredResult = tenderData?.filter((tenderList) => {
      return tenderList?.contractType
        ?.toLowerCase()
        .includes(contractFilterValue.toLowerCase());
    });
    setSearchData(filteredResult);
  }, [contractFilterValue]);

  function formatDate(value) {
    var date = new Date(value);
    if (value) {
      var formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    }
    return formattedDate;
  }

  function editData(initialData) {
    navigate("/tenders/edit-tender", {
      state: {
        initialData,
      },
    });
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }
  useEffect(() => {
    if (tenderId) {
      getAttachments();
    }
  }, [tenderId, renderState]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-md-6">
                <h4 className="m-0">TENDERS</h4>
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-3 text-end">
                <div className="d-flex gap-2" style={{position: "relative", zIndex: "3"}}>
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select a date"
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<StartDatePickerInput />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <DatePicker
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select a date"
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    customInput={<EndDatePicker />}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      value={contractFilterValue}
                      onChange={(e) => setContractFilterValue(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Contract Types
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Works">Works</option>
                      <option value="Store">Stores</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      value={biddingStatus}
                      onChange={(e) => setBiddingStatus(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Bidding Status
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Bidded">Bidded</option>
                      <option value="Not Bidded">Not Bidded</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control border-danger"
                      value={awardedStatus}
                      onChange={(e) => setAwardedStatus(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Awarded Status
                      </option>
                      <option value="">All Tenders</option>
                      <option value="Awarded">Awarded</option>
                      <option value="Not Awarded">Not Awarded</option>
                      <option value="PostPoned">PostPoned</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-flex justify-content-end">
                <div className="input-group me-3 w-75">
                  <input
                    type="text"
                    name="example-input1-group2"
                    className="form-control form-control-sm border-danger"
                    placeholder="Search by Title or Number"
                    style={{ fontSize: ".9rem" }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button type="button" className="btn btn-primary btn-sm">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>

              <div className="col-md-2">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => setConditionTenders(!conditionTenders)}
                >
                  {conditionTenders
                    ? "View Returned Tenders"
                    : "View Due Tenders"}
                </button>
              </div>
            </div>

            <div>
              {loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "70vh" }}
                >
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="danger" />
                </div>
              ) : (
                <div>
                  <div
                    className="table-responsive table-container-scroll"
                    style={{ overflowX: "auto" }}
                  >
                    <table
                      className="table tender-list-table"
                      style={{ tableLayout: "unset" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <CommonTh value="S No." width="3rem"></CommonTh>
                          <CommonTh value="Railway Zone"></CommonTh>
                          <CommonTh value="Sub Divison"></CommonTh>
                          <CommonTh value="Tender No."></CommonTh>
                          <CommonTh value="Tender Title"></CommonTh>
                          <CommonTh value="Closing Date"></CommonTh>
                          <CommonTh value="Amount"></CommonTh>
                          <CommonTh value="IREPS Ref No."></CommonTh>
                          <CommonTh value="Transaction ID"></CommonTh>
                          <CommonTh value="Transaction Date"></CommonTh>
                          <CommonTh value="Transaction Time"></CommonTh>
                          <CommonTh value="Tender Award Status"></CommonTh>
                          <CommonTh value="Return Status"></CommonTh>
                          <CommonTh value="Return Date"></CommonTh>
                          <CommonTh value="Remarks"></CommonTh>
                          <CommonTh value="Attachment"></CommonTh>
                          <CommonTh value="Action" width={"6rem"}></CommonTh>
                        </tr>
                      </thead>
                      <tbody>
                        {searchData?.map((tender, i) => (
                          <tr key={i}>
                            <th scope="row">
                              <h6
                                style={{ minWidth: "3rem", maxWidth: "3rem" }}
                              >
                                {i + 1}
                              </h6>
                            </th>
                            <>
                              <CommonTd
                                value={tender.railwayZoneName}
                              ></CommonTd>
                              <CommonTd
                                value={tender.subDivisionName}
                              ></CommonTd>
                              <CommonTd value={tender.tenderNo}></CommonTd>
                              <CommonTd
                                value={tender.itemDecsription}
                              ></CommonTd>
                              <CommonTd
                                value={formatDate(tender.closingDate)}
                              ></CommonTd>
                              <CommonTd
                                value={formatNumber(
                                  tender.ifApplicableAmountEmd
                                )}
                              ></CommonTd>
                              <CommonTd value={tender.IREPSRefNo}></CommonTd>
                              <CommonTd value={tender.transactionId}></CommonTd>
                              <CommonTd
                                value={formatDate(tender.transactionDate)}
                              ></CommonTd>
                              <CommonTd
                                value={tender.transactionTime}
                              ></CommonTd>
                              <CommonTd
                                value={tender.tenderAwardStatus}
                              ></CommonTd>
                              <CommonTd
                                value={tender.tenderReturnStatus}
                              ></CommonTd>
                              <CommonTd
                                value={formatDate(tender.tenderReturnDate)}
                              ></CommonTd>
                              <CommonTd
                                value={tender.tenderEmdReturnRemarks}
                              ></CommonTd>
                              <td
                                onClick={() => {
                                  setModalShow(true);
                                  setTenderID(tender.id);
                                }}
                              >
                                <h6>
                                  <FaEye
                                    style={{
                                      width: "1.5rem",
                                      height: "1.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                </h6>
                              </td>

                              <td>
                                <h6
                                  style={{ minWidth: "6rem", maxWidth: "6rem" }}
                                >
                                  <i
                                    className="las la-pen text-dark font-20 px-1"
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit Zone Details"
                                    onClick={() => editData(tender)}
                                  ></i>
                                </h6>
                              </td>
                            </>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <EmdTrackerAttachmentModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={tenderEmdDocs}
        renderState={renderState}
        setRenderState={setRenderState}
      />
    </>
  );
}

const StartDatePickerInput = ({ value, onClick }) => (
  <div className="custom-datepicker-input text-start" onClick={onClick}>
    <SlCalender />
    {value ? value : "Start date"}
  </div>
);

const EndDatePicker = ({ value, onClick }) => (
  <div className="custom-datepicker-input text-start" onClick={onClick}>
    <SlCalender />
    {value ? value : "End date"}
  </div>
);

export default EmdReturnTracker;
