import React, { useEffect, useState } from "react";
import "../../organisationStructure/OrgStucture.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AuthorizeSignatorieModal from "./AuthorizeSignatorieModal";
import DeleteModal from "../OrganizationComponents/DeleteModal";
import { toast } from "react-toastify";
import { deleteAuthorizedSignatureApi } from "../OrganizationComponents/organizationStructureApi";
function AuthorizeSignatureDetail({
  authorizeSignatory,
  key,
  getAuthoizeSignatory,
}) {
  
  const [editData, setEditData] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleEditClick = () => {
    setOpen(true);
  };
  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };
  const handleDeletemodalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const deleteAuthoriseSignatory = async () => {
    setLoader(true);
    try {
      const requestData = {
        id: authorizeSignatory?.id,
      };
      const res = await deleteAuthorizedSignatureApi(requestData);
 
  
      if (res.status === 200) {
        toast.success("Authorized Signature deleted successfully", {
          autoClose: 1000,
        });
        getAuthoizeSignatory();
        handleDeletemodalClose();
      }
    } catch (error) {
     
      toast.error(error?.response?.data?.message || error.message || "An unexpected error occurred.");
    } finally {
      setLoader(false);
    }
  };
  
  return (
    <div
      style={{
        border: "1px solid rgba(207, 207, 207, 1)",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 1)",
        padding: "1.5rem",
      }}
      className="legalEntityRegisteredAddress mb-3"
      key={key}
    >
      <div className="row my-2">
        <div className="col-3 mb-5">
          <p>Signatroy Name</p>
          <p className="bold">{authorizeSignatory?.name}</p>
        </div>
        <div className="col-4">
          <p>Signatory Designation</p>
          <p className="bold">{authorizeSignatory?.designation}</p>
        </div>
        <div className="col-3">
          <p>Email Address </p>
          <p className="bold">{authorizeSignatory?.email} </p>
        </div>
        {/* <div className="col-2 text-endssss"> dot</div> */}
        <div class="col-2 dropdown">
          <button
            class="dropdown-toggle border-0 bg-transparent"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MoreVertIcon />
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ minWidth: "8rem" }}
          >
            <li>
              <button
                class="dropdown-item"
                onClick={() => {
                  handleEditClick(authorizeSignatory);
                }}
              >
                Edit
              </button>
            </li>
            <li>
              <button class="dropdown-item" onClick={handleDeleteClick}>
                Delete
              </button>
            </li>
          </ul>
        </div>
        <div className="col-3">
          <p>Signatroy Father’s Name</p>
          <p className="bold">{authorizeSignatory?.father_name}</p>
        </div>
        <div className="col-4">
          <p>PAN Card Number</p>
          <p className="bold">{authorizeSignatory?.pan}</p>
        </div>
        <div className="col-3 mb-5">
          <p>Country </p>
          <p className="bold">{authorizeSignatory?.country} </p>
        </div>
        <div className="col-4">
          <p>Country </p>
          <p className="bold">{authorizeSignatory?.address_line_1} </p>
          <p className="bold">{authorizeSignatory?.address_line_2} </p>
        </div>
      </div>
      <AuthorizeSignatorieModal
        open={open}
        handleClose={handleClose}
        legalEntity={authorizeSignatory}
        getAuthoizeSignatory={getAuthoizeSignatory}
        mode="edit"
      />
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        handleDeletemodalClose={handleDeletemodalClose}
        deleteData={deleteAuthoriseSignatory}
        loader={loader}
      />
    </div>
  );
}

export default AuthorizeSignatureDetail;
