
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import DeleteModal from "../Organisation/organisationStructure/OrganizationComponents/DeleteModal";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../CommonComponent/Loader";
import NoDataFound from "../CommonComponent/NoDataFound";
// Updated column definitions

export default function JobWorkTimeCommonTable({ columns, rows,getEditData, deleteData, loader, handleClose,deleteModalOPen,setDeleteModalOPen}) {
  const [page, setPage] =useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

const[idToDelete,setIdToDelete] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
const deleteHandler =()=>{
  deleteData(idToDelete)
}
const openDeleteModal=(id)=>{
  setIdToDelete(id)
  setDeleteModalOPen(true)
}
  return (<>
    {loader?<Loader/>:rows?.length>0?<div className=" job-Work-Time-CommonTable mx-3">
      <Paper  sx={{ width: "100%", overflow: "hidden" ,boxShadow:"none"}}>
        <TableContainer sx={{ maxHeight: 440, }} >
          <Table  className="border-0" stickyHeader aria-label="sticky table">
            <TableHead className="table-head ">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{
                      minWidth: column.minWidth,
                      width: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={column.id} align="left">
                              {column.id === "actions" ? (
                                <>
                                  <FiEdit fontSize={"28px"} className="me-5"onClick={()=>getEditData(row)} />
                                  <RiDeleteBin6Line
                                    color="red"
                                    fontSize={"28px"}
                                    onClick={()=>openDeleteModal(row?.id)}
                                  />
                                </>
                              ) : column.format && value ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteModal deleteModalOpen={deleteModalOPen}
  handleDeletemodalClose ={handleClose}
  deleteData ={deleteHandler}
  loader={loader} />
  
    </div>:<NoDataFound/>}
    </>
  );
}
