import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../../Common/CommonTable";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Itemmaster.module.css";
import { getItemMasterList } from "../../purchaseApi";
import CommonTd from "../../../Tender/Common/CommonTd";
import { FaRegEdit } from "react-icons/fa";
import { errorsMessage } from "../../../constant";
import { CiSearch } from "react-icons/ci";
import usePagination from "../../Common/usePurchasePagination";
import { IoIosSearch } from "react-icons/io";
import usePurchaseURLFilters from "../../Common/usePurchaseUrlFilter";

function ItemMasterList() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  let imageBaseUrl = "https://dev-unti.fictivebox.tech/";
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const [filters, setFilters] = usePurchaseURLFilters();
  const {
    data: itemMasterList,
    loading,
    hasMore,
    loadMore,
    resetPagination,
  } = usePagination(
    getItemMasterList,
    user_id,
    "ItemMasterList",
    filters.search
  );
  const tableBodyRef = useRef(null);
  const tableHeading = [
    {
      name: "Id",
      width: "2rem",
    },
    {
      name: "Item Code",
      width: "4rem",
    },
    {
      name: "Item Name",
      width: "29rem",
    },
    {
      name: "Classification Name",
      width: "10rem",
    },
    {
      name: "Sub Class. Name",
      width: "10rem",
    },
    {
      name: "Image",
      width: "5rem",
    },
    {
      name: "Edit",
      width: "3rem",
    },
  ];

  function editData(element) {
    navigate("/editItemMaster", { state: { editData: element } });
  }
  const handleScroll = () => {
    const tableBody = tableBodyRef.current;
    if (
      tableBody.scrollTop + tableBody.clientHeight >=
        tableBody.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      loadMore();
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      const newFilters = {
        ...filters,
        search: search,
      };
      setFilters(newFilters);
      navigate(`?search=${newFilters.search}`);
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [search]);
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-6">
              <h4 className="page-title">ITEM MASTER</h4>
            </div>
            <div className="col-sm-4 text-end">
              <div className="postion-relative">
                <input
                  className="form-control w-75 ms-auto"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch
                  size={30}
                  className="position-absolute top-0 end-0 mt-1 me-2"
                  style={{ color: "ef4d56" }}
                />
              </div>
            </div>
            <div className="col-sm-auto text-end">
              <Link to={"/addItemMaster"}>
                <button className="btn btn-danger">Add Item</button>
              </Link>
            </div>
          </div>
          {/* <div className="row mt-3">
            <div className={styles.itemMasterHeader}>
              <div style={{ width: "12rem" }}>
                <label htmlFor="" className="form-label fw-bold font-16">
                  {" "}
                  From Date
                </label>
                <input className="form-control border-danger" type="date" />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "19rem",
                }}
              >
                <div
                  style={{
                    width: "12rem",
                  }}
                >
                  <label htmlFor="" className="form-label fw-bold font-16">
                    {" "}
                    To Date
                  </label>
                  <input className="form-control border-danger" type="date" />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    alignItems: "end",
                    position: "relative",
                    bottom: "10px",
                  }}
                >
                  <div>
                    <input
                      className="form-control border-danger"
                      type="checkbox"
                    />
                  </div>
                  <label
                    style={{
                      marginLeft: ".5rem",
                      lineHeight: "1",
                    }}
                  >
                    With date
                  </label>
                </div>
              </div>

              <div style={{ width: "10rem" }}>
                <label className="form-label fw-bold font-16">Type</label>
                <select className="form-control border-danger">
                  <option value={""}>Select</option>
                </select>
              </div>
              <div
                style={{ width: "15rem", display: "flex", alignItems: "end" }}
              >
                <div>
                  <label className="form-label fw-bold font-16">Search</label>
                  <input
                    className="form-control border-danger"
                    placeholder="Search"
                    
                  />
                </div>
                <div>
                  <button
                    className="btn btn-danger"
                    style={{ width: "3rem", padding: 0 }}
                  >
                    <CiSearch />
                  </button>
                </div>
              </div>
              <div style={{ marginTop: "8px" }}>
                <Link to={"/addItemMaster"}>
                  <button className="btn btn-danger">Add Item</button>
                </Link>
              </div>
            </div>
          </div> */}

          <div className="card">
            <div
              className="card-body "
              style={{ padding: "1rem 0", margin: "0" }}
            >
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading}></CommonTable>
                  <tbody ref={tableBodyRef}>
                    {itemMasterList?.map((element, index) => (
                      <tr key={index}>
                        <CommonTd width="2rem" value={element.id}></CommonTd>

                        <CommonTd
                          width="4rem"
                          value={element?.item_code}
                        ></CommonTd>
                        <CommonTd
                          width="29rem"
                          value={element?.product_name}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.main_master_name}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.sub_master_name}
                        ></CommonTd>
                        <td style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                          <img
                            style={{ width: "4rem", height: "3rem" }}
                            src={
                              element?.product_image_url
                                ? imageBaseUrl + element?.product_image_url
                                : null
                            }
                            alt="No Image"
                          />
                        </td>
                        <td>
                          <p
                            style={{
                              minWidth: "3rem",
                              maxWidth: "3rem",
                              margin: "auto",
                              padding: "auto",
                              cursor: "pointer",
                            }}
                            className="text-center"
                          >
                            <FaRegEdit
                              className="text-dark font-20"
                              style={{ margin: "auto" }}
                              onClick={() => editData(element)}
                            />
                          </p>
                        </td>
                        {/* <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <RiDeleteBinLine
                                  data-bs-toggle="modal"
                                  data-bs-target="#unitMasterDelete"
                                  className="text-danger font-20"
                                  style={{ margin: "auto" }}
                                  onClick={() => setDeleteId(element.id)}
                                />
                            </p>
                          </td> */}
                      </tr>
                    ))}
                  </tbody>
                  {loading && (
                    <div className="d-flex align-items-center justify-content-center pb-5">
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMasterList;
