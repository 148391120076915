import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import BackgroundLetterAvatars from './LetterAvatar';
import ImageAvatars from './ImageAvatar';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from '../CommonComponent/Loader';
import NoDataFound from '../CommonComponent/NoDataFound'
// Updated column definitions

export default function CommonTable({rows,columns,loader}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ImageLetterAvatarHandler =(row)=>{
 console.log("calledd",row)
    if(row?.profileimage
      !==null){
      console.log("imageeee")
        return <ImageAvatars name={row?.name} image={row?.image} />
    }else{console.log("letterrrrr")
      return <BackgroundLetterAvatars name={row?.name}/>}

  }
  return (
    <>
  {loader?<Loader/>:rows?.length>0?
    <Paper sx={{ width: '100%', overflow: 'hidden',boxShadow:"none" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table className='border-0'stickyHeader aria-label="sticky table">
          <TableHead  className='table-head'>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (<>
                        <TableCell key={column.id} align="left">

                        {column.id === 'name'
                          ? ImageLetterAvatarHandler(row)
                          : column.id === 'actions'
                            ? <MoreVertIcon />
                            : column.format && value
                              ? column.format(value)
                              : value}
                        </TableCell>
                       
                        </> );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>:<NoDataFound/>}
    </>
  );
}
