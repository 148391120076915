import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link } from "react-router-dom";
import { getrailwayzone } from "../../redux/api";
import { useNavigate } from "react-router-dom";
import { subdivisonSchema } from "../../schemas/settingSchema";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";

function SubDivisonAdd() {
  const navigate = useNavigate();
  const [zoneList, setZoneList] = useState([]);
  const [validationError, setValidationError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;

  const initialValues = {
    name: "",
    railway_zone: "",
  };

  const { handleSubmit, values, errors, handleChange, touched, handleBlur } =
    useFormik({
      initialValues: initialValues,
      validationSchema: subdivisonSchema,
      onSubmit: (values) => {
        addDivison(values);
      },
    });

  function railwayZoneDropDown() {
    getrailwayzone({ user_id: user_id })
      .then((res) => {
        setZoneList(res?.data?.response?.RailwayZoneList);
      })
      .catch((err) => {
      
      });
  }

  function addDivison(value) {
    setSpinner(true);
    const data = {
      user_id,
      ...value,
      accessType: "add",
    };
    const getToken = user?.response?.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(baseUrl + "storesubdivision", data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.data.success) {
          navigate("/subdivison");
          toast.success("Added Successfully");
        }
        if (response.data.status === "Token is Expired") {
          navigate("/");
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error.response.data.response);
        }
        if (error.response.data.response === "Already Exist.") {
          setValidationError(error.response.data.response);
        }
      });
  }

  useEffect(() => {
    railwayZoneDropDown();
  }, []);

  return (
    <>
      
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                        <Link to="#!">Projects /</Link>
                      </li> */}
                      <li className="breadcrumb-item">
                        <Link to="/subdivison">Sub Divison/</Link>
                      </li>
                      <li className="breadcrumb-item active">Add SubDivison</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Add Sub Divison</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label">
                              Sub Divison
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Sub Divison name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className="text-danger">
                              {touched.name && errors.name
                                ? errors.name
                                : validationError?.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label
                              htmlFor="flexSwitchCheckChecked"
                              className="form-label"
                            >
                              Railway Zone
                            </label>
                            <select
                              className="form-control"
                              placeholder="Select Railway zone"
                              name="railway_zone"
                              value={values.railway_zone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Zone</option>
                              {zoneList?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="text-danger">
                              {touched.railway_zone && errors.railway_zone
                                ? errors.railway_zone
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                      {spinner ? (
                        <div className="">
                          <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                          <p>Wait...</p>
                        </div>
                      ) : (
                        <div className="col-md-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-3"
                          >
                            Add Sub Divison
                          </button>
                          <Link to="/subdivison">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm"
                            >
                              Cancel
                            </button>
                          </Link>
                        </div>
                      )}
                    </form>

                    {/* <p style={{ color: "red" }}>{error}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SubDivisonAdd;
