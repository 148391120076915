import React, { useState } from "react";
import CommonTableExits from "../../MaterialUiComponent/CommonTableExits";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitSearchBar from "./ExitSearchBar";

const InExitProcess = ({ tabName }) => {
  const columns = [
    { id: "Employee", label: "Employee", minWidth: "10rem" },
    {
      id: "Exit type& Resign date",
      label: "Exit type& Resign Date",
      minWidth: 200,
    },
    { id: "Notice Date", label: "Notice Date", minWidth: 150 },
    { id: "Last Day", label: "Last Day", minWidth: 150 },
    {
      id: "Reporting Manager",
      label: "Reporting Manager",
      minWidth: 180,
      format: (value) => new Date(value).toLocaleDateString("en-US"),
    },
    { id: "Location", label: "Location", minWidth: 150 },
    { id: "Comment", label: "Comment", minWidth: 150 },
    { id: "Status", label: "Status", minWidth: 150 },
    { id: "actions", label: "Action", minWidth: 150 },
  ];

  // Sample data
  const rows = [
    {
      Employee: "John Doe",
      "Exit type& Resign date": "Software Engineer",
      "Notice Date": "john.doe@example.com",
      "Last Day": "Engineering",
      "Reporting Manager": "2024-12-31",
      Location: "1234",
      Comment: "--",
      Status: "Relieved",
    },

    // Add more sample data here
  ];
  const departmentChange = () => {
    console.log(tabName);
  };

  return (
    <section className="inExit-Process">
      <div className="bg-white mx-3">
        <ExitSearchBar tabName={tabName} departmentChange={departmentChange} />
        <CommonTableExits
          columns={columns}
          rows={rows}
          actionfield={<MoreVertIcon />}
        />
      </div>
    </section>
  );
};

export default InExitProcess;
