import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CommonInput from "./CommonInput";
import CommonOptions from "./CommonOptions";
import { emdvalidationSchema } from "./TdcEmdSchema";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import Modals from "./Modal";

function EmdDetail({ emdData, renderState, setRenderState }) {
  const param = useParams();
  const project_id = Number(param.id);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const initialValues = {
    emd_toPay: 0,
    emd_paidExempted: "",
    emd_paidTransactionNoDate: "",
    emd_expiryDate: "",
    emd_returnDate: "",
    emd_returnAmount: 0,
    emd_returnStatus: "",
    emd_due: 0,
    emd_remark: "",
    emd_attachement: "",
    emd_paidTransactionNo: "",
  };

  function saveDataOnserver(url, value, project_id) {
    const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    setSpinner(true);
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(
        apiurl + url,
        { ...value, user_id, project_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setSpinner(false);
          setRenderState(!renderState);
          toast.success("Data added successfully");
        }
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
      });
    return spinner;
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => readFileAsBase64(file))).then(
      (filesArray) => {
        setFieldValue("emd_attachement", filesArray);
      }
    );
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const handleInputChange = (event) => {
    const updatedValue = event.target.value;
    if (/^\d*\.?\d*$/.test(updatedValue)) {
      handleChange(event);
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: emdvalidationSchema,
    onSubmit: (values) => {
      saveDataOnserver("storeuntiprojectemd", values, project_id);
    },
  });
  useEffect(() => {
    if (emdData) {
      setValues({ ...emdData, emd_attachement: [] });
      setData(emdData);
    }
  }, [emdData]);
  useEffect(() => {
    if (
      values.emd_paidExempted === "Not Applicable" ||
      values.emd_paidExempted === "Exempted"
    ) {
      setValues((prevValues) => ({
        ...prevValues,
        emd_toPay: 0,
        emd_paidTransactionNo: "",
        emd_paidTransactionNoDate: "",
        emd_attachement: [],
        emd_expiryDate: "",
        emd_returnDate: "",
        emd_returnAmount: 0,
      }));
    }
  }, [values.emd_paidExempted]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div
          className="row mb-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 12rem)",
            gridGap: "1rem",
            overflowY: "hidden",
            overflowX: "scroll",
            paddingBlock: "18px 24px",
          }}
        >
          <div className="form-group mb-12 col">
            <CommonOptions
              handleChange={handleChange}
              values={values.emd_paidExempted}
              name="emd_paidExempted"
              labelValue="Paid/Exempted"
              handleBlur={handleBlur}
            />
            <p className="text-danger">
              {touched.emd_paidExempted && errors.emd_paidExempted
                ? errors.emd_paidExempted
                : null}
            </p>
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleInputChange}
              values={values.emd_toPay}
              type="text"
              name="emd_toPay"
              labelValue="Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleChange}
              values={values.emd_paidTransactionNo}
              type="text"
              name="emd_paidTransactionNo"
              labelValue="Transaction No."
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              placeHolder={"EMD Paid Transaction no."}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleChange}
              values={values.emd_paidTransactionNoDate}
              type="date"
              name="emd_paidTransactionNoDate"
              labelValue="Transaction Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleChange}
              values={values.emd_expiryDate}
              type="date"
              name="emd_expiryDate"
              labelValue="Expiry Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleChange}
              values={values.emd_returnDate}
              type="date"
              name="emd_returnDate"
              labelValue="Return Date"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleInputChange}
              values={values.emd_returnAmount}
              type="text"
              name="emd_returnAmount"
              labelValue="Return Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              values={
                (values.emd_due = values.emd_toPay - values.emd_returnAmount)
              }
              type="text"
              name="emd_due"
              labelValue="Due Amount"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              handleChange={handleChange}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              className={values.emd_due !== 0 ? "bg-danger" : "bg-success"}
              values={
                (values.emd_returnStatus =
                  values.emd_due === 0 ? "Completed" : "Pending")
              }
              type="text"
              name="emd_returnStatus"
              labelValue="Return Status"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              handleChange={handleChange}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleChange}
              values={values.emd_remark}
              type="text"
              name="emd_remark"
              labelValue="Remark"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              onBlur={handleBlur}
              placeHolder={"EMD Remark"}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>

          <div className="form-group mb-12 col">
            <CommonInput
              handleChange={handleFileChange}
              type="file"
              name="emd_attachement"
              labelValue="Attachment"
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              defaultValues={values.emd_attachement}
              disabled={
                values.emd_paidExempted === "Exempted" ||
                values.emd_paidExempted === "Not Applicable"
              }
            />
          </div>
          <div className="form-group mb-12 col ">
            <label htmlFor="" className="form-label">
              Attachment Preview
            </label>

            <p
              style={{ cursor: "pointer", textAlign: "center", width: "50px" }}
              onClick={() => setModalShow(true)}
            >
              <FaEye />
            </p>
            <Modals
              show={modalShow}
              onHide={() => setModalShow(false)}
              data={data.emd_attachement}
              renderState={renderState}
              setRenderState={setRenderState}
              url={"setdeleteemdattechment"}
            />
          </div>
        </div>
      </div>
      <div className="text-end mt-3">
        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Updating ...</p>
          </div>
        ) : (
          <button type="submit" className="btn btn-danger">
            Save
          </button>
        )}
      </div>
    </form>
  );
}

export default EmdDetail;
