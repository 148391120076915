import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import VehicleRequestForm from "./VehicleRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { VehicleRequestSchema } from "../../schemas/StoreSchema/VehicleRequestSchema";

const AddVechileRequest = () => {
  const [spinner, setSpinner] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const { user } = useSelector((state) => ({ ...state.auth }));
  const [storeVehicleRequestId, setStoreVehicleRequestId] = useState(null);

  const navigate = useNavigate();
  const vehicle_requested_by_department =
    user?.response?.userDetails?.department_id;
  const vehicle_requested_by_department_user = user?.response?.userDetails?.id;

  const initialValue = {
    requested_date_for: "",
    project_name: "",
    po_no: "",
    transit_mode: "",
    material_type: "",
    required_vehicle_size: "",
    material_weight: "",
    loading_address: "",
    la_pin_code: "",
    la_contact_name: "",
    la_contact_number: "",
    delivery_address: "",
    da_pin_code: "",
    da_contact_number: "",
    requestee_signature: "",
    approvee_hod_signature: "",
    remarks: "",
    evidence_and_confirmation: "",
    material_insurance: "",
    vehicle_hold: "",
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: VehicleRequestSchema,
    onSubmit: (values) => {
      addVehicleRequset(values);
    },
  });
  const addVehicleRequset = (values) => {
   
    const data = {
      ...values,
      user_id,
      vehicle_requested_by_department,
      vehicle_requested_by_department_user,
    };
    const url = `${baseUrl}addvehiclerequest`;
    setSpinner(true)
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false)
        if (res.status === 200) {
          setStoreVehicleRequestId(res.data.response.StoreVehicleRequestId);
          resetForm();
          toast.success("Form submit successfully",{ autoClose: 2000});
          navigate(-1);
        }
      })
      .catch((err) => {
        setSpinner(false)
        // console.log(err?.response?.data?.response)
        setValidationError(err?.response?.data?.response);
      });
  };

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <VehicleRequestForm
                  handleChange={handleChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  mode="add"
                  setFieldValue={setFieldValue}
                  validationError={validationError}
                  spinner={spinner}
                  state=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVechileRequest;
