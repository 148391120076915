import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

function ScopeOfWork({ projectId }) {
  const [workOfScopeData, setWorkOfScopeData] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const excelContent = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheetName],
        { defval: "" }
      );

      setWorkOfScopeData(excelContent);
    };

    reader.readAsBinaryString(file);
  };

  function uploadExcel() {
    let excelData = workOfScopeData;
    if (workOfScopeData) {
      for (var i = 0; i < excelData.length; i++) {
        var currentObject = excelData[i];
        var keys = Object.keys(currentObject);
        var modifiedObject = {};

        for (var j = 0; j < keys.length; j++) {
          var currentKey = keys[j];
          var newKey = currentKey.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");
          modifiedObject[newKey] = currentObject[currentKey];
        }
        excelData[i] = modifiedObject;
      }
      setSpinner(true);
      let url = apiurl + "importscopeofwork";
      let data = { project_id: projectId, user_id, XLSXRecord: excelData };
      const getToken = user.response.token;
      const bearer_token = `Bearer ${getToken}`;
      axios
        .post(url, data, {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          navigate("/projects");
          toast.success("Excel Uploaded Successfully");
          setSpinner(false);
        })
        .catch((error) => {
          if (error.response.status == 422) {
            toast.error("Invaild Excel Format");
          } else {
            toast.error("Something Went Wrong");
          }
          setSpinner(false);
        });
    }
  }

  return (
    <>
      <form>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="" className="form-label fw-bold">
                Upload Excel
              </label>
              <input
                type="file"
                className="form-control"
                accept=".xls,.xlsx"
                onChange={handleFileChange}
              ></input>
            </div>
          </div>
          <div className="col-8"></div>
        </div>
        {spinner ? (
          <div className="">
            <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
            <p>Wait a moment....</p>
          </div>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm me-3 mt-3"
              onClick={() => uploadExcel()}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-de-danger btn-sm border-danger me-3 mt-3"
              onClick={() => navigate("/projects")}
            >
              Cancel
            </button>
          </>
        )}
      </form>
    </>
  );
}

export default ScopeOfWork;
