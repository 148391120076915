import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { useState } from "react";

function Modals(props) {
  const [deleteAttachmentId, setDeleteAttachmentId] = useState(null);
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  function deleteAttachment(attachmentId) {
    const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
    const user = JSON.parse(localStorage.getItem("profile"));
    const user_id = user?.response?.userDetails?.id;
    const getToken = user.response.token;
    const bearer_token = `Bearer ${getToken}`;
    axios
      .post(
        apiurl + props.url,
        { attachmentId, user_id },
        {
          headers: {
            Authorization: bearer_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          props.setRenderState(!props.renderState);
          toast.success("Attachment Deleted successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Attachments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {props.data?.map((tdc_url, index) => (
              <div key={index} className="col-6">
                {deleteAttachmentId === tdc_url.id ? (
                  <div className="row">
                    <div className="d-flex justify-content-between">
                      <p className="fw-bold">Are you sure?</p>

                      <span style={{ marginleft: "10rem", cursor: "pointer" }}>
                        <button
                          className="btn-danger btn-sm fw-bold me-3"
                          onClick={() => deleteAttachment(tdc_url.id)}
                        >
                          {" "}
                          yes
                        </button>
                        <button
                          className="btn-warning btn-sm fw-bold"
                          onClick={() => setDeleteAttachmentId(null)}
                        >
                          {" "}
                          No
                        </button>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="d-flex justify-content-between">
                      <span>
                        <Link
                          to={attachmentBaseUrl + tdc_url.name}
                          target="_blank"
                          className="fw-bold"
                        >{`Attachment ${index + 1}`}</Link>
                      </span>
                      <span
                        onClick={() => setDeleteAttachmentId(tdc_url.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <MdDelete />
                      </span>
                    </div>
                  </div>
                )}

                <iframe
                  src={attachmentBaseUrl + tdc_url.name}
                  width="340"
                  height="200"
                  title="Attachment Preview"
                  frameBorder="0"
                  border="0"
                  className="w-100"
                  style={{ border: "1px solid #ccc" }}
                ></iframe>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modals;
