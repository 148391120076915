import React from "react";

const PolicyDetailCard = ({ selectedProbationPolicy }) => {
  return (
    <section className="policy-card">
      <div
        className="d-flex justify-content-evenly align-items-center p-3 mx-4 mt-5 border "
        style={{
          borderRadius: "23px",
        }}
      >
        <div className="ps-1">
          <p>Duration</p>
          <h6>{selectedProbationPolicy?.duration}</h6>
        </div>
        <div>
          <p>Max Probation Extension</p>
          <h6>{selectedProbationPolicy?.max_extension}</h6>
        </div>
      </div>
    </section>
  );
};

export default PolicyDetailCard;
