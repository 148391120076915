import React, { useState } from "react";
import DegreeIcon from "../../../Assets/DegreeIcon.png";
import idcard from "../../../Assets/id-card.png";
import suitcase from "../../../Assets/suitcase.png";
import open from "../../../Assets/open.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import upload from "../../../Assets/upload.png";
import UploadDegreeDetail from "./UploadDegreeDetail";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "../../HrDepartment.scss"
import { useDispatch, useSelector } from "react-redux";
import { selectLoading, setLoading } from "../../../redux/features/Hr/LoaderSlice";
import { updateDocument } from "../../hrApi";
import { convertFileToBase64} from "../../CommonFunction";
const DocumentDetail = ({data,employeeDetail}) => {
  const [activeTab, setActiveTab] = useState("Document&Certificates");
  const[validationError,setValidationError] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [image,setImage] = useState("")
  const[showDetail,setShowDetail] = useState(false)
  const dispatch = useDispatch()
  const loader = useSelector(selectLoading)
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
  
    // Set image preview
    setImage(URL.createObjectURL(file));
  
    // Convert the file to Base64 using the utility function
    convertFileToBase64(file, (base64) => {

      setFieldValue("document", base64);  // Set the base64 string in the form field
    });
  };
 
  const handleclose = () => {
    setShowModal(false);
    setImage(null)
  };
  const handleToggle = (eventKey) => {
    setActiveTab(eventKey);
  };
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState("");
  const handleFocus = (event) => {
    const { name } = event.target;
    setIsFocused(name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };
  const initialValues = {
    type:"degree",
    name:"degree",
    document:""
  
  
  };
  const {
    values,
    errors,
    touched,
    handleChange,
 
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: workDetailSchema,
    onSubmit: (values) => {
      uploadeEmployeeDocuments()
    },
  });

  const uploadeEmployeeDocuments =async()=>{
    dispatch(setLoading(true))
    const reqpayload ={employee_id:data?.id,data:[{...values}]}
    try{
const response = await updateDocument(reqpayload)

setShowDetail(true)
setShowModal(false)
toast.success(response?.data?.response)
    }
    catch(error){
      toast.error(error?.response?.data?.message)
      setValidationError(error?.response?.data?.message)
    }

  }
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="card" style={{ borderRadius: "5px" }}>
            <div className="document-type">
              <h4>Documents Type</h4>
              <div className="horizontal-row"></div>
              <div
                className={
                  activeTab == "Document&Certificates" ? " document-active m-2 rounded m-2" : "m-2 "
                }
              >
                <div
                  className="d-flex  ps-2 "
                  onClick={() => {
                    handleToggle("Document&Certificates");
                  }}
                >
                  <div className="img-div">
                    {" "}
                    <img
                      className="documentType-icon p-1 "
                      src={DegreeIcon}
                    />{" "}
                  </div>

                  <div>
                    <h6 className="heading"> Document&Certificates</h6>
                    <p className="heading-value">2 Certificates</p>
                  </div>
                </div>
              </div>
              <div
                className={activeTab == "Identity" ? " document-active m-2 rounded m-2" : "m-2 "}
              >
                <div
                  className="d-flex ps-2"
                  onClick={() => {
                    handleToggle("Identity");
                  }}
                >
                  <div className="img-div">
                    {" "}
                    <img className="documentType-icon  " src={idcard} />{" "}
                  </div>

                  <div>
                    <h6 className="heading"> Identity</h6>
                    <p className="heading-value">5 Documents</p>
                  </div>
                </div>
              </div>
              <div
                className={
                  activeTab == "Previous Experience" ? " document-active m-2 rounded m-2" : "m-2 "
                }
              >
                <div
                  className="d-flex ps-2"
                  onClick={() => {
                    handleToggle("Previous Experience");
                  }}
                >
                  <div className="img-div ">
                    {" "}
                    <img
                      className="documentType-icon p-1 "
                      src={suitcase}
                    />{" "}
                  </div>

                  <div>
                    <h6 className="heading"> Previous Experience</h6>
                    <p className="heading-value">2 Documents</p>
                  </div>
                </div>
              </div>{" "}
              <div
                className={
                  activeTab == "Employee Letter" ?" document-active m-2 rounded" : "m-2 "
                }
              >
                <div
                  className="d-flex ps-2"
                  onClick={() => {
                    handleToggle("Employee Letter");
                  }}
                >
                  <div className="img-div ">
                    {" "}
                    <img className="documentType-icon p-1 " src={open} />{" "}
                  </div>

                  <div>
                    <h6 className="heading"> Employee Letter</h6>
                    <p className="heading-value">1 Documents</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className="card"
            // style={{ borderRadius: "5px", width: "705px", height: "300px" }}
          >
            <div className="document-upload p-4">
              <h4>Degree & Certificates</h4>
              <p className="detail-section">
                This section contains details about all the Degrees &
                Certificates of an employee.
              </p>
          {   showDetail? <UploadDegreeDetail/>
           :<div className="d-flex align-items-center justify-content-between upload-degree mt-4">
                <div>
                  {" "}
                  <h4>Upload Degree</h4>
                </div>

                <button type="button" class="hr btn btn-primary" onClick={() => setShowModal(true)}>
                  Upload Document
                </button>
              </div>
             }
              <div className="d-flex align-items-center justify-content-between upload-degree mt-4">
                <div className="text-center">
                  {" "}
                  <h4>Upload Certificates</h4>
                </div>
                <button
                  type="button"
                  class="hr btn btn-primary"
                  
                >
                  Upload Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
        size="lg"
      className="document-modal"
          show={showModal}
          onHide={handleclose}
          // backdrop="static"
          // keyboard={false}
          centered
        >
          <Modal.Header className="modal-header" closeButton style={{backgroundColor:"white"}}>
            <Modal.Title className="modal-heading">Upload Degree</Modal.Title>
          </Modal.Header>
          <Modal.Body>
        
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6 upload-file-modal  ms-0  ps-4 py-3">
                  <label  htmlFor="upload-doc" >
                    <img
                      style={{     padding: "1rem 1rem 0rem 9rem" }}
                      src={upload}
                    />
                    <p style = {{paddingLeft:"5rem"}}>Drop files here or click to upload.</p>
                  </label>
                  <input
                    type="file"
                    id="upload-doc"
                    name="upload-doc"
                    multiple
                    onChange={(event)=>{handleImageChange(event)}}
                    style={{ paddingLeft: ".75rem", display: "none" }}
                    className="form-control form-control-sm border-secondary"
                    onFocus={(event) => handleFocus(event)}
                    onBlur={handleBlur}
                    placeholder="Drop files here or click to upload."
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-5 "><img className ="img-prev" src={image}/></div>

                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "college-name" ? "text-danger" : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    College/ University
                  </label>
                  <div
                    className={`${
                      isFocused == "college-name"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                      type="text"
                      name="college-name"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Enter College/ University "
                    />
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "degree" ? "text-danger" : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    Degree
                  </label>
                  <div
                    className={`${
                      isFocused == "degree"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                      type="text"
                      name="degree"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Enter Middle Name"
                    />
                  </div>
                </div>
                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "branch-name" ? "text-danger" : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    Branch/ Specialization
                  </label>
                  <div
                    className={`${
                      isFocused == "branch-name"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                      type="text"
                      name="branch-name"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "joining" ? "text-danger" : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    Year of Joining
                  </label>
                  <div
                    className={`${
                      isFocused == "joining"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                    type="date"
                      name="joining"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Select Gender"
                    >
                      
                    </input>
                  </div>
                </div>
                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "completeion-year"
                        ? "text-danger"
                        : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    Year of Completion
                  </label>
                  <div
                    className={`${
                      isFocused == "completeion-year"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                      type="text"
                      name="completeion-year"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Enter Mobile Number"
                    />
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-5">
                  <label
                    htmlFor=""
                    className={`form-label fw-bold font-12 font-weight-500  ${
                      isFocused == "cgpa" ? "text-danger" : "text-black"
                    }`}
                    style={{ paddingLeft: ".75rem", marginTop: "1rem" }}
                  >
                    CGPA/ Percentage
                  </label>
                  <div
                    className={`${
                      isFocused == "cgpa"
                        ? "add_emp_input_active"
                        : "add_emp_input"
                    }`}
                  >
                    <input
                      type="text"
                      name="cgpa"
                      style={{ paddingLeft: ".75rem" }}
                      className="form-control form-control-sm border-secondary"
                      onFocus={(event) => handleFocus(event)}
                      onBlur={handleBlur}
                      onChange ={handleChange}
                      placeholder="Enter Email Address"
                    />
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <Button className="btn btn-outline-secondary text-black" variant="outline-secondary"  onClick={handleclose}>
                  Cancel
                </Button>
                <Button  className="hr btn btn-primary"type="submit" variant="primary" 
                 > Upload Document</Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DocumentDetail;
