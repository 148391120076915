import React from "react";

import JobWorkTimeCommonTable from "../../MaterialUiComponent/JobWorkTimeCommonTable";
import ExitSearchBar from "./ExitSearchBar";

const NoticePeriod = ({tabName}) => {
  const columns = [
    { id: "name", label: "Name ", minWidth: "6rem" }, // Increase minWidth
    { id: "description", label: "Description", minWidth: "30rem" },
    { id: "duration", label: "Duration", minWidth: "8rem" }, // Increase minWidth
    { id: "appliesTo", label: "Applies To", minWidth: "8rem" }, // Increase minWidth
    { id: "actions", label: "Action", minWidth: 100 }, // Keep as is
  ];

  // Sample data
  const rows = [
    {
      name: "1 Month Notice Period",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has department:been the industry",
      appliesTo: "2Employees",
      duration: "1 Month",
    },
    {
      name: "Intern Notice Period",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has department:been the industry",
      appliesTo: "2Employees",
      duration: "15days",
    },
    {
      name: "Role-Based Notice Periods",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has department:been the industry",
      AppliedTo: "2Employees",
      duration: "as per role ",
    },
    // Add more sample data here
  ];
  const departmentChange = () => {
    console.log(tabName);
  };
  return (
    <section className="notice-period">
      <div className="bg-white">
     
        <ExitSearchBar tabName={tabName} departmentChange={departmentChange} />
        <JobWorkTimeCommonTable columns={columns} rows={rows}/>
      </div>
    </section>
  );
};

export default NoticePeriod;
