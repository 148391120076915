import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
function Home() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));

  return (
    <div>
      {userProfile ? (
        <Navigate to="/dashboard"></Navigate>
      ) : (
        <Navigate to="/login"></Navigate>
      )}
    </div>
  );
}
export default Home;
