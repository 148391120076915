import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useLocation } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "./Style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ErrorPage from "../Components/ErrorPage";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { setLogout } from "../redux/features/authSlice";
import { useDispatch } from "react-redux";
import { MdErrorOutline } from "react-icons/md";

function ProjectsTeam() {
  const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const placeholderImage = imageBaseUrl + "assets/user.jpg";
  const baseURL = apiurl;
  const [currentPage, setCurrentPage] = useState(1);
  const [departs, setDeparts] = useState(null);
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [per_page, setPer_page] = useState();
  const [totalNumbers, setTotalNumbers] = useState(0);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [networkError, setNetworkError] = useState(false);
  const [departmentProject, setDepartmentProject] = useState([]);
  const [projectDetailModal, setProjectDetailModal] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const dispatch = useDispatch();
  let location = useLocation();

  const handleClose = () => {
    setProjectDetailModal(false);
    setDescriptionModal(false);
  };

  const navigate = useNavigate();

  function getDepartments(page) {
    setLoader(true);
    setNetworkError(false);
    const url = baseURL + "getdepartmentlist";
    var body = { user_id: user_id, search, accessType: "view" };
    axios
      .post(url, body, config)
      .then((res) => {
        setDeparts(res?.data?.response?.departmentsList);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(setLogout());
        }
        if (err?.code === "ERR_NETWORK") {
          setNetworkError(true);
        }

        setLoader(false);
      });
  }

  useEffect(() => {
    getDepartments(currentPage);
  }, [currentPage, search]);

  //////////// PAGINATION ////////////////

  const recordsPerPage = 6;
  const npage = departs ? Math.ceil(totalNumbers / recordsPerPage) : [];
  const numbers = [...Array(npage + 1).keys()].slice(1);

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage((prevPage) => {
        const prePageValue = prevPage - 1;
        getDepartments(prePageValue);
        return prePageValue;
      });
    }
  }

  function changeCpage(id) {
    setCurrentPage(id);
    getDepartments(id);
  }
  ///////////// PAGINATION END //////////////

  function getDepartmentProject(id) {
    let depProject = departs?.find((e) => e.id === id);
    setDepartmentProject(depProject.departmentProjects);
    setProjectDetailModal(true);
  }

  return (
    <>
      

      {networkError ? (
        <ErrorPage />
      ) : (
        <div className="page-wrapper">
          <div className="page-content-tab">
            <div className="container-fluid">
              <div>
                <div className="row mb-4">
                  <div className="col-lg-7">
                    <div className="page-title-box pb-0">
                      <h4 className="page-title pb-2 font-48">DEPARTMENT</h4>
                    </div>
                    <h5 className="m-0 font-14">
                      Here is the list of all the departments.{" "}
                    </h5>
                  </div>

                  <div className="col-lg-5 text-end pt-4 d-flex gap-4">
                    <div className="input-group">
                      <input
                        type="text"
                        id="example-input1-group2"
                        name="example-input1-group2"
                        className="form-control form-control-sm border-danger"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button type="button" className="btn btn-primary btn-sm">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                    <Link to="/department/add-department">
                      <button type="button" className="btn btn-primary btn-sm">
                        Create&nbsp;department
                      </button>
                    </Link>
                  </div>
                </div>

                {loader ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "100vh" }}
                  >
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="danger" />
                  </div>
                ) : (
                  <div className="row gy-5">
                    {departs?.map((element, index) => {
                      return (
                        <div
                          className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"
                          key={index}
                        >
                          <div className="department__card bg-white">
                            <div className="depart_info">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    element.image
                                      ? imageBaseUrl + element.image
                                      : placeholderImage
                                  }
                                  alt="user"
                                  className="rounded-circle thumb-md"
                                />
                                <div className="ms-2">
                                  <Link
                                    to={`/department/department-detail/${element.id}`}
                                  >
                                    {" "}
                                    <h4 className="mb-1 fw-bold text-dark font-18">
                                      {element.name}
                                    </h4>
                                  </Link>
                                  <h6 className="m-0 font-12">
                                    {element.email}
                                  </h6>
                                </div>
                              </div>
                              <div className="dropdown d-inline-block">
                                <NavLink
                                  to={`/department/edit-department/${element.id}`}
                                >
                                  <i
                                    className="las la-pen"
                                    style={{
                                      fontSize: "24px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </NavLink>
                                <div></div>
                              </div>
                            </div>
                            <div
                              className="mt-3 mb-2 department_description"
                              id="department_description"
                              style={{
                                minHeight: "5.5rem",
                                maxHeight: "5.5rem",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDescription(element?.description);
                                setDescriptionModal(true);
                              }}
                            >
                              {element.description.length > 150
                                ? element.description.slice(0, 150) + "..."
                                : element.description}
                            </div>
                            <div>
                              <h6>
                                Department Head:{" "}
                                <span style={{ fontWeight: 700 }}>
                                  {element?.departmentHead?.name}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {totalNumbers > 6 && (
                  <div className="text-end mt-3">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm mb-0 justify-content-end">
                        <li className="page-item">
                          <Link
                            className="page-link"
                            to=""
                            onClick={() => prePage()}
                          >
                            Previous
                          </Link>
                        </li>
                        {numbers.map((e, i) => {
                          return (
                            <li
                              className={`page-item ${
                                currentPage === e ? "active" : ""
                              }`}
                              key={i}
                            >
                              <Link
                                to=""
                                className="page-link"
                                onClick={() => changeCpage(e)}
                              >
                                {e}
                              </Link>
                            </li>
                          );
                        })}

                        <li className="page-item">
                          <Link
                            className="page-link"
                            to=""
                            onClick={() => nextPage()}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal show={projectDetailModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="p-0 m-0">Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table
              className="table table-striped mb-0"
              style={{ border: "1px solid #f0f0f0" }}
            >
              <tbody className="p-0">
                {departmentProject?.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <NavLink to={`/ProjectsProjectDetail/${e.project_id}`}>
                          <img
                            src={
                              e?.projectImage
                                ? imageBaseUrl + e?.projectImage
                                : placeholderImage
                            }
                            alt=""
                            className="rounded-circle thumb-xs me-1"
                          />{" "}
                          {e.project_title}
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={descriptionModal}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton className="m-0 p-3">
          <Modal.Title className="m-0 p-0">Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ maxHeight: "300px", overflowY: "scroll" }}
            className="my-3"
          >
            {description}
          </div>
        </Modal.Body>
      </Modal>
      <Modal.Footer></Modal.Footer>

      <Footer />
    </>
  );
}
export default ProjectsTeam;
