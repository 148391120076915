import React from "react";

function CommonTh({ value, width }) {
  
  return (
    <>
      <th>
        <p
          className="font-18"
          style={{ minWidth: width, maxWidth: width,color:"white"}}
        >
          {value}
        </p>
      </th>
    </>
  );
}

export default CommonTh;
