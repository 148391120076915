import React, { useState } from "react";
import "../../BackofficeStyle/BackofficeStyle.css";
import BackOfficeTableTh from "../../Common/BackOfficeTableTh";
import BackOfficeTableTd from "../../Common/BackOfficeTableTd";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function PgTracker({ projects, loader }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  return (
    <>
      <div
        className="table-responsive table-container-scroll"
        style={{ overflowX: "auto" }}
      >
        <table
          className="table table-striped sd-list-table"
          style={{ tableLayout: "unset" }}
        >
          <thead className="thead-light">
            <tr>
              <BackOfficeTableTh
                value="Railway Zone"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Sub Division"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="PO/LOA
              No."
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="PO/LOA
              Date"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Item
              Description"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh value="Amount" width=" "></BackOfficeTableTh>
              <BackOfficeTableTh value="Status" width=" "></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Transaction No."
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Transaction Date"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Expiry
              Date"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Return
              Date"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Return
              Amount"
                width=" "
              ></BackOfficeTableTh>
              <BackOfficeTableTh
                value="Due
              Amount"
                width=" "
              ></BackOfficeTableTh>
            </tr>
          </thead>
          <tbody>
            {projects?.map((project, i) => (
              <tr key={i}>
                <BackOfficeTableTd
                  value={project.pd_railwayZone}
                  width=""
                ></BackOfficeTableTd>
                <BackOfficeTableTd
                  value={project.pd_subDivision}
                  width=""
                ></BackOfficeTableTd>
                <BackOfficeTableTd
                  value={project.pd_poloaNo}
                  width=""
                ></BackOfficeTableTd>
                <BackOfficeTableTd
                  value={formatDate(project.pd_poloaDate)}
                  width=""
                ></BackOfficeTableTd>
                <BackOfficeTableTd
                  value={project.pd_projectItemDescription}
                  width=""
                ></BackOfficeTableTd>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(formatNumber(element.pg_toPay))}
                      >
                        <h6>
                          {element.pg_toPay
                            ? formatNumber(element.pg_toPay)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(element.pg_returnStatus)}
                      >
                        <h6>
                          {element.pg_returnStatus
                            ? element.pg_returnStatus
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(element?.pg_paidTransactionNo)}
                      >
                        <h6>
                          {element?.pg_paidTransactionNo
                            ? element?.pg_paidTransactionNo
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(
                          element?.pg_paidTransactionNoDate
                        )}
                      >
                        <h6>
                          {element?.pg_paidTransactionNoDate
                            ? formatDate(element?.pg_paidTransactionNoDate)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(element?.pg_expiryDate)}
                      >
                        <h6>
                          {element?.pg_expiryDate
                            ? formatDate(element?.pg_expiryDate)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(element?.pg_returnDate)}
                      >
                        <h6>
                          {element?.pg_returnDate
                            ? formatDate(element?.pg_returnDate)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(
                          formatNumber(element?.pg_returnAmount)
                        )}
                      >
                        <h6>
                          {element?.pg_returnAmount
                            ? formatNumber(element?.pg_returnAmount)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
                <td>
                  {project.pgMultipleData?.map((element, index) => {
                    return (
                      <OverlayTrigger key={index}
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(formatNumber(element?.pg_due))}
                      >
                        <h6>
                          {element?.pg_due
                            ? formatNumber(element?.pg_due)
                            : "---"}
                        </h6>
                      </OverlayTrigger>
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default PgTracker;
