import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import logo from "../../Assets/Group 1000003296.png";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";
import {
  createLegalEntityApi,
  updateLeagalEntityApi,
} from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
import { LocalAtmSharp } from "@mui/icons-material";
import { leagalEntitySchema } from "../OrganizationComponents/OrganizationSchema";
import ShowError from "../OrganizationComponents/ShowError";
import { useDispatch } from "react-redux";
import { fetchLegalEntity } from "../../../../redux/features/Hr/LegalEntitySlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const LeagalEntityModal = ({ open, handleClose, editData }) => {
  const [loader, setLoader] = useState(false);
const dispatch = useDispatch()
  const initialValues = {
    entity_name: "",
    legal_name: "",
    image: "",
    cin: "",
    date_of_incorp: "",
    business_type: "",
    sector: "",
    business_nature: "",
    address_line_1: "",
    address_line_2: "",
    country: "India",
    state: "",
    city: "",
    zip: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: leagalEntitySchema,
    onSubmit: (values) => {
      if (editData) {
        updateLeagalEntity(values);
      } else {
        addLeagalEnity(values);
      }
    },
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteLogo = () => {
    setFieldValue("image", "");
  };

  async function addLeagalEnity(value) {
    setLoader(true);
    try {
      const res = await createLegalEntityApi(value);
      toast.success("Leagal Enity Added Successfully");
      handleClose();
      // getLegalEntity();
      dispatch(fetchLegalEntity(dispatch))
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }

  async function updateLeagalEntity(value) {
    try {
      const res = await updateLeagalEntityApi(value);
      toast.success("Leagal Enity Updated Successfully");
      handleClose();
      dispatch(fetchLegalEntity(dispatch))
    } catch (error) {
      console.log("update", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (editData) {
      setValues({ ...editData, image: "" });
    } else {
      resetForm();
    }
  }, [editData,open]);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {editData ? "Edit" : "Add"} Legal Entity
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-7 mb-4">
                  <FormControl fullWidth>
                    <div
                      className="file-upload p-3 d-flex justify-content-center align-items-center"
                      style={{
                        border: "1px dashed #4667B6",
                        borderRadius: "1rem",
                        backgroundColor: "#F2F6FF",
                      }}
                    >
                      <div className="upload-icon text-center">
                        <label
                          htmlFor="file-upload"
                          className="d-inline-block text-center"
                        >
                          <FileUploadOutlinedIcon
                            style={{ color: "#4667B6", fontSize: "3rem" }}
                          />
                          <h5 style={{ color: "#4667B6", fontSize: ".9rem" }}>
                            Drop logo here or click to upload
                          </h5>
                        </label>
                      </div>
                    </div>
                    <TextField
                      type="file"
                      name="image"
                      value={values.file}
                      onChange={handleFileUpload}
                      onBlur={handleBlur}
                      id="file-upload"
                      variant="outlined"
                      hidden
                    />
                    <ShowError touched={touched.image} message={errors.image} />
                  </FormControl>
                </div>
                <div className="col-3 mb-4">
                  <div className="uploaded-image position-relative">
                    {values.image && (
                      <img
                        className="w-100 rounded-3"
                        src={values.image}
                        alt="Logo"
                        style={{ height: "7.4rem" }}
                      />
                    )}
                    {values.image && (
                      <span className="d-inline-block position-absolute top-0 start-100 translate-middle">
                        <button
                          type="button"
                          className="d-inline-block border-0 bg-transparent"
                          onClick={handleDeleteLogo}
                        >
                          <CancelIcon className="text-primary" />
                        </button>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="entity_name"
                      id=""
                      label="Entity Name"
                      variant="outlined"
                      value={values.entity_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.entity_name}
                      message={errors.entity_name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      id=""
                      label="Legal Name of the Company"
                      name="legal_name"
                      value={values.legal_name}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.legal_name}
                      message={errors.legal_name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      id=""
                      name="cin"
                      value={values.cin}
                      label="company Identification Number"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError touched={touched.cin} message={errors.cin} />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      size="small"
                      name="date_of_incorp"
                      value={values.date_of_incorp}
                      id=""
                      type="date"
                      //  label="Date of Incorporation"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.date_of_incorp}
                      message={errors.date_of_incorp}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Type of Business
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type of Business"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="business_type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.business_type}
                    >
                      <MenuItem value={"Public Sector"}>Public Sector</MenuItem>
                      <MenuItem value={"Private Limited"}>
                        Private Limited
                      </MenuItem>
                      <MenuItem value={"Limited Liability"}>
                        Limited Liability
                      </MenuItem>
                      <MenuItem value={"Public Limited"}>
                        Public Limited
                      </MenuItem>
                      <MenuItem value={"Proprietorship"}>
                        Proprietorship
                      </MenuItem>
                      <MenuItem value={"Partnership Firm"}>
                        Partnership Firm
                      </MenuItem>
                      <MenuItem value={"Trust"}>Trust</MenuItem>
                      <MenuItem value={"NGO"}>NGO</MenuItem>
                      <MenuItem value={"Hindu Undivided Family"}>
                        Hindu Undivided Family
                      </MenuItem>
                    </CustomSelect>
                    <ShowError
                      touched={touched.business_type}
                      message={errors.business_type}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Sector
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sector"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="sector"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sector}
                    >
                      <MenuItem value={"Manufacturing Industry"}>
                        Manufacturing Industry
                      </MenuItem>
                      <MenuItem value={"Trading"}>Trading</MenuItem>
                      <MenuItem value={"Commission Agents"}>
                        Commission Agents
                      </MenuItem>
                      <MenuItem value={"Builder"}>Builder</MenuItem>
                      <MenuItem value={"Constractors"}>Constractors</MenuItem>
                      <MenuItem value={"Professionals"}>Professionals</MenuItem>
                      <MenuItem value={"Service Sector"}>
                        Service Sector
                      </MenuItem>
                      <MenuItem value={"Finanical Service Sector"}>
                        Finanical Service Sector
                      </MenuItem>
                      <MenuItem value={"Entertainment Industry"}>
                        Entertainment Industry
                      </MenuItem>
                    </CustomSelect>
                    <ShowError
                      touched={touched.sector}
                      message={errors.sector}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Nature of Business
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Nature of Business"
                      size="small"
                      name="business_nature"
                      value={values.business_nature}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                    >
                      <MenuItem value={"Agro-based industries"}>
                        Agro-based industries
                      </MenuItem>
                      <MenuItem value={"Automobile and Auto parts"}>
                        Automobile and Auto parts
                      </MenuItem>
                      <MenuItem value={"Cement"}>Cement</MenuItem>
                      <MenuItem value={"Diamond cutting"}>
                        Diamond cutting
                      </MenuItem>
                      <MenuItem value={"Durgs and Pharmaceuticals"}>
                        Durgs and Pharmaceuticals
                      </MenuItem>
                      <MenuItem
                        value={"Electronics including Computer Hardware"}
                      >
                        Electronics including Computer Hardware
                      </MenuItem>
                      <MenuItem value={"Engineering goods"}>
                        Engineering goods
                      </MenuItem>
                      <MenuItem value={"Fertilizers, Chemicals, Paints"}>
                        Fertilizers, Chemicals, Paints
                      </MenuItem>
                      <MenuItem value={"Flour & Rice Mills"}>
                        Flour & Rice Mills
                      </MenuItem>
                      <MenuItem value={"Marble & Granite"}>
                        Marble & Granite
                      </MenuItem>
                      <MenuItem value={"Paper"}>Paper</MenuItem>
                      <MenuItem value={"Petroleum and Petrochemicals"}>
                        Petroleum and Petrochemicals
                      </MenuItem>
                      <MenuItem value={"Power and energy"}>
                        Power and energy
                      </MenuItem>
                      <MenuItem value={"Rubber"}>Rubber</MenuItem>
                      <MenuItem value={"Steel"}>Steel</MenuItem>
                      <MenuItem value={"Suger"}>Suger</MenuItem>
                      <MenuItem value={"Tea,Coffee"}>Tea,Coffee</MenuItem>
                      <MenuItem value={"Textiles, Handloom, Powerlooms"}>
                        Textiles, Handloom, Powerlooms
                      </MenuItem>
                      <MenuItem value={"Tobacco"}>Tobacco</MenuItem>
                      <MenuItem value={"Tyre"}>Tyre</MenuItem>
                      <MenuItem value={"Vanaspati & Edible Oils"}>
                        Vanaspati & Edible Oils
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                      <MenuItem value={"Electrical"}>Electrical</MenuItem>
                    </CustomSelect>
                    <ShowError
                      touched={touched.sector}
                      message={errors.sector}
                    />
                  </CustomFormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5
                    className="text-uppercase fw-normal mb-4"
                    style={{ color: "#A6A6A6" }}
                  >
                    Address Detail
                  </h5>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 1"
                      variant="outlined"
                      size="small"
                      name="address_line_1"
                      value={values.address_line_1}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_1}
                      message={errors.address_line_1}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 2"
                      name="address_line_2"
                      variant="outlined"
                      value={values.address_line_2}
                      onBlur={handleBlur}
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_2}
                      message={errors.address_line_2}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="State"
                      name="state"
                      value={values.state}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_2}
                      message={errors.address_line_2}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="City"
                      name="city"
                      value={values.city}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                      size="small"
                    ></CustomTextField>
                    <ShowError touched={touched.city} message={errors.city} />
                  </CustomFormControl>
                </div>
                <div className="col-6">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Zip Code"
                      name="zip"
                      value={values.zip}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                      size="small"
                    ></CustomTextField>
                    <ShowError touched={touched.zip} message={errors.zip} />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Edit Entity "
                ) : (
                  "Add Entity"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default LeagalEntityModal;
