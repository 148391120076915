import React, { useCallback, useEffect, useRef, useState } from "react";
import CollapsibleTable from "./GoodsReceiptNoteTable";
import { Link } from "react-router-dom";
import { errorsMessage } from "../../constant";
import { getGrnListing } from "../commonComponents/storeApi";
import { Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import BasicPagination from "../commonComponents/Pagination";
import GoodsReceiptNoteFilters from "./GoodsReceiptNoteFilters";

function GoodReceiptNote() {
  const [grnData, setGrnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const departmentName = userProfile?.response?.userDetails?.departmentName;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [filters, setFilters] = useState({
    store_id: "",
    document_no: "",
    startDate: null,
    endDate: null,
  });
  function grnList(term, page = 1) {
    getGrnListing({ ...term, user_id, page: page })
      .then((res) => {
        setGrnData(res?.data?.response?.GrnListing?.data);
        setPageCount(res?.data?.response?.GrnListing?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const debouncedFetchGRNList = useCallback(
    debounce((term, page) => {
      grnList(term, page);
    }, 600),
    [user_id]
  );

  function handleFilterChange(name, value) {
    setFilters({ ...filters, [name]: value });
  }

  useEffect(() => {
    debouncedFetchGRNList(filters, page);
  }, [filters, debouncedFetchGRNList, page]);

  function handlePaginateChange(event, value) {
    setPage(value);
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <div className="row d-flex mx-3 my-3">
              <div className="col-md-2">
                <h4>GOODS RECEIPT NOTE</h4>
              </div>
              <div className="col-md-10">
                <GoodsReceiptNoteFilters
                  handleFilterChange={handleFilterChange}
                  filters={filters}
                />
              </div>
            </div>

            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <CollapsibleTable
                grnData={grnData}
                departmentName={departmentName}
              />
            )}
            <div className="text-end mt-2">
              <BasicPagination
                handlePaginateChange={handlePaginateChange}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodReceiptNote;
