import React, { useEffect, useState } from "react";
import BackOfficeTableTh from "../../../Common/BackOfficeTableTh";
import BackOfficeTableTd from "../../../Common/BackOfficeTableTd";
import "../../../BackofficeStyle/BackofficeStyle.css";
import { errorsMessage } from "../../../../constant";
import { getWarrantyTrackerList } from "../../../../redux/api";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDropdowns } from "../../../../redux/features/allDropdownSlice";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function WarrantyTrackerTable() {
  const [loading, setLoading] = useState(false);
  const [warrantyTrackerList, setWarrantyTrackerList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const allDropdowns = useSelector(selectDropdowns);
  const [initialStatus, setInitialStatus] = useState([]);
  const [changeAssigne, setChangeAssigne] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [trackerId, setTrackerId] = useState(null);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const navigate = useNavigate();

  function formatDate(value) {
    if (!value) {
      return false;
    }
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function formatNumber(value) {
    if (!value) {
      return false;
    }
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }
  function warrantyTrackerData() {
    setLoading(true);
    getWarrantyTrackerList({ user_id })
      .then((res) => {
        setWarrantyTrackerList(res?.data?.response?.TrakerList);
        let trackerList = res?.data?.response?.TrakerList;
        const arrayList = Array.from(
          { length: trackerList.length },
          (_, index) => ({
            loading: false,
          })
        );
        setInitialStatus(JSON.parse(JSON.stringify(arrayList)));
        setChangeAssigne(JSON.parse(JSON.stringify(arrayList)));
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function changeStatusList(e, trackerId, index) {
    setInitialStatus((prevInitialStatus) => {
      const updatedStatus = [...prevInitialStatus];
      updatedStatus[index].loading = true;
      return updatedStatus;
    });

    let newStatusId = e.target.value;
    const url = baseUrl + "changewarrantytrackerstatus";
    const data = {
      user_id,
      trackerId,
      Status: newStatusId,
    };

    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          warrantyTrackerData();
          toast.success("Status changed successfully");
        }
      })
      .catch((error) => {
        setInitialStatus((prevInitialStatus) => {
          const updatedStatus = [...prevInitialStatus];
          updatedStatus[index].loading = false;
          return updatedStatus;
        });
        errorsMessage(error.status);
      });
  }

  function changeAssigned(e, trackerId, index) {
    setChangeAssigne((prevAsignee) => {
      const updateAssignee = [...prevAsignee];
      updateAssignee[index].loading = true;
      return updateAssignee;
    });

    let newAssigneeId = e.target.value;
    const url = baseUrl + "changewarrantytrackerassignee";
    const data = {
      user_id,
      trackerId,
      AssignedTo: newAssigneeId,
    };

    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          warrantyTrackerData();
          toast.success("Assignee changed successfully");
        }
      })
      .catch((error) => {
        setChangeAssigne((prevAsignee) => {
          const updateAssignee = [...prevAsignee];
          updateAssignee[index].loading = false;
          return updateAssignee;
        });
        errorsMessage(error.status);
      });
  }

  function deleteWarrantyTracker() {
    let url = baseUrl + "deletewarrantytracker";
    let data = { user_id, trackerId: trackerId };
    axios
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          warrantyTrackerData();
          handleClose();
          toast.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong , Please try again later");
      });
  }
  function handleClose() {
    setDeleteModal(false);
  }
  useEffect(() => {
    warrantyTrackerData();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mt-4">
              <h4 className="fw-bold">Warranty Tracker</h4>
              <div className="mx-4">
                <Link to={"/add-warrantyTracker"}>
                  <button className="btn btn-danger">Add Warranty </button>
                </Link>
              </div>
            </div>
            <div
              className="table-responsive table-container-scroll"
              style={{ overflowX: "auto", marginTop: "2rem" }}
            >
              <table
                className="table sd-list-table"
                style={{ tableLayout: "unset" }}
              >
                <thead className="thead-light">
                  <tr>
                    <BackOfficeTableTh
                      value="S.No."
                      width="5rem "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="WR Advice No."
                      width=" "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="WR Advise Date"
                      width="13rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Complaint No."
                      width="13rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Reporting Rly"
                      width="13rem"
                    ></BackOfficeTableTh>

                    <BackOfficeTableTh
                      value="To Attend
                      At"
                      width="13.05rem  "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="To Attend
                      At (Address)"
                      width="26rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Po No.
                      "
                      width=" 13rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Po
                      Date"
                      width="13rem "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Warranty Period"
                      width=" "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Defect Type"
                      width="13rem "
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Description of Defect"
                      width="16rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Coach No.
                      (Product SN.)"
                      width="13rem "
                    ></BackOfficeTableTh>

                    <BackOfficeTableTh
                      value="Qty."
                      width=" 10rem"
                    ></BackOfficeTableTh>

                    <BackOfficeTableTh
                      value="Make"
                      width=" 10rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Status"
                      width=" 10rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Joint Report CRN Proof"
                      width=" 14rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="WC Closer Date
                      (CRN No.)"
                      width="14rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="WC Closer Date
                      (Date)"
                      width="12rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Assigned to"
                      width="12rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Last Date to
                      Attend"
                      width="15rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Claim Amount
                      (to deduct from Bill)"
                      width="13rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Deduction Status"
                      width="12rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Deduction Status Date"
                      width="12rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Deduction Details"
                      width="16rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Consignee to
                      Contact"
                      width="20rem"
                    ></BackOfficeTableTh>
                    <BackOfficeTableTh
                      value="Contact No."
                      width=" "
                    ></BackOfficeTableTh>

                    <BackOfficeTableTh
                      value="Action"
                      width="10rem "
                    ></BackOfficeTableTh>
                  </tr>
                </thead>
                <tbody>
                  {warrantyTrackerList?.map((tracker, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          tracker?.Status === "Attended"
                            ? "#e2efda"
                            : tracker?.Status === "Attended (CRN Pending)"
                            ? "#eacf7f"
                            : null,
                      }}
                    >
                      <BackOfficeTableTd
                        value={i + 1}
                        width="5rem"
                      ></BackOfficeTableTd>

                      <td>
                        {tracker?.WRAdviceFile ? (
                          <Link
                            to={`https://dev-unti.fictivebox.tech/${tracker?.WRAdviceFile}`}
                            target="_blank"
                          >
                            <h6>{tracker?.WarrantyRejectionAdviceNo} </h6>
                          </Link>
                        ) : (
                          <h6>{tracker?.WarrantyRejectionAdviceNo}</h6>
                        )}
                      </td>

                      <BackOfficeTableTd
                        value={formatDate(tracker?.WarrantyRejectionAdviceDate)}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.ComplaintNo}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.ReportingRlyName}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.ToAttendAtName}
                        width="13rem"
                      ></BackOfficeTableTd>
                       <BackOfficeTableTd
                        value={tracker?.AttendAddress}
                        width="26rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.PoNo}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={formatDate(tracker?.PoDate)}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={formatDate(tracker?.WarrantyPeriod)}
                        width="16rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.DefectTypeName}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.DescOfDefect}
                        width="16rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.CoachNo_ProductSN}
                        width="13rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.Qty}
                        width="10rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.Make}
                        width="10rem"
                      ></BackOfficeTableTd>

                      <td style={{ minWidth: "10rem", maxWidth: "10rem" }}>
                        {initialStatus[i].loading ? (
                          <Spinner animation="border" variant="danger" />
                        ) : (
                          <select
                            className="form-control"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              fontSize: "1rem",
                              fontWeight: "600",
                              color: "#303e67",
                            }}
                            onChange={(event) =>
                              changeStatusList(event, tracker?.id, i)
                            }
                            value={tracker?.Status}
                          >
                            <option value={"Attended"}>
                              <h6>Attended</h6>
                            </option>
                            <option value={"Pending"}>
                              <h6>Pending</h6>
                            </option>
                            <option value={"Attended (CRN Pending)"}>
                              <h6>Attended (CRN Pending) </h6>
                            </option>
                          </select>
                        )}
                      </td>
                      <td style={{ minWidth: "14rem", maxWidth: "14rem" }}>
                        {tracker?.JointLetterCrnProof ? (
                          <Link
                            to={`https://dev-unti.fictivebox.tech/${tracker?.JointLetterCrnProof}`}
                            target="_blank"
                          >
                            <h6>Proof Attachment</h6>
                          </Link>
                        ) : (
                          <h6>No Attahment</h6>
                        )}
                      </td>
                      <td style={{ minWidth: "14rem", maxWidth: "14rem" }}>
                        {tracker?.WCCloserCRNFile ? (
                          <Link
                            to={`https://dev-unti.fictivebox.tech/${tracker?.WCCloserCRNFile}`}
                            target="_blank"
                          >
                            <h6>{tracker?.WcCloserCrnNo}</h6>
                          </Link>
                        ) : tracker?.WcCloserCrnNo ? (
                          <h6>{tracker?.WcCloserCrnNo}</h6>
                        ) : (
                          <h6>---</h6>
                        )}
                      </td>
                      <BackOfficeTableTd
                        value={formatDate(tracker?.WC_CloserDate_AsPerCRN)}
                        width="12rem"
                      ></BackOfficeTableTd>

                      <td style={{ minWidth: "12rem", maxWidth: "12rem" }}>
                        {changeAssigne[i].loading ? (
                          <Spinner animation="border" variant="danger" />
                        ) : (
                          <select
                            className="form-control"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              fontSize: "1rem",
                              fontWeight: "600",
                              color: "#303e67",
                            }}
                            onChange={(event) =>
                              changeAssigned(event, tracker?.id, i)
                            }
                            value={tracker?.AssignedTo}
                          >
                            {allDropdowns?.userList?.map((element) => (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <BackOfficeTableTd
                        value={formatDate(tracker?.LastDateToAttend)}
                        width="15rem"
                      ></BackOfficeTableTd>

                      <td style={{ minWidth: "13rem", maxWidth: "13rem" }}>
                        {tracker?.ClaimAmountTBDFile ? (
                          <Link
                            to={`https://dev-unti.fictivebox.tech/${tracker?.ClaimAmountTBDFile}`}
                            target="_blank"
                          >
                            <h6>
                              {formatNumber(
                                tracker?.ClaimAmount_ToDeductFromBill
                              )}{" "}
                            </h6>
                          </Link>
                        ) : (
                          <h6>
                            {" "}
                            {
                            
                            !isNaN(parseFloat(tracker?.ClaimAmount_ToDeductFromBill))? formatNumber(
                              tracker?.ClaimAmount_ToDeductFromBill
                            ):"--"
                            
                            }{" "}
                          </h6>
                        )}
                      </td>
                      <BackOfficeTableTd
                        value={tracker?.DeductionStatus}
                        width="12rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={formatDate(tracker?.DeductionStatusDate)}
                        width="12rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.deductionDetails}
                        width="16rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.ConsigneeToContact}
                        width="20rem"
                      ></BackOfficeTableTd>
                      <BackOfficeTableTd
                        value={tracker?.ContactNo}
                        width="16rem"
                      ></BackOfficeTableTd>
                      <td style={{ minWidth: "10rem", maxWidth: "10rem" }}>
                        <i
                          className="las la-pen text-dark font-20 px-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/edit-warrantyTracker", {
                              state: tracker,
                            });
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Material Item"
                        ></i>

                        <i
                          className="las la-trash-alt font-20 px-1 text-dark"
                          onClick={() => {
                            setDeleteModal(true);
                            setTrackerId(tracker?.id);
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Material Item"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              
            </div>
            <Modal
              show={deleteModal}
              onHide={handleClose}
              centered
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-uppercase mb-0">
                  Delete
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h3 className="text-secondary text-center mx-3">
                  Are you sure, <br /> Do you want to Delete this?
                </h3>
              </Modal.Body>
              <div className="d-flex gap-3 justify-content-center mb-3">
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={deleteWarrantyTracker}>
                  Delete
                </Button>
              </div>
            </Modal>
            {/* <PendingStatusAlert pendingStatusList={pendingStatusList} /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default WarrantyTrackerTable;
