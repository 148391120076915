import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import logo from "../../Assets/Group 1000003296.png";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";
import {
 
  createLocationApi,
  updateLocationApi,
} from "../OrganizationComponents/organizationStructureApi";
import { toast } from "react-toastify";
import { LocalAtmSharp } from "@mui/icons-material";
import { addLocationSchema } from "../OrganizationComponents/OrganizationSchema";
import ShowError from "../OrganizationComponents/ShowError";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const LocationModal = ({ open, handleClose, editData,getLocationList }) => {
  const [loader, setLoader] = useState(false);
console.log("loaderrr",loader)
  const initialValues = {
    name: "",
    head: "",
    group_email: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    state:"",
    city: "",
    zip: "",
    description: ""
}
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addLocationSchema,
    onSubmit: (values) => {
     if (editData) {
       updateLocation();
     } else {
        addLocation(values)
     }
    },
  });
  async function addLocation(value) {
    setLoader(true);
    try {
      const res = await createLocationApi(value);
      toast.success("Location Added Successfully",{autoClose:1000});
      handleClose();
      getLocationList()
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }
  async function updateLocation() {
    const reqPayload ={id:editData?.id,...values}
    setLoader(true);
    try {
      const res = await updateLocationApi(reqPayload);
      toast.success("Location update Successfully",{autoClose:1000});
      handleClose();
      getLocationList()
      resetForm();
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: 1000 });
    } finally {
      setLoader(false);
    }
  }
useEffect(()=>{
  if(editData){
    setValues(editData)
  }else{
    resetForm()
  }
},[editData,open])
  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            {editData ? "Update" : "Add"} Location
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="name"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.name}
                      message={errors.name}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomInputLabel
                      id="demo-simple-select-label"
                      size="small"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Location Head
                    </CustomInputLabel>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Location Head"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      size="small"
                      name="head"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.head}
                    >
                      <MenuItem value={"Suraj Pandey"}>Suraj Pandey</MenuItem>
                      <MenuItem value={"head1"}>head1</MenuItem>
                    </CustomSelect>
                    <ShowError
                      touched={touched.head}
                      message={errors.head}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      id=""
                      name="group_email"
                      value={values.group_email}
                      label="Group email"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    <ShowError touched={touched.group_email} message={errors.group_email} />
                  </CustomFormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5
                    className="text-uppercase fw-normal mb-4"
                    style={{ color: "#A6A6A6" }}
                  >
                    Address Detail
                  </h5>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 1"
                      variant="outlined"
                      size="small"
                      name="address_line_1"
                      value={values.address_line_1}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_1}
                      message={errors.address_line_1}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Address Line 2"
                      name="address_line_2"
                      variant="outlined"
                      value={values.address_line_2}
                      onBlur={handleBlur}
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_2}
                      message={errors.address_line_2}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="State"
                      name="state"
                      value={values.state}
                      onBlur={handleBlur}
                      variant="outlined"
                      size="small"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.address_line_2}
                      message={errors.address_line_2}
                    />
                  </CustomFormControl>
                </div>
                <div className="col-6 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="City"
                      name="city"
                      value={values.city}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                      size="small"
                    ></CustomTextField>
                    <ShowError touched={touched.city} message={errors.city} />
                  </CustomFormControl>
                </div>
                <div className="col-6">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Zip Code"
                      name="zip"
                      value={values.zip}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                      size="small"
                    ></CustomTextField>
                    <ShowError touched={touched.zip} message={errors.zip} />
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      id=""
                      label="Description"
                      variant="outlined"
                      size="small"
                      name="description"
                      multiline
                      rows={5}
                      value={values.description}
                      onBlur={handleBlur}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      onChange={handleChange}
                    ></CustomTextField>
                    <ShowError
                      touched={touched.description}
                      message={errors.description}
                    />
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="hr btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Update Location "
                ) : (
                  "Add Location"
                )}
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default LocationModal;
