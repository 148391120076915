import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "../TenderStyle/TenderStyle.css"

function TenderTicketComment({ ticketId }) {
  const [spinner, setSpinner] = useState(false);
  const [comment, setComment] = useState("");
  const [deleteCommentModal, setDeleteCommentModal] = useState(false);
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const [commentList, setCommentList] = useState([]);
  const [commentId, setCommentId] = useState(0);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  function addComment() {
    setSpinner(true);
    let url = baseUrl + "storetenderticketfollowup";
    let data = { user_id, comment, ticket_id: ticketId };
    axios
      .post(url, data, config)
      .then((response) => {
        getCommentList();
        setComment("");
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
      });
  }

  function getCommentList() {
    let url = baseUrl + "gettenderticketfollowup";
    let data = { user_id, ticket_id: ticketId };
    axios
      .post(url, data, config)
      .then((response) => {
        setCommentList(response.data.response.TenderticketFollowupList);
      })
      .catch((error) => {
  
      });
  }

  function deleteComment() {
    const url = `${baseUrl}deletetenderticketfollowup`;
    const data = { user_id, followup_id: commentId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setDeleteCommentModal(false);
          toast.success("Comment Deleted Successfully");
          getCommentList();
        }
      })
      .catch((error) => {});
  }

  function timeAgo(time) {
    let minutes;
    let hours;
    let days;
    const currentTime = new Date().getTime();
    const commentTime = new Date(time).getTime();
    const agoTime = currentTime - commentTime;
    hours = Math.floor((agoTime / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((agoTime / 1000 / 60) << 0);
    days = Math.floor(hours / 24);

    if (minutes === 0) {
      return "Just now";
    }
    if (minutes < 60) {
      return `${minutes} minute ago`;
    }
    if (hours < 24) {
      return `${hours} hours ago`;
    }
    if (days <= 1) {
      return `${days} days ago`;
    }
  }

  useEffect(() => {
    if (ticketId) {
      getCommentList();
    }
  }, [ticketId]);

  return (
    <>
      <div className="mt-3">
    
          <form className="mx-2">
            <div className="row">
              <label className="form-label">Comment</label>
              <textarea
                className="form-control mb-4 border-secondary"
                id="comment"
                rows={1}
                placeholder="Add Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="text-end">
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-2"></i>
                  <p>Wait a moment...</p>
                </div>
              ) : (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={addComment}
                  disabled={comment.length === 0}
                >
                  Add Comment
                </Button>
              )}

              {/* <audio ref={audioPlayer} src={doneSound} /> */}
            </div>
          </form>
        
      </div>

      <div className="row mt-3">
        <h5> All Comments</h5>

        {commentList?.map((element, index) => {
          return (
            <>
              <div className="col-12 my-3 border-bottom pe-3" key={element.id}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <img
                          src={imageBaseUrl + element.userData.profileimage}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "25px",
                          }}
                          alt="DP"
                        />
                      </div>
                      <div>
                        <h6 className=" m-0 p-0" style={{ fontWeight: "600" }}>
                          {element.userData.name}{" "}
                          <span
                            className="text-secondary"
                            style={{ fontSize: "10px" }}
                          >
                            &nbsp; {timeAgo(element.created_at)}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <MdDelete
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCommentId(element.id);
                        setDeleteCommentModal(true);
                      }}
                    />
                  </div> */}
                </div>

                <div
                  className="text-secondary me-5"
                  style={{ marginLeft: "2.3rem" }}
                >
                  {element.comment}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <Modal
        size="sm"
        show={deleteCommentModal}
        onHide={() => setDeleteCommentModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        keyboard={false}
        className="border-danger"
        style={{ border: "1px solid red" }}
      >
        <Modal.Header className="bg-danger">
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="bg-danger m-0 p-0 text-center"
          >
            Are You sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-between">
          <button className="btn btn-sm btn-danger" onClick={deleteComment}>
            Confirm
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => setDeleteCommentModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TenderTicketComment;
