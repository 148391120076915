import React from 'react'

const AsideLIst = ({children,asideHeading}) => {
  return (
  
<div className=' aside-list col-md-3 '>
<h5 className='text-white pt-4 ps-3 text-uppercase'>{asideHeading}</h5>
   {children}

</div>


  )
}

export default AsideLIst
