import React,{useState} from "react";
import "../../HrDepartment.scss"
import "../../HrDepartment.scss"
const EmployeeAssets = () => {
 
  return (
    <>
    <div className="card">
        <div className="assignedAssets">Assigned Assets</div>
        <hr/>
        <div className="table_24">
 <table className="table border0px">
  <thead className="thead-light">
    <tr className="borderBottomOpx">
      <th scope="col" className="headingStyle">Type</th>
      <th scope="col" className="headingStyle">Name</th>
      <th scope="col" className="headingStyle">ID</th>
      <th scope="col" className="headingStyle">Assigned On</th>
      <th scope="col" className="headingStyle">Current Condition</th>
    </tr>
  </thead>
  <tbody className="boxShadowNone">
    <tr >
    
      <td className="cellStyle">Mark</td>
      <td className="cellStyle">Otto</td>
      <td className="cellStyle">@mdo</td>
      <td className="cellStyle">Otto</td>
      <td className="cellStyle">@mdo</td>
    </tr>
   
  </tbody>
</table>
</div>
</div>
    </>
  );
};

export default EmployeeAssets;
