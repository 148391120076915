import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useSelector } from "react-redux";

import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import MaterialItemDetail from "./MaterialItemDetail";
const MaterialDetail = () => {
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const location = useLocation()
const detail = location?.state
  const projectDropDown = useSelector(
    (state) => state?.dropdown?.dropdowns?.projectList
  );

  const [listMaterialInStatus, setListMaterialInStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleTabChange = (eventkey, event) => {
    setActiveTab(eventkey);
  };

  const listMaterialStatus = () => {
    const url = `${baseUrl}listmaterialinstatus`;
    const data = { user_id };
    axios
      .post(url, data, config)
      .then((res) => {
        if (res.status == 200) {
          setListMaterialInStatus(res?.data?.response?.ListMaterialInStaus);
        }
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };


  useEffect(() => {
    listMaterialStatus();
  }, []);
 
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card p-4">
                <h3 className="mb-5">MATERIAL IN REVIEW</h3>

                <div className="card-body p-0">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={activeTab}
                    onSelect={(eventkey, event) =>
                      handleTabChange(eventkey, event)
                    }
                    className="mb-3"
                  >
                    <Tab
                      eventKey="0"
                      title="With purchase order"
                      className="mt-4"
                    >
                      <MaterialItemDetail projectDropDown={projectDropDown}
                      listMaterialInStatus={listMaterialInStatus} detail={detail}/>
                   
                    </Tab>
                    <Tab
                      eventKey="1"
                      title="Without purchase order"
                      className="mt-4"
                    >
                       <MaterialItemDetail projectDropDown={projectDropDown}
                      listMaterialInStatus={listMaterialInStatus}/>
                  
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialDetail;
