import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddMaterialPurchaseModal from "../../Modals/AddMaterialPurchaseModal";
import axios from "axios";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "../../TableStyle/TableStyle.css";
import "../../Pages/Style.css";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";

export default function MaterialPurchase({
  tabKey,
  lotData,
  lotId,
  setLotId,
  state = null,
}) {
  const [showLetterModal, setShowLetterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const attachmentBaseUrl = process.env.REACT_APP_BASE_URL;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [spinner, setSpinner] = useState(false);
  const [scopeOfWorkData, setScopeOfWorkData] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [lotDetails, setLotDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const param = useParams();
  const [attachment, setAttachment] = useState([]);
  const [spinnerStates, setSpinnerStates] = useState([]);
  let project_id = state ? state.project_id : Number(param.id);

  function handleClose() {
    setShowLetterModal(false);
    setDeleteModal(false);
  }

  function listscopeofwork() {
    let url = baseUrl + "listscopeofwork";
    let data = { user_id, project_id, lotNumber: lotId };
    axios
      .post(url, data, config)
      .then(async (response) => {
        if (response.status === 200) {
          setLoading(false);
          setScopeOfWorkData(
            response?.data?.response?.scopeOfWorkList?.scopeOfWork
          );
          setEditableData(
            response?.data?.response?.scopeOfWorkList?.scopeOfWork
          );
          setLotDetails(response?.data?.response?.scopeOfWorkList?.lotDetails);

          setAttachment(
            new Array(
              response?.data?.response?.scopeOfWorkList?.scopeOfWork?.length
            ).fill("")
          );
          setSpinnerStates(
            new Array(
              response?.data?.response?.scopeOfWorkList?.scopeOfWork?.length
            ).fill(false)
          );
          toast.success("List Updated");
        }
      })
      .catch((error) => {
        toast.error("Something went Wrong");
        setLoading(false);
      });
  }

  function workListChange(event, index) {
    const { name, value } = event.target;
    const newData = [...editableData];
    if (name === "attachment") {
      const data = new FileReader();
      data.addEventListener("load", () => {
        newData[index][name] = data.result;
        let attachmentData = [...attachment];
        attachmentData[index] = data.result;
        setAttachment(attachmentData);
      });
      data.readAsDataURL(event.target.files[0]);
    } else {
      newData[index][name] = value;
      setEditableData(newData);
    }
  }

  function submitWork(index) {
    const updatedSpinnerStates = [...spinnerStates];
    updatedSpinnerStates[index] = true;
    setSpinnerStates(updatedSpinnerStates);
    const url = baseUrl + "storesowrowwise";
    const data = editableData[index];
    const newData = {
      ...data,
      item_id: data.id,
      lotNumber: lotId,
      user_id,
      project_id,
      attachment: attachment[index]
        ? attachment[index]
        : data.attachment && data.attachment.startsWith("assets")
        ? attachmentBaseUrl + data.attachment
        : data.attachment,
    };
    axios
      .post(url, newData, config)
      .then((response) => {
        setSpinner(false);
        const updatedSpinnerStates = [...spinnerStates];
        updatedSpinnerStates[index] = false;
        setSpinnerStates(updatedSpinnerStates);
        let attachmentData = [...attachment];
        attachmentData[index] = "";
        setAttachment(attachmentData);
        listscopeofwork();
        toast.success("Save Successfully");
      })
      .catch((error) => {
        setSpinner(false);
        toast.error("Something went wrong");
        const updatedSpinnerStates = [...spinnerStates];
        updatedSpinnerStates[index] = false;
        setSpinnerStates(updatedSpinnerStates);
        let attachmentData = [...attachment];
        attachmentData[index] = "";
        setAttachment(attachmentData);
      });
  }

  useEffect(() => {
    if (tabKey === "material-costing") {
      setLoading(true);
      listscopeofwork();
    }
  }, [tabKey, lotId]);

  return (
    <>
      <>
        <div className="card-body">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ border: "1px solid black" }}
          >
            <div
              className="col-md-6"
              style={{ borderRight: "1px solid black" }}
            >
              <h3
                className="p-3 m-0"
                style={{
                  backgroundColor: "#f4f4f4",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                }}
              >
                All Items
              </h3>
            </div>
            <div className="col-md-6">
              <select
                className="form-control py-3 m-0"
                onChange={(e) => setLotId(e.target.value)}
                style={{
                  backgroundColor: "#f4f4f4",
                  fontWeight: 700,
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              >
                {lotData?.map((element, index) => {
                  return (
                    <option key={index} value={element.id}>
                      LOT {index + 1} - ({element.poLoaQty})
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {loading ? (
            <div className="text-center mt-3">
              <Spinner animation="border" variant="danger" />
            </div>
          ) : (
            <div
              className="table-container-scroll"
              style={{ overflowX: "auto" }}
            >
              <table
                className="table table-striped material-list-table"
                style={{ tableLayout: "unset" }}
              >
                <thead className="">
                  <tr>
                    <th>
                      <p
                        style={{ minWidth: "3rem", maxWidth: "3rem" }}
                        className="fw-bold font-18 text-secondary"
                      >
                        {" "}
                        S&nbsp;No.
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Item&nbsp;PL&nbsp;No.
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Item&nbsp;Description
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Reference&nbsp;Coach&nbsp;Type
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Item&nbsp;Colour/Shade
                      </p>
                    </th>
                    {/* <th>
                      <p className="fw-bold font-18 text-secondary">
                        Item&nbsp;Shade
                      </p>
                    </th> */}
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Reference&nbsp;Specification&nbsp;No.
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Item&nbsp;Size
                      </p>
                    </th>
                    {/* <th>
                      <p className="fw-bold font-18 text-secondary">
                        UOM&nbsp;Item&nbsp;Size
                      </p>
                    </th> */}
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Drawing&nbsp;No.
                      </p>
                    </th>
                    {/* <th>
                      <p className="fw-bold font-18 text-secondary">UOM</p>
                    </th> */}
                    <th>
                      <p className="fw-bold font-18 text-secondary">QPC</p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Inspection&nbsp;Agency
                      </p>
                    </th>

                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Vendor&nbsp;Name{" "}
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Vendor&nbsp;PO&nbsp;No.
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        PO&nbsp;Date
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Qty&nbsp;Ordered
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        PO&nbsp;Value
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        RITES&nbsp;Value
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">Status</p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        ETD{" "}
                        <span className="font-12">
                          (Estimated Time Delivery)
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">
                        Attachment
                      </p>
                    </th>
                    <th>
                      <p style={{ width: "5rem" }}>View</p>
                    </th>
                    <th>
                      <p className="fw-bold font-18 text-secondary">Submit</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white w-100">
                  {scopeOfWorkData?.map((list, index) => {
                    return (
                      <tr key={list.id}>
                        <td>
                          <h6 style={{ minWidth: "3rem", maxWidth: "3rem" }}>
                            {index + 1}
                          </h6>
                        </td>
                        <td>
                          <h6>{list.itemPLNo ? list.itemPLNo : "---"}</h6>
                        </td>
                        <td>
                          <h6>
                            {list.itemDescription
                              ? list.itemDescription
                              : "---"}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {list.referenceCoachType
                              ? list.referenceCoachType
                              : "---"}
                          </h6>
                        </td>
                        <td>
                          <h6>{list.itemColour ? list.itemColour : "---"}</h6>
                        </td>
                        {/* <td>
                          <h6>{list.itemShade ? list.itemShade : "---"}</h6>
                        </td> */}
                        <td>
                          <h6>
                            {list.referenceSpecificationNo
                              ? list.referenceSpecificationNo
                              : "---"}
                          </h6>
                        </td>
                        <td>
                          <h6>{list.itemSize ? list.itemSize : "---"}</h6>
                        </td>
                        {/* <td>
                          <h6>{list.UOMItemSize ? list.UOMItemSize : "---"}</h6>
                        </td> */}
                        <td>
                          <h6>{list.drawingNo ? list.drawingNo : "---"}</h6>
                        </td>
                        {/* <td>
                          <h6>{list.UOM ? list.UOM : "---"}</h6>
                        </td> */}
                        <td>
                          <h6>{list.QPC ? list.QPC : "---"}</h6>
                        </td>
                        <td>
                          <h6>
                            {list.inspectionAgency
                              ? list.inspectionAgency
                              : "---"}
                          </h6>
                        </td>

                        <td>
                          <input
                            className="form-control border-secondary"
                            placeholder="Vendor Name"
                            name="vendorName"
                            value={list.vendorName}
                            onChange={(event) => workListChange(event, index)}
                          ></input>
                        </td>
                        <td>
                          <input
                            className="form-control border-secondary"
                            placeholder="Vendor PO No."
                            value={list.vendorPoNo}
                            name="vendorPoNo"
                            onChange={(event) => workListChange(event, index)}
                          ></input>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              type="date"
                              placeholder="PO Date"
                              name="PoDate"
                              value={list.PoDate}
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              placeholder="Qty Ordered"
                              name="QtyOrdered"
                              value={list.QtyOrdered}
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              placeholder="PO Value"
                              value={list.PoValue}
                              name="PoValue"
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              placeholder="Rites Value"
                              name="RitesValue"
                              value={list.RitesValue}
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              placeholder="Status"
                              name="Status"
                              value={list.Status}
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              placeholder="Estimated Time Delivery"
                              value={list.ETD}
                              name="ETD"
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>
                        <td>
                          <h6>
                            <input
                              className="form-control border-secondary"
                              type="file"
                              accept=".pdf"
                              name="attachment"
                              onChange={(event) => workListChange(event, index)}
                            ></input>
                          </h6>
                        </td>

                        <td>
                          <h6>
                            {list.attachment && (
                              <a
                                href={
                                  attachment[index]
                                    ? attachment[index]
                                    : attachmentBaseUrl + list.attachment
                                }
                                target="_blank"
                              >
                                <FaEye
                                  style={{ height: "20px", width: "20px" }}
                                />
                              </a>
                            )}
                          </h6>
                        </td>

                        <td>
                          <h6>
                            {spinnerStates[index] ? (
                              <div className="">
                                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                                <p className="fw-bold font-18 text-secondary">
                                  Updating ...
                                </p>
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => submitWork(index)}
                              >
                                Save
                              </button>
                            )}
                          </h6>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </>

      <Modal show={deleteModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="text-uppercase mb-0">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-secondary text-center mx-3">
            Are you sure, <br /> Do you want to Delete this?
          </h3>
        </Modal.Body>
        <div className="d-flex gap-3 justify-content-center mb-3">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary">Delete</Button>
        </div>
      </Modal>

      <AddMaterialPurchaseModal
        show={showLetterModal}
        handleClose={handleClose}
      />
    </>
  );
}
