import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import InputAdornment from "@mui/material/InputAdornment";

import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import { useFormik } from "formik";
import { values } from "lodash";

import { toast } from "react-toastify";
import { LocalAtmSharp } from "@mui/icons-material";

// import ShowError from "../OrganizationComponents/ShowError";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .MuiPaper-root": {
    maxWidth: "800px",
    minWidth: "691px", // Increased modal width
    maxHeight: "100%", // Increased modal height
    borderRadius: theme.shape.borderRadius * 2, // Added border radius
    padding: theme.spacing(1.5), // Added padding space
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3), // Increased padding
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2), // Increased padding
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(143, 143, 143, 1)", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "#115293", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#115293", // Custom border color on focus
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.MuiInputLabel-root": {
    fontSize: "1rem", // Custom label size
  },
  "&.Mui-focused": {
    fontSize: ".9rem", // Custom label size on focus
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "8px", // Custom border radius
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2", // Custom border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#115293", // Custom border color on focus
  },
}));

const AddNoticePeriodModal = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);

  const [checked, setChecked] = React.useState(true);

  const handlechekboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const initialValues = {
    name: "intern",
    description: "internnnn",
    duration:""
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: leagalEntitySchema,
    onSubmit: (values) => {},
  });

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            className="pb-0"
            sx={{ m: 0, p: 2, color: " #4667B6" }}
            id="customized-dialog-title"
          >
            Add Notice Period
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent className="pb-0 px-3">
              <div className="row mt-3">
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      name="name"
                      id=""
                      label="Name"
                      variant="outlined"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                    {/* <ShowError
                      touched={touched.entity_name}
                      message={errors.entity_name}
                    /> */}
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      multiline={true}
                      inputProps={{ style: { fontSize: ".95rem" } }}
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="large"
                      id=""
                      rows={5}
                      label="Description"
                      name="description"
                      value={values.description}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CustomTextField>
                  </CustomFormControl>
                </div>
                <div className="col-12 mb-2">
                  <CustomFormControl fullWidth>
                    <CustomTextField
                      name="duration"
                      value={values.duration}
                      label="Duration"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="outlined-start-adornment"
                      // { style: { fontSize: ".95rem" } }
                      InputLabelProps={{ style: { fontSize: "0.9rem" } }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">month</InputAdornment>
                        ),
                      }}
                    ></CustomTextField>
                  </CustomFormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary"
                disabled={loader}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loader}
              >
                {/* {loader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : editData ? (
                  "Edit Entity "
                ) : (
                  "Add Entity"
                )} */}
                Add Notice Period
              </button>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

export default AddNoticePeriodModal;
