import React from "react";
function CommonOptions({handleChange,values,name,handleBlur,readOnly}) {
  return (
    <>
      <label htmlFor="" className="form-label">
        Paid/Exempted
      </label>
      <select
        type="text"
        className="form-control"
        id={name}
        name={name}
        value={values}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder=""
        disabled={readOnly}
      >
        <option value="" className="text-secondary" disabled  hidden>
          Select
        </option>
        <option value="Paid">Paid</option>
        <option value="Exempted">Exempted</option>
        <option value="Not Applicable">Not Applicable</option>
        <option value="Pending">Pending</option>
      </select>
    </>
  );
}

export default CommonOptions;
