import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../TenderStyle/TenderStyle.css";

function TenderCards({ searchData }) {
  const navigate = useNavigate();
  const [titleModal, setTitleModal] = useState(false);
  const [titleData, setTitleData] = useState("");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  function formatDate(value) {
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return formattedDate;
  }

  function formatNumber(value) {
    return Number(value).toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  }

  function editData(initialData) {
    navigate("/tenders/edit-tender", {
      state: {
        initialData,
      },
    });
  }

  return (
    <div>
      <div className="row">
        {searchData.map((tender) => {
          return (
            <div className="col-md-6" onDoubleClick={() => editData(tender)}>
              <div className="card tender-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-9">
                      <h5 className="m-0">{tender.tenderNo}</h5>
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 100 }}
                        overlay={renderTooltip(tender.railwayZoneName)}
                      >
                        <h5 className="fw-bold tender-title">
                          {tender.railwayZoneName}
                        </h5>
                      </OverlayTrigger>
                    </div>
                    <div className="col-md-3 text-center">
                      <h6
                        style={
                          tender.biddingStatus === "Not Awarded"
                            ? {
                                backgroundColor: "#FA7070DB ",
                                borderRadius: "10px",
                              }
                            : tender.biddingStatus === "Awarded"
                            ? {
                                backgroundColor: "#a8e28d",
                                borderRadius: "10px",
                              }
                            : tender.biddingStatus === "Bidded"
                            ? {
                                backgroundColor: "#fdec8b",
                                borderRadius: "10px",
                              }
                            : tender.biddingStatus === "Not Bidded"
                            ? {
                                backgroundColor: "#dcdfd8",
                                borderRadius: "10px",
                              }
                            : { backgroundColor: "" }
                        }
                        className="py-2"
                      >
                        {tender.biddingStatus}
                      </h6>
                    </div>
                  </div>

                  <div className="py-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 100 }}
                          overlay={renderTooltip(tender.itemDecsription)}
                        >
                          <p className="m-0 tender-detail">
                            Tender Title :{" "}
                            <span className="text-secondary fw-bold">
                              {tender.itemDecsription}
                            </span>
                          </p>
                        </OverlayTrigger>
                      </div>
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Sub Divison :{" "}
                          <span className="text-secondary fw-bold">
                            {tender.subDivisionName
                              ? tender.subDivisionName
                              : "---"}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Contract Type :{" "}
                          <span className="text-secondary fw-bold">
                            {tender.contractType}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Tender Type :{" "}
                          <span className="text-secondary fw-bold">
                            {tender.tenderType}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Amount (EMD) :{" "}
                          <span className="text-secondary fw-bold">
                            {formatNumber(tender.ifApplicableAmountEmd)}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Intending Participation :
                          <span className="text-secondary fw-bold">
                            {tender.intendingParticipation}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Validity of Offer (Days) :{" "}
                          <span className="text-secondary fw-bold">
                            {tender.validityOfOffer
                              ? tender.validityOfOffer
                              : "---"}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Published Date :{" "}
                          <span className="text-secondary fw-bold">
                            {formatDate(tender.publishedDate)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="py-3 border-bottom">
                    <div className="row">
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Closing Date :{" "}
                          <span className="text-secondary fw-bold">
                            {formatDate(tender.closingDate)}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="m-0 tender-detail">
                          Closing Time :{" "}
                          <span className="text-secondary fw-bold">
                            {tender.time}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        show={titleModal}
        onHide={() => setTitleModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton className="m-0 p-3">
          <Modal.Title className="m-0 p-0">Tender Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>{titleData}</h5>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TenderCards;
