import { toast } from "react-toastify";

export const Todo = "Todo";
export const Completed = "Completed";

export function errorsMessage(status) {
  if (status === 422) {
    return toast.error("Oops! Validation Error, Please check your all inputs");
  }
  if (status === 500) {
    return toast.error("Oops! There is server error, Please try again");
  }
  if (status === 429) {
    return toast.error(
      "Too many request at the moment , please try again after some time"
    );
  }
  if (status === 401) {
    return toast.error(
      "Your Access Token is Expired, Please Login again to Access"
    );
  } else {
    return toast.error("Opps! Something went wrong, Please try again");
  }
}

export function formatDate(value) {
  if (value) {
    var date = new Date(value);
    var formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  }

  return formattedDate;
}

export function formatNumber(value) {
  return Number(value).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
}
