import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";

import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { AcceptRequestSchema } from "../../schemas/StoreSchema/VehicleRequestSchema";

const AcceptVehicleRequest = () => {
  const userDetail = JSON.parse(localStorage.getItem("profile"));
  const user_id = userDetail?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = JSON.parse(localStorage.getItem("profile"))?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = { headers: { Authorization: bearer_token } };
  const [requestStatus, setRequestStatus] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const signatureRef = useRef();
  const signatureRefApprovedHOD = useRef();
  const handleSignatureEnd = () => {
    const signatureLogisticHeadDataUrl = signatureRef.current.toDataURL();

    const signatureApprovedHODDataUrl =
      signatureRefApprovedHOD.current.toDataURL();

    setFieldValue("logistic_head_signature", signatureLogisticHeadDataUrl);
    setFieldValue("approvee_hod_signature", signatureApprovedHODDataUrl);
  };
  const [validationError, setValidationError] = useState(false);
  const initialValue = {
    approved_date: "",
    transporter_name: "",
    mobile_number: "",
    loading_date: "",
    delivery_date: "",
    delivery_time: "",
    vehicle_cost: "",
    transit_mode: "",
    vehicle_cancellation_cost: "",
    detention_charges: "",
    extra_cost_over_hw: "",
    remarks: "",
    logistic_head_signature: "",
    approvee_hod_signature: "",
  };
  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialValue,
    // validationSchema: AcceptRequestSchema,
    onSubmit: (values) => {
      acceptVehicleRequest(values);
    },
  });

  const acceptVehicleRequest = () => {
    const url = `${baseUrl}approvevehiclerequest`;
    const data = { ...values, user_id, vehicle_request_id: id };
    setSpinner(true);
    axios
      .post(url, data, config)
      .then((res) => {
        setSpinner(false);
        if (res.status == 200) {
          navigate("/requestDetail", {
            state: {
              request_status: 1,

              id: id,
            },
          });
        }
      })
      .catch((err) => {
        setSpinner(false);
        setValidationError(err?.response?.data?.response);
      });
  };
  console.log("validatin",validationError)
  console.log("errors",errors)
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="container" style={{ textAlign: "start" }}>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="fw-bold">ACCEPT VEHICLE REQUEST</h5>
                      <form className="department-form" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Date
                              </label>
                              <input
                                type="date"
                                className="form-control border-secondary"
                                placeholder="select"
                                name="approved_date"
                                value={values?.approved_date}
                                id="approved_date"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.approved_date && errors.approved_date
                                  ? errors.approved_date
                                  : validationError?.approved_date}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Transporter Name
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter Name"
                                name="transporter_name"
                                value={values?.transporter_name}
                                id="transporter_name"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.transporter_name &&
                                errors.transporter_name
                                  ? errors.transporter_name
                                  : validationError?.transporter_name}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Mobile Number
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter Number"
                                name="mobile_number"
                                value={values?.mobile_number}
                                id="mobile_number"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.mobile_number && errors.mobile_number
                                  ? errors.mobile_number
                                  : validationError?.mobile_number}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Loading Date
                              </label>
                              <input
                                type="date"
                                className="form-control border-secondary"
                                placeholder="select"
                                name="loading_date"
                                value={values?.loading_date}
                                id="loading_date"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.loading_date && errors.loading_date
                                  ? errors.loading_date
                                  : validationError?.loading_date}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Delivery Date
                              </label>
                              <input
                                type="date"
                                className="form-control border-secondary"
                                placeholder="select"
                                name="delivery_date"
                                value={values?.delivery_date}
                                id="delivery_date"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.delivery_date && errors.delivery_date
                                  ? errors.delivery_date
                                  : validationError?.delivery_date}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Delivery Time
                              </label>
                              <input
                                type="time"
                                className="form-control border-secondary"
                                placeholder="select"
                                name="delivery_time"
                                value={values?.delivery_time}
                                id="delivery_time"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.delivery_time && errors.delivery_time
                                  ? errors.delivery_time
                                  : validationError?.delivery_time}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Vehicle Cost
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter Cost "
                                name="vehicle_cost"
                                value={values?.vehicle_cost}
                                id="vehicle_cost"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.vehicle_cost && errors.vehicle_cost
                                  ? errors.vehicle_cost
                                  : validationError?.vehicle_cost}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Transit Mode
                              </label>
                              <select
                                className="form-control border-secondary"
                                placeholder=""
                                name="transit_mode"
                                value={values.transit_mode}
                                onChange={handleChange}
                              >
                                <option value="" className="text-secondary">
                                  Select
                                </option>
                                <option value="Due">By Bus</option>
                                <option value="Returned">By Train</option>
                              </select>
                              <p className="text-danger m-0">
                                {touched.transit_mode && errors.transit_mode
                                  ? errors.transit_mode
                                  : validationError?.transit_mode}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Vehicle Cancellation Cost
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter Cost "
                                name="vehicle_cancellation_cost"
                                value={values?.vehicle_cancellation_cost}
                                id="vehicle_cancellation_cost"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.vehicle_cancellation_cost &&
                                errors.vehicle_cancellation_cost
                                  ? errors.vehicle_cancellation_cost
                                  : validationError?.vehicle_cancellation_cost}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Detention Charges
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter  Charges"
                                name="detention_charges"
                                value={values?.detention_charges}
                                id="detention_charges"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.detention_charges &&
                                errors.detention_charges
                                  ? errors.detention_charges
                                  : validationError?.detention_charges}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Extra Cost Over height/Over Weight
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter  Charges"
                                name="extra_cost_over_hw"
                                value={values?.extra_cost_over_hw}
                                id="extra_cost_over_hw"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.extra_cost_over_hw &&
                                errors.extra_cost_over_hw
                                  ? errors.extra_cost_over_hw
                                  : validationError?.extra_cost_over_hw}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="form-group mb-3">
                              <label
                                htmlFor=""
                                className="form-label fw-bold font-16"
                              >
                                Remark
                              </label>
                              <input
                                className="form-control border-secondary"
                                placeholder="Enter remark"
                                name="remarks"
                                value={values?.remarks}
                                id="remarks"
                                onChange={handleChange}
                              ></input>
                              <p className="text-danger m-0">
                                {touched.remarks && errors.remarks
                                  ? errors.remarks
                                  : validationError?.remarks}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3"></div>
                          {/* <div className="col-md-3">
                                <div className="form-group mb-3">
                                  <label
                                    htmlFor=""
                                    className="form-label fw-bold font-16"
                                  >
                                    Signature (Logistic Head)
                                  </label>
                                  <input
                                    style={{ width: "300px", height: "100px" }}
                                    className="form-control border-secondary"
                                  ></input>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-3">
                                  <label
                                    htmlFor=""
                                    className="form-label fw-bold font-16"
                                  >
                                    Signature(Approved by HOD or Project Leader)
                                  </label>
                                  <input
                                    style={{ width: "300px", height: "100px" }}
                                    className="form-control border-secondary"
                                  ></input>
                                </div>
                              </div> */}
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label fw-bold font-16"
                                >
                                  Signature (Logistic Head)
                                </label>
                                <div
                                  style={{
                                    border: "1px solid #8D93A1",
                                    borderRadius: "0.2rem",
                                    padding: "2rem",
                                  }}
                                >
                                  {/* {!values?.logistic_head_signature ? ( */}
                                  <SignatureCanvas
                                    ref={signatureRef}
                                    name="logistic_head_signature"
                                    value={values?.logistic_head_signature}
                                    onEnd={handleSignatureEnd}
                                    canvasProps={{
                                      width: "300px",
                                      height: "100px",
                                      className: "signatureCanvas",
                                    }}
                                  />
                                  {/* ) : (
                                    <img
                                      src={`https://dev-unti.fictivebox.tech/${values?.requestee_signature}`}
                                    />
                                  )} */}
                                </div>
                               <button type="button" style={{padding:".5rem",borderRadius:"5px"}}  onClick={() => signatureRef.current.clear()}>Clear Signature</button>
                                <p className="text-danger m-0">
                                  {touched.logistic_head_signature &&
                                  errors.logistic_head_signature
                                    ? errors.logistic_head_signature
                                    : validationError?.logistic_head_signature}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label fw-bold font-16"
                                >
                                  Signature (Approved by HOD or Project Leader)
                                </label>
                                <div
                                  style={{
                                    border: "1px solid #8D93A1",
                                    borderRadius: "0.2rem",
                                    padding: "2rem",
                                  }}
                                >
                                  {/* {!values?.approvee_hod_signature ? ( */}
                                  <SignatureCanvas
                                    ref={signatureRefApprovedHOD}
                                    name="approvee_hod_signature"
                                    value={values?.approvee_hod_signature}
                                    onEnd={handleSignatureEnd}
                                    canvasProps={{
                                      width: "300px",
                                      height: "100px",
                                      className: "signatureCanvas",
                                    }}
                                  />
                                  {/* ) : (
                                    <img
                                      src={`https://dev-unti.fictivebox.tech/${values?.approvee_hod_signature}`}
                                    />
                                  )} */}
                                </div>
                                <button type="button" style={{padding:".5rem",borderRadius:"5px"}}  onClick={() => signatureRefApprovedHOD.current.clear()}>Clear Signature</button>
                                <p className="text-danger m-0">
                                  {touched.approvee_hod_signature &&
                                  errors.approvee_hod_signature
                                    ? errors.approvee_hod_signature
                                    : validationError?.approvee_hod_signature}
                                </p>
                              </div>
                            </div>
                          </div>
                          {spinner ? (
              <div className="">
                <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                <p>Wait...</p>
              </div>
            ) : (
                          <div className="mt-3">
                            <button
                              type="button"
                              className="btn btn-de-danger btn-sm border-danger"
                              style={{ marginLeft: "1rem" }}
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-danger btn-sm border-danger"
                              style={{ marginLeft: "1rem" }}
                            >
                              ADD
                            </button>
                          </div>)}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptVehicleRequest;
