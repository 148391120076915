import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import AddBoardGroupModal from "../Modals/AddBoardGroupModal";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

export default function BoardGroup() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [groupList, setGroupList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [groupId, setGroupId] = useState(null);

  function handleClose() {
    setShowAddModal(false);
    setShowDeleteModal(false);
    setEditData(null);
    setGroupId(null);
  }

  function getGroupList() {
    setLoader(true);
    let url = baseUrl + "listboardgroup";
    let data = { user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        setLoader(false);

        setGroupList(response?.data?.response.BoardGroupList);
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  function deleteGroup() {
    let url = baseUrl + "deleteboardgroup";
    let data = { user_id, board_group_id: groupId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
        },
      })
      .then((response) => {
        toast.success("Delete Successfully");
        handleClose();
        getGroupList();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  }

  function getEditData(group) {
    setEditData(group);
    setShowAddModal(true);
  }

  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="float-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#"></Link>
                      </li>
                      {/* <li className="breadcrumb-item">
                            <Link to="/emd-paid">EMD Paid Status /</Link>
                          </li> */}
                      <li className="breadcrumb-item active">Board Group</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Board Group</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h5 className="mt-0">
                      Here the list of all Board Group.{" "}
                      {/* <span className="badge badge-pink">6</span> */}
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 text-end">
                <div className="text-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="input-group">
                        <input
                          type="text"
                          id="example-input1-group2"
                          name="example-input1-group2"
                          className="form-control form-control-sm"
                          placeholder="Search"
                        />
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setShowAddModal(true)}
                      >
                        Add Board Status
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loader ? (
              <div
                className="d-flex align-items-center justify-content-center pb-5"
                style={{ height: "70vh" }}
              >
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col">
                          <h4 className="card-title">Board Status</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead className="thead-light">
                            <tr>
                              <th>S. No.</th>
                              <th>Status</th>
                              <th>Department</th>
                              <th>Level</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupList?.map((element, index) => {
                              return (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td>{element.name}</td> 
                                  <td>{element.departmentName}</td>
                                  <td>{element.is_level}</td>
                                  <td>
                                    <i
                                      className="las la-pen text-dark font-20 px-1"
                                      style={{ cursor: "pointer" }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit Zone Details"
                                      onClick={() => getEditData(element)}
                                    ></i>
                                    <i
                                      className="las la-trash-alt font-20 px-1 text-dark"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        setGroupId(element.id);
                                      }}
                                      data-toggle="tooltip"
                                      style={{ cursor: "pointer" }}
                                      data-placement="top"
                                      title="Delete Zone"
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddBoardGroupModal
        show={showAddModal}
        handleClose={handleClose}
        editData={editData}
        getGroupList={getGroupList}
      ></AddBoardGroupModal>

      <Modal show={showDeleteModal} centered>
        <Modal.Body className="text-center">
          <h4 className="lh-base mb-4">
            Are you sure you want to <br /> delete this record
          </h4>
          <button
            className="btn btn-success me-3"
            onClick={() => deleteGroup()}
          >
            Yes
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(false)}
          >
            No
          </button>
        </Modal.Body>
      </Modal>
      <Footer></Footer>
    </>
  );
}
