import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getPermissionList } from "../redux/api";
import { useFormik } from "formik";
import { FeatureListSchema } from "../schemas/settingSchema";

export default function ({
  show,
  handleClose,
  getFeatures,
  editableFeature,
  permissionId,
}) {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const baseUrl = process.env.REACT_APP_DEV_API_KEY_NEW;
  const getToken = user?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const [permissionList, setPermissionList] = useState([]);
  const [permissionGroup, setPermissionGroup] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const initialValues = {
    perm_group_id: "",
    feature: "",
    icon: "",
    url: "",
  };

  const {
    touched,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FeatureListSchema,
    onSubmit: (values) => {
      if (editableFeature) {
        editFeature(values);
      } else {
        addFeature(values);
      }
    },
  });

  function getPermission() {
    getPermissionList({ user_id })
      .then((response) => {
        if (response.status === 200) {
          setPermissionList(response?.data?.response?.permissionList);
        }
      })
      .catch((error) => {
      });
  }

  function addFeature(value) {
    setSpinner(true);
    let url = baseUrl + "addpermissionfeature";
    let data = { ...value, user_id };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getFeatures();
        }
      })
      .catch((error) => {
        setSpinner(false);
        if (error.response.data.message === "Error validation") {
          setValidationError(error?.response?.data?.response);
        }
      });
  }

  function editFeature(value) {
    setSpinner(true);
    let url = baseUrl + "editpermissionfeature";
    let data = { ...value, user_id, perm_feature_id: permissionId };
    axios
      .post(url, data, {
        headers: {
          Authorization: bearer_token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          handleClose();
          getFeatures();
        }
      })
      .catch((error) => {
        setSpinner(false);
      });
  }

  useEffect(() => {
    if (editableFeature) {
      setValues(editableFeature);
    } else {
      resetForm();
    }
  }, [editableFeature, show]);

  useEffect(() => {
    getPermission();
    setValidationError(null);
    setFeatureName("");
    setPermissionGroup("");
    // resetForm();
  }, [show]);


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 ">
                <label className="form-label">Permission Group</label>
                <select
                  className="form-control border-secondary"
                  placeholder="Permission Group"
                  name="perm_group_id"
                  value={values.perm_group_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled selected hidden>
                    Select Permission Group
                  </option>
                  {permissionList?.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                {touched.perm_group_id && errors.perm_group_id ? (
                  <p className="text-danger">{errors.perm_group_id}</p>
                ) : (
                  <p className="text-danger">
                    {validationError?.perm_group_id}
                  </p>
                )}
              </div>
              <div className="col-12 ">
                <label className="form-label">Feature Name</label>
                <input
                  className="form-control border-secondary"
                  placeholder="Feature Name"
                  name="feature"
                  value={values.feature}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {touched.feature && errors.feature ? (
                  <p className="text-danger">{errors.feature}</p>
                ) : (
                  <p className="text-danger">{validationError?.feature}</p>
                )}
              </div>
              <div className="col-12 ">
                <label className="form-label">Icon</label>
                <input
                  className="form-control border-secondary"
                  placeholder="eg : <i class='fa fa-icon'></i>"
                  name="icon"
                  value={values.icon}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {touched.icon && errors.icon ? (
                  <p className="text-danger">{errors.icon}</p>
                ) : (
                  <p className="text-danger">{validationError?.icon}</p>
                )}
              </div>
              <div className="col-12">
                <label className="form-label">URL</label>
                <input
                  className="form-control border-secondary"
                  placeholder="URL of where page is gonna redirect"
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></input>
                {touched.url && errors.url ? (
                  <p className="text-danger">{errors.url}</p>
                ) : (
                  <p className="text-danger">{validationError?.url}</p>
                )}
              </div>
            </div>
            <Modal.Footer>
              {spinner ? (
                <div className="">
                  <i className="fa fa-spinner fa-spin fa-3x text-danger me-3 mt-3"></i>
                  <p>Wait...</p>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {editableFeature ? "Update" : "Add"}
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
